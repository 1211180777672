<div class="general-container">
  <h3>{{ 'invest.Reserved.Title' | translate }}</h3>
  <div class="header-text">
      <p *ngIf="totalInversoresActual > 0">{{ 'invest.Reserved.Current' | translate }} <span class="money">{{ totalInversoresActual }}</span> {{ userN }} {{ 'invest.Reserved.Confirm' | translate }}
          <span class="money">{{ totalMoneyReserved | number:'1.0-0':'es' }} €</span>. </p>
      <p *ngIf="totalInversoresActual === 0">{{ 'invest.Reserved.no-investors' | translate }}</p>
      <p *ngIf="btnType === 'primaryDark-disabled'">{{ 'invest.Reserved.Stay' | translate }}</p>
      <p *ngIf="btnType === 'primaryDark'">{{ 'invest.Reserved.Continue' | translate }}</p>
  </div>
  <div class="tables">
      <div *ngIf="reservedData.length > 0" class="table-header">
          <p>{{ 'invest.Reserved.expire_in' | translate }}</p>
          <p>{{ 'invest.Reserved.money' | translate }}</p>
      </div>
      <div *ngFor="let data of reservedData" class="reserved-data">
          <p class="time-r">{{ data.timeR }}</p>
          <p class="amount">{{ data.amount | localeNumber }} €</p>
      </div>
  </div>
  <div class="btn-container row">
      <div class="col-6">
          <fun-new-button [buttonType]="'secondaryDark'" title="cancelar" (eventClick)="cancel()"></fun-new-button>
      </div>
      <div class="col-6">
          <fun-new-button *ngIf="btnType === 'primaryDark-disabled'" [buttonType]="btnType"
          title="{{ totalMoneyReserved | number:'1.0-0':'es' }} € Reservados"></fun-new-button>
          <fun-new-button *ngIf="btnType === 'primaryDark'" [buttonType]="btnType"
          title="{{ freeToInvest | number:'1.0-0':'es' }} € Disponibles" (eventClick)="continue()"></fun-new-button>
      </div>
  </div>
</div>
