<div class="register">
    <div class="body">
        <div class="phone-c">
            <fun-phone-input 
            [dataValue]="data"
            (sendValueEvent)="getPhoneValue($event)" 
            (sendErrorEvent)="getPhoneError()"
            labelName="{{ 'register.phone.phone' | translate }}" 
            placeholder="{{ 'register.phone.phone' | translate }}">
            </fun-phone-input>
        </div>
    </div>
</div>