import { Component, OnInit, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'fun-overal-message',
  templateUrl: './overal-message.component.html',
  styleUrls: ['./overal-message.component.scss']
})
export class OveralMessageComponent implements OnInit {

  @Input() text: any;
  @Input() idG: any;
  @Input() image: any;
  @Input() btnList: any;
  @Input() btnText: any;
  @Input() refCode: any;
  @Input() check: any;
  @Input() removePadding: any;
  @Output() public btnEvent = new EventEmitter<any>();
  @Output() public btnTxtEvent = new EventEmitter<any>();
  @Output() public eventShare = new EventEmitter<any>();
  @Output() public checkEvent = new EventEmitter<any>();
  imgUrl: any;
  idTitle: string;

  isCopied: boolean;
  paymentType: string;
  ref: string;
  shareText: string;

  selected = false;

  constructor(private cdRef: ChangeDetectorRef, public toastService: ToastService, public translate: TranslateService) { }

  ngOnInit() {
    this.shareText =
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_1') +
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_2') +
      this.refCode +
      this.translate.instant('REFERRAL.PAGE.SHARE_TEXT_3', { code: this.refCode });
    this.imageList();
    this.idTitle = (this.idG !== undefined) ? this.idTitle = this.idG : 'generic';
    this.cdRef.detectChanges();
  }

  selectElement() {
    this.selected = !this.selected;
    this.checkEvent.emit(this.selected);
    this.cdRef.detectChanges();
  }


  imageList() {
    switch (this.image) {
      case 1:
        this.imgUrl = '../../assets/images/img/failed-test.svg';
        break;
      case 2:
        this.imgUrl = '../../assets/images/img/reserved-invest.svg';
        break;
      case 3:
        this.imgUrl = '../../assets/images/img/passaport.svg';
        break;
      case 4:
        this.imgUrl = '../../assets/images/img/no-register.svg';
        break;
      case 5:
        this.imgUrl = '../../assets/images/img/not-found.webp'; // For not found
        break;
      case 6:
        this.imgUrl = '../../assets/images/img/ico-success.svg';
        break;
      case 7:
        this.imgUrl = '../../assets/images/img/ico-uploaded-ok.svg';
        break;
      case 8:
        this.imgUrl = '../../assets/images/illustrations/notifications.svg';
        break;
      case 9:
        this.imgUrl = '../../assets/images/illustrations/touch-id.svg';
        break;
      case 10:
        this.imgUrl = '../../assets/images/img/email-verificated.svg';
        break;
      case 11:
        this.imgUrl = '../../assets/images/img/phone-verificated.svg';
        break;
      case 12:
        this.imgUrl = '../../assets/images/illustrations/plants.svg';
        break;
      case 13: // A partir de aqui va lo nuevo
        this.imgUrl = '../../assets/images/img/rocket.svg';
        break;
      case 14:
        this.imgUrl = '../../assets/images/img/checked-image.svg';
        break;
      case 15:
        this.imgUrl = '../../assets/images/img/question-ok.svg';
        break;
      case 16:
        this.imgUrl = '../../assets/images/img/question-ko.svg';
        break;
    }
    this.cdRef.detectChanges();
  }
  emitClick(index) {
    this.btnEvent.emit(index);
    this.cdRef.detectChanges();
  }

  btnTxtClicked() {
    this.btnTxtEvent.emit();
    this.cdRef.detectChanges();
  }

  share() {
    this.eventShare.emit();
    this.cdRef.detectChanges();
  }
  copyCode(refCode) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = refCode;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.showToast(refCode + ' copiado', 'success', true);
    this.showMsgCopied(refCode);
    this.cdRef.detectChanges();
  }
  showMsgCopied(ref) {
    this.isCopied = true;
    setTimeout(() => (this.isCopied = false), 3000);
  }
  shareWatsapp() {
    const share = 'https://api.whatsapp.com/send?text=' + this.shareText;
    window.open(share);
  }
  shareFacebook() {
    const share = 'http://www.facebook.com/sharer/sharer.php?u=www.fundeen.com/es/';
    window.open(share);
  }
  shareTwitter() {
    const share = 'http://twitter.com/intent/tweet?text=' + this.shareText;
    window.open(share);
  }
  shareLinkedin() {
    const share =
      'https://www.linkedin.com/shareArticle?mini=true&url=https://www.fundeen.com/es/&title=' +
      this.shareText +
      '&summary=fundeen.com&source=Fundeen';
    window.open(share);
  }
  shareMail() {
    const ssubjectMail = this.translate.instant('REFERRAL.SUBJECT_MAIL');
    const share = 'mailto:?subject=' + ssubjectMail + '&body=' + this.shareText;
    window.open(share);
  }

}
