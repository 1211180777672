import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-register-person-phone',
  templateUrl: './register-person-phone.component.html',
  styleUrls: ['./register-person-phone.component.scss']
})
export class RegisterPersonPhoneComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  getPhoneValue(val) {
    const value = {
      phone: val.phone,
      pref: val.prefix.slice(1)
    };
    this.sendInfoEvent.emit(value);
  }
  getInfo() {}
  getPhoneError() {
    this.sendErrorEvent.emit();
  }

}
