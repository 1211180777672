<div class="general-container">
  <h2 *ngIf="investorsInfo.status !== 15" class="font-sans text-h600 text-eggplant-100/[0.25]">{{ 'OVERVIEW_PROJECT.COINVESTORS' | translate }}</h2>
  <h2 *ngIf="investorsInfo.status === 15" class="font-sans text-h600 text-eggplant-100/[0.25]">{{ 'FORMALITIES.RESERVE_PROCESS.RESERVE_NUMBER' | translate }}</h2>
  <!-- <p>{{ 'overview.investor.txt' | translate }}</p> -->
  <div class="content flex flex-col gap-24 border border-eggplant-100/[0.1]">
      <div *ngIf="investorsNumber > 4" class="avt-content border-b border-b-eggplant-100/[0.1]">
        <fun-overviiew-status-avatars [investors]="investorsNumber" [size]="'xl'"></fun-overviiew-status-avatars>
      </div>
      <div *ngIf="investorsInfo.fundeenMoney > 0 || investorsInfo.consumer > 0 || investorsInfo.constructor > 0 || investorsInfo.manager > 0" class="flex gap-12 md:gap-24 place-content-between">
          <div *ngIf="investorsInfo.fundeenMoney > 0">
            <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.fundeen' | translate }}</h3>
            <h2 class="font-sans text-eggplant-100 text-h500">{{ investorsInfo.fundeenMoney | localeNumber }} €</h2>
          </div>
          <div [ngClass]="{ 'border-l !pl-16': investorsInfo.fundeenMoney > 0 }" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.consumer > 0">
            <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.consumer' | translate }}</h3>
            <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.consumer | localeNumber }} €</h2>
          </div>
          <div [ngClass]="{ 'border-l !pl-16': investorsInfo.fundeenMoney > 0 || investorsInfo.consumer > 0 }" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.constructor > 0">
            <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.constructor' | translate }}</h3>
            <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.constructor | localeNumber }} €</h2>
          </div>
          <div [ngClass]="{ 'border-l !pl-16': investorsInfo.fundeenMoney > 0 || investorsInfo.consumer > 0 || investorsInfo.constructor > 0 }" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.manager > 0">
            <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.manager' | translate }}</h3>
            <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.manager | localeNumber }} €</h2>
          </div>
      </div>
      <div *ngIf="investorsInfo.investorLevel.first > 0 || investorsInfo.investorLevel.second > 0 || investorsInfo.investorLevel.third > 0 || investorsInfo.investorLevel.fourth > 0" class="flex gap-12 md:gap-24 place-content-between overflow-auto">
        <div *ngIf="investorsInfo.investorLevel.first > 0" class="flex flex-col">
          <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.until' | translate }}
            <!-- <br class="md:hidden" *ngIf="investorsInfo.investorLevel.first > 0 && investorsInfo.investorLevel.second > 0 && investorsInfo.investorLevel.third > 0 && investorsInfo.investorLevel.fourth > 0"> {{ 'overview.investor.and' | translate }} -->
            <br class="md:hidden" *ngIf="investorsInfo.investorLevel.first > 0 && investorsInfo.investorLevel.second > 0 && investorsInfo.investorLevel.third > 0 && investorsInfo.investorLevel.fourth > 0">  1.000€</h3>
          <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.investorLevel.first | localeNumber }}</h2>
        </div>
        <div [ngClass]="{ 'border-l !pl-[10px] md:!pl-16': investorsInfo.investorLevel.first > 0 }" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.investorLevel.second > 0">
          <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.between' | translate }} 1.000€ {{ 'overview.investor.and' | translate }} 5.000€</h3>
          <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.investorLevel.second | localeNumber }}</h2>
        </div>
        <div [ngClass]="{ 'border-l !pl-[10px] md:!pl-16': investorsInfo.investorLevel.first > 0 || investorsInfo.investorLevel.second > 0}" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.investorLevel.third > 0">
          <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.between' | translate }} 5.000€ {{ 'overview.investor.and' | translate }} 25.000€</h3>
          <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.investorLevel.third | localeNumber }}</h2>
        </div>
        <div [ngClass]="{ 'border-l !pl-[10px] md:!pl-16': investorsInfo.investorLevel.first > 0 || investorsInfo.investorLevel.second > 0 || investorsInfo.investorLevel.third > 0 }" class="border-eggplant-100/[0.10] flex flex-col" *ngIf="investorsInfo.investorLevel.fourth > 0">
          <h3 class="font-sans text-eggplant-100/[0.25] text-l">{{ 'overview.investor.more' | translate }} 25.000€</h3>
          <h2 class="font-sans text-eggplant-100 text-h500 mt-auto">{{ investorsInfo.investorLevel.fourth | localeNumber }}</h2>
        </div>
      </div>
  </div>
</div>