<ion-slides class="navigation-component" [options]="navSlideOpts">
  <ion-slide [ngClass]="{ active: currentPage === 'daily-production' }" (click)="changePage('daily-production')">
    <p>{{ 'DASHBOARD.COMPONENTS.GRAPHIC_MENU.PRODUCTION' | translate }}</p>
  </ion-slide>
  <ion-slide class="income" [ngClass]="{ active: currentPage === 'daily-income' }" (click)="changePage('daily-income')">
    <p>{{ 'DASHBOARD.COMPONENTS.GRAPHIC_MENU.INCOME' | translate }}</p>
  </ion-slide>
  <ion-slide class="income" [ngClass]="{ active: currentPage === 'yearly-revenues' }" (click)="changePage('yearly-revenues')">
    <p>{{ 'DASHBOARD.COMPONENTS.GRAPHIC_MENU.REVENUE' | translate }}</p>
  </ion-slide>
  <ion-slide class="historic" [ngClass]="{ active: currentPage === 'production' }" (click)="changePage('production')">
    <p>{{ 'DASHBOARD.COMPONENTS.GRAPHIC_MENU.HISTORICAL' | translate }}</p>
  </ion-slide>
</ion-slides>
