import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  ViewContainerRef,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToastComponent } from './toast.component';

type TypeToast = 'warning' | 'info' | 'check' | 'success';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  public listRef: ComponentRef<ToastComponent>[] = [];
  public ref: ComponentRef<ToastComponent>;
  public component$: Observable<any>;
  public componentViewContainer: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: any
  ) { }

  /**
   * showToast
   *
   * @param toastText string
   * @param type string ('warning' | 'info' | 'check' | 'success')
   * @param closeTimeout  boolean (default false) time 500ms
   */
  public showToast<T>(toastText: string, type: TypeToast, closeTimeout: boolean = false): Observable<ToastComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(ToastComponent);
    this.ref = factory.create(this.injector);
    this.listRef.push(this.ref);
    this.ref.instance.setIndexRef(this.listRef.length - 1);

    this.ref.changeDetectorRef.detectChanges();
    const { nativeElement } = this.ref.location;
    this.document.body.appendChild(nativeElement);

    const modalRef$ = new ReplaySubject();
    modalRef$.next(this.ref);
    modalRef$.complete();

    this.ref.instance.setType(type);
    this.ref.instance.setText(toastText);
    if (closeTimeout) {
      this.ref.instance.setTimeout();
    }
    return modalRef$.asObservable().pipe(map( (modal: ComponentRef<ToastComponent>) => modal.instance )) as Observable<ToastComponent>;
  }

  public removeToast(index: number) {
    if (this.listRef.length > 0 && this.listRef[index] != null) {
      const ref = this.listRef[index];
      this.listRef[index] = null;
      const { nativeElement } = ref.location;
      this.document.body.removeChild(nativeElement);
    }
  }

}
