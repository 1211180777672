<div id="content" class="content block fixed w-full h-full left-0 z-100 bg-white shadow-xl overflow-auto "
  [ngClass]="{'appear': !mobileScreen, 'appearUp': mobileScreen,
  'dissapear': (closeProccess && !mobileScreen), 'dissapearDown': (closeProccess && mobileScreen)}">
  <div>
    <fun-new-detail-overview
      [slug]="project.slug"
      (closeModalEvent)="closeModal()"
      [page]="page"
      [openProjectInvestmentProccess]="openProjectInvestmentProccess">
    </fun-new-detail-overview>
  </div>
</div>
<div (click)="closeModal()" class="w-full h-full bg-eggplant-100/[0.2] absolute md:hidden top-0 left-0"></div>