import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NationalityService } from 'src/app/services/nationality.service';
import { NationModel } from './nation.model';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-register-person-natinality',
  templateUrl: './register-person-natinality.component.html',
  styleUrls: ['./register-person-natinality.component.scss']
})
export class RegisterPersonNatinalityComponent implements OnInit, AfterViewInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() isMobile: boolean;
  @Input() disabled: boolean;
  @Input() myProfile = false;

  public selectForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public selectIsOpen = false;

  nationList = [];

  residence: any;
  nation: any;

  hasResidence = true;
  hasCountry = true;
  countryListCharged = false;

  nationName: string;
  countryName: string;

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private nationalityService: NationalityService,
    private utils: UtilsService
  ) {
    this.nationName, this.countryName = this.translate.instant('register.nationality.not_specified');
    this.getCountryList();
  }

  ngOnInit() {
    this.selectForm = new FormGroup({
      nation: new FormControl(this.data.nation ? this.data.nation : (this.myProfile ? null : 108), [Validators.required]),
      residence: new FormControl(this.data.residence ? this.data.residence : (this.myProfile ? null : 108), [Validators.required]),
    });
    if (this.data.nation && this.data.nation !== undefined) {
      this.nation = this.data.nation;
    } else {
      this.nation = '0';
      this.hasCountry = false;
    }
    if (this.data.residence && this.data.residence !== undefined) {
      this.residence = this.data.residence;
    } else {
      this.residence = '0';
      this.hasResidence = false;
    }
    setTimeout(() => {
      this.countrySelected();
    }, 100);
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.countrySelected();
    this.chdRef.detectChanges();
  }

  getCountryList() {
    this.nationalityService.getNations().subscribe(
      (response) => {
        let nations: NationModel[] = response;
        for (let nation of nations) {
          if (nation.code === 108) {
            this.nationList.unshift({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          } else {
            this.nationList.push({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          }
          if (!this.utils.checkEmptyField(this.data) && !this.utils.checkEmptyField(this.data.nation) && this.data.nation === nation.code) {
            this.nationName = `${nation.name[0]}${nation.name.slice(1).toLowerCase()}`;
          }
          if (!this.utils.checkEmptyField(this.data) && !this.utils.checkEmptyField(this.data.residence) && this.data.residence === nation.code) {
            this.countryName = `${nation.name[0]}${nation.name.slice(1).toLowerCase()}`;
          }
        }
        this.countryListCharged = true;
        this.chdRef.detectChanges();
      }
    );
    /* this.translate.get('COUNTRY').subscribe(resp => {
      const num = Object.keys(resp).length;
      for (let i = 0; i < num; i++) {
        const code = Object.keys(resp)[i];
        this.translate.get('COUNTRY.' + code).subscribe(response => {
          const country = response;
          const countryList = {
            id: Number(code),
            name: country
          };
          if (code === '108') {
            this.nationList.unshift(countryList);
          } else {
            this.nationList.push(countryList);
          }
        });
      }
    });
    const spain = this.nationList.find((element) => element.id === 108);
    this.nationList.splice(0, 1);
    this.nationList.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    this.nationList.unshift(spain); */
  }

  countrySelected() {
    let nat;
    let res;
    if (!this.selectForm.value.nation || this.selectForm.value.nation === undefined || this.selectForm.value.nation === '') {
      nat = String(this.nation);
    } else {
      nat = this.selectForm.value.nation;
    }
    if (!this.selectForm.value.residence || this.selectForm.value.residence === undefined || this.selectForm.value.residence === '') {
      res = this.residence;
      this.hasResidence = true;
    } else {
      res = this.selectForm.value.residence;
    }
    this.hasCountry = (nat !== '0') ? true : false;
    this.hasResidence = (res !== '0') ? true : false;
    const value = {
      nation: nat,
      residence: res
    };
    if (nat !== '0' && res !== '0') {
      this.sendInfoEvent.emit(value);
    }
    this.chdRef.detectChanges();
  }

}
