import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'fun-register-person-edit-email',
  templateUrl: './register-person-edit-email.component.html',
  styleUrls: ['./register-person-edit-email.component.scss']
})
export class RegisterPersonEditEmailComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public acceptConditionsEvent = new EventEmitter<any>();
  @Output() public acceptOffersEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Input() public data;

  public emailForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public acceptConditions;
  public acceptOffers;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      pwd: new FormControl('', [Validators.required])
    });
    this.getInfo();
    this.chdRef.detectChanges();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getInfo() {
    const payLoad = this.emailForm.value;
    if (this.emailForm.status === 'VALID') {
      this.sendInfoEvent.emit(payLoad);
    } else {
      this.sendErrorEvent.emit();
    }
    this.chdRef.detectChanges();
  }

}
