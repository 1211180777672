
<ion-split-pane when="lg" contentId="main" [class]="currentBg">
  <div contentId="main" [ngClass]="{'menu-main': menuOpened, 'menu-secondary': !menuOpened }"  id="myMenu">
    <div class="pt-[45px] pl-[36px] pb-[72px] h-full">
      <div class=" h-full overflow-hidden " [ngClass]="{ ' border-r border-r-eggplant-100/[.25] ' : menuOpened !== false }" id="sideMenu">
        <app-sidemenu (toggleOpeneEvent)="toggleMenu($event)"></app-sidemenu>
      </div>
    </div>
  </div>

  <div class="ion-page" id="main">

    <ion-content class="ion-padding">
      <ion-router-outlet></ion-router-outlet>
      <app-tab></app-tab>
    </ion-content>
  </div>
</ion-split-pane>
