import { ElementRef, HostListener, Input, ViewChild, ViewChildren, ViewContainerRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @ViewChild('filter',  {static: false}) element: ElementRef<any>;

  @Output() public openMobFilterEvent = new EventEmitter<any>();
  @Output() public changeFiltersEvent = new EventEmitter<any>();
  @Input() filterList: any;
  @Input() activeFilters: number;

  count: number;
  openedFilterIndex;
  filtersOpened = false;
  filterModel = [];
  loading = false;
  filterMobOpen = false;
  buttonFilter;
  buttonMenuFilter;
  mobileActiveFilters: number;

  constructor() { }

  ngOnInit() {
    this.initFilters();
  }

  @HostListener('document:click', ['$event']) onClick(event: MouseEvent) {
    this.buttonFilter = document.querySelector(".filterButton");
    this.buttonMenuFilter = document.querySelector(".filters-panel");
    if((!event.composedPath().includes(this.buttonFilter)) && ((!event.composedPath().includes(this.buttonMenuFilter)))){
      this.filtersOpened = false;
    }
  }

  initFilters() {
    this.filterModel = [];
    this.filterList.forEach((element, i) => {
      this.filterModel.push({ title: element.title, id: element.id, values: []});
      element.list.forEach(option => {
        this.filterModel[i].values.push({name: option, checked: false});
      });
    });
    this.count = 0;
  }

  elementSelected(filterIndex, optionIndex, checked) {
    this.filterModel[filterIndex].values[optionIndex].checked = checked;
    checked ? this.count++ : this.count--;
    this.changeFiltersEvent.emit(this.filterModel);
  }

  getMobileFilters(evt: any) {
    this.filterModel = evt.mobileFilters;
    this.mobileActiveFilters = evt.mobileCount;
    this.count = evt.mobileCount;
    this.changeFiltersEvent.emit(this.filterModel);
  }

  openFilter(index: number) {
    this.openedFilterIndex !== index ? this.openedFilterIndex = index : this.openedFilterIndex = undefined;
  }

  deleteFilters() {
    this.initFilters();
    this.changeFiltersEvent.emit(this.filterModel);
  }

  openMobFilter() {
    this.filterMobOpen = !this.filterMobOpen;
  }

}
