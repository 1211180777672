<div class="register">
    <div class="body">
        <div class="code-container">
            <fun-code [isMobile]="isMobile" (sendValueEvent)="getCodeValue($event)"></fun-code>
            <p *ngIf="!codeResended" class="body-info">{{ 'register.code.resend_txt' | translate }}
                <span *ngIf="isCountDowndEnd" class="span_b hovered" (click)="resendCode()"> {{ 'register.code.resend' | translate }} </span>
                <span *ngIf="!isCountDowndEnd">{{ 'register.code.can_resend' | translate }}</span>
                <span *ngIf="!isCountDowndEnd">{{ countDown }}</span>
                <span>{{ 'register.code.seconds_or' | translate }}</span>
                <span class="span_b hovered">{{ 'register.code.contact_us' | translate }}.</span>
                <span> {{ 'register.code.call_us' | translate }} </span>
                <a href="tel:+34911238277"><span class="span_b hovered">+34 911 23 82 77</span></a>
            </p>
            <p *ngIf="codeResended" class="body-info">{{ 'register.code.resend_txt_2' | translate }}</p>
        </div>
    </div>
</div>