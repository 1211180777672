import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameProfile',
    pure: true
})
export class NameProfilePipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const nameProfile = value[0]; // value.split(' ').map((n) => n[0]).join('');
            return nameProfile;
        }
        return value;
    }

}