import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-help-marketplace',
  templateUrl: './help-marketplace.component.html',
  styleUrls: ['./help-marketplace.component.scss']
})
export class HelpMarketplaceComponent implements OnInit {
  @Input() questions: any;
  @Output() public eventQuestionClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.questions.forEach(element => {
      element.opened = false;
    });
  }
  emitQuestion(index) {
    const el = document.getElementById(`element${index}`);
    this.questions.forEach(element => {
      if (element.index === index) {
        element.opened = !element.opened;
        if (!element.opened) {
          el.style.height = '0px';
          el.style.transition = 'height 0.5s';
        } else {
          el.style.height = `${el.scrollHeight}px`;
          el.style.transition = 'height 0.5s';
        }
      }
    });
  }

}
