import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SidemenuService } from '../../sidemenu/sidemenu.service';

@Component({
  selector: 'fun-new-detail',
  templateUrl: './new-detail.component.html',
  styleUrls: ['./new-detail.component.scss'],
})
export class NewDetailComponent implements OnInit {

  @Output() private closeModalEvent = new EventEmitter<void>();
  @Input() public project;
  @Input() public page = 'overview';
  @Input() public openProjectInvestmentProccess = false;

  mobileScreen = window.innerWidth < 780 ? true : false;
  closeProccess = false;

  constructor(private sidemenuService: SidemenuService, private chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.sidemenuService.elementClickedEvent.subscribe(val => {
      this.closeModal();
      this.chdRef.detectChanges();
    });
   }

  closeModal() {
    this.closeProccess = true;
    setTimeout(() => {
      this.closeModalEvent.emit();
    }, 200);
  }

}
