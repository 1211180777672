<div (keyup)="getValues(code1.value, code2.value, code3.value, code4.value)">
    <div class="code-container">
        <input type="password" maxlength="1" #code1 id="code1" class="code"
        (keyup)="nextFocus(code1); code2.focus(); controlEmpty();" (click)="code1.value = ''">
        <div class="spacing"></div>
        <img src="../../../../../assets/images/img/code.svg" alt="" *ngIf="!isEmpty1" class="points" (click)="code1.value = ''; controlEmpty(); code1.focus();">
        <img src="../../../../../assets/images/img/code-empty.svg" *ngIf="isEmpty1" alt="" class="points" (click)="code1.focus();">
    </div>
    <div class="code-container">
        <input type="password" maxlength="1" #code2 id="code2" class="code"
        (keyup)="nextFocus(code2); code3.focus(); controlEmpty();" (click)="code2.value = ''">
        <div class="spacing"></div>
        <img src="../../../../../assets/images/img/code.svg" alt="" *ngIf="!isEmpty2" class="points" (click)="code2.value = ''; controlEmpty(); code2.focus();">
        <img src="../../../../../assets/images/img/code-empty.svg" *ngIf="isEmpty2" alt="" class="points" (click)="code2.focus();">
    </div>
    <div class="code-container">
        <input type="password" maxlength="1" #code3 id="code3" class="code"
        (keyup)="nextFocus(code3); code4.focus(); controlEmpty();" (click)="code3.value = ''">
        <div class="spacing"></div>
        <img src="../../../../../assets/images/img/code.svg" alt="" *ngIf="!isEmpty3" class="points" (click)="code3.value = ''; controlEmpty(); code3.focus();">
        <img src="../../../../../assets/images/img/code-empty.svg" *ngIf="isEmpty3" alt="" class="points" (click)="code3.focus();">
    </div>
    <div class="code-container">
        <input type="password" maxlength="1" #code4 id="code4" class="code"
        (keyup)="nextFocus(code4); controlEmpty();" (click)="code4.value = ''">
        <div class="spacing"></div>
        <img src="../../../../../assets/images/img/code.svg" alt="" *ngIf="!isEmpty4" class="points" (click)="code4.value = ''; controlEmpty(); code4.focus();">
        <img src="../../../../../assets/images/img/code-empty.svg" *ngIf="isEmpty4" alt="" class="points" (click)="code4.focus();">
    </div>
</div>