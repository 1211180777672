import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'fun-skeleton-card-list',
  templateUrl: './skeleton-card-list.component.html',
  styleUrls: ['./skeleton-card-list.component.scss']
})
export class SkeletonCardListComponent {


}
