<div class="general-container register flex flex-col" [ngClass]="{'mob-general': isMobileApp !== undefined}">
    <div *ngIf="isMobileApp === undefined && onlyAccredit !== true && list.length > 2" class="progress_bar_container">
        <div class="progress_bar" [ngStyle]="{ width: percent + '%' }"></div>
    </div>

    <div class="body-content overflow-auto pr-24 pt-24 md:pt-0 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <ng-container>
            <!-- onBoarding step -->
            <div *ngIf="step === onBoardingIndex && !loading" class="step pwd">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.start.company_question' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.start.requirements' | translate}}</p>
                </div>
                <fun-register-person-last-step-advantages
                    [options]="companyRequirements"
                ></fun-register-person-last-step-advantages>
            </div>
            <!-- email step -->
            <div *ngIf="step === emailIndex && !loading" class="step pwd">
                <div class="span-container">
                    <span id="page-title">Email y contraseña</span>
                </div>
                <div *ngIf="emailPage === 'email'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.email.contact_person_email' | translate}}</h2>
                        <p class="font-sans text-center text-xl text-gray-40" *ngIf="!editM">{{ 'register.email.email_need' | translate }}</p>
                        <p class="font-sans text-center text-xl text-gray-40" *ngIf="editM">{{ 'register.email.edit' | translate }}</p>
                    </div>
                    <fun-register-person-email
                    #registerPersonEmail
                    *ngIf="!editM"
                    [data]="info"
                    profileType="2"
                    (sendErrorEvent)="getError()"
                    (sendInfoEvent)="getEmailData($event)"
                    (acceptConditionsEvent)="getConditions($event)"
                    (acceptOffersEvent)="getOffers($event)"
                    (checkRefCodeEvent)="checkRefCode($event)"
                    (selectTermsAndConditionsEvent)="showTermsAndConditions($event)">
                    </fun-register-person-email>
                    <fun-register-person-edit-email
                        (sendErrorEvent)="getError()"
                        (sendInfoEvent)="getNewEmailData($event)"
                        *ngIf="editM"
                    ></fun-register-person-edit-email>
                </div>
                <div *ngIf="emailPage === 'terms'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'REGISTER.TERMS' | translate }}</h2>
                        <div class="check-conditions">
                            <fun-spinner *ngIf="!hasTermsTxt"></fun-spinner>
                            <div class="conditions-container" *ngIf="hasTermsTxt" [innerHTML]="termsTxt"></div>
                        </div>
                    </div>
                </div>
                <div *ngIf="emailPage === 'privacity'">
                    <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                        <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'REGISTER.PRIVACY' | translate }}</h2>
                        <div class="check-conditions">
                            <fun-spinner *ngIf="!hasPrivacyText"></fun-spinner>
                            <div class="conditions-container" *ngIf="hasPrivacyText" [innerHTML]="privacyTxt"></div>
                        </div>
                    </div>
                </div>

            </div>
            <!---->
            <div *ngIf="step === verifyEmailIndex && !loading" class="step ">
                <!-- <fun-overal-message [text]="text" [image]="image" [btnList]="btnList" (btnEvent)="clickBtn($event)"></fun-overal-message> -->
                <fun-overal-message [text]="textAcceptMail" [image]="imageAcceptMail" [btnList]="btnListAcceptMail" (btnEvent)="removeModal()"></fun-overal-message>
            </div>
            <!-- emailCode step -->
            <div *ngIf="step === emailCodeIndex && !loading" class="step code">
                <div class="span-container">
                    <span id="page-title">Validación de email</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.email.confirm_email' | translate}}</h2>
                    <h2 class="email_ad">{{ info.email }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.email.check_spam' | translate}} <span (click)="editMail()" class="span_b">{{'register.email.edit_email_simple' | translate}}</span> </p>
                </div>
                <!-- <fun-register-person-code
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (resetBtn)="sendXHR = 'disabled'"
                    (sendInfoEvent)="getCodeInfo($event)"
                    (resendCodeEvent)="resendCode('mail')"
                ></fun-register-person-code> -->
            </div>
            <!-- phone step -->
            <div *ngIf="step === phoneIndex && !loading" class="step phone">
                <div class="span-container">
                    <span id="page-title">Número de teléfono</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.phone.phone' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.phone.will_use' | translate }}</p>
                </div>
                <fun-register-person-phone
                    [data]="info"
                    (sendInfoEvent)="getPhoneData($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-person-phone>
            </div>
            <!-- phoneCode step -->
            <div *ngIf="step === phoneCodeIndex && !loading" class="step code">
                <div class="span-container">
                    <span id="page-title">Número de teléfono</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.code.introduce' | translate }}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{ 'register.code.send' | translate }}<span>+{{ info.prefix }} {{ info.phone }}</span>
                        {{'register.phone.its_wrong' |translate}} <span class="span_b" (click)="editPhone()">{{'register.phone.edit_phone' | translate}}</span>
                    </p>
                </div>
                <!-- <fun-register-person-code
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (resetBtn)="sendXHR = 'disabled'"
                    (sendInfoEvent)="getCodeInfo($event)"
                    (resendCodeEvent)="resendCode('phone')"
                ></fun-register-person-code> -->
            </div>
            <!-- passToPCAdvice step -->
            <div *ngIf="step === passToPCAdviceIndex && !loading" class="step">
                <fun-overal-message [text]="textPCAdvice" [image]="imagePCAdvice" [btnList]="btnListPCAdvice" (btnEvent)="next()"></fun-overal-message>
            </div>
            <!-- contactPersonData step -->
            <div *ngIf="step === contactPersonDataIndex && !loading" class="step name">
                <div class="span-container">
                    <span id="page-title">Persona de contacto</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.know.contact_person_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.know.contact_person_subtitle' | translate}}</p>
                </div>
                <fun-register-company-contact-person
                    [data]="info"
                    (sendInfoEvent)="getContactPersonInfo($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-contact-person>
            </div>
            <!-- companyData step -->
            <div *ngIf="step === companyDataIndex && !loading" class="step company">
                <div class="span-container">
                    <span id="page-title">Datos de la empresa</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.company.company_data_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.company.company_data_subtitle' | translate}}</p>
                </div>
                <fun-register-company-data
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getCompanyData($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-data>
            </div>
            <!-- companyAddress step -->
            <div *ngIf="step === companyAddressIndex && !loading" class="step address">
                <div class="span-container">
                    <span id="page-title">{{'REGISTER.COMPANY_ADDRESS' | translate}}</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.company.company_address_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.company.company_address_subtitle' | translate}}</p>
                </div>
                <fun-register-company-address
                    [data]="info"
                    [towns]="towns"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getCompanyAddress($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-address>
            </div>
            <!-- welcome step -->
            <div *ngIf="step === welcomeIndex && !loading" class="step predoc">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.know.hi' | translate}} {{info.name}}!</h2>
                    <p class="font-sans text-center text-xl text-gray-40 pb-0">{{'register.know.you_are_on_fundeen_subtitle' | translate}}</p>
                    <p class="font-sans text-center text-xl text-gray-40 pb-0">{{'register.know.you_are_on_fundeen_subtitle1' | translate}}</p>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.know.you_are_on_fundeen_subtitle2' | translate}}</p>
                </div>
                <fun-register-person-last-step-advantages
                    [options]="companyDocsRequirements"
                ></fun-register-person-last-step-advantages>
            </div>
            <!-- constitutionDocs step -->
            <div *ngIf="step === contitutionDocsIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Documentos de la empresa: escrituras</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.start.requirements_docs.constitution' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.start.requirements_docs.constitution_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <!-- Documento 1 -->
                        <fun-file-card
                            (getFile)="getConstitutionDocument1($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'constitutionDocument1'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Documento 2 -->
                        <fun-file-card
                            *ngIf="hasConstitution1"
                            (getFile)="getConstitutionDocument2($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'constitutionDocument2'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- realOwnershipDoc step -->
            <div *ngIf="step === realOwnershipDocIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Documentos de la empresa: registro mercantil</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.start.requirements_docs.RM_inscribe' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.start.requirements_docs.RM_inscribe_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getRealOwnershipDocument($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'realOwnershipDocument'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                    <div class="form-input checkbox">
                        <input type="checkbox" id="realOwnershipChecked" [(ngModel)]="realOwnershipChecked" (change)="checkedRealOwnershipDocument()">
                        <label for="realOwnershipChecked">{{'register.start.requirements_docs.RM_inscribe_check' | translate}}</label>
                    </div>
                </div>
            </div>
            <!-- model200 step -->
            <div *ngIf="step === enterpriseModel200DocIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Documentos de la empresa: modelo 200</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.start.requirements_docs.model_200' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.start.requirements_docs.model_200_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getModel200Document($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'model200Document'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                    <div class="form-input checkbox">
                        <input type="checkbox" id="model200Checked" [(ngModel)]="model200Checked" (change)="checkedModel200Document()">
                        <label for="model200Checked">{{'register.start.requirements_docs.model_200_check' | translate}}</label>
                    </div>
                </div>
            </div>
            <!-- identityPartnerDocs step -->
            <div *ngIf="step === identityPartnerDocsIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Documentos de la empresa: titulares</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.start.requirements_docs.titularityDNIs' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.start.requirements_docs.titularityDNIs_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <!-- Documento 1 -->
                        <fun-file-card
                            (getFile)="getIdentityDocumentPartner1($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'identityDocumentPartner1'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Documento 2 -->
                        <fun-file-card
                            *ngIf="hasDocument1"
                            (getFile)="getIdentityDocumentPartner2($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'identityDocumentPartner2'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Documento 3 -->
                        <fun-file-card
                            *ngIf="hasDocument2"
                            (getFile)="getIdentityDocumentPartner3($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'identityDocumentPartner3'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Documento 4 -->
                        <fun-file-card
                            *ngIf="hasDocument3"
                            (getFile)="getIdentityDocumentPartner4($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'identityDocumentPartner4'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!---->
            <div class="step" *ngIf="step === identityPartnerSpecificDocsIndex && !loading">
                <app-register-company-specific-partner-doc [profile]="profile" [partner]="specificPartner"
                (setSpecificDocumentOwnerEvent)="setSpecificDocumentOwner($event)" (deleteSpecificDocumentOwnerEvent)="deleteSpecificDocumentOwner()">
                </app-register-company-specific-partner-doc>
            </div>
            <!-- RLSteps step -->
            <div *ngIf="step === RLStepsIndex && !loading" class="step predoc">
                <div class="span-container">
                    <span id="page-title">Documentos de la empresa: validación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.steps.perfect' | translate}}</h2>
                    <p cclass="font-sans text-center text-xl text-gray-40 pb-0">{{'register.steps.a_bit_more' | translate}}</p>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.steps.have_near' | translate}}</p>
                </div>
                <fun-register-person-last-step-advantages
                    [options]="RLDocsRequirements"
                ></fun-register-person-last-step-advantages>

            </div>
            <!-- RLPersonalData step -->
            <div *ngIf="step === RLPersonalDataIndex && !loading" class="step pwd">
                <div class="span-container">
                    <span id="page-title">Representante legal: información básica</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.legal-representative.data_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.legal-representative.data_subtitle' | translate}}</p>
                </div>
                <fun-register-company-rldata
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getRLData($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-rldata>
            </div>
            <!--RLAddres step-->
            <div *ngIf="step === RLPersonalAddressIndex && !loading" class="step pwd">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">Dirección de representante legal</h2>
                    <p class="font-sans text-center text-xl text-gray-40">Introduce la dirección fiscal del representante legal.</p>
                </div>
                <fun-register-company-address
                    [data]="info"
                    [towns]="towns"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getReplegalAddress($event)"
                    (sendErrorEvent)="getError()">
                </fun-register-company-address>
            </div>
            <!-- RLNationality step -->
            <div *ngIf="step === RLNationalityIndex && !loading" class="step pwd">
                <div class="span-container">
                    <span id="page-title">Representante legal: nacionalidad</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.legal-representative.data_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.legal-representative.nationality_subtitle' | translate}}</p>
                </div>
                <fun-register-company-rlnationality
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getRLNationality($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-rlnationality>
            </div>
            <!-- RLDocumentType step -->
            <div *ngIf="step === RLDocumentTypeIndex && !loading" class="step pwd">
                <div class="span-container">
                    <span id="page-title">Representante legal: documento de identidad</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.legal-representative.data_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.legal-representative.document_subtitle' | translate}}</p>
                </div>
                <fun-register-company-rldocument
                    [data]="info"
                    [isMobile]="(detectOS() === 'iOS' || detectOS() === 'Android')"
                    (sendInfoEvent)="getRLDocType($event)"
                    (sendErrorEvent)="getError()"
                ></fun-register-company-rldocument>
            </div>
            <!-- RLNIF step -->
            <div *ngIf="step === RLIdentityDocIndex && info.identityDocumentTypeRepresentative === 1 && !loading" class="step ">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.identity.dni' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.identity.dni_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <!-- Anverso -->
                        <fun-file-card
                            (getFile)="getRLIdentityDocumentFront($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'RLIdentityDocumentFront'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Reverso -->
                        <fun-file-card
                            *ngIf="hasFrontDni"
                            (getFile)="getRLIdentityDocumentBack($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'RLIdentityDocumentBack'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- RLNIE step -->
            <div *ngIf="step === RLIdentityDocIndex && info.identityDocumentTypeRepresentative === 2 && !loading" class="step ">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.identity.nie' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.identity.nie_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <!-- Anverso -->
                        <fun-file-card
                            (getFile)="getRLIdentityDocumentFront($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'RLIdentityDocumentFront'"
                            [username]="info.email"
                        ></fun-file-card>
                        <!-- Reverso -->
                        <fun-file-card
                            *ngIf="hasFrontNie"
                            (getFile)="getRLIdentityDocumentBack($event)"
                            [fileType]="'RLIdentityDocumentBack'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- RLPassport step -->
            <div *ngIf="step === RLIdentityDocIndex && info.identityDocumentTypeRepresentative === 3 && !loading" class="step ">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.identity.passport' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.identity.passport_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <!-- Anverso -->
                        <fun-file-card
                            (getFile)="getRLIdentityDocumentFront($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'RLIdentityDocumentFront'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- RLIdentityDocAdvice -->
            <div *ngIf="step === RLIdentityDocAdviceIndex && !loading" class="step ">
                <fun-overal-message [text]="textIdentityDocs" [image]="imageIdentityDocs" [btnList]="btnListIdentityDocs"></fun-overal-message>
            </div>
            <!-- RLAddressDoc step -->
            <div *ngIf="step === RLAddressDocIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Representante legal: dirección fiscal</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.identity.address_doc' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.identity.address_doc_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getRLAddressDocument($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'RLAddressDocument'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- RLSuccess step -->
            <div *ngIf="step === RLSuccessIndex && !loading" class="step"> <!-- Documentos subidos con éxito -->
                <div class="span-container">
                    <span id="page-title">Acreditación</span>
                </div>
                <fun-overal-message [text]="textDocs" [image]="imageDocs" [btnList]="btnListDocs"></fun-overal-message>
            </div>
            <!-- accreditationConditions step -->
            <div *ngIf="step === acreditationConditionsIndex && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Requisitos de acreditación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.ACCOUNT_ACCREDITATION_LEGAL.ACCOUNT_ACCREDITATION' | translate}}</h2>
                    <div class="check-steps" style="height: 200px;">
                        <p class="font-sans text-center text-xl text-gray-40">{{'register.ACCOUNT_ACCREDITATION_LEGAL.DESCRIPTION_1' | translate}}</p>
                        <p class="font-sans text-center text-xl text-gray-40">{{'register.ACCOUNT_ACCREDITATION_LEGAL.DESCRIPTION_2' | translate}}</p>
                    </div>
                </div>
                <div class="body">
                    <div class="checkbox-title">
                        <label class="no-padding-bottom">{{'register.ACCOUNT_ACCREDITATION_LEGAL.MEET_CRITERIALS' | translate}}</label>
                    </div>
                    <div class="form-input checkbox">
                        <input (change)="changeAcreditationConditions()" type="checkbox" id="cb1" [(ngModel)]="cb1">
                        <label for="cb1">{{'register.ACCOUNT_ACCREDITATION_LEGAL.CRITERIAL_1' | translate}}</label>
                    </div>
                    <div class="form-input checkbox">
                        <input (change)="changeAcreditationConditions()" type="checkbox" id="cb2" [(ngModel)]="cb2">
                        <label for="cb2">{{'register.ACCOUNT_ACCREDITATION_LEGAL.CRITERIAL_2' | translate}}</label>
                    </div>
                    <div class="form-input checkbox">
                        <input (change)="changeAcreditationConditions()" type="checkbox" id="cb3" [(ngModel)]="cb3">
                        <label for="cb3">{{'register.ACCOUNT_ACCREDITATION_LEGAL.CRITERIAL_3' | translate}}</label>
                    </div>
                    <div class="form-input checkbox">
                        <input (change)="changeAcreditationConditions()" type="checkbox" id="cb4" [(ngModel)]="cb4">
                        <label for="cb4">{{'register.ACCOUNT_ACCREDITATION_LEGAL.CRITERIAL_4' | translate}}</label>
                    </div>
                </div>
            </div>
            <!-- acreditationBalanceDoc step -->
            <div *ngIf="step === acreditationDocIndex && balanceDoc && !loading" class="step ">
                <div class="span-container">
                    <span id="page-title">Documentos de acreditación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.accreditation_docs.balance_doc_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.accreditation_docs.balance_doc_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getAcreditationDocument($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'acreditationDoc'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- acreditationProfitsDoc step -->
            <div *ngIf="step === acreditationDocIndex && profitsDoc && !loading" class="step ">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.accreditation_docs.profits_report_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.accreditation_docs.profits_report_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getAcreditationDocument($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'acreditationDoc'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- acreditationCNMVDoc step -->
            <div *ngIf="step === acreditationDocIndex && cnmvDoc && !loading" class="step ">
                <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                    <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{'register.accreditation_docs.CNMV_title' | translate}}</h2>
                    <p class="font-sans text-center text-xl text-gray-40">{{'register.accreditation_docs.CNMV_subtitle' | translate}}</p>
                </div>
                <div class="body">
                    <div class="code-container">
                        <fun-file-card
                            (getFile)="getAcreditationDocument($event)"
                            (errorEvent)="errorFile($event)"
                            [fileType]="'acreditationDoc'"
                            [username]="info.email"
                        ></fun-file-card>
                    </div>
                </div>
            </div>
            <!-- IFRAME SIGNATURIT -->
            <div *ngIf="step === acreditationSignaturitIndex && !loading">
                <!-- <h2>IFRAME SIGNATURIT</h2> -->
                <fun-register-ifame [url]="signaturitUrl" #registerIfameComponent (initEvent)="getSignaturitUrl()" (signEvent)="next()" (signErrorEvent)="next()" (signaturitIssueEvent)="next()"></fun-register-ifame>
            </div>
            <!--Mangopay Terms-->
            <div *ngIf="step === mangopayWalletIndex && !loading" class="step full-grid">
                <div class="second-grid">
                    <app-register-mangopay-terms (checkActionEvent)="checkMangopayTerms($event)"></app-register-mangopay-terms>
                </div>
            </div>
            <!-- successCompleteRegister step -->
            <div *ngIf="step === successCompleteRegisterIndex && !loading">
                <div class="span-container">
                    <span id="page-title">{{ personalSpan }}</span>
                </div>
                <fun-overal-message [text]="textFinish" [image]="imageFinish" [btnList]="" (btnEvent)="finish()"></fun-overal-message>
            </div>
            <!-- IFRAME MEETING -->
            <div *ngIf="step === iframeMeetingIndex && !loading">
                <iframe class="iframe-meeting" src="https://info.fundeen.com/meetings/adrian-bautista/registro-empresas" frameborder="0"></iframe>
            </div>
            <!---->
            <fun-overal-message *ngIf="step === investMessageIndex && !loading"
            [text]="textM" [image]="imageM" [btnList]="btnListM" (btnEvent)="next()"></fun-overal-message>
            <!---->
            <fun-overal-message *ngIf="step === okInvestMessageIndex && !loading"
            [text]="textI" [image]="imageM" [btnList]="btnListM" (btnEvent)="continueInvest()"></fun-overal-message>
            <!---->
            <fun-overal-message *ngIf="step === investFinishMessageIndex && !loading"
            [text]="textF" [image]="imageM"></fun-overal-message>
            <!--loading-->
            <fun-overal-message *ngIf="step === mailValidatedIndex && !loading"
            [text]="textMailV" [image]="imageMailV" ></fun-overal-message>
            <!--loading-->
            <fun-overal-message *ngIf="step === phoneValidatedIndex && !loading"
            [text]="textPhoneV" [image]="imagePhoneV"></fun-overal-message>
            <div *ngIf="loading">
                <fun-spinner></fun-spinner>
            </div>
        </ng-container>
    </div>

    <div *ngIf="hasFooter()" class="mobile-footer">
        <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="step === emailIndex && emailPage !== 'email'" (eventClick)="showTermsAndConditions('email')" class="back-btn"
        [buttonType]="'ghost'" title="{{ 'register.btn.back' | translate }}" id="go_back"></fun-new-button>
        <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="next()" class="next-btn"
        [buttonType]="sendXHR" [title]="setTitleButton()" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="changeWay()" *ngIf="step === onBoardingIndex || step === emailIndex" id="natural_person">{{'register.btn.person' | translate}}</h4>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        *ngIf="canSkip()" (click)="goFinish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        *ngIf="acreditationSkip()" (click)="goFinish()">{{'register.btn.not_now'|translate}}</h4>
    </div>
    <div *ngIf="hasFooter()" class="desktop-footer">
        <div class="content-footer">
            <fun-new-button [classExtra]="' !w-full '" size="xl" *ngIf="step === emailIndex && emailPage !== 'email'" (eventClick)="showTermsAndConditions('email')" class="back-btn"
            [buttonType]="'ghost'" title="{{ 'register.btn.back' | translate }}" id="go_back"></fun-new-button>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            (click)="changeWay()" *ngIf="step === onBoardingIndex || step === emailIndex" id="natural_person">{{'register.btn.person' | translate}}</h4>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            *ngIf="canSkip()" (click)="goFinish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            *ngIf="acreditationSkip()" id="not_now" (click)="goFinish()">{{'register.btn.not_now'|translate}}</h4>
            <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="next()" class="next-btn"
            [buttonType]="sendXHR" [title]="setTitleButton()" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        </div>
    </div>
    <div *ngIf="step === successCompleteRegisterIndex && onlyAccredit !== true" class="mobile-footer">
        <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="goIframe('ok')" class="next-btn"
        [buttonType]="sendXHR" [title]="setTitleButton()" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="finish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
    </div>
    <div *ngIf="step === successCompleteRegisterIndex && onlyAccredit !== true" class="desktop-footer">
        <div class="content-footer">
            <!--<fun-button (eventClick)="prev()" class="back-btn" [buttonType]="secondBtn" title="volver" [id]="'go_back'"></fun-button>-->
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            (click)="finish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
            <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="goIframe('ok')" class="next-btn"
            [buttonType]="sendXHR" [title]="setTitleButton()" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        </div>
    </div>
    <div *ngIf="step === iframeMeetingIndex" class="mobile-footer">
        <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="finish()" class="next-btn"
        [buttonType]="'primaryDark'" title="{{'register.btn.finish' | translate}}" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="finish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
    </div>
    <div *ngIf="step === iframeMeetingIndex" class="desktop-footer">
        <div class="content-footer">
            <!--<fun-button (eventClick)="prev()" class="back-btn" [buttonType]="secondBtn" title="volver" [id]="'go_back'"></fun-button>-->
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            (click)="finish()" id="not_now">{{'register.btn.another_time'|translate}}</h4>
            <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="finish()" class="next-btn"
            [buttonType]="'primaryDark'" title="{{'register.btn.finish' | translate}}" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
        </div>
    </div>

</div>