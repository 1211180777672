<div class="h-full relative overflow-hidden pr-24">
  <ion-header>
    <ion-toolbar *ngIf="!opened" class="w-[51px]">
      <img class="w-[51px]" src="../../../../../assets/images/new_branding/logo_color.svg" alt="fundeen" class="cursor-pointer" (click)="navigateTo('portfolio')">
      <div *ngIf="hasRicEnergyLogo" class="border-t border-t-eggplant-100/[0.1] flex items-center justify-center">
        <img class="!h-[56px] pt-12" src="../../../../../assets/images/new_branding/ricenergy-v3.webp" alt="fundeen" (click)="navigateTo('portfolio')">
      </div>
    </ion-toolbar>
    <ion-toolbar *ngIf="opened" class="w-[184px]">
      <img src="../../../../../assets/images/new_branding/imagotipo_color.svg" alt="fundeen" class="cursor-pointer" (click)="navigateTo('portfolio')">
      <div *ngIf="hasRicEnergyLogo" class="border-t border-t-eggplant-100/[0.1]">
        <img src="../../../../../assets/images/new_branding/ricenergy.webp" alt="fundeen" class="cursor-pointer" (click)="navigateTo('portfolio')">
      </div>
    </ion-toolbar>
  </ion-header>
  <div class="pt-80 pb-64 flex" >
    <app-avatar (click)="navigateTo('my-profile')" class="cursor-pointer" *ngIf="nameUser && nameUser !== null && nameUser !== undefined"></app-avatar>
    <div *ngIf="opened" class="self-center">
      <p class="font-sans text-xl text-eggplant-80 pl-8 cursor-pointer" (click)="navigateTo('my-profile')" *ngIf="nameUser && nameUser !== null && nameUser !== undefined">{{ nameUser }}</p>
      <p class="font-sans text-xl text-eggplant-80 pl-8 cursor-pointer" (click)="completeProfile()" *ngIf="nameUser === '' ">{{ 'REGISTER.MY_PROFILE.COMPLETE_PROFILE' | translate }}</p>
    </div>
  </div>
  <div class=" flex flex-col h-full">
    <div *ngFor="let option of menuList">
      <div (click)="navigateTo(option.path)" class="flex mb-12 cursor-pointer rounded-2xl"  [ngClass]="{'linear-gradient-2': option.selected, 'no-selected': !option.selected}">
        <div>
          <div class="self-center" >
            <fun-sidemenu-icon [icon]="option.icon" [selected]="option.selected"></fun-sidemenu-icon>
          </div>
        </div>
        <div *ngIf="opened" class="self-center">
          <p class="font-sans text-h300 pl-12" [ngClass]="{'text-eggplant-100': !option.selected, 'text-white': option.selected}" >{{ option.title }}</p>
        </div>
      </div>
    </div>
    <div class="absolute bottom-0 left-[14px]">
      <div class="flex">
        <img src="../../../../../assets/images/new_branding/settings.svg" alt="fundeen" class="cursor-pointer" (click)="navigateTo('configuration')"/>
        <div *ngIf="opened" class="self-center w-full">
          <p (click)="navigateTo('configuration')" class="pl-[14px] text-eggplant-80 font-sans text-h300 cursor-pointer">{{ 'MENU.CONFIGURATION' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
