import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';

@Component({
  selector: 'fun-new-button',
  templateUrl: './new-button.component.html',
  styleUrls: ['./new-button.component.scss'],
})
export class NewButtonComponent implements OnInit, OnChanges {
  @Input('buttonType') buttonType;
  @Input('title') title;
  @Input('id') id;
  @Input('span') span;
  @Input('size') size = 'xl';
  @Input() icon;
  @Input() iconPosition;
  @Input() iconAnimate;
  @Input() filterIcon;
  @Input() classExtra = ' ';
  @Input() count: number;
  @Input() showCount: boolean;
  @Input() responsive: boolean = true;
  @Output() private eventClick = new EventEmitter<void>();

  rwd: string;
  disabled: boolean;

  btnStyles = {
    'base': 'group flex items-center justify-between w-fit whitespace-nowrap transition-all duration-500 font-sansMedium uppercase rounded hover:cursor-pointer overflow-hidden',
    'ghost': 'text-eggplant-100 hover:bg-eggplant-100/[0.1] active:bg-eggplant-100/[0.25] duration-75',
    'ghost-disabled': 'text-eggplant-100/[.4] hover:cursor-not-allowed',
    'colorFull': 'colorFull text-white shadow-dimension duration-75',
    'colorFull-disabled': 'colorFull-disabled text-white hover:cursor-not-allowed',
    'primaryDark': 'border border-eggplant-100 text-cream-100 bg-eggplant-100 shadow-dimension hover:bg-eggplant-80 hover:border-eggplant-80 duration-75',
    'primaryDark-disabled': 'border border-eggplant-100/[.4] text-cream-100/[.4] bg-eggplant-100/[.4] hover:cursor-not-allowed',
    'primaryLight': 'border border-orange-100 text-cream-100 bg-orange-100 shadow-dimension hover:bg-orange-120 hover:border-orange-120 duration-75',
    'primaryLight-disabled': 'border border-orange-100/[.4] text-cream-100/[.4] bg-orange-100/[.4] hover:cursor-not-allowed',
    'secondary': 'border border-cream-100 text-eggplant-100 bg-cream-100 shadow-dimension hover:bg-cream-120 hover:border-cream-120 duration-75',
    'secondary-disabled': 'border border-cream-100/[.4] text-eggplant-100/[.4] bg-cream-100/[.4] hover:cursor-not-allowed',
    'secondaryLight': 'border border-cream-100 text-cream-100 drop-shadow-dimension hover:bg-cream-100 hover:bg-opacity-20 duration-75',
    'secondaryLight-disabled': 'border border-cream-100/[.4] text-cream-100/[.4] hover:cursor-not-allowed',
    'secondaryDark': 'border border-eggplant-100 text-eggplant-100 drop-shadow-dimension hover:bg-eggplant-100 hover:bg-opacity-10 focus:bg-eggplant-100 focus:bg-opacity-10 duration-75',
    'secondaryDark-disabled': 'border border-eggplant-100/[.25] text-eggplant-100/[.25] opacity-25 hover:cursor-not-allowed',
    'ricEnergyLight': 'border border-[#0f5e42] text-[#0f5e42] bg-white shadow-dimension hover:bg-[#0f5e42]/[0.8] hover:border-[#0f5e42]/[0.8] duration-75',
    'ricEnergyDark': 'border border-[#0f5e42] text-white bg-[#0f5e42] shadow-dimension hover:bg-[#0f5e42]/[0.8] hover:border-[#0f5e42]/[0.8] duration-75',
    'success': 'border border-success text-white bg-success shadow-dimension hover:bg-opacity-85 duration-75',
    'success-disabled': 'border border-success/[.1] text-success bg-success/[.1] hover:cursor-not-allowed',
    'alert': 'border border-alert text-white bg-alert shadow-dimension hover:bg-opacity-85 duration-75',
    'alert-disabled': 'border border-alert/[.1] text-alert bg-alert/[.1] hover:cursor-not-allowed',
    '2xl': 'gap-8 text-2xl py-8 pr-10 pl-12',
    'xl': 'gap-8 text-xl py-8 px-10',
    'l': 'gap-8 text-l py-8 px-10',
    'm': 'gap-8 text-m py-8 px-10',
    's': 'gap-6 text-s py-6 pr-6 pl-8',
    '2xl-responsive': 'gap-8 text-m md:text-2xl py-8 px-10 md:pr-10 md:pl-12',
    'xl-responsive': 'gap-8 text-m md:text-xl py-8 px-10',
    'l-responsive': 'gap-8 text-l py-8 px-10',
    'm-responsive': 'gap-6 md:gap-8 text-m py-8 px-10',
    's-responsive': 'gap-6 text-s py-6 pr-6 pl-8',
  };

  textStyles = {
    'base': 'w-full text-center ',
    '2xl': 'px-12',
    'xl': 'px-8',
    'l': 'px-8',
    'm': 'px-8',
    's': 'px-8',
    '2xl-left': 'px-12',
    'xl-left': 'px-8',
    'l-left': 'px-8',
    'm-left': 'px-8',
    's-left': 'px-8',
    '2xl-right': 'px-12',
    'xl-right': 'px-8',
    'l-right': 'px-8',
    'm-right': 'px-8',
    's-right': 'px-8',
  };

  iconStyles = {
    'base': 'stroke-2',
    'ghost': 'stroke-eggplant-100',
    'colorFull': 'stroke-white',
    'colorFull-disabled': 'stroke-white/[0.4]',
    'primaryDark': 'stroke-cream-100',
    'primaryDark-disabled': 'stroke-cream-100/[0.4]',
    'primaryLight': 'stroke-cream-100',
    'primaryLight-disabled': 'stroke-cream-100/[0.4]',
    'secondary': 'stroke-eggplant-100',
    'secondary-disabled': 'stroke-eggplant-100/[0.4]',
    'secondaryLight': 'stroke-cream-100',
    'secondaryLight-disabled': 'stroke-cream-100/[0.4]',
    'secondaryDark': 'stroke-eggplant-100',
    'secondaryDark-disabled': 'stroke-eggplant-100/[0.4]',
    'ricEnergyLight': 'stroke-[#0f5e42]',
    'ricEnergyDark': 'stroke-white',
    '2xl': 'w-40 h-40',
    'xl': 'w-32 h-32',
    'l': 'w-28 h-28',
    'm': 'w-24 h-24',
    's': 'w-24 h-24',
    '2xl-responsive': 'w-24 h-24 md:w-40 md:h-40',
    'xl-responsive': 'w-24 h-24 md:w-32 md:h-32',
    'l-responsive': 'w-28 h-28',
    'm-responsive': 'w-24 h-24',
    's-responsive': 'w-24 h-24',
    'left': 'group-hover:animate-slideToLeft',
    'right': 'group-hover:animate-slideToRight',
    'topRight': 'group-hover:animate-slideToTopRight',
    'bottom': 'group-hover:animate-slideToBottom',
    'top': 'group-hover:animate-slideToTop'
  };

  btnClass: string;
  textClass: string;
  iconClass: string;
  type: string;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.type = this.buttonType;
    this.setBtnStyles();
    this.chdRef.detectChanges();
  }

  ngOnChanges() {
    this.type = this.buttonType;
    this.setBtnStyles();
    this.chdRef.detectChanges();
  }

  setBtnStyles() {
    let btnShadow;
    const type = this.type;
    if (
      type.indexOf('disabled') > -1
      ) {
        this.disabled = true;
        btnShadow = '';
      } else {
        this.disabled = false;
        btnShadow = 'transition-all duration-500 rounded focus:shadow-dimensionFocus';
      }
      const textGap = (this.iconPosition !== '') ? this.textStyles['base'] + this.textStyles[this.size + '-' + this.iconPosition] : this.textStyles['base'] + this.textStyles[this.size];
      this.rwd = this.responsive ? '-responsive' : '';
      this.btnClass = `${this.btnStyles['base']} ${this.btnStyles[this.type]} ${this.btnStyles[this.size + this.rwd]} ${btnShadow} ${this.classExtra}`;
      this.textClass = `${textGap}`;
      this.iconClass = `${this.iconStyles['base']} ${this.iconStyles[this.type]} ${this.iconStyles[this.size + this.rwd]} ${this.iconStyles[this.iconPosition]} ${this.iconStyles[this.iconAnimate]}`;
  }

  clickBtn() {
    if (!this.disabled) {
      this.eventClick.emit();
    }
  }

  changeType(type) {
    this.type = type;
    this.setBtnStyles();
  }

}
