import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Profile } from 'src/app/shared/models/register.models';

@Component({
  selector: 'app-register-company-specific-partner-doc',
  templateUrl: './register-company-specific-partner-doc.component.html',
  styleUrls: ['./register-company-specific-partner-doc.component.scss'],
})
export class RegisterCompanySpecificPartnerDocComponent implements OnInit {

  @Input() profile: Profile;
  @Input() partner: string;
  @Output() public setSpecificDocumentOwnerEvent = new EventEmitter<any>();
  @Output() public deleteSpecificDocumentOwnerEvent = new EventEmitter<any>();
  isMobileApp = undefined;


  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {}

  getIdentityDocumentPartner(value) {
    this.setSpecificDocumentOwnerEvent.emit(value);
    this.chdRef.checkNoChanges();
  }

  errorFile(value) {
    this.deleteSpecificDocumentOwnerEvent.emit();
    this.chdRef.checkNoChanges();
  }

}
