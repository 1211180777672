import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-overview-meeting-documents',
  templateUrl: './overview-meeting-documents.component.html',
  styleUrls: ['./overview-meeting-documents.component.scss'],
})
export class OverviewMeetingDocumentsComponent implements OnInit {

  @Input() public documents;
  btnIcon: string;

  constructor(public chdRef: ChangeDetectorRef, private utils: UtilsService) { }

  ngOnInit() {
    this.btnIcon = Capacitor.getPlatform() !== 'web' ? 'eye' : 'download';
    this.chdRef.detectChanges();
  }

  downloadDocument(doc) {
    if (Capacitor.getPlatform() !== 'web') {
      this.utils.viewdFile(doc['document']);
    } else {
      this.utils.downloadFile(doc.document.data.attributes.url, doc.document.data.attributes.ext, doc.title);
    }
    this.chdRef.detectChanges();
  }

}
