import { Component, ViewChild, OnInit, AfterViewInit, HostListener, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';

@Component({
  selector: 'app-income-graphic',
  templateUrl: './income-graphic.component.html',
  styleUrls: ['./income-graphic.component.scss'],
})
export class IncomeGraphicComponent implements OnInit, OnChanges {

  @Input() public data;
  @Input() public filter;
  @Input() public date;
  @Input() public startDate;
  @Input() public moneyToday;
  @Input() public moneyEstimated;

  public context: CanvasRenderingContext2D;
  labelList = [];
  chart: any;
  ctx: any;

  public dataIndex = 0;
  public dataIndexDrill = 0;

  incomesEstimateArray: any;
  incomesArray : any;

  constructor( public chdRef: ChangeDetectorRef ) {}

  ngOnInit() {
    this.incomesEstimateArray = this.data.map(item => item.data_estimate);
    this.incomesArray = this.data.map(item => item.data);
    this.labelList = this.data.map(item => item.label);
    this.createGrap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['data'] && !changes['data'].firstChange) {
      this.filter = 'year';
      this.incomesEstimateArray = this.data.map(item => item.data_estimate);
      this.incomesArray = this.data.map(item => item.data);
      this.labelList = this.data.map(item => item.label);
      this.chart.destroy();
      this.createGrap();
    }
  }

  createGradient(startColor: string, endColor: string): CanvasGradient {
    const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
    const gradient = ctx.createLinearGradient(0, 0, 0, 180); // Modifica las dimensiones del gradiente
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1, endColor);
    return gradient;
  }

  createGrap() {
    const gradient1 = this.createGradient('rgba(255, 92, 47, 0.3)', 'rgba(255, 92, 47, 0.85)'); // Gradiente para el segundo conjunto de datos
    const gradient2 = this.createGradient('rgba(255, 205, 62, 0.3)', 'rgba(255, 255, 255, 0.3)'); // Gradiente para el primer conjunto de datos
    const animation: _DeepPartialObject<any> = this.getAnimation();
    Chart.register(...registerables);
    const config: ChartConfiguration = {
      type: 'bar',
      data: {
        labels: this.labelList,
        datasets: [
          {
            type: 'bar',
            label: 'Reales (€)',
            data: this.incomesArray, //this.mockPoints,
            borderColor: '#FF6C3E', // Los naranjas #FF679B #FF5C2F (mejor) #FF6C3E
            backgroundColor: gradient1,
            borderRadius: 18,
            borderWidth: 0,
            barPercentage: 1,
            categoryPercentage: 0.6,
            borderSkipped: false
          },
          {
            type: 'line',
            label: 'Estimados (€)',
            data: this.incomesEstimateArray, //this.mockPoints2,
            borderColor: '#FFCD3E', // Los amarillos de #FFCD3E a #FFF1BA ,
            backgroundColor: gradient2,
            borderWidth: 2,
            pointRadius: 0,
            fill: true
          }
        ]
      },
      options: {
        animation,
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom'
          },
          tooltip: {
            mode: 'index'
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        scales: {
          y: {
            display: true,
            grid: {
              display: false
            }
          },
          x: {
            display: true,
            grid: {
              display: false
            }
          }
        },
        locale: 'de-DE',
        onClick: (event, elements) => {
          this.handleChartClick(event, elements);
        }
      }
    }
    const chartItem: ChartItem = document.getElementById('myChart') as ChartItem;
    this.chart = new Chart(chartItem, config);
    this.chdRef.detectChanges();
  }

  handleChartClick(event, elements): void {
    if (elements.length > 0 && this.filter != 'day') {
      // Ejemplo de cómo podrías hacerlo:
      const dataIndexChart = elements[0].index;
      const label = this.chart.data.labels[dataIndexChart];

      let type = '';
      let newData = [];
      if(label.length > 3 && this.filter != 'day') {
        // Gestionamos botones y label
        type = 'day';
        this.dataIndexDrill = dataIndexChart;
        newData = this.data[this.dataIndex].data_days[this.dataIndexDrill].data_hours; // Datos para el "drilldown"
      } else {
        type = 'month';
        this.dataIndex = dataIndexChart;
        newData = this.data[this.dataIndex].data_days; // Datos para el "drilldown"
      }

      const incomesEstimateArray = newData.map(item => item.data_estimate);
      const incomesArray = newData.map(item => item.data);
      const labelsList = newData.map(item => item.label);

      this.chart.data.labels = labelsList;
      this.chart.data.datasets[0].data = incomesArray;
      this.chart.data.datasets[1].data = incomesEstimateArray;

      const dataChangeGraphic = {
        graphic: 'income',
        type: type,
        label: label,
        total: incomesArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
        totalEstimate: incomesEstimateArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      }
      this.changeDate(dataChangeGraphic);

      this.drillUp();
      this.chart.update();
    }
  }

  drillUp() {
    const drillUp = document.getElementById('btnResetIncome');
    drillUp.addEventListener('click', (evt) => {
      this.chart.destroy();
      const dataChangeGraphic = {
        graphic: 'income',
        type: 'year',
        label: '',
        total: this.incomesArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0),
        totalEstimate: this.incomesEstimateArray.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      }
      this.changeDate(dataChangeGraphic);
      this.createGrap();
    });
  }

  changeDate(data) {
    const type = data.type;
    this.filter = type;
    switch(type) {
      case 'year':  this.date = this.parseCurrentYear(this.startDate); break;
      case 'month': this.date = (data.label !== undefined) ? `${data.label}/${this.parseCurrentYear(this.startDate)}` : `${this.parseCurrentMonth(this.startDate)}/${this.parseCurrentYear(this.startDate)}`; break;
      case 'day': this.date = (data.label !== undefined) ? data.label : 'SD'; break;
    }
    this.moneyToday = data.total; this.moneyEstimated = data.totalEstimate;
    this.chdRef.detectChanges();
  }

  parseCurrentMonth(fecha: string): string {
    const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    // Dividir la fecha en año, mes y día
    const [ano, mes, dia] = fecha.split('-');

    // Crear un nuevo objeto de fecha con los componentes extraídos
    const fechaObj = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));

    // Obtener los componentes de la nueva fecha
    const nuevoMes = fechaObj.getMonth(); // Nota: los meses comienzan desde 0

    // Formatear los componentes en el formato deseado
    const formatoDeseado = `${months[nuevoMes]}`;

    return formatoDeseado;
  }

  parseCurrentYear(fecha: string): string {
    // Dividir la fecha en año, mes y día
    const [ano, mes, dia] = fecha.split('-');

    // Crear un nuevo objeto de fecha con los componentes extraídos
    const fechaObj = new Date(parseInt(ano), parseInt(mes) - 1, parseInt(dia));

    // Obtener los componentes de la nueva fecha
    const nuevoAno = fechaObj.getFullYear(); // Obtener solo los últimos dos dígitos del año

    // Formatear los componentes en el formato deseado
    const formatoDeseado = `${nuevoAno.toString()}`;

    return formatoDeseado;
  }

  getAnimation() {
    const totalDuration = 1000;
    const delayBetweenPoints = totalDuration / this.labelList.length;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
    return {
      x: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: previousY,
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0;
          }
          ctx.yStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      }
    };
  }

}
