import {
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Renderer2,
  ChangeDetectorRef,
  AfterViewInit,
  EventEmitter,
  Output} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { ModalService } from './modal.service';

@Component({
  selector: 'fun-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('componentPlaceholder', { read: ViewContainerRef, static: true }) componentPlaceholder: ViewContainerRef;
  @Output() public closeEvent = new EventEmitter<any>();
  @Output() public notCloseEvent = new EventEmitter<any>();

  public ref: ComponentRef<ModalComponent>;
  childComponentRef$: Observable<ComponentRef<any>>;
  private close$: ReplaySubject<any>;
  title: string;
  status: string;
  lostTagName: string;
  offer_status: string;
  enableCloseOut = false;
  closeOut = 'true';
  keepOpen = false;
  hideButton = false;
  reduceHeightDefault = false;
  public enableTransitionUp = true;
  public enableTransitionDown = true;
  public closeProccess = false;

  isIos = false;

  constructor(
    private renderer: Renderer2,
    private chdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private platform: Platform
  ) {
    this.close$ = new ReplaySubject();
  }

  ngOnInit() {
    this.renderer.addClass(document.body, 'pop');
    this.isIos = this.platform.is('ios');
  }
  ngAfterViewInit() {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribe(() => {
        this.modalService.removeModal();
      });
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'pop');
  }

  onClose(): Observable<any> {
    return this.close$.asObservable();
  }

  setTitle(title: string): void {
    this.title = title;
    this.chdRef.detectChanges();
  }

  hideButtonClose(hideButton) {
    this.hideButton = hideButton;
    this.chdRef.detectChanges();
  }

  setStatus(status: string): void {
    this.status = status;
    this.chdRef.detectChanges();
  }
  setLostTagName(name) {
    this.lostTagName = name;
    this.chdRef.detectChanges();
  }
  setOfferstatus(status) {
    this.offer_status = status;
    this.chdRef.detectChanges();
  }
  changeOpenStatus(status: boolean) {
    this.keepOpen = status;
  }
  closeM() {
    if (!this.keepOpen) {
      this.closeEvent.emit();
      this.modalService.removeModal();
    }
  }
  close(externalClose: boolean = false) {
    if (!this.keepOpen) {
      this.closeEvent.emit();
      this.modalService.removeModal();
    } else {
      this.notCloseEvent.emit();
    }
  }

  getChildComponent<T>(): Observable<T> {
    return this.childComponentRef$.pipe(map((component: ComponentRef<T>) => component.instance));
  }

  reduceHeight() {
    if (this.hideButton) {
      this.reduceHeightDefault = true;
    }
    this.chdRef.detectChanges();
  }

  disableTransitionUp() {
    this.enableTransitionUp = false;
    this.chdRef.detectChanges();
  }

  disableTransitionDown() {
    this.enableTransitionDown = false;
    this.chdRef.detectChanges();
  }

  enableCloseProccess() {
    this.closeProccess = true;
    this.chdRef.detectChanges();
  }

}
