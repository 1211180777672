<div [ngClass]="{'bg-ric' : isFlexibleCompensation, 'linear-gradient-2' : !isFlexibleCompensation}"
class=" px-16 md:px-[42px] pt-[30px] pb-[102px] md:pt-80 md:pb-[74px] content-bg linear-gradient-2" >
  <div class="md:grid grid-cols-4 md:grid-cols-14 md:gap-x-32 lg:gap-x-32 place-content-center relative">
    <div class="col-span-4 md:col-span-6 md:col-start-4 inline-block">
      <p class="text-white font-sansBold md:font-sans text-h600 md:text-h800">{{ 'OVERVIEW_PROJECT.CALCULATE_B' | translate }}</p>
    </div>
    <div class="md:col-start-11 md:col-span-1 self-center inline-block">
      <fun-icon [name]="'percent'" [style]="'w-[52.67px] h-[52.67px] stroke-white'"></fun-icon>
    </div>
    <div class="col-span-4 md:col-start-4 mt-8 md:pt-[56px]">
      <p class=" font-sans text-h300 text-white pb-20 md:pb-32">{{ 'OVERVIEW_PROJECT.INTRODUCE' | translate }}</p>
      <div class="w-full flex items-center">
        <fun-input placeholder="5000" [parentForm]="cashForm" [formControlKeys]="formControlKeys"
        [errorMessages]="errorMessages" [isSubmit]="isSubmit" controlName="money" type="invest"
        [errorLogin]="errorLogin" #inputInvest classExtra="!rounded-r-none"></fun-input>
        <fun-new-button [size]="'l'" [buttonType]="inputType" (eventClick)="calculate(true)" title="Calcular" [classExtra]="' !w-full !rounded-l-none '"></fun-new-button>
      </div>
    </div>
    <div class="col-start-2 col-span-8 md:col-start-9 md:col-span-3 mt-20 md:mt-56 py-16 px-32 border border-cream-100 rounded-lg">
      <div class="pb-16 children:font-sans children:text-l children:text-cream-100 children:inline-block">
        <p>{{ 'OVERVIEW_PROJECT.INITIAL_INV' | translate }}</p>
        <span *ngIf="!loadingCalendarInfo" class="float-right">{{ benefits.inversion | number:'1.0-0':'es' }} €</span>
        <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 30%" animated></ion-skeleton-text>
      </div>
      <div class="pb-16 children:font-sans children:text-l children:text-cream-100 children:inline-block">
        <p>{{ 'dashboard-card.Benefits' | translate }}</p>
        <span *ngIf="!loadingCalendarInfo" class="float-right">{{ benefits.reem | number:'1.0-0':'es' }} €</span>
        <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 30%" animated></ion-skeleton-text>
      </div>
      <div class="border border-white "></div>
      <div class="children:font-sans pt-16 children:text-xl children:text-white children:uppercase children:inline-block">
        <p>TOTAL </p>
        <span class="float-right" *ngIf="!loadingCalendarInfo">{{ benefits.total | number:'1.0-0':'es' }} €</span>
        <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 30%" animated></ion-skeleton-text>
      </div>
    </div>
    <div *ngIf="hasInfo" class="col-start-2 col-span-4 md:col-start-4 pt-78 pb-24 hidden md:block">
      <p class="font-sans text-h600 text-white">{{ 'CALENDAR.RETURN_TIMES' | translate }}</p>
    </div>
    <div *ngIf="hasInfo" class="col-start-2 col-span-8 md:col-start-4 mt-32 md:mt-0">
      <div *ngIf="period !== null && calendarInfo && calendarInfo.length > 15" class="p-12">
        <p class="font-sans text-xl text-cream-100">{{ 'CALENDAR.PEDIOD_TEXT' | translate }} <span class="lowercase"> {{ period }}</span>.</p>
      </div>
      <div class="grid frid-cols-4 md:grid-cols-8 md:place-content-center py-[16px] children:uppercase
        children:text-white children:font-sans children:text-h200 md:children:text-h300">
        <p class="col-span-1 col-start-1 md:col-span-2 pl-[16px]">{{ 'CALENDAR.PAYMENT_DATE' | translate }}</p>
        <p class="col-span-1 col-start-2 md:col-start-3 md:col-span-2 text-right">
          <span *ngIf="type === 2">{{ 'CALENDAR.AVERAGE_INTEREST' | translate }}</span>
          <span *ngIf="type !== 2">{{ 'DASHBOARD.COMPONENTS.PORTFOLIO_CARD.BENEFITS' | translate }}</span>
        </p>
        <p class="col-span-1 col-start-3 md:col-start-5 md:col-span-2 text-right">
          <span *ngIf="type === 2">{{ 'CALENDAR.AMORTIZATION' | translate }}</span>
          <span *ngIf="type !== 2">{{ 'CALENDAR.CAP_RETURN' | translate }}</span>
        </p>
        <p class="col-span-1 col-start-4 md:col-start-7 md:col-span-2 pr-[16px] text-right">Total</p>
      </div>

      <div *ngIf="hasInfo" class="rounded rounded-xl">
        <div *ngIf="hasPartialCalendar !== true">
          <div *ngFor="let item of calendarInfo; let i = index" class="grid md:grid-cols-8 md:place-content-center py-16 children:text-eggplant-80
          children:text-m md:children:text-l children:font-sans border-ocher-100 border-opacity-[0.25] border-t-[1px] bg-cream-25"
          [ngClass]="{'rounded-t-xl': i === 0 }">
            <p class="col-span-2 pl-12 md:pl-16 capitalize">{{ item.period }}</p>
            <p class="col-start-3 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.profitOrInterest | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-5 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.sharePremiumOrRedemption | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-7 col-span-2 text-right md:pr-16 pr-12">
              <span *ngIf="!loadingCalendarInfo">{{item.amount | number:'1.0-0':'es' }} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
          </div>
        </div>

        <div *ngIf="hasPartialCalendar === true">
          <div *ngFor="let item of partialCalendar.prev; let i = index" class="grid md:grid-cols-8 md:place-content-center py-16 children:text-eggplant-80
          children:text-m md:children:text-l children:font-sans border-ocher-100 border-opacity-[0.25] border-t-[1px] bg-cream-25"
          [ngClass]="{'rounded-t-xl': i === 0 }">
            <p class="col-span-2 pl-12 md:pl-16 capitalize">{{ item.period }}</p>
            <p class="col-start-3 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.profitOrInterest | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-5 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.sharePremiumOrRedemption | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-7 col-span-2 text-right md:pr-16 pr-12">
              <span *ngIf="!loadingCalendarInfo">{{item.amount | number:'1.0-0':'es' }} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
          </div>
          <div class="w-full bg-cream-25 border-ocher-100 border-opacity-[0.25] border-t-[1px]">
            <div class="flex justify-center items-center gap-6 cursor-pointer" (click)="viewAll()">
              <fun-icon [name]="'plus-circle'" [style]="'w-20 h-20 stroke-2 stroke-eggplant-80'" ></fun-icon>
              <p class="font-sans py-20 text-center text-eggplant-80 text-m">{{ 'CALENDAR.LOAD_ALL' | translate }}</p>
            </div>
          </div>

          <div *ngFor="let item of partialCalendar.next; let i = index" class="grid md:grid-cols-8 md:place-content-center py-16 children:text-eggplant-80
          children:text-m md:children:text-l children:font-sans border-ocher-100 border-opacity-[0.25] border-t-[1px] bg-cream-25">
            <p class="col-span-2 pl-12 md:pl-16 capitalize">{{ item.period }}</p>
            <p class="col-start-3 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.profitOrInterest | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-5 col-span-2 text-right">
              <span *ngIf="!loadingCalendarInfo">{{ item.sharePremiumOrRedemption | number:'1.0-0':'es'}} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
            <p class="col-start-7 col-span-2 text-right md:pr-16 pr-12">
              <span *ngIf="!loadingCalendarInfo">{{item.amount | number:'1.0-0':'es' }} €</span>
              <ion-skeleton-text class="float-right" *ngIf="loadingCalendarInfo" style="width: 62px; height: 90%;" animated></ion-skeleton-text>
            </p>
          </div>
        </div>

      </div>

      <div class="md:grid md:grid-cols-8 place-content-center p-[16px] bg-cream-100 rounded-b-lg">
        <p class="col-span-3 self-center text-eggplant-70 text-l font-sans md:pl-[16px]">{{ 'AUTOINVEST.STEP_2.RENT_ESTIM' | translate }} {{ profitability | number:'1.0-2':'es' }}%</p>
        <p class="col-start-6 col-span-4 font-sansMedium text-l md:text-xl text-right text-eggplant-80 pr-[16px] flex">{{ 'CALENDAR.FULL_REFOUND' | translate }}
          <span *ngIf="!loadingCalendarInfo" class="pl-12">{{ benefits.total | number:'1.0-0':'es' }} €</span>
          <ion-skeleton-text class="float-right pl-12" *ngIf="loadingCalendarInfo" style="width: 20%; height: 30px;" animated></ion-skeleton-text>
        </p>
      </div>
    </div>
    <div class="pt-64 col-start-5 col-span-6">
      <p class="text-center font-sans text-white text-h600 md:text-h700 mb-24"></p>
      <div class="block m-auto w-fit">
        <fun-overviiew-status-avatars [investors]="investors" [version]="'light'"></fun-overviiew-status-avatars>
      </div>
    </div>
    <div class="pt-[34px] col-span-2 col-start-7 pb-120 md:pb-0" *ngIf="status !== 3">
      <fun-new-button [size]="'xl'" [buttonType]="'secondary'" (eventClick)="invest()" title="{{ 'INVEST.INVEST' | translate }}" [classExtra]="' !w-full '"></fun-new-button>
    </div>
  </div>

</div>
