import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../toast/toast.service';

@Component({
  selector: 'fun-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  @Output() public submitPwdEvent = new EventEmitter<any>();
  @Output() public forgotPwdEvent = new EventEmitter<any>();

  public passwordForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorMail = false;
  public errorPwd1 = false;
  public errorPwd2 = false;
  public errorPwd3 = false;

  loading = false;
  btnType = 'primaryDark';

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.passwordForm = new FormGroup({
      pwd1: new FormControl('', [Validators.required]),
      pwd2: new FormControl('', [Validators.required]),
      pwd3: new FormControl('', [Validators.required]),
    });
  }

  isLoading(value: boolean) {
    this.loading = value;
    this.chdRef.detectChanges();
  }

  submit() {
    if (this.passwordForm.status === 'VALID' && this.passwordForm.controls.pwd2.value === this.passwordForm.controls.pwd3.value) {
      const params = {
        odlPwd: this.passwordForm.controls.pwd1.value,
        newPwd: this.passwordForm.controls.pwd2.value,
        repeatPwd: this.passwordForm.controls.pwd3.value
      };
      this.errorPwd2 = false;
      this.errorPwd3 = false;
      this.btnType = 'primaryDark-disabled';
      this.submitPwdEvent.emit(params);
    } else if (this.passwordForm.controls.pwd2.value !== this.passwordForm.controls.pwd3.value) {
      this.errorPwd2 = true;
      this.errorPwd3 = true;
    }
    if (this.passwordForm.controls.pwd2.value !== this.passwordForm.controls.pwd3.value) {
      this.toastService.showToast(this.translate.instant('pwd.not_same_pwd'), 'warning', true);
    }
    this.chdRef.detectChanges();
  }

  incorrectPassword() {
    this.btnType = 'primaryDark';
    this.toastService.showToast('Error.', 'warning', true);
    this.chdRef.detectChanges();
  }

  forgotPwd() {
    this.forgotPwdEvent.emit();
  }

}
