<div class="general-container">
  <fun-spinner *ngIf="loading"></fun-spinner>

  <div class="relative !p-[32px]" *ngIf="isInvestor">
    <div class="row flex gap-12" *ngIf="platform !== 'android'">
        <img class="appear cursor-pointer" (click)="downloadList()" *ngIf="!downloadedXlsx" src="../../../../../assets/images/img/download.svg" alt="">
        <img class="appear cursor-pointer" *ngIf="downloadedXlsx" src="../../../../../assets/images/img/download-ok.svg" alt="">
        <p class="font-sans text-l pl-12 text-eggplant-100/[0.4]">{{ 'MOVEMENTS.DOWNLOAD_LIST' | translate }}</p>
    </div>
  </div>

  <div class="appear lst rounded-2xl bg-white" *ngIf="isInvestor === true && filterFound && !loading">
    <div *ngFor="let movement of movements; let i = index">
        <div class="flex w-full">
            <div class="flex items-center place-content-center !px-10 md:!px-20">
                <fun-icon *ngIf="movement.amount > 0" [name]="'arrow-trendingup'" [style]="'w-24 h-24 md:w-32 md:h-32 stroke-[2px] stroke-[#39C847]'"></fun-icon>
                <fun-icon *ngIf="movement.amount <= 0" [name]="'arrow-trendingdown'" [style]="'w-24 h-24 md:w-32 md:h-32 stroke-[2px] stroke-eggplant-100'"></fun-icon>
            </div>
            <div class="w-full flex justify-between full-row" [ngClass]="{'border-b border-b-eggplant-100/[0.1]': i < (movements.length - 1) }">
                <div class="w-[70%] md:w-[65%]">
                    <p class="font-sans text-l md:text-2xl text-eggplant-100">{{ movement.movementDefinition }} <span class="w-fit whitespace-nowrap">( {{ movement.dateParse }} )</span></p>
                    <p class="font-sans text-s md:text-l text-eggplant-100/[0.55]">{{ movement.description }}</p>
                </div>
                <div class="!pr-[16px] w-[30%] md:w-[35%]">
                    <p [ngClass]="{ 'text-[#39C847]': movement.amount > 0}" class="text-right font-sans text-xl md:text-2xl text-eggplant-100">
                        <span class="w-fit whitespace-nowrap">{{ movement.amount | localeNumber }} €</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="flex justify-center view_more_container items-center" *ngIf="isInvestor === true && filterFound && !loading && !selectedAll">
    <p (click)="viewAll()" class="uppercase font-sansMedium text-m md:text-xl text-eggplant-100 cursor-pointer pr-12">{{ 'INFO.BTN_MSG_BALEARES' | translate }}</p>
    <fun-icon (click)="viewAll()" [name]="'arrow-down-circle'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F] cursor-pointer'"></fun-icon>
  </div>
  <div class="notInvestor appear" *ngIf="isInvestor && !filterFound">
      <img src="../../../../../assets/images/movements/no-movements.svg" alt="notification">
      <p class="font-sans text-eggplant-100 text-h400 text-center">{{ 'MOVEMENTS.MOVEMENT-LIST.NOT-FOUND-TXT' | translate }}</p>
  </div>
  <div class="notInvestor appear" *ngIf="isInvestor === false">
      <img src="../../../../../assets/images/movements/no-movements.svg" alt="notification">
      <p class="font-sans text-eggplant-100 text-h400 text-center">{{ 'MOVEMENTS.MOVEMENT-LIST.TEXT-NO-INVESTOR' | translate }}</p>
  </div>
</div>

