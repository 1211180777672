<div class="general-container">
    <fun-spinner *ngIf="loading"></fun-spinner>
    <div *ngIf="!loading && (hasDocuments || hasSecondaryDocuments)" class="documents-table appear border border-eggplant-100/[0.1] bg-white rounded-2xl">
      <div *ngIf="hasDocuments">
        <div *ngFor="let document of documents" class="content flex bg-white row-table rounded-2xl ">
          <div class="icon-content rounded-2xl">
            <fun-icon [name]="'file'" [style]="'w-32 h-32 stroke-[4px] stroke-[#29141F]'"></fun-icon>
          </div>
          <div (click)="downloadDocument(document)" class="w-full relative rounded-2xl">
              <p *ngIf="document.subtype === 'ANNUAL_REPORT' " class="title text-l md:text-2xl font-sans text-eggplant-100 ">
                {{ 'DOCUMENTATION.ANNUAL_RE' | translate }} </p>
              <p *ngIf="document.subtype === 'WEALTH_CERTIFICATE' " class="title text-l md:text-2xl font-sans text-eggplant-100 ">
                {{ 'DOCUMENTATION.CERTIFICATE' | translate }} </p>
              <p *ngIf="document.subtype === 'INVEST' " class="title text-l md:text-2xl font-sans text-eggplant-100 " [ngClass]="{'project_title': document.project && document.project !== undefined}">
                {{ 'DOCUMENTATION.AGREEMENT' | translate }} </p>
              <p *ngIf="document.subtype === 'ANNUAL_REPORT' || document.subtype === 'WEALTH_CERTIFICATE' "
              class="font-sans text-s md:text-l text-eggplant-100/[0.4]">{{ 'DOCUMENTATION.DOCUMENT' | translate }} ({{ document.taxYear }})</p>
              <p *ngIf="document.subtype === 'INVEST' " class="font-sans text-s md:text-l text-eggplant-100/[0.4]">{{ document.name }} ({{ document.year }})<span *ngIf="document.project">: {{ document.project }}</span></p>

              <div class="btn mob_none ">
                <fun-new-button buttonType="secondaryDark" size="s" title="Descargar" *ngIf="downloadStatus[document.index] === 0 || downloadStatus[document.index] === 2"
                icon="download" iconPosition="right" (eventClick)="downloadDocument(document)"></fun-new-button>
              </div>
          </div>

          <div *ngIf="downloadStatus[document.index] === 1" class="lds-spinner download" [ngClass]="{'proj_icon_spinner': document.project && document.project !== undefined }">
              <div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div>
              <div></div><div></div><div></div><div></div>
          </div>
        </div>
      </div>
      <!---->
      <div *ngIf="hasSecondaryDocuments">
        <div class="">
          <div *ngFor="let document of secondaryDocsLits" class="content flex bg-white row-table rounded-2xl ">
            <div class="icon-content rounded-2xl">
              <fun-icon [name]="'file'" [style]="'w-32 h-32 stroke-[4px] stroke-[#29141F]'"></fun-icon>
            </div>
            <div (click)="viewDocument(document)" class="w-full relative rounded-2xl">
              <p class="title text-l md:text-2xl font-sans text-eggplant-100 ">{{ document.name }}</p>
              <div class="btn mob_none ">
                <fun-new-button buttonType="secondaryDark" size="s" title="Descargar" icon="download" iconPosition="right"></fun-new-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
    </div>

  <div class="notInvestor" *ngIf="!loading && !hasDocuments && !hasSecondaryDocuments">
      <img src="../../assets/images/illustrations/notifications.svg" alt="notification">
      <p>{{ 'DOCUMENTATION.TXT' | translate }}</p>
  </div>
</div>