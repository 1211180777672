<ion-tabs>
  <ion-tab-bar slot="bottom" [ngClass]="{'native-tab': platform === 'ios' }">
    <ion-tab-button (click)="goTo('/list-of-projects')">
      <fun-icon *ngIf="tabSelected === 'investments'" [name]="'investments-filled'" [style]="'w-24 h-24 stroke-[1px]'"></fun-icon>
      <fun-icon *ngIf="tabSelected !== 'investments'" [name]="'investments'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
      <p class="font-sansMedium text-s text-eggplant-100" [ngClass]="{'icon-selected': tabSelected === 'investments' }">{{ 'MENU.INV' | translate }}</p>
    </ion-tab-button>

    <ion-tab-button (click)="goTo('/portfolio')">
      <fun-icon *ngIf="tabSelected === 'portfolio'" [name]="'briefcase-filled'" [style]="'w-24 h-24 stroke-[1px] stroke-[white] fill-[#E54937]'"></fun-icon>
      <fun-icon *ngIf="tabSelected !== 'portfolio'" [name]="'briefcase'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
      <p class="font-sansMedium text-s text-eggplant-100" [ngClass]="{'icon-selected': tabSelected === 'portfolio' }">{{ 'MENU.WALLET' | translate }}</p>
    </ion-tab-button>

    <ion-tab-button (click)="goTo('/movements')">
      <fun-icon *ngIf="tabSelected !== 'movements'" [name]="'coins'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
      <fun-icon *ngIf="tabSelected === 'movements'" [name]="'coins-filled'" [style]="'w-24 h-24 stroke-[1px] stroke-[white] fill-[#E54937]'"></fun-icon>
      <p class="font-sansMedium text-s text-eggplant-100" [ngClass]="{'icon-selected': tabSelected === 'movements' }">{{ 'MENU.MOVEMENTS' | translate }}</p>
    </ion-tab-button>

    <ion-tab-button (click)="goTo('/help')">
      <fun-icon *ngIf="tabSelected === 'help'" [name]="'help-circle-filled'" [style]="'w-24 h-24 stroke-[1px] stroke-[white] fill-[#E54937]'"></fun-icon>
      <fun-icon *ngIf="tabSelected !== 'help'" [name]="'help-circle'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
      <p class="font-sansMedium text-s text-eggplant-100" [ngClass]="{'icon-selected': tabSelected === 'help' }">{{ 'MENU.HELP' | translate }}</p>
    </ion-tab-button>

    <ion-tab-button (click)="goTo('/my-profile')">
      <fun-icon *ngIf="tabSelected === 'profile'" [name]="'user-filled'" [style]="'w-24 h-24 stroke-[1px] stroke-[#E54937] fill-[#E54937]'"></fun-icon>
      <!-- <ion-icon name="person" class="icon-selected" *ngIf="tabSelected === 'profile'"></ion-icon> -->
      <fun-icon *ngIf="tabSelected !== 'profile'" [name]="'user'" [style]="'w-24 h-24 stroke-[2px] stroke-[#29141F]'"></fun-icon>
      <!-- <ion-icon name="person-outline" *ngIf="tabSelected !== 'profile'"></ion-icon> -->
      <p class="font-sansMedium text-s text-eggplant-100" [ngClass]="{'icon-selected': tabSelected === 'profile' }">{{ name }}</p>
    </ion-tab-button>
  </ion-tab-bar>
</ion-tabs>