import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductionGraphicService } from './production-graphic.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-production-graphic',
  templateUrl: './production-graphic.component.html',
  styleUrls: ['./production-graphic.component.scss'],
})
export class ProductionGraphicComponent implements OnInit {
  @ViewChild('faddingContainer', { static: false }) container: ElementRef;
  @Input('side') side;

  productionBar: string;
  billingBar: string;
  billingEstimatedWidth: string;
  productionEstimatedWidth: string;
  production: any;
  money: any;
  productionestimated: any;
  moneyEstimated: any;
  productionUntilNow: any;
  moneyUntilNow: any;

  idProject: string;
  projectName: string;
  power: string;
  technology: string;
  location: string;

  data: any;
  moment: string;

  constructor(
    private route: ActivatedRoute,
    private productionService: ProductionGraphicService,
    private utils: UtilsService,
    public chdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    registerLocaleData(es);
    this.route.queryParams.subscribe((params) => {
      this.idProject = params['id'];
      this.projectName = params['name'];
      this.power = params['power'];
      this.technology = params['type'];
      this.location = params['location'];

      this.data = {
        name: this.projectName,
        power: this.power,
        technology: this.technology,
        location: this.location,
      };
    });
    this.getMoment('day');
  }

  getMoment(moment) {
    this.animationFading('not');
    this.moment = moment;
    this.productionService.getData(moment, this.idProject).subscribe((resp) => {
      this.animationFading('ok');
      this.production = this.utils.roundNumber(resp.production);
      this.money = this.utils.roundNumber(resp.money);

      this.productionestimated = this.utils.roundNumber(resp.productionEstimate);
      this.moneyEstimated = this.utils.roundNumber(resp.moneyEstimate);

      const prodUntilNow = resp.productionEstimateUntilNow;
      const monetUntilNow = resp.moneyEstimateUntilNow;
      this.productionUntilNow = this.utils.roundNumber(prodUntilNow);
      this.moneyUntilNow = this.utils.roundNumber(monetUntilNow);
      if (this.production > this.productionestimated) {
        this.productionBar = '100%';
        if ((prodUntilNow * 100) / this.production > 100) {
          this.productionEstimatedWidth = '100%';
        } else {
          this.productionEstimatedWidth = (prodUntilNow * 100) / this.production + '%';
        }
      } else {
        this.productionBar = (this.production * 100) / this.productionestimated + '%';
        if ((prodUntilNow * 100) / this.productionestimated > 100) {
          this.productionEstimatedWidth = '100%';
        } else {
          this.productionEstimatedWidth = (prodUntilNow * 100) / this.productionestimated + '%';
        }
      }

      if (this.money > this.moneyEstimated) {
        this.billingBar = '100%';
        if ((monetUntilNow * 100) / this.money > 100) {
          this.billingEstimatedWidth = '100%';
        } else {
          this.billingEstimatedWidth = (monetUntilNow * 100) / this.money + '%';
        }
      } else {
        this.billingBar = (this.money * 100) / this.moneyEstimated + '%';
        if ((monetUntilNow * 100) / this.moneyEstimated > 100) {
          this.billingEstimatedWidth = '100%';
        } else {
          this.billingEstimatedWidth = (monetUntilNow * 100) / this.moneyEstimated + '%';
        }
      }
      this.chdRef.detectChanges();
    });
  }
  animationFading(status) {
    if (status !== 'ok') {
      if (this.container) {
        this.container.nativeElement.setAttribute('class', 'animation-fading');
      }
    } else {
      this.container.nativeElement.removeAttribute('class');
    }
  }
}
