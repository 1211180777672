import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { countryPrefix } from 'src/app/pages/my-profile/phone-prefix';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile, RegisterCompanyMainData, RegisterPersonMainData } from '../../models/register.models';
import { ModalService } from '../modal/modal.service';
import { RegisterCompanyComponent } from './register-company/register-company.component';
import { RegisterPersonLastStepComponent } from './register-person-last-step/register-person-last-step.component';
import { RegisterPersonComponent } from './register-person/register-person.component';
import { RegisterWelcomeComponent } from './register-welcome/register-welcome.component';

@Component({
  selector: 'fundeen-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  @Output() public emitWayEvent = new EventEmitter<any>();
  @Output() public emitLoginEvent = new EventEmitter<any>();
  @Output() public codeNumberEvent = new EventEmitter<any>();
  @Output() public codeEmailEvent = new EventEmitter<any>();
  @Output() public phoneNumberEvent = new EventEmitter<any>();
  @Output() public resendCodeEmailEvent = new EventEmitter<any>();
  @Output() public resendCodeEvent = new EventEmitter<any>();
  @Output() public validateEmailEvent = new EventEmitter<any>();
  @Output() public generateProfilePersonEvent = new EventEmitter<any>();
  @Output() public emitDocumentntationEvent = new EventEmitter<any>();
  @Output() public emitFullAddressEvent = new EventEmitter<any>();
  @Output() public emitPersonalDocsEvent = new EventEmitter<any>();
  @Output() public emitAccreditationChecksEvent = new EventEmitter<any>();
  @Output() public emitBankDocument = new EventEmitter<any>();
  @Output() public acceptLwTermsEvent = new EventEmitter<any>();
  @Output() public finishProccessEvent = new EventEmitter<any>();
  @Output() public otherMomentAccreditEvent = new EventEmitter<any>();
  @Output() public downloadAppEvent = new EventEmitter<any>();
  @Output() public updateInfoEvent = new EventEmitter<any>();
  @Output() public changeEmailEvent = new EventEmitter<any>();
  @Output() public isFinishEvent = new EventEmitter<any>();

  @Output() public updateCompanyAddressEvent = new EventEmitter<any>();
  @Output() public updateConstitutionDocumentEvent = new EventEmitter<any>();
  @Output() public updateRealOwnershipDocumentEvent = new EventEmitter<any>();
  @Output() public updateModel200DocumentEvent = new EventEmitter<any>();
  @Output() public updateIdentityPartnersDocumentsEvent = new EventEmitter<any>();
  @Output() public updateRLDataEvent = new EventEmitter<any>();
  @Output() public updateRLIdentityDocumentEvent = new EventEmitter<any>();
  @Output() public updateProofAddressDocumentEvent = new EventEmitter<any>();
  @Output() public updateAcreditationCompanyChecksEvent = new EventEmitter<any>();
  @Output() public updateAcreditationCompanyDocEvent = new EventEmitter<any>();
  @Output() public acceptLWTermsEvent = new EventEmitter<any>();
  @Output() public continueInvestEvent = new EventEmitter<any>();
  @Output() public updateDocNumberEvent = new EventEmitter<any>();

  @Output() public getSignaturitEvent = new EventEmitter<any>();
  @Output() public signaturitSignedEvent = new EventEmitter<any>();
  @Output() public checkRefCodeEvent = new EventEmitter<any>();

  @Output() public otherMomentEvent = new EventEmitter<any>();
  @Output() public companyProfileGeneratedEvent = new EventEmitter<any>();

  @Input() list: any;
  @Input() isMobile: any;
  @Input() profile: Profile;
  @Input() hasType: Profile;
  @Input() towns: any;
  @Input() specificFileSteps: any;
  @Input() onlyAccredit: any;
  @Input() updateSpecificDocument: boolean;
  @Input() addSpecificNie: boolean;
  @Input() specificPartner: string;

  @ViewChild('registerPerson', { static: false }) registerPerson: RegisterPersonComponent;
  @ViewChild('registerCompany', { static: false }) registerCompany: RegisterCompanyComponent;
  @ViewChild('registerPersonAccredited', { static: false }) registerPersonAccredited: RegisterPersonLastStepComponent;
  @ViewChild('registerDocument', { static: false }) registerDocument: RegisterPersonComponent;
  @ViewChild('registerWellcome', { static: false }) registerWellcome: RegisterWelcomeComponent;

  info: RegisterPersonMainData = {};
  companyInfo: RegisterCompanyMainData = {};
  phoneCode: any;
  phoneNumber: any;

  public step;
  public registerStep;
  public method;
  public lastStep = false;
  public userInfo: any = {};
  public registerPersonSteps: any;
  public registerCompanySteps: any;
  public isRegister = true;
  public registerLoading = false;

  public variabledeprueba: any;

  public idDocument = true;

  public taxC;

  constructor(public chdRef: ChangeDetectorRef, private profileService: ProfileService, private modalService: ModalService) { }

  ngOnInit() {
    this.registerStep = 0;
    if (this.profile === null || this.profile === undefined) {
      this.step = 0;
    } else {
      this.isRegister = false;
      if (this.profile.profileType !== null && this.profile.profileType  !== undefined) {
        this.emitWay(this.profile.profileType);
      } else {
        this.step = 0;
      }
    }
    this.chdRef.detectChanges();
  }
  getFullInfo(value) {
    this.chdRef.detectChanges();
  }

  emitWay(way) {
    this.step = 1;
    if (way === 1) {
      this.method = 'person';
      this.setRegisterPersonSteps();
    } else {
      this.method = 'company';
      this.setRegisterCompanySteps();
    }
    this.chdRef.detectChanges();
    this.emitWayEvent.emit(way);
  }

  setRegisterCompanySteps() {
    // Si no hay perfil creado
    if (!this.profile || this.profile === undefined) {
      this.registerCompanySteps = [
        {name: 'email', index: 0},
        {name: 'verify_mail', index: 1},
        //{name: 'phoneCode', index: 2},
        // {name: 'contactPersonData', index: 2},
        // {name: 'companyData', index: 3},
        // {name: 'companyAddress', index: 4},
        // {name: 'welcome', index: 5},
        // {name: 'constitutionDocs', index: 6},
        // {name: 'realOwnershipDoc', index: 7},
        // {name: 'model200Doc', index: 8},
        // {name: 'identityPartnerDocs', index: 9},
        // {name: 'RLSteps', index: 10},
        // {name: 'RLPersonalData', index: 11},
        // {name: 'RLPersonalAddress', index: 12},
        // {name: 'RLNationality', index: 13},
        // {name: 'RLDocumentType', index: 14},
        // {name: 'RLIdentityDoc', index: 15},
        // {name: 'RLIdentityDocAdvice', index: 16},
        // {name: 'RLAddressDoc', index: 17},
        // {name: 'RLSuccess', index: 18},
        // {name: 'iframeMeeting', index: 19}
      ];
    } else { // Si hay perfil creado
      if (this.list === undefined) {
        this.companyInfo = this.profile;
        this.registerCompanySteps = [];
        const steps = [];
        if (this.profile.mangopayWalletId === null && (this.profile.walletstatus === 0 || this.profileService.checkCreationDate(this.profile.creationDate))) {
          steps.unshift('mangopayCheckStep');
        }
        if (!this.profile.mobileVerified) {
          //steps.unshift('phoneCode');
          steps.unshift('phone');
        }
        if (this.checkEmptyField(this.profile.nameRepresentative)
            || this.checkEmptyField(this.profile.nationalityRepresentative)
            || (this.checkEmptyField(this.profile.identityDocumentDniRepresentative) && this.checkEmptyField(this.profile.identityDocumentNieRepresentative) && this.checkEmptyField(this.profile.identityDocumentPassportRepresentative))
            //|| this.checkEmptyField(this.profile.addressVerifyRepresentative)
            ) {
              //steps.unshift('RLSuccess');
            }
        // if (this.checkEmptyField(this.profile.addressVerifyRepresentative)) {
        //   steps.unshift('RLAddressDoc');
        // }
        if ((this.checkEmptyField(this.profile.identityDocumentDniRepresentative)
              && this.checkEmptyField(this.profile.identityDocumentNieRepresentative)
              && this.checkEmptyField(this.profile.identityDocumentPassportRepresentative))
            || (this.checkEmptyField(this.profile.identityDocumentFrontRepresentative)
              && this.checkEmptyField(this.profile.identityDocumentFrontNieRepresentative)
              && this.checkEmptyField(this.profile.identityDocumentFrontPassportRepresentative))) {
          //steps.unshift('RLIdentityDocAdvice');
          steps.unshift('RLIdentityDoc');
          steps.unshift('RLDocumentType');
        }
        if (this.checkEmptyField(this.profile.nationalityRepresentative)) {
          steps.unshift('RLNationality');
        }
        if (this.checkEmptyField(this.profile.postalCodeRepresentative) && this.checkEmptyField(this.profile.addressRepresentative)) {
          steps.unshift('RLPersonalAddress');
        }
        if (this.checkEmptyField(this.profile.nameRepresentative)) {
          steps.unshift('RLPersonalData');
        }
        // if (this.checkEmptyField(this.profile.nameRepresentative)
        //     || this.checkEmptyField(this.profile.nationalityRepresentative)
        //     || (this.checkEmptyField(this.profile.identityDocumentDniRepresentative) && this.checkEmptyField(this.profile.identityDocumentNieRepresentative) && this.checkEmptyField(this.profile.identityDocumentPassportRepresentative))
        //     //|| this.checkEmptyField(this.profile.addressVerifyRepresentative)
        //     ) {
        //       steps.unshift('RLSteps');
        //     }
        if (this.checkEmptyField(this.profile.identityDocumentPartner1)) {
          steps.unshift('identityPartnerDocs');
        }
        // if (this.checkEmptyField(this.profile.enterpriseModel200) && (!this.checkEmptyField(this.profile.creationDate) && (new Date(Number(this.profile.creationDate.split('/')[2]), Number(this.profile.creationDate.split('/')[1]) - 1, Number(this.profile.creationDate.split('/')[0])) > new Date(2021, 10, 15)))) {
        //   steps.unshift('model200Doc');
        // }
        if (this.checkEmptyField(this.profile.realOwnershipDocument)) {
          steps.unshift('realOwnershipDoc');
        }
        if (this.checkEmptyField(this.profile.constitutionDocument1)) {
          steps.unshift('constitutionDocs');
        }
        if (this.checkEmptyField(this.profile.taxAddress)) {
          steps.unshift('companyAddress');
        }
        if (this.checkEmptyField(this.profile.socialDenomination)) {
          steps.unshift('companyData');
        }
        if ((this.profile.name === null || this.profile.name === undefined || this.profile.name === '')) {
          steps.unshift('contactPersonData');
        } else {
          this.companyInfo.name = this.profile.name;
          this.companyInfo.surname = this.profile.surname;
          this.companyInfo.birthDate = this.profile.birthDate;
        }
        if ((steps.length > 0) && (this.detectOS() === 'iOS' || this.detectOS() === 'Android')) {
          //steps.unshift('passToPCAdvice');
        }
        if (!this.profile.mailVerified) {
        } else {
          this.companyInfo.email = this.profile.username;
        }
        if (steps.length > 0) {
          steps.push('successCompleteRegister');

        }
        if (this.checkEmptyField(this.profile.walletstatus)) {
          steps.push('iframeMeeting');
        }
        for (let i = 0; i < steps.length; i++) {
          const model = {
            name: steps[i],
            index: i
          };
          this.registerCompanySteps.push(model);
        }
      } else {
        this.registerCompanySteps = this.list;
      }
    }
  }

  detectOS() {
    const platform = navigator.platform.toLowerCase(), iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
    if (platform.includes('mac')) return 'MacOS';
    if (iosPlatforms.includes(platform)) return 'iOS';
    if (platform.includes('win')) return 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
    if (/linux/.test(platform)) return 'Linux';
    this.chdRef.detectChanges();
    return 'unknown';
  }

  // Comprueba si el campo está vacío
  checkEmptyField(field: any): boolean {
    if (field === null || field === undefined || field === '') {
      return true;
    } else {
      return false;
    }
  }

  setRegisterPersonSteps() {
    let paymentEntity = true;
    let accredited = true;

    if (!this.profile || this.profile === undefined) {
      paymentEntity = false;
      accredited = false;
      this.idDocument = false;
      this.registerPersonSteps = [
        {name: 'emailUser', index: 0},
        {name: 'verify_mail', index: 1},
        //{name: 'phoneUser', index: 2},
        // {name: 'nameUser', index: 2},
        // {name: 'nationalityUser', index: 3},
        // {name: 'address', index: 4},
        // {name: 'docTypeNumber', index: 5},
        // {name: 'documentFolder', index: 6},
        // {name: 'successStep', index: 7}
      ];
    } else {
      if (this.list === undefined) {
        this.registerPersonSteps = [];
        const steps = [];
        if (this.profile.walletstatus !== null && this.profile.walletstatus !== undefined && this.profile.walletstatus > 0) {
          steps.unshift('investFinishMessage');
        } else {
          steps.unshift('successStep');
        }

        if (this.profile.mangopayWalletId === null && (this.profile.walletstatus === 0 || this.profileService.checkCreationDate(this.profile.creationDate))) {
          steps.unshift('mangopayCheckStep');
        }
        if (
          ((this.profile.mobile === null
          || this.profile.mobile === undefined
          || this.profile.mobile === '') ||
          (this.profile.mobileVerified === null
            || this.profile.mobileVerified === undefined
            || !this.profile.mobileVerified))
          ) {
            //steps.unshift('phoneUser', 'code');
            steps.unshift('phoneUser');
          }
        if (
          (this.profile.identityDocumentFront === null || this.profile.identityDocumentFront === '' || this.profile.identityDocumentFront === undefined)
          && (this.profile.identityDocumentFrontNie === null || this.profile.identityDocumentFrontNie === '' || this.profile.identityDocumentFrontNie === undefined)
          && (this.profile.identityDocumentFrontPassport === null || this.profile.identityDocumentFrontPassport === '' || this.profile.identityDocumentFrontPassport === undefined)
        ) {
          steps.unshift('documentFolder');
        }
        if (
          (!this.profile.identityDocumentDni || this.profile.identityDocumentDni === null || this.profile.identityDocumentDni === undefined || this.profile.identityDocumentDni === '')
          && (!this.profile.identityDocumentNie || this.profile.identityDocumentNie === null || this.profile.identityDocumentNie === undefined || this.profile.identityDocumentNie === '')
          && (!this.profile.identityDocumentPassport || this.profile.identityDocumentPassport === null || this.profile.identityDocumentPassport === undefined || this.profile.identityDocumentPassport === '')
          ) {
          steps.unshift('docTypeNumber');
        }
        if ((this.profile.taxCity === null || this.profile.taxCity === undefined || this.profile.taxCity === '') && (this.profile.taxInternationalCity === null || this.profile.taxInternationalCity === undefined || this.profile.taxInternationalCity === '')) {
          steps.unshift('address');
        }
        if (
          this.profile.nationality === null
          || this.profile.nationality === undefined
          ) {
          steps.unshift('nationalityUser');
        } else {
          this.info.nation = this.profile.nationality;
          this.info.residence = this.profile.taxRegion;
        }
        if (
          this.profile.name === null
          || this.profile.name === undefined
          || this.profile.name === ''
          ) {
          steps.unshift('nameUser');
          steps.unshift('taxdown');
          // taxdown
        }
        if (
          this.profile.mailVerified === null
          || this.profile.mailVerified === undefined
          || !this.profile.mailVerified
          ) {
            //steps.unshift('emailValidated');
          //steps.unshift('emailCode');
        } else {
          this.info.email = this.profile.username;
        }
        if (
          this.profile.username === null
          || this.profile.username === undefined
          || this.profile.username === ''
          ) {
          steps.unshift('emailUser');
        } else {
          this.info.email = this.profile.username;
        }

        for (let i = 0; i < steps.length; i++) {
          const model = {
            name: steps[i],
            index: i
          };
          this.registerPersonSteps.push(model);
        }
      } else {
        this.registerPersonSteps = this.list;
      }

      if (this.profile.identityDocumentDni && this.profile.identityDocumentDni !== undefined) {
        this.info.docType = 1;
        this.userInfo.info = this.info;
      } else if (this.profile.identityDocumentNie && this.profile.identityDocumentNie !== undefined) {
        this.info.docType = 2;
        this.userInfo.info = this.info;
      } else if (this.profile.identityDocumentPassport && this.profile.identityDocumentPassport !== undefined) {
        this.info.docType = 3;
        this.userInfo.info = this.info;
      }
    }
    this.chdRef.detectChanges();
  }

  emitLogin() {
    this.emitLoginEvent.emit();
    this.chdRef.detectChanges();
  }

  emitDocPerson(value) {
    this.info = value;
    let model: any;
    if (Number(value.docType) === 1) {
      // this.profile.identityDocumentDni = value.docNumber;
      model = {
        identityDocumentDni: value.docNumber,
        // identityDocumentType: Number(value.docType)
      };
    } else if ((Number(value.docType) === 2)) {
      // this.profile.identityDocumentNie = value.docNumber;
      model = {
        identityDocumentNie: value.docNumber,
        // identityDocumentType: Number(value.docType)
      };
    } else if ((Number(value.docType) === 3)) {
      // this.profile.identityDocumentPassport = value.docNumber;
      model = {
        identityDocumentPassport: value.docNumber,
        // identityDocumentType: Number(value.docType)
      };
    }
    this.emitPersonalDocsEvent.emit(model);
    this.chdRef.detectChanges();
  }
  emitAddressValue(value) {
    const model = value;
    if (this.taxC !== null && this.taxC !== undefined) {
      model['taxC'] = this.taxC;
    }
    this.emitFullAddressEvent.emit(model);
  }
  getDocument(document) {
    this.userInfo.document = document;
  }
  getCodeNumber(code) {
    this.phoneCode = code;
  }
  confitmCode(value, type) {
    if (type === 'phone') {
      const data = {
        phone: this.phoneNumber || this.profile.mobile,
        code: value
      };
      this.codeNumberEvent.emit(data);
    } else {
      const data = {
        code: value,
        email: this.info.email || this.companyInfo.email || this.profile.username
      };
      this.codeEmailEvent.emit(data);
    }
  }
  downloadApp() {
    // this.downloadAppEvent.emit(this.phoneNumber);
  }

  getPhoneNumber(phone) {
    this.phoneNumber = phone;
    this.info.mobile = phone;
    if (this.info.phone === undefined) {
      this.info.phone = phone;
    }
    this.chdRef.detectChanges();
  }
  confirmPhone() {
    let number;
    let hasPrefix = false;
    if (this.phoneNumber !== undefined) {
      number = this.phoneNumber;
    } else if (this.profile !== undefined && this.profile.mobile && this.profile.mobile !== undefined) {
      number = this.profile.mobile;
    }
    countryPrefix.forEach(element => {
      const prefixN = element.prefix.toString();
      const prefix = number.substring(0, prefixN.toString().length);
      if (prefixN === prefix) {
        hasPrefix = true;
      }
    });
    if (!hasPrefix) {
      number = '34' + number;
    }
    setTimeout(() => {
      this.phoneNumberEvent.emit(number);
    }, 500);
    // this.phoneNumberEvent.emit(number);
    this.chdRef.detectChanges();
  }
  resendCode(value) {
    const email = this.info.email || this.companyInfo.email || this.profile.username;
    if (value === 'mail') {
      this.resendCodeEmailEvent.emit(email);
    } else if (value === 'phone') {
      if (this.phoneNumber === undefined) {
        this.phoneNumber = this.info.mobile || this.companyInfo.mobile;
      }
      this.resendCodeEvent.emit(this.phoneNumber);
    } else {
      this.downloadApp();
    }
  }
  backRegisterPerson() {
    this.step = 1;
    this.registerPersonLastStep();
    this.chdRef.detectChanges();
  }
  emitDocumentation(documents) {
    this.emitDocumentntationEvent.emit(documents);
    // this.step = 3;
    this.chdRef.detectChanges();
  }
  updateDocNumber(doc) {
    this.updateDocNumberEvent.emit(doc);
  }
  reset() {
    this.step = 0;
    this.chdRef.detectChanges();
  }

  secondStep(value?: any) {
    this.isFinishEvent.emit();
    this.chdRef.detectChanges();
  }
  changeWay() {
    let way: number;
    if (this.method === 'person') {
      way = 2;
    } else {
      way = 1;
    }
    this.emitWay(way);
    this.emitWayEvent.emit(way);
    this.chdRef.detectChanges();
  }
  validateEmail(value) {
    this.validateEmailEvent.emit(value);
    this.chdRef.detectChanges();
  }
  generateProfilePerson(value) {
    let type;
    let data: any;
    if (this.method === 'person') {
      type = 1;
      // const phoneNumber = value.prefix + value.phone.toString();
      const birth = value.birthDate.replace('-', '/').replace('-', '/');
      data = {
        profileType: Number(type),
        birthDate: birth,
        surname: value.surname,
        // nationality: Number(value.nation),
        // taxCountry: Number(value.residence),
        username: value.email,
        name: value.name,
        // phone: phoneNumber,
      };
    } else {
      type = 2;
    }

    this.generateProfilePersonEvent.emit(data);
    this.chdRef.detectChanges();
  }

  updateCompanyAddressInfo(value) {
    let model = {};
    this.taxC = Number(value.taxCountry);
    if (Number(value.taxCountry) === 108) {
      model = {
        taxAddress: value.taxAddress,
        taxPostalCode: value.taxPostalCode,
        taxCountry: value.taxCountry,
        taxRegion: value.taxRegion,
        taxCity: value.taxCity
      };
    } else {
      model = {
        taxAddress: value.taxAddress,
        taxPostalCode: value.taxPostalCode,
        taxCountry: value.taxCountry,
        taxInternationalRegion: value.taxInternationalRegion,
        taxInternationalCity: value.taxInternationalCity
      };
    }
    this.updateCompanyAddressEvent.emit(model);
  }
  updateConstitutionDocument(value) {
    this.updateConstitutionDocumentEvent.emit(value);
  }
  updateRealOwnershipDocument(value) {
    this.updateRealOwnershipDocumentEvent.emit(value);
  }
  updateModel200Document(value) {
    this.updateModel200DocumentEvent.emit(value);
  }
  updateIdentityPartnersDocuments(value) {
    this.updateIdentityPartnersDocumentsEvent.emit(value);
  }
  updateRLData(value) {
    const data = {
      nameRepresentative: value.nameRepresentative,
      surnameRepresentative: value.surnameRepresentative,
      birthDateRepresentative: value.birthDateRepresentative,
      actAsRepresentative: Number(value.actAsRepresentative)
    };
    this.updateRLDataEvent.emit(data);
  }
  updateRLNationality(value) {
    const data = {
      nationalityRepresentative: value.nationalityRepresentative || 108,
      countryRepresentative: value.countryRepresentative || 108
    };
    this.updateRLDataEvent.emit(data);
  }
  updateRLDocType(value) {
    let data = {};
    switch (Number(value.identityDocumentTypeRepresentative)) {
      case 1:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentDniRepresentative: value.identityDocumentDniRepresentative,
        };
        break;
      case 2:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentNieRepresentative: value.identityDocumentNieRepresentative,
        };
        break;
      case 3:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentPassportRepresentative: value.identityDocumentPassportRepresentative,
        };
        break;
    }
    this.updateRLDataEvent.emit(data);
  }
  updateRLIdentityDoc(value) {
    let data = {};
    switch (Number(value.identityDocumentTypeRepresentative)) {
      case 1:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentFrontRepresentative: value.identityDocumentFrontRepresentative,
          identityDocumentBackRepresentative: value.identityDocumentBackRepresentative
        };
        break;
      case 2:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentFrontNieRepresentative: value.identityDocumentFrontNieRepresentative,
          identityDocumentBackNieRepresentative: value.identityDocumentBackNieRepresentative
        };
        break;
      case 3:
        data = {
          identityDocumentTypeRepresentative: value.identityDocumentTypeRepresentative,
          identityDocumentFrontPassportRepresentative: value.identityDocumentFrontPassportRepresentative,
        };
        break;
    }
    this.updateRLIdentityDocumentEvent.emit(data);
  }

  updateProofAddressDoc(value) {
    this.updateProofAddressDocumentEvent.emit(value);
  }

  updateAcreditationCompanyChecks(value) {
    const model = {
      accountAcreditation1000kActive: value.accountAcreditation1000kActive,
      accountAcreditation2000kBusiness: value.accountAcreditation2000kBusiness,
      accountAcreditation300kResources: value.accountAcreditation300kResources,
      accountAcreditationProfessionalAdvice: value.accountAcreditationProfessionalAdvice
      };
    this.updateAcreditationCompanyChecksEvent.emit(model);
  }

  updateAcreditationCompanyDoc(value) {
    this.updateAcreditationCompanyDocEvent.emit(value);
  }

  acceptLWTerms(value) {
    const model = {
      acceptPaymentEntity: value
    };
    this.acceptLwTermsEvent.emit(model);
  }

  updateInfo(value) {
    this.updateInfoEvent.emit(value);
  }

  accreditationChecks(value) {
    this.emitAccreditationChecksEvent.emit(value);
    this.chdRef.detectChanges();
  }
  getBankDocument(value) {
    this.emitBankDocument.emit(value);
    this.chdRef.detectChanges();
  }
  acceptLw(value) {
    this.acceptLwTermsEvent.emit(value);
    this.chdRef.detectChanges();
  }
  finishRegister(value) {
    if (value !== undefined) {
      this.userInfo.info = value;
    }
    this.lastStep = true;
    if (this.method === 'person') {
      //this.step = 3;
    }
    this.otherMomentEvent.emit();
    this.chdRef.detectChanges();
  }
  registerPersonPrev() {
    if (this.registerPerson) { this.registerPerson.prev(); }
    if (this.registerCompany) {
      this.registerCompany.resetDocs();
      this.registerCompany.prev();
    }
    this.chdRef.detectChanges();
  }
  registerPersonNext() {
    if (this.registerPerson) { this.registerPerson.next(); }
    if (this.registerCompany) { this.registerCompany.next(); }
    this.chdRef.detectChanges();
  }
  registerPersonSetLoading() {
    if (this.registerPerson) { this.registerPerson.isLoading(true); }
    if (this.registerCompany) { this.registerCompany.isLoading(true); }
    this.chdRef.detectChanges();
  }
  registerPersonRemoveLoading() {
    if (this.registerPerson) { this.registerPerson.isLoading(false); }
    if (this.registerCompany) {this.registerCompany.isLoading(false); }
    this.chdRef.detectChanges();
  }
  registerDocumentSetLoading() {
    this.registerDocument.isLoading(true);
  }
  registerDocumentRemoveLoading() {
    this.registerDocument.isLoading(false);
  }
  registerPersonLastStep() {
    this.registerPerson.setLastStep();
    this.chdRef.detectChanges();
  }
  fullRegisterPrev() {
    if (this.step > 0) {
      this.step--;
    }
    this.chdRef.detectChanges();
  }
  fullRegisterLoading() {
    this.registerLoading = true;
    this.chdRef.detectChanges();
  }
  removeFullRegisterLoading() {
    this.registerLoading = false;
    this.chdRef.detectChanges();
  }
  accreditedPersonSetLoading() {
    this.registerPerson.accreditedPersonSetLoading();
    this.chdRef.detectChanges();
  }
  accreditedPersonRemoveLoading() {
    this.registerPerson.accreditedPersonRemoveLoading();
    this.chdRef.detectChanges();
  }
  otherMomentAccredit() {
    this.otherMomentAccreditEvent.emit();
    this.chdRef.detectChanges();
  }
  finishProccess() {
    this.finishProccessEvent.emit();
    this.chdRef.detectChanges();
  }
  changeEmail(value) {
    this.changeEmailEvent.emit(value);
    this.chdRef.detectChanges();
  }

  public registerPersonFileLoading(loading, error?: boolean) {
    this.registerPerson.fileLoading(loading, error);
    this.chdRef.detectChanges();
  }

  getSignaturitUrl() {
    setTimeout(() => {
      this.getSignaturitEvent.emit();
    }, 100);
    this.chdRef.detectChanges();
  }

  continueInvest() {
    this.continueInvestEvent.emit();
    this.chdRef.detectChanges();
  }

  public setAccreditSignaturitUrl(url) {
    if (this.method === 'person') {
      this.registerPerson.setAccreditSignaturitUrl(url);
    } else {
      this.registerCompany.setSignaturitUrl(url);
    }
    // if (this.registerPerson) { this.registerPerson.setAccreditSignaturitUrl(url); }
    this.chdRef.detectChanges();
  }

  signaturitSigned() {
    this.signaturitSignedEvent.emit();
    this.chdRef.detectChanges();
  }

  checkRefCode(code) {
    this.checkRefCodeEvent.emit(code);
    this.chdRef.detectChanges();
  }

  public setRefUsername(username) {
    this.registerWellcome.setRefCode(username);
    this.chdRef.detectChanges();
  }

  removeModal() {
    this.modalService.removeModal();
    this.chdRef.detectChanges();
  }

  companyProfileGenerated() {
    this.companyProfileGeneratedEvent.emit();
    this.chdRef.detectChanges();
  }

 public registerDone(val) {
  this.registerWellcome.registerDone(val);
  this.chdRef.detectChanges();
 }

}
