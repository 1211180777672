import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class ProductionGraphicService {
  start: string;
  end: string;

  datos: any;

  constructor(private communicationService: CommunicationService) {}

  getData(date: any, id: any) {
    switch (date) {
      case 'day':
        this.start = moment().format('YYYY-MM-DD');
        this.end = moment().format('YYYY-MM-DD');
        break;
      case 'week':
        this.start = moment().startOf('isoWeek').format('YYYY-MM-DD');
        this.end = moment().endOf('isoWeek').format('YYYY-MM-DD');
        break;
      case 'month':
        this.start = moment().startOf('month').format('YYYY-MM-DD');
        this.end = moment().endOf('month').format('YYYY-MM-DD');
        break;
      case 'year':
        this.start = moment().startOf('year').format('YYYY-MM-DD');
        this.end = moment().endOf('year').format('YYYY-MM-DD');
        break;
    }

    return this.communicationService.get(
      'apiservices/dashboard/v1/dashboard/' + id + '/statistics/performance/' + this.start + '/' + this.end,
      null
    );
  }
}
