import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'fun-overvew-meeting-chat',
  templateUrl: './overvew-meeting-chat.component.html',
  styleUrls: ['./overvew-meeting-chat.component.scss'],
})
export class OvervewMeetingChatComponent implements OnInit, AfterViewInit  {

  @Input() public meeting;
  @Input() public project;
  @Input() public user;
  @Input() public strapiUserId;
  @Output() public sendCommentEvent = new EventEmitter<any>();
  @ViewChild('messagesContainer') messagesContainer!: ElementRef;

  isConnected = false;
  messages = [];

  public messageForm: FormGroup;

  constructor(public chdRef: ChangeDetectorRef, private storage: Storage) { }

  ngOnInit() {
    this.messageForm = new FormGroup({
      msg: new FormControl(''),
    });

    const list = this.meeting['attributes'].comments.data || [];
    if (list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        const msg = {
            comment: list[i].attributes.comment,
            time: list[i].attributes.time,
            meeting: this.meeting.id,
            users_permissions_user: list[i].attributes.users_permissions_user.data.id,
            name: list[i].attributes.users_permissions_user.data.attributes.name,
            username: list[i].attributes.users_permissions_user.data.attributes.username,
        };
        this.messages.push(msg);
      }
    }
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
  }

  public getComment(comment) {
    this.messages.push(comment);
    setTimeout(() => {
      this.scrollToBottom();
    }, 50);
    this.chdRef.detectChanges();
  }

  sendMessage() {
    this.storage.get('STRAPITOKEN').then(val => {
      const message = this.messageForm.controls.msg.value.trim();
      if (message.length > 0) {
        const now = new Date();
        const comment = {
          authToken: val,
          name: this.user.users_permissions_user.data.attributes.name,
          comment: message,
          username: this.user.name,
          time: now,
          meeting: this.meeting.id,
        }
        this.messages.push(comment);
        this.sendCommentEvent.emit(comment);
        this.messageForm.controls.msg.setValue('');
        this.scrollToBottom();
      }
      this.chdRef.detectChanges();
    });
  }

}
