import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import * as momentNs from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MarketPlaceOfferCreateModel, MarketPlaceProjectModel, MarketPlaceStockPackageModel } from 'src/app/shared/models/marketplace.models';
import { HelpService } from 'src/app/services/help.service';
import { DateService } from 'src/app/services/date.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

const moment = momentNs;

const validatorAmountMax: ValidatorFn = (formGroup: FormGroup) => {
  const start = formGroup.get('amount').value;
  const end = formGroup.get('stocks').value;

  if (start !== null && end !== null && start <= end) {
    return null;
  }
  formGroup.get('amount').setErrors({ invalid: true });
  return { max: true };
};

@Component({
  selector: 'fun-offer-create',
  templateUrl: './offer-create.component.html',
  styleUrls: ['./offer-create.component.scss']
})
export class OfferCreateComponent implements OnInit {

  @Output() public eventGetStocksPackages = new EventEmitter<any>();
  @Output() public eventCreateOffer = new EventEmitter<any>();
  @Output() public eventButtonsRoute = new EventEmitter<string>();

  public rangeValue = 15;
  public closDate: any;
  public closDateM: any;
  public isSubmit: string;
  public formControlKeys = [];
  public errorMessages = {};
  public errorAmount = false;

  public dateMls: number;

  defaultProject: MarketPlaceProjectModel;
  defaultStockPackage: MarketPlaceStockPackageModel;

  private _dataProjectList: MarketPlaceProjectModel[] = [];
  get dataProjectList(): MarketPlaceProjectModel[] {
    return this._dataProjectList;
  }

  @Input('dataProjectList')
  set dataProjectList(value: MarketPlaceProjectModel[]) {
    this._dataProjectList = value;

    if (value || Array.isArray(value)) {
      this.setDefaultProject();
    }
    this.chdRef.detectChanges();
  }

  private _dataList: MarketPlaceStockPackageModel[] = [];
  get dataList(): MarketPlaceStockPackageModel[] {
    return this._dataList;
  }

  @Input('dataList')
  set dataList(value: MarketPlaceStockPackageModel[]) {
    this._dataList = value;

    if (value || Array.isArray(value)) {
      this.setDefaultStockPackage();
      this.setForm(this.getDefaultStockPackage());
    }
    this.chdRef.detectChanges();
  }

  private _offerCreated = false;
  get offerCreated(): boolean {
    return this._offerCreated;
  }

  @Input('offerCreated')
  set offerCreated(value: boolean) {
    this._offerCreated = value;

    if (value) {
      this.amplitudeService.trackEvent('marketplace_offer_created', {});
      this.finalStep();
    }
  }

  public dataForm: FormGroup;
  public stateValue = 1;
  public backBtn = 'secondaryDark-disabled';
  public selectIsOpen = false;
  public formalizationTypes = new Map([
    //['internal', 'internal'],
    ['external', 'external']
  ]);
  public packageTypes =  new Map([
    [1, 'constitución'],
    [2, 'venta'],
    [3, 'marketplace'],
    [4, 'primaria']
  ]);

  constructor(
    private formBuilder: FormBuilder,
    private chdRef: ChangeDetectorRef,
    private helpService: HelpService,
    public dateService: DateService,
    private amplitudeService: AmplitudeService
  ) {
    this.dataForm = this.createForm();
  }

  ngOnInit() {
    registerLocaleData(es);
    setTimeout(() => this.initForm());
    this.onFormValuesChange();
    this.onProjectIdValueChange();
    this.getClDay();
    this.isSubmit = 'primaryDark';
    this.controlStep();
    this.chdRef.detectChanges();
    //this.onClosingDateValueChange();
  }

  /* ngOnDestroy(): void {
    this.dataForm.reset();
  } */

  nextStep() {
    this.dateMls = this.dateService.getDateMs(this.dataForm.value.date);
    window.scroll(0, 0);
    if (this.validStep()) {
      this.stateValue = this.stateValue + 1;
    }
    this.controlStep();
    this.chdRef.detectChanges();
  }

  prevStep() {
    window.scroll(0, 0);
    if (this.stateValue > 1) {
      this.stateValue = this.stateValue - 1;
    }
    this.controlStep();
    this.chdRef.detectChanges();
  }

  finalStep() {
    this.stateValue = 5;
    this.controlStep();
    this.chdRef.detectChanges();
  }
  controlStep() {
    if (this.stateValue === 1) {
      this.backBtn = 'secondaryDark-disabled';
    } else {
      this.backBtn = 'secondaryDark';
    }
    this.chdRef.detectChanges();
  }

  validStep(): boolean {
    switch (this.stateValue) {
      case 1:
        return (
          this.dataForm.get('projectId').errors ||
          this.dataForm.get('stockPackageId').errors
        ) ? false : true;
      case 2:
        return (
          this.dataForm.get('amount').errors
          //|| this.dataForm.get('closingDate').errors
        ) ? false : true;
      case 3:
        return (
          this.dataForm.get('formalizationType').errors
        ) ? false : true;
      case 4:
        return (
          this.dataForm.errors
        ) ? false : true;
      default:
        return true;
    }
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      projectId: [null, Validators.required],
      projectName: ['', Validators.required],
      amount: [null, [Validators.required, Validators.min(1), Validators.pattern(/^[0-9]\d*$/)]],
      formalizationType: ['', Validators.required],
      // closingDate: ['', Validators.required],
      // formattedClosingDate: ['', Validators.required],
      stockPackageId: [null, Validators.required],
      type: ['', Validators.required],
      stocks: [null, Validators.required],
      date: ['', Validators.required],
      participationInit: [null, Validators.required],
      participationEnd: [null, Validators.required],
      adquisitionPrice: [null, Validators.required]
    }, {
      validator: validatorAmountMax
    });
  }

  initForm(): void {
    const formalizationType = this.getDefaultFormalizationType();
    // const closingDate = this.getDefaultClosingDate();
    const defaultProjectId: number = this.getDefaulProjectId();
    const project: MarketPlaceProjectModel = this.getProjectById(defaultProjectId);

    if (project) {
      this.dataForm.get('projectId').setValue(defaultProjectId);
      this.dataForm.get('projectName').setValue(project.name);
      this.dataForm.get('formalizationType').setValue(formalizationType);
      // this.dataForm.get('closingDate').setValue(closingDate);
    }
  }

  resetForm(): void {
    const formalizationType = this.getDefaultFormalizationType();
    // const closingDate = this.getDefaultClosingDate();

    this.dataForm.get('amount').reset();
    // this.dataForm.get('formattedClosingDate').reset();
    this.dataForm.get('stockPackageId').reset();
    this.dataForm.get('type').reset();
    this.dataForm.get('stocks').reset();
    this.dataForm.get('date').reset();
    this.dataForm.get('participationInit').reset();
    this.dataForm.get('participationEnd').reset();
    this.dataForm.get('adquisitionPrice').reset();
    this.dataForm.get('formalizationType').setValue(formalizationType);
    // this.dataForm.get('closingDate').setValue(closingDate);
  }

  setForm(stockPackage?: MarketPlaceStockPackageModel): void {
    if (stockPackage) {
      this.dataForm.get('stockPackageId').setValue(stockPackage.id);
      this.dataForm.get('type').setValue(stockPackage.type);
      this.dataForm.get('stocks').setValue(stockPackage.stocks);
      this.dataForm.get('date').setValue(stockPackage.date);
      this.dataForm.get('participationInit').setValue(stockPackage.participation_init);
      this.dataForm.get('participationEnd').setValue(stockPackage.participation_end);
      this.dataForm.get('adquisitionPrice').setValue(stockPackage.adquisition_price);
    }
  }

  submitForm(): void {
    this.isSubmit = 'primaryDark-disabled';
    this.chdRef.detectChanges();
    if (this.dataForm.valid) {
      const offer: MarketPlaceOfferCreateModel = this.prepareForm();
      this.emitEventCreateOffer(offer);
    }
  }

  prepareForm(): MarketPlaceOfferCreateModel {
    const partID = this.dataList[0].participation_id;
    return {
      amount: this.dataForm.get('amount').value,
      closing_date: this.getClDay(), //this.dataForm.get('formattedClosingDate').value,
      formalization_type: this.dataForm.get('formalizationType').value,
      participation_init: this.dataForm.get('participationInit').value,
      participation_end: this.dataForm.get('participationEnd').value,
      participation_id: partID,
      projectId: this.dataForm.get('projectId').value
    };
  }

  setDefaultProject(): void {
    this.defaultProject = (this.dataProjectList) ?
      this.dataProjectList.find(project => project ? project : null) : null;
  }

  getDefaultProject(): MarketPlaceProjectModel {
    return this.defaultProject;
  }

  setDefaultStockPackage(): void {
    this.defaultStockPackage = (this.dataList) ?
    this.dataList.find(stockPackge => stockPackge ? stockPackge : null) : null;
    this.dataList.forEach(element => {
      element.msDate = this.dateService.getDateMs(element.date);
      element.date = element.date.replace('/', '-').replace('/', '-');
    });
  }

  getDefaultStockPackage(): MarketPlaceStockPackageModel {
    return this.defaultStockPackage;
  }

  getDefaulProjectId(): number {
    const defaultProject = this.getDefaultProject();
    return defaultProject ? defaultProject.id : null;
  }
/*
  getDefaultClosingDate(): string {
    return moment(new Date(), 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD');
  }*/

  getDefaultFormalizationType(): string {
    return this.formalizationTypes.get('external');
  }

  getProjectById(projectId: number): MarketPlaceProjectModel {
    return (this.dataProjectList) ?
      this.dataProjectList.find(project => project.id === projectId ? project : null) : null;
  }

  getStockPackageTypeValue(type: number): string {
    const packageType = this.packageTypes.get(type);
    return packageType ? packageType : '';
  }
  getClDay() {
    // this.dateService.getCurrentDateMs();
    this.closDate = moment(new Date(), 'YYYY-MM-DD').add(this.rangeValue, 'days').format('YYYY-MM-DD');
    this.closDate = this.closDate.replace('-', '/').replace('-', '/');
    this.closDateM = this.dateService.getDateMs(this.closDate); // this.closDate.replace('-', '/').replace('-', '/');
    return this.closDate;
  }

  changeDataRange(evt) {
    this.rangeValue = evt.detail.value;
    this.getClDay();
  }

  onFormValuesChange(): void {
    this.dataForm.valueChanges.subscribe(() => {
      this.chdRef.detectChanges();
    });
  }

  onProjectIdValueChange(): void {
    this.dataForm.get('projectId').valueChanges.subscribe((value: number) => {
      this.resetForm();
      this.defaultProject = this.getProjectById(value);
      this.emitEventGetStocksPackages(value);
    });
  }
/*
  onClosingDateValueChange(): void {
    this.dataForm.get('closingDate').valueChanges.subscribe((value: number) => {
      const formattedClosingDate = moment(value).format('YYYY/MM/DD');
      this.dataForm.get('formattedClosingDate').setValue(formattedClosingDate);
    });
  }*/

  onProjectIdSelectChange(event: { target: { value: string; }; }): void {
    const projectId = this.dataForm.controls['projectId'].value;
    const project: MarketPlaceProjectModel = this.getProjectById(projectId);
    this.dataForm.get('projectName').setValue(project.name);
  }
  openHelp() {
    this.helpService.openHelp('second', 1, 'PHASE');
  }

  onEventClickRoute(route: string) {
    this.emitEventClickRoute(route);
  }

  emitEventCreateOffer(offer: any): void {
    this.eventCreateOffer.emit(offer);
  }

  emitEventGetStocksPackages(projectId: number): void {
    this.eventGetStocksPackages.emit({ projectId });
  }

  emitEventClickRoute(route: string): void {
    this.eventButtonsRoute.emit(route);
  }

  get minDate(): string {
    return moment(new Date(), 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD');
  }

  get maxDate(): string {
    return moment(new Date(), 'YYYY-MM-DD').add(30, 'days').format('YYYY-MM-DD');
  }
/*
  get closingDate(): string {
    return moment(new Date(), 'YYYY-MM-DD').add(7, 'days').format('YYYY-MM-DD');
  }*/

  get buttonType(): string {
    return !this.validStep() ? 'primaryDark-disabled' : 'primaryDark';
  }

  detectOS() {
    const platform = navigator.platform.toLowerCase(), iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
    if (platform.includes('mac')) return 'MacOS';
    if (iosPlatforms.includes(platform)) return 'iOS';
    if (platform.includes('win')) return 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
    if (/linux/.test(platform)) return 'Linux';
    this.chdRef.detectChanges();
    return 'unknown';
  }

}
