import { Component, Input, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'fun-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, AfterViewInit {

  @Input() faqus: any;
  @Input() version: any;

  contentBase = ' border-t border-borderAccordion overflow-hidden px-8';
  contentClass: string;
  baseText: string;
  colorText: string;
  textStyle: string;
  iconStyle: string;

  constructor() { }

  ngOnInit() {
    this.contentClass = this.version === 'color' ? ' md:hover:bg-cream-100/[0.1] ' + this.contentBase: this.contentBase;
    this.baseText = 'hover:cursor-pointer flex w-full justify-between items-center rounded-lg py-16 md:py-32 text-left text-m md:text-xl font-sansMedium rounded-none';
    this.colorText = this.version === 'color' ? 'text-white hover:text-cream-100 ' : 'text-eggplant-100 hover:text-orange-100 ';
    this.textStyle = this.colorText + this.baseText;
    this.iconStyle = this.version === 'color' ? 'stroke-cream-100 hover:cursor-pointer w-32 h-32 md:w-40 md:h-40' : 'stroke-orange-80 hover:cursor-pointer w-32 h-32 md:w-40 md:h-40';
  }

  ngAfterViewInit() {
    for (let i = 0; i < this.faqus.length; i++) {
      const el = document.getElementById(`container-${this.faqus[i].index}`);
      el.style.height = '0px';
      el.style.transition = 'height 0.5s';
    }
  }

  toggleItem(item) {
    for (let i = 0; i < this.faqus.length; i++) {
      if (item.index === this.faqus[i].index) {
        item.opened = !item.opened;
        const el = document.getElementById(`container-${item.index}`);
        if (item.opened) {
          el.style.height = `${el.scrollHeight}px`;
          el.style.transition = 'height 0.5s';
        } else {
          el.style.height = '0px';
          el.style.transition = 'height 0.5s';
        }
      } else {
        this.faqus[i].opened = false;
        const el = document.getElementById(`container-${this.faqus[i].index}`);
        el.style.height = '0px';
        el.style.transition = 'height 0.5s';
      }
    }
  }

}
