<div class="general-container">
    <div class="text">
        <h2 class="font-sans text-2xl text-eggplant-100">{{ 'renew-session.title' | translate }}</h2>
        <p class="font-sans text-l text-eggplant-100/[0.4]">{{ 'renew-session.text' | translate }}</p>
        <fun-input
        [disabled]="disableInput"
        placeholder="{{ 'renew-session.placeholder' | translate }}"
        [parentForm]="cashForm"
        [formControlKeys]="formControlKeys"
        [errorMessages]="errorMessages"
        [isSubmit]="isSubmit"
        controlName="password"
        type="password"
        [errorLogin]="errorAmount"
        labelName="{{ 'renew-session.label' | translate }}"
        ></fun-input>
    </div>
    <div class="btn-container flex items-center justify-center">
        <fun-new-button [buttonType]="sendXHR" title="{{ 'renew-session.Continue' | translate }}" (eventClick)="emitClick()" [classExtra]="' w-full md:w-auto mb-12'"></fun-new-button>
        <fun-new-button [buttonType]="cancelBtn" title="{{ 'renew-session.Cancel' | translate }}" (eventClick)="emitCancel()" [classExtra]="' w-full md:w-auto '"></fun-new-button>
    </div>
</div>
