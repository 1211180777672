<div class="register">
    <div class="body">
        <div class="company-container">
            <div class="inp">
                <fun-input
                    placeholder="{{'register.company.company_name' | translate}}"
                    [parentForm]="companyDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="socialDenomination"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.company_name' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (focusout)="focusCompanyNameLost()"
                    [value]="data.socialDenomination"
                ></fun-input>
                <span *ngIf="notCorrectName" class="advice">{{'register.company.company_name_advise' | translate}}</span>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.company.company_nif' | translate}}"
                    [parentForm]="companyDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="nif"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.company_nif' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.nif"
                ></fun-input>
            </div>
            <div *ngIf="cnaeListCharged" class="inp">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.company.cnae_code' | translate}}"
                    [parentForm]="companyDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="cnae"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.cnae_code' | translate}}"
                    (selectChange)="changeCnae()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="cnaeList"
                    [selectedItem]="data.cnae"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="companyDataForm">
                    <div class="inp select form-input">
                        <label>{{'register.company.cnae_code' | translate}}</label>
                        <select class="select-box" [ngClass]="{'option': companyDataForm.controls['cnae'].value > 0}"
                        name="cnaeCode" formControlName="cnae" (change)="changeCnae()">
                            <option hidden [value]="0">{{'register.company.cnae_code' | translate}}</option>
                            <option *ngFor="let data of cnaeList"
                                [value]="data.id">
                                    {{data.name}}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.company.web' | translate}}"
                    [parentForm]="companyDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="web"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.web' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.web"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
