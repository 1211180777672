<div class="bg-white rounded-2xl p-24 pb-48 h-full overflow-auto scrollbar-none">
  <div class="flex justify-end">
    <fun-icon [name]="'x'" [style]="'w-32 h-32 stroke-[4px] stroke-[#29141F]'" (click)="closeModal()"></fun-icon>
  </div>
  <p *ngIf="realList.length > 0" class="font-sans text-center text-xl text-eggplant-100 pb-12">{{ 'NOTIFICATIONS.TITLE' | translate }}</p>
  <div *ngIf="!loadingNotifications && realList.length > 0">
    <div *ngFor="let notification of realList" class="items-center justify-center flex" [id]="'notification-' + notification.id">
      <div (click)="readNotification(notification)" class="pb-8 cursor-pointer w-full" [id]="'notification-body-' + notification.id">
        <div [id]="'notification-content-' + notification.id" class="notification_content w-full p-8 rounded-xl border border-eggplant-100/[0.1] relative"
        [ngClass]="{ 'bg-cream-100/[0.2]': notification.status !== 'VIEWED' }">

          <div class="flex " [id]="'notification-bodycontent-' + notification.id">
            <div class="pt-4 icon-container" [id]="'notification-icons-' + notification.id">
               <fun-icon *ngIf="notification.status !== 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#29141F]'"></fun-icon>
               <fun-icon *ngIf="notification.status === 'VIEWED'" [name]="'investments'" [style]="'w-16 h-16 stroke-[4px] stroke-[#C9C4C7]'"></fun-icon>
            </div>
            <p class="font-sans text-l pl-12" [id]="'notification-text-' + notification.id"
            [ngClass]="{ 'text-eggplant-100 not-readed': notification.status !== 'VIEWED', 'text-eggplant-100/[0.4]': notification.status === 'VIEWED' }">
              {{ notification.title }}. {{ notification.short_text }}
            </p>
            <!---->
          </div>
          <p class="font-sans text-s text-eggplant-100/[0.4] pl-32">{{ notification.timeRemaining }}</p>
        </div>
      </div>
      <div class="ml-12 cursor-pointer" (click)="deleteNotification(notification)">
        <fun-icon [name]="'trash'" [style]="'w-16 h-16 stroke-[4px] stroke-orange-100'"></fun-icon>
      </div>
    </div>
    <div class="flex gap-12 w-full items-center justify-center mt-12">
      <p class="view_more font-sans text-eggplant-70 cursor-pointer text-xl text-center " (click)="navigateTo('notifications')">{{ 'INFO.BTN_MSG_BALEARES' | translate }}</p>
      <div class="cursor-pointer" (click)="navigateTo('notifications')">
        <fun-icon [name]="'eye'" [style]="'w-16 h-16 stroke-[4px] stroke-eggplant-70'"></fun-icon>
      </div>
    </div>
    <div class="flex gap-12 w-full items-center justify-center mt-12 pb-32">
      <p class="view_more font-sans text-orange-100 cursor-pointer text-l text-center " (click)="deleteAllNotifications()">{{ 'NOTIFICATIONS.DELETE_ALL' | translate }}</p>
      <div class="cursor-pointer">
        <fun-icon [name]="'trash'" [style]="'w-16 h-16 stroke-[4px] stroke-orange-100'"></fun-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!loadingNotifications && realList.length === 0">
    <div class="flex flex-col gap-32 items-center justify-center p-32">
      <div>
        <fun-icon [name]="'eye-off'" [style]="'w-32 h-32 stroke-[4px] stroke-orange-100'"></fun-icon>
      </div>
      <p class="font-sans text-2xl text-center text-orange-100">{{ 'NOTIFICATIONS.NOT_NOTIFICATIONS' | translate }}</p>
    </div>
  </div>
  <div *ngIf="loadingNotifications">
    <fun-spinner></fun-spinner>
  </div>
</div>