import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appFormatIban]'
})
export class AppFormatIbanDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    const input = this.el.nativeElement;
    let value = input.value.replace(/\s+/g, '');

    const formattedValue = value.replace(/(.{4})/g, '$1 ').trim();
    input.value = formattedValue;
  }

}
