import { Component, Input, OnInit } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';
import { BenefitsByMonth } from 'src/app/shared/models/benefits.models';

@Component({
  selector: 'app-calendar-pay-month',
  templateUrl: './calendar-pay-month.component.html',
  styleUrls: ['./calendar-pay-month.component.scss'],
})
export class CalendarPayMonthComponent implements OnInit {

  @Input() monthWithBenefits: BenefitsByMonth;
  @Input() indice: number;

  constructor(private utils: UtilsService) { }

  ngOnInit() {
    for (let i = 0; i < this.monthWithBenefits.benefits.length; i++) {
      this.monthWithBenefits.benefits[i]['month'] = this.utils.parseMonthShort(this.monthWithBenefits.date[1]);
      this.monthWithBenefits.benefits[i]['day'] = this.monthWithBenefits.date[2];
    }
  }

}
