import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fundeen-register-person-nif-info',
  templateUrl: './register-person-nif-info.component.html',
  styleUrls: ['./register-person-nif-info.component.scss']
})
export class RegisterPersonNifInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
