import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RegisterIfameComponent } from '../register-ifame/register-ifame.component';

@Component({
  selector: 'fun-register-person-last-step',
  templateUrl: './register-person-last-step.component.html',
  styleUrls: ['./register-person-last-step.component.scss']
})
export class RegisterPersonLastStepComponent implements OnInit {

  @Output() public finishEvent = new EventEmitter<any>();
  @Output() public nextCheckEvent = new EventEmitter<any>();
  @Output() public prevCheckEvent = new EventEmitter<any>();
  @Output() public prevModalEvent = new EventEmitter<any>();
  @Output() public openFinishModalEvent = new EventEmitter<any>();
  @Output() public accreditationChecksEvent = new EventEmitter<any>();
  @Output() public bankDocumentEvent = new EventEmitter<any>();
  @Output() public acceptLwTermsEvent = new EventEmitter<any>();
  @Output() public otherMomentEventEvent = new EventEmitter<any>();
  @Output() public signaturitAccreditEvent = new EventEmitter<any>();
  @Output() public signaturitSignedEvent = new EventEmitter<any>();
  @Input() isMobileApp: any;
  @Input() lastStep: any;
  @Input() userInfo: any;
  @Input() profile: any;
  @Input() onlyAccredit: any;

  @ViewChild('registerIfameComponent', { static: false }) registerIfameComponent: RegisterIfameComponent;

  username: any;
  step: number;
  btnList: any;
  image: number;
  text: string;
  sendXHR: string;
  accreditationChecks: any;
  bankDocument: any;
  backBtn = 'secondary-disabled';
  loading = false;
  acceptLwTerms: boolean;
  stepList = [];

  checksStepIndex: number;
  documentsStepIndex: number;
  lwTermsIndex: number;
  finishIndex: number;
  signIndex: number;
  acceptedTerms: boolean;
  hasToAaceptterms: boolean;
  signMessageIndex: number;

  signaturitUrl: string;

  accreditedOptions = [
    {
      title: this.translateService.instant('register.accredited_investor.higher'),
      text: this.translateService.instant('register.accredited_investor.more_than')
    },
    {
      title: this.translateService.instant('register.accredited_investor.unlimited'),
      text: this.translateService.instant('register.accredited_investor.more_than_2')
    }
  ];
  checkStatus = 0;
  personalId: string;
  btnSign = [];
  imageSign = 7;
  textSign = this.translateService.instant('register.accredited_investor.signaturit_txt');

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.sendXHR === 'primaryDark') {
      this.next();
    }
  }

  constructor(public chdRef: ChangeDetectorRef, private translateService: TranslateService) { }

  ngOnInit() {
    this.step = 0;
    if (this.userInfo !== undefined && this.userInfo.info !== undefined && this.userInfo.info.email) {
      this.username = this.userInfo.info.email;
    } else {
      this.username = this.profile.username;
    }
    this.sendXHR = 'primaryDark';
    if (this.profile === undefined) {
      this.checksStepIndex = 1;
      this.documentsStepIndex = 2;
      this.signMessageIndex = 3;
      this.signIndex = 4;
      this.lwTermsIndex = 5;
      this.finishIndex = 6;
      this.stepList = [
        { index: 0, name: 'advantages' },
        { index: 1, name: 'checksStep' },
        { index: 2, name: 'documentStep' },
        { index: 3, name: 'signMessageStep' },
        { index: 4, name: 'signStep' },
        { index: 5, name: 'lwTermsStep' },
        { index: 6, name: 'finishStep'}
      ];
      //this.step = (this.lastStep) ? 4 : 0;
      if (this.lastStep) {
        this.sendXHR = 'primaryDark-disabled';
        this.step = 5;
      } else {
        this.step = 0;
      }
      this.hasToAaceptterms = true;
    } else {
      const stpList = [];
      if (this.profile.acreditationStatus === 'caducada') {
        stpList.push('advantages', 'checksStep', 'documentStep');
        this.checksStepIndex = 1;
        this.documentsStepIndex = 2;
        this.signMessageIndex = 3;
        this.signIndex = 4;
      } else if (
        (!this.profile.acreditatedInvestor
        || this.profile.acreditatedInvestor === null
        || this.profile.acreditatedInvestor === undefined) &&
        (!this.profile.accountAcreditation50kIncomings
          || this.profile.accountAcreditation50kIncomings === null
          || this.profile.accountAcreditation50kIncomings === undefined) &&
        (!this.profile.accountAcreditation100kWealth
          || this.profile.accountAcreditation100kWealth === null
          || this.profile.accountAcreditation100kWealth === undefined) &&
          (!this.profile.accountAcreditationProfessionalAdvice
            || this.profile.accountAcreditationProfessionalAdvice === null
            || this.profile.accountAcreditationProfessionalAdvice === undefined)
        ) {
          stpList.push('advantages', 'checksStep', 'documentStep', 'signMessageStep', 'signStep');
          this.checksStepIndex = 1;
          this.documentsStepIndex = 2;
          this.signMessageIndex = 3;
          this.signIndex = 4;
        } else if (this.profile.acreditationVerificationBankInfo === null) { //acreditationVerificationBankInfo
          stpList.push('advantages', 'documentStep', 'signMessageStep', 'signStep');
          this.documentsStepIndex = 1;
          this.signMessageIndex = 2;
          this.signIndex = 3;
        } else if (this.profile.acreditationStatus === 'en_proceso' && this.profile.acreditationSignedIfInProgress) {
          stpList.push('signMessageStep', 'signStep');
          this.signMessageIndex = 0;
          this.signIndex = 1;
          this.sendXHR = 'primaryDark';
          this.signaturitAccreditEvent.emit();
        }
      if (!this.profile.acceptPaymentEntity
        || this.profile.acceptPaymentEntity === null
        || this.profile.acceptPaymentEntity === undefined) {
          this.hasToAaceptterms = true;
          stpList.push('lwTermsStep');
          this.lwTermsIndex = stpList.length - 1;
          //this.lwTermsIndex = (this.checksStepIndex === 1 && this.documentsStepIndex === 2) ? 4 : 0;
          /*if (this.checksStepIndex === 1 && this.documentsStepIndex === 2) {
            this.lwTermsIndex = 4;
          } else {
            this.lwTermsIndex = 0;
          }*/
        } else {
          this.hasToAaceptterms = false;
        }
      stpList.push('finishStep');
      this.finishIndex = stpList.length - 1;

      for (let i = 0; i < stpList.length; i++) {
        this.stepList.push({ index: i, name: stpList[i] });
      }
      if (this.lastStep && this.onlyAccredit !== true) {
        if (!this.hasToAaceptterms || this.hasToAaceptterms === undefined) {
          this.step = this.finishIndex; //this.lwTermsIndex;
        } else {
          this.step = this.lwTermsIndex;
        }
        // this.step = (!this.hasToAaceptterms) ? this.finishIndex : this.lwTermsIndex;
      } else {
          this.step = 0;
      }
    }

    this.getIdBtnName();

    this.image = 6;
    this.btnList = [
      {
        type: 'primaryDark',
        index: 1,
        title: 'terminar',
      },
    ];
    this.text = this.translateService.instant('messages.congratulations_registration_ok');
  }

  controlChecks(data) {
    this.accreditationChecks = data;
    this.chdRef.detectChanges();
  }

  getBankDocument(value) {
    this.bankDocument = value;
    this.sendXHR = 'primaryDark';
    this.chdRef.detectChanges();
  }

  errorFile() {
    this.sendXHR = 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }

  checkedLastStep(value) {
    this.acceptLwTerms = value;
    if (value) {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  next() {
    if (this.step === this.checksStepIndex && (this.accreditationChecks === undefined || (
      this.accreditationChecks.first === false
      && this.accreditationChecks.second === false
      && this.accreditationChecks.third === false
    ))) {
      this.finishRegister();
    } else {
      if (this.step === this.checksStepIndex) {
        this.accreditationChecksEvent.emit(this.accreditationChecks);
        this.controlCheckStatus();
      }
      if (this.step === this.documentsStepIndex) {
        this.bankDocumentEvent.emit(this.bankDocument);
      }
      if (this.step === this.lwTermsIndex) {
        let model: any;
        if (
          (this.profile === undefined && this.accreditationChecks === undefined) ||
          (this.accreditationChecks === undefined
          && (this.profile.accountAcreditation50kIncomings === null
            || this.profile.accountAcreditation50kIncomings === undefined
            || this.profile.accountAcreditation50kIncomings === false)
          && (this.profile.accountAcreditation100kWealth === null
            || this.profile.accountAcreditation100kWealth === undefined
            || this.profile.accountAcreditation100kWealth === false)
          && (this.profile.accountAcreditationProfessionalAdvice === null
            || this.profile.accountAcreditationProfessionalAdvice === undefined
            || this.profile.accountAcreditationProfessionalAdvice === false))
          ) {
          model = {
            accountAcreditation50kIncomings: false,
            accountAcreditation100kWealth: false,
            accountAcreditationProfessionalAdvice: false,
            acceptPaymentEntity: this.acceptLwTerms,
            profileType: 1
          };
        } else {
          model = { acceptPaymentEntity: this.acceptLwTerms, profileType: 1 };
        }
        this.acceptLwTermsEvent.emit(model);
      }
      this.step++;
      this.getIdBtnName();
      if (this.step !== this.checksStepIndex) {
        this.sendXHR = 'primaryDark-disabled';
      }
      if (this.step === this.signMessageIndex) {
        this.sendXHR = 'primaryDark';
        this.signaturitAccreditEvent.emit();
      }
      if (this.step === this.finishIndex) {
        if (this.isMobileApp !== undefined) {
          this.openFinishModal();
        }
      }
    }
    this.nextCheckEvent.emit();
    this.chdRef.detectChanges();
  }

  prev() {
    if (this.step !== 0) {
      this.step--;
      this.sendXHR = 'primaryDark-disabled';
    } else {
      this.prevModalEvent.emit();
    }
    if (this.step === 0) {
      this.sendXHR = 'primaryDark';
    }
    this.prevCheckEvent.emit();
    this.chdRef.detectChanges();
  }

  controlCheckStatus() {
    if (this.accreditationChecks.first) {
      this.checkStatus = 0;
    } else if (this.accreditationChecks.second) {
      this.checkStatus = 1;
    } else {
      this.checkStatus = 2;
    }
    this.chdRef.detectChanges();
  }

  finishRegister() {
    this.sendXHR = 'primaryDark-disabled';
    this.otherMomentEventEvent.emit();
    //this.step = (this.hasToAaceptterms) ? this.finishIndex : this.lwTermsIndex;
    if (this.hasToAaceptterms !== true) {
      this.step = this.finishIndex;
    } else {
      this.step = this.lwTermsIndex;
    }
    this.chdRef.detectChanges();
  }
  finish() {
    this.finishEvent.emit();
    if (this.isMobileApp !== undefined) {
      this.openFinishModal();
    }
    this.chdRef.detectChanges();
  }
  openFinishModal() {
    this.openFinishModalEvent.emit(this.userInfo);
  }
  loadingComponent() {
    this.loading = true;
    this.chdRef.detectChanges();
  }
  removeLoading() {
    this.loading = false;
    this.chdRef.detectChanges();
  }

  getSignaturitUrl() {
    this.signaturitAccreditEvent.emit();
    this.chdRef.detectChanges();
  }

  public setSignaturitUrl(url) {
    this.signaturitUrl = url;
    if (this.step === this.signIndex) {
      this.registerIfameComponent.startSign(url);
    }
    //this.registerIfameComponent.startSign(url);
    this.chdRef.detectChanges();
  }

  downloadFile() {}
  onChangeAcceptRisks() {}

  getIdBtnName() {
    let name;
    if (this.stepList[this.step] && this.stepList[this.step].name && this.stepList[this.step].name !== undefined) {
      name = this.stepList[this.step].name;
    } else {
      name = '';
    }
    switch (this.stepList[this.step].name) {
      case 'advantages':
        this.personalId = 'pf_accreditation_request';
        break;
      case 'checksStep':
        this.personalId = 'pf_accreditation_conditions';
        break;
      case 'documentStep':
        this.personalId = 'pf_accreditation_documents';
        break;
      case 'lwTermsStep':
        this.personalId = 'pf_terms_and_conditions';
        break;
      default:
        //this.personalId = 'pf_full_profile';
        break;
    }
    this.chdRef.detectChanges();
  }

  signed() {
    this.signaturitSignedEvent.emit();
    this.chdRef.detectChanges();
  }

}
