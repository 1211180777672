import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { HubspotService } from 'src/app/services/hubspot.service';
import '@justinribeiro/lite-youtube';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'fundeen-activation-wallet-message',
  templateUrl: './activation-wallet-message.component.html',
  styleUrls: ['./activation-wallet-message.component.scss']
})
export class ActivationWalletMessageComponent implements OnInit {

  @Output() public transferEvent = new EventEmitter<any>();
  @Output() public clickedProjectEvent = new EventEmitter<any>();

  loading = true;
  showVideo = true; // Cambiar si queremos que s emuestre el video de onboarding
  info: any;

  constructor(
    private chdRef: ChangeDetectorRef, 
    private amplitudeService: AmplitudeService,
    private storage: Storage,
    private HubspotService: HubspotService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.amplitudeService.trackEvent('register_wallet_validated', {});
  }

  public loadedPage(project) {
    this.info = project;
    this.loading = false;
    this.chdRef.detectChanges();
  }

  public msgStartInvert() {
    this.loading = false;
    this.showVideo = false;  
    this.chdRef.detectChanges();
  }

  transfer() {
    this.transferEvent.emit();
    this.chdRef.detectChanges();
  }

  onClickValoration(value) {
    this.loading = true;
    this.storage.get('USERNAME').then((val) => {
      this.HubspotService.searchContact(val).subscribe((resHusbpot: any) => {
        if(resHusbpot.success) {
          const contacts = resHusbpot.data.results;
          if(contacts.length) {
            const idhbp = contacts[0].id;
            if(idhbp !== '') {
              const updateParams = {
                "video_onboarding": value
              };
              this.HubspotService.updateContact(idhbp, updateParams).subscribe((resHusbpot: any) => {
                this.msgStartInvert();
              });
            }
          } else {
            this.msgStartInvert();
          }
        } else {
          this.msgStartInvert();
        }
      });
    });
    this.chdRef.detectChanges();
  }

  emitClickProject(event, project) {
    const model = {
      event: event,
      project: project
    };
    this.clickedProjectEvent.emit(model);
  }

}
