import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-overview-aboutproject-section',
  templateUrl: './overview-aboutproject-section.component.html',
  styleUrls: ['./overview-aboutproject-section.component.scss'],
})
export class OverviewAboutprojectSectionComponent implements OnInit {
  @Input() aboutProjectInfo: any;
  @ViewChild('sliderImg')  sliderImg: IonSlides;

  text: string;
  imageUrl: string;
  isMoreOpen = false;

  slideOpts = {
    initialSlide: 0,
    speed: 1000,
    paginationClickable: true,
    effect: 'slide',
    spaceBetween: 20,
    slidesPerView: 1,
    slideToClickedSlide: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    }
  };
  sliderIndex = 0;

  constructor() { }

  ngOnInit() {
    if (this.aboutProjectInfo.imageUrl !== null && this.aboutProjectInfo.imageUrl !== undefined) {
      this.imageUrl = this.aboutProjectInfo.imageUrl;
    }
    this.text = this.aboutProjectInfo.text.replace(/\↵/ig, '<br>');
  }

  next() {
    this.sliderImg.slideNext();
  }

  prev() {
    this.sliderImg.slidePrev();
  }

  getCurrentItem() {
    this.sliderImg.getActiveIndex().then(val => {
      this.sliderIndex = val;
    });
  }

}
