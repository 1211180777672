import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-register-person-last-step-end',
  templateUrl: './register-person-last-step-end.component.html',
  styleUrls: ['./register-person-last-step-end.component.scss']
})
export class RegisterPersonLastStepEndComponent implements OnInit {

  @Output() public checkedEvent = new EventEmitter<any>();

  checked: boolean;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.checked = false;
    this.chdRef.detectChanges();
  }
  onChangeAcceptRisks() {
    this.checked = !this.checked;
    this.checkedEvent.emit(this.checked);
    this.chdRef.detectChanges();
  }

}
