import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { ToastService } from '../../toast/toast.service';
import { InputComponent } from '../../input/input.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-invest-reservation',
  templateUrl: './invest-reservation.component.html',
  styleUrls: ['./invest-reservation.component.scss'],
})
export class InvestReservationComponent implements OnInit, AfterViewInit {
  @ViewChild('inputAmount', { static: false }) inputAmountRef: InputComponent;
  @Input() amount: any;
  @Input() data: any;
  @Input() modelData: any;
  @Output() public increaseToInvest = new EventEmitter<any>();
  @Output() public eventCloseModalCard = new EventEmitter<any>();
  @Output() public deleteHeaderEvent = new EventEmitter<any>();
  @Output() public reserveHubspotEvent = new EventEmitter<any>();

  public cashForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorCash = false;
  public prevent = 0;
  moreTanAvailable: boolean;
  step = 0;
  btntype = 'primaryDark-disabled';

  loading = false;
  checkedRespon = false;
  txtError: string;

  imageOv = 7;
  textOv: string;
  btnListOv = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('REGISTER.PASSPORT.BTN'),
  }];

  minReserveAmount = 0;

  constructor(
    private chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    public toastService: ToastService,
    private overviewService: OverviewProjectService,
    private amplitudeService: AmplitudeService
    ) {
      this.cashForm = new FormGroup({
        cash: new FormControl('',
        [Validators.required, Validators.min(1)])
      });
      this.formControlKeys = Object.keys(this.cashForm.controls);
      this.errorMessages = {
        min: this.translate.instant('input-errors-messages.incorrect-amount'),
        pattern: this.translate.instant('input-errors-messages.incorrect-amount'),
        required:  this.translate.instant('input-errors-messages.required'),
      };
     }

  ngOnInit() {
    const slug = this.data.slug;
    const maxTxt = this.translate.instant(`LIMITED_RESERVE.${slug}`);

    this.textOv = this.translate.instant('FORMALITIES.SUCCESS_RESERVE_TXT', { projectName: this.data.projectName});
    if (this.amount > 0) {
      this.btntype = 'primaryDark';
      this.calculate();
    }
    registerLocaleData(es);
    if (!isNaN(Number(maxTxt))) {
      this.minReserveAmount = Number(maxTxt);
    }
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.calculate();
    this.chdRef.detectChanges();
  }

  sendValue() {
    let valueCash = this.cashForm.value.cash;
    valueCash = valueCash.replace(/\D/g, '');
    if (Number(valueCash) !== null && Number(valueCash) !== undefined) {}
    this.increaseToInvest.emit(Number(valueCash));
    this.calculate();
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  calculate() {
    let valueCash = this.cashForm.value.cash;
    valueCash = valueCash.replace(/\D/g, '');
    if (Number(valueCash) && Number(valueCash) !== 0) {
      if (this.data.co2kwh && this.data.co2kwh !== 0 && this.data.co2kwh !== null && this.data.co2kwh !== undefined) {
        this.prevent = this.data.power * 1500 * this.data.co2kwh * this.data.term * Number(valueCash) / this.data.total;
      } else {
        this.prevent = this.data.power * 1500 * 0.1 * this.data.term * Number(valueCash) / this.data.total;
      }
    } else {
      this.prevent = 0;
    }
    if (Number(valueCash) > (this.data.total - this.data.contributed)) {
      this.moreTanAvailable = true;
    } else {
      this.moreTanAvailable = false;
    }
    this.chdRef.detectChanges();
    this.inputAmountRef.triggerKeyup();
  }

  controlBtn() {
    const limitDown = this.modelData.id === 75 ? 1500 : 1;
    const limitUp = this.modelData.id === 51 ? 2500 : this.data.total * 0.1;

    let valueCash = this.cashForm.value.cash;
    valueCash = valueCash.replace(/\D/g, '');
    if (this.step === 0) {
      if (((Number(valueCash) + this.data.contributed) > this.data.total)) {
        this.btntype = 'primaryDark-disabled';
      } else if (Number(valueCash) < this.minReserveAmount) {
        this.btntype = 'primaryDark-disabled';
      } else if (this.cashForm.valid && (Number(valueCash)) >= limitDown && (Number(valueCash)) <= limitUp) {
        this.btntype = 'primaryDark';
      } else {
        this.btntype = 'primaryDark-disabled';
      }
    } else if (this.step === 1) {
      if (!this.checkedRespon) {
        this.btntype = 'primaryDark-disabled';
      } else {
        this.btntype = 'primaryDark';
      }
    }
    this.chdRef.detectChanges();
  }

  prev() {
    if (this.step === 0) {
      this.eventCloseModalCard.emit();
    } else {
      this.step--;
      this.controlBtn();
    }
    this.chdRef.detectChanges();
  }

  next() {
    this.step++;
    this.controlBtn();
    if (this.step === 2) {
      this.reserveInvestment();
      this.deleteHeaderEvent.emit(true);
    }
    this.chdRef.detectChanges();
  }

  clickBtn(value) {
    this.eventCloseModalCard.emit();
    this.chdRef.detectChanges();
  }

  reserveInvestment() {
    let valueCash = this.cashForm.value.cash;
    valueCash = valueCash.replace(/\D/g, '');
    const model = {
      project_id: this.modelData.id,
      amount: Number(valueCash),
      accept_conditions: this.checkedRespon,
      username: this.modelData.username,
      wallet: this.modelData.wallet
    };
    this.loading = true;
    this.overviewService.reserveInvestment(model).subscribe(resp => {
      this.loading = false;
      this.amplitudeService.trackEvent('reservation_intention', { amount: model.amount, status: 'ok' });
      this.reserveHubspotEvent.emit(resp);
      this.chdRef.detectChanges();
    }, error => {
      this.deleteHeaderEvent.emit(false);
      this.prev();
      this.loading = false;
      this.getErrors(error.error.errors);
      this.amplitudeService.trackEvent('reservation_intention', { amount: model.amount, status: 'error', msg: this.txtError });
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  checkRespon() {
    this.checkedRespon = !this.checkedRespon;
    this.controlBtn();
    this.chdRef.detectChanges();
  }

  getErrors(errors) {
    this.txtError = '';
    errors.forEach(element => {
      this.translate.get('invest.errors.' + element).subscribe(x => {
        this.txtError = this.txtError.concat(x);
        this.txtError = this.txtError.concat(' ');
      });
    });
    if (this.txtError !== '') {
      this.toastService.showToast(this.txtError, 'warning', true);
    }
    this.chdRef.detectChanges();
  }

}
