<div class="register">
    <div class="body">
        <div class="mail-container">
            <div class="inp">
                <fun-input
                    placeholder="nombre@tucorreo.es"
                    [parentForm]="emailForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="email"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="E-mail"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="**********"
                    [parentForm]="emailForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="pwd"
                    type="password"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.email.password' | translate }}"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
