import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetService } from '../shared/components/action-sheet/action-sheet.service';
import { HelpMobileComponent } from '../shared/components/help/help-mobile/help-mobile.component';

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(public actionSheetService: ActionSheetService, private translate: TranslateService) {}

  openHelp(level, index, page) {
    const title = this.getTitle(index, page);
    const modalParams = {
      hasSecondLevel: true,
      setLavel: level,
      setIndex: index,
      fullData: [
        {
          index: 0,
          level: 'first',
          subtitles: [
            { title: this.translate.instant('HELP.' + page + '.Q1.TITLE'), index: 1 },
            { title: this.translate.instant('HELP.' + page + '.Q2.TITLE'), index: 2 },
            { title: this.translate.instant('HELP.' + page + '.Q3.TITLE'), index: 3 },
          ],
        },
        {
          index: 1,
          level: 'second',
          text: [this.translate.instant('HELP.' + page + '.Q1.TEXT_1'), this.translate.instant('HELP.' + page + '.Q1.TEXT_2')],
        },
        {
          index: 2,
          level: 'second',
          text: [this.translate.instant('HELP.' + page + '.Q2.TEXT_1'), this.translate.instant('HELP.' + page + '.Q2.TEXT_2')],
        },
        {
          index: 3,
          level: 'second',
          text: [this.translate.instant('HELP.' + page + '.Q3.TEXT_1'), this.translate.instant('HELP.' + page + '.Q3.TEXT_2')],
        },
        {
          index: 4,
          level: 'second',
          text: [this.translate.instant('HELP.' + page + '.Q4.TEXT_1'), this.translate.instant('HELP.' + page + '.Q4.TEXT_2')],
        },
        {
          index: 5,
          level: 'second',
          text: [this.translate.instant('HELP.' + page + '.Q5.TEXT_1'), this.translate.instant('HELP.' + page + '.Q5.TEXT_2')],
        }
      ],
    };
    this.actionSheetService.showModal(HelpMobileComponent, modalParams, '', '', true).subscribe((modal: any) => {
      modal.setTitle(title);
      modal.changeLevel.subscribe((resp) => {
        const newTitle = this.getTitle(0, page);
        modal.setTitle(newTitle);
        modal.getChildComponent().subscribe((componentRef: HelpMobileComponent) => {
          componentRef.setFirstLevel();
        });
      });
      modal.getChildComponent().subscribe((componentRef: HelpMobileComponent) => {
        componentRef.changeS.subscribe((res) => {
          const newTitle = this.getTitle(res, page);
          modal.setTitle(newTitle);
          modal.secondLevel();
        });
      });
      modal.onClose().subscribe();
    });
  }
  getTitle(index, page) {
    let title;
    switch (index) {
      case 0:
        title = this.translate.instant('HELP.TITLE');
        break;
      case 1:
        title = this.translate.instant('HELP.' + page + '.Q1.TITLE');
        break;
      case 2:
        title = this.translate.instant('HELP.' + page + '.Q2.TITLE');
        break;
      case 3:
        title = this.translate.instant('HELP.' + page + '.Q3.TITLE');
        break;
      case 4:
        title = this.translate.instant('HELP.' + page + '.Q4.TITLE');
        break;
      case 5:
        title = this.translate.instant('HELP.' + page + '.Q5.TITLE');
        break;
    }
    return title;
  }
}
