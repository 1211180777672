
  <div class="card-container">
    <div class="bg-image"></div>
    <div class="filter"></div>
    <div class="header">
        <div class="title-container">
        </div>
    </div>
    <div class="content-container">
        <div class="tag">

        </div>
        <div>
            <div class="spacing-four"></div>
        </div>
        <div class="info-container">
            <div class="info-subheader">
                <div class="global-income">
                    <p class="info-sub"><ion-skeleton-text style="width: 90%" animated></ion-skeleton-text></p>
                </div>
                <div class="spacing-four"></div>
                <div class="time-remaining">
                    <p class="info-sub">
                      <ion-skeleton-text style="width: 90%" animated></ion-skeleton-text>
                    </p>
                </div>
                <div class="spacing-four second"></div>
                <div class="pr-to-cap">
                    <p class="info-legend"><ion-skeleton-text style="width: 90%" animated></ion-skeleton-text></p>
                </div>
                <div class="line">
                    <div class="mar-top"></div>
                    <div class="grey-line"></div>
                    <div class="spacing-three"></div>

                </div>
            </div>
        </div>
        <div class="offers-container">
          <fun-skeleton-list></fun-skeleton-list>
        </div>
    </div>

</div>
