<div class="general-container">
  <div class="steps_header" *ngIf="step <= 5">
    <div class="step" [ngClass]="{ selected: step === 1, no_selected: step !== 1 }">
      <div *ngIf="step > 1" class="checked"></div> <p [ngClass]="{ checked_step: step > 1 }"><span *ngIf="step === 1">1</span> {{ 'AUTOINVEST.HEADER.TITLE_1' | translate }}</p>
    </div>
    <div class="step" [ngClass]="{ selected: step === 2, no_selected: step !== 2 }">
      <div *ngIf="step > 2" class="checked"></div> <p [ngClass]="{ checked_step: step > 2 }"><span *ngIf="step <= 2">2</span> {{ 'AUTOINVEST.HEADER.TITLE_2' | translate }}</p>
    </div>
    <div class="step" [ngClass]="{ selected: step === 3, no_selected: step !== 3 }">
      <div *ngIf="step > 3" class="checked"></div> <p [ngClass]="{ checked_step: step > 3 }"><span *ngIf="step <= 3">3</span> {{ 'AUTOINVEST.HEADER.TITLE_3' | translate }}</p>
    </div>
    <div class="step" [ngClass]="{ selected: step === 4, no_selected: step !== 4 }">
      <div *ngIf="step > 4" class="checked"></div><p [ngClass]="{ checked_step: step > 4 }"><span *ngIf="step <= 4">4</span> {{ 'AUTOINVEST.HEADER.TITLE_4' | translate }}</p>
    </div>
    <div class="step" [ngClass]="{ selected: step === 5, no_selected: step !== 5 }">
      <p><span>5</span> {{ 'AUTOINVEST.HEADER.TITLE_5' | translate }}</p>
    </div>
  </div>

  <div class="body_content">
    <div class="steps_body">
      <!---->
      <div *ngIf="step === 1" class="step_one">
        <div class="txt_container grey-bg">
          <h2 class="title">{{ 'AUTOINVEST.STEP_1.TITLE' | translate }}</h2>
          <p class="information" innerHtml="{{ 'AUTOINVEST.STEP_1.INFORMATION_1' | translate }}"></p>
          <p class="information" innerHtml="{{ 'AUTOINVEST.STEP_1.INFORMATION_2' | translate }}"></p>
          <p class="information" innerHtml="{{ 'AUTOINVEST.STEP_1.INFORMATION_3' | translate }}"></p>
        </div>
        <!-- <div class="help_container">
          <fun-help [questions]="questions" (eventQuestionClicked)="questionClicked($event)"></fun-help>
        </div> -->
      </div>
      <!---->
      <div *ngIf="step === 2" class="step_two">
        <div class="grey-bg full-grey-bg">
          <div class="second_title">
            <p class="information information_title">{{ 'AUTOINVEST.STEP_2.TITLE' | translate }}</p>
          </div>
          <div  class="second_content">
            <div  class="second_body_section second_params_container">
              <div class="inp_content">
                <label>{{ 'AUTOINVEST.STEP_2.RENT_ESTIM' | translate }}</label>
                <div class="inp inp_multi_3">
                  <div *ngFor="let info of infData.profEstim"  (click)="selectType('profEstim', info.data)"
                  class="inp_options" [ngClass]="{'inp_options_selected': info.selected}"><p> {{ info.data }} </p></div>
                </div>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.investAnyProf}" (click)="selectChecks('investAnyProf')">{{ 'AUTOINVEST.STEP_2.INVEST_CHECK' | translate }}</p>
              </div>
              <!--<div class="inp_content">
                <label>Rating</label>
                <div class="inp">
                  <div class="input_rating_container">
                    <fun-input
                      placeholder="70%"
                      [parentForm]="ratingForm"
                      [formControlKeys]="formControlKeys"
                      [errorMessages]="errorMessages"
                      [isSubmit]="isSubmit"
                      controlName="aType"
                      type="number"
                      [errorLogin]="errorAmount"
                      labelName="A"
                    ></fun-input>
                  </div>
                  <div class="input_rating_container">
                    <fun-input
                      placeholder="20%"
                      [parentForm]="ratingForm"
                      [formControlKeys]="formControlKeys"
                      [errorMessages]="errorMessages"
                      [isSubmit]="isSubmit"
                      controlName="bType"
                      type="number"
                      [errorLogin]="errorAmount"
                      labelName="B"
                    ></fun-input>
                  </div>
                  <div class="input_rating_container">
                    <fun-input
                      placeholder="10%"
                      [parentForm]="ratingForm"
                      [formControlKeys]="formControlKeys"
                      [errorMessages]="errorMessages"
                      [isSubmit]="isSubmit"
                      controlName="cType"
                      type="number"
                      [errorLogin]="errorAmount"
                      labelName="C"
                    ></fun-input>
                  </div>
                </div>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.investAnyRating}" (click)="selectChecks('investAnyRating')">Invierte en proyectos con cualquier rating</p>
              </div>-->
              <div class="inp_content">
                <label>{{ 'AUTOINVEST.STEP_2.DISTRIB_TYPE' | translate }}</label>
                <div class="inp inp_multi_2">
                  <div class="inp_options" [ngClass]="{'inp_options_selected': info.selected}"
                  *ngFor="let info of infData.distribType"  (click)="selectType('distribType', info.data)"><p> {{ info.data }} </p></div>
                </div>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.investAnyDistrib}" (click)="selectChecks('investAnyDistrib')">{{ 'AUTOINVEST.STEP_2.ANY_TYPE_CHECK' | translate }}</p>
              </div>
            </div>
            <div class="second_body_section second_params_info">
              <p>{{ 'AUTOINVEST.STEP_2.PARAM_1' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_2.PARAM_2' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_2.PARAM_3' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <div *ngIf="step === 3" class="step_two">
        <div class="grey-bg full-grey-bg">
          <div class="second_title">
            <p class="information information_title">{{ 'AUTOINVEST.STEP_3.TITLE' | translate }}</p>
          </div>
          <div class="second_content">
            <div class="second_body_section second_params_container">
              <div class="inp_content">
                <fun-input
                  placeholder="{{ 'AUTOINVEST.STEP_3.INPUT_PLACEHOLDER' | translate }}"
                  [parentForm]="investForm"
                  [formControlKeys]="formControlKeys"
                  [errorMessages]="errorMessages"
                  [isSubmit]="isSubmit"
                  controlName="annualInvest"
                  type="invest"
                  [errorLogin]="errorAmount"
                  labelName="{{ 'AUTOINVEST.STEP_3.INPUT_LABEL' | translate }}"
                  (keyup)="getInvestValue()"
                  ></fun-input>
              </div>
              <div class="inp_content">
                <label>{{ 'AUTOINVEST.STEP_3.FRECUENCY' | translate }}</label>
                <div class="inp inp_multi_3">
                  <div class="inp_options" [ngClass]="{'inp_options_selected': info.selected}"
                  *ngFor="let info of infData.investFrecuency"  (click)="selectType('investFrecuency', info.data)"><p>{{ info.data }}</p></div>
                </div>
              </div>
              <div class="inp_content">
                <label>{{ 'AUTOINVEST.STEP_3.TIME' | translate }}</label>
                <div class="inp inp_multi_3">
                  <div class="inp_options inp_options_selected" [ngClass]="{'inp_options_selected': info.selected}"
                  *ngFor="let info of infData.investType"  (click)="selectType('investType', info.data)"><p>{{ info.data }}</p></div>
                </div>
              </div>
              <div class="inp_content">
                <label>{{ 'AUTOINVEST.STEP_3.PERIOD' | translate }}</label>
                <div class="inp inp_multi_3">
                  <div class="inp_options" *ngFor="let info of infData.investPeriod" [ngClass]="{'inp_options_selected': info.selected}"
                    (click)="selectType('investPeriod', info.data)"><p>{{ info.data }}</p></div>
                </div>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.reinvestMoney}" (click)="selectChecks('reinvestMoney')">{{ 'AUTOINVEST.STEP_3.REINVEST_CHECK' | translate }}</p>
              </div>
            </div>
            <div class="second_body_section second_params_info">
              <p>{{ 'AUTOINVEST.STEP_3.PARAM_1' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_3.PARAM_2' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_3.PARAM_3' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_3.PARAM_4' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <div *ngIf="step === 4" class="step_two">
        <div class="grey-bg full-grey-bg">
          <div class="second_title">
            <p class="information information_title">{{ 'AUTOINVEST.STEP_4.TITLE' | translate }}</p>
          </div>
          <div class="second_content">
            <div class="second_body_section second_params_container">
              <div *ngFor="let card of infData.paymentType" class="inp_content" (click)="selectType('paymentType', card.data)">
                <div class="inp inp_check_card" [ngClass]="{'inp_check_card_selected': card.selected}">
                  <div class="check_icon"></div>
                  <p class="title_card">{{ card.title }}</p>
                  <p class="subtitle_card">{{ card.subtitle }}</p>
                </div>
              </div>
            </div>
            <div class="second_body_section second_params_info">
              <p>{{ 'AUTOINVEST.STEP_4.PARAMS_1' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_4.PARAMS_2' | translate }}</p>
              <p>{{ 'AUTOINVEST.STEP_4.PARAMS_3' | translate }}</p>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <div *ngIf="step === 5" class="step_two">
        <div class="grey-bg full-grey-bg">
          <div class="second_title">
            <p class="information information_title">{{ 'AUTOINVEST.STEP_5.TITLE' | translate }}</p>
          </div>
          <div class="second_content">
            <div class="strategy_content">
              <div class="strategy">
                <div class="str_content strategy_info">
                  <p class="str_label">{{ 'AUTOINVEST.STEP_5.PROF' | translate }}</p>
                  <p class="str_inf">{{ profselected }}</p>
                </div>
                <div class="str_content strategy_info">
                  <p class="str_label">{{ 'AUTOINVEST.STEP_5.DISTRIBUTION' | translate }}</p>
                  <p class="str_inf">{{ distribution }}</p>
                </div>
                <div class="str_content strategy_info">
                  <!--<p class="str_label">Estructuración</p>
                  <p class="str_inf">Capital</p>-->
                </div>
                <div class="str_content strategy_action strategy_action_1">
                  <div class="btn_container">
                    <fun-button [buttonType]="'secondary'" title="cambiar" (click)="goToStep(2)"></fun-button>
                  </div>
                </div>
              </div>
              <div class="strategy">
                <div class="str_content strategy_info">
                  <p class="str_label">{{ 'AUTOINVEST.STEP_5.DURATION' | translate }}</p>
                  <p class="str_inf">{{ investTime }}</p>
                </div>
                <div class="str_content strategy_info">
                  <p class="str_label">{{ 'AUTOINVEST.STEP_5.FRECUENCY' | translate }}</p>
                  <p class="str_inf">{{ investFrecuency }}</p>
                </div>
                <div class="str_content strategy_info">
                  <p class="str_label">{{ 'AUTOINVEST.STEP_5.ANNUAL_INVEST' | translate }}</p>
                  <p class="str_inf">{{ totalInvest }}€</p>
                </div>
                <div class="str_content strategy_action strategy_action_2">
                  <div class="btn_container">
                    <fun-button [buttonType]="'secondary'" title="cambiar" (click)="goToStep(3)"></fun-button>
                  </div>
                </div>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.getBenefictsRec}" (click)="selectChecks('getBenefictsRec')">{{ 'AUTOINVEST.STEP_5.BENEFICTS' | translate }}</p>
              </div>
              <div class="inp_content">
                <p class="check" [ngClass]="{'checked': infData.termsandConditions}" (click)="selectChecks('termsandConditions')">{{ 'AUTOINVEST.STEP_5.RISKS' | translate }} <span class="terms_and_conditions">{{ 'AUTOINVEST.STEP_5.TERMS' | translate }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <div *ngIf="step === 6">
        <fun-overal-message [text]="text" [image]="image" ></fun-overal-message>
      </div>
      <!---->
    </div>
    <div *ngIf="step <= 5" class="footer">
      <div class="btn_container first">
        <fun-button [buttonType]="prevBtn" title="{{ 'AUTOINVEST.FOOTER.PREV' | translate }}" (eventClick)="prev()" ></fun-button>
      </div>
      <div class="btn_container secod">
        <fun-button [buttonType]="'default'" title="{{ 'AUTOINVEST.FOOTER.NEXT' | translate }}" (eventClick)="next()" ></fun-button>
      </div>
    </div>
  </div>

  <div *ngIf="step <= 5" class="steps_header footer_next_step">
    <div *ngIf="step === 1" class="step">
      <p><span>2</span> {{ 'AUTOINVEST.HEADER.TITLE_2' | translate }}</p>
    </div>
    <div *ngIf="step === 2" class="step">
      <p><span>3</span> {{ 'AUTOINVEST.HEADER.TITLE_3' | translate }}</p>
    </div>
    <div *ngIf="step === 3" class="step">
      <p><span>4</span> {{ 'AUTOINVEST.HEADER.TITLE_4' | translate }}</p>
    </div>
    <div *ngIf="step === 4" class="step">
      <p><span>5</span> {{ 'AUTOINVEST.HEADER.TITLE_5' | translate }}</p>
    </div>
  </div>

  <div *ngIf="step <= 5" class="line_footer"></div>
</div>