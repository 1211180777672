
    <div class="flex flex-col">
      <div class="text-center relative">
        <div class="absolute top-0 right-0 font-sans badge">{{ date }}</div>
        <!-- <p class="font-sans text-m text-eggplant-100/[0.4]">Concepto</p> -->
        <h3 class="font-sans text-eggplant-100 !text-3xl !p-0 !m-0" >{{ 'DASHBOARD.DETAIL_PROJECT.DAILY_PROD' | translate }}</h3>
      </div>
    </div>

    <div class="flex items-center justify-center gap-x-12 mt-12 mb-24">
      <ul class="flex flex-row gap-x-8 font-sans badge">
        <li class="badge" [ngClass]="{'current': (filter == 'year')}">{{ 'CALENDAR.YEAR' | translate }}</li>
        <li class="badge" [ngClass]="{'current': (filter == 'month')}">{{ 'CALENDAR.MONTH' | translate }}</li>
        <li class="badge" [ngClass]="{'current': (filter == 'day')}">{{ 'CALENDAR.DAY' | translate }}</li>
      </ul>
      <div *ngIf="(filter != 'year')" id="btnResetDaily">
        <fun-new-button size="s" icon="" iconPosition="" buttonType="ghost" title="reset"></fun-new-button>
      </div>
    </div>

    <div class="flex flex-row">
      <div class="basis-1/2">
        <div *ngIf="productionToday !== undefined"  class="flex flex-col font-sans text-eggplant-100" title="{{ productionToday | localeNumber }} €">
          <p class="font-sans text-m">{{ 'DASHBOARD.DAILY_PRODUCTION.REAL' | translate }}</p>
          <p class="font-sans !text-2xl">{{ productionToday | localeNumber }} <span class="!text-m">kWh</span></p>
        </div>
      </div>
      <div class="basis-1/2 text-right">
        <div *ngIf="productionEstimated !== undefined" class="flex flex-col font-sans text-eggplant-100/[0.4] text-3xl" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ productionEstimated| localeNumber }} €">
          <p class="font-sans text-m">{{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED' | translate }}</p>
          <p class="font-sans !text-2xl">{{ productionEstimated | localeNumber }} <span class="!text-m">kWh</span></p>
        </div>
      </div>
    </div>

    <div>
      <div class="w-full h-[320px]">
        <div class="content">
            <div class="graphic">
                <canvas #lineChart id="myChartProduction">{{ chart }}</canvas>
            </div>
        </div>
      </div>
    </div>