<div class="general-container border border-eggplant-100/[0.25] bg-white">
  <div class="flex mb-32 pb-16 border-b border-b-eggplant-100/[0.25] w-full gap-32">
    <div class="w-[33%]">
      <ion-skeleton-text animated style="width: 40%; height: 8px; margin-bottom: 8px;"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 96%; height: 16px"></ion-skeleton-text>
    </div>
    <div class="w-[33%]">
      <ion-skeleton-text animated style="width: 65%; height: 8px; margin-bottom: 8px;"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 84%; height: 16px"></ion-skeleton-text>
    </div>
    <div class="w-[33%]">
      <ion-skeleton-text animated style="width: 50%; height: 8px; margin-bottom: 8px;"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 100%; height: 16px"></ion-skeleton-text>
    </div>
  </div>
  <div class="flex pb-16 w-full gap-32">
    <div class="w-[50%]">
      <ion-skeleton-text animated style="width: 50%; height: 8px; margin-bottom: 8px;"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 100%; height: 18px"></ion-skeleton-text>
    </div>
    <div class="w-[50%]">
      <div class="flex w-full">
        <div class="w-[65%]"></div>
        <ion-skeleton-text animated style="width: 35%; height: 8px; margin-bottom: 8px;"></ion-skeleton-text>
      </div>
      <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
    </div>
  </div>
  <ion-skeleton-text animated style="width: 20%; height: 8px; border-radius: 40px; margin-top: 12px;"></ion-skeleton-text>
  <ion-skeleton-text animated style="width: 100%; height: 16px; margin: 8px 0 42px 0; border-radius: 40px;"></ion-skeleton-text>

  <!-- <ion-skeleton-text animated style="width: 100%; height: 16px; margin: 8px 0;"></ion-skeleton-text> -->
  <ion-skeleton-text animated style="width: 100%; height: 50px; margin: 8px 0;"></ion-skeleton-text>
  <ion-skeleton-text animated style="width: 100%; height: 16px; margin: 8px 0;"></ion-skeleton-text>
</div>