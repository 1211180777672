import { Component, OnInit, HostListener, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-graphics-nav',
  templateUrl: './graphics-nav.component.html',
  styleUrls: ['./graphics-nav.component.scss'],
})
export class GraphicsNavComponent implements OnInit {
  @Input('initial') initial;
  @Output() private eventClick = new EventEmitter<void>();

  public navSlideOpts;
  isMobile: boolean;
  currentPage: string;

  idProject: any;
  projectName: any;
  power: any;
  technology: any;
  location: any;
  data: any;

  constructor() {}

  ngOnInit() {
    switch (this.initial) {
      case 0:
        this.currentPage = 'daily-production';
        break;
      case 1:
        this.currentPage = 'daily-income';
        break;
      case 2:
        this.currentPage = 'yearly-revenues';
        break;
      case 3:
        this.currentPage = 'production';
        break;
    }
    this.createSlider();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.createSlider();
  }

  createSlider() {
    if (window.innerWidth < 750) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.navSlideOpts = {
      initialSlide: this.initial,
      speed: 1000,
      width: 450,
      effect: 'slide', // slide, fade, cube, coverflow, flip
      slidesPerView: 4,
      grabCursor: true,
      slideToClickedSlide: this.isMobile,
      freeMode: this.isMobile,
      centeredSlides: this.isMobile,
    };
  }
  changePage(page) {
    this.currentPage = page;
    this.eventClick.emit(page);
  }
}
