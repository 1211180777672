<div class="general-container border border-eggplant-100/[0.25] bg-white">
  <div class="header">
      <div class="flex header-content border-b border-b-eggplant-100/[0.25]">
        <div *ngIf="info.status !== 9" class="prof-container border-r border-r-eggplant-100/[0.25] flex md:flex-col flex-col-reverse">
            <p class="text-l text-eggplant-100/[0.25] font-sans capitalize">{{ 'PROJECT_LIST.PROFITABILITY' | translate }}</p>
            <p class="text-eggplant-100 uppercase font-sans text-xl md:text-h500 whitespace-nowrap">{{info.profitability | number:'1.0-2':'es'}} <span class="text-h300 lowercase">%</span></p>
        </div>
        <div class="year-container border-r border-r-eggplant-100/[0.25] flex md:flex-col flex-col-reverse">
            <p class="text-l text-eggplant-100/[0.25] font-sans capitalize">{{ 'OVERVIEW_PROJECT.DEAD_LINE' | translate }}</p>
            <p class="text-eggplant-100 uppercase font-sans text-xl md:text-h500 whitespace-nowrap">{{info.id !== 130 ? info.term : '6,5'}} <span class="text-h300 lowercase">{{ 'OVERVIEW_PROJECT.YEARS' | translate }}</span></p>
        </div>
        <div class="inv-container flex flex-col">
            <p class="font-sans text-s text-center text-eggplant-100/[0.4] uppercase labels cap-label border border-eggplant-100/[0.4] w-fit">
              <span *ngIf="info.type === 1">{{ 'OVERVIEW_PROJECT.LOAN' | translate }}</span>
              <span *ngIf="info.type === 2">{{ 'OVERVIEW_PROJECT.DEBT' | translate }}</span>
            </p>
            <p class="font-sans text-s text-center text-eggplant-100/[0.4] uppercase labels border border-eggplant-100/[0.4] w-fit whitespace-nowrap">{{info.power | localeNumber}} MW</p>
        </div>
      </div>
  </div>
  <div class="subheader flex !pt-24" [ngClass]="{'justify-between' : (info.contributed > 0 && projectStatus > 3) || (info.total > 0 && projectStatus === 3),
    'place-content-end' : !(info.contributed > 0 && projectStatus > 3) || (info.total > 0 && projectStatus === 3),
    'flex-col md:flex-row' : (projectStatus > 4 && projectStatus < 12)}">
    <div *ngIf="(info.contributed > 0 && projectStatus > 3) || (info.total > 0 && projectStatus === 3)">
        <p *ngIf="projectStatus === 3" class="font-sans text-m text-eggplant-100/[0.4]">{{ 'PROJECT_LIST.OBJECTIVE' | translate }}</p>
        <p *ngIf="projectStatus > 3" class="font-sansMedium text-3xl w-fit whitespace-nowrap" [ngClass]="{'text-[#0f5e42]' : isFlexibleCompensation, 'bg-gradient-2-text linear-gradient-2' : !isFlexibleCompensation}">{{info.contributed | localeNumber}} €</p>
        <p *ngIf="projectStatus === 3" class="font-sansMedium text-3xl w-fit whitespace-nowrap" [ngClass]="{'text-[#0f5e42]' : isFlexibleCompensation, 'bg-gradient-2-text linear-gradient-2' : !isFlexibleCompensation}">{{info.total | localeNumber}} €</p>
    </div>
    <div>
        <p class="text-right text-m font-sans text-eggplant-100/[0.4]" *ngIf="(projectStatus > 4 && projectStatus < 12 && projectStatus !== 9 &&  projectStatus !== 15)">{{ 'OVERVIEW_PROJECT.STATUS' | translate }}</p>
        <p class="text-right text-xl font-sansMedium text-eggplant-100" *ngIf="(projectStatus > 4 && projectStatus < 12 && projectStatus !== 9 && projectStatus !== 15)">{{ 'OVERVIEW_PROJECT.FINANCIED' | translate }}</p>
        <p class="text-right text-xl font-sansMedium text-eggplant-100" *ngIf=" projectStatus === 9 && projectId === 17">{{ 'PROJECT_LIST.SHOLD' | translate }}</p>
    </div>
  </div>

  <div *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 15)" class="graphic">
      <div *ngIf="((info.contributed > 0 && projectStatus > 3) || (info.total > 0 && projectStatus === 3))" class="percent">
          <h3 class="font-sansMedium text-eggplant-100/[0.25] text-m" *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 15) && (percent < 98 || percent >= 99.9)">
              <span *ngIf="percent <= 5 && percent < 94" [ngStyle]="{ left: percent + '%' }">{{ percent | number:'1.0-1':'es' }}%</span>
              <span *ngIf="percent <= 5 && percent >= 94" [ngStyle]="{ left: '94%' }">{{ percent | number:'1.0-1':'es' }}%</span>
              <span *ngIf="percent > 5 && percent < 94" [ngStyle]="{ left: percent + '%' }">{{ percent | number:'1.0-0':'es' }}%</span>
              <span *ngIf="percent > 5 && percent >= 94" [ngStyle]="{ left: '94%' }">{{ percent | number:'1.0-0':'es' }}%</span>
            </h3>
          <h3 class="font-sansMedium text-eggplant-100/[0.25] text-m" *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 15) && percent >= 98 && percent < 99.9">{{ percent | number:'1.1-1':'es' }}%</h3>
          <h3 class="font-sansMedium text-eggplant-100/[0.25] text-m" *ngIf="projectStatus === 14">{{ percentIncreased | number:'1.0-0':'es' }}%</h3>
      </div>
      <div *ngIf="(((info.contributed > 0 || info.contributed_intention) && projectStatus > 3) || (info.total > 0 && projectStatus === 3))" class="bar-grap-container">
          <div class="bar-container">
              <div class="barEnd" *ngIf="projectStatus !== 4 && projectStatus !== 12 && projectStatus !== 14 && projectStatus !== 15"></div>
              <!---->
              <div class="barIncreaseProgressReserve" [ngStyle]="{ width: percentIncreaseReserv }"
              *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 14)"></div>
              <!---->
              <div class="barIncreaseProgress" [ngStyle]="{ width: percentIncrease }"
              *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 14 || projectStatus === 15)"></div>
              <div class="barProgress" [ngClass]="{ 'fullRounded' : percent > 50}" [ngStyle]="{ width: percentPx }"
              *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 14 || projectStatus === 15)"></div>
          </div>
      </div>
      <div class="flex">
        <p class="font-sans text-l text-eggplant-100/[0.25] w-[75%]" *ngIf="(projectStatus === 4 || projectStatus === 14) && info.contributed_intention > 0">{{ 'FORMALITIES.RESERVE_PROCESS.RESERVE_NUMBER' | translate }}: {{ info.contributed_intention | number:'1.0-0':'es'}} €</p>
        <p class="text-right font-sans text-l text-eggplant-100/[0.25] w-full">{{ 'PROJECT_LIST.OBJECTIVE' | translate }}: {{ objetiveParse | number:'1.0-2':'es' }} M€</p>
      </div>
      <div class="!pt-24">
        <fun-overviiew-status-avatars [yourInvest]="yourInvest" [investors]="info.investors" [status]="projectStatus" [isFlexibleCompensation]="isFlexibleCompensation"></fun-overviiew-status-avatars>
      </div>
  </div>
  <div *ngIf="((projectStatus !== 13 && info.activateInvest) || projectStatus === 14)" class="advise pb-12">
      <fun-new-button *ngIf="(projectStatus === 3)" [buttonType]="btnMain" title="{{ 'OVERVIEW.GET_INFORMED' | translate }}" (eventClick)="btnClick('call')"
      [classExtra]="' !w-full mt-32 '" [responsive]="false" [size]="'l'" ></fun-new-button>
      <fun-new-button [title]="btnInvest" [size]="'xl'" [buttonType]="'colorFull'" (eventClick)="btnClick('invest')"
      *ngIf="projectStatus === 4 || projectStatus === 12 || (projectStatus === 14 && info.available && allowedIncrease)" [classExtra]="' !w-full '" [responsive]="false"></fun-new-button>
      <!---->
      <fun-new-button *ngIf="(projectStatus > 4 && projectStatus < 12)" [title]="btnInvested" [size]="'xl'" [buttonType]="'secondaryDark'" (eventClick)="btnClick('marketplace')"
      [classExtra]="' !w-full '" [responsive]="false"></fun-new-button>
      <!---->
      <fun-new-button  *ngIf="(projectStatus === 15)" size="xl" [buttonType]="btnMain" [title]="'Reservar inversión'" (eventClick)="reserveInvestment()"
      [classExtra]="' !w-full '" [responsive]="false"></fun-new-button>
      <fun-new-button *ngIf="(projectStatus === 14 && (!info.available || !allowedIncrease))" [buttonType]="btnMain" title="{{ 'OVERVIEW.CAPITAL_INCREASE.READ_MORE' | translate }}" (eventClick)="btnClick('info')"
      [classExtra]="' !w-full '" [responsive]="false"></fun-new-button>
      <div class="cancel_invest !pt-8" *ngIf="(projectStatus === 4 || projectStatus === 12 || projectStatus === 5 || projectStatus === 15)" >
        <fun-new-button *ngIf="info.canCancel === true" buttonType="ghost" title="{{ 'movements.cancel_investment' | translate }}" (eventClick)="editInvest()"
        [classExtra]="' !w-full '" [size]="'m'" [responsive]="false"></fun-new-button>
        <fun-new-button *ngIf="info.canCancel !== true && (projectStatus === 12 || projectStatus === 4 || projectStatus === 15)" buttonType="ghost" title="{{ 'OVERVIEW_PROJECT.CALCULATE_B' | translate }}"
        (eventClick)="calculateB()" [classExtra]="' !w-full '" [size]="'m'" [responsive]="false"></fun-new-button>
      </div>
  </div>
  <div *ngIf="(projectId === 41)" class="textLineZuera"></div>
  <div *ngIf="(projectId === 41)"><p class="zueraText">* {{ 'DETAIL_PROJECT_BENEFITS' | translate }}</p></div>
  <div *ngIf="(projectStatus === 3 && info.available === true)">
      <fun-new-button *ngIf="(projectStatus === 3 && info.available === true)" buttonType="primaryDark" [title]="btnInvest" id="begin-checkout" (eventClick)="btnClick('invest')"
      [classExtra]="' !w-full '" [responsive]="false"></fun-new-button>
  </div>
  <div *ngIf="projectStatus === 15 || (projectStatus > 4 && projectStatus < 12)" class="flex"
    [ngClass]="{'!pt-24 border-t border-t-eggplant-100/[0.4]' : (projectStatus > 4 && projectStatus < 12)}">
    <div class="icon-container">
        <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
    </div>
    <p *ngIf="(projectStatus > 4 && projectStatus < 12)" class="font-sans text-m text-gray-40">{{ 'OVERVIEW_PROJECT.TXT_INFO_1' | translate }}</p>
    <p *ngIf="projectStatus === 15" class="font-sans text-m text-gray-40">{{ 'OVERVIEW_PROJECT.TXT_INFO_2' | translate }}</p>
  </div>
</div>

<div *ngIf="info.hasDocuments && projectStatus >= 4">
    <fun-new-document (onClickEvent)="downloadAllDocuments()"></fun-new-document>
</div>