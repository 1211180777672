import { Component, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef, Output, EventEmitter, } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'fun-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss']
})
export class PhoneInputComponent implements OnInit {
  @ViewChild('ng2telinput', {static: false}) ng2TelInput: ElementRef;

  @Input('labelName') labelName;
  @Input('placeholder') placeholder;
  @Input('disabled') disabled;
  @Input() size = 'l';
  @Input() dataValue;
  @Output() public sendValueEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();

  parentForm: FormGroup;
  value: any;
  form: any;
  isInside = false;
  error: boolean;

  inputStyles = {
    'base': 'block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill invalid:border-alert invalid:text-alert mt-6 py-8 pl-12 pr-28',
    'xl': 'text-xl',
    'l': 'text-l',
    'm': 'text-m'
  };

  labelStyles = {
    'base': 'block w-full font-sans text-eggplant-80',
    'xl': 'text-l',
    'l': 'text-l',
    'm': 'text-m'
  };

  labelClass: string;
  inputClass: string;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.labelClass = `${this.labelStyles[this.size]} ${this.labelStyles.base}`;
    this.inputClass = `${this.inputStyles[this.size]} ${this.inputStyles.base}`;
    this.parentForm = new FormGroup({
      prefix: new FormControl('+34', [Validators.required]),
      phone:  new FormControl('', [Validators.required])
    });
    this.chdRef.detectChanges();
  }

  focus() {
    this.isInside = true;
    this.chdRef.detectChanges();
  }

  focusout() {
    setTimeout(() => {
      this.isInside = false;
    }, 50);
    this.chdRef.detectChanges();
  }

  hasError(event) {
    this.error = !event;
    if (this.error) {
      this.sendErrorEvent.emit();
    } else {
      this.sendValueEvent.emit(this.parentForm.value);
    }
    this.chdRef.detectChanges();
  }

  getNumber(event) {
    /* console.log('ng2TelOutput event', event); */
    this.chdRef.detectChanges();
  }

  telInputObject(event) {
    /* console.log('intlTelInputObject event', event); */
    this.chdRef.detectChanges();
  }

  onCountryChange(event) {
    this.parentForm.controls['prefix'].setValue('+' + event.dialCode);
    this.chdRef.detectChanges();
  }

  checkPhone() {
    let input = document.querySelector('#phone') as HTMLElement;
    input.blur();
    input.focus();
    this.chdRef.detectChanges();
  }

  clear() {
    this.parentForm.controls['phone'].setValue('');
    this.chdRef.detectChanges();
  }

  getPhoneData() {
    if (this.parentForm.valid && this.error === false) {
      this.sendValueEvent.emit(this.parentForm.value);
    } else {
      if (this.parentForm.value.phone !== undefined && this.parentForm.value.phone !== '') {
        this.sendErrorEvent.emit();
      }
    }
  }

}
