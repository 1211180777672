import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as momentNs from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { Capacitor } from '@capacitor/core';
import { MovementsModel } from 'src/app/shared/models/movements.models';
import { AmplitudeService } from 'src/app/services/amplitude.service';

const moment = momentNs;

@Component({
  selector: 'app-movement-list',
  templateUrl: './movement-list.component.html',
  styleUrls: ['./movement-list.component.scss'],
})
export class MovementListComponent implements OnInit {
  @Input() movements$: Observable<MovementsModel>;
  @Output() public canceCurrentInvest = new EventEmitter<any>();
  @Output() public editCurrentInvest = new EventEmitter<any>();
  @Output() public loadedEvent = new EventEmitter<any>();
  isInvestor: boolean;
  movements = [];
  initialMovements = [];
  fullMovements: any;
  times = 0;
  activeFilters = 0;
  filterFound = true;

  transactionTypeList = [];

  nameDown = true;
  transactionDown = true;
  dateDown = true;
  amountDown = true;

  listOfFilter: any;

  year0: string;
  year1: string;
  year2: string;

  projectList: string[] = [];
  loading: boolean;
  downloadedXlsx = false;

  modal: HTMLIonModalElement;

  selectedAll = false;
  platform = Capacitor.getPlatform();

  constructor(
    public actionSheetController: ActionSheetController,
    public translate: TranslateService,
    private utils: UtilsService,
    public modalController: ModalController,
    private amplitudeService: AmplitudeService
    ) { }

  ngOnInit() {
    this.getMovementList();
  }

  public getMovementList() {
    this.loading = true;
    this.year0 = (moment().year()).toString();
    this.year1 = (moment().year() - 1).toString();
    this.year2 = (moment().year() - 2).toString();
    registerLocaleData(es);

    this.movements$.subscribe({
      next: data => {
        this.fullMovements = this.orderList(data);
        this.fullMovements.forEach(movement => {
          //movement['showEditBtn'] = false;
          movement['showDeleteMovile'] = false;
          this.times++;
          switch (movement.type) {
            case 1:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.DEPOSIT').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 2:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.PROMOTION').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 3:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.BENEFIT').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 4:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.INVESTMENT').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 5:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.COMMISSION').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 6:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.TAX').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 7:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.WITHDRAWAL').subscribe(x => {movement.movementDefinition = x; });
              if (movement.description.substring(0, 28).toUpperCase() !== 'FIN DE CONTRATO CON LEMONWAY') {
                this.translate.get('MOVEMENTS.MOVEMENT-LIST.TRANSFER').subscribe(x => {movement.description = x; });
              }
              break;
            case 8:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.INTERNAL-TRANSFER').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 9:
              if (movement.amount > 0) {
                this.translate.get('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-SALE').subscribe(x => {movement.movementDefinition = x; });
              } else {
                this.translate.get('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-BUY').subscribe(x => {movement.movementDefinition = x; });
              }
              break;
            case 10:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.REFUND').subscribe(x => {movement.movementDefinition = x; });
              break;
            case 12:
              this.translate.get('MOVEMENTS.MOVEMENT-LIST.REEM').subscribe(x => {movement.movementDefinition = x; });
              break;
          }
          if (movement.projectId && !this.projectList.includes(movement.projectName)) {
            this.projectList.push(movement.projectName);
          }
          const month = Number(movement['dateAccountant'].substring(3,5));
          const year = Number(movement['dateAccountant'].substring(6,11));
          const dateParse =  `${movement['dateAccountant'].substring(0,2)} ${this.utils.parseMonthShort(month)} - ${year}`;
          movement['dateParse'] = dateParse;
        });
        if (this.fullMovements.length <= 4) {
          this.movements = this.fullMovements;
          this.selectedAll = true;
        } else {
          for (let i = 0; i < 4; i++) {
            this.movements.push(this.fullMovements[i])
          }
        }
        if (this.movements && this.movements !== undefined && this.movements.length > 0) {
          this.isInvestor = true;
          this.getFilterList();
        } else {
          this.isInvestor = false;
        }
        this.loading = false;
        this.loadedEvent.emit();
      },
      error: error => {
        this.isInvestor = false;
        this.loading = false;
        this.loadedEvent.emit();
      }
    });
  }

  orderList(movements) {
    movements.sort(function(b, a) {
      if (b.dateAccountant === a.dateAccountant && b.amount < a.amount) {
        return -1;
      }
      return 0;
    });
    return movements;
  }

  getFilterList() {
    const deposit = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DEPOSIT'));
    const promotion = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.PROMOTION'));
    const benefit = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.BENEFIT'));
    const investment = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.INVESTMENT'));
    const commission = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.COMMISSION'));
    const tax = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.TAX'));
    const withdrawal = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.WITHDRAWAL'));
    const transfer = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.INTERNAL-TRANSFER'));
    const marketplaceSale = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-SALE'));
    const marketplaceBuy = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-BUY'));
    const refund = this.movements.filter((item) => item.movementDefinition === this.translate.instant('MOVEMENTS.MOVEMENT-LIST.REFUND'));

    if (deposit.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DEPOSIT')); }
    if (promotion.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.PROMOTION')); }
    if (benefit.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.BENEFIT')); }
    if (investment.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.INVESTMENT')); }
    if (commission.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.COMMISSION')); }
    if (tax.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.TAX')); }
    if (withdrawal.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.WITHDRAWAL')); }
    if (transfer.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.INTERNAL-TRANSFER')); }
    if (marketplaceSale.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-SALE')) }
    if (marketplaceBuy.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.MARKETPLACE-BUY')) }
    if (refund.length > 0) { this.transactionTypeList.push(this.translate.instant('MOVEMENTS.MOVEMENT-LIST.REFUND')); }

    this.listOfFilter = [
      {
        title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DATE'),
        list: [
          this.year0,
          this.year1,
          this.year2
        ],
        id: 'date'
      },
      {
        title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.TRANSACTION'),
        list: this.transactionTypeList,
        id: 'transaction'
      },
      {
        title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.PROJECT'),
        list: this.projectList,
        id: 'project'
      }
    ];
  }

  applyFilters(filters: any) {
    let filteredMovements = this.fullMovements;
    let checked = [];
    filters.forEach((filter) => {
      switch (filter.id) {
        case 'date':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredMovements = filteredMovements.filter((movement) => checked.includes(movement.dateAccountant.substring(6, 10)));
          }
          break;
        case 'transaction':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredMovements = filteredMovements.filter((movement) => checked.includes(movement.movementDefinition));
          }
          break;
        case 'project':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredMovements = filteredMovements.filter((movement) => checked.includes(movement.projectName));
          }
          break;
      }
      this.movements = filteredMovements;
    });
  }

  orderByName() {
    this.nameDown = !this.nameDown;
    this.transactionDown = true;
    this.dateDown = true;
    this.amountDown = true;
    this.sortByName(this.movements);
  }

  orderByTransaction() {
    this.transactionDown = !this.transactionDown;
    this.nameDown = true;
    this.dateDown = true;
    this.amountDown = true;
    this.sortByTransaction(this.movements);
  }

  orderByDate() {
    this.dateDown = !this.dateDown;
    this.nameDown = true;
    this.transactionDown = true;
    this.amountDown = true;
    this.sortByDate(this.movements);
  }

  orderByAmount() {
    this.amountDown = !this.amountDown;
    this.nameDown = true;
    this.transactionDown = true;
    this.dateDown = true;
    this.sortByAmount(this.movements);
  }

  sortByName(arr) {
    if (this.nameDown) {
      arr.sort(function(a, b) {
        if (a.description > b.description) {
          return 1;
        }
        if (a.description < b.description) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      arr.sort(function(a, b) {
        if (a.description < b.description) {
          return 1;
        }
        if (a.description > b.description) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    return arr;
  }

  sortByTransaction(arr) {
    if (this.transactionDown) {
      arr.sort(function(a, b) {
        if (a.movementDefinition > b.movementDefinition) {
          return 1;
        }
        if (a.movementDefinition < b.movementDefinition) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      arr.sort(function(a, b) {
        if (a.movementDefinition < b.movementDefinition) {
          return 1;
        }
        if (a.movementDefinition > b.movementDefinition) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    return arr;
  }

  sortByDate(arr) {
    if (this.dateDown) {
      arr.sort(function (a, b) {
        const dayA = a.dateAccountant.substring(0, 2);
        const monthA = a.dateAccountant.substring(3, 5);
        const yearA = a.dateAccountant.substring(6, 10);
        const dateA = `${monthA}/${dayA}/${yearA}`;
        const dayB = b.dateAccountant.substring(0, 2);
        const monthB = b.dateAccountant.substring(3, 5);
        const yearB = b.dateAccountant.substring(6, 10);
        const dateB = `${monthB}/${dayB}/${yearB}`;
        return new Date((moment(dateB).format('YYYY-MM-DD'))).getTime() - new Date((moment(dateA).format('YYYY-MM-DD'))).getTime();
      });
    } else {
      arr.sort(function (a, b) {
        const dayA = a.dateAccountant.substring(0, 2);
        const monthA = a.dateAccountant.substring(3, 5);
        const yearA = a.dateAccountant.substring(6, 10);
        const dateA = `${monthA}/${dayA}/${yearA}`;
        const dayB = b.dateAccountant.substring(0, 2);
        const monthB = b.dateAccountant.substring(3, 5);
        const yearB = b.dateAccountant.substring(6, 10);
        const dateB = `${monthB}/${dayB}/${yearB}`;
        return new Date((moment(dateA).format('YYYY-MM-DD'))).getTime() - new Date((moment(dateB).format('YYYY-MM-DD'))).getTime();
      });
    }
    return arr;
  }

  sortByAmount(arr) {
    if (this.amountDown) {
      arr.sort(function (a, b) {
        return Number((a.amount)) - Number((b.amount));
      });
    } else {
      arr.sort(function (a, b) {
        return Number((b.amount)) - Number((a.amount));
      });
    }
    return arr;
  }

  viewAll() {
    this.movements = this.fullMovements;
    this.selectedAll = true;
  }

  cancelInvest(movement) {
    this.canceCurrentInvest.emit(movement);
  }

  showDeleteMobileMsg(movement) {
    this.movements.forEach(element => {
      if (element.id === movement.id) {
        movement.showDeleteMovile = !movement.showDeleteMovile;
      }
    });
  }

  editMovement(movement) {
    this.editCurrentInvest.emit(movement);
  }
  downloadList() {
    const customArrToChangeKey: Partial<any>[] = this.fullMovements.map(x => ({
      description: x.description,
      movementDefinition: x.movementDefinition,
      dateAccountant: x.dateAccountant,
      amount: x.amount,
      project: x.projectName || ''
    }));
    const params = {
      title: `Fundeen - ${this.translate.instant('MOVEMENTS.MOVEMENT-LIST.MY-MOVEMENTS')}`,
      numberSymbol: ' €',
      colorPositiveNumbers: '2fda90',
      platform: this.platform,
      list: customArrToChangeKey,
      titleProp: [
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT'),
          width: 50
        },
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.TRANSACTION'),
          width: 30
        },
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DATE'),
          width: 25
        },
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.QUANTITY'),
          width: 15
        },
        {
          title: this.translate.instant('bid-create.project'),
          width: 40
        }
      ]
    };
    this.utils.generateXlsxFromArrayObj(params);
    this.downloadedXlsx = true;
    setTimeout(() => this.downloadedXlsx = false, 10000);
    this.amplitudeService.trackEvent('download_movement_list', {});
  }

}
