import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentNs from 'moment';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AlertService } from '../alert/alert.service';
import { OveralMessageComponent } from '../overal-message/overal-message.component';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HubspotService } from 'src/app/services/hubspot.service';
import { Storage } from '@ionic/storage';
const moment = momentNs;

@Component({
  selector: 'app-pending-procedures',
  templateUrl: './pending-procedures.component.html',
  styleUrls: ['./pending-procedures.component.scss'],
})
export class PendingProceduresComponent implements OnInit {

  @Input('procedures') procedures;
  @Input('isRicEnergy') isRicEnergy = false;
  @Output() public procedureListEmptyevent = new EventEmitter<any>();
  @Output() public formalizeProcedureEvent = new EventEmitter<any>();

  ordered = false;
  loading = false;

  constructor(
    private utils: UtilsService,
    private translate: TranslateService,
    private alertService: AlertService,
    private chdRef: ChangeDetectorRef,
    private overviewService: OverviewProjectService,
    private storage: Storage,
    private hubspotService: HubspotService
    ) { }

  ngOnInit() {
    this.operate();
    registerLocaleData(es);
  }

  operate() {
    const currentBrowser = this.utils.checkBrowser();
    let moreInfodate;
    let month;
    let day;
    let year;
    for (let i = 0; i < this.procedures.length; i++) {
      if ((this.procedures[i].status === null || this.procedures[i].status === undefined) && this.procedures[i].type) {
        this.procedures[i].status = this.procedures[i].type;
      }
      if (this.procedures[i].status === 'INVESTMENT_INTENTION' ||  this.procedures[i].status === 'INVESTMENT_INTENTION_ACTIVE') {
        const amountFormated = Number(this.procedures[i].amount).toLocaleString('es-ES', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          useGrouping: true
        });
        this.procedures[i]['amount'] = this.procedures[i].amount;
        this.procedures[i]['description'] = this.translate.instant('FORMALITIES.TYPE');
        this.procedures[i]['opened'] = false;
        this.procedures[i]['deleted_opened'] = false;
        this.procedures[i]['moreInfo'] = this.translate.instant('FORMALITIES.MORE_INFO_PROCEDURES', { amount: amountFormated, name: this.procedures[i].project_name});
      }
      if (currentBrowser === 'Firefox') {
        this.procedures[i]['date'] = moment(this.procedures[i].exec_date.replace('/', '-').replace('/', '-')).format('DD/MM/YYYY');
        if (this.procedures[i]['creation_date'] !== null && this.procedures[i]['creation_date'] !== undefined) {
          moreInfodate = moment(this.procedures[i]['creation_date'].replace('/', '-').replace('/', '-'));
          month = this.getMonth(moment(moreInfodate).format('MM'));
          day = moment(moreInfodate).format('DD');
          year = moment(moreInfodate).format('YYYY');
          this.procedures[i]['moreInfoDate'] = `${day} de ${month} de ${year}`;
        }
      } else {
        const dt = new Date(this.utils.convertToIos(moment(this.procedures[i].exec_date).format('YYYY-MM-DD  HH:mm:ss'))).getTime();
        this.procedures[i]['date'] = moment(dt).format('DD/MM/YYYY');
        if (this.procedures[i]['creation_date'] !== null && this.procedures[i]['creation_date'] !== undefined) {
          moreInfodate = moment(new Date(this.utils.convertToIos(moment(this.procedures[i].creation_date).format('YYYY-MM-DD  HH:mm:ss'))).getTime());
          month = this.getMonth(moment(moreInfodate).format('MM'));
          day = moment(moreInfodate).format('DD');
          year = moment(moreInfodate).format('YYYY');
          this.procedures[i]['moreInfoDate'] = `${day} de ${month} de ${year}`;
        }
      }
    }
    this.sortByDate(this.procedures);
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(a.date)) - Number(new Date(b.date));
    });
    return arr;
  }

  formalize(procedure) {
    this.formalizeProcedureEvent.emit(procedure);
  }

  openMoreInfoProcedure(id) {
    for (let i = 0; i < this.procedures.length; i++) {
      if (this.procedures[i].id === id) {
        if (this.procedures[i].opened !== null && this.procedures[i].opened !== undefined) {
          this.procedures[i].opened = !this.procedures[i].opened;
          const el = document.getElementById(`content_${id}`);
          if (this.procedures[i].opened) {
            el.style.height = `${el.scrollHeight}px`;
            el.style.transition = 'height 0.5s';
          } else {
            el.style.height = '0px';
            el.style.transition = 'height 0.5s';
          }
        }
      }
    }
  }

  getMonth(month) {
    return this.utils.parseMonth(Number(month));
  }

  toggleDeleteBtn(id) {
    for (let i = 0; i < this.procedures.length; i++) {
      if (this.procedures[i].id === id) {
        this.procedures[i]['deleted_opened'] = !this.procedures[i]['deleted_opened'];
      }
    }
  }

  deleteProcedure(procedure) {
    for (let i = 0; i < this.procedures.length; i++) {
      if (this.procedures[i].id === procedure.id) {
        this.procedures[i]['deleted_opened'] = !this.procedures[i]['deleted_opened'];
        const msg = this.translate.instant('FORMALITIES.DELETE_PROCEDURE');
        const modalParams = {
          text: msg,
          btnList: [
            {
              type: 'primaryDark',
              index: 1,
              title: this.translate.instant('BIOMETRIC.CANCEL'),
            },
            {
              type: 'secondary',
              index: 2,
              title:  this.translate.instant('NOTIFICATIONS.BTN_1'),
            }
          ],
        };
        this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
          modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
            componentRef.btnEvent.subscribe((resp) => {
              this.alertService.removeModal();
              if (resp === 2) {
                this.loading = true;
                this.overviewService.cancelReservation(procedure.id).subscribe(respo => {
                  this.overviewService.getReservationList().subscribe(response => {
                    if (response.length < 1) {
                      this.procedureListEmptyevent.emit();
                    }
                    if (this.isRicEnergy) {
                      const ricEnergyIds = this.translate.instant('RIC_ENERGY.IDS').split(',').map(Number);
                      const list = response.filter(item => ricEnergyIds.includes(item.project_id));
                      this.procedures = list;
                      if (list.length < 1) {
                        this.procedureListEmptyevent.emit();
                      }
                    } else {
                      this.procedures = response;
                    }
                    this.operate();
                    this.loading = false;
                    this.chdRef.detectChanges();
                  }, error => {
                    this.loading = false;
                    this.procedureListEmptyevent.emit();
                    this.chdRef.detectChanges();
                  })
                  this.changeHubspotDeal(procedure);
                  this.chdRef.detectChanges();
                }, error => {
                  this.loading = false;
                  this.chdRef.detectChanges();
                });
              }
            });
          });
          modal.onClose().subscribe();
        });
      }
    }
    this.chdRef.detectChanges();
  }

  changeHubspotDeal(procedure) {
    this.storage.get('USERNAME').then((val) => {
      const userEmail = val;
      const idProject = procedure.project_id;
      const nameProject = procedure.project_name;
      const params = {
        projectName: nameProject,
        projectId: idProject,
        estado_del_proyecto: 15
      }
      let currentDeal;
      this.chdRef.detectChanges();
      this.hubspotService.getDeals(userEmail, idProject).subscribe((resHusbpot: any) => {
        if(resHusbpot.success) {
          const deals = resHusbpot.data.results;
          if(deals.length) {
            currentDeal = deals[0].id;
            this.chdRef.detectChanges();
            this.hubspotService.updateDeal(currentDeal, 'cancel', {}).subscribe((resHusbpot: any) => {
              this.chdRef.detectChanges();
            });
          } else {
            this.storage.get('HS_SURNAME_USER').then((name) => {
              const userName = name;
              this.storage.get('HS_CONTACT_ID').then((idhbp) => {
                const info = `${userName} - ${nameProject}`;
                this.hubspotService.createDeal(userEmail, idProject, idhbp, info, nameProject).subscribe((resHusbpot: any) => {
                  if(resHusbpot.success) {
                    currentDeal = resHusbpot.data.id;
                    this.hubspotService.updateDeal(currentDeal, 'cancel', params).subscribe((resHusbpot: any) => { });
                  }
                });
              });
            });
          }
        }
      });
    })
  }

}
