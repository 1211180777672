<div class="modal-container" #component>
    <div class="bg_shadow" (click)="close()"></div>
    <div [class]="side">
        <div class="modal-header">
            <img *ngIf="currentLevel === 'first'" alt="x" class="close" (click)="close()" src="../../assets/images/img/ico-close.svg">
            <img *ngIf="currentLevel === 'second'" alt="x" class="close" (click)="changeCurrentLevel()" src="../../assets/images/img/ico-arrow-down.svg">
            <div class="modal-status">
                <h2>{{ title }}</h2>
            </div>
            <div class="line"></div>
        </div>
        <div>
            <div #componentPlaceholder></div>
        </div> 
    </div>
</div>