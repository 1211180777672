<div class="general-container">
  <div *ngIf="loading" class="body-content">
    <fun-skeleton-list></fun-skeleton-list>
  </div>
  <div class="content">
    <div *ngIf="!loading" class="body-content">
      <!---->
      <section *ngIf="step === 0">
        <h2 class="font-sans text-eggplant-100 text-3xl">{{ 'LOCAL_INNVEST.STEP_0.TITLE' | translate }}</h2>
        <p class="subtitle txt_1 font-sans text-l text-eggplant-100/[0.55]">{{ 'LOCAL_INNVEST.STEP_0.SUBTITLE' | translate:{'projectName':projectName} }}</p>
        <div class="info_steps_container">
          <div class="info_step" *ngFor="let stp of postalCodeInvestmentStages">
            <div class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[-23px] line"></div>
            <h3 class="font-sans text-xl text-eggplant-80" *ngIf="stp.title !== null && stp.title !== undefined && stp.title !== '' ">{{ stp.title }}</h3>
            <h3 class="font-sans text-xl text-eggplant-80" *ngIf="stp.title === null || stp.title === undefined || stp.title === '' ">{{ stp.name }}</h3>

            <p class="font-sans text-m text-eggplant-100/[0.4]" *ngIf="stp.description !== null && stp.description !== undefined && stp.description !== '' ">{{ stp.description }}</p>
          </div>
        </div>
        <p class="txt_1 font-sans text-l "> {{ 'LOCAL_INNVEST.STEP_0.PROJECT_PHASE' | translate }} {{ phaseP }}.
          <span *ngIf="canInvest">{{ 'LOCAL_INNVEST.STEP_0.CAN_INVEST' | translate }}</span>
          <span *ngIf="!canInvest && investmentUnlock !== undefined">{{ 'LOCAL_INNVEST.STEP_0.CANT_INVEST' | translate }} {{ timeRemaining }} {{ 'LOCAL_INNVEST.STEP_0.DAYS' | translate }}.</span>
          <span *ngIf="status !== 15" class="green" (click)="seeValidCode()">{{ 'LOCAL_INNVEST.STEP_0.SEE_ADDRESS' | translate }}.</span>
        </p>
      </section>
      <!---->
      <section *ngIf="step === 1">
        <h2 class="font-sans text-eggplant-100 text-3xl">{{ 'LOCAL_INNVEST.STEP_1.TITLE' | translate }}</h2>
        <p class="subtitle txt_1 font-sans text-l text-eggplant-100/[0.55]">{{ 'LOCAL_INNVEST.STEP_1.SUBTITLE' | translate:{'projectName':projectName} }}</p>

        <div class="form-container" *ngIf="profile.profileType === 1">
          <fun-input *ngIf="profile.profileType === 1"
            placeholder="{{ 'LOCAL_INNVEST.STEP_3.DOC_TYPE' | translate }}"
            [parentForm]="documentForm"
            [formControlKeys]="formControlKeys"
            [errorMessages]="errorMessages"
            [isSubmit]="isSubmit"
            controlName="document"
            type="select"
            [errorLogin]="errorDocument"
            labelName="{{ 'LOCAL_INNVEST.STEP_3.DOC_TYPE' | translate }}"
            (selectChange)="changeDocument()"
            (keyup)="changeDocument()"
            (click)="changeDocument()"
            [items]="documentList"
            [selectedItem]="1"
          ></fun-input>
        </div>

        <div *ngIf="address !== undefined" class="address-confirm-container">
          <h4>{{ 'LOCAL_INNVEST.STEP_1.ADDRESS' | translate }}</h4>
          <p class="address txt_1 font-sans text-l text-eggplant-100/[0.55]" *ngIf="address !== undefined">{{ address }}</p>
          <p class="txt_1 font-sans text-l text-eggplant-100/[0.55]" *ngIf="address !== undefined && hasCity">{{ userCity }}</p>
        </div>
        <div *ngIf="hasAddress" class="check_container flex">
          <div class="checkbox_content">
            <div class="checkbox" [ngClass]="{ checked: sameAddressChecked }" (click)="checkAction('sameAddress')"></div>
          </div>
          <div class="">
            <p class="font-sans text-eggplant-70 text-l">{{ 'LOCAL_INNVEST.STEP_1.CONFIRM_ADDRESS' | translate }}</p>
          </div>
        </div>

      </section>
      <!---->
      <section *ngIf="step === 2">
        <h2 class="font-sans text-eggplant-100 text-3xl">{{ 'LOCAL_INNVEST.STEP_2.TITLE' | translate }}</h2>
        <p class="subtitle font-sans text-l text-eggplant-100/[0.55]">{{ 'LOCAL_INNVEST.STEP_2.SUBTITLE' | translate }}</p>
        <div class="form-container">
          <div *ngIf="hasProfile && hasListOfTowns">
            <fun-register-person-address *ngIf="profile.profileType === 1"
              [data]="info"
              [profile]="profile"
              [towns]="towns"
              [isMobile]="false"
              (sendInfoEvent)="getAddressInfo($event)"
              (sendErrorEvent)="getAddressError($event)"
            ></fun-register-person-address>
            <!---->
            <fun-register-company-address
              *ngIf="profile.profileType === 2"
              [data]="profile"
              [towns]="towns"
              [isMobile]="false"
              (sendInfoEvent)="getAddressInfo($event)"
              (sendErrorEvent)="getAddressError($event)"
            ></fun-register-company-address>
            <!---->
          </div>
          <div *ngIf="!hasProfile || !hasListOfTowns">
            <fun-skeleton-list></fun-skeleton-list>
          </div>
        </div>
      </section>
      <!---->
      <section *ngIf="step === 3">
        <h2 class="font-sans text-eggplant-100 text-3xl">{{ 'LOCAL_INNVEST.STEP_3.TITLE' | translate }}</h2>
        <p class="subtitle font-sans text-l text-eggplant-100/[0.55]" *ngIf="profile.profileType === 2 || (fileName === 'identityDocumentFront' && !hasDniFile) || (hasDniFile && fileName !== 'identityDocumentFront')">
          {{ 'LOCAL_INNVEST.STEP_3.SUBTITLE' | translate }}
          <span *ngIf="profile.profileType === 1 && fileName === 'identityDocumentFront'">{{ 'LOCAL_INNVEST.STEP_3.PF_DOCUMENT_1' | translate }}</span>
          <span *ngIf="profile.profileType === 1 && fileName === 'census'">{{ 'LOCAL_INNVEST.STEP_3.PF_DOCUMENT_2' | translate }}</span>
          <span *ngIf="profile.profileType === 2">{{ 'LOCAL_INNVEST.STEP_3.PJ_DOCUMENT' | translate }}</span>
        </p>
        <p class="subtitle font-sans text-l text-eggplant-100/[0.55]" *ngIf="profile.profileType === 1 && fileName === 'identityDocumentFront' && hasDniFile">{{ 'LOCAL_INNVEST.STEP_3.SUBTITLE_2' | translate }}</p>
        <div class="form-container" *ngIf="profile.profileType === 2 || (fileName === 'identityDocumentFront' && !hasDniFile) || (hasDniFile && fileName !== 'identityDocumentFront')">
          <fun-file-card
            #fileCard
            (getFile)="getFile1($event)"
            (errorEvent)="errorFile1($event)"
            [fileType]="fileName"
            [username]="profile.username"
          ></fun-file-card>
          <fun-file-card
          *ngIf="valueF === 1 && fileMain !== undefined && profile.profileType === 1"
            #dniBackCard
            (getFile)="getDniBack($event)"
            (errorEvent)="errorDniBack($event)"
            [fileType]="'identityDocumentBack'"
            [username]="profile.username"
          ></fun-file-card>
        </div>
        <div class="check_container flex" [ngClass]="{ spaceTop: fileName === 'identityDocumentFront' && hasDniFile }">
          <div class="checkbox_content flex">
            <div class="checkbox" [ngClass]="{ checked: termsChecked }" (click)="checkAction('terms')"></div>
          </div>
          <div class="">
            <p class="font-sans text-eggplant-70 text-m">{{ 'LOCAL_INNVEST.STEP_4.CHECK' | translate }}</p>
          </div>
        </div>
      </section>
      <!---->
      <fun-overal-message *ngIf="step === 4" [text]="text" [image]="14" [btnList]="btnList" (btnEvent)="finish()"></fun-overal-message>
      <!---->
    </div>
  </div>

  <div class="footer" *ngIf="step < 4 && !loading">
    <div class="btn_container first">
      <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
      [buttonType]="'ghost'" title="{{ 'AUTOINVEST.FOOTER.PREV' | translate }}"></fun-new-button>
    </div>
    <!-- <p class="footer-info" (click)="finish()">{{ 'LOCAL_INNVEST.DYNAMIC.OTHER_MOMENT' | translate }}</p> -->
    <div class="btn_container secod">
      <fun-new-button size="xl" (eventClick)="next()" class="next-btn"  [classExtra]="' !w-full '"
      [buttonType]="btnType" title="{{ 'AUTOINVEST.FOOTER.NEXT' | translate }}" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
    </div>
  </div>
</div>