export const Regions = [{
    "code": "02",
    "countryCode": 108,
    "name": "Albacete"
  },
  {
    "code": "03",
    "countryCode": 108,
    "name": "Alicante/Alacant"
  },
  {
    "code": "04",
    "countryCode": 108,
    "name": "Almería"
  },
  {
    "code": "01",
    "countryCode": 108,
    "name": "Araba/Álava"
  },
  {
    "code": "33",
    "countryCode": 108,
    "name": "Asturias"
  },
  {
    "code": "05",
    "countryCode": 108,
    "name": "Ávila"
  },
  {
    "code": "06",
    "countryCode": 108,
    "name": "Badajoz"
  },
  {
    "code": "07",
    "countryCode": 108,
    "name": "Balears, Illes"
  },
  {
    "code": "08",
    "countryCode": 108,
    "name": "Barcelona"
  },
  {
    "code": "48",
    "countryCode": 108,
    "name": "Bizkaia"
  },
  {
    "code": "09",
    "countryCode": 108,
    "name": "Burgos"
  },
  {
    "code": "10",
    "countryCode": 108,
    "name": "Cáceres"
  },
  {
    "code": "11",
    "countryCode": 108,
    "name": "Cádiz"
  },
  {
    "code": "39",
    "countryCode": 108,
    "name": "Cantabria"
  },
  {
    "code": "12",
    "countryCode": 108,
    "name": "Castellón/Castelló"
  },
  {
    "code": "13",
    "countryCode": 108,
    "name": "Ciudad Real"
  },
  {
    "code": "14",
    "countryCode": 108,
    "name": "Córdoba"
  },
  {
    "code": "15",
    "countryCode": 108,
    "name": "Coruña, A"
  },
  {
    "code": "16",
    "countryCode": 108,
    "name": "Cuenca"
  },
  {
    "code": "20",
    "countryCode": 108,
    "name": "Gipuzkoa"
  },
  {
    "code": "17",
    "countryCode": 108,
    "name": "Girona"
  },
  {
    "code": "18",
    "countryCode": 108,
    "name": "Granada"
  },
  {
    "code": "19",
    "countryCode": 108,
    "name": "Guadalajara"
  },
  {
    "code": "21",
    "countryCode": 108,
    "name": "Huelva"
  },
  {
    "code": "22",
    "countryCode": 108,
    "name": "Huesca"
  },
  {
    "code": "23",
    "countryCode": 108,
    "name": "Jaén"
  },
  {
    "code": "24",
    "countryCode": 108,
    "name": "León"
  },
  {
    "code": "25",
    "countryCode": 108,
    "name": "Lleida"
  },
  {
    "code": "27",
    "countryCode": 108,
    "name": "Lugo"
  },
  {
    "code": "28",
    "countryCode": 108,
    "name": "Madrid"
  },
  {
    "code": "29",
    "countryCode": 108,
    "name": "Málaga"
  },
  {
    "code": "30",
    "countryCode": 108,
    "name": "Murcia"
  },
  {
    "code": "31",
    "countryCode": 108,
    "name": "Navarra"
  },
  {
    "code": "32",
    "countryCode": 108,
    "name": "Ourense"
  },
  {
    "code": "34",
    "countryCode": 108,
    "name": "Palencia"
  },
  {
    "code": "35",
    "countryCode": 108,
    "name": "Palmas, Las"
  },
  {
    "code": "36",
    "countryCode": 108,
    "name": "Pontevedra"
  },
  {
    "code": "26",
    "countryCode": 108,
    "name": "Rioja, La"
  },
  {
    "code": "37",
    "countryCode": 108,
    "name": "Salamanca"
  },
  {
    "code": "38",
    "countryCode": 108,
    "name": "Santa Cruz de Tenerife"
  },
  {
    "code": "40",
    "countryCode": 108,
    "name": "Segovia"
  },
  {
    "code": "41",
    "countryCode": 108,
    "name": "Sevilla"
  },
  {
    "code": "42",
    "countryCode": 108,
    "name": "Soria"
  },
  {
    "code": "43",
    "countryCode": 108,
    "name": "Tarragona"
  },
  {
    "code": "44",
    "countryCode": 108,
    "name": "Teruel"
  },
  {
    "code": "45",
    "countryCode": 108,
    "name": "Toledo"
  },
  {
    "code": "46",
    "countryCode": 108,
    "name": "Valencia/València"
  },
  {
    "code": "47",
    "countryCode": 108,
    "name": "Valladolid"
  },
  {
    "code": "49",
    "countryCode": 108,
    "name": "Zamora"
  },
  {
    "code": "50",
    "countryCode": 108,
    "name": "Zaragoza"
  },
  {
    "code": "51",
    "countryCode": 108,
    "name": "Ceuta"
  },
  {
    "code": "52",
    "countryCode": 108,
    "name": "Melilla"
  }
]