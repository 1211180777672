import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LangGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private translate: TranslateService, private localize: LocalizeRouterService, private navCtrl: NavController) {}
  i = 0;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let lan;
    if (this.i === 0) {
      lan = state.url.substring(1, 3);
    }
    this.i++;
    const page = state.url.slice(3);
    if (lan !== this.translate.currentLang && (lan === 'en' || lan === 'es' || lan === 'ca')) {
      this.navCtrl.navigateRoot([this.localize.translateRoute(page)], {}).then((x) => {
        this.localize.changeLanguage(lan);
      });
    }
    return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
