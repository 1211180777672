import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'fun-overviiew-status-avatars',
  templateUrl: './overviiew-status-avatars.component.html',
  styleUrls: ['./overviiew-status-avatars.component.scss'],
})
export class OverviiewStatusAvatarsComponent implements OnInit {

  @Input() investors: any;
  @Input() size = 'm';
  @Input() yourInvest = 0;
  @Input() status: any;
  @Input() version = 'dark';
  @Input() isFlexibleCompensation: boolean = false;
  numRemaining: number;
  initials = [];

  investorsNumber: number;

  iconsStyles = {
    'xl': 'w-56 h-56 md:w-64 md:h-64',
    'm':  'w-40 h-40'
  }
  textStyles = {
    'xl': 'text-l',
    'm':  'text-s leading-[15.6px]',
    'dark': 'text-eggplant-100/[0.4]',
    'light': 'text-cream-100'
  }
  captionStyles = {
    'xl': 'text-h400 md:text-h600 pt-12 md:pt-10',
    'm':  'text-h400 pt-4'
  }
  containerStyles = {
    'xl': 'flex-col items-start md:flex-row md:items-center gap-y-8',
    'm':  'flex-row items-center'
  }

  avtClass: string;
  txtClass: string;
  capClass: string;
  wrapClass: string;

  constructor() { }

  ngOnInit() {
    this.numRemaining = this.investors > 3 ? this.investors - 3 : 0;
    const numInitials = this.investors > 3 ? 3 : this.investors;
    const characters = 'ABCDEFGHIJLMNOPQRSTUVZ';
    const bgList = ['linear-gradient-1', 'linear-gradient-2', 'linear-gradient-3', 'linear-gradient-4', 'linear-gradient-5', 'linear-gradient-6', 'linear-gradient-7'];

    for (let i = 0; i < numInitials; i++) {
      const initial = Math.floor(Math.random() * characters.length);
      const bg = Math.floor(Math.random() * bgList.length);
      const obj = {
        letter: characters[initial],
        background: bgList[bg]
      }
      this.initials.push(obj);
    }
    const lastAvt = {
      letter: '+' + this.numRemaining,
      background: 'linear-gradient-7'
    }

    if (this.numRemaining > 99) {
      if (this.size === 'm') {
        this.captionStyles[this.size] = 'text-h200';
        this.capClass = `${this.captionStyles[this.size]} pt-8`;
      } else {
        this.captionStyles[this.size] = 'l';
        this.capClass = `${this.captionStyles[this.size]} pt-[14px]`;
      }
    } else {
      this.capClass = `${this.captionStyles[this.size]}`;
    }

    this.initials.push(lastAvt);
    this.wrapClass = `${this.containerStyles[this.size]}`;
    this.avtClass = `${this.iconsStyles[this.size]}`;
    this.txtClass = `${this.textStyles[this.size]} ${this.textStyles[this.version]}`;
  }

}
