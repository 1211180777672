import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translate: TranslateService, private localizeService: LocalizeRouterService, private http: HttpClient) {}

  setInitialAppLanguage() {
    const language = this.translate.getBrowserLang();

    if (language) {
      this.localizeService.changeLanguage(language);
    }
  }

  setLanguage(setLang) {
    this.localizeService.changeLanguage(setLang);
  }

  getLanguage() {
    return this.translate.getDefaultLang();
  }

  public loadTranslationsOverride(locale: string) {
    const fileOverride = 'assets/i18n/override_' + locale + '.json';
    return this.http.get(fileOverride);
  }

}
