import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fun-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
  @Input() questions: any;
  @Output() public eventQuestionClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  emitQuestion(index) {
    this.eventQuestionClicked.emit(index);
  }

}
