import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Profile } from 'src/app/shared/models/register.models';

@Component({
  selector: 'fun-register-upload-specific-document',
  templateUrl: './register-upload-specific-document.component.html',
  styleUrls: ['./register-upload-specific-document.component.scss']
})
export class RegisterUploadSpecificDocumentComponent implements OnInit {

  @Input() profile: Profile;
  @Input() type: Profile;
  @Output() public updateFileEvent = new EventEmitter<any>();

  btnType: string;
  step: number;
  file: any;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.btnType = 'primaryDark-disabled';
    this.step = 0;
    this.chdRef.detectChanges();
  }

  next() {
    this.step = 1;
    this.updateFileEvent.emit(this.file);
    this.chdRef.detectChanges();
  }

  getFrontalFile(file) {
    this.file = file;
    this.btnType = 'primaryDark';
    this.chdRef.detectChanges();
  }

  errorFile() {
    this.btnType = 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }

}
