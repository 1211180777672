import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class ListOfProjectsService {
  // apiservices/project/v1/projects
  constructor(private communicationService: CommunicationService) {}

  public getProjectsList() {
    return this.communicationService.get('apiservices/project/v1/projects', null);
  }

  public getStatisticData() {
    return this.communicationService.get('apiservices/project/v1/projects/info/statistic', null);
  }

  public getMkpProjectList() {
    return this.communicationService.get('apiservices/marketplace/v1/marketplace/openProjects', null);
  }
   public postBid(bid) {
    return this.communicationService.post(`apiservices/marketplace/v1/marketplace/bid`, bid);
   }

  subscribeExclusive(email: any, idP: any, exclusive: boolean) {
    const ids = exclusive ? ['5239388'] : ['5239388', '6441045'];
    const payload = {
      'email': email,
      'subscriptions': ids,
      'comunicaciones_sobre_proyectos': idP
    };
    if (!exclusive) {
      payload['suscripcion_a_la_newsletter'] = true;
      payload['comunicacion_nuevos_proyectos'] = true;
    }
    return this.communicationService.post('apiservices/user/v1/users/subscribe/hubspot', payload);
  }
}
