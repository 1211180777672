import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { CanLoad, Router, NavigationStart } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable()
export class AuthGuard implements CanLoad {
  constructor(private authService: AuthService, private router: Router, private storage: Storage) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.validateToken();
  }
}
