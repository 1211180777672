import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
declare const YT: any;

@Component({
  selector: 'app-youtube-video-player',
  templateUrl: './youtube-video-player.component.html',
  styleUrls: ['./youtube-video-player.component.scss'],
})
export class YoutubeVideoPlayerComponent implements OnInit {

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.setBackgroundVideo();
  }

  setBackgroundVideo() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    setTimeout(function(){
      const playerWrap = document.getElementById("wrapVideo");
      const playerFrame = document.getElementById("bkVideo");

      const onPlayerReady = function(event) {
        event.target.playVideo();
      }

      const onPlayerStateChange = function(event) {
        if (event.data == YT.PlayerState.ENDED) {
          playerWrap.classList.add("ended");
        } else if (event.data == YT.PlayerState.PAUSED) {
          playerWrap.classList.add("paused");
        } else if (event.data == YT.PlayerState.PLAYING) {
          playerWrap.classList.remove("ended");
          playerWrap.classList.remove("paused");
        }
      };

      playerWrap.addEventListener("click", function() {
        let playerState = player.getPlayerState();
        if (playerState == YT.PlayerState.ENDED) {
          player.seekTo(6);
        } else if ((playerState == YT.PlayerState.PAUSED) || ((playerState == YT.PlayerState.UNSTARTED))) {
          player.playVideo();
        }
      });

      let player = new YT.Player(playerFrame, {
        videoId: 'ryW5nYg3Nro',
        playerVars: {
          'controls': 0,
          'autoplay': 1,
          'mute':     1,
          'loop':     1,
          'playlist': 'ryW5nYg3Nro',
          'rel':      0,
          'showinfo': 0,
          'modestbranding': 1,
          'fs':       0,
          'start':    6,
          'end':      135
        },
        events: {
          'onReady': onPlayerReady,
          'onStateChange': onPlayerStateChange
        }
      });
      this.chdRef.detectChanges();
    }, 1000);
  }

}
