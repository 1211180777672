import { AbstractControl } from '@angular/forms';

export function ValidatorNif(control: AbstractControl): { [key: string]: boolean } | null {
    const error = validateNif(control.value);
    if (!error) {
      return { code: true };
    }
    return null;
}
function validateNif(dni) {
    let number;
    let letter;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    if (dni !== undefined && dni !== null){
        dni = dni.toUpperCase();
    }

    if (expresion_regular_dni.test(dni) === true) {
        number = dni.substr(0, dni.length - 1);
        number = number.replace('X', 0);
        number = number.replace('Y', 1);
        number = number.replace('Z', 2);
        number = number % 23;
        letter = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letter = letter.substring(number, number + 1);
        if (letter !== dni.substr(dni.length - 1, 1)) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}
