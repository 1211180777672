import { Component, OnInit, AfterViewInit, ElementRef, HostListener, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { MenuController, NavController, AnimationController, ModalController } from '@ionic/angular';
import { Location } from '@angular/common';
import { UtilsService } from '../../services/utils.service';
import { Storage } from '@ionic/storage';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';
import { HelpService } from 'src/app/services/help.service';
import { GraphicsService } from 'src/app/pages/dashboard/graphics/graphics.service';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { ProfileService } from 'src/app/services/profile.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { submenuRoutes, specificSubmenuRoutes } from '../other/submenu-routes';
import { Profile } from '../models/register.models';
import { BadgeService } from 'src/app/services/badge.service';
import { BadgeModel } from '../models/badge.model';
import { NotificationListComponent } from '../components/notification-list/notification-list.component';
import { myEnterAnimationGoUp, myLeaveAnimationGoUp } from '../other/animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {

  isPortfolio: any;
  isMovements: any;
  isRef: any;
  portfolioActive: any;
  isMarketplace: any;
  isUserLoged = true;
  currentUrl: string;
  name: string;
  avatarLetters: string;
  fillColor: string;
  submenuOpen = false;
  listactive: boolean;
  isList: boolean;
  isGraphicPage: boolean;
  isMyOffers: boolean;
  isMyBids: boolean;
  isListMP: boolean;
  isOverview: boolean;
  isListOfProjects: boolean;
  isMyProfile: boolean;
  isMyProfileUpdate: boolean;
  isRegister: boolean;
  isDocumentation: boolean;
  isPaymentCalendar: boolean;
  isMeeting: boolean;

  marketplaceActivated = environment.activateMarketplace;
  currentLan: any;
  walletStatus: any;
  hasProfile: boolean = true;
  isUpdates = false;
  hasFullProfile: boolean;
  isNewProfile: boolean;

  notificationsNumber = 0;
  notificationList: any;

  realList = [];
  isSubmenu: boolean;
  loadingNotifications: boolean;
  notificationsAction = false;
  isHelp = false;

  langListOpened = false;
  currentLanTxt: string;
  langList = [
    { id: 'es', txt: this.translate.instant('LANGUAGES.SPANISH') },
    { id: 'en', txt: this.translate.instant('LANGUAGES.ENGLISH') },
    { id: 'ca', txt: this.translate.instant('LANGUAGES.CAT') }
  ];

  submenu = '';
  routeInSubmenu = '';
  isUpdateProfile = false;

  badgeProps: any;
  hasBadge = false;

  @ViewChild("bell", { read: ElementRef, static: false }) bell: ElementRef;

  constructor(
    private router: Router,
    public menuCtrl: MenuController,
    public location: Location,
    public utilsService: UtilsService,
    private storage: Storage,
    private authService: AuthService,
    public helpService: HelpService,
    private graphicService: GraphicsService,
    private overviewService: OverviewProjectService,
    private translate: TranslateService,
    private localize: LocalizeRouterService,
    private communicationService: CommunicationService,
    public profileService: ProfileService,
    private notificationService: NotificationsService,
    private navCtrl: NavController,
    private animationCtrl: AnimationController,
    private chdRef: ChangeDetectorRef,
    private badgeService: BadgeService,
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.langList.forEach(element => {
      if (element.id === this.translate.currentLang) {
        this.currentLanTxt = element.id;
      }
    });
    this.hasFullProfile = true;
    this.isNewProfile = false;
    this.getNameProfile();
    this.getBadgeProps();

    this.storage.get('WALLET').then((val) => {
      if (val !== null && val !== undefined &&
        (val.walletstatus === 0 || val.walletstatus === -1 || val.walletstatus === null)) {
        this.walletStatus = val.walletstatus;
      } else {
        this.communicationService.get('apiservices/profile/v1/profiles/wallets/status', null).subscribe(
          (resp) => {
            this.walletStatus = resp.walletstatus;
          });
      }
    });

    this.fillColor = '#FFFFFF';
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
        if (
          val.url.includes('/es/actualizar-perfil')
          || val.url.includes('/ca/actualitzar-perfil')
          || val.url.includes('/en/update-my-profile')
          ) {
          this.isUpdateProfile = true;
        } else {
          this.isUpdateProfile = false;
        }

        if (
          val.url.includes('/es/ayuda')
          || val.url.includes('/ca/ajuda')
          || val.url.includes('/en/help')
          ) {
          this.isHelp = true;
        }

        if (
          val.url.includes('/es/programa-de-referido')
          || val.url.includes('referral-program')
          || val.url.includes('/ca/programa-de-referits')
          || val.url.includes('/es/lista-de-referidos')
          || val.url.includes('referral-list')
          ) {
          this.isRef = true;
          this.fillColor = '#FFFFFF';
        } else {
          this.isRef = false;
        }

        if (
          val.url.includes('/es/portfolio') ||
          val.url.includes('/en/portfolio') ||
          val.url.includes('/ca/portfolio') ||
          val.url.includes('/es/detalle-del-proyecto') ||
          val.url.includes('/en/detail-project') ||
          val.url.includes('/ca/detall-del-projecte') ||
          val.url.includes('/es/graficas') ||
          val.url.includes('/en/graphics') ||
          val.url.includes('/ca/grafiques') ||
          val.url.includes('/es/actualizaciones') ||
          val.url.includes('/ca/actualitzacions') ||
          val.url.includes('/en/updates') ||
          val.url.includes('/en/documentation') ||
          val.url.includes('/es/documentacion') ||
          val.url.includes('/ca/documentació') ||
          val.url.includes('/es/calendario-de-pagos') ||
          val.url.includes('/en/payment-calendar') ||
          val.url.includes('/ca/calendari-de-pagaments')
        ) {
          this.portfolioActive = true;
          if (val.url.includes('/es/graficas') || val.url.includes('/en/graphics') || val.url.includes('/ca/grafiques')) {
            this.isGraphicPage = true;
          } else {
            this.isGraphicPage = false;
          }
          if (val.url.includes('/es/actualizaciones') || val.url.includes('/en/updates') || val.url.includes('/ca/actualitzacions')) {
            this.isUpdates = true;
          } else {
            this.isUpdates = false;
          }
          if (val.url.includes('/es/registro') || val.url.includes('/en/register') || val.url.includes('/ca/registre')) {
            this.isRegister = true;
          } else {
            this.isRegister = false;
          }
          if (val.url.includes('/es/documentacion') || val.url.includes('/en/documentation') || val.url.includes('/ca/documentació')) {
            this.isDocumentation = true;
          } else {
            this.isDocumentation = false;
          }
          if (val.url.includes('/es/calendario-de-pagos') || val.url.includes('/en/payment-calendar') || val.url.includes('/ca/calendari-de-pagaments')) {
            this.isPaymentCalendar = true;
          } else {
            this.isPaymentCalendar = false;
          }
        } else {
          this.portfolioActive = false;
        }
        if (val.url.includes('/es/portfolio') || val.url.includes('/en/portfolio') || val.url.includes('/ca/portfolio')
        || val.url.includes('/es/movimientos') || val.url.includes('/en/movements') || val.url.includes('/ca/moviments')
        || val.url.includes('/es/actualizaciones') || val.url.includes('/en/updates') || val.url.includes('/ca/actualitzacions')
        || val.url.includes('/es/documentacion') || val.url.includes('/en/documentation') || val.url.includes('/ca/documentació')
        || val.url.includes('/es/calendario-de-pagos') || val.url.includes('/en/payment-calendar') || val.url.includes('/ca/calendari-de-pagaments')) {
          this.fillColor = '#FFFFFF';
          this.isPortfolio = true;
        } else {
          this.fillColor = '#FFFFFF';
          this.isPortfolio = false;
        }
        if (
          val.url.includes('/es/mis-pujas') ||
          val.url.includes('/en/my-bids') ||
          val.url.includes('/ca/les-meves-licitacions') ||
          val.url.includes('/es/mis-ofertas') ||
          val.url.includes('/en/my-offers') ||
          val.url.includes('/ca/les-meves-ofertes')
        ) {
          this.listactive = true;
          if (val.url.includes('/es/mis-pujas') || val.url.includes('/en/my-bids') || val.url.includes('/ca/les-meves-licitacions')) {
            this.isMyBids = true;
          } else {
            this.isMyBids = false;
          }
          if (val.url.includes('/es/mis-ofertas') || val.url.includes('/en/my-offers') || val.url.includes('/ca/les-meves-ofertes')) {
            this.isMyOffers = true;
          } else {
            this.isMyOffers = false;
          }
        }
        if (val.url.includes('/es/movimientos') || val.url.includes('/en/movements') || val.url.includes('/ca/moviments')) {
          this.isMovements = true;
          this.fillColor = '#FFFFFF';
        } else {
          this.isMovements = false;
        }
        if (val.url.includes('/es/listado-de-proyectos') || val.url.includes('/en/list-of-projects') || val.url.includes('/ca/llistat-de-projectes')) {
          this.listactive = true;
          this.isList = true;
          this.isListOfProjects = true;
          this.fillColor = '#FFFFFF';
        } else {
          this.isListOfProjects = false;
          this.isList = false;
        }
        if (val.url.includes('/es/vision-general-del-proyecto') || val.url.includes('/en/overview-project') || val.url.includes('/ca/visió-general-del-projecte')) {
          this.isOverview = true;
          this.isList = true;
          setTimeout(() => {
            this.storage.get('OVERVIEWPAGE').then((value) => {
              if (value === 'news' || value === 'overview') {
                this.listactive = true;
              } else if (value === 'agora') {
                this.isMarketplace = true;
              }
            });
          }, 10);

          this.fillColor = '#FFFFFF';
        } else {
          this.isOverview = false;
        }
        if(
          val.url.includes('es/mi-perfil')
          || val.url.includes('en/my-profile')
          || val.url.includes('ca/el-meu-perfil')
          || val.url.includes('en/communications')
          || val.url.includes('es/comunicaciones')
          || val.url.includes('en/notifications')
          || val.url.includes('es/notificaciones')
          || val.url.includes('/ca/notificacions')
          || val.url.includes('/es/ayuda')
          || val.url.includes('/en/help')
          || val.url.includes('/ca/ajuda')
          ) {
          this.isMyProfile = true;
          this.fillColor = '#FFFFFF';
        } else {
          this.isMyProfile = false;
        }
        if(
          val.url.includes('es/mi-perfil')
          || val.url.includes('en/my-profile')
          || val.url.includes('ca/el-meu-perfil')
          || val.url.includes('en/communications')
          || val.url.includes('es/comunicaciones')
          || val.url.includes('en/notifications')
          || val.url.includes('es/notificaciones')
          || val.url.includes('/ca/notificacions')
        ) {
          this.isMyProfileUpdate = true;
          this.fillColor = '#FFFFFF';
        } else {
          this.isMyProfileUpdate = false;
        }
        if (val.url.includes('/es/juntas') || val.url.includes('/en/meeting') || val.url.includes('/ca/juntas')) {
          this.isMeeting = true;
        } else {
          this.isMeeting = false;
        }
      }
    });
    this.realList = [];
    this.getNotifications();
    this.notificationService.getNotificationList();
    //
    this.notificationService.readNotificationEvent.subscribe(() => {
      this.realList = [];
      this.getNotifications(true);
    });
    this.notificationService.deleteNotificationEvent.subscribe(() => {
      this.realList = [];
      this.getNotifications(true);
    });
    this.notificationService.deleteSpecificNotificationEvent.subscribe(() => {
      this.realList = [];
      this.getNotifications(true);
    });
    this.getSubmenuRoutes();
  }
  ngAfterViewInit() {
    this.currentLan = this.translate.currentLang;
  }

  getNotifications(action?: boolean) {
    this.realList = [];
    this.loadingNotifications = true;
    this.notificationService.setNotificationListEvent.subscribe(resp => {
      this.notificationService.emitNotificationList(resp);
      this.notificationList = this.notificationService.filterNotifications(resp);
      this.notificationList.forEach(element => {
        if (element.title === null || element.title === undefined) {
          if (element.projectName !== null && element.projectName !== undefined) {
            element.title = element.projectName;
          } else {
            element.title = element.text.substring(0, 16) + '...';
          }
        }
        if (element.text.length > 140) {
          element['short_text'] = element.text.substring(0, 140) + '...';
        } else {
          element['short_text'] = element.text;
        }
      });
      this.notificationService.setTimeRemaining(this.notificationList);
      const notReaded = this.notificationList.filter(item => item.status !== 'VIEWED');
      const readed = this.notificationList.filter(item => item.status === 'VIEWED');
      this.notificationsNumber = notReaded.length;
      this.realList = [];
      for (let i = 0; i < notReaded.length; i++) {
        if (i < 4) {
          this.realList.push(notReaded[i]);
        }
      }
      if (this.realList.length < 4) {
        for (let i = 0; i < readed.length; i++) {
          if (this.realList.length < 4) {
            this.realList.push(readed[i]);
          }
        }
      }

      if (this.notificationsNumber > 0) {
        this.moveBell();
      } else {
        if (action) {
          this.notificationsAction = true;
        }
      }
      this.loadingNotifications = false;
    }, error => {
      this.loadingNotifications = false;
      this.notificationsNumber = 0;
    });
  }

  getNameProfile() {
    this.storage.get('NAME').then((val) => {
      if (val === null || val === undefined) {
        this.hasProfile = false;
        this.timer();
        this.customName(this.translate.instant('REGISTER.MY_PROFILE.TITLE'));
      } else {
        this.customName(val);
        this.hasProfile = true;
        this.getFullProfile();
      }
    });
  }

  getFullProfile() {
    this.storage.get('PROFILE').then((prof: Profile) => {
      if (prof === null || prof === undefined) {
        this.hasFullProfile = false;
        this.profileTimer();
      } else {
        this.hasFullProfile = this.profileService.checkStepsRequired(prof);
        if (!this.hasFullProfile) {
          this.profileTimer();
        }
        if(prof.lemonwayKycStatus === null) {
          this.isNewProfile = true;
        }
      }
      this.profileService.setProfileCompleted(this.hasFullProfile);
      this.profileService.setProfileIsNew(this.isNewProfile);
    });
  }

  timer() {
    setTimeout(() => this.getNameProfile(), 1000);
  }

  profileTimer() {
    setTimeout(() => this.getNameProfile(), 1000);
  }

  customName(name) {
    const c = name.toLowerCase();
    if (c.length > 16) {
      this.name = c.substr(0, 12) + '...';
    } else {
      this.name = c;
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (event.target.id !== 'lanSelector' && event.target.id !== 'langType') {
      if (
        event.target.id !== 'userMenu' &&
        event.target.id !== 'avatarComponent' &&
        event.target.id !== 'notification-bell' &&
        event.target.id !== 'bell' &&
        event.target.id !== 'notification-number' &&
        event.target.id !== 'avatar-container-menu' &&
        event.target.id !== 'avatar-name-menu' &&
        event.target.id !== 'avatarDefault' &&
        !event.target.id.includes('notification-')
        && this.submenuOpen
      ) {
        this.submenuOpen = false;
        this.langListOpened = false;
        const submenunNav = document.getElementById('submenuNav');
        const submenuNot = document.getElementById('submenuNot');
        const langContainer = document.getElementById('lanContainer');
        submenunNav.style.height = '0px';
        submenunNav.style.transition = 'height 0.5s';
        submenuNot.style.height = '0px';
        submenuNot.style.transition = 'height 0.5s';
        langContainer.style.height = '0px';
        this.langListOpened = false;
      }
    }
  }

  openMenu(val) {
    if (window.innerWidth < 780) {
      this.openModal();
    } else {
      this.submenuOpen = !this.submenuOpen;
      const el = document.getElementById(`submenuNav`);

      if (this.submenuOpen) {
        el.style.height = `${el.scrollHeight}px`;
        el.style.transition = 'height 0.5s';
      } else {
        el.style.height = '0px';
        el.style.transition = 'height 0.5s';
      }
    }
  }

  async openModal() {
    const notificationsNumber = this.notificationService.notificationList.length;
    if (notificationsNumber > 0) {
      const modal = await this.modalCtrl.create({
        component: NotificationListComponent,
        componentProps: { realList: this.realList },
        enterAnimation: myEnterAnimationGoUp,
        leaveAnimation: myLeaveAnimationGoUp
      });

      modal.present();
    }
  }

  openPortfolioHelp(level, index) {
    this.helpService.openHelp(level, index, 'PORTFOLIO');
  }

  openHelp(level, index) {
    if (this.isPortfolio) {
      if (this.isUpdates) {
        this.helpService.openHelp(level, index, 'UPDATES');
      } else {
        this.helpService.openHelp(level, index, 'PORTFOLIO');
      }
    }
    if (this.isGraphicPage) {
      this.graphicService.getCurrentGraphic();
    }
    if (this.isMarketplace) {
      if (this.isListMP) {
        this.helpService.openHelp(level, index, 'MARKETPLACE');
      }
      if (this.isMyBids) {
        this.helpService.openHelp(level, index, 'MY_BIDS');
      }
      if (this.isMyOffers) {
        this.helpService.openHelp(level, index, 'MY_OFFERS');
      }
    }
    if (this.isOverview) {
      this.overviewService.getCurrentPage();
    }
    if (this.isListOfProjects) {
      this.helpService.openHelp(level, index, 'MARKETPLACE');
    }
    if (this.isRef) {
      this.helpService.openHelp(level, index, 'REFERRAL');
    }
    if (this.isMyProfile) {
      this.storage.get('USERNAME').then((val) => {
        this.profileService.getProfile(val).subscribe((resp) => {
          if (resp && resp.profileType === 1) {
            this.helpService.openHelp(level, index, 'MY_PROFILE_PF');
          } else if (resp && resp.profileType === 2) {
            this.helpService.openHelp(level, index, 'MY_PROFILE_PJ');
          }
        });
      });
    }
  }

  goBack() {
    this.location.back();
  }

  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
  }

  changeLanguage(lan) {
    this.langListOpened = false;
    this.submenuOpen = false;
    if (lan !== this.translate.currentLang) {
      this.localize.changeLanguage(lan);
    }
  }

  logout() {
    this.authService.logout(true);
  }

  complete() {
    this.profileService.getUserData().subscribe(resp => {
      const model = {
        username: resp.username,
        mailVerified: resp.enabled,
        profileType: resp.profileType,
        mobile: resp.mobile,
        phone: resp.mobile,
        mobileVerified: resp.mobileVerified
      }
      this.authService.completeProfile(model);
    });
  }

  readNotification(notification) {
    if (notification.status !== 'VIEWED') {
      this.loadingNotifications = true;
      notification.status = 'VIEWED';
      this.notificationService.readNotification(notification.id, notification.category, notification.newNotification);
      this.realList = [];
      this.getNotifications();
      this.notificationService.getNotificationList();
      //
      this.notificationService.readNotificationEvent.subscribe(() => {
        this.realList = [];
        this.getNotifications(true);
      });
      this.notificationService.deleteNotificationEvent.subscribe(() => {
        this.realList = [];
        this.getNotifications(true);
      });
    }
    const route = this.utilsService.getNotificationRoute(notification.type);
    this.navigateTo(route);
  }

  deleteNotification(notification) {
    this.loadingNotifications = true;
    this.notificationService.deleteSpecificNotification(notification.id, notification.category, notification.newNotification);
  }

  deleteAllNotifications() {
    this.notificationService.deleteNotifications();
  }

  moveBell() {
    setTimeout(() => {
      const loadingAnimation = this.animationCtrl.create('loading-animation')
      .addElement(this.bell.nativeElement)
      .duration(80)
      .iterations(50)
      .fromTo('transform', 'rotate(0deg)', 'rotate(18deg)')
      .fromTo('transform', 'rotate(18deg)', 'rotate(0deg)');
      loadingAnimation.play();
      this.checkNotificationsNumber();
    }, 3000);
  }

  checkNotificationsNumber() {
    setTimeout(() => {
      if (this.notificationsNumber > 0) {
        this.moveBell();
      }
    }, 50000);
  }

  toggleLangContainer() {
    this.langListOpened = !this.langListOpened;
    const langContainer = document.getElementById('lanContainer');
    if (this.langListOpened) {
      langContainer.style.height = `${langContainer.scrollHeight}px`;
      langContainer.style.transition = 'height 0.5s';
    } else {
      langContainer.style.height = '0px';
      langContainer.style.transition = 'height 0.5s';
    }
  }

  getSubmenuRoutes() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const port = submenuRoutes.portfolio.indexOf(val.url);
        const prof = submenuRoutes.profile.indexOf(val.url);
        const proj = submenuRoutes.projects.indexOf(val.url);
        if (port !== -1) {
          this.submenu = 'portfolio';
          const port = specificSubmenuRoutes.portfolio.portfolio.indexOf(val.url);
          const upd = specificSubmenuRoutes.portfolio.updates.indexOf(val.url);
          const doc = specificSubmenuRoutes.portfolio.documentation.indexOf(val.url);
          if (port !== -1) {
            this.routeInSubmenu = 'dashboard';
          } else if (upd !== -1) {
            this.routeInSubmenu = 'updates';
          } else if (doc !== -1) {
            this.routeInSubmenu = 'documentation';
          } else {
            this.routeInSubmenu = 'payment-calendar';
          }
        } else if (prof !== -1) {
          this.submenu = 'profile';
          const mov = specificSubmenuRoutes.profile.movements.indexOf(val.url);
          const prof = specificSubmenuRoutes.profile.profile.indexOf(val.url);
          const not = specificSubmenuRoutes.profile.notifications.indexOf(val.url);
          if (mov !== -1) {
            this.routeInSubmenu = 'movements';
          } else if (prof !== -1) {
            this.routeInSubmenu = 'my-profile';
          } else if (not !== -1) {
            this.routeInSubmenu = 'notifications';
          } else {
            this.routeInSubmenu = 'referral-program';
          }
        } else if (proj !== -1) {
          this.submenu = 'projects';
          const projects = specificSubmenuRoutes.projects.projectList.indexOf(val.url);
          const bids = specificSubmenuRoutes.projects.bids.indexOf(val.url);
          if (projects !== -1) {
            this.routeInSubmenu = 'list';
          } else if (bids !== -1) {
            this.routeInSubmenu = 'bids';
          } else {
            this.routeInSubmenu = 'offers';
          }
        } else {
          this.submenu = '';
          this.routeInSubmenu = '';
        }
      }
    })
  }

  goTo(url) {
    this.navCtrl.navigateRoot([this.localize.translateRoute(url)]);
  }

  detectOS() {
    const platform = navigator.platform.toLowerCase(), iosPlatforms = ['iphone', 'ipad', 'ipod', 'ipod touch'];
    if (platform.includes('mac')) return 'MacOS';
    if (iosPlatforms.includes(platform)) return 'iOS';
    if (platform.includes('win')) return 'Windows';
    if (/android/.test(navigator.userAgent.toLowerCase())) return 'Android';
    if (/linux/.test(platform)) return 'Linux';
    this.chdRef.detectChanges();
    return 'unknown';
  }

  async getBadgeProps() {
    if (this.badgeService.hasBadge) {
      this.badgeProps = this.badgeService.badgeContent;
      this.hasBadge = true;
    } else {
      await this.badgeService.hasBadgeEvent.subscribe((badge: BadgeModel) => {
        this.badgeProps = badge;
        this.hasBadge = true;
      });
      await this.badgeService.removeBadgeEvent.subscribe(() => {
        this.hasBadge = false;
      });
    }
  }

}
