<div class="general-container">
    <div class="filters-header">
        <div class="filter-by">{{ 'filters.filter_by' | translate }}</div>
        <div *ngIf="mobileFilters > 0" class="delete-filters" (click)="deleteFilters()">{{ 'filters.clean_filters' | translate }}</div>
    </div>
    <div *ngFor="let filter of filterModel; let i = index" class="filter">
        <div class="filter-title" (click)="openFilter(i)">
            <div class="name">{{filter.title}}</div>
            <img class="open-arrow" src="../../../../../assets/images/img/up.svg" alt=""
                [ngClass]="{'closed': openedFilterIndex !== i}">
        </div>
        <div *ngIf="openedFilterIndex === i" class="filter-content">
            <div *ngFor="let option of filter.values; let optionIndex = index" class="filter-option" (click)="elementSelected(i, optionIndex, !option.checked)">
                <div class="name">{{option.name}}</div>
                <input type="checkbox" [(ngModel)]="option.checked" [id]="option"
                    (change)="elementSelected(i, optionIndex, $event.target.checked)">
            </div>
        </div>
    </div>
</div>
