import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as momentNs from 'moment';
import { ProfileService } from 'src/app/services/profile.service';
import { File } from '@ionic-native/file/ngx';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { UtilsService } from 'src/app/services/utils.service';
import { ToastService } from '../toast/toast.service';
import { InvestDocumentViewfinderComponent } from '../invest/invest-document-viewfinder/invest-document-viewfinder.component';
import { ModalService } from '../modal/modal.service';
import { saveAs } from 'file-saver';
import { Http } from '@capacitor-community/http';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';

const moment = momentNs;

@Component({
  selector: 'app-documentation-list',
  templateUrl: './documentation-list.component.html',
  styleUrls: ['./documentation-list.component.scss'],
})
export class DocumentationListComponent implements OnInit {

  @Input() movementList$: Observable<any>;
  @Input() investProjectDocuments: any;
  @Input() secondaryDocsLits: any;
  loading: boolean;
  fullDocuments: any;
  documents: any;
  hasDocuments: boolean;
  hasSecondaryDocuments: boolean;
  downloadStatus;

  constructor(
    public translate: TranslateService,
    public chdRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private utils: UtilsService,
    private file: File,
    private toastService: ToastService,
    private modalService: ModalService
    ) { }
  nameDown = true;
  typeDown = true;
  dateDown = true;

  listOfFilter: any;

  year0: string;
  year1: string;
  year2: string;

  projectList: string[] = [];
  documentTypeList: string[] = [];
  yearList: number[] = [];

  ngOnInit() {
    this.hasSecondaryDocuments = !this.utils.checkEmptyField(this.secondaryDocsLits);
    this.year0 = (moment().year()).toString();
    this.year1 = (moment().year() - 1).toString();
    this.year2 = (moment().year() - 2).toString();
    this.downloadStatus = [];
    this.loading = true;
    if (this.movementList$ === undefined) {
      if (this.investProjectDocuments !== undefined) {
        this.operateDocuments(this.investProjectDocuments, true);
      } else {
        this.hasDocuments = false;
        this.loading = false;
      }
    } else {
      this.movementList$.subscribe(resp => {
        this.operateDocuments(resp);
        this.chdRef.detectChanges();
      }, error => {
        this.loading = false;
        this.hasDocuments = false;
        this.chdRef.detectChanges();
      });
    }
  }

  operateDocuments(file, isSpecific?) {
    if (isSpecific) {
      this.fullDocuments = [];
    } else {
      this.fullDocuments = file.documents.filter( item => item.type === 'DOCUMENT' &&
    (item.subtype === 'ANNUAL_REPORT' || item.subtype === 'WEALTH_CERTIFICATE'));
    }
    file.documents.forEach(element => {
      const files = element.files;
      if (element.type === 'SIGNATURE' && element.subtype === 'INVEST') {
        for (let i = 0; i < files.length; i++) {
          const doc = files[i];
          doc.date = element.date;
          doc.subtype = element.subtype;
          doc.type = element.type;
          doc.format = 'application/pdf';
          doc.name = this.translate.instant('DOCUMENTATION.INVEST');
          doc.year = Number(element.date.substring(0, 4));
          doc.project = element.project;
          doc.projectId = element.projectId;
          let itemInArray;
          if (isSpecific !== true) {
            itemInArray = this.fullDocuments.filter(item => item.projectId === doc.projectId).length;
            if (files[i].status === 'completed' && itemInArray < 1) {
              this.fullDocuments.push(doc);
            }
          } else {
            this.fullDocuments.push(doc);
          }
        }
      }
    });

    // Captura de valores para filtros
    if (this.fullDocuments.length > 0) {
      this.fullDocuments.forEach((document) => {
        if (document.project && !this.projectList.includes(document.project)) {
          this.projectList.push(document.project);
        }
        if ((document.subtype === 'ANNUAL_REPORT' || document.subtype === 'WEALTH_CERTIFICATE')
            && !this.documentTypeList.includes(this.translate.instant('DOCUMENTATION.DOCUMENT'))) {
          this.documentTypeList.push(this.translate.instant('DOCUMENTATION.DOCUMENT'));
        }
        if (document.subtype === 'INVEST' && document.name && !this.documentTypeList.includes(document.name)) {
          this.documentTypeList.push(document.name);
        }
        if ((document.subtype === 'ANNUAL_REPORT' || document.subtype === 'WEALTH_CERTIFICATE')
            && !this.yearList.includes(document.taxYear)) {
          this.yearList.push(document.taxYear);
        }
        if (document.subtype === 'INVEST' && document.year && !this.yearList.includes(document.year)) {
          this.yearList.push(document.year);
        }
      });
      this.listOfFilter = [
        {
          title: this.translate.instant('DOCUMENTATION.SUBTITLE'),
          list: this.documentTypeList,
          id: 'type'
        },
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.PROJECT'),
          list: this.projectList,
          id: 'project'
        },
        {
          title: this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DATE'),
          list: this.yearList,
          id: 'date'
        }
      ];
    }

    for (let i = 0; i < this.fullDocuments.length; i++) {
      this.downloadStatus.push(0);
      this.fullDocuments[i].index = i;
    }

    if (this.fullDocuments.length > 0) {
      this.hasDocuments = true;
    } else {
      this.hasDocuments = false;
    }
    this.documents = this.fullDocuments;
    this.loading = false;
    this.orderByDate();
    this.chdRef.detectChanges();
  }

  applyFilters(filters: any) {
    let filteredDocuments = this.fullDocuments;
    let checked = [];
    filters.forEach((filter) => {
      switch (filter.id) {
        case 'project':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredDocuments = filteredDocuments.filter((document) => checked.includes(document.project));
          }
          break;
        case 'type':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredDocuments = filteredDocuments.filter((document) => checked.includes((document.subtype === 'INVEST') ? document.name : this.translate.instant('DOCUMENTATION.DOCUMENT')));
          }
          break;
        case 'date':
          checked = [];
          filter.values.forEach((value) => {
            if (value.checked) {
              checked.push(value.name);
            }
          });
          if (checked.length > 0) {
            filteredDocuments = filteredDocuments.filter((document) => checked.includes((document.subtype === 'INVEST') ? document.year : document.taxYear));
          }
          break;
      }
      this.documents = filteredDocuments;
    });
  }

  orderByName() {
    this.nameDown = !this.nameDown;
    this.typeDown = true;
    this.dateDown = true;
    this.sortByName(this.documents);
  }

  orderByType() {
    this.typeDown = !this.typeDown;
    this.nameDown = true;
    this.dateDown = true;
    this.sortByType(this.documents);
  }

  orderByDate() {
    this.dateDown = !this.dateDown;
    this.nameDown = true;
    this.typeDown = true;
    this.sortByDate(this.documents);
  }

  sortByName(arr) {
    const annual = this.translate.instant('DOCUMENTATION.ANNUAL_RE');
    const certificate = this.translate.instant('DOCUMENTATION.CERTIFICATE');
    const agreement = this.translate.instant('DOCUMENTATION.AGREEMENT');
    if (this.nameDown) {
      arr.sort(function(a, b) {
        let aType;
        let bType;
        switch (a.subtype) {
          case 'ANNUAL_REPORT':
            aType = annual;
            break;
          case 'WEALTH_CERTIFICATE':
            aType = certificate;
            break;
          case 'INVEST':
            aType = agreement;
            break;
        }
        switch (b.subtype) {
          case 'ANNUAL_REPORT':
            bType = annual;
            break;
          case 'WEALTH_CERTIFICATE':
            bType = certificate;
            break;
          case 'INVEST':
            bType = agreement;
            break;
        }
        if (aType > bType) {
          return 1;
        }
        if (aType < bType) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      arr.sort(function(a, b) {
        let aType;
        let bType;
        switch (a.subtype) {
          case 'ANNUAL_REPORT':
            aType = annual;
            break;
          case 'WEALTH_CERTIFICATE':
            aType = certificate;
            break;
          case 'INVEST':
            aType = agreement;
            break;
        }
        switch (b.subtype) {
          case 'ANNUAL_REPORT':
            bType = annual;
            break;
          case 'WEALTH_CERTIFICATE':
            bType = certificate;
            break;
          case 'INVEST':
            bType = agreement;
            break;
        }
        if (aType < bType) {
          return 1;
        }
        if (aType > bType) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    return arr;
  }
  sortByType(arr) {
    const document = this.translate.instant('DOCUMENTATION.DOCUMENT');
    if (this.typeDown) {
      arr.sort(function(a, b) {
        if (((a.subtype === 'INVEST') ? a.name : document) > ((b.subtype === 'INVEST') ? b.name : document)) {
          return 1;
        }
        if (((a.subtype === 'INVEST') ? a.name : document) < ((b.subtype === 'INVEST') ? b.name : document)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    } else {
      arr.sort(function(a, b) {
        if (((a.subtype === 'INVEST') ? a.name : document) < ((b.subtype === 'INVEST') ? b.name : document)) {
          return 1;
        }
        if (((a.subtype === 'INVEST') ? a.name : document) > ((b.subtype === 'INVEST') ? b.name : document)) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    }
    return arr;
  }
  sortByDate(arr) {
    if (this.dateDown) {
      arr.sort(function(a, b) {
        return ((a.subtype === 'INVEST') ? a.year : a.taxYear) - ((b.subtype === 'INVEST') ? b.year : b.taxYear);
      });
    } else {
      arr.sort(function(a, b) {
        return ((b.subtype === 'INVEST') ? b.year : b.taxYear) - ((a.subtype === 'INVEST') ? a.year : a.taxYear);
      });
    }
    return arr;
  }

  downloadDocument(document) {
    if (this.downloadStatus[document.index] === 0) {
      if (document.subtype === 'INVEST') {
        this.downloadInvestDocuments(document);
      } else {
        this.getDocument(document);
      }
      this.downloadStatus[document.index] = 1;
    }
  }

  dcumentDownloaded(document) {
    this.downloadStatus[document.index] = 2;
    setTimeout(() => { this.downloadStatus[document.index] = 0; }, 5000);
  }

  getDocument(doc) {
    let filename: string;

    if (doc.subtype === 'ANNUAL_REPORT') {
      filename = this.translate.instant('DOCUMENTATION.ANNUAL_RE');
    } else if (doc.subtype === 'WEALTH_CERTIFICATE') {
      filename = this.translate.instant('DOCUMENTATION.CERTIFICATE');
    } else {
      filename =`${this.translate.instant('DOCUMENTATION.AGREEMENT')}: ${doc.project}`;
    }

    this.profileService.getSpecificDocument(doc.type, doc.uuid).subscribe(response => {
      if (Capacitor.getPlatform() === 'ios') {
        const dataBase64 = `${response}`;
        const fileName2 = `${filename}.pdf`;
        const DataBlob = this.utils.b64toBlob(dataBase64, 'application/pdf');

        this.file.writeFile(this.file.tempDirectory, fileName2, DataBlob, { replace: true }).then(res => {
          Share.share({
            title: fileName2,
            url: res.nativeURL,
          }).then(resShare => {
            this.dcumentDownloaded(doc);
            this.toastService.showToast(this.translate.instant('MESSAGES.DOCUMENT_DOWNLOAD_OK'), 'success', true);
          });
        }, err => {
        console.log('Error dataDirectory: ', err);
        });
      } else if (Capacitor.getPlatform() === 'android') {
        const linkSource = 'data:application/pdf;base64,' + response;
        const modalParams = {
          documents: [{ url: linkSource }],
          btnList: false
        };
        this.modalService.showModal(InvestDocumentViewfinderComponent, modalParams, '', '', true).subscribe((modal: any) => {
          this.dcumentDownloaded(doc);
          modal.onClose().subscribe();
        });
      } else {
        const linkSource = 'data:application/pdf;base64,' + response;
        const downloadLink = document.createElement("a");
        const fileName = filename + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.dcumentDownloaded(doc);
      }
    });
  }

  downloadInvestDocuments(doc) {
    const filename =`${this.translate.instant('DOCUMENTATION.AGREEMENT')}: ${doc.project}`;
    this.profileService.getInvestDocuments(doc.projectId).subscribe(response => {
      if (Capacitor.getPlatform() === 'ios') {
        const dataBase64 = `${response}`;
        const fileName2 = `${filename}.pdf`;
        const DataBlob = this.utils.b64toBlob(dataBase64, 'application/pdf');

        this.file.writeFile(this.file.tempDirectory, fileName2, DataBlob, { replace: true }).then(res => {
          Share.share({
            title: fileName2,
            url: res.nativeURL,
          }).then(resShare => {
            this.dcumentDownloaded(doc);
            this.toastService.showToast(this.translate.instant('MESSAGES.DOCUMENT_DOWNLOAD_OK'), 'success', true);
          });
        }, err => {
        console.log('Error dataDirectory: ', err);
        });
      } else if (Capacitor.getPlatform() === 'android') {
        const linkSource = 'data:application/pdf;base64,' + response;
        const modalParams = {
          documents: [{ url: linkSource }],
          btnList: false
        };
        this.modalService.showModal(InvestDocumentViewfinderComponent, modalParams, '', '', true).subscribe((modal: any) => {
          this.dcumentDownloaded(doc);
          modal.onClose().subscribe();
        });
      } else {
        const linkSource = 'data:application/pdf;base64,' + response;
        const downloadLink = document.createElement("a");
        const fileName = filename + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.dcumentDownloaded(doc);
      }
    });
  }

  viewDocument(file) {
    const url = file.url;
    const filename = file.name + '.docx';

    if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
      Http.downloadFile({
        url: url,
        filePath: filename,
        method: 'GET',
        headers: {
          'Content-Type': 'application/octet-stream'
        }
      }).then(async response => {
        //console.log(response, 'respuesta en el primer paso')
        await Filesystem.writeFile({
          path: filename,
          data: response.path,
          directory: Directory.Documents,
          encoding: Encoding.UTF8
        }).then((res) => {console.log(res)}, error => {console.log(error, 'step 1 error')});
        await Filesystem.getUri({
          path: filename,
          directory: Directory.Documents
        }).then(uri => {
          //console.log(uri, 'uri')
          const fileUrl = uri.uri;
        }, error => {
          //console.log(error, 'Casca fallo al descargar')
          this.toastService.showToast(this.translate.instant('DOCUMENTATION.DOWNLOAD_FILE_ERROR'), 'warning');
        });
      }, error => {
        console.log(error)
      });
    } else {
      fetch(url)
      .then(res => res.blob())
      .then(blob => saveAs(blob, filename));
    }
  }

}
