import { Injectable, Output, EventEmitter } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SidemenuService {

  @Output() public toggleMenuEvent = new EventEmitter<any>();
  @Output() public elementClickedEvent = new EventEmitter<any>();

  userName: string;
  color: string;

  constructor(private storage: Storage, private authService: AuthService) {
    this.authService.logoutEvent.subscribe(() => {
      this.userName = null;
      this.color = null;
    })
   }

  toggleMenu() {
    this.toggleMenuEvent.emit();
  }

  clickElement(el) {
    this.elementClickedEvent.emit(el);
  }

  async getUserName() {
    if (this.userName === null || this.userName === undefined || this.userName === '') {
      await this.storage.get('NAME').then((val) => {
        if (val !== null && val !== undefined) {
          this.userName = val;
          return val;
        } else {
          this.userName = '';
          return '';
        }
      });
    }
    return this.userName;
  }

  async getAvatarColor() {
    if (this.color === null || this.color === undefined) {
      await this.storage.get('COLOR').then((val) => {
        if (val !== null && val !== undefined) {
          this.color = val;
          return val;
        } else {
          this.color = '';
          return '';
        }
      });
    }
    return this.color;
  }
}
