import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { conditionalValidator } from '../../movements/money-out/money-out-change-account/money-out-change-account.validator';
import { TaxdownService } from 'src/app/services/taxdown.service';
import { ToastService } from '../../toast/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Storage } from '@ionic/storage';
import { Profile } from 'src/app/shared/models/register.models';
import { TranslateService } from '@ngx-translate/core';
import { RegisterFileComponent } from '../register-file/register-file.component';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'fun-register-taxdown',
  templateUrl: './register-taxdown.component.html',
  styleUrls: ['./register-taxdown.component.scss'],
})
export class RegisterTaxdownComponent implements OnInit {

  @Input() email: string;
  @Output() public nextEvent = new EventEmitter<any>();
  @Output() public modifyTaxDownDataEvent = new EventEmitter<any>();
  @Output() public closemodalEvent = new EventEmitter<any>();
  @ViewChild('registerPersonFile', { static: false }) registerPersonFile: RegisterFileComponent;

  public taxdownForm: FormGroup;
  public formControlKeys = [];
  public formControlKeysRep = [];
  public formControlKeysCompany = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  loading: boolean;
  acceptedTerms: boolean;
  docType: string;
  option: number = 1;
  step: number = 0;
  sendXHR: string = 'primaryDark-disabled';

  documentList = [
    {
      id: 'DNI',
      name: 'DNI'
    },
    {
      id: 'NIE',
      name: 'NIE'
    }
  ];

  challengeList = [
    {
      id: 'APP',
      name: 'APP'
    },
    {
      id: 'SMS',
      name: 'SMS'
    }
  ]

  sessionId: string;
  pinCode: string;
  userData: any;
  modelUserData: any;
  profile: Profile;
  overalText: string;
  overalImage: number;
  btnList = [];
  profileNotUpdated = false;
  cityUpdated = false;
  specificFileSteps: any; // [{index: 0, name: 'dni_file'}];
  documentsModel: any;
  textFinish: string;
  imageFinish = 6;
  btnListFinish = [
    {
      type: 'primaryDark',
      index: 13,
      title: this.translate.instant('register.btn.navigate')
    },
  ];

  towns = this.profileService;
  newAddressInfo: any;
  hasNationality: boolean;
  nationalityData: any;

  constructor(
    public chdRef: ChangeDetectorRef,
    private taxdownService: TaxdownService,
    private toastService: ToastService,
    private authService: AuthService,
    private profileService: ProfileService,
    private storage: Storage,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.overalImage = 1;
    this.docType = 'DNI';
    this.acceptedTerms = false;
    this.hasNationality = true;
    this.loading = false;
    const title = this.translate.instant('register.finish.step_1.title');
    const text = this.translate.instant('register.finish.step_1.txt');
    this.textFinish = title + ' <p>' + text + '</p> ';
    this.taxdownForm = new FormGroup({
      identity_type: new FormControl('', [Validators.required]),
      supportNumber: new FormControl('', [conditionalValidator(
        () => this.taxdownForm && this.taxdownForm.get('identity_type')?.value === 'NIE',
        Validators.required
      )]),
      validationDate: new FormControl('', [Validators.required]),
      docNumber: new FormControl('', [Validators.required]),
      challengeMethod: new FormControl(0, [Validators.required])
    });
    this.chdRef.detectChanges();
  }

  selectOption(param) {
    this.option = param;
    if (this.option === 2 || this.acceptedTerms) {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  checkAction(check) {
    switch (check) {
      case 'terms':
        this.acceptedTerms = !this.acceptedTerms;
        this.selectOption(this.option);
        break;
      case '':
        //number = 1;
        break;
    }
    this.chdRef.detectChanges();
  }

  controlForm() {
    this.docType = this.taxdownForm.value.identity_type;
    if (this.taxdownForm.status === 'VALID') {
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  getPinCode(code) {
    if (code['code1'] && code['code2'] && code['code3']) {
      this.pinCode = `${code['code1']}${code['code2']}${code['code3']}`;
      this.sendXHR = 'primaryDark';
    } else {
      this.sendXHR = 'primaryDark-disabled';
    }
    this.chdRef.detectChanges();
  }

  next() {
    switch (this.step) {
      case 0:
        if (this.option === 2) {
          this.nextEvent.emit();
        } else {
          this.step++;
          this.sendXHR = 'primaryDark-disabled';
        }
        break;
      case 1:
        let model = {
          nif: this.taxdownForm.value.identity_type === 'DNI' ? this.taxdownForm.value.docNumber : null,
          nie: this.taxdownForm.value.identity_type === 'NIE' ? this.taxdownForm.value.docNumber : null,
          supportNumber: this.taxdownForm.value.identity_type === 'NIE' ? this.taxdownForm.value.supportNumber : null,
          validationDate: this.taxdownForm.value.validationDate.replace(/\//g, "-"),
          challengeMethod: this.taxdownForm.value.challengeMethod
        };
        this.loading = true;
        this.taxdownService.createPinSession(model).subscribe(resp => {
          this.sessionId = resp.data.item.id;
          this.step++;
          this.loading = false;
          this.sendXHR = 'primaryDark-disabled';
          this.chdRef.detectChanges();
        }, error => {
          this.loading = false;
          this.sendXHR = 'primaryDark-disabled';
          this.toastService.showToast(this.translate.instant('ERRORS.500'), 'warning', true);
          this.chdRef.detectChanges();
        });
        break;
      case 2:
        this.loading = true;
        const pinModel = {
          pin: this.pinCode
        };
        this.taxdownService.updatepinSessionsessionId(this.sessionId, pinModel).subscribe(resp => {
          this.sessionId = resp.data.item.id;
          this.taxdownService.getAeatData(this.sessionId).subscribe(response => {
            this.userData = response.data.item;
            this.userData['streetName'] = decodeURIComponent(escape(this.userData['streetName']));
            this.userData['town'] = decodeURIComponent(escape(this.userData['town']));
            const bDate = this.userData.birthDate.replace(/-/g, '/');
            const [year, month, day] = bDate.split('/');
            const date = `${day}/${month}/${year}`;

            this.modelUserData = {
              name: this.userData.name,
              surname: `${this.userData.surname} ${this.userData.secondSurname}`,
              birthDate: date,
              taxAddress: `${this.userData.streetType} ${this.userData.streetName} ${this.userData.streetNumber}, ${this.userData.town}, ${this.userData.province}`,
              taxPostalCode: this.userData.postalCode,
              docType: this.taxdownForm.value.identity_type === 'DNI' ? 1 : 2,
              annualGrossIncome: this.userData.grossSalary,
              civilStatus: this.userData.civilStatus === 'Single' ? 1 : this.userData.civilStatus === 'Married' ? 2 : null
            };
            if (this.taxdownForm.value.identity_type === 'DNI') {
              this.modelUserData['identityDocumentDni'] = this.userData.nif;
              this.specificFileSteps = [{index: 0, name: 'dni_file'}];
            } else {
              this.modelUserData['identityDocumentNie'] = this.userData.nie;
              this.specificFileSteps = [{index: 0, name: 'nie_file'}, {index: 1, name: 'passport_number'}, {index: 2, name: 'passport_file'}];
            }
            this.step++;
            this.loading = false;
            this.chdRef.detectChanges();
          }, error => {
            this.loading = false;
            this.toastService.showToast(this.translate.instant('ERRORS.500'), 'warning', true);
            this.chdRef.detectChanges();
          });
        }, error => {
          this.loading = false;
          this.toastService.showToast(this.translate.instant('ERRORS.500'), 'warning', true);
          this.chdRef.detectChanges();
        });
        break;
      case 4:
        if (this.profile['taxCity']) {
          this.modelUserData['taxCity'] = this.profile['taxCity'];
          this.modelUserData['taxRegion'] = this.profile['taxRegion'];
        }
        if (this.profileNotUpdated) {
          this.loading = true;
          this.modifyTaxDownDataEvent.emit(this.modelUserData)
          this.chdRef.detectChanges();
        } else {
          if (!this.cityUpdated || !this.hasNationality) {
            this.step = 5;
            this.chdRef.detectChanges();
          } else {
            this.step = 6;
            this.chdRef.detectChanges();
          }
          this.chdRef.detectChanges();
        }
        break;
      case 5:
        if (!this.hasNationality) {
          this.loading = true;
          const model ={nationality: this.nationalityData.nation, taxCountry: this.nationalityData.residence}
          this.profileService.updateProfile(model).subscribe(profVal => {
            this.storage.set('PROFILE', profVal);
            this.profile = profVal;
            this.hasNationality = true;
            if (this.cityUpdated) {
              this.step++;
            }
            this.loading = false;
            this.chdRef.detectChanges();
          }, error => {
            this.toastService.showToast(this.translate.instant('ERRORS.500'), 'warning', true);
            this.loading = false;
            this.chdRef.detectChanges();
          });
        } else {
          const modelC = {
            taxCity: this.newAddressInfo.city,
            taxRegion: this.newAddressInfo.region
          };
          this.loading = true;
          this.profileService.updateProfile(modelC).subscribe(profVal => {
            this.storage.set('PROFILE', profVal);
            this.step++;
            this.loading = false;
            this.chdRef.detectChanges();
          }, error => {
            this.toastService.showToast(this.translate.instant('ERRORS.500'), 'warning', true);
            this.loading = false;
            this.chdRef.detectChanges();
          });
          this.chdRef.detectChanges();
        }
        break;
    }
    this.chdRef.detectChanges();
  }

  gerateProfileAndUpdateData() {
    this.loading = true;
    const model = {
      profileType: 1,
      birthDate: this.modelUserData.birthDate,
      surname: this.modelUserData.surname,
      username: this.email,
      name: this.modelUserData.name,
    };
    this.profileService.generateProfile(model).subscribe(prof => {
      this.profile = prof;
      this.storage.set('PROFILE', prof);
      setTimeout(() => {
        this.authService.profileGeneratedByTaxDown(this.modelUserData.name, prof);
        let profModel = {
          docType: this.modelUserData['docType'],
          taxAddress: this.modelUserData['taxAddress'],
          taxPostalCode: this.modelUserData['taxPostalCode'],
          extraPostalCodes: this.modelUserData['taxPostalCode'],
          annualGrossIncome: this.modelUserData['annualGrossIncome'],
          civilStatus: this.modelUserData['civilStatus']
        }
        if (this.userData.country === 'ES') {
          profModel['nationality'] = 108;
          profModel['taxCountry'] = 108;
        } else {
          this.hasNationality = false;
        }
        if (this.taxdownForm.value.identity_type === 'DNI') {
          profModel['identityDocumentDni'] = this.userData.nif;
        } else {
          profModel['identityDocumentNie'] = this.userData.nie;
        }

        this.profileService.updateProfile(profModel).subscribe(val => {
          this.profile = val;
          this.storage.set('PROFILE', val);
          if (this.userData.provinceCode) {
            this.profileService.getCities(108, this.userData.provinceCode).subscribe(listT => {
              const listOfTowns = listT;
              const taxR = this.userData.provinceCode;
              const matchingTown = listOfTowns.find(town => town.name.toLowerCase() === this.userData.town.toLowerCase());

              if (matchingTown) {
                const modelC = {
                  taxCity: matchingTown.id,
                  taxRegion: taxR
                };

                this.profileService.updateProfile(modelC).subscribe(profVal => {
                  this.cityUpdated = true;
                  this.overalImage = 3;
                  this.overalText = this.translate.instant('REGISTER.FINISH_TAXDOWN_1');
                  this.profile = profVal;
                  this.storage.set('PROFILE', profVal);
                  this.profileService.getPhoneCode(this.userData['phone']).subscribe(value => {
                    this.step++;
                    this.loading = false;
                    this.chdRef.detectChanges();
                  }, error => {
                    this.step++;
                    this.loading = false;
                    this.chdRef.detectChanges();
                  });
                }, error => {
                  this.overalImage = 3;
                  this.overalText = this.translate.instant('REGISTER.FINISH_TAXDOWN_2');
                  this.step++;
                  this.loading = false;
                  this.profileService.getPhoneCode(this.userData['phone']).subscribe(value => {
                    this.chdRef.detectChanges();
                  }, error => {
                    this.chdRef.detectChanges();
                  });
                  this.chdRef.detectChanges();
                });
              } else {
                this.overalImage = 3;
                this.overalText = this.translate.instant('REGISTER.FINISH_TAXDOWN_2');
                this.step++;
                this.loading = false;
                this.profileService.getPhoneCode(this.userData['phone']).subscribe(value => {
                  this.chdRef.detectChanges();
                }, error => {
                  this.chdRef.detectChanges();
                });
                this.chdRef.detectChanges();
              }
              this.chdRef.detectChanges();
            });
          } else {
            this.overalImage = 3;
            this.overalText = this.translate.instant('REGISTER.FINISH_TAXDOWN_3');
            this.step++;
            this.loading = false;
            this.profileService.getPhoneCode(this.userData['phone']).subscribe(value => {
              this.chdRef.detectChanges();
            }, error => {
              this.chdRef.detectChanges();
            });
            this.chdRef.detectChanges();
          }
        },error => {
          this.profileNotUpdated = true;
          this.overalText = this.translate.instant('REGISTER.ERROR_TAXDOWN_1');
          this.step++;
          this.loading = false;
          this.chdRef.detectChanges();
        });
        this.chdRef.detectChanges();
      }, 200);
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  getDocuments(docs) {
    this.documentsModel = docs;
    this.chdRef.detectChanges();
  }

  lastStepPerson() {
    this.finish();
    this.chdRef.detectChanges();
  }

  finishRegister() {
    this.finish();
    this.chdRef.detectChanges();
  }

  getAddressInfo(val) {
    this.newAddressInfo = val;
    this.chdRef.detectChanges();
  }

  emitDocs(val) {
    this.registerPersonFile.loading = true;
    const documents = this.documentsModel ;
    const msg = this.translate.instant('REGISTER.MY_PROFILE.UPLOAD_ERROR');

    if (documents.frontDocument && documents.frontDocument!== undefined) {
      this.profileService.uploadFile(documents.frontDocument).subscribe(resp => {
        const documentFront = resp.fileID;
        if (documents.backDocument && documents.backDocument!== undefined) {
          this.profileService.uploadFile(documents.backDocument).subscribe(resp => {
            const documentBack = resp.fileID;
            const model = {
              identityDocumentFront: documentFront,
              identityDocumentBack: documentBack
            }
            this.profileService.updateProfile(model).subscribe((response) => {
              this.storage.set('PROFILE', response);
              this.step++;
              this.registerPersonFile.loading = false;
              this.chdRef.detectChanges();
            }, error => {
              this.toastService.showToast(msg, 'warning', true);
              this.registerPersonFile.loading = false;
              this.chdRef.detectChanges();
            });
            this.chdRef.detectChanges();
          }, error => {
            const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
            this.toastService.showToast(specificMsg, 'warning', true);
            this.registerPersonFile.loading = false;
            this.chdRef.detectChanges();
          });
          this.chdRef.detectChanges();
        } else {
          const model = {
            identityDocumentFront: documentFront
          }
          this.profileService.updateProfile(model).subscribe((response) => {
            this.storage.set('PROFILE', response);
            this.step++;
            this.registerPersonFile.loading = false;
            this.chdRef.detectChanges();
          }, error => {
            this.registerPersonFile.loading = false;
            this.toastService.showToast(msg, 'warning', true);
            this.chdRef.detectChanges();
          });
          this.chdRef.detectChanges();
        }
      }, error => {
        this.registerPersonFile.loading = false;
        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
        this.toastService.showToast(specificMsg, 'warning', true);
        this.chdRef.detectChanges();
      });
    } else if(documents.frontNieDocument && documents.frontNieDocument!== undefined) {
      this.profileService.uploadFile(documents.frontNieDocument).subscribe(resp => {
        const documentFront = resp.fileID;
        if (documents.backNieDocument && documents.backNieDocument!== undefined) {
          this.profileService.uploadFile(documents.backNieDocument).subscribe(resp => {
            const documentBack = resp.fileID;
              const model = {
                identityDocumentFrontNie: documentFront,
                identityDocumentBackNie: documentBack
              }
              this.profileService.updateProfile(model).subscribe((response) => {
                this.storage.set('PROFILE', response);
                this.registerPersonFile.loading = false;
                this.chdRef.detectChanges();
              }, error => {
                this.registerPersonFile.loading = false;
                this.toastService.showToast(msg, 'warning', true);
                this.chdRef.detectChanges();
              });
            this.chdRef.detectChanges();
          }, error => {
            this.registerPersonFile.loading = false;
            const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
            this.toastService.showToast(specificMsg, 'warning', true);
            this.chdRef.detectChanges();
          });
          this.chdRef.detectChanges();
        } else {
          const model = {
            identityDocumentFrontNie: documentFront
          }
          this.profileService.updateProfile(model).subscribe((response) => {
            this.storage.set('PROFILE', response);
            this.registerPersonFile.loading = false;
            this.chdRef.detectChanges();
          }, error => {
            this.registerPersonFile.loading = false;
            this.toastService.showToast(msg, 'warning', true);
            this.chdRef.detectChanges();
          });
        }
        this.chdRef.detectChanges();
      }, error => {
        this.registerPersonFile.loading = false;
        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
        this.toastService.showToast(specificMsg, 'warning', true);
        this.chdRef.detectChanges();
      });
      this.chdRef.detectChanges();
    } else if (documents.frontPassportDocument && documents.frontPassportDocument !== undefined) {
      this.profileService.uploadFile(documents.frontPassportDocument).subscribe(file => {
        const documentPassport = file.fileID;
        const model = {
          identityDocumentFrontPassport: documentPassport
        }
        this.profileService.updateProfile(model).subscribe((response) => {
          this.storage.set('PROFILE', response);
          this.step++;
          this.registerPersonFile.loading = false;
          this.chdRef.detectChanges();
          }, error => {
            this.registerPersonFile.loading = false;
            this.toastService.showToast(msg, 'warning', true);
            this.chdRef.detectChanges();
          });
          this.chdRef.detectChanges();
      }, error => {
        this.registerPersonFile.loading = false;
        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : msg;
        this.toastService.showToast(specificMsg, 'warning', true);
        this.chdRef.detectChanges();
      });
    }
    this.chdRef.detectChanges();
  }

  updatePassportNumber(value) {
    this.registerPersonFile.loading = true;
    this.profileService.updateProfile(value).subscribe((response) => {
      this.storage.set('PROFILE', response);
      this.registerPersonFile.loading = false;
      this.chdRef.detectChanges();
      }, error => {
        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : this.translate.instant('REGISTER.MY_PROFILE.UPLOAD_ERROR');
        this.toastService.showToast(specificMsg, 'warning', true);
        this.chdRef.detectChanges();
      });
    this.chdRef.detectChanges();
  }

  getNationalityInfo(val) {
    this.nationalityData = val;
    this.chdRef.detectChanges();
  }

  prevStep() {
    this.step--;
    this.chdRef.detectChanges();
  }

  finish() {
    this.closemodalEvent.emit();
    this.chdRef.detectChanges();
  }

}
