import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentNs from 'moment-timezone';
const moment = momentNs;

@Component({
  selector: 'app-cancel-invest-movement',
  templateUrl: './cancel-invest-movement.component.html',
  styleUrls: ['./cancel-invest-movement.component.scss'],
})
export class CancelInvestMovementComponent implements OnInit {

  @Output() public goBackEvent = new EventEmitter<any>();
  @Output() public investrejectedEvent = new EventEmitter<any>();
  @Input('investmentList') investmentList;

  text = this.translate.instant('MESSAGES.CANCEL_INVESTMENT_MSG');
  btnType = 'primaryLight-disabled';
  showMsg = false;
  loading = false;

  constructor(
    private translate: TranslateService,
    private cdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.orderByAmount(this.investmentList);
    for (let i = 0; i < this.investmentList.length; i++) {
      this.investmentList[i]['selected'] = false;
      this.investmentList[i]['amount'] = Math.abs(this.investmentList[i]['amount']);
      this.investmentList[i]['index'] = i;
      this.investmentList[i].dateAccountant = this.investmentList[i].dateAccountant.substring(10, 0);
    }
    this.cdRef.detectChanges();
  }

  orderByAmount(arr) {
    arr.sort(function (a, b) {
      return Number(b.amount) - Number(a.amount);
    });
    return arr;
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  selectInvestment(index) {
    for (let i = 0; i < this.investmentList.length; i++) {
      if (this.investmentList[i]['index'] === index) {
        this.investmentList[i]['selected'] = !this.investmentList[i]['selected'];
      } else {
        this.investmentList[i]['selected'] = false;
      }
    }
    const selectedArray = this.investmentList.filter((item) => item['selected'] === true);
    if (selectedArray.length > 0) {
      let totalSelected = 0;
      let total = 0;

      selectedArray.filter(obj => {
        totalSelected = totalSelected + obj.investment;
      });
      this.investmentList.filter(obj => {
        total = total + obj.investment;
      });

      if ((total - totalSelected < 500) && (totalSelected !== total)) {
        this.showMsg = true;
      } else {
        this.showMsg = false;
      }

      this.btnType = 'primaryLight';
    } else {
      this.btnType = 'primaryLight-disabled';
      this.showMsg = false;
    }
    this.cdRef.detectChanges();
  }

  cancelSelected() {
    this.loading = true;
    this.text = this.translate.instant('MESSAGES.LOADING_CANCEL_INVEST');
    const selectedArray = this.investmentList.filter((item) => item['selected'] === true);
    this.investrejectedEvent.emit(selectedArray);
    this.cdRef.detectChanges();
  }

  goBack() {
    this.goBackEvent.emit();
    this.cdRef.detectChanges();
  }

}
