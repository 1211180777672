import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  ViewContainerRef,
  Inject
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionSheetComponent } from './action-sheet.component';

@Injectable({
  providedIn: 'root'
})
export class ActionSheetService {
  public ref: ComponentRef<ActionSheetComponent>;
  public component$: Observable<any>;
  public componentViewContainer: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    @Inject(DOCUMENT) private document: any
  ) { }

  public showModal<T>(component: any, parameters?: object, title?: string, type?: string,
    enableCloseOut?: boolean): Observable<ActionSheetComponent> {

const factory = this.componentFactoryResolver.resolveComponentFactory(ActionSheetComponent);
this.ref = factory.create(this.injector);
this.ref.changeDetectorRef.detectChanges();
const { nativeElement } = this.ref.location;
this.document.body.appendChild(nativeElement);

const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(component);
this.componentViewContainer = this.ref.instance.componentPlaceholder;
this.injector = this.ref.injector;
this.ref.instance.childComponentRef$ = this.createChildComponentFromFactory<T>(componentFactory, parameters);

const modalRef$ = new ReplaySubject();
modalRef$.next(this.ref);
modalRef$.complete();
this.ref.instance.type = 'm_light ' + type;
this.ref.instance.title = title;
this.ref.instance.enableCloseOut = enableCloseOut;
return modalRef$.asObservable().pipe(map( (modal: ComponentRef<ActionSheetComponent>) => modal.instance )) as Observable<ActionSheetComponent>;
}

/**
* This method is used to destroy a modal dinamically.
*/
public removeModal() {
if (this.ref) {
const { nativeElement } = this.ref.location;
this.document.body.removeChild(nativeElement);
}
}

private createChildComponentFromFactory<T>(componentFactory: ComponentFactory<T>,
                           parameters?: object): Observable<ComponentRef<T>> {

const componentRef$ = new ReplaySubject();
const childInjector = Injector.create({
providers: [],
parent: this.injector
});
const componentRef = this.componentViewContainer.createComponent(componentFactory, 0, childInjector);

// pass the @Input parameters to the instance
Object.assign(componentRef.instance, parameters);
componentRef.instance['destroy'] = () => {
componentRef.destroy();
};
componentRef.changeDetectorRef.detectChanges();
componentRef$.next(componentRef);
componentRef$.complete();
return componentRef$.asObservable() as Observable<ComponentRef<T>>;
}

}
