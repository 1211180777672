import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpParams, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpClient) { }

  public upload(url: string, paramsData?: {}, token?: string): Observable<HttpEvent<any>> {
    let headersOptions: {} = null;

    if (token) {
      headersOptions = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token };
    } else {
      headersOptions = { 'Content-Type': 'application/json' };
    }
    const headers = new HttpHeaders(headersOptions);
    const options = {
      params: new HttpParams(),
      reportProgress: true,
      headers
    };
    const request = new HttpRequest('POST', url, paramsData, options);
    return this.httpClient.request(request);
  }


  public toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
