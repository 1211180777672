<div *ngIf="!loading" class="genearl-container" [ngClass]="{'container-active': step === 1}" id="sign_up_page">
    <div class="register">
        <div class="span-container">
            <span id="page-title">Registro</span>
        </div>
    </div>
    <div *ngIf="step === 0" class="body-content">
        <div class="step pwd">
            <div class="header">
                <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.create_your_account' | translate }}</h2>
            </div>
            <fun-register-person-email
            #registerPersonEmail
            [data]="userData"
            profileType="1"
            (sendErrorEvent)="getError()"
            (sendInfoEvent)="getEmailData($event)"
            (acceptConditionsEvent)="getConditions($event)"
            (acceptOffersEvent)="getOffers($event)"
            (checkRefCodeEvent)="checkRefCode($event)">
            </fun-register-person-email>
        </div>
    </div>

    <h2 *ngIf="step === 1" class="font-sans text-eggplant-100 text-h500 md:text-h700 bg-gradient-2-text py-32">{{ title }}</h2>
    <p *ngIf="step === 1" class="font-sans text-eggplant-100/[0.55] text-l">{{ subtitle }}</p>
    <div *ngIf="step === 1" class="pt-32">
        <div class="question cursor-pointer mr-16 mb-16 w-full" (click)="selectOption(1)"
        [ngClass]="{'option-selected': profileType === 1, 'option': profileType !== 1}">
            <p class="px-24 py-4 font-sans text-l text-eggplant-100 mt-12">{{ 'REGISTER.PUBLIC.P1_TITLE' | translate }}</p>
            <h4 class="px-24 py-4 font-sans text-m text-eggplant-100/[0.55] ">{{ 'REGISTER.PUBLIC.P1_SUBTITLE' | translate }}</h4>
        </div>
        <div class="question cursor-pointer mr-16 w-full" (click)="selectOption(2)"
        [ngClass]="{'option-selected': profileType === 2, 'option': profileType !== 2}">
            <p class="px-24 py-4 font-sans text-l text-eggplant-100 mt-12">{{ 'REGISTER.PUBLIC.P2_TITLE' | translate }}</p>
            <h4 class="px-24 py-4 font-sans text-m text-eggplant-100/[0.55] ">{{ 'REGISTER.PUBLIC.P2_SUBTITLE' | translate }}</h4>
        </div>
    </div>
</div>
<div *ngIf="(step === 1 || step === 0) && !loading" class="footer">
    <div class="btn-content">
        <fun-new-button *ngIf="step === 0" [buttonType]="sendXHR" size="xl" title="{{ 'CANCEL_INVEST.BTN_1' | translate }}" (eventClick)="submiForm()" [classExtra]="' !w-full '"></fun-new-button>
        <fun-new-button *ngIf="step === 1" buttonType="primaryDark" size="xl" title="{{ 'CANCEL_INVEST.BTN_1' | translate }}" (eventClick)="setProfileType()" [classExtra]="' !w-full '"></fun-new-button>
    </div>
</div>
<div *ngIf="step === 2 && !loading">
    <fun-overal-message [text]="textAcceptMail" [image]="imageAcceptMail" [btnList]="btnListAcceptMail" (btnEvent)="removeModal()"></fun-overal-message>
</div>
<div *ngIf="loading">
    <fun-skeleton-list></fun-skeleton-list>
</div>