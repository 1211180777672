import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview-contract-summary',
  templateUrl: './overview-contract-summary.component.html',
  styleUrls: ['./overview-contract-summary.component.scss'],
})
export class OverviewContractSummaryComponent implements OnInit {

  @Input() contractSummary: [any];

  documents = [];
  hasSection = false;
  sectionInfo: any;
  viewMore = false;
  viewMoreBlur = false;

  constructor(private translate: TranslateService, private chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    let currentLang = this.translate.currentLang;
    this.translate.onLangChange.subscribe(event => {
      currentLang = event.lang;
      this.chdRef.detectChanges();
    });
    let fullSummary;
    for (let i = 0; i < this.contractSummary.length; i++) {
      if (this.contractSummary[i].contract_summary !== null
        && this.contractSummary[i].contract_summary !== undefined
        && currentLang === this.contractSummary[i].language) {
          fullSummary = this.contractSummary[i].contract_summary;
        } else {
          if (this.contractSummary[i].contract_summary === undefined && currentLang === this.contractSummary[i].language) {
            for (let j = 0; j < this.contractSummary.length; j++) {
              if (this.contractSummary[j].language === 'es') {
                fullSummary = this.contractSummary[j].contract_summary;
              }
            }
          }
        }
    }
    const parser = new DOMParser();
	  const doc = parser.parseFromString(fullSummary, 'text/html');
    const h1List = doc.getElementsByTagName('h1');
    const divList = doc.getElementsByTagName('div');
    const sectionList = doc.getElementsByTagName('section');

    for (let i = 0; i < h1List.length; i++) {
      const element = {
        index: i,
        opened: false,
        title: h1List[i].innerHTML,
        content: divList[i].innerHTML,
        idContent: `content${i}`
      };
      this.documents.push(element)
    }

    if (sectionList !== null && sectionList !== undefined) {
      this.hasSection = sectionList.length > 0 ? true : false;
      if (this.hasSection) {
        this.sectionInfo = sectionList[0].innerHTML;
      }
    }
  }

  openContent(index) {
    this.documents.forEach(element => {
      if (element.index === index) {
        element.opened = !element.opened;
        const el = document.getElementById(`content${index}`);
        if (element.opened) {
          el.style.height = `${el.scrollHeight}px`;
          el.style.transition = 'height 0.5s';
        } else {
          el.style.height = '0px';
          el.style.transition = 'height 0.5s';
        }
      }
    });
  }

  toggleSection() {
    this.viewMore = !this.viewMore;
    const el = document.getElementById('sectionInfo');
    if (this.viewMore) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.transition = 'height 0.5s';
      this.viewMoreBlur = true;
    } else {
      el.style.height = '68px';
      el.style.transition = 'height 0.5s';
      setTimeout(() => {
        this.viewMoreBlur = false;
      }, 500);
    }
  }

}

