<ion-slides [options]="slideOpts" [pager]="false" #currentSlider >
  <ion-slide *ngFor="let image of imageList">
    <div class="relative rounded-xl max-w-[326px] md:max-w-[226px]">
      <div class="absolute top-4 right-4">
        <fun-icon [name]="'user-x'" [style]="'w-24 h-24 stroke-[2px] stroke-[#E64937]'"></fun-icon>
      </div>
      <img [src]="image.url" />
      <p class="font-sans text-m text-eggplant-80">{{ image.text }}</p>
    </div>
  </ion-slide>
</ion-slides>
