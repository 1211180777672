<div class="general-container">
  <p class="title font-sans text-l text-eggplant-80">{{ 'local_invest.phase_title' | translate }} {{ stepName }}.
      <span *ngIf="!info.canInvest && timeRemaining > 0 && info.status !== 15">{{ timeRemainingTxt }}</span>
      <span *ngIf="info.canInvest && info.status !== 15">{{ 'local_invest.can_invest' | translate }}</span>
      <span *ngIf="info.canInvest && info.status === 15">{{ 'local_invest.can_book' | translate }}</span>
      <span *ngIf="!info.canInvest && info.status === 15">{{ 'INVESTMENT_RESERVATION.CAN_RESERVE' | translate }}</span>
  </p>
  <div class="steps-container">
      <app-local-invest-steps [step]="step" [stepList]="stepList"></app-local-invest-steps>
  </div>
</div>
