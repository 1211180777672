import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fund-register-full-iframe',
  templateUrl: './register-full-iframe.component.html',
  styleUrls: ['./register-full-iframe.component.scss']
})
export class RegisterFullIframeComponent implements OnInit {

  @Input() isMobileApp: any;
  @Input() url: any;
  @Output() public signEvent = new EventEmitter<any>();
  @Output() public signErrorEvent = new EventEmitter<any>();
  @Output() public startSignEvent = new EventEmitter<any>();
  @Output() public signaturitIssueEvent = new EventEmitter<any>();
  @Output() public initEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  urlSafe: SafeResourceUrl;
  isCharged: boolean;
  hasUrl = false;
  mobApp: string;

  constructor(private sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.mobApp = (this.isMobileApp !== undefined) ? 'mob_app' : 'content-ifr';
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    window.addEventListener('message', e => {
      // ready, signed
      this.cdRef.detectChanges();
      if (e.data.event === 'completed') {
        this.signEvent.emit();
      } else {
        if (e.data.event === 'declined' || e.data.event === 'declined') {
          this.signErrorEvent.emit();
        }
      }
      // if (e.data.event === 'signed') {}
      if (e.data.message === 'signaturit issue') {
        this.signaturitIssueEvent.emit();
      }
    });
    this.cdRef.detectChanges();
  }

}
