import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { HttpClient, HttpParams } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { InvestCardService } from 'src/app/services/invest-card.service';
import { Http, HttpOptions } from '@capacitor-community/http';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { ToastService } from '../../toast/toast.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-invest-mangopay',
  templateUrl: './invest-mangopay.component.html',
  styleUrls: ['./invest-mangopay.component.scss'],
})
export class InvestMangopayComponent implements OnInit {
  @Input() investData: any;
  @Input() mpWalletModel: any;
  @Output() public editInvestEvent = new EventEmitter<any>();
  @Output() public eventStopTime = new EventEmitter<any>();
  @Output() public successInvested = new EventEmitter<any>();

  urlSafe: SafeResourceUrl;
  loading = false;

  continueBtnType = 'colorFull-disabled';
  prevBtnType = 'ghost';
  cardForm: any;
  step = 0;
  ipAddressClient: any;
  iframeLoaded: boolean;
  timesChecked = 0;
  investResult: string;

  text: string;
  image: number;
  btnList = [];

  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private http: HttpClient,
    private translate: TranslateService,
    private toastService: ToastService,
    private investCardService: InvestCardService,
    private amplitudeService: AmplitudeService
    ) {}

  ngOnInit() {
    this.iframeLoaded = false;
    registerLocaleData(es);
    this.getBrowserInfo();
    window.addEventListener('message', e => {
      switch (e.data.message) {
        case 'close timer':
          this.eventStopTime.emit();
          break;
        case 'invest_finished':
          this.checkTransaction();
          break;
      }
      this.cdRef.detectChanges();
    });
    this.amplitudeService.trackEvent('invest_card_form', { amount: this.investData.amount });
    this.cdRef.detectChanges();
  }

  prev() {
    this.editInvestEvent.emit();
    this.cdRef.detectChanges();
  }

  async investByCard() {
    this.loading = true;
    const body = new HttpParams({
      fromObject: {
        'accessKeyRef': this.investData.mpCardRegistrationAccessKey,
        'data': this.investData.mpCardPreRegistrationData,
        'cardNumber': this.cardForm.cardNumber.replace(/\s/g, ""),
        'cardExpirationDate': this.cardForm.expirationDate.replace('/', '').trim(),
        'cardCvx': this.cardForm.cvv
      }
    });

    this.getRegistrationData(body).subscribe((resp: any) => {
      const tokenData = Capacitor.getPlatform() !== 'web' ? resp.data : resp;
      if (tokenData.includes('data=')) {
        const params = {
          cardRegistrationId: this.investData.mpCardRegistrationId,
          cardRegistrationData: tokenData
        };
        this.investCardService.registerCard(params).subscribe(resp => {
          if (resp.status === 'ERROR' || resp.cardId === null) {
            this.editInvestEvent.emit();
            this.toastService.showToast(this.translate.instant(`invest.errors.02101`), 'warning', true);
            this.continueBtnType = 'colorFull-disabled';
            this.loading = false;
            this.cdRef.detectChanges();
          } else {
            const origin = (Capacitor.getPlatform() === 'web') ? window.origin : environment.origin; // window.origin para pruebas en local
            const model = {
              investmentId: this.investData.id,
              wallet: this.mpWalletModel,
              cardId: resp.cardId,
              secureModeReturnUrl: `${origin}/es/invertir-tarjeta`,
              browserInfo: this.getBrowserInfo()
            };
            this.investCardService.finishInvestByCard(model).subscribe(response => {
              if (response.transaction.mpCardSecureModeNeeded) {
                this.openInvestIframe(response.transaction);
                this.cdRef.detectChanges();
              } else {
                this.checkTransaction();
                this.cdRef.detectChanges();
              }
              this.cdRef.detectChanges();
            }, error => {
              this.editInvestEvent.emit();
              this.toastService.showToast(this.translate.instant(`invest.errors.${error.error.errors[0]}`), 'warning', true);
              this.continueBtnType = 'colorFull-disabled';
              this.loading = false;
              this.cdRef.detectChanges();
            });
          }
          this.cdRef.detectChanges();
        }, error => {
          this.toastService.showToast(this.translate.instant(`invest.errors.02101`), 'warning', true);
          this.continueBtnType = 'colorFull-disabled';
          this.loading = false;
        });
      } else {
        this.toastService.showToast(this.translate.instant(`invest.errors.${tokenData.substring(10, tokenData.length)}`), 'warning', true);
        this.continueBtnType = 'colorFull-disabled';
        this.loading = false;
      }
      this.cdRef.detectChanges();
    }, error => {
      this.toastService.showToast(this.translate.instant(`invest.errors.02101`), 'warning', true);
      this.continueBtnType = 'colorFull-disabled';
      this.loading = false;
      this.cdRef.detectChanges();
    });
    this.cdRef.detectChanges();
  }

  getRegistrationData(body): Observable<any> {
    if (Capacitor.getPlatform() !== 'web') {
      const url = this.investData.mpCardRegistrationURL;
      const options: HttpOptions = {
        url,
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
          'Authorization': '*',
        },
        data: {
          'accessKeyRef': this.investData.mpCardRegistrationAccessKey,
          'data': this.investData.mpCardPreRegistrationData,
          'cardNumber': this.cardForm.cardNumber.replace(/\s/g, ""),
          'cardExpirationDate': this.cardForm.expirationDate.replace('/', '').trim(),
          'cardCvx': this.cardForm.cvv
        },
      };
      return from(Http.request(options));
    }
    let responseTypeStr = null;
    responseTypeStr = 'text';
    return this.http
      .post<Observable<string>>(this.investData.mpCardRegistrationURL, body, { responseType: responseTypeStr })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((e) => {
          return e;
        })
      );
  }

  invalidCardForm() {
    this.continueBtnType = 'colorFull-disabled';
    this.cdRef.detectChanges();
  }

  cardFormValidated(value) {
    this.cardForm = value;
    this.continueBtnType = 'colorFull';
    this.cdRef.detectChanges();
  }

  getBrowserInfo() {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const params = {
      language: navigator.language,
      userAgent: navigator.userAgent,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      javascriptEnabled: true,
      javaEnabled: navigator.javaEnabled(),
      colorDepth: window.screen.colorDepth,
      timeZoneOffset: offset
    }
    return params;
  }

  openInvestIframe(value) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(value.mpCardSecureModeRedirectUrl);
    this.step = 1;
    this.loading = false;
    this.cdRef.detectChanges();
  }

  charged(event) {
    this.iframeLoaded = true;
    this.cdRef.detectChanges();
  }

  checkTransaction() {
    this.step = 2;
    this.loading = true;
    this.investCardService.checkMovementStatus(this.investData.id).subscribe(resp => {
      this.btnList = [
        {
          type: 'colorFull',
          index: 2,
          title: this.translate.instant("CARD_PAGE.BTN"),
        }
      ]
      switch (resp) {
        case -1:
          if (this.timesChecked < 3) {
            this.timer();
          } else {
            this.investResult = 'not_determined';
            this.loading = false;
            this.text = `<h2>${this.translate.instant('CARD_PAGE.PUBLIC_OK')}</h2><p>${this.translate.instant('CARD_PAGE.PUBLIC_TXT')}</p>`;
            this.image = 12;
          }
          break;
        case 0:
          this.loading = false;
          this.investResult = 'ok';
          this.successInvested.emit();
          break;
        case 1:
          this.loading = false;
          this.investResult = 'ko';
          this.text = `<h2>${this.translate.instant('CARD_PAGE.ERROR')}</h2><p>${this.translate.instant('CARD_PAGE.FAILURE')}</p>`;
          this.image = 1;
          break;
        case 2:
          this.investResult = 'declined';
          this.text = `<h2>${this.translate.instant('CARD_PAGE.ERROR')}</h2><p>${this.translate.instant('CARD_PAGE.FAILURE')}</p>`;
          this.image = 1;
          this.loading = false;
          break;
      }
      this.cdRef.detectChanges();
    }, error => {
      this.investResult = 'not_determined';
      this.text = `<h2>>${this.translate.instant('CARD_PAGE.PUBLIC_OK')}</h2><p>${this.translate.instant('CARD_PAGE.PUBLIC_TXT')}</p>`;
      this.image = 12;
    });
    this.cdRef.detectChanges();
  }

  timer() {
    setTimeout(() => {
      this.timesChecked++;
      this.checkTransaction();
      this.cdRef.detectChanges();
    }, 3000);
    this.cdRef.detectChanges();
  }

  clickBtn(evt) {
    this.editInvestEvent.emit();
    this.cdRef.detectChanges();
  }
}
