<div class="card rounded-2xl relative w-full md:w-[460px] min-h-[224px] bg-white mb-32 md:mb-0">
  <div class="w-full h-full gradient-card p-24 rounded-2xl min-h-[224px] ">
    <div class="pb-12">
      <p class="font-sans text-l text-eggplant-100/[0.25]">{{'MOVEMENTS.BALANCE' | translate }}</p>
      <p class="font-sans text-eggplant-100 text-h600">{{ balance | localeNumber }} <span class="text-h300"> €</span></p>
    </div>
    <div class="pb-12" *ngIf="promotionalCode > 0">
      <p class="font-sans text-l text-eggplant-100/[0.25]">{{'MOVEMENTS.REAL_BALANCE' | translate }} </p>
      <p class="font-sans text-eggplant-100 text-h600">{{ moneyOutBalance | localeNumber }} <span class="text-h300"> €</span></p>
    </div>
    <div class="pb-12" *ngIf="promotionalCode > 0">
      <p class="font-sans text-l text-eggplant-100/[0.25]">{{'MOVEMENTS.PROMOTIONAL_MONEY' | translate }} </p>
      <p class="font-sans text-eggplant-100 text-h600">{{ promotionalCode | localeNumber }} <span class="text-h300"> €</span></p>
    </div>
    <img class="isotipo-card" src="../../../../assets/images/new_branding/isotipo-card.svg" alt="">
  </div>
</div>