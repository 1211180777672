import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-register-person-last-step-checks',
  templateUrl: './register-person-last-step-checks.component.html',
  styleUrls: ['./register-person-last-step-checks.component.scss']
})
export class RegisterPersonLastStepChecksComponent implements OnInit {

  @Output() public checkListEvent = new EventEmitter<any>();
  firstCheck: boolean;
  secondCheck: boolean;
  thirdCheck: boolean;

  constructor() { }

  ngOnInit() {
    this.firstCheck = false;
    this.secondCheck = false;
    this.thirdCheck = false;
  }
  onChangeAcceptRisks(value) {
    switch (value) {
      case 1:
        this.firstCheck = !this.firstCheck;
        break;
      case 2:
        this.secondCheck = !this.secondCheck;
        break;
      case 3:
        this.thirdCheck = !this.thirdCheck;
        break;
    }
    const checkList = {
      first: this.firstCheck,
      second: this.secondCheck,
      third: this.thirdCheck
    };
    this.checkListEvent.emit(checkList);
  }

}
