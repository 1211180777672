import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MultipleValidator(val: string): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {
      if (val === '') {
          return null;
      } else {
        const valArray = val.split(',');
        for(let i = 0; i < valArray.length; i++) {
            if ((Number(control.value) % Number(valArray[i]) === 0)) {
                return null;
            }
        }
        return { code: true };
      }
  }
}
