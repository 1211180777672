<div  class="z-10 appear">
  <div class="flex justify-between">
    <h2 *ngIf="title" class="font-sans text-eggplant-100 text-h500 md:text-h600 pl-8 appear">{{ title }}</h2>
    <div *ngIf="projectList.length > 3" class="btn-action-container hidden md:block">
      <div class="btn-container pt-0 relative w-84">
        <div class="arrow arrow-left w-32 h-32" (click)="prev()"
          [ngClass]="{'arrow-disabled': index === 0 }">
          <img class="-rotate-90" src="../../../../assets/icons/arrow-up-white.svg" alt="arrow">
        </div>
        <div class="arrow arrow-right w-32 h-32" (click)="next()"
          [ngClass]="{'arrow-disabled': isEnd }">
          <img class="rotate-90" src="../../../../assets/icons/arrow-up-white.svg" alt="arrow">
        </div>
      </div>
    </div>
  </div>

  <p *ngIf="subtitle" class="font-sans text-l text-eggplant-70 pt-16 pl-8 appear">{{ subtitle }}</p>
  <div class="appear pt-24">
    <div *ngIf="featured">
      <fun-new-card [isFlexibleCompensation]="isFlexibleCompensation" [project]="projectList[0]" [size]="'l'" [featured]="featured" [cardType]="cardType" (viewProjectEvent)="openNewProject(projectList[0])"></fun-new-card>
    </div>
    <ion-slides *ngIf="!featured" [options]="slideOpts" [pager]="hasPager" #currentSlider (ionSlideDidChange)="getCurrentItem()">
      <ion-slide *ngFor="let project of projectList">
        <fun-new-card [isFlexibleCompensation]="isFlexibleCompensation" [project]="project" [size]="'l'" [featured]="featured" [cardType]="cardType" (viewProjectEvent)="openNewProject(project)"></fun-new-card>
      </ion-slide>
  </ion-slides>
  </div>
</div>