import {
  Component,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MovementsService } from 'src/app/services/movements.service';
import { Storage } from '@ionic/storage';
import { ProfileService } from 'src/app/services/profile.service';
import { ActionSheetService } from '../../action-sheet/action-sheet.service';
import { AlertService } from '../../alert/alert.service';
import { ToastService } from '../../toast/toast.service';
import { IncomeInfoComponent } from '../../income-info/income-info.component';
import { WarningComponent } from '../../warning/warning.component';

@Component({
  selector: 'app-money-out',
  templateUrl: './money-out.component.html',
  styleUrls: ['./money-out.component.scss'],
})
export class MoneyOutComponent implements OnInit, AfterViewInit {
  @Input() public available;
  @Input() public walletId;
  @Input() public username;
  @Output() public moneyOut = new EventEmitter<any>();
  @Output() public goToProjects = new EventEmitter<any>();
  @Output() public closeIt = new EventEmitter<any>();

  public cashForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public moneyOutInfo;
  public skeletonActive = false;
  public sendXHR;

  step = 0;
  loading = true;
  disableInputs: boolean;
  bankNumber: any;
  ibanNumber: any;

  text = this.translate.instant('MOVEMENTS.NO_MONEY_OUT');

  constructor(
    public actionSheetService: ActionSheetService,
    public alertService: AlertService,
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    public translate: TranslateService,
    private movementsService: MovementsService,
    private storage: Storage,
    private profileService: ProfileService
  ) {
    this.generateFormGroup();
   }

   ngOnInit() {
    registerLocaleData(es);
    this.sendXHR = 'primaryDark-disabled';
    this.getInfo();
    this.chdRef.detectChanges();
  }
  ngAfterViewInit(): void {
    this.chdRef.detectChanges();
  }
  getInfo() {
    this.profileService.getProfile(this.username).subscribe(prof => {
      if (prof.mangopayFundsMigrationStatus !== 'IN_PROCESS') {
        this.movementsService.getAccount().subscribe(resp => {
          this.ibanNumber = resp.account.iban;
          this.bankNumber = this.parseIban(resp.account.iban);
          if (resp.account.status === '5') {
            this.disableInputs = false;
          } else {
            this.disableInputs = true;
            this.toastService.showToast(this.translate.instant('MOVEMENTS.MONEY-OUT.CHECKING'), 'check');
          }
          this.loading = false;
          this.chdRef.detectChanges();
        }, error => {
          this.changeCount();
          this.chdRef.detectChanges();
        });
        this.chdRef.detectChanges();
      } else {
        this.step = 2;
        this.loading = false;
        this.chdRef.detectChanges();
      }
    });
    this.chdRef.detectChanges();
  }
  generateFormGroup() {
    this.cashForm = new FormGroup({
      money: new FormControl('', [
        Validators.required,
        Validators.pattern('^(?!0,?\d)[0-9]+(\.[0-9]{1,2})?$'),
        Validators.min(1),
        Validators.max(this.available)
      ]),
      concept: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    this.formControlKeys = Object.keys(this.cashForm.controls);
    this.errorMessages = {
      min: this.translate.instant('input-errors-messages.incorrect-amount'),
      pattern: this.translate.instant('input-errors-messages.incorrect-amount'),
      required: this.translate.instant('input-errors-messages.required'),
      minlength: this.translate.instant('input-errors-messages.minlength'),
      max: this.translate.instant('MOVEMENTS.MONEY-OUT.NO-MORE-AVAILABLE')
    };
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
    if (this.cashForm.value.money > 0 && this.cashForm.value.concept.length > 0) {
      this.sendXHR = 'primaryDark';
      this.chdRef.detectChanges();
    } else {
      this.sendXHR = 'primaryDark-disabled';
      this.chdRef.detectChanges();
    }
    if (this.cashForm.value.money <= this.available) {
      this.errorAmount = false;
      this.chdRef.detectChanges();
    }
  }

  outMoney() {
    this.isSubmit = true;
    if (this.cashForm.valid) {
      if (this.cashForm.value.money <= this.available) {
        this.errorAmount = false;
        this.sendXHR = 'primaryDark-disabled';
        this.chdRef.detectChanges();
        this.moneyOutInfo = this.cashForm.value;
        const concept = this.moneyOutInfo.concept;
        const money = this.moneyOutInfo.money;
        const model = {
          amount: money,
          iban: this.ibanNumber,
          wallet: this.walletId,
          concept: concept,
        };
        this.loading = true;
        this.movementsService.getWithdrawFunds(model).subscribe((x) => {
          this.isSuccess();
          this.moneyOut.emit();
        }, error => {
          this.loading = false;
          this.toastService.showToast(this.translate.instant('MOVEMENTS.MONEY-OUT.ERROR_MONEY_OUT'), 'warning');
          this.chdRef.detectChanges();
        });
      } else {
        this.errorAmount = true;
      }
    }
    this.chdRef.detectChanges();
  }

  public isSuccess() {
    this.bankNumber = null;
    this.chdRef.detectChanges();
  }

  openInfo() {
    const modalParams = {
      page: 'cash-out'
     };

    this.actionSheetService.showModal(IncomeInfoComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('MOVEMENTS.DEPOSIT-FUNDS.INCOME-INFORMATION').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.onClose().subscribe();
      }
    );
  }

  change() {
    const modalParams = {
      place: 'input'
     };

    this.alertService.showModal(WarningComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('MOVEMENTS.DEPOSIT-FUNDS.WARNING').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.getChildComponent().subscribe((componentRef: WarningComponent) => {
          componentRef.continueClick.subscribe((res) => {
            this.changeCount();
            this.alertService.removeModal();
          });
        });
        modal.onClose().subscribe();
      }
    );
  }

  goProjects() {
    this.goToProjects.emit();
    this.chdRef.detectChanges();
  }

  closeModal() {
    this.closeIt.emit();
    this.chdRef.detectChanges();
  }

  parseIban(iban) {
    const ibanParse =
      iban.charAt(0) +
      iban.charAt(1) +
      iban.charAt(2) +
      iban.charAt(3) +
      ' **** ' +
      iban.charAt(iban.length - 4) +
      iban.charAt(iban.length - 3) +
      iban.charAt(iban.length - 2) +
      iban.charAt(iban.length - 1);
    return ibanParse;
  }

  changeCount() {
    this.loading = true;
    this.step = 1;
    this.loading = false;
    this.chdRef.detectChanges();
  }

}
