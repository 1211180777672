<div class="container" [ngClass]="{'mobile-code-container': isMobile}" *ngIf="!isDisabled" [formGroup]='parentForm'>
    <input type="number" maxlength="6" class="defaultCode" #code1 id="code1" (blur)="validate()" (keydown)="next(code1, $event)"
    (paste)="validate(); pasteCode();" (keyup)="nextFocus(code1, $event); validate()" formControlName="code1"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
    <input type="number" maxlength="1" class="defaultCode" #code2 id="code2" (blur)="validate()" (keydown)="next(code2, $event)"
    (paste)="validate()" (keyup)="nextFocus(code2, $event); validate()" formControlName="code2"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
    <input type="number" maxlength="1" class="defaultCode" #code3 id="code3" (blur)="validate()" (keydown)="next(code3, $event)"
    (paste)="validate()" (keyup)="nextFocus(code3, $event); validate()" formControlName="code3"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
    <div class="defaultLine"></div>
    <input type="number" maxlength="1" class="defaultCode" #code4 id="code4" (blur)="validate()" (keydown)="next(code4, $event)"
    (paste)="validate()" (keyup)="nextFocus(code4, $event); validate()" formControlName="code4"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
    <input type="number" maxlength="1" class="defaultCode" #code5 id="code5" (blur)="validate()" (keydown)="next(code5, $event)"
    (paste)="validate()" (keyup)="nextFocus(code5, $event); validate()" formControlName="code5"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
    <input type="number" (keyup)="nextFocus(code6, $event);" maxlength="1" class="defaultCode" #code6 id="code6"
    formControlName="code6" (paste)="validate()" (keyup)="validate()" (blur)="validate()"
    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
</div>

<div class="container" *ngIf="isDisabled">
    <input type="text" disabled class="disabledCode">
    <input type="text" disabled class="disabledCode">
    <input type="text" disabled class="disabledCode">
    <div class="disabledLine"></div>
    <input type="text" disabled class="disabledCode">
    <input type="text" disabled class="disabledCode">
    <input type="text" disabled class="disabledCode">
</div>
