<div class="general-container register flex flex-col">
    <div *ngIf="step === 0">
        <div class="body-content">
            <div class="step">
                <div class="header">
                    <h2>{{ 'register.document.upload_document' | translate }}</h2>
                    <p>{{ 'register.document.upload_again' | translate }}</p>
                </div>
                <div class="body">
                    <fun-file-card (getFile)="getFrontalFile($event)" (errorEvent)="errorFile()" [fileType]="type" [username]="profile.username"></fun-file-card>
                </div>
            </div>
        </div>
        <div class="mobile-footer">
            <fun-new-button (eventClick)="next()" class="next-btn" [buttonType]="btnType" title="{{ 'register.btn.continue' | translate }}"></fun-new-button>
        </div>

        <div class="desktop-footer">
            <div class="content-footer">
                <fun-new-button class="back-btn" [buttonType]="'primaryDark-disabled'" title="{{ 'register.btn.back' | translate }}"></fun-new-button>
                <fun-new-button (eventClick)="next()" class="next-btn" [buttonType]="btnType" title="{{ 'register.btn.continue' | translate }}"></fun-new-button>
            </div>
        </div>
    </div>
    <div *ngIf="step === 1">
        <div class="body-content">
            <fun-skeleton-list></fun-skeleton-list>
        </div>
    </div>
</div>
