import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationsService } from 'src/app/services/notifications.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {

  @Input() public realList;
  loadingNotifications = false;

  constructor(
    public utilsService: UtilsService,
    private modalController: ModalController,
    private notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.notificationService.setNotificationListEvent.subscribe((list) => {
      if (list && list.length > 0) {
        const preList = this.notificationService.filterNotifications(list);
        const notReaded = preList.filter(item => item.status !== 'VIEWED');
        const readed = preList.filter(item => item.status === 'VIEWED');
        this.realList = []; // this.notificationService.filterNotifications(list);
        for (let i = 0; i < notReaded.length; i++) {
          if (i < 4) {
            this.realList.push(notReaded[i]);
          }
        }
        if (this.realList.length < 4) {
          for (let i = 0; i < readed.length; i++) {
            if (this.realList.length < 4) {
              this.realList.push(readed[i]);
            }
          }
        }
      } else {
        this.realList = [];
      }
      this.loadingNotifications = false;
    });
  }

  readNotification(notification) {
    this.notificationService.readNotification(notification.id, notification.category, notification.newNotification);
    const route = this.utilsService.getNotificationRoute(notification.type);
    this.navigateTo(route);
    this.loadingNotifications = true;
  }


  navigateTo(path) {
    this.utilsService.navigateTo('/' + path);
    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  deleteNotification(notification) {
    this.notificationService.deleteSpecificNotification(notification.id, notification.category, notification.newNotification);
    this.loadingNotifications = true;
  }

  deleteAllNotifications() {
    this.notificationService.deleteNotifications();
    this.loadingNotifications = true;
  }

}
