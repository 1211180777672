import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-mangopay-terms',
  templateUrl: './register-mangopay-terms.component.html',
  styleUrls: ['./register-mangopay-terms.component.scss'],
})
export class RegisterMangopayTermsComponent implements OnInit {

  @Output() public checkActionEvent = new EventEmitter<any>();

  checked = false;

  constructor(private chdRef: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {
    this.chdRef.detectChanges();
  }

  checkaction() {
    this.checked = !this.checked;
    this.checkActionEvent.emit(this.checked);
    this.chdRef.detectChanges();
  }

  openMangopayTerms() {
    if (this.translate.currentLang === 'en') {
      window.open('https://www.mangopay.com/terms/MANGOPAY_Terms-EN.pdf');
    } else {
      window.open('https://www.mangopay.com/terms/MANGOPAY_Terms-ES.pdf')
    }
    // window.open('https://www.mangopay.com/es/update-terms/');
    this.chdRef.detectChanges();
  }

}
