import { Component, OnInit, Input } from '@angular/core';
import { Storage } from '@ionic/storage';
import { SidemenuService } from '../../sidemenu/sidemenu.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  name;
  @Input('radius') radius;

  avatarLetters: string;
  color: any;
  colorList = ['linear-gradient-1', 'linear-gradient-2', 'linear-gradient-3', 'linear-gradient-4', 'linear-gradient-5', 'linear-gradient-6', 'linear-gradient-7'];
  public hasDot: boolean;

  constructor(
    private storage: Storage,
    private sideMenuService: SidemenuService,
    ) {}

  ngOnInit() {
    this.hasDot = false;
    this.getUsername();
    this.getColor();
  }

  async getUsername() {
    this.name = await this.sideMenuService.getUserName();
    if (this.name && this.name !== undefined && this.name !== null && this.name !== '') {
      if (this.name.includes(' ')) {
        const space = this.name.indexOf(' ');
        this.avatarLetters = this.name.charAt(0) + this.name.charAt(space + 1);
      } else {
        this.avatarLetters = this.name.charAt(0);
      }
    }
  }

  async getColor() {
    this.color = await this.sideMenuService.getAvatarColor();
  }

  public setDot() {
    this.hasDot = true;
  }

  public removeDot() {
    this.hasDot = false;
  }

  changeProfileAvatar() {
    //console.log('Change profile avatar')
    // const model = {
    //   profileImage: ''
    // }
    // this.profileService.updateProfile(model).subscribe(res => {});
  }
}
