import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as momentNs from 'moment';
import { IonSlides } from '@ionic/angular';
import { ToastService } from '../toast/toast.service';
const moment = momentNs;

@Component({
  selector: 'app-update-list',
  templateUrl: './update-list.component.html',
  styleUrls: ['./update-list.component.scss'],
})
export class UpdateListComponent implements OnInit {

  @Input() updates: any;
  @Input() isInvestor: any;
  @Input() isPortfolio: any;
  @Input() isDetail: any;
  @Output() public eventEmitUuid = new EventEmitter<any>();
  @ViewChild('sliderImg')  sliderImg: IonSlides;
  isMoreOpen = [];
  longLength = [];
  hasInfo = true;
  hasCurrentLang: boolean;
  goalReserched: any;
  public downloadingDoc = [];
  public okElement = [];
  idDoc: number;
  hasPrivateUpdate = false;

  slideOpts = {
    initialSlide: 0,
    speed: 1000,
    effect: 'slide',
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    slideToClickedSlide: true,
    freeMode: false
  };
  sliderIndex: number;
  updateList = [];
  viewdAll: boolean;

  constructor(
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.viewdAll = true;
    this.sliderIndex = 0;
    registerLocaleData(es);
    this.goalReserched = this.getFinishInvest();
    this.getUpdates();
    this.cdRef.detectChanges();
  }

  ngOnChanges() {
    this.getUpdates();
    this.cdRef.detectChanges();
  }

  getUpdates() {
    let i = 0;
    this.idDoc = 0;
    this.updates.forEach(update => {
      update = this.getUpdateText(update);
      update.frontId = i;
      this.isMoreOpen.push(false);
      if (!update.public) {
        //this.hasPrivateUpdate = true;
      }
      if (update.youtubeUrl && update.youtubeUrl !== undefined) {
        update.youtubeUrlS = this.sanitizer.bypassSecurityTrustResourceUrl(update.youtubeUrl);
      }
      if (update.files && update.files !== undefined) {
        update['imgList'] = update.files.filter((item) => item.tipo === 1);
        update.hasFiles = true;
        update.files.forEach(file => {
          if (file.uuid && file.tipo === 2) {
            file.id = this.idDoc;
            this.idDoc++;
            this.downloadingDoc.push(false);
            this.okElement.push(false);
          }
        });
      } else {
        update.hasFiles = false;
      }
      let info;
      const lng = this.translate.currentLang;
      if (update.locales  !== undefined) {
        update.locales.forEach(element => {
          if (element.language === lng) {
            info = element;
          }
        });
      }
      if (info && info !== undefined) {
        this.hasCurrentLang = true;
        update.info = info;
      } else if (update.locales && update.locales[0]) {
        update.info = update.locales[0];
      } else {
        update.info = '';
      }
      if (update.date) {
        update.fullDate = this.getStartDate(update.date);
      } else {
        update.fullDate = '';
      }
      if (update.type) {
        update.typeName = this.getType(update.category, update.HasDocuments);
      } else {
        update.typeName = undefined;
      }
      i++;
    });
    if (this.hasPrivateUpdate) {
      if (!this.isInvestor) {
        const toastText = this.translate.instant('UPDATES.PRIVACY');
        this.toastService.showToast(toastText, 'info', true);
      }
    }
    if (this.updates.length > 5) {
      this.updateList = this.updates.slice(0, 5);
      this.viewdAll = false;
    } else {
      this.updateList = this.updates;
    }
    this.cdRef.detectChanges();
  }

  viewAll() {
    this.viewdAll = true;
    this.updateList = this.updates;
    this.cdRef.detectChanges();
  }

  getUpdateText(update) {
    // const newProfile: Profile = Object.assign({}, this.profile);
    if (update.category === 1) {
      if (update.type === 2) {
        if (update.locales !== undefined) {
          if (this.translate.currentLang === 'es') {
            update.locales[0].title = update.locales[0].title;
          } else {
            update.locales[0].title = 'Opening financing';
          }
        }
      }
      if (update.type === 3) {
        if (update.locales !== undefined) {
          if (this.translate.currentLang === 'es') {
            update.locales[0].title = update.locales[0].title;
          } else {
            update.locales[0].title = 'Financing achieved';
          }
        }
      }
      if (update.type === 4) {
        if (update.info !== undefined) {
          if (this.translate.currentLang === 'es') {
            update.info.title = update.info.title;
          } else {
            update.info.title = 'Dividend distribution';
          }
        }
        if (update.locales !== undefined) {
          if (this.translate.currentLang === 'es') {
            update.locales[0].title = update.locales[0].title;
          } else {
            update.locales[0].title = 'Dividend distribution';
          }
        }
      }
    }

    if (update.category === 4) {
      if (update.info !== undefined) {
        if (this.translate.currentLang === 'es') {
          update.info.title = update.info.title;
        } else {
          update.info.title = 'Dividend distribution';
        }
      }
      if (update.locales !== undefined) {
        if (this.translate.currentLang === 'es') {
          update.locales[0].title = update.locales[0].title;
        } else {
          update.locales[0].title = 'Dividend distribution';
        }
      }
    }
    return update;
  }

  getFinishInvest() {
    const start = this.updates.filter(item => item.type === 2);
    const end = this.updates.filter(item => item.type === 3);
    if (start.length > 0 && end.length > 0) {
      let startDateN;
      let endDateN;
      startDateN = new Date(this.convertToIos(moment(start[0].date).format('YYYY-MM-DD HH:mm:ss'))).getTime();
      endDateN = new Date(this.convertToIos(moment(end[0].date).format('YYYY-MM-DD HH:mm:ss'))).getTime();
      if (startDateN === isNaN || startDateN === undefined || !startDateN) {
        // method for firefox
        startDateN = new Date(this.convertToIos(moment(start[0].date.replace('/', '-')
        .replace('/', '-')).format('YYYY-MM-DD HH:mm:ss'))).getTime();
      }
      if (endDateN === isNaN || endDateN === undefined || !endDateN) {
        endDateN = new Date(this.convertToIos(moment(end[0].date.replace('/', '-')
        .replace('/', '-')).format('YYYY-MM-DD HH:mm:ss'))).getTime();
      }
      const hoursOpen = Math.floor((endDateN - startDateN) / (1000 * 60 * 60));
      const daysOpen = Math.floor((endDateN - startDateN) / (1000 * 60 * 60 * 24));
      if (daysOpen >= 1) {
        if (daysOpen === 1) {
          return this.translate.instant('UPDATES.FINANCED_IN_A_DAY');
        } else {
          return this.translate.instant('OVERVIEW.FINANCED_IN_DAYS', {days: daysOpen});
        }
      } else {
        if (hoursOpen < 1) {
          return this.translate.instant('overview.status.less-than-hour');
        } else {
          if (hoursOpen === 1) {
            return this.translate.instant('overview.status.one-hour');
          } else {
            return this.translate.instant('OVERVIEW.FINANCED_IN', {hour: hoursOpen});
          }
        }
      }
    } else {
      return undefined;
    }
  }

  getStartDate(date) {
    let day;
    day = parseInt(moment(date).format('DD'));
    let monthNumber;
    monthNumber = parseInt(moment(date).format('MM'));
    if (day === isNaN || day === undefined || !day) {
      day = parseInt(moment(date.replace('/', '-').replace('/', '-')).format('DD'));
    }
    if (monthNumber === isNaN || monthNumber === undefined || !monthNumber) {
      monthNumber = parseInt(moment(date.replace('/', '-').replace('/', '-')).format('MM'));
    }
    let months;
    if (this.translate.currentLang === 'en') {
      months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
      ];
    } else {
      months = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
      ];
    }
    const month = months[monthNumber - 1];
    let year;
    year = moment(date).format('YYYY');
    let tst;
    tst = parseInt(moment(date).format('YYYY'));
    if (tst === isNaN || tst === undefined || !tst) {
      year = moment(date.replace('/', '-').replace('/', '-')).format('YYYY');
    }
    if (this.translate.currentLang === 'en') {
      return month + ' ' + day + 'th, ' + year;
    } else {
      return day + ' de ' + month + ', ' + year;
    }
  }

  getInfoTextLan(locale) {
    return locale.find(o => o.language === this.translate.currentLang);
  }

  getType(type, hasDocs) {
    let name: string;
    if (hasDocs && hasDocs !== undefined) {
      if (this.translate.currentLang === 'es') {
        name = 'Documentos';
      } else {
        name = 'Documents';
      }
    } else {
      switch (type) {
        case 1:
          if (this.translate.currentLang === 'es') {
            name = 'Financiación';
          } else {
            name = 'Financing';
          }
          break;
        case 2:
          if (this.translate.currentLang === 'es') {
            name = 'Construcción';
          } else {
            name = 'Construction';
          }
          break;
        case 3:
          if (this.translate.currentLang === 'es') {
            name = 'Operaciones';
          } else {
            name = 'Operations';
          }
          break;
        case 4:
          if (this.translate.currentLang === 'es') {
            name = 'Beneficios';
          } else {
            name = 'Benefits';
          }
          break;
        case 5:
          if (this.translate.currentLang === 'es') {
            name = 'Otros';
          } else {
            name = 'Others';
          }
          break;
        case 0:
          if (this.translate.currentLang === 'es') {
            name = 'Otros';
          } else {
            name = 'Others';
          }
          break;
      }
    }
    return name;
  }

  viewMore(i) {
    this.isMoreOpen[i] = !this.isMoreOpen[i];
  }

  openDoc(url, fichero) {
    const file = new Blob([url], {type: 'application/pdf'});
    const link = window.URL.createObjectURL(file);
    window.open(link);
  }

  downLoadDoc(url, name) {
    const downloadLink = document.createElement('a');
    const fileName = name + '.pdf';
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] -1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  clickDock(uuid, name, updateId, projectId) {
    if (this.downloadingDoc[updateId] === false) {
      this.downloadingDoc[updateId] = true;
      const data = {id: uuid, fileName: name, idUp: updateId, idProject: projectId};
      this.eventEmitUuid.emit(data);
    }
  }

  public downloadedDoc(id) {
    this.okElement[id] = true;
    setTimeout(() => {
      this.downloadingDoc[id] = false;
      this.okElement[id] = false;
      this.cdRef.detectChanges();
    }, 5500);
    this.cdRef.detectChanges();
  }

  next() {
    this.sliderImg.slideNext();
  }

  prev() {
    this.sliderImg.slidePrev();
  }

  getCurrentItem() {
    this.sliderImg.getActiveIndex().then(val => {
      this.sliderIndex = val;
    });
  }

}
