import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Storage } from '@ionic/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Regions } from '../../other/regions';
import * as momentNs from 'moment-timezone';
import { FileCardComponent } from '../file-card/file-card.component';
import { ToastService } from '../toast/toast.service';
import { AlertService } from '../alert/alert.service';
import { OveralMessageComponent } from '../overal-message/overal-message.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { HubspotService } from 'src/app/services/hubspot.service';
const moment = momentNs;

@Component({
  selector: 'app-affiliation-local-invest',
  templateUrl: './affiliation-local-invest.component.html',
  styleUrls: ['./affiliation-local-invest.component.scss'],
})
export class AffiliationLocalInvestComponent implements OnInit {

  @ViewChild('fileCard', { static: false }) fileCard: FileCardComponent;
  @ViewChild('dniBackFile', { static: false }) dniBackCard: FileCardComponent;
  @Output() public closeModalEvent = new EventEmitter<any>();
  @Output() public reloadPageEvent = new EventEmitter<any>();
  @Output() public updateDealEvent = new EventEmitter<any>();
  @Input() public postalCodeInvestmentStages;
  @Input() public investmentUnlock;
  @Input() public canInvest;
  @Input() public projectName;
  @Input() public status;

  public addressForm: FormGroup;
  public documentForm: FormGroup;
  loading = true;

  step = 0;
  profile: any;
  info: any;
  towns: any;
  hasProfile = false;
  hasListOfTowns = false;
  formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorDocument = false;
  public timeRemaining;
  phaseP;

  text = this.translate.instant('LOCAL_INNVEST.DYNAMIC.SUCCESS_TXT')
  btnList = [{ type: 'primaryDark', index: 1,  title: this.translate.instant('LOCAL_INNVEST.DYNAMIC.FINISH')}];

  documentList = [
    { index: 1, name: this.translate.instant('LOCAL_INNVEST.DYNAMIC.ID_CARD'), id: 1 },
    { index: 2, name: this.translate.instant('LOCAL_INNVEST.DYNAMIC.CENSUS_R'), id: 2 },
    { index: 3, name: this.translate.instant('LOCAL_INNVEST.DYNAMIC.TAX_R'), id: 3 }
  ];
  address: string;
  hasAddress: boolean;
  hasCity = false;
  userCity: string;

  sameAddressChecked = true;
  termsChecked = false;
  btnType: string;

  newAddress: any;
  newAddressError = true;
  fileName = 'identityDocumentFront';
  fileMain: any;
  fileMainId: string;
  fileValue: number;
  dniBackFile: any;
  dniFileId: any;

  valueF = 1;
  hasDniFile = false;
  //phase: string;

  constructor(
    private translate: TranslateService,
    public chdRef: ChangeDetectorRef,
    public profileService: ProfileService,
    private storage: Storage,
    private toastService: ToastService,
    private alertService: AlertService,
    private amplitudeService: AmplitudeService,
    private HubspotService: HubspotService,
    ) { }

  ngOnInit() {
    this.sortByDate(this.postalCodeInvestmentStages);
    this.controlBtnType();
    this.phaseP = this.getCurrentStep();
    const today =  moment( moment(new Date()).format('YYYY/MM/DD  HH:mm:ss'), 'YYYY/MM/DD').tz('Europe/Madrid');
    const endDate = moment(this.investmentUnlock, 'YYYY/MM/DD  HH:mm:ss').tz('Europe/Madrid');
    this.timeRemaining = endDate.diff(today, 'days');
    //this.phase = this.timeRemaining === 1 ? 'municipal' : this.timeRemaining === 2 ? 'local' :  this.timeRemaining === 3 ? 'autonómica' : 'pública';
    this.storage.get('USERNAME').then((val) => {
      this.profileService.getProfile(val).subscribe(prof => {
        this.loading = false;
        this.getFullAddress(prof);
        this.profile = prof;
        this.hasProfile = true;
        this.hasDniFile = prof.profileType === 1 && prof.identityDocumentFront !== null && prof.identityDocumentFront !== undefined ? true : false;
        if (this.profile.profileType === 2) {
          this.fileName = 'census';
        }
        this.getListOfTowns();
        this.chdRef.detectChanges();
      }, error => { });
    });
    this.documentForm = new FormGroup({
      document: new FormControl('', [Validators.required])
    });
    this.amplitudeService.trackEvent('reservation_modal_opened', {});
    this.chdRef.detectChanges();
  }

  prev() {
    this.valueF = undefined;
    if (this.step > 0) {
      if (this.sameAddressChecked && this.step === 3) {
        this.step = 1;
      } else {
        this.step--;
      }
      if (this.step === 1) {
        this.valueF = 1;
        this.fileName = 'identityDocumentFront';
        this.documentForm.patchValue({ document: 1 });
      }
    } else {
      this.closeModalEvent.emit();
    }
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  next() {
    this.step++;
    if (this.step === 2 && this.hasAddress) {
      if (this.sameAddressChecked) {
        this.step = 3;
      } else {
        this.profile.taxCountry = 108;
        this.profile.taxAddress = null;
        this.profile.taxCity = null;
        this.profile.taxPostalCode = null;
      }
    }
    if (this.step === 3 && !this.sameAddressChecked) {
      this.loading = true;
      const msg = this.translate.instant('LOCAL_INNVEST.DYNAMIC.ADDRESS_ERROR_UPDATE');
      let model;
      if (this.profile.profileType === 1) {
        const cityCode = this.newAddress.city < 10 ? '0' + this.newAddress.city : this.newAddress.city;
        model = {
          taxAddress: this.newAddress.address,
          taxCity: cityCode,
          taxRegion: this.newAddress.region,
          taxPostalCode: this.newAddress.cp
        }
      } else {
        model = this.newAddress;
      }
      this.profileService.updateProfile(model).subscribe((response) => {
        this.storage.set('PROFILE', response);
        this.loading = false;
        this.chdRef.detectChanges();
      }, error => {
        this.loading = false;
        this.step--;
        this.toastService.showToast(msg, 'warning', true);
        this.chdRef.detectChanges();
      });
    }
    if (this.step === 4) {
      this.loading = true;
      const hasFileBack = this.dniBackFile !== undefined ? true : false;
      this.profileService.verifyPostalCode().subscribe(() => {
        this.reloadPageEvent.emit();
        this.amplitudeService.trackEvent('reservation_address_valid_proccess', {});

        // Actualizamos la propiedad del status de código postal de Husbpot
        this.storage.get('HS_CONTACT_ID').then((idhbp) => {
          if(idhbp !== '') {
            const updateParams = {
              "cp_validation_status": "Solicitado"
            };
            this.HubspotService.updateContact(idhbp, updateParams).subscribe((resHusbpot: any) => {
              if(resHusbpot.success) {
                // console.log('Contacto actualizado!!', resHusbpot.data);
              }
            });
          }
        });
        this.updateDealEvent.emit('validation');
        this.chdRef.detectChanges();
      });
      //
      if ((this.fileName === 'identityDocumentFront' && !this.hasDniFile) || (this.hasDniFile && this.fileName !== 'identityDocumentFront')) {
      this.profileService.uploadFile(this.fileMain).subscribe((resp1) => {
        if (hasFileBack) {
          this.profileService.uploadFile(this.dniBackFile).subscribe((resp2) => {
            if (this.fileName === 'identityDocumentFront') {
              const model = {
                identityDocumentFront: resp1.fileID,
                identityDocumentBack: resp2.fileID
              }
              this.profileService.updateProfile(model).subscribe(() => {
                this.documentUploaded();
                this.chdRef.detectChanges();
              }, error => {
                this.documentUploaded();
                this.step--;
                this.toastService.showToast(this.translate.instant('LOCAL_INNVEST.DYNAMIC.FILE_ERROR'), 'warning', true);
                this.chdRef.detectChanges();
              });
            } else {
              this.documentUploaded();
            }
            this.chdRef.detectChanges();
          }, error => {
            this.documentUploaded();
            this.step--;
            const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : this.translate.instant('LOCAL_INNVEST.DYNAMIC.FILE_ERROR');
            this.toastService.showToast(specificMsg, 'warning', true);
            this.chdRef.detectChanges();
          });
        } else {
          if (this.fileName === 'identityDocumentFront') {
            const model = {
              identityDocumentFront: resp1.fileID
            }
            this.profileService.updateProfile(model).subscribe(() => {
              this.documentUploaded();
              this.chdRef.detectChanges();
            }, error => {
              this.documentUploaded();
              this.step--;
              this.toastService.showToast(this.translate.instant('LOCAL_INNVEST.DYNAMIC.FILE_ERROR'), 'warning', true);
              this.chdRef.detectChanges();
            });
          } else {
            this.documentUploaded();
          }
        }
        this.chdRef.detectChanges();
      }, error => {
        this.documentUploaded();
        this.step--;
        const specificMsg = error.error.message !== null && error.error.message !== undefined ? error.error.message : this.translate.instant('LOCAL_INNVEST.DYNAMIC.FILE_ERROR');
        this.toastService.showToast(specificMsg, 'warning', true);
        this.chdRef.detectChanges();
      });
      } else {
        this.documentUploaded();
      }
    }

    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  seeValidCode() {

    const stepsByOrder = this.sortByDate(this.postalCodeInvestmentStages);
    const msg1 = this.translate.instant('LOCAL_INNVEST.DYNAMIC.VALID_CODE_TXT_1');
    const phase1 = this.translate.instant('LOCAL_INNVEST.STEP_0.PHASE_1');
    const phase2 = this.translate.instant('LOCAL_INNVEST.STEP_0.PHASE_2');
    let phase3;
    if (this.postalCodeInvestmentStages.length > 2) {
      phase3 = this.translate.instant('LOCAL_INNVEST.STEP_0.PHASE_3');
    } else {
      phase3 = '';
    }
    const community = ''; //this.translate.instant('LOCAL_INNVEST.DYNAMIC.COMUNITY');
    const modalParams = {
      text: `${msg1} <ul><li><span>${phase1} </span>${stepsByOrder[0].postal_codes.replace(/([,])/g, ', ').trim()}</li><li><span>${phase2} </span>${stepsByOrder[1].postal_codes.replace(/([,])/g, ', ').trim()}</li><li><span>${phase3} </span>${community}</li></ul>`,
      removePadding: true,
      btnList: [
        {
          type: 'primaryDark',
          index: 1,
          title: this.translate.instant('LOCAL_INNVEST.DYNAMIC.VALIDATE'),
        }
      ],
    };

    this.alertService.showModal(OveralMessageComponent, modalParams,'', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
        componentRef.btnEvent.subscribe((resp) => {
          this.next();
          this.alertService.removeModal();
        });
      });
      modal.activeCross().subscribe();
      modal.onClose().subscribe();
    });
    this.chdRef.detectChanges();
  }

  getAddressInfo(value) {
    this.newAddressError = false;
    this.newAddress = value;
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  getAddressError(value) {
    this.newAddressError = true;
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  getListOfTowns() {
    const region = this.profile.taxRegion !== null ? this.profile.taxRegion : 2;
    this.profileService.getCities(108, region).subscribe(resp => {
      let city;
      let prov: string;
      if (this.profile.profileType === 1) {
        this.towns = {list: resp, call: this.profileService};
      } else {
        this.towns = this.profileService;
      }

      this.hasListOfTowns = true;

      if (this.profile.taxRegion !== null) {
        Regions.map(region => {
          if (Number(region.code) === this.profile.taxRegion) {
            prov = region.name;
            resp.map(cy => {
              if (Number(cy.id) === this.profile.taxCity) {
                city = cy.name;
                this.userCity = `${city}, ${prov} `;
                this.hasCity = true;
                this.chdRef.detectChanges();
              }
            });
          }
        });
      }
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  changeDocument() {
    this.valueF =  this.documentForm.controls.document.value;
    if (this.valueF === 1) {
      this.fileName = 'identityDocumentFront';
    } else {
      this.fileName = 'census';
    }
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  errorFile1(error) {
    this.fileMain = undefined;
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  errorDniBack(error) {
    this.dniBackFile = undefined;
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  getFile1(file) {
    this.fileMain = file;
    this.fileMainId = this.fileMain.field;
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  getDniBack(file) {
    this.dniBackFile = file;
    this.dniFileId = this.dniBackFile.fileId;
    this.chdRef.detectChanges();
  }

  finish() {
    this.closeModalEvent.emit();
    this.chdRef.detectChanges();
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      return Number(new Date(a.start_date)) - Number(new Date(b.start_date));
    });
    return arr;
  }

  getFullAddress(prof) {
    if (prof.taxAddress !== null && prof.taxAddress !== '') {
       this.hasAddress = true;
       this.address = `${prof.taxAddress}, ${prof.taxPostalCode} `
    } else {
      this.sameAddressChecked = false;
      this.hasAddress = false;
    }
    this.chdRef.detectChanges();
  }

  checkAction(type) {
    if (type === 'sameAddress') {
      this.sameAddressChecked = !this.sameAddressChecked;
    }
    if (type === 'terms') {
      this.termsChecked = !this.termsChecked;
    }
    this.controlBtnType();
    this.chdRef.detectChanges();
  }

  controlBtnType() {
    switch (this.step) {
      case 0:
        this.btnType = 'primaryDark';
        break;
      case 1:
        this.btnType = 'primaryDark';
        break;
      case 2:
        if (this.newAddressError) {
          this.btnType = 'primaryDark-disabled';
        } else {
          if (this.profile.profileType === 2) {
            if (this.newAddress.taxCity === null || this.newAddress.taxCity === '' || this.newAddress.taxCity === undefined) {
              this.btnType = 'primaryDark-disabled';
            } else {
              this.btnType = 'primaryDark';
            }
          } else {
            this.btnType = 'primaryDark';
          }
        }
        break;
      case 3:
        if ( this.termsChecked &&
          ((this.profile.profileType === 1 &&
            ((this.fileMain !== undefined && this.fileName !== 'identityDocumentFront') || (this.fileName === 'identityDocumentFront' && this.hasDniFile)) &&
            this.fileName !== undefined)
          || this.profile.profileType === 2 && this.fileMain !== undefined)) {
          this.btnType = 'primaryDark';
        } else {
          this.btnType = 'primaryDark-disabled';
        }
        break;
    }
    this.chdRef.detectChanges();
  }

  getCurrentStep() {
    const todayParse = new Date(this.convertToIos(moment().tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
    const today = moment(todayParse);
    const arr = this.sortByDate(this.postalCodeInvestmentStages);
    let j = 0;
    if (this.status === 3) {
      return 0;
    }
    arr.forEach(element => {
      j++;
      element.index = j;
    });

    for (let i = 0; i < arr.length; i++) {
      // const starDateParse = new Date(this.convertToIos(moment(arr[i].start_date).tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      let starDateParse = new Date(this.convertToIos(moment(arr[i].start_date).tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        starDateParse = new Date(this.convertToIos(moment(arr[i].start_date.replace('/', '-').replace('/', '-'))
        .tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      }
      const starDate = moment(starDateParse);
      const timeRemaining =  starDate.valueOf() - today.valueOf(); //starDate.diff(this.today, 'seconds');

      if (timeRemaining <= 0 && arr.length > i + 1) {
        //const starDateNext = moment(arr[i + 1].start_date, 'YYYY/MM/DD HH:mm:ss').tz('Europe/Madrid');
        let starDateNext = moment(arr[i + 1].start_date, 'YYYY/MM/DD HH:mm:ss').tz('Europe/Madrid');
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          starDateNext = moment(arr[i + 1].start_date.replace('/', '-').replace('/', '-'), 'YYYY/MM/DD HH:mm:ss').tz('Europe/Madrid');
        }
        const timeRemainingNext = starDateNext.valueOf() - today.valueOf(); // starDateNext.diff(this.today, 'seconds');
        if (timeRemainingNext > (- 60 * 1000 * 60)) {
          return i + 1;
        }
      } else if (arr.length === i + 1) {
        if (timeRemaining <= 0) {
          return i + 1;
        }
      }
      if (this.status === 3) {
        return 0;
      }
    }
    this.chdRef.detectChanges();
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  documentUploaded() {
    this.loading = false;
    this.dniBackFile = undefined;
    this.fileMain = undefined;
    this.chdRef.detectChanges();
  }

}
