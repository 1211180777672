<div class="rounded-2xl w-56 h-56 flex items-center justify-center" >
  <svg *ngIf="icon === 'house'" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path *ngIf="selected" d="M25 38L25 28H31V38M19 25L28 18L37 25V36C37 36.5304 36.7893 37.0391 36.4142 37.4142C36.0391 37.7893 35.5304 38 35 38H21C20.4696 38 19.9609 37.7893 19.5858 37.4142C19.2107 37.0391 19 36.5304 19 36L19 25Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path *ngIf="!selected" d="M25 38L25 28H31V38M19 25L28 18L37 25V36C37 36.5304 36.7893 37.0391 36.4142 37.4142C36.0391 37.7893 35.5304 38 35 38H21C20.4696 38 19.9609 37.7893 19.5858 37.4142C19.2107 37.0391 19 36.5304 19 36L19 25Z" stroke="#381B2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg *ngIf="icon === 'files'" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path *ngIf="selected" d="M37.1806 22.1603C37.3801 22.2915 37.5518 22.4607 37.686 22.6582C37.8201 22.8558 37.914 23.0778 37.9624 23.3116C38.0107 23.5455 38.0125 23.7865 37.9677 24.0211L35.5813 36.5232C35.4907 36.9967 35.2159 37.4149 34.8171 37.6858C34.4184 37.9567 33.9284 38.0581 33.4548 37.9678L25.5 36.4495M18 30.7276H34.3639M19.8182 18L32.5457 18C33.5499 18 34.3639 18.814 34.3639 19.8182V32.5458C34.3639 33.55 33.5499 34.3641 32.5457 34.3641H19.8182C18.814 34.3641 18 33.55 18 32.5458L18 19.8182C18 18.814 18.814 18 19.8182 18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path *ngIf="!selected" d="M37.1806 22.1603C37.3801 22.2915 37.5518 22.4607 37.686 22.6582C37.8201 22.8558 37.914 23.0778 37.9624 23.3116C38.0107 23.5455 38.0125 23.7865 37.9677 24.0211L35.5813 36.5232C35.4907 36.9967 35.2159 37.4149 34.8171 37.6858C34.4184 37.9567 33.9284 38.0581 33.4548 37.9678L25.5 36.4495M18 30.7276H34.3639M19.8182 18L32.5457 18C33.5499 18 34.3639 18.814 34.3639 19.8182V32.5458C34.3639 33.55 33.5499 34.3641 32.5457 34.3641H19.8182C18.814 34.3641 18 33.55 18 32.5458L18 19.8182C18 18.814 18.814 18 19.8182 18Z" stroke="#381B2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg *ngIf="icon === 'portfolio'" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path *ngIf="selected" d="M32 23V21C32 20.4696 31.7893 19.9609 31.4142 19.5858C31.0391 19.2107 30.5304 19 30 19H26C25.4696 19 24.9609 19.2107 24.5858 19.5858C24.2107 19.9609 24 20.4696 24 21V23M28 27.5V31.5M20.93 30.43L23.76 33.26M32.24 33.26L35.07 30.43M20 23L36 23C37.1046 23 38 23.8954 38 25V35C38 36.1046 37.1046 37 36 37H20C18.8954 37 18 36.1046 18 35L18 25C18 23.8954 18.8954 23 20 23Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path *ngIf="!selected" d="M32 23V21C32 20.4696 31.7893 19.9609 31.4142 19.5858C31.0391 19.2107 30.5304 19 30 19H26C25.4696 19 24.9609 19.2107 24.5858 19.5858C24.2107 19.9609 24 20.4696 24 21V23M28 27.5V31.5M20.93 30.43L23.76 33.26M32.24 33.26L35.07 30.43M20 23L36 23C37.1046 23 38 23.8954 38 25V35C38 36.1046 37.1046 37 36 37H20C18.8954 37 18 36.1046 18 35L18 25C18 23.8954 18.8954 23 20 23Z" stroke="#381B2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg *ngIf="icon === 'coin'" width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path *ngIf="selected" d="M13.7021 4.93002C18.4964 0.133486 26.2721 0.135338 31.0682 4.93002C35.8644 9.72469 35.8644 17.501 31.0682 22.2975M8.59177 23.9166C9.3313 26.2833 11.531 28 14.1297 28C15.6161 28 16.9719 27.4383 17.9987 26.5146M8.59177 23.9166C8.41914 23.3642 8.32608 22.7764 8.32608 22.1666C8.32608 21.5569 8.41914 20.9691 8.59177 20.4166M8.59177 23.9166H7.16537M8.59177 23.9166H12.9689M8.59177 20.4166C9.3313 18.05 11.531 16.3333 14.1297 16.3333C15.6161 16.3333 16.9719 16.895 17.9987 17.8187M8.59177 20.4166H7.16537M8.59177 20.4166H12.9689M25.8943 22.3846C25.8943 29.1678 20.3959 34.6666 13.6132 34.6666C6.83048 34.6666 1.33203 29.1678 1.33203 22.3846C1.33203 15.6014 6.83048 10.1025 13.6132 10.1025C20.3959 10.1025 25.8943 15.6014 25.8943 22.3846Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path *ngIf="!selected" d="M13.7021 4.93002C18.4964 0.133486 26.2721 0.135338 31.0682 4.93002C35.8644 9.72469 35.8644 17.501 31.0682 22.2975M8.59177 23.9166C9.3313 26.2833 11.531 28 14.1297 28C15.6161 28 16.9719 27.4383 17.9987 26.5146M8.59177 23.9166C8.41914 23.3642 8.32608 22.7764 8.32608 22.1666C8.32608 21.5569 8.41914 20.9691 8.59177 20.4166M8.59177 23.9166H7.16537M8.59177 23.9166H12.9689M8.59177 20.4166C9.3313 18.05 11.531 16.3333 14.1297 16.3333C15.6161 16.3333 16.9719 16.895 17.9987 17.8187M8.59177 20.4166H7.16537M8.59177 20.4166H12.9689M25.8943 22.3846C25.8943 29.1678 20.3959 34.6666 13.6132 34.6666C6.83048 34.6666 1.33203 29.1678 1.33203 22.3846C1.33203 15.6014 6.83048 10.1025 13.6132 10.1025C20.3959 10.1025 25.8943 15.6014 25.8943 22.3846Z" stroke="#381B2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  <svg *ngIf="icon === 'help'" width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path *ngIf="selected" d="M13.1487 13C13.5405 11.8861 14.314 10.9468 15.332 10.3485C16.35 9.75025 17.5469 9.53154 18.7107 9.73117C19.8745 9.93079 20.9301 10.5359 21.6905 11.4392C22.4509 12.3425 22.8671 13.4859 22.8654 14.6666C22.8654 18 17.8654 19.6666 17.8654 19.6666M17.9987 26.3333H18.0154M34.6654 18C34.6654 27.2047 27.2034 34.6666 17.9987 34.6666C8.79395 34.6666 1.33203 27.2047 1.33203 18C1.33203 8.79523 8.79395 1.33331 17.9987 1.33331C27.2034 1.33331 34.6654 8.79523 34.6654 18Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path *ngIf="!selected" d="M13.1487 13C13.5405 11.8861 14.314 10.9468 15.332 10.3485C16.35 9.75025 17.5469 9.53154 18.7107 9.73117C19.8745 9.93079 20.9301 10.5359 21.6905 11.4392C22.4509 12.3425 22.8671 13.4859 22.8654 14.6666C22.8654 18 17.8654 19.6666 17.8654 19.6666M17.9987 26.3333H18.0154M34.6654 18C34.6654 27.2047 27.2034 34.6666 17.9987 34.6666C8.79395 34.6666 1.33203 27.2047 1.33203 18C1.33203 8.79523 8.79395 1.33331 17.9987 1.33331C27.2034 1.33331 34.6654 8.79523 34.6654 18Z" stroke="#381B2A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</div>
