import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from 'src/app/services/profile.service';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-subscribe-project-news',
  templateUrl: './subscribe-project-news.component.html',
  styleUrls: ['./subscribe-project-news.component.scss'],
})
export class SubscribeProjectNewsComponent implements OnInit {

  @Input() projectName: any;
  @Input() projectId: any;
  @Output() public chageStageHubSpotEvent = new EventEmitter<any>();

  public emailForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public errMsgRequired = {};
  public errMsgLogin = {};
  public isSubmit = false;
  public errorLogin = false;
  public sendXHR = false;
  public isActive = true;
  public idToInvest: string;
  public isLogout = false;

  isSubmitted = false;
  btnType = 'primaryDark-disabled';
  loading = false;
  text: string;
  image: number;
  acceptCommunications: boolean;
  showCheck: boolean;
  wantToChangeMail = false;
  defaultEmail: string;

  constructor(
    public chdRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private storage: Storage,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.loading = true;
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+[.]+[a-z]{2,4}$')])
    });
    this.storage.get('USERNAME').then(username => {
      this.defaultEmail = username;
      this.profileService.getProfile(username).subscribe(value => {
        this.acceptCommunications = value.acceptCommunications;
        this.showCheck = !value.acceptCommunications;
        this.loading = false;
        this.chdRef.detectChanges();
      });
    });
    this.validateForm();
    this.chdRef.detectChanges();
  }

  changeM() {
    this.wantToChangeMail = !this.wantToChangeMail;
    this.validateForm();
    this.chdRef.detectChanges();
  }

  validateForm() {
    this.btnType = !this.wantToChangeMail ? 'primaryDark' : this.emailForm.valid ? 'primaryDark' : 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }

  submitForm() {
    this.loading = true;
    /* if (this.showCheck && this.acceptCommunications) {
      this.profileService.updateProfile( {acceptCommunications: true} ).subscribe(() => {
        const mail = !this.wantToChangeMail ? this.defaultEmail: this.emailForm.value.email;
        this.listOfProjectsService.subscribeExclusive(mail, this.projectId, true).subscribe(() => {
          this.formSubmited(true);
          this.chdRef.detectChanges();
        }, error => {
          this.formSubmited(false);
          this.chdRef.detectChanges();
        });
      }, error => {
        this.formSubmited(false);
        this.chdRef.detectChanges();
      });
    } else {
      const mail = !this.wantToChangeMail ? this.defaultEmail: this.emailForm.value.email;
      this.listOfProjectsService.subscribeExclusive(mail, this.projectId, false).subscribe(() => {
        this.formSubmited(true);
        this.chdRef.detectChanges();
      }, error => {
        this.formSubmited(false);
        this.chdRef.detectChanges();
      });
    } */
    this.chageStageHubSpotEvent.emit('info');
    this.chdRef.detectChanges();
  }

  public formSubmited(sub) {
    if (sub) {
      this.text = this.translate.instant('NEWS.SUBSCRIBED_OK');
      this.image = 6;
      this.isSubmitted = true;
      this.loading = false;
      this.chdRef.detectChanges();
    } else {
      this.text = this.translate.instant('NEWS.SUBSCRIBED_ERROR');
      this.image = 1;
      this.isSubmitted = true;
      this.loading = false;
      this.chdRef.detectChanges();
    }
  }

  toggleCommunicationss() { }

}
