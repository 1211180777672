<div class="contentList">
  <div class="update-content">
    <div class="row">
      <div class="col-2"><ion-skeleton-text  style="width: 90%" animated></ion-skeleton-text></div>
      <div class="col-7"></div>
      <div class="col-2"><ion-skeleton-text style="width: 90%" animated></ion-skeleton-text></div>
      <div class="col-1"><ion-skeleton-text style="width: 90%" animated></ion-skeleton-text></div>
    </div>
    <div class="row mov_none">
      <div class="col-3"><ion-skeleton-text  style="width: 90%" animated></ion-skeleton-text></div>
    </div>
    <div class="row mov_none">
      <div class="col-12"><ion-skeleton-text  style="width: 90%" animated></ion-skeleton-text></div>
      <div class="col-12"><ion-skeleton-text  style="width: 90%" animated></ion-skeleton-text></div>
    </div>
  </div>
</div>