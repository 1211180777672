import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatorNif } from '../../register-validators/nif.validator';

@Component({
  selector: 'fun-register-person-document-id',
  templateUrl: './register-person-document-id.component.html',
  styleUrls: ['./register-person-document-id.component.scss']
})
export class RegisterPersonDocumentIdComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() public defaultSelected;
  @Input() isMobile: boolean;
  @Input() addSpecificNie: boolean;

  public selectForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public selectIsOpen = false;

  docListValue: any;
  docList = [];
  valueInp: any;
  documentPlaceholder: string;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.addSpecificNie) {
      this.docList = [{ name: 'NIE', id: 2}];
      this.docListValue = 2;
    } else if (this.defaultSelected !== undefined) {
      this.docList = [this.defaultSelected];
      this.docListValue = this.defaultSelected.id;
      this.valueInp = '';
    } else {
      if (this.data.docNumber !== undefined) {
        this.valueInp = this.data.docNumber;
      } else {
        this.valueInp = '';
      }
      this.docList = [
        { name: 'DNI', id: 1},
        //{ name: 'NIE', id: 2},
        { name: 'Pasaporte', id: 3}
      ];
      if (this.data.nation && this.data.nation !== undefined && this.data.nation !== '') {
        const nation = this.data.nation;
        const residence = this.data.residence;
        if (nation === '108' || nation === 108) {
          this.docListValue = 1;
          this.docList.pop();
        } /*else if (residence === '108' || residence === 108) {
          this.docListValue = 2;
        }*/ else {
          this.docListValue = 3;
          this.docList.shift();
        }
      } else {
        this.docListValue = null;
      }
    }
    this.selectForm = new FormGroup({
      docType: new FormControl(this.docListValue, []),
      dni: new FormControl('', [Validators.required]),
    });
    this.getInfo();
  }
  @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }

  onProjectIdSelectChange(event: { target: { value: string; }; }): void {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  getInfo() {
    let type: string;
    let documentValid: boolean;

    if (this.selectForm.value.docType === '' || this.selectForm.value.docType === undefined || !this.selectForm.value.docType) {
      type = this.docListValue;
    } else {
      type = this.selectForm.value.docType;
    }
    const value = {
      docType: type,
      dni: this.selectForm.value.dni
    };

    if ((this.selectForm.value.docType === '' && (this.docListValue === 1 || this.docListValue === 2))
    || (this.selectForm.value.docType === 1 || this.selectForm.value.docType === 2)) {
      documentValid = this.validateNif(this.selectForm.value.dni);
      this.selectForm.controls['dni'].setValidators([Validators.required, ValidatorNif]);
      if (this.selectForm.valid && documentValid) {
        this.sendInfoEvent.emit(value);
      } else {
        this.sendErrorEvent.emit();
      }
    } else {
      this.selectForm.controls['dni'].setValidators([Validators.required]);
      if (this.selectForm.value.dni.length < 2 || !this.selectForm.valid) {
        this.sendErrorEvent.emit();
      } else {
        this.sendInfoEvent.emit(value);
      }
    }

    if (this.defaultSelected !== undefined) {
      if (this.defaultSelected.name === 'NIE' || this.defaultSelected.name === 'DNI') {
        this.selectForm.controls['dni'].setValidators([Validators.required, ValidatorNif]);
      }
    }
    this.getDocPlaceholder();
    this.chdRef.detectChanges();
  }

  validateNif(dni) {
    let number;
    let letter;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    if (dni !== undefined && dni !== null) {
        dni = dni.toUpperCase();
    }

    if (expresion_regular_dni.test(dni) === true) {
        number = dni.substr(0, dni.length - 1);
        number = number.replace('X', 0);
        number = number.replace('Y', 1);
        number = number.replace('Z', 2);
        number = number % 23;
        letter = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letter = letter.substring(number, number + 1);
        if (letter !== dni.substr(dni.length - 1, 1)) {
            return false;
        }
        return true;
    }
    return false;
  }

  getDocPlaceholder() {
    switch (this.selectForm.value.docType) {
      case 1:
        this.documentPlaceholder = '57824824H';
        break;
      case 2:
        this.documentPlaceholder = 'X2465756H';
        break;
      case 3:
        this.documentPlaceholder = 'ZAB00254';
        break;
      default:
        this.documentPlaceholder = '57824824H';
        break;
    }
    this.chdRef.detectChanges();
  }

}
