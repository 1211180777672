<div class="w-full h-full border border-eggplant-100 bg-white">
  <div>
    <p>{{ movement.amount | localeNumber }} €</p>
    <p>{{ 'dashboard-card.Benefits' | translate }}</p>
    <p>{{ movement.dateParse }}</p>
  </div>
  <div>
    <p>{{ movement.projectName }}</p>
    <div class="flex"></div>
  </div>
</div>