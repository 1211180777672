import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'fun-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit, AfterViewInit {

  @ViewChild('code1', {static: false}) code1: ElementRef;
  @ViewChild('code2', {static: false}) code2: ElementRef;
  @ViewChild('code3', {static: false}) code3: ElementRef;
  @ViewChild('code4', {static: false}) code4: ElementRef;
  @ViewChild('code5', {static: false}) code5: ElementRef;
  @ViewChild('code6', {static: false}) code6: ElementRef;

  @Input('disabled') disabled;
  @Input() isMobile: boolean;
  @Output() public sendValueEvent = new EventEmitter<any>();

  parentForm: FormGroup;
  isDisabled: boolean;
  elements: any;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.parentForm = new FormGroup({
      code1: new FormControl('', []),
      code2: new FormControl('', []),
      code3: new FormControl('', []),
      code4: new FormControl('', []),
      code5: new FormControl('', []),
      code6: new FormControl('', [])
    });
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.code1.nativeElement.focus();
    this.elements =  [this.code1, this.code2, this.code3, this.code4, this.code5, this.code6];
    if (this.disabled === undefined) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
    this.chdRef.detectChanges();
  }

  next(code, e) {
    for (let i = 0; i < this.elements.length; i++) {
      if (code.id === this.elements[i].nativeElement.id) {
        this.elements[i].nativeElement.focus();
        if (code.value !== '' && (
          e.key === '0' || e.key === '1' || e.key === '2'
          || e.key === '3' || e.key === '4' || e.key === '5'
          || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9'
        )) {
          if (i < 5 && this.elements[i].nativeElement.value !== '') {
            this.elements[i + 1].nativeElement.focus();
          }
        }
      }
    }
    this.chdRef.detectChanges();
  }

  nextFocus(code, e) {
    for (let i = 0; i < this.elements.length; i++) {
      if (code.id === this.elements[i].nativeElement.id) {
        this.elements[i].nativeElement.focus();
        if (code.value !== '' && (
          e.key === '0' || e.key === '1' || e.key === '2'
          || e.key === '3' || e.key === '4' || e.key === '5'
          || e.key === '6' || e.key === '7' || e.key === '8' || e.key === '9'
        )) {
          if (i < 5) {
            this.elements[i + 1].nativeElement.focus();
          }
        } else {
          if (i > 0 && e.key === 'Backspace') {
            this.elements[i - 1].nativeElement.focus();
          }
        }
        if (e.key === 'ArrowLeft') {
          if (i > 0) {
            this.elements[i - 1].nativeElement.focus();
          }
        }
        if ( e.key === 'ArrowRight') {
          if (i < 5) {
            this.elements[i + 1].nativeElement.focus();
          }
        }
      }
    }
    this.chdRef.detectChanges();
  }

  validate() {
    if (this.parentForm.valid) {
      this.sendValueEvent.emit(this.parentForm.value);
    }
    this.chdRef.detectChanges();
  }

  pasteCode() {
    setTimeout(() => {
      const fullCode = this.parentForm.value.code1.toString();
      if (this.checkDigit(fullCode[0])) {
        this.parentForm.patchValue({code1: Number(fullCode[0])});
        this.elements[1].nativeElement.focus();
        if (fullCode[1] && this.checkDigit(fullCode[1])) {
          this.parentForm.patchValue({code2: Number(fullCode[1])});
          this.elements[2].nativeElement.focus();
          if (fullCode[2] && this.checkDigit(fullCode[2])) {
            this.parentForm.patchValue({code3: Number(fullCode[2])});
            this.elements[3].nativeElement.focus();
            if (fullCode[3] && this.checkDigit(fullCode[3])) {
              this.parentForm.patchValue({code4: Number(fullCode[3])});
              this.elements[4].nativeElement.focus();
              if (fullCode[4] && this.checkDigit(fullCode[4])) {
                this.parentForm.patchValue({code5: Number(fullCode[4])});
                this.elements[5].nativeElement.focus();
                if (fullCode[5] && this.checkDigit(fullCode[5])) {
                  this.parentForm.patchValue({code6: Number(fullCode[5])});
                }
              }
            }
          }
        }
      }
    }, 10);
  }

  checkDigit(digit) {
    if (digit[0] === '1'
        || digit[0] === '2'
        || digit[0] === '3'
        || digit[0] === '4'
        || digit[0] === '5'
        || digit[0] === '6'
        || digit[0] === '7'
        || digit[0] === '8'
        || digit[0] === '9'
        || digit[0] === '0'
    ) {
      return true;
    }
    return false;
  }

}
