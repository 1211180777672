<div class="register">
    <div class="body">
        <div class="form-input checkbox">
            <input (change)="onChangeAcceptRisks(1)" type="checkbox" id="cb1">
            <label for="cb1">{{ 'register.accredited_investor.check_1' | translate }}</label>
        </div>
        <div class="form-input checkbox">
            <input (change)="onChangeAcceptRisks(2)" type="checkbox" id="cb2">
            <label for="cb2">{{ 'register.accredited_investor.check_2' | translate }}</label>
        </div>
        <div class="form-input checkbox">
            <input (change)="onChangeAcceptRisks(3)" type="checkbox" id="cb3">
            <label for="cb3">{{ 'register.accredited_investor.check_3' | translate }}</label>
        </div>
    </div>
</div>
