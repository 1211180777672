<div class="register">
    <div class="body">
        <div class="name-container">
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.know.name' | translate }}"
                    [parentForm]="nameForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="name"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.name' | translate }}"
                    (keyup.enter)="getInfo(true)"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.name"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.know.last_name' | translate }}"
                    [parentForm]="nameForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="surname"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.last_name' | translate }}"
                    (keyup.enter)="getInfo(true)"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (focusout)="focusLost()"
                    [value]="data.surname"
                ></fun-input>
                <span *ngIf="notFullSurname" class="advice">{{ 'register.know.advice' | translate }}</span>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="dd/mm/aaaa"
                    [parentForm]="nameForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="date"
                    type="date"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.date' | translate }}"
                    (keyup.enter)="getInfo(true)"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.birthDate"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
