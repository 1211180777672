import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-page-submenu',
  templateUrl: './page-submenu.component.html',
  styleUrls: ['./page-submenu.component.scss'],
})
export class PageSubmenuComponent implements OnInit {

  @Output() public iconClickedEvent = new EventEmitter<any>();
  @Input() list: [any];

  firstList = [];
  secondList = [];

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.list.length > 2) {
      const halft = Math.ceil(this.list.length / 2);
      this.firstList = this.list.slice(0, halft);
      this.secondList = this.list.slice(halft);
    } else {
      this.firstList = this.list;
    }

    this.chdRef.detectChanges();
  }

  iconClicked(url) {
    this.iconClickedEvent.emit(url);
    this.chdRef.detectChanges();
  }

}
