import { EventEmitter, Injectable, Output } from '@angular/core';

import { NativeBiometric, AvailableResult, Credentials } from 'capacitor-native-biometric';
import { Haptics } from '@capacitor/haptics';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { AlertService } from '../shared/components/alert/alert.service';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {

  @Output() public getCredentialsEvent = new EventEmitter<any>();

  constructor(public translate: TranslateService, public aletService: AlertService, public storage: Storage) { }

  canUseBiometricData() {
    return NativeBiometric.isAvailable();
  }

  makeMobileVibrate() {
    Haptics.vibrate();
  }

  checkBiometricAvailable() {
    this.storage.get('CREDENTIALS_SAVED').then(val => {
      if (val) {
        // Check if biometrics are available and which type is supported
        NativeBiometric.isAvailable().then(
          (result: AvailableResult) => {
            const isAvailable = result.isAvailable;
            //const isFaceId = result.biometryType === BiometryType.FACE_ID;

            if (isAvailable) {
              // Get user's credentials
              NativeBiometric.getCredentials({
                server: "www.fundeen.com",
              }).then((credentials: Credentials) => {
                Haptics.vibrate();
                // Authenticate using biometrics before logging the user in
                NativeBiometric.verifyIdentity({
                  reason: this.translate.instant("BIOMETRIC.TITLE"),
                  title: this.translate.instant("BIOMETRIC.TITLE"),
                  subtitle: this.translate.instant("BIOMETRIC.SUBTITLE"),
                  description: "",
                  negativeButtonText: this.translate.instant("BIOMETRIC.CANCEL")
                }).then(
                  () => {
                    Haptics.vibrate();
                    // Authentication successful
                    this.getCredentialsEvent.emit(credentials);
                    return credentials;
                  },
                  (error) => {
                    // Failed to authenticate
                    this.getCredentialsEvent.emit(null);
                    return null;
                  }
                );
              });
            }
          },
          (error) => {
            // Couldn't check availability
          }
        );
      }
    });
  }

  askToSaveCredentials(user, pwd) {
    this.storage.get('CREDENTIALS_SAVED').then(val => {
      if (val === null) {
        NativeBiometric.isAvailable().then((result: AvailableResult) => {
          const modalParams = {
            image: 3,
            text: this.translate.instant("BIOMETRIC.ASK"),
            btnList: [
              {
                type: 'primaryDark',
                index: 2,
                title: this.translate.instant("BIOMETRIC.YES"),
              },
              {
                type: 'secondaryDark',
                index: 1,
                title: this.translate.instant("BIOMETRIC.NO"),
              },
            ],
          };
          this.aletService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
            modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
              componentRef.btnEvent.subscribe((resp) => {
                this.aletService.removeModal();
                if (resp === 1) {
                  this.storage.set('CREDENTIALS_SAVED', false);
                } else {
                  this.storage.set('CREDENTIALS_SAVED', true);
                  this.saveUserCredentials(user, pwd);
                }
              });
            });
            modal.onClose().subscribe();
          });
        });
      }
    });
  }

  saveUserCredentials(user, pwd) {
    // Save user's credentials
    NativeBiometric.setCredentials({
      username: user,
      password: pwd,
      server: "www.fundeen.com",
    }).then();
  }

  getUserCredentials() {
    return NativeBiometric.getCredentials({
      server: "www.fundeen.com",
    });
  }

  deleteUserCredentials() {
    NativeBiometric.deleteCredentials({
      server: "www.fundeen.com",
    }).then();
  }
}
