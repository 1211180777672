import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MovementsService } from 'src/app/services/movements.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {

  @Output() public btnAction = new EventEmitter<any>();
  @Output() public deleteAction = new EventEmitter<any>();

  loading = true;
  text: string;
  image = 1;
  btnList = [{
    type: 'primaryDark',
    index: 2,
    title: this.translate.instant('DELETE_ACCOUNT.DELETE'),
  },{
    type: 'secondaryDark',
    index: 1,
    title: this.translate.instant('DELETE_ACCOUNT.CANCEL'),
  }];

  constructor(
    private chdRef: ChangeDetectorRef,
    private profileService: ProfileService,
    private toastService: ToastService,
    private translate: TranslateService,
    private movementsService: MovementsService
    ) { }

  ngOnInit() {
    this.movementsService.getBalance().subscribe(resp => {
      if (resp.balance === 0 && resp.credit === 0 && resp.debit === 0) {
        this.text = this.translate.instant('DELETE_ACCOUNT.TXT_1');
      } else if (resp.balance === 0 && ( resp.credit !== 0 || resp.debit !== 0)) {
        this.text = this.translate.instant('DELETE_ACCOUNT.TXT_2');
      } else if (resp.balance !== 0 && resp.credit === 0 && resp.debit === 0) {
        this.btnList = [{
          type: 'secondary',
          index: 2,
          title: this.translate.instant('DELETE_ACCOUNT.BLOCK'),
        },{
          type: 'secondaryDark',
          index: 1,
          title: this.translate.instant('DELETE_ACCOUNT.CANCEL'),
        }];
        this.text = this.translate.instant('DELETE_ACCOUNT.TXT_3');
      } else {
        this.btnList = [{
          type: 'secondary',
          index: 2,
          title: this.translate.instant('DELETE_ACCOUNT.BLOCK'),
        },{
          type: 'secondaryDark',
          index: 1,
          title: this.translate.instant('DELETE_ACCOUNT.CANCEL'),
        }];
        this.text = this.translate.instant('DELETE_ACCOUNT.TXT_4');
      }
      this.loading = false;
      this.chdRef.detectChanges();
    }, error => {
      this.text = this.translate.instant('DELETE_ACCOUNT.TXT_1');
      this.loading = false;
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  deleteAccount(id) {
    if (id === 1) {
      this.btnAction.emit();
      this.chdRef.detectChanges();
    } else {
      this.loading = true;
      this.profileService.deleteAccount().subscribe(() => {
        this.deleteAction.emit();
        this.chdRef.detectChanges();
      }, error => {
        this.btnAction.emit();
        this.toastService.showToast(this.translate.instant('DELETE_ACCOUNT.DELETE_ERROR'), 'warning', true);
        this.chdRef.detectChanges();
      });
      this.chdRef.detectChanges();
    }
  }

}
