import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidatorNif } from '../../register-validators/nif.validator';

@Component({
  selector: 'fun-register-company-rldocument',
  templateUrl: './register-company-rldocument.component.html',
  styleUrls: ['./register-company-rldocument.component.scss']
})
export class RegisterCompanyRldocumentComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() isMobile: boolean;

  public RLDocForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  selectIsOpen = false;
  disabled = false;

  docList = [
    { id: 1, name: 'DNI' },
    { id: 2, name: 'NIE' },
    { id: 3, name: 'Pasaporte' }
  ];

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) { }

  @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }

  ngOnInit() {
    this.RLDocForm = new FormGroup({
      identityDocumentTypeRepresentative: new FormControl(this.checkDefaultType(), [Validators.required, Validators.min(0.01)]),
      identityDocumentNumberRepresentative: new FormControl('', [Validators.required]),
    });
    this.formControlKeys = Object.keys(this.RLDocForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    if (this.data.identityDocumentTypeRepresentative !== null
      && this.data.identityDocumentTypeRepresentative !== undefined) {
          this.disabled = true;
    } else {
      this.disabled = false;
    }
    this.getInfo();
    this.chdRef.detectChanges();
  }

  checkDefaultType() {
    if (this.data.identityDocumentTypeRepresentative) {
      return this.data.identityDocumentTypeRepresentative;
    } else {
      if (this.data.nationalityRepresentative === 108 || this.data.nationalityRepresentative === '108') {
        return 1; // DNI
      } else if (this.data.countryRepresentative === 108 || this.data.countryRepresentative === '108') {
        return 2; // NIE
      } else {
        return 3; // Passport
      }
    }
  }

  getInfo() {
    let documentValid: boolean;
    if (Number(this.RLDocForm.value.identityDocumentTypeRepresentative) === 1
        || Number(this.RLDocForm.value.identityDocumentTypeRepresentative) === 2) {
          this.RLDocForm.controls['identityDocumentNumberRepresentative'].setValidators([Validators.required, ValidatorNif]);
          documentValid = this.RLDocForm.controls['identityDocumentNumberRepresentative'].valid;
          if (!documentValid) {
            this.sendErrorEvent.emit();
          }
    } else {
      this.RLDocForm.controls['identityDocumentNumberRepresentative'].setValidators([Validators.required]);
      if (this.RLDocForm.value.identityDocumentNumberRepresentative !== '') {
        documentValid = true;
      } else {
        documentValid = false;
        this.sendErrorEvent.emit();
      }
    }
    if (this.RLDocForm.valid && documentValid) {
      this.sendInfoEvent.emit(this.RLDocForm.value);
    } else {
      this.sendErrorEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  changeType() {
    this.getInfo();
    this.chdRef.detectChanges();
  }

}
