
<div *ngIf="loaded">
  <div class="container-header" *ngIf="stepValue < 4">

  <div class="container">
      <ul [className]="selectIsOpen ? 'open' : ''">
          <li class="font-sans text-eggplant-100" (click)="selectOpen()">
              <span class="text-m text-eggplant-100/[0.55]" >{{ 'bid-create.project' | translate }}</span>
              <span class="text-l" >
                  {{ createBidOfferData.name_project }}
              </span>
              <span class="hidden-lg text-l">{{ createBidOfferData.amount }} {{ 'bid-create.shares' | translate }}</span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.seller' | translate }}</span>
              <span class="text-l" >
                  <span class="avatar text-white">{{ createBidOfferData.name_seller | nameProfile }}</span>
                  {{ createBidOfferData.name_seller | titlecase }}
              </span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.shares' | translate }}</span>
              <span class="text-l">{{ createBidOfferData.amount | localeNumber }}</span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.adquisition-price' | translate }}</span>
              <span class="text-l" >{{ createBidOfferData.adquisition_price | localeNumber }} €</span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.formalization' | translate }}</span>
              <span class="text-l" *ngIf="createBidOfferData.formalization_type == 'internal'">{{ 'bid-create.internal' | translate }}</span>
              <span class="text-l" *ngIf="createBidOfferData.formalization_type == 'external'">{{ 'bid-create.external' | translate }}</span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.bids' | translate }}</span>
              <span class="text-l">{{ createBidOfferData.current_bids | localeNumber }}</span>
          </li>
          <li class="font-sans text-eggplant-100">
              <span class="text-m text-eggplant-100/[0.55]">{{ 'bid-create.time-remain' | translate }}</span>
              <span class="text-l" *ngIf="remainDays > 1">{{ remainDays }} {{ 'bid-create.days' | translate }}</span>
              <span class="text-l" *ngIf="remainDays == 1">{{ remainDays }} {{ 'bid-create.day' | translate }}</span>
          </li>
      </ul>
      <div class="overlay"></div>
  </div>
</div>

<div class="container" *ngIf="stepValue == 0">
  <div class="row">
      <ul class="pagination-tab show-all children:font-sans children:text-xl">
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">1</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.risks' | translate }}</span>
          </li>
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">2</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.amount' | translate }}</span>
          </li>
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">3</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.confirm' | translate }}</span>
          </li>
      </ul>
  </div>
</div>


<div class="container bid" *ngIf="stepValue == 1">
  <div class="row">
      <ul class="pagination-tab children:font-sans children:text-xl">
          <li class="selected">
              <span class="number text-white bg-eggplant-100/[0.2]">1</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.risks' | translate }}</span>
          </li>
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">2</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.amount' | translate }}</span>
          </li>
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">3</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.confirm' | translate }}</span>
          </li>
      </ul>
  </div>
  <div class="row">
      <div class="content-background-modal space [&>h3]:font-sans [&>p]:text-eggplant-100/[0.55] [&>p]:text-l [&>p]:font-sans [&>h3]:text-eggplant-100 [&>h3]:text-xl"
        (scroll)="onScroll($event)" [innerHTML]="'bid-create.risk-text' | translate"></div>
  </div>
</div>

<div class="container bid" *ngIf="stepValue == 2">
  <div class="row">
      <ul class="pagination-tab children:font-sans children:text-xl">
          <li class="done">
              <span class="number text-white bg-eggplant-100/[0.2]">1</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.risks' | translate }}</span>
          </li>
          <li class="selected">
              <span class="number text-white bg-eggplant-100/[0.2]">2</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.amount' | translate }}</span>
          </li>
          <li>
              <span class="number text-white bg-eggplant-100/[0.2]">3</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.confirm' | translate }}</span>
          </li>
      </ul>
  </div>
  <div class="row">
      <div class="content-background-modal row space" [formGroup]="bidCreateForm">
          <p class="description col-6">{{ 'bid-create.amount-text' | translate }}</p>
          <div class="form-input input col-4">
              <label for="cantidad">{{ 'bid-create.your-bid' | translate }}:</label>
              <input (keyup)="onChangeAmount()" [ngModel]="inputV.value | notzero" #inputV
              class="font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 bg-cream-100/[.10] mt-6 py-8 pl-12 pr-28"
              [ngClass]="{'error': f.amount.errors?.required && (isCheckAcceptRisks && isCheckAcceptContactData) || f.amount.errors?.min && (isCheckAcceptRisks && isCheckAcceptContactData)}"
              formControlName="amount" placeholder="{{ 'bid-create.input-text' | translate }}" type="number"
              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57">
              <p class="error-message" *ngIf="f.amount.errors?.required && (isCheckAcceptRisks && isCheckAcceptContactData)">{{ 'bid-create.required-text' | translate }}</p>
              <p class="error-message" *ngIf="f.amount.errors?.min && (isCheckAcceptRisks && isCheckAcceptContactData)">{{ 'bid-create.min-text' | translate }}</p>
          </div>
          <div class="form-input checkbox">
              <input formControlName="acceptRisks" (change)="onChangeAcceptRisks()" type="checkbox" id="cb1">
              <label for="cb1">{{ 'bid-create.accept-risks-text' | translate }}</label>
          </div>
          <div class="form-input checkbox">
            <input formControlName="acceptShareMyContact" (change)="onChangeAcceptContactData()" type="checkbox" id="cb2">
            <label for="cb2">{{ 'BID.ACCEPT_SHARE_INFO' | translate }}</label>
        </div>
      </div>

  </div>
</div>

<div class="container bid" *ngIf="stepValue == 3">
  <div class="row">
      <ul class="pagination-tab children:font-sans children:text-xl">
          <li class="done">
              <span class="number text-white bg-eggplant-100/[0.2]">1</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.risks' | translate }}</span>
          </li>
          <li class="done">
              <span class="number text-white bg-eggplant-100/[0.2]">2</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.amount' | translate }}</span>
          </li>
          <li class="selected">
              <span class="number text-white bg-eggplant-100/[0.2]">3</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.confirm' | translate }}</span>
          </li>
      </ul>
  </div>
  <div class="row">
      <div class="content-background-modal row space">
          <p class="description col-12">{{ 'bid-create.confirm-text' | translate }}</p>
          <ul class="list-confirm">
              <li class="font-sans text-xl text-eggplant-100">
                  <span>{{ 'bid-create.project' | translate }}</span>
                  <span class="text-eggplant-100/[0.55]">{{ createBidOfferData.name_project }}</span>
              </li>
              <li class="font-sans text-xl text-eggplant-100">
                  <span>{{ 'bid-create.amount' | translate }}</span>
                  <span class="text-eggplant-100/[0.55]">{{ createBidOfferData.amount | localeNumber }} {{ 'card.shares' | translate }}</span>
              </li>
              <li class="font-sans text-xl text-eggplant-100">
                  <span>{{ 'bid-create.your-bid' | translate }}</span>
                  <span class="text-eggplant-100/[0.55]">{{ dataBid.price | localeNumber }} €</span>
              </li>
          </ul>
      </div>

  </div>
</div>

<div class="container-footer" *ngIf="stepValue < 4">
  <div *ngIf="stepValue < 4" class="container grid md:flex gap-24 justify-between items-center">
      <fun-new-button [classExtra]="' !w-full '" *ngIf="stepValue < 4" [buttonType]="backBtn" title="{{ 'bid-create.return' | translate }}" class="button" (eventClick)="prevStep()"></fun-new-button>
      <fun-new-button [classExtra]="' !w-full '" *ngIf="stepValue == 0" [buttonType]="stepsContinue[0] ? 'primaryDark':'primaryDark-disabled'" title="{{ 'bid-create.continue' | translate }}" class="button" (eventClick)="nextStep()"></fun-new-button>
      <fun-new-button [classExtra]="' !w-full '" *ngIf="stepValue == 1" [buttonType]="stepsContinue[1] ? 'primaryDark':'primaryDark-disabled'" title="{{ 'bid-create.continue' | translate }}" class="button" (eventClick)="nextStep()"></fun-new-button>
      <fun-new-button [classExtra]="' !w-full '" *ngIf="stepValue == 2" [buttonType]="stepsContinue[2] ? 'primaryDark':'primaryDark-disabled'" title="{{ 'bid-create.continue' | translate }}" class="button" (eventClick)="nextStep()"></fun-new-button>
      <fun-new-button [classExtra]="' !w-full '" *ngIf="stepValue == 3" [buttonType]="stepsContinue[3] ? 'primaryDark':'primaryDark-disabled'" title="{{ 'bid-create.continue' | translate }}" class="button last" (eventClick)="nextStep()"></fun-new-button>
  </div>
</div>

<div *ngIf="stepValue < 4" class="container hidden-lg">
  <div class="row">
      <ul class="pagination-tab show-all children:font-sans children:text-xl">
          <li *ngIf="stepValue == 1">
              <span class="number text-white bg-eggplant-100/[0.2]">2</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.amount' | translate }}</span>
          </li>
          <li *ngIf="stepValue == 2">
              <span class="number text-white bg-eggplant-100/[0.2]">3</span>
              <span class="title text-eggplant-100/[0.2]">{{ 'bid-create.confirm' | translate }}</span>
          </li>
      </ul>
  </div>
</div>

<div class="container final-step" *ngIf="stepValue == 4 && isBidCreatedData">
  <div class="row">
      <div class="content-background-modal row bid-create-txt font-sans" [innerHTML]="'bid-create.bid-created-text' | translate">
      </div>
      <div class="container btn-final-container">
          <fun-new-button [classExtra]="' !w-full '" buttonType="secondaryDark" title="{{ 'bid-create.goto-portfolio' | translate }}" class="button" (eventClick)="onEventClick('dashboard')"></fun-new-button>
          <fun-new-button [classExtra]="' !w-full '" buttonType="primaryDark" title="{{ 'bid-create.goto-marketplace' | translate }}" class="button" (eventClick)="onEventClick('list-of-projects')"></fun-new-button>
      </div>
  </div>
</div>
</div>

