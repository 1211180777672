import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit {
  @Input('texto') texto;
  @Input('lat') lat;
  @Input('lng') lng;
  @Input('zoom') zoom;

  constructor() {}

  ngOnInit() {}
}
