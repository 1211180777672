import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-filter-originator',
  templateUrl: './filter-originator.component.html',
  styleUrls: ['./filter-originator.component.scss']
})
export class FilterOriginatorComponent implements OnInit {

  @Output() public itemselectedEvent = new EventEmitter<any>();
  @Input() items: any;
  @Input() defaultSelected: any;

  itemSelected = '';
  slideOpts: any;

  currentItemName: string;
  opened = false;

  constructor() { }

  ngOnInit() {
    if (this.defaultSelected !== undefined) {
      this.itemSelected = this.defaultSelected;
      this.currentItemName = this.defaultSelected;
    } else {
      this.currentItemName = this.itemSelected[0];
    }
    this.slideOpts = {
      initialSlide: 0,
      speed: 1000,
      effect: 'slide',
      slidesPerView: 4,
      spaceBetween: 12,
      grabCursor: true,
      slideToClickedSlide: true,
      freeMode: true,
      centeredSlides: false
    };
  }

  selectItem(item) {
    this.itemSelected = item;
    this.currentItemName = item;
    this.toggleMenu();
    this.itemselectedEvent.emit(item);
  }

  toggleMenu() {
    this.opened = !this.opened;
    const el = document.getElementById(`content_filter`);
    if (this.opened) {
      el.style.height = `${el.scrollHeight}px`;
      el.style.transition = 'height 0.5s';
    } else {
      el.style.height = '0px';
      el.style.transition = 'height 0.5s';
    }
  }

}
