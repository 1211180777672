<div class="month-title">
  <p class="font-sans text-h600 text-eggplant-100/[0.25] pb-[30px]">{{monthWithBenefits.dateName}}</p>
</div>
<div>
  <ng-container *ngFor="let benefit of monthWithBenefits.benefits">
      <div class="rounded-[16px] w-full relative mb-[30px] flex">
          <div class="content inline-block bg-white w-full py-[28px] px-[40px]">
            <div class="flex">
              <div class="w-full">
                  <p class="font-sans text-xl md:text-2xl text-eggplant-80 pb-[4px]">{{'PAYMENT_CALENDAR.BENEFITS_COMMON_TYPE' | translate}}</p>
                  <p class="font-sans text-l text-eggplant-80">{{benefit.projectName}}</p>
              </div>
              <div class="pl-6">
                  <p class="text-right font-sans text-xl md:text-2xl text-eggplant-80">{{benefit.amount | currency: 'EUR': 'symbol': '1.2-2'}}</p>
              </div>
            </div>
          </div>
      </div>
  </ng-container>
</div>
