<div [formGroup]="parentForm">
    <div>
        <label class={{labelClass}}>{{ labelName }}</label>
    </div>
    <div>
        <input type="tel"
        class={{inputClass}}
        formControlName="phone"
        id="phone"
        class="input"
        [placeholder]="placeholder"
        [ngModel]="value"
        (keyup)="checkPhone()"
        (focus)="focus()"
        onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
        ng2TelInput
        [ng2TelInputOptions]="{initialCountry: 'es', separateDialCode: true, autoPlaceholder: 'off', customPlaceholder: placeholder, preferredCountries: ['es']}"
        (hasError)="hasError($event)"
        (ng2TelOutput)="getNumber($event)"
        (intlTelInputObject)="telInputObject($event)"
        (countryChange)="onCountryChange($event)" />
    </div>
</div>
