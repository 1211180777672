import { Injectable } from '@angular/core';
import { Capacitor  } from '@capacitor/core';
//import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
//import { PosthogService } from './posthog.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {

  constructor(
    //private posthogService: PosthogService
    ) {}

  public pushOnDataLayer(obj: any) {/*
    if (Capacitor.platform !== 'web') {
      analyticsFirebase.logEvent(obj.name, obj.params)
      .then(() => console.log('Event successfully tracked'))
      .catch(err => console.log('Error tracking event:', err));
    }*/
    if (Capacitor.getPlatform() !== 'web') {
      // FirebaseAnalytics.logEvent({
      //   name: obj.name,
      //   params: obj.params
      // });
    }
    //this.posthogService.pushEventParams(obj);
  }

  public pushCustomEventGTM(nameEvent: string){
    if (Capacitor.getPlatform() === 'web') {
      (<any>window).dataLayer = (<any>window).dataLayer || [];
      (<any>window).dataLayer.push({
        event: nameEvent
      });
    }
    //this.posthogService.pushEvent(nameEvent);
  }

}
