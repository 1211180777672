import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fun-skeleton-input',
  templateUrl: './skeleton-input.component.html',
  styleUrls: ['./skeleton-input.component.scss']
})
export class SkeletonInputComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
