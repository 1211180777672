import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class TaxdownService {

  constructor(private communicationService: CommunicationService) { }

  createPinSession(model) {
    return this.communicationService.post('apiservices/user/v1/users/createPinSession', model);
  }

  updatepinSessionsessionId(sessionId, model) {
    return this.communicationService.patch(`apiservices/user/v1/users/updatePinSession/${sessionId}`, model);
  }

  getAeatData(sessionId) {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    return this.communicationService.get(`apiservices/user/v1/users/getAEATData/${sessionId}/${lastYear}`, null);
  }

}
