import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'notzero' })

export class NotZeroPipe implements PipeTransform {

    transform(value: any): any {
        if (value[0] === '0') {
            value = value[-1];
            return value;
        }
        return value;
    }
}
