import { Injectable } from '@angular/core';
import * as momentNs from 'moment';
const moment = momentNs;

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getCurrentDateString() {
    return new Date(this.convertToIos(moment().format('YYYY-MM-DD')));
  }
  getCurrentDateMs() {
    return new Date(this.convertToIos(moment().format('YYYY-MM-DD HH:mm:ss'))).getTime();
  }
  getDateMs(date) {
    let dateN = new Date(this.convertToIos(moment(date).format('YYYY-MM-DD  HH:mm:ss'))).getTime();
    if (
      isNaN(new Date(this.convertToIos(moment(date).format('YYYY-MM-DD  HH:mm:ss'))).getTime()) ||
      dateN === undefined || !dateN) {
      dateN = new Date(
        this.convertToIos(moment(date.replace('/', '-').replace('/', '-')).format('YYYY-MM-DD  HH:mm:ss'))
      ).getTime();
    }
    return dateN;
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] -1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  isSafariBrowser() {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;
    return isSafari;
  }
}
