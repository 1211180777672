<div class="pb-16">
  <p class="font-sans text-2xl pt-12 pb-[40px] text-center">{{ 'MEETING.DOCUMENTATION' | translate }}:</p>
  <div class="flex flex-col gap-24 items-center content-center justify-center w-full h-full overflow-aut0">
    <div *ngFor="let document of documents">
      <fun-new-button
        [buttonType]="'ghost'"
        (eventClick)="downloadDocument(document)"
        [title]="document.title"
        [size]="'m'"
        [icon]="btnIcon"
        [iconPosition]="'left'"
      ></fun-new-button>
    </div>
  </div>
</div>