import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NationalityService } from 'src/app/services/nationality.service';
import { Regions } from 'src/app/shared/other/regions';
import { NationModel } from '../../register-person/register-person-natinality/nation.model';

@Component({
  selector: 'fun-register-company-address',
  templateUrl: './register-company-address.component.html',
  styleUrls: ['./register-company-address.component.scss']
})
export class RegisterCompanyAddressComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() towns: any;
  @Input() isMobile: boolean;

  public companyAddressForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  countriesList = [];
  citiesList = [];
  regionsList = Regions.map(region => {
    return {
      id: region.code,
      name: region.name
    };
  });

  isNationalAddress = true;

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private nationalityService: NationalityService
  ) {}

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.companyAddressForm.valid) {
      this.sendInfoEvent.emit(this.companyAddressForm.value);
      this.submitEvent.emit();
    } else {
      if ((event.target as Element).id === 'taxAddress') {
        document.getElementById('taxPostalCode').focus();
      } else if ((event.target as Element).id === 'taxPostalCode') {
        document.getElementById('taxPostalCode').blur();
      } else if ((event.target as Element).id === 'taxInternationalRegion') {
        document.getElementById('taxInternationalCity').focus();
      }
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.getCountryList();
    this.companyAddressForm = new FormGroup({
      taxAddress: new FormControl(this.data.taxAddress ? this.data.taxAddress : '', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ,.0123456789ºª/]+$')]),
      taxPostalCode: new FormControl(this.data.taxPostalCode ? this.data.taxPostalCode : '', [Validators.required, Validators.pattern('^[A-Za-z0123456789]+$')]),
      taxCountry: new FormControl(this.data.taxCountry ? this.data.taxCountry : (this.isMobile ? '0' : undefined), [Validators.required, Validators.min(0.01)]),
      taxCity: new FormControl(this.data.taxCity ? this.data.taxCity : (this.isMobile ? '0' : null), []),
      taxInternationalCity: new FormControl(this.data.taxInternationalCity ? this.data.taxInternationalCity : '', [Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')]),
      taxRegion: new FormControl(this.data.taxRegion ? String((this.data.taxRegion < 10) ? `0${Number(this.data.taxRegion)}` : this.data.taxRegion) : (this.isMobile ? '0' : null), []),
      taxInternationalRegion: new FormControl(this.data.taxInternationalRegion ? this.data.taxInternationalRegion : '', [Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')])
    });
    this.formControlKeys = Object.keys(this.companyAddressForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    this.isNationalAddress = (Number(this.companyAddressForm.value.taxCountry) === 108);
    if (Number(this.data.taxCountry) === 108 && this.data.taxRegion) {
      if (this.towns) {
        this.towns.getCities(108, this.companyAddressForm.value.taxRegion).subscribe(resp => {
          this.citiesList = resp;
          this.chdRef.detectChanges();
          this.getInfo();
        });
      }
    }
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getInfo() {
    if (this.companyAddressForm.valid) {
      this.sendInfoEvent.emit(this.companyAddressForm.value);
    } else {
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  changeRegion() {
    this.companyAddressForm.controls['taxCity'].setValue(this.isMobile ? '0' : null);
    if (this.towns) {
      this.towns.getCities(108, this.companyAddressForm.value.taxRegion).subscribe(resp => {
        this.citiesList = resp;
        this.chdRef.detectChanges();
        this.getInfo();
      });
    }
  }

  changeCountry() {
    if (Number(this.companyAddressForm.value.taxCountry) === 108) {
      this.isNationalAddress = true;
      this.companyAddressForm.controls['taxPostalCode'].setValidators([Validators.required, Validators.pattern('^[0123456789]+$')]);
      this.companyAddressForm.controls['taxRegion'].setValidators([Validators.required, Validators.min(0.01)]);
      this.companyAddressForm.controls['taxCity'].setValidators([Validators.required, Validators.min(0.01)]);
      this.companyAddressForm.controls['taxInternationalRegion'].setValidators([]);
      this.companyAddressForm.controls['taxInternationalCity'].setValidators([]);
    } else {
      this.isNationalAddress = false;
      this.companyAddressForm.controls['taxPostalCode'].setValidators([Validators.required, Validators.pattern('^[A-Za-z0123456789]+$')]);
      this.companyAddressForm.controls['taxRegion'].setValidators([]);
      this.companyAddressForm.controls['taxCity'].setValidators([]);
      this.companyAddressForm.controls['taxInternationalRegion'].setValidators([Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]);
      this.companyAddressForm.controls['taxInternationalCity'].setValidators([Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]);
      setTimeout(() => {
        document.getElementById('taxInternationalRegion').focus();
      }, 150);
    }
    this.chdRef.detectChanges();
    this.getInfo();
  }

  getCountryList() {
    this.nationalityService.getNations().subscribe(
      (response) => {
        let nations: NationModel[] = response;
        for (let nation of nations) {
          if (nation.code === 108) {
            this.countriesList.unshift({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          } else {
            this.countriesList.push({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          }
        }
        this.chdRef.detectChanges();
      }
    );
    /* this.translate.get('COUNTRY').subscribe(
      (response) => {
        const num = Object.keys(response).length;
        for (let i = 0; i < num; i++) {
          const code = Object.keys(response)[i];
          this.translate.get('COUNTRY.' + code).subscribe(
            (response) => {
              const country = response;
              const countryList = {
                id: Number(code),
                name: country
              };
              countryList.id === 108 ? this.countriesList.unshift(countryList) : this.countriesList.push(countryList);
            }
          );
        }
      }
    );
    const spain = this.countriesList.find((element) => element.id === 108);
    this.countriesList.splice(0, 1);
    this.countriesList.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    this.countriesList.unshift(spain); */
  }

}
