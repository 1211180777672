import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'fun-skeleton-contact-data',
  templateUrl: './skeleton-contact-data.component.html',
  styleUrls: ['./skeleton-contact-data.component.scss']
})
export class SkeletonContactDataComponent {


}
