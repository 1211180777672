<div class="container" [formGroup]='parentForm'>
  <input type="text" maxlength="6" class="defaultCode" #code1 id="code1" (blur)="validate()" (keydown)="next(code1, $event)"
  (paste)="validate(); pasteCode();" (keyup)="nextFocus(code1, $event); validate()" formControlName="code1"
  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
  <input type="text" maxlength="1" class="defaultCode" #code2 id="code2" (blur)="validate()" (keydown)="next(code2, $event)"
  (paste)="validate()" (keyup)="nextFocus(code2, $event); validate()" formControlName="code2"
  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
  <input type="text" maxlength="1" class="defaultCode" #code3 id="code3"
  (paste)="validate()" (keyup)="validate()" (blur)="validate()" formControlName="code3"
  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);">
</div>
