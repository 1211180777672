<div class="general-container">
  <div>
    <div class="header">
      <h2>{{'REGISTER.MANGOPAY.TERMS_TITLE' | translate}}</h2>
      <p>{{'REGISTER.MANGOPAY.TERMS_SUBTITLE_2' | translate}}</p>
      <p>{{'REGISTER.MANGOPAY.TERMS_SUBTITLE' | translate}}</p>
    </div>
    <div class="content">
      <div class="step-content">
        <div class="stp">
          <h4>{{'REGISTER.MANGOPAY.TERMS_STP_1_TITLE' | translate}}</h4>
          <p>{{'REGISTER.MANGOPAY.TERMS_STP_1_SUBTITLE' | translate}}</p>
        </div>
        <div class="stp">
          <h4>{{'REGISTER.MANGOPAY.TERMS_STP_2_TITLE' | translate}}</h4>
          <p>{{'REGISTER.MANGOPAY.TERMS_STP_2_SUBTITLE' | translate}}</p>
        </div>
        <div class="stp">
          <h4>{{'REGISTER.MANGOPAY.TERMS_STP_3_TITLE' | translate}}</h4>
          <p>{{'REGISTER.MANGOPAY.TERMS_STP_3_SUBTITLE' | translate}}</p>
        </div>
      </div>
      <p class="risk-txt">{{ 'REGISTER.MANGOPAY.TERMS_TXT' | translate}}</p>
      <div class="check-container">
        <div class="check" [ngClass]="{'checked': checked === true }" (click)="checkaction()"></div>
        <p>{{'REGISTER.MANGOPAY.TERMS_CHECK_1' | translate}} <span (click)="openMangopayTerms()">{{'REGISTER.MANGOPAY.TERMS_CHECK_2' | translate}}</span> {{'REGISTER.MANGOPAY.TERMS_CHECK_3' | translate}}</p>
      </div>
    </div>
  </div>
</div>