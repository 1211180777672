<form [formGroup]="dataForm">
    <div class="container" *ngIf="stateValue == 0">
        <div class="row">
            <ul class="pagination-tab show-all children:font-sans children:text-xl">
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.investment' | translate }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                </li>
                <li>
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
    </div>

    <div class="container" *ngIf="stateValue == 1">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.investment' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal" *ngIf="dataProjectList.length">
                <div class="w-full gap-24 flex flex-col md:flex-row items-center justify-cente">
                    <div class="select form-input">
                        <fun-input
                            *ngIf="(detectOS() !== 'iOS' && detectOS() !== 'Android')"
                            placeholder="{{'offer-create.label-select-project' | translate}}"
                            [parentForm]="dataForm"
                            [formControlKeys]="formControlKeys"
                            [errorMessages]="errorMessages"
                            [isSubmit]="isSubmit"
                            controlName="projectId"
                            type="select"
                            [errorLogin]="errorAmount"
                            labelName="{{'offer-create.label-select-project' | translate}}"
                            (selectChange)="onProjectIdSelectChange($event)"
                            [items]="dataProjectList"
                            [selectedItem]="defaultProject.id"
                        ></fun-input>
                        <form *ngIf="(detectOS() === 'iOS' || detectOS() === 'Android')" action="" [formGroup]="dataForm">
                            <div class="inp select form-input">
                                <label>{{'offer-create.label-select-project' | translate}}</label>
                                <select class="select-box" [ngClass]="{'option': dataForm.controls['projectId'].value > 0}"
                                name="projectId" formControlName="projectId" (change)="onProjectIdSelectChange($event)">
                                    <option hidden [value]="0">{{'offer-create.label-select-project' | translate}}</option>
                                    <option *ngFor="let project of dataProjectList"
                                    [value]="project.id">
                                        {{ project.name }}
                                    </option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <p class="font-sans text-eggplant-100/[0.55] text-m md:pt-16">{{ 'offer-create.description-step-one' | translate }}</p>
                </div>

                <div class="radiobutton check-style form-input" *ngIf="dataList.length">
                    <p class="font-sans text-m text-eggplant-100/[0.55]">{{ 'offer-create.label-select-stock-package' | translate }}</p>
                    <!--De aqui-->
                    <div class="packaje-container">
                        <label *ngFor="let dataItem of dataList">
                            <input type="radio" class="card-input-element" name="stockPackageId" formControlName="stockPackageId" [value]="dataItem.id" (click)="setForm(dataItem)" />

                            <div class="panel panel-default card-input">
                                <div class="px-16 py-24">
                                    <div class="panel-heading"><h3 class="font-sans text-eggplant-100 text-xl">{{ dataItem.stocks | localeNumber }} {{ 'card.shares' | translate }}</h3></div>
                                    <div class="panel-body">
                                    <ul class="table-check">
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.price' | translate }}</span>
                                            <span class="text-l text-eggplant-80" *ngIf="dataItem.adquisition_price > 100">{{ dataItem.adquisition_price | number:'1.0-0':'es' }} €</span>
                                            <span class="text-l text-eggplant-80" *ngIf="dataItem.adquisition_price < 100">{{ dataItem.adquisition_price | localeNumber }} €</span>
                                        </li>
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.date' | translate }}</span>
                                            <span class="text-l text-eggplant-80">{{ dataItem.msDate | date:'dd/MM/yyyy' }}</span>
                                        </li>
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.phase' | translate }}</span>
                                            <span class="text-l text-eggplant-80">{{ getStockPackageTypeValue(dataItem.type) | titlecase }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>

                    <!---->
                </div>
                <div class="radiobutton check-style form-input" *ngIf="!dataList.length">
                    <p>{{ 'offer-create.error-no-stocks-packages' | translate }}</p>
                </div>
            </div>
            <div class="content-background-modal" *ngIf="!dataProjectList.length">
                <p class="description font-sans text-eggplant-100 text-l">{{ 'offer-create.error-no-projects' | translate }}</p>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="stateValue == 2">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.investment' | translate }}</span>
                    <span class="mark-orange text-eggplant-100/[0.2]">{{ dataForm.get('projectName').value }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal row">
                <p class="description col-6 font-sans text-eggplant-100/[0.55] text-m">{{ 'offer-create.description-step-two' | translate }}</p>
                <div class="flex flex-col md:flex-row gap-24">
                    <div class="form-input input col-3 pt-[18px]">
                        <p for="amount">{{ 'offer-create.label-input-amount' | translate }}</p>
                        <fun-input
                        placeholder="{{ dataForm.get('stocks').value | localeNumber }} {{ 'card.shares' | translate }}"
                        [parentForm]="dataForm"
                        controlName="amount"
                        type="number"
                      ></fun-input>
                    </div>
                    <div class="radiobutton check-style form-input col-5">
                        <p>{{ 'offer-create.label-stock-shares-selected' | translate }}</p>
                        <label>
                            <input disabled type="radio" class="card-input-element disabled" />
                            <div class="panel panel-default card-input" *ngIf="dataForm.get('stockPackageId')">
                                <div class="px-16 py-24">
                                    <div class="panel-heading"><h3 class="font-sans text-eggplant-100 text-xl">{{ dataForm.get('stocks').value | localeNumber }} {{ 'card.shares' | translate }}</h3></div>
                                    <div class="panel-body">
                                    <ul class="table-check">
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.price' | translate }}</span>
                                            <span class="text-l text-eggplant-80" *ngIf="dataForm.get('adquisitionPrice').value > 100">{{ dataForm.get('adquisitionPrice').value | number:'1.0-0':'es' }} </span>
                                            <span class="text-l text-eggplant-80" *ngIf="dataForm.get('adquisitionPrice').value < 100">{{ dataForm.get('adquisitionPrice').value | localeNumber }} €</span>
                                        </li>
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.date' | translate }}</span>
                                            <span class="text-l text-eggplant-80">{{ dateMls | date:'dd/MM/yyyy' }}</span>
                                        </li>
                                        <li class="font-sans">
                                            <span class="text-m text-eggplant-100/[0.55]">{{ 'offer-create.phase' | translate }}</span>
                                            <span class="text-l text-eggplant-80">{{ packageTypes.get(dataForm.get('type').value) | titlecase }}</span>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf="stateValue == 3">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.investment' | translate }}</span>
                    <span class="mark-orange text-eggplant-100/[0.2]">{{ dataForm.get('projectName').value }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                    <span class="mark text-eggplant-100/[0.2]">{{ dataForm.get('amount').value | localeNumber }} {{ 'card.shares' | translate }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                </li>
                <li class="future">
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal row flex flex-col md:flex-row gap-24">
                <div class="col-12 range">
                    <div class="row time-range">
                        <div class="w-full">
                            <p class="font-sans text-eggplant-100/[0.55] text-m">{{ 'offer-create.time' | translate }}</p>
                        </div>
                        <div class="w-full bar-range">
                                <ion-range min="15" max="30" pin="true" [value]="rangeValue" (ionChange)="changeDataRange($event)"> <!--[(ngModel)]="value"-->
                                </ion-range>
                                <label class="first">15</label>
                                <label class="second">30</label>
                        </div>
                    </div>
                </div>
                <!-- <p class="description col-12 font-sans text-eggplant-100 text-m">{{ 'offer-create.description-step-three' | translate }}</p> -->
                <div class="radiobutton check-style form-input col-12">
                    <p>{{ 'offer-create.label-formalization-way' | translate }}</p>
                    <label>
                        <input type="radio" class="card-input-element" name="formalizationType" formControlName="formalizationType" [value]="formalizationTypes.get('external')" [checked]="dataForm.get('formalizationType').value === formalizationTypes.get('external') ? 'true' : null "/>
                        <div class="panel panel-default card-input lst-conditions">
                            <div class="px-16 py-24">
                                <div class="panel-heading"><h3 class="font-sans text-eggplant-100 text-xl">{{ 'offer-create.external-way' | translate }}</h3></div>
                                <div class="panel-body">
                                    <ul class="list-check">
                                        <li>{{ 'offer-create.formalization-external-way-one' | translate }}</li>
                                        <li>{{ 'offer-create.formalization-external-way-two' | translate }}</li>
                                        <li>{{ 'offer-create.formalization-external-way-three' | translate }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

        </div>
    </div>

    <div class="container" *ngIf="stateValue == 4">
        <div class="row">
            <ul class="pagination-tab children:font-sans children:text-xl">
                <li class=" text-eggplant-100/[0.2]">
                    <span class="number text-white bg-eggplant-100/[0.2]">1</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.investment' | translate }}</span>
                    <span class="mark-orange text-eggplant-100/[0.2]">{{ dataForm.get('projectName').value }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                    <span class="mark text-eggplant-100/[0.2]">{{ dataForm.get('amount').value | localeNumber }} {{ 'card.shares' | translate }}</span>
                </li>
                <li class="done">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                    <span class="mark text-eggplant-100/[0.2]" *ngIf="dataForm.get('formalizationType').value === 'internal'">{{ 'offer-create.internal-way' | translate }}</span>
                    <span class="mark text-eggplant-100/[0.2]" *ngIf="dataForm.get('formalizationType').value === 'external'">{{ 'offer-create.external-way' | translate }}</span>
                </li>
                <li class="selected">
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="row">
            <div class="content-background-modal row">
                <p class="description col-12 font-sans text-eggplant-100/[0.55] text-m">{{ 'offer-create.description-step-four' | translate }}</p>
                <ul class="list-confirm">
                    <li class="font-sans text-xl text-eggplant-100">
                        <span>{{ 'offer-create.project' | translate }}</span>
                        <span class="text-eggplant-100/[0.55] pl-12">{{ dataForm.get('projectName').value }}</span>
                    </li>
                    <li class="font-sans text-xl text-eggplant-100">
                        <span>{{ 'offer-create.amount' | translate }}</span>
                        <span class="text-eggplant-100/[0.55] pl-12">{{ dataForm.get('amount').value | localeNumber }} {{ 'card.shares' | translate }}</span>
                    </li>
                    <li class="font-sans text-xl text-eggplant-100">
                        <span>{{ 'offer-create.formalization-way' | translate }}</span>
                        <span class="mark text-eggplant-100/[0.55] pl-12" *ngIf="dataForm.get('formalizationType').value === 'internal'">{{ 'offer-create.internal-way' | translate }}</span>
                        <span class="mark text-eggplant-100/[0.55] pl-12" *ngIf="dataForm.get('formalizationType').value === 'external'">{{ 'offer-create.external-way' | translate }}</span>
                    </li>
                    <li class="font-sans text-xl text-eggplant-100">
                        <span>{{ 'offer-create.closing-date' | translate }}</span>
                        <span class="text-eggplant-100/[0.55] pl-12">{{ closDateM | date:'dd/MM/yyyy' }}</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="container final-step" *ngIf="stateValue == 5">
        <div class="row">
            <div class="content-background-modal row offer-create-txt">
                <h3>{{ 'offer-create.title-step-five' | translate }}</h3>
                <p>{{ 'offer-create.description-step-five' | translate }}</p>
            </div>
            <div class="container">
                <div class="last-step-btn-container">
                    <fun-new-button [classExtra]="' !w-full '" buttonType="secondaryDark" title="{{ 'offer-create.goto-portfolio' | translate }}" class="button" (eventClick)="onEventClickRoute('portfolio')"></fun-new-button>
                    <fun-new-button [classExtra]="' !w-full '" buttonType="primaryDark" title="{{ 'offer-create.goto-marketplace' | translate }}" class="button" (eventClick)="onEventClickRoute('list-of-projects')"></fun-new-button>
                </div>
            </div>
        </div>
    </div>

    <div class="container-footer">
        <div class="container grid md:flex gap-24 justify-between items-center">
            <fun-new-button [classExtra]="' !w-full '" [buttonType]="backBtn" title="{{ 'btn.back' | translate }}" class="button" (eventClick)="prevStep()"></fun-new-button> <!--Este es el que deshabilito-->
            <fun-new-button [classExtra]="' !w-full '" *ngIf="stateValue !== 4" [buttonType]="buttonType" title="{{ 'card.continue' | translate }}" class="button" (eventClick)="nextStep()"></fun-new-button>
            <fun-new-button [classExtra]="' !w-full '" *ngIf="stateValue === 4" [buttonType]="isSubmit" title="{{ 'card.continue' | translate }}" class="submit" (eventClick)="submitForm()"></fun-new-button>
        </div>
    </div>

    <div class="container hidden-lg">
        <div class="row">
            <ul class="pagination-tab show-all children:font-sans children:text-xl">
                <li *ngIf="stateValue == 1" (click)="nextStep()">
                    <span class="number text-white bg-eggplant-100/[0.2]">2</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.amount' | translate }}</span>
                </li>
                <li *ngIf="stateValue == 2" (click)="nextStep()">
                    <span class="number text-white bg-eggplant-100/[0.2]">3</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.formalization' | translate }}</span>
                </li>
                <li *ngIf="stateValue == 3" (click)="nextStep()">
                    <span class="number text-white bg-eggplant-100/[0.2]">4</span>
                    <span class="title text-eggplant-100/[0.2]">{{ 'offer-create.confirmation' | translate }}</span>
                </li>
            </ul>
        </div>
    </div>
</form>