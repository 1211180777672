<div class="general-container">
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'input'">{{ 'warning.text-1' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === undefined">{{ 'warning.text-2' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'invest'">{{ 'warning.text-3' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'bid-status'">{{ 'warning.text-4' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'offer-status' && txtOffer === undefined">{{ 'warning.text-5' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'offer-status' && txtOffer !== undefined">{{ 'warning.text-6' | translate }}</p>
    <p class="font-sans text-eggplant-100 text-l" *ngIf="place === 'accept-bid'">{{ text }}</p>
    <div class="md:flex gap-12">
        <div class="btn-container">
            <fun-new-button [size]="'xl'" *ngIf="place !== 'invest'" [buttonType]="btnType" [title]="buttonTitle" class="button" (eventClick)="continue()" [classExtra]="' !w-full '"></fun-new-button>
            <fun-new-button [size]="'xl'" *ngIf="place === 'invest'" buttonType="secondaryDark" title="{{ 'warning.back' | translate }}" class="button" (eventClick)="close()" [classExtra]="' !w-full '"></fun-new-button>
        </div>
        <div class="btn-container">
            <fun-new-button [size]="'xl'" *ngIf="place !== 'invest'" buttonType="secondaryDark" title="{{ 'warning.back' | translate }}" class="button" (eventClick)="close()" [classExtra]="' !w-full '"></fun-new-button>
            <fun-new-button [size]="'xl'" *ngIf="place === 'invest'" buttonType="primaryLight" title="{{ 'warning.cancel-invest' | translate }}" class="button" (eventClick)="continue()" [classExtra]="' !w-full '"></fun-new-button>
        </div>
    </div>
</div>
