import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-automatic-invest',
  templateUrl: './automatic-invest.component.html',
  styleUrls: ['./automatic-invest.component.scss'],
})
export class AutomaticInvestComponent implements OnInit {

  public investForm: FormGroup;
  public ratingForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;

  step = 1;
  questions = [
    { text: this.translate.instant('HELP.AUTOMATIC_INVEST.Q1.TITLE'), index: 1 },
    { text: this.translate.instant('HELP.AUTOMATIC_INVEST.Q2.TITLE'), index: 2 },
    { text: this.translate.instant('HELP.AUTOMATIC_INVEST.Q3.TITLE'), index: 3 },
  ];
  prevBtn = 'secondary-disabled';

  infData = {
    profEstim: [{data: '5%', selected: false}, {data: '7%', selected: true}, {data: '>7%', selected: false}],
    reinvestMoney: false,
    investAnyRating: false,
    investAnyProf: false,
    investAnyDistrib: false,
    distribType: [
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.CONSUM'), selected: true},
      {data: 'Red', selected: false}
    ],
    investFrecuency: [
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.MONTHLY'), selected: false},
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.QUARTERLY'), selected: true},
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.ANNUAL'), selected: false}
    ],
    investType: [
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_1'), selected: true},
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_5'), selected: false},
      {data: this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_10'), selected: false}
    ],
    investPeriod: [
      {data: `<${this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_10')}`, selected: true},
      {data: `<${this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_20')}`, selected: false},
      {data: `>${this.translate.instant('AUTOINVEST.DYNAMICS.YEAR_20')}`, selected: false}
    ],
    paymentType: [
      {
        data: 'bank',
        selected: true,
        title: this.translate.instant('AUTOINVEST.DYNAMICS.BANK_CARD'),
        subtitle: this.translate.instant('AUTOINVEST.DYNAMICS.BANK_TXT')
      },
      {
        data: 'transfer',
        selected: false,
        title: 'Wallet',
        subtitle: this.translate.instant('AUTOINVEST.DYNAMICS.WALLET_TXT')
      }
    ],
    getBenefictsRec: false,
    termsandConditions: false
  };

  strategyData: any;
  profselected: string;
  capital: string;
  distribution: string;
  investTime: string;
  investFrecuency: string;
  totalInvest = '0';

  image = 6;
  text = this.translate.instant('AUTOINVEST.DYNAMICS.FINISH_MSG');


  constructor(private helpService: HelpService, private translate: TranslateService, public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.investForm = new FormGroup({
      annualInvest: new FormControl('', [Validators.required])
    });
    this.ratingForm = new FormGroup({
      aType: new FormControl('', []),
      bType: new FormControl('', []),
      cType: new FormControl('', [])
    });
    this.getStrategydata();
    this.chdRef.detectChanges();
  }

  getInvestValue() {
    this.totalInvest = this.investForm.controls.annualInvest.value;
    this.chdRef.detectChanges();
  }

  questionClicked(index) {
    this.helpService.openHelp('second', index, 'AUTOMATIC_INVEST');
    this.chdRef.detectChanges();
  }

  prev() {
    if (this.step > 1) {
      this.step--;
      this.prevBtn = 'secondary';
    }
    if (this.step === 1) {
      this.prevBtn = 'secondary-disabled';
    }
    this.chdRef.detectChanges();
  }

  next() {
    this.step++;
    this.prevBtn = 'secondary';
    this.chdRef.detectChanges();
  }

  goToStep(stp) {
    this.step = stp;
    this.prevBtn = 'secondary';
    this.chdRef.detectChanges();
  }

  selectType(arr, param) {
    this.infData[arr].forEach(element => {
      element.selected = (element.data === param) ? true : false;
    });
    this.getStrategydata();
    this.chdRef.detectChanges();
  }

  selectChecks(ckeck) {
    this.infData[ckeck] = !this.infData[ckeck];
    this.getStrategydata();
    this.chdRef.detectChanges();
  }

  getStrategydata() {

   this.infData.profEstim.forEach(element => {
     if (element.selected) {
       this.profselected = element.data;
     }
   });
   this.infData.distribType.forEach(element => {
    if (element.selected) {
      this.distribution = element.data;
    }
   });
   this.infData.investType.forEach(element => {
    if (element.selected) {
      this.investTime = element.data;
    }
   });
   this.infData.investFrecuency.forEach(element => {
    if (element.selected) {
      this.investFrecuency = element.data;
    }
   });
  }

}
