import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'fun-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss']
})
export class SkeletonCardComponent {


}
