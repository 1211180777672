<div class="files">
  <ion-slides class="rounded-2xl" pager="false" [options]="slideOpts" [scrollbar]="false" #sliderImg (ionSlideDidChange)="getCurrentItem()">
    <ion-slide *ngFor="let file of imageList">
        <div class="file" *ngIf="file.tipo === 1 && file.URL" [ngStyle]="{'background':' url(' + file.URL + ')', 'background-size': 'cover',
        'background-position': 'center'}"></div>
        <div class="file" *ngIf="file.tipo === 1 && file.url" [ngStyle]="{'background':' url(' + file.url + ')',
        'background-size': 'cover', 'background-position': 'center'}"></div>
    </ion-slide>
  </ion-slides>

  <div *ngIf="imageList.length > 1" class="arrow arrow-left" (click)="prev()"
      [ngClass]="{'arrow-disabled': sliderIndex === 0 }">
      <img class="transform rotate-[-90deg]" src="../../../../../assets/icons/arrow-up-white.svg" alt="arrow-green">
  </div>
  <div *ngIf="imageList.length > 1" class="arrow arrow-right" (click)="next()"
      [ngClass]="{'arrow-disabled': sliderIndex === imageList.length - 1 }">
      <img class="transform rotate-90" src="../../../../../assets/icons/arrow-up-white.svg" alt="arrow-green">
  </div>

</div>