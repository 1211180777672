<div class="general-container">
    <div class="flex">
        <p class="hover:cursor-pointer font-sans underline underline-offset-2 text-l text-eggplant-100" (click)="toggleMenu()">{{ currentItemName }}</p>
        <img class="hover:cursor-pointer" src="../../../../assets/icons/down-disabled.svg" [ngClass]="{'up-content': opened, 'down-content': !opened }" (click)="toggleMenu()">
    </div>
    <div class="submenu-notifications submenu-transition absolute mt-8 px-[24px] right-0 rounded-2xl bg-white"
    [ngClass]="{'openMenu': opened, 'closeMenu': !opened }" id="content_filter">
        <p class="py-12 hover:cursor-pointer font-sans text-l text-eggplant-100/[0.55] hover:underline hover:underline-offset-2"
        [ngClass]="{ selected: itemSelected === item }" *ngFor="let item of items" (click)="selectItem(item)">{{ item }}</p>
    </div>
</div>
