<div class="general-container">
  <h2>Comisiones de Fundeen</h2>
  <div class="commissions-list-container">
    <p class="substitle">Estas son las comisiones que cobra Fundeen en caso de que el proyecto se financie. Te contamos un poco sobre cada comisión:</p>
    <div class="commissions-text">
      <ul>
        <li><span>Comisión de estructuración:</span> Suele estar entre un 2-5% del importe total de financiación y varía en función de la complejidad del proyecto</li>
        <li><span>Comisión de gestión:</span> Suele estar entre un 2-5% del importe total de financiación. No todos los proyectos cuentan con ella y se cobra anualmente de manera recurrente</li>
      </ul>
      <div class="blur-container"></div>
    </div>
    <p class="view-more">Ver más</p>
  </div>

  <div class="commissions-table">
    <div class="row-content row-title">
      <p>Comisión</p>
      <p>Porcentaje</p>
      <p>Total</p>
    </div>
    <div class="row-content row-texts">
      <p>Estructuración</p>
      <p>3%</p>
      <p>55.000€</p>
    </div>
    <div class="row-content row-texts">
      <p>Gestión</p>
      <p>2%</p>
      <p>25000€</p>
    </div>
    <div class="row-content row-texts">
      <p>Otros servicios</p>
      <p>-</p>
      <p>4000€</p>
    </div>
    <div class="row-content row-total">
      <p>Total</p>
      <p>-</p>
      <p>84000€</p>
    </div>
  </div>
</div>