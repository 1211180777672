<div class="summary">
  <div class="rounded-xl summary_content">
      <h3 class="font-sans text-h600 text-eggplant-100/[0.25]">{{ 'OVERVIEW.DOCUMENTATION.SUMMARY' | translate }}</h3>
      <div class="summary_container">
        <div class="subtitle_container">
          <p class="subtitle_static text-eggplant-100 text-xl md:text-h400 font-sans">{{ 'OVERVIEW.DOCUMENTATION.SUMMARY_SUBTITLE' | translate }}</p>
        </div>
        <div class="subtitle_container" *ngIf="hasSection">
          <div class="section-info" id="sectionInfo" [innerHTML]="sectionInfo"  [ngClass]="{'hideSection': !viewMore, 'openSection': viewMore }"></div>
          <div *ngIf="!viewMoreBlur" class="blur-content"></div>
          <h5 (click)="toggleSection()"> <span *ngIf="!viewMore">{{ 'INFO.BTN_MSG_BALEARES' | translate }}</span><span *ngIf="viewMore">{{ 'INFO.BTN_MSG_LESS' | translate }}</span> </h5>
        </div>

        <div *ngFor="let element of documents" class="summary-c border-t border-t-eggplant-100/[0.25]">
          <div class="more-btn" (click)="openContent(element.index)">
            <div class="sp1"></div>
            <div class="sp2"  [ngClass]="{'rotate2': element.opened, 'rotate1': !element.opened }"></div>
          </div>
          <h4 (click)="openContent(element.index)" class="font-sans text-eggplant-100 text-l">{{ element.title }}</h4>
          <div class="summary_text" [id]="element.idContent" [innerHTML]="element.content"
          [ngClass]="{'hideContent': !element.opened, 'showContent': element.opened }"></div>
        </div>
      </div>
  </div>
</div>