import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'fun-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  lngList = [
    {
      ln: 'es',
      text: 'Español',
      selected: false
    },
    {
      ln: 'en',
      text: 'English',
      selected: false
    },
    {
      ln: 'ca',
      text: 'Català',
      selected: false
    }
  ];

  platform: string;

  constructor(public translate: TranslateService, public languageService: LanguageService) { }

  ngOnInit() {
    for (let i = 0; i < this.lngList.length; i++) {
      if (this.lngList[i].ln === this.translate.currentLang) {
        this.lngList[i].selected = true;
      } else {
        this.lngList[i].selected = false;
      }
    }
    this.platform = Capacitor.getPlatform();
  }

  changeLn(ln) {
    this.languageService.setLanguage(ln);
  }

  openLegal(slug) {
    window.open(`https://www.fundeen.com/legal/${slug}`);
  }
}
