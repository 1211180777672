import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-filter-mobile',
  templateUrl: './filter-mobile.component.html',
  styleUrls: ['./filter-mobile.component.scss']
})
export class FilterMobileComponent implements OnInit {

  @Output() public openMobFilterEvent = new EventEmitter<any>();
  @Output() public changeMobileFiltersEvent = new EventEmitter<any>();
  @Output() public closeEvent = new EventEmitter<any>();
  @Input() filterList: any;

  filterModel = [];
  loading = false;

  openedFilterIndex;
  mobileFilters: number;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.initFilters();
  }

  initFilters() {
    this.filterModel = [];
    this.filterList.forEach((element, i) => {
      this.filterModel.push({ title: element.title, id: element.id, values: []});
      element.list.forEach(option => {
        this.filterModel[i].values.push({name: option, checked: false});
      });
    });
    this.mobileFilters = 0;
  }

  elementSelected(filterIndex, optionIndex, checked) {
    this.filterModel[filterIndex].values[optionIndex].checked = checked;
    checked ? this.mobileFilters++ : this.mobileFilters--;
    this.changeMobileFiltersEvent.emit({mobileFilters: this.filterModel, mobileCount: this.mobileFilters});
  }

  openFilter(index: number) {
    this.openedFilterIndex !== index ? this.openedFilterIndex = index : this.openedFilterIndex = undefined;
  }

  deleteFilters() {
    this.initFilters();
    this.changeMobileFiltersEvent.emit({mobileFilters: this.filterModel, mobileCount: this.mobileFilters});
  }

  closeFilter() {
    this.closeEvent.emit();
  }

}
