import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'fun-register-person-name',
  templateUrl: './register-person-name.component.html',
  styleUrls: ['./register-person-name.component.scss']
})
export class RegisterPersonNameComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;

  public nameForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public notFullSurname = false;
  public isFocusLost = false;

  constructor(public chdRef: ChangeDetectorRef, public translate: TranslateService, private dateService: DateService) {
    this.nameForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚÇçóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')]),
      surname: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚÇçóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀÉÈËÊĘĖĒíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')]),
      date: new FormControl('', [Validators.required]),
    });
    this.formControlKeys = Object.keys(this.nameForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
  }

  @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    this.getInfo();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
  }

  ngOnInit() {
    this.chdRef.detectChanges();
  }

  getInfo(submit?: boolean) {
    if (this.nameForm.valid) {
      const model = {
        name: this.nameForm.value.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        surname: this.nameForm.value.surname.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        date: this.nameForm.value.date//.format('DD/MM/YYYY')
      };
      this.sendInfoEvent.emit(model);
      if (submit === true) { this.submitEvent.emit(); }
    } else {
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  focusLost() {
    this.isFocusLost = true;
    if (this.nameForm.value.surname !== undefined && this.isFocusLost) {
      if (this.nameForm.value.surname.includes(' ')) {
        this.notFullSurname = false;
      } else {
        this.notFullSurname = true;
      }
    }
    this.chdRef.detectChanges();
  }

}
