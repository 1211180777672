import { Injectable, Inject } from '@angular/core';
//import { RollbarService } from '../shared/other/rollbar';

// import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// import { HttpErrorResponse } from '@angular/common/http';
// import { environment } from '../../environments/environment';
// import { Storage } from '@ionic/storage';
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.
//const sendLogs = environment.rollbarToken;

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(
    //@Inject(RollbarService) private rollbar: Rollbar, private storage: Storage
    ) { }

  rollbarInfo(msg) {
    // Needs the rollbar object from the constructor.
    // if (sendLogs) {
    //   this.rollbar.info(msg);
    // }
  }

  throwError(error) {
    // Does not need the rollbar object from the constructor,
    // and will still log to Rollbar.
    // throw new Error(error);
    // let errorMsg: any;
    // if (error instanceof HttpErrorResponse) {
    //   errorMsg = `Status code: ${error.status}. Error body: ${error.message}`;
    // } else if (error instanceof TypeError) {
    //   errorMsg = `There was a Type error.: ${error.message}.`;
    // } else if (error instanceof Error) {
    //   errorMsg = `There was a general error: ${error}.`;
    // } else {
    //   errorMsg = `Other type of error: ${error}.`;
    // }
    // if (sendLogs) {
    //   this.storage.get('TRACKINGAUTH').then((val) => {
    //     if (val) {
    //       this.rollbar.error(errorMsg);
    //     }
    //   });
    // }
  }
}