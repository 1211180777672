<div class="general-container" [ngClass]="{ limitHeight: maxHeight === true }">
  <fun-spinner *ngIf="loading" ></fun-spinner>
    <pdf-viewer *ngIf="file !== undefined" #viewer
              [src]="file"
              [render-text]="true"
              style="display: block;"
              [zoom]="zoomNumber"
              [zoom-scale]="'page-width'"
              [original-size]="false"
              [autoresize]="true"
              (after-load-complete)="callBackFn($event)"
              (error)="onError($event)"
              (pagechanging)="pagechanging($event)"
              [ngClass]="{ limitHeight: maxHeight === true }"
              (after-load-complete)="afterLoadComplete($event)"
  ></pdf-viewer>
  <div class="images" *ngIf="imageList !== undefined">
    <div *ngFor="let image of imageList">
      <img [src]="image">
    </div>
  </div>
</div>
