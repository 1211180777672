import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef } from '@angular/core';
import * as momentNs from 'moment';
import { Observable } from 'rxjs';
const moment = momentNs;
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MarketPlaceMyOffersModel } from 'src/app/shared/models/marketplace.models';
import { ToastService } from '../../toast/toast.service';
import { DateService } from 'src/app/services/date.service';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'fun-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {

  @Output() private emitOfferDetails = new EventEmitter<MarketPlaceMyOffersModel>();
  @Output() private eventClickCreateOffer = new EventEmitter<void>();
  @Output() public eventButtonsRoute = new EventEmitter<string>();
  @Input() dataList$: Observable<MarketPlaceMyOffersModel[]>;
  @Input() hasWalletOk: boolean;

  totalNomberGroup: number;

  dataList: MarketPlaceMyOffersModel[];
  dataListFiltered: MarketPlaceMyOffersModel[];
  isShow = false;
  idSelected: number;
  filterType: string;

  totalN: number;
  activeN: number;
  soldN: number;
  notsoldN: number;
  pendingN: number;
  isEmpty = false;
  showMsg: boolean;
  notificationsNumber: number;

  orderBestShare = false;
  orderBestPrice = false;

  projectList = [];
  projectListOpen = false;
  filterByP = '';

  statusList = [{name: this.translate.instant('movements.all'), id: 'all'}];
  statusOpen = false;
  filterByS = '';
  btnType: string = 'primaryDark-disabled';
  loaded = false;

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private dateService: DateService,
    private marketplceService: MarketplaceService,
    private storage: Storage
  ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.showMsg = false;
    this.getData();

    this.storage.get('WALLET').then((val) => {
      if (val !== null && val !== undefined && val.walletstatus === 0) {
        this.hasWalletOk = true;
        this.btnType = 'primaryDark';
      } else {
        this.hasWalletOk = false;
        this.btnType = 'primaryDark-disabled';
      }
    });
  }
  public getData() {
    this.notificationsNumber = 0;
    this.loaded = false;
    const numberGroup = [0, 0, 0];

    this.dataList$.subscribe({
      next: data => {
        this.loaded = true;
        this.dataList = data;
        this.getInteractiveBids(data);
        this.dataListFiltered = [...this.dataList];
        this.getProjectList();
        this.dataListFiltered.forEach(element => {
          element.tagName = this.getLostTagname(element);
          if (element.closing_date !== undefined) {
            element.closing_date_msg = this.dateService.getDateMs(element.closing_date);
            element.decision_due_date = this.dateService.getDateMs(element.decision_due_date);
          }
        });
        this.totalN = this.dataList.length;
        this.activeN = this.dataList.filter(item => item.status === 'active' || item.status === 'pending_acceptation').length;
        this.pendingN = this.dataList.filter(item => item.status === 'pending_acceptation').length;
        this.soldN = this.dataList.filter(item => item.status === 'sold').length;
        this.notsoldN = this.dataList.filter(item => item.status === 'not_sold').length;
        const sold = this.dataList.filter(item => item.status === 'sold');
        const pendingSold = sold.filter(item => item.sold_status !== 'ko' && item.sold_status !== 'ok')
        .filter(item => item.sold_status !== 'ok').length;
        if (this.notificationsNumber > 0 && !this.showMsg) {
          let toastText: string;
          if (this.notificationsNumber > 1) {
            toastText = this.translate.instant('toast-messages.attention-offers');
          } else {
            toastText = this.translate.instant('toast-messages.attention-offer');
          }
          this.toastService.showToast(toastText, 'info', true);
          this.showMsg = true;
        }

        if (this.activeN > 0) {
          numberGroup[0] = 1;
          this.statusList.push({name: this.translate.instant('movements.active'), id: 'active'});
        }
        if (this.soldN > 0) {
          numberGroup[1] = 1;
          this.statusList.push({name: this.translate.instant('movements.sold'), id: 'sold'});
        }
        if (this.notsoldN > 0) {
          numberGroup[2] = 1;
          this.statusList.push({name: this.translate.instant('movements.not_sold'), id: 'not_sold'});
        }
        this.totalNomberGroup = numberGroup.reduce((a, b) => a + b, 0);

        if (this.activeN > 0) {
          this.filterType = 'active';
        } else {
          if (this.totalNomberGroup > 1) {
            this.filterType = 'all';
          } else if (this.soldN > 0) {
            this.filterType = 'sold';
          } else if (this.notsoldN > 0) {
            this.filterType = 'not_sold';
          }
        }

        this.dataListFiltered = this.orderList(this.dataListFiltered);
        this.chdRef.detectChanges();
      },
      error: error => {
        this.loaded = true;
        this.isEmpty = true;
        this.totalN = 0;
        this.activeN = 0;
        this.notsoldN = 0;
        this.soldN = 0;
        this.chdRef.detectChanges();
      }
    });
  }

  toggleDisplay(id: number) {
    this.isShow = !this.isShow;
    this.idSelected = id;
  }

  filterList(type: string) {
    this.filterType = type;
    this.statusOpen = false;
    this.filterByP = '';

    if (type === 'all') {
      this.dataListFiltered = [...this.dataList];
      this.filterByS = '';
    } else {
      this.filterByS = type;
    }

    if (type === 'active') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'active' || item.status === 'pending_acceptation');
    }

    if (type === 'sold') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'sold');
    }

    if (type === 'not_sold') {
      this.dataListFiltered = this.dataList.filter(item => item.status === 'not_sold');
    }
  }

  viewOfferDetails(dataItem: MarketPlaceMyOffersModel) {
    this.emitOfferDetails.emit(dataItem);
  }

  sortListByDate(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.id - b.id;
      }
      if (type === 'desc') {
        return b.id - a.id;
      }
    });
    return arr;
  }

  getLostTagname(offer: MarketPlaceMyOffersModel) {
    let hasActive = false;
    if (offer.bids !== undefined) {
      offer.bids.forEach(element => {
        if (element.status === 'active') {
          hasActive = true;
        }
      });
    }
    const currentState = this.marketplceService.getStateOfferStatus(offer, hasActive);
    let lostTagName: string;
    if (currentState === 'notSold') {
      lostTagName = this.translate.instant('offer-status.cancelled_tag');
    } else if (offer.bids !== undefined && offer.bids.length > 0) {
      lostTagName = this.translate.instant('offer-status.expired_tag');
    } else {
      lostTagName = this.translate.instant('offer-status.not_sold_tag');
    }
    return lostTagName;
  }

  onEventClickCreateOffer() {
    this.eventClickCreateOffer.emit();
  }

  onEventClickRoute(route: string) {
    this.eventButtonsRoute.emit(route);
  }

  getProjectList() {
    this.dataList.forEach(element => {
      const isInArray = this.projectList.includes(element.project_name);
      if (isInArray) {
        return;
      } else {
        this.projectList.push(element.project_name);
      }
    });
  }

  getInteractiveBids(data) {
    data.forEach(element => {
      if ((element.status === 'active' && element.best_bid > 0) || element.status === 'pending_acceptation') {
        this.notificationsNumber++;
      }
      if (element.status !== 'active' && element.status !== 'pending_acceptation') {
        if ((
          element.formalization_type === 'external'
          && element.sold_status === 'external_waiting_contracts'
          && element.status === 'sold'
          && element.contract_seller === false ) || (
          element.formalization_type === 'internal'
          && element.sold_status === 'internal_waiting_sign'
          && element.signed_seller === false
          && element.status === 'sold'
        )) {
          this.notificationsNumber++;
        }
      }
    });
  }

  orderList(data) {
    const orderedData = [];
    const lastOffers = [];
    data.forEach(element => {
      if (element.status === 'active') {
        orderedData.push(element);
      }
    });
    data.forEach(element => {
      if (element.status === 'pending_acceptation') {
        orderedData.push(element);
      }
    });
    data.forEach(element => {
      if (element.status !== 'active' && element.status !== 'pending_acceptation') {
        if ((
          element.formalization_type === 'external'
          && element.sold_status === 'external_waiting_contracts'
          && element.status === 'sold'
          && element.contract_seller === false ) || (
          element.formalization_type === 'internal'
          && element.sold_status === 'internal_waiting_sign'
          && element.signed_seller === false
          && element.status === 'sold'
        )) {
          orderedData.push(element);
          element.notification = true;
        } else {
          lastOffers.push(element);
          element.notification = false;
        }
      }
    });
    lastOffers.forEach(element => {
      orderedData.push(element);
    });
    return orderedData;
  }

  filterByProjects(value) {
    this.filterByS = '';
    this.projectListOpen = false;
    this.filterByP = value;
    let filterLst = this.dataList;
    filterLst = filterLst.filter((item) => item.project_name === value);
    this.dataListFiltered = filterLst;
  }

  toggleSelectProject() {
    this.statusOpen = false;
    this.projectListOpen = !this.projectListOpen;
  }

  toggleStatusProject() {
    this.projectListOpen = false;
    this.statusOpen = !this.statusOpen;
  }

  orderByShare() {
    this.orderBestShare = !this.orderBestShare;
    this.orderBestPrice  = false;
    if (!this.orderBestShare) {
      this.sortListByShare('asc', this.dataListFiltered);
    } else {
      this.sortListByShare('desc', this.dataListFiltered);
    }
  }

  orderByPrice() {
    this.orderBestPrice = !this.orderBestPrice;
    this.orderBestShare = false;
    if (!this.orderBestPrice) {
      this.sortListByPrice('asc', this.dataListFiltered);
    } else {
      this.sortListByPrice('desc', this.dataListFiltered);
    }
  }

  sortListByShare(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.amount - b.amount;
      }
      if (type === 'desc') {
        return b.amount - a.amount;
      }
    });
    return arr;
  }

  sortListByPrice(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.best_bid - b.best_bid;
      }
      if (type === 'desc') {
        return b.best_bid - a.best_bid;
      }
    });
    return arr;
  }

  public showOfferAgain(id) {
    this.dataList$.subscribe(data => {
      this.dataList = data;
      this.dataList.forEach(dataItem => {
        if (dataItem.id === id) {
          this.emitOfferDetails.emit(dataItem);
        }
      });
    });
  }

  reset() {
    this.filterByP = '';
    this.filterByS = '';
    this.orderBestShare = false;
    this.dataListFiltered = this.orderList(this.dataList);
  }

}
