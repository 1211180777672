import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bic' })
export class BICPipe implements PipeTransform {
    transform(value: string): string {
        if (value.length <= 12) {
            return value.split(' ').join('');
        } else {
            return value.split(' ').join('').substring(0, 11);
        }
    }
}
