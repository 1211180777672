import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService } from 'src/app/services/strapi.service';

@Component({
  selector: 'fun-overvew-meeting-list',
  templateUrl: './overvew-meeting-list.component.html',
  styleUrls: ['./overvew-meeting-list.component.scss'],
})
export class OvervewMeetingListComponent implements OnInit {

  @Input() public meeting;
  @Input() public project;
  @Input() public user;
  @Input() public strapiUserId;
  @Input() public hasVoteDelegate;

  @Output() public sendVoteEvent = new EventEmitter<any>();

  recordList = [];
  recordCurrent = null;
  userEmail: string;
  disabledVote = false;
  indexOpened = 0;
  countDownMst: string;

  constructor(
    public chdRef: ChangeDetectorRef,
    public strapiService: StrapiService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    this.countDownMst = '';
    this.userEmail = this.user.name;
    this.recordList = this.parseRecordList(this.meeting.attributes.records.data);
    if(!this.recordCurrent) {
      if (this.meeting.attributes.status === 'abierta a voto anticipado') {
        this.recordCurrent = this.recordList[0];
      } else if (this.meeting.attributes.status === 'en celebracion') {
        this.recordList.forEach((item, index) => {
          if (item.attributes.status === 'abierto') {
            this.indexOpened = index;
            this.recordCurrent = item;
          }})
      }
    }
    this.chdRef.detectChanges();
  }

  voteForEachRecord(record, vote) {
    if (!this.disabledVote && this.user.role !== 'public') {
      this.disabledVote = true;

      const model = {
        data: {
          meeting: this.meeting.id,
          result: vote,
          anticipated: this.meeting.attributes.status === 'abierta a voto anticipado' ? true : false,
          record: record.id,
        }
      }

      let apiCall$

      if (record.attributes.votes.data.length > 0) {
        apiCall$ = this.strapiService.updatevoteUser(model, record.attributes.votes.data[0].id);
      } else {
        apiCall$ = this.strapiService.createVoteUser(model);
      }
      apiCall$.subscribe(newVote => {
        let model = newVote;
        record.attributes.votes.data[0] = {
          id: model.data.attributes.result.id,
          result: model.data.attributes.result.result
        };
        record.attributes['userVote'] = vote;
        model.meetingId = this.meeting.id;
        this.disabledVote = false;
        this.sendVoteEvent.emit(model);
        this.chdRef.detectChanges();
      });
    }
    this.chdRef.detectChanges();
  }

  nextRecord() {
    this.indexOpened++;
    this.recordCurrent = this.recordList[this.indexOpened];
    this.chdRef.detectChanges();
  }

  parseRecordList(list) {
    list.forEach((item, index) => {
      if (item.attributes.status === 'abierto' && this.meeting.attributes === 'en celebracion') {
        this.indexOpened = index;
        this.recordCurrent = item;
      }
      const votes = item.attributes.votes.data;
      if (votes && votes.length > 0) {
        item['attributes']['userVote'] = item['attributes'].votes.data[0].attributes.result;
      } else if (votes.length === 0 && this.meeting.attributes.status === 'en celebracion' && this.user.role !== 'public') {
        const model = {
          data: {
            meeting: this.meeting.id,
            result: 'empty',
            anticipated: false,
            record: item.id,
          }
        }
        this.strapiService.createVoteUser(model).subscribe(newVote => {
          let model = newVote;
          item.attributes.votes.data[0] = {
            id: model.data.attributes.result.id,
            result: model.data.attributes.result.result
          };
          item.attributes['userVote'] = 'empty';
          model.meetingId = this.meeting.id;
          this.sendVoteEvent.emit(model);
          this.chdRef.detectChanges();
        });
      }
    });
    this.chdRef.detectChanges();
    return list;
  }

  public updateMeetingListStatus(record) {
    this.recordList.map((rec, index) => {
      if (rec.id === record.id) {
          if (record.attributes.status !== 'cerrado') {
            rec.attributes.status = record.attributes.status;
            this.indexOpened = index;
            this.recordCurrent = rec;
          } else {
            let count = 11;
            const countdownInterval = setInterval(() => {
              count--;
              this.countDownMst = `${this.translate.instant('MEETING.POINT_WILL_CLOSE')} ${count}`;
              if (count === 0) {
                clearInterval(countdownInterval);
                rec.attributes.status = record.attributes.status;
                this.recordCurrent = null;
                this.countDownMst = '';
                this.chdRef.detectChanges();
              }
            }, 1000);
          }
      }
      return rec;
    });
    this.chdRef.detectChanges();
  }

}
