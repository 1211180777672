import { Component, OnInit, ViewChild, AfterViewInit, TemplateRef, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import * as momentNs from 'moment';
import { MarketPlaceBalanceWalletModel, MarketPlaceContactModel, MarketPlaceMyBidsModel, StateBid, StatesBid } from 'src/app/shared/models/marketplace.models';
import { IFileButton } from 'src/app/shared/models/file.models';
import { ToastService } from '../../toast/toast.service';
import { HelpService } from 'src/app/services/help.service';
import { AlertService } from '../../alert/alert.service';
import { DateService } from 'src/app/services/date.service';
import { WarningComponent } from '../../warning/warning.component';
import { UtilsService } from 'src/app/services/utils.service';

const moment = momentNs;

@Component({
  selector: 'fun-bid-status',
  templateUrl: './bid-status.component.html',
  styleUrls: ['./bid-status.component.scss']
})
export class BidStatusComponent implements OnInit, AfterViewInit {

  private _bidData: MarketPlaceMyBidsModel = null;
  walletData: MarketPlaceBalanceWalletModel;
  contactData: MarketPlaceContactModel;
  cancelBtn: any;
  btnAccept: string;
  btnSecondary: string;

  loaded = false;

  get bidData(): MarketPlaceMyBidsModel {
    return this._bidData;
  }

  @Input('bidData')
  set bidData(value: MarketPlaceMyBidsModel) {
    value.date = this.dateService.getDateMs(value.date); // value.date.replace('/', '-').replace('/', '-');
    value.closing_date = this.dateService.getDateMs(value.closing_date);
    value.decision_due_date = this.dateService.getDateMs(value.decision_due_date);
    if (value.external_waiting_documentation_due_date !== undefined) {
      value.external_waiting_documentation_due_date = this.dateService.getDateMs(value.external_waiting_documentation_due_date);
    }
    if (value.contract_buyer_date !== undefined) {
      value.contract_buyer_date = this.dateService.getDateMs(value.contract_buyer_date);
    }
    if (value.ko_date !== undefined) {
      value.ko_date = this.dateService.getDateMs(value.ko_date);
    }
    if (value.ok_date !== undefined) {
      value.ok_date = this.dateService.getDateMs(value.ok_date);
    }
    if (value.internal_waiting_payment_due_date !== undefined) {
      value.internal_waiting_payment_due_date = this.dateService.getDateMs(value.internal_waiting_payment_due_date);
    }
    if (value.internal_waiting_sign_due_date !== undefined) {
      value.internal_waiting_sign_due_date = this.dateService.getDateMs(value.internal_waiting_sign_due_date);
    }
    if (value.ok_due_date !== undefined) {
      value.ok_due_date = this.dateService.getDateMs(value.ok_due_date);
    }
    if (value.ko_due_date !== undefined) {
      value.ko_due_date = this.dateService.getDateMs(value.ko_due_date);
    }
    if (value.contract_seller_date !== undefined) {
      value.contract_seller_date = this.dateService.getDateMs(value.contract_seller_date);
    }
    this._bidData = value;
    if (value) {
      this.loadStatus(value);
    }
  }

  @Input() bidContactData: Observable<MarketPlaceContactModel>;
  @Input() fileButtonData: IFileButton;
  @Input() balanceWalletData: Observable<MarketPlaceBalanceWalletModel>;
  @Input() actionPayBid: Observable<any>;
  @Input() actionUpdateBid: Observable<MarketPlaceMyBidsModel>;
  @Input() nameSeller: any;

  @Output() public eventButtonsRoute = new EventEmitter<string>();
  @Output() public eventCancelBid = new EventEmitter<number>();
  @Output() public eventBuyerSign = new EventEmitter<any>();
  @Output() public eventSellerSign = new EventEmitter<any>();
  @Output() public eventRechargeBalance = new EventEmitter<any>();
  @Output() public payBidevent = new EventEmitter<any>();

  @ViewChild('activeActive', { static: false }) activeActive: TemplateRef<any>;
  @ViewChild('activePendingAcceptation', { static: false }) activePendingAcceptation: TemplateRef<any>;
  @ViewChild('lostNotSold', { static: false }) lostNotSold: TemplateRef<any>;
  @ViewChild('soldExternalWaitingContractsOne', { static: false }) soldExternalWaitingContractsOne: TemplateRef<any>;
  @ViewChild('soldExternalWaitingContractsTwo', { static: false }) soldExternalWaitingContractsTwo: TemplateRef<any>;
  @ViewChild('soldExternalWaitingContractsKO', { static: false }) soldExternalWaitingContractsKO: TemplateRef<any>;
  @ViewChild('soldExternalContractsKO', { static: false }) soldExternalContractsKO: TemplateRef<any>;
  @ViewChild('soldExternalValidating', { static: false }) soldExternalValidating: TemplateRef<any>;
  @ViewChild('soldExternalOK', { static: false }) soldExternalOK: TemplateRef<any>;
  @ViewChild('soldExternalKO', { static: false }) soldExternalKO: TemplateRef<any>;
  @ViewChild('soldInternalWaitingSignBuyer', { static: false }) soldInternalWaitingSignBuyer: TemplateRef<any>;
  @ViewChild('soldInternalWaitingSignSeller', { static: false }) soldInternalWaitingSignSeller: TemplateRef<any>;
  @ViewChild('soldInternalWaitingSignBuyerKO', { static: false }) soldInternalWaitingSignBuyerKO: TemplateRef<any>;
  @ViewChild('soldInternalWaitingSignSellerKO', { static: false }) soldInternalWaitingSignSellerKO: TemplateRef<any>;
  @ViewChild('soldInternalWaitingPayment', { static: false }) soldInternalWaitingPayment: TemplateRef<any>;
  @ViewChild('soldInternalWaitingPaymentKO', { static: false }) soldInternalWaitingPaymentKO: TemplateRef<any>;
  @ViewChild('soldInternalOK', { static: false }) soldInternalOK: TemplateRef<any>;
  @ViewChild('soldInternalKO', { static: false }) soldInternalKO: TemplateRef<any>;
  @ViewChild('soldInternalWaitingAdminKO', { static: false }) soldInternalWaitingAdminKO: TemplateRef<any>;

  public statesTmpl: StateBid[] = [
    { state: 'activeActive' },
    { state: 'activePendingAcceptation' },
    { state: 'lostNotSold' },
    { state: 'soldExternalWaitingContractsOne' },
    { state: 'soldExternalWaitingContractsTwo' },
    { state: 'soldExternalWaitingContractsKO' },
    { state: 'soldExternalContractsKO' }, // nuevo estado
    { state: 'soldExternalValidating' },
    { state: 'soldExternalOK' },
    { state: 'soldExternalKO' },
    { state: 'soldInternalWaitingSignBuyer' },
    { state: 'soldInternalWaitingSignSeller' },
    { state: 'soldInternalWaitingSignBuyerKO' },
    { state: 'soldInternalWaitingSignSellerKO' },
    { state: 'soldInternalWaitingPayment' },
    { state: 'soldInternalWaitingPaymentKO' },
    { state: 'soldInternalOK' },
    { state: 'soldInternalKO' },
    { state: 'soldInternalWaitingAdminKO' }
  ];

  public currentState: StatesBid;

  elements: any;
  isShow = true;
  selectIsOpen = false;
  stateValue = 1;
  isPayProcess = false;

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private helpService: HelpService,
    private utils: UtilsService,
    private alertService: AlertService,
    public dateService: DateService
  ) { }

  ngOnInit() {
    this.cancelBtn = 'primaryLight';
    this.btnAccept = 'primaryDark';
    this.btnSecondary = 'secondaryDark';
    this.bidData.adquisition_price = this.utils.roundNumber(this.bidData.adquisition_price);
    registerLocaleData(es);
    this.bidContactData.subscribe((bidContactData: MarketPlaceContactModel) => {
      this.contactData = bidContactData;
      this.chdRef.detectChanges();
    });

    this.balanceWalletData.subscribe((balanceWalletData: MarketPlaceBalanceWalletModel) => {
      this.walletData = balanceWalletData;
      this.chdRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.chdRef.detectChanges();
  }

  selected(el) {
    this.elements.forEach(element => {
      if (el.id === element.nativeElement.id) {
        element.nativeElement.setAttribute('class', 'selected');
      } else {
        element.nativeElement.setAttribute('class', 'link');
      }
    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
    this.chdRef.detectChanges();
  }

  selectOpen() {
    this.selectIsOpen = !this.selectIsOpen;
    this.chdRef.detectChanges();
  }

  nextStep() {
    this.stateValue = this.stateValue + 1;
    this.chdRef.detectChanges();
  }

  prevStep() {
    this.stateValue = this.stateValue - 1;
    this.chdRef.detectChanges();
  }

  onClickChangeState(state: StatesBid): void {
    this.currentState = state;
    this.chdRef.detectChanges();
  }

  onEventClickCancelBid(idBid): void {
    this.cancelBtn = 'primaryLight-disabled';
    const modalParams = {
      place: 'bid-status'
     };
    this.alertService.showModal(WarningComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('warning.mkp-title').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.getChildComponent().subscribe((componentRef: WarningComponent) => {
          componentRef.continueClick.subscribe((res) => {
            this.eventCancelBid.emit(idBid);
            this.alertService.removeModal();
            this.chdRef.detectChanges();
          });
          componentRef.backClick.subscribe((res) => {
            this.cancelBtn = 'primaryLight';
            this.chdRef.detectChanges();
          });
        });
        modal.onClose().subscribe();
      }
    );
    this.chdRef.detectChanges();
  }

  onEventClickPayBid(): void {
    this.isPayProcess = true;
    this.actionPayBid.subscribe({
      next: data => {
        this.actionUpdateBid.subscribe({
          next: bidDataResp => {
            this._bidData = bidDataResp;
            this._bidData.closing_date = this.dateService.getDateMs(this._bidData.closing_date);
            this._bidData.date = this.dateService.getDateMs(this._bidData.date);
            this._bidData.decision_due_date = this.dateService.getDateMs(this._bidData.decision_due_date);
            this._bidData.internal_waiting_payment_due_date = this.dateService.getDateMs(this._bidData.internal_waiting_payment_due_date);
            this._bidData.internal_waiting_sign_due_date = this.dateService.getDateMs(this._bidData.internal_waiting_sign_due_date);
            this._bidData.ok_date = this.dateService.getDateMs(this._bidData.ok_date);
            this.currentState = 'soldInternalOK';
            this.isPayProcess = false;
            this.payBidevent.emit();
            this.chdRef.detectChanges();
          },
          error: error => console.error('error al recuperar los datos de la puja', error)
        });
      },
      error: error => {
        const txt: any = 'Error al realizar le pago';  // this.translate.instant('Error al realizar el pago');
        this.toastService.showToast(txt, 'warning', true);
      }
    });
    this.chdRef.detectChanges();
  }

  onEventClickRoute(route): void {
    this.btnAccept = 'primaryDark-disabled';
    this.btnSecondary = 'secondaryDark-disabled';
    this.eventButtonsRoute.emit(route);
    this.chdRef.detectChanges();
  }

  onEventClickBuyerSign(): void {
    this.eventBuyerSign.emit();
  }

  onEventClickSellerSign(): void {
    this.eventSellerSign.emit();
  }

  onEventUploadResponse(event: HttpResponse<any> | HttpErrorResponse): void {
    if (event instanceof HttpErrorResponse) {
      const toastText: any = this.translate.instant('toast-messages.upload-fail');
      this.toastService.showToast(toastText, 'warning');
    }
    if (event instanceof HttpResponse) {
      if (event.status === 200) {

        this.actionUpdateBid.subscribe({
          next: bidDataResp => {
            this._bidData = bidDataResp;
            this._bidData.closing_date = this.dateService.getDateMs(this._bidData.closing_date);
            this._bidData.contract_buyer_date = this.dateService.getDateMs(this._bidData.contract_buyer_date);
            this._bidData.contract_seller_date = this.dateService.getDateMs(this._bidData.contract_seller_date);
            this._bidData.date = this.dateService.getDateMs(this._bidData.date);
            this._bidData.external_waiting_documentation_due_date =
            this.dateService.getDateMs(this._bidData.external_waiting_documentation_due_date);
            this._bidData.decision_due_date = this.dateService.getDateMs(this._bidData.decision_due_date);
            this.currentState = 'soldExternalValidating';
            this.payBidevent.emit();
            this.chdRef.detectChanges();
          },
          error: error => console.error(error)
        });
      }
    }
    this.eventSellerSign.emit();
  }

  onEventClickRechargeBalance() {
    this.btnAccept = 'primaryDark-disabled';
    this.eventRechargeBalance.emit();
    this.eventSellerSign.emit();
  }

  loadStatus(bid: MarketPlaceMyBidsModel) {
    if (bid.status === 'active' && bid.offer_status === 'active') {
      this.currentState = 'activeActive';
    }
    if (bid.offer_status === 'pending_acceptation') {
      this.currentState = 'activePendingAcceptation';
    }
    if (bid.status === 'lost' && (bid.offer_status === 'not_sold' || bid.offer_status === 'sold')) {
      this.currentState = 'lostNotSold';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'external_waiting_contracts'
      && bid.contract_buyer === false
      && bid.formalization_type === 'external') {
      this.currentState = 'soldExternalWaitingContractsOne';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'not_sold'
      && bid.offer_sold_status === 'external_waiting_contracts'
      && bid.formalization_type === 'external') {
      this.currentState = 'soldExternalWaitingContractsKO';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && (bid.offer_sold_status === 'external_validating' || bid.contract_buyer === true)
      && bid.formalization_type === 'external') {
      this.currentState = 'soldExternalValidating';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'ok'
      && bid.formalization_type === 'external') {
      this.currentState = 'soldExternalOK';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'ko'
      && bid.formalization_type === 'external') {
      this.currentState = 'soldExternalKO';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'internal_waiting_sign'
      && bid.formalization_type === 'internal'
      && bid.signed_buyer === false
      && bid.signed_seller === false) {
      this.currentState = 'soldInternalWaitingSignBuyer';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'internal_waiting_sign'
      && bid.formalization_type === 'internal'
      && bid.signed_seller === true) {
      this.currentState = 'soldInternalWaitingSignSeller';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'not_sold'
      && (bid.offer_sold_status === 'internal_waiting_sign' || bid.offer_sold_status === 'ok')
      && bid.formalization_type === 'internal'
      && (bid.signed_buyer === false || bid.signed_buyer === undefined)
      && (bid.signed_seller === false || bid.signed_seller === undefined)
      ) {
      this.currentState = 'soldInternalWaitingSignBuyerKO';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'not_sold'
      && bid.offer_sold_status === 'internal_waiting_sign'
      && bid.formalization_type === 'internal'
      && bid.signed_buyer === true
      && bid.signed_seller === false) {
      this.currentState = 'soldInternalWaitingSignSellerKO';
    }
    if (bid.status === 'won'
    && bid.offer_status === 'not_sold'
    && bid.offer_sold_status === 'internal_waiting_sign'
    && bid.formalization_type === 'internal'
    && bid.signed_buyer === true
    && bid.signed_seller === true) {
    this.currentState = 'soldInternalWaitingAdminKO';
   }
    if (bid.status === 'won'
      && bid.offer_status === 'not_sold'
      && bid.offer_sold_status === 'internal_waiting_sign'
      && bid.formalization_type === 'internal'
      && bid.signed_buyer === true
      && bid.signed_seller === false) {
      this.currentState = 'soldInternalWaitingSignSellerKO';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'internal_waiting_payment' // Es aqui
      && bid.formalization_type === 'internal'
      && bid.internal_waiting_payment_due_date
      // && bid.signed_seller === true
      ) {
      this.currentState = 'soldInternalWaitingPayment';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'not_sold'
      && bid.offer_sold_status === 'internal_waiting_payment'
      && bid.formalization_type === 'internal') {
      this.currentState = 'soldInternalWaitingPaymentKO';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'ok'
      && bid.formalization_type === 'internal') {
      this.currentState = 'soldInternalOK';
    }
    if (bid.status === 'won'
      && bid.offer_status === 'sold'
      && bid.offer_sold_status === 'ko'
      && bid.formalization_type === 'internal') {
      this.currentState = 'soldInternalKO';
    }
  }
  openHelp(level, index) {
    this.helpService.openHelp(level, index, 'BID_STATUS');
  }

  changeToResendDocumentStatus() {
    this.currentState = 'soldExternalWaitingContractsTwo';
    this.chdRef.detectChanges();
  }

  contactByMail() {
    const subject = 'Subida de documentos en tablón de anuncios';
    const mailText = `mailto:soporte@fundeen.com,bdemiguel@demira.es?subject=${subject}&body=¡Hola! %0DEscribo este mensaje para comunicar que he subido mi contrato de venta al tablón de anuncios.`;
    window.location.href = mailText;
  }
}
