<div class="general-container">
  <div class="title" [innerHTML]="text"></div>
  <div class="investment-list" *ngIf="!loading">
    <div class="investment-title row-content">
      <div class="amount-title">
        <p class="font-sans text-l text-eggplant-100/[0.55]">{{ 'CANCEL_INVEST.AMOUNT' | translate }}</p>
      </div>
      <div class="date-title">
        <p class="font-sans text-l text-eggplant-100/[0.55]">{{ 'CANCEL_INVEST.DATE' | translate }}</p>
      </div>
    </div>
    <div *ngFor="let investment of investmentList">
      <div class="investment row-content">
        <div class="checks-container">
          <div [ngClass]="{'check': !investment.selected, 'checked': investment.selected}"
          (click)="selectInvestment(investment.index)"></div>
        </div>
        <div class="amount-container">
          <p class="font-sans text-l text-eggplant-100/[0.55]">{{ investment.amount }}€</p>
        </div>
        <div class="date-container">
          <p class="font-sans text-l text-eggplant-100/[0.55]">{{ investment.dateAccountant }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showMsg && !loading" class="alertMsg">
    <p class="font-sans text-l text-eggplant-100">{{ 'CANCEL_INVEST.TXT' | translate }}</p>
  </div>
  <div class="btn-container" *ngIf="!loading">
    <div class="btn btn-first">
      <fun-new-button
        (eventClick)="cancelSelected()"
        [buttonType]="btnType"
        title="{{ 'CANCEL_INVEST.BTN_1' | translate }}"
      ></fun-new-button>
    </div>
    <div class="btn">
      <fun-new-button
        (eventClick)="goBack()"
        buttonType="secondaryDark"
        title="{{ 'CANCEL_INVEST.BTN_2' | translate }}"
      ></fun-new-button>
    </div>
  </div>
  <div *ngIf="loading" class="loader-container">
    <ion-spinner name="lines"></ion-spinner>
  </div>
</div>