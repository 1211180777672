import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  private API_PATH = 'crm/v3/objects/';
  private PROXY_URL = environment.endPointHubspot;

  deals: any[];

  pipeline: string;
  stages: any;

  constructor(private http: HttpClient) {
    if(environment.env === 'prod') {
      this.stages = {
        ['cart_abandoned']: '114894392',
        ['view']: '114894393',
        ['info']: '131699065',
        ['calculator']: '114894394',
        ['download']: '114894395',
        ['add_to_cart']: '114894396',
        ['reservation']: '114942039',
        ['test']: '114942040',
        ['checkout']: '114942041',
        ['sign']: '114942042',
        ['payment']: '114942043',
        ['ok']: '114894397',
        ['cancel']: '114894398',
        ['validation']: '138421581'
      };
      this.pipeline = '57904999';
    } else {
      this.stages = {
        ['cart_abandoned']: '114948656',
        ['view']: '77900719',
        ['info']: '131381275',
        ['calculator']: '114894371',
        ['download']: '105683575',
        ['add_to_cart']: '114948657',
        ['reservation']: '105683576',
        ['test']: '105683577',
        ['checkout']: '105683578',
        ['sign']: '105683579',
        ['payment']: '105683580',
        ['ok']: '98891150',
        ['cancel']: '105683581',
        ['validation']: '138478212'
      };
      this.pipeline = '34570783';
    }
  }

  getStage(key: string) {
    return this.stages[key];
  }

  searchContact(email: string): Observable<any> {
    const path = `${this.API_PATH}contacts/search`;
    const params = {
      "filterGroups": [
        {
          "filters": [
            {
              "value": email,
              "propertyName": "email",
              "operator": "EQ"
            }
          ]
        }
      ]
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.post(`${this.PROXY_URL}`, options);
  }

  updateContact(id: string, props: any): Observable<any> {
    const path = `${this.API_PATH}contacts/${id}`;
    const params = {
      "properties": props
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.patch(`${this.PROXY_URL}`, options);
  }

  getDeals(email: string, project: string): Observable<any> {
    const path = `${this.API_PATH}deals/search`;
    const params = {
      "filterGroups": [
        {
          "filters": [
            {
              "value": email,
              "propertyName": "referencias",
              "operator": "EQ"
            },
            {
              "value": project,
              "propertyName": "project",
              "operator": "EQ"
            },
            // {
            //   "value": `${this.stages['ok']}`, // Etapa Final inversión realizada
            //   "propertyName": "dealstage",
            //   "operator": "NEQ"
            // }
          ]
        }
      ]
    };
    return this.http.post(`${this.PROXY_URL}`, { path: path, params: params });
  }

  createDeal(email: string, project: string, id: string, info: string, projectName: string): Observable<any> {
    const path = `${this.API_PATH}deals`;
    const params = {
      "properties": {
        "dealname": info,
        "dealstage": `${this.stages['view']}`,
        "pipeline": `${this.pipeline}`,
        "referencias": email,// Email del contacto,
        "nombre_del_proyecto": projectName,
        "project": project
      },
      "associations": [
          {
              "to": {
                  "id": id // Id del contacto
              },
              "types": [
                  {
                      "associationCategory": "HUBSPOT_DEFINED",
                      "associationTypeId": 3
                  }
              ]
          }
      ]
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.post(`${this.PROXY_URL}`, options);
  }

  updateDeal(id: string, stage: string, props: any): Observable<any> {
    const path = `${this.API_PATH}deals/${id}`;
    const newStage = this.stages[stage];
    props.dealstage = `${newStage}`;
    const params = {
      "properties": props
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.patch(`${this.PROXY_URL}`, options);
  }

  searchProduct(sku: number): Observable<any> {
    const path = `${this.API_PATH}products/search`;
    const params = {
      "filterGroups": [
        {
          "filters": [
            {
              "value": sku,
              "propertyName": "hs_sku",
              "operator": "EQ"
            }
          ]
        }
      ]
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.post(`${this.PROXY_URL}`, options);
  }

  createOrder(product: number, price: number): Observable<any> {
    const path = `${this.API_PATH}line_items`;
    const params = {
      "properties": {
        "quantity": 1,
        "price": price,
        "hs_product_id": product
      }
    };
    const options = {
      path: path,
      params: params
    };
    return this.http.post(`${this.PROXY_URL}`, options);
  }

  associateDealOrder(deal: number, order: number): Observable<any> {
    const path = `${this.API_PATH}deals/${deal}/associations/line_items/${order}/19`;
    const options = {
      path: path,
      params: {}
    };
    return this.http.put(`${this.PROXY_URL}`, options);
  }

}