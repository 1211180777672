<div class="register">
    <div class="body">
        <div class="person-contact-container" [formGroup]="contactPersonForm">
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.know.name' | translate }}"
                    [parentForm]="contactPersonForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="name"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.name' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.name"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{ 'register.know.last_name' | translate }}"
                    [parentForm]="contactPersonForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="surname"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.last_name' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (focusout)="focusLost()"
                    [value]="data.surname"
                ></fun-input>
                <span *ngIf="notFullSurname" class="advice">{{ 'register.know.advice' | translate }}</span>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="dd/mm/aaaa"
                    [parentForm]="contactPersonForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="date"
                    type="date"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.know.date' | translate }}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.birthDate"
                ></fun-input>
            </div>
        </div>
        <div class="form-input checkbox" [formGroup]="contactPersonForm">
            <input formControlName="businessPower" [value]="data.businessPower" (change)="getInfo()" type="checkbox" id="cb1">
            <label for="cb1">{{'register.know.fundeen_authorize' | translate}}<span class="span_b" (click)="goToLink()"> {{'register.know.know_more' | translate}}</span></label>
        </div>
    </div>
</div>
