import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-new-select',
  templateUrl: './new-select.component.html',
  styleUrls: ['./new-select.component.scss'],
})
export class NewSelectComponent implements OnInit, OnChanges {
  @Input('size') size = 'm';
  @Input() options;
  @Input() selectedValue;
  
  @Output() public selectChange = new EventEmitter<any>();

  selectStyles = {
    'base': 'block w-fit h-fit bg-transparent cursor-pointer font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill invalid:border-alert invalid:text-alert py-8 pl-12 pr-28 relative',
    'xl': 'text-xl',
    'l': 'text-l',
    'm': 'text-m',
    's': 'text-s'
  };

  selectClass: string;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.setSelectStyles();
    this.chdRef.detectChanges();
  }

  ngOnChanges() {}

  setSelectStyles() {
    this.selectClass = `${this.selectStyles['base']} ${this.selectStyles[this.size]} `;
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedValue = selectElement.value;
    this.selectChange.emit(this.selectedValue);
  }

}
