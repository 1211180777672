<div *ngIf="loaded && loadedRecords">

  <div class="general-container pt-24 pb-12 md:pb-24 flex justify-center relative appear">
    <h1 *ngIf="meeting.attributes.status === 'en celebracion' || meeting.attributes.status === 'abierta a voto anticipado' || meeting.attributes.status === 'cerrada'"
     class="text-eggplant-100 font-sans text-h500 md:text-h600 px-16 py-32">{{ meeting.attributes.title }}</h1>
    <div class="absolute right-[16px] flex flex-row items-center gap-12">
      <div (click)="goBack()" class="cursor-pointer transform rotate-180"><fun-icon [name]="'log-out'" [style]="'stroke-2 stroke-eggplant-100/[.4] w-[24px] h-[24px]'"></fun-icon></div>
      <p (click)="goBack()" class="font-sans text-xl text-eggplant-100/[0.4] cursor-pointer">{{ 'MEETING.EXIT' | translate }}</p>
    </div>
  </div>

  <div *ngIf="meeting.attributes.status === 'en celebracion' || meeting.attributes.status === 'abierta a voto anticipado' || meeting.attributes.status === 'cerrada'" class="general-container flex flex-col h-full relative p-16 pb-[142px] md:p-0 md:pb-64">
    <fun-overal-message  *ngIf="hasVoteDelegate" [text]="text" [image]="image" [btnList]="btnList" (btnEvent)="goBack()"></fun-overal-message>

    <div class="flex flex-col md:flex-row w-full gap-24 appear">
      <div *ngIf="!hasVoteDelegate" class="w-full md:basis-2/3">

        <!-- Puntos del dia -->
        <div class="rounded-2xl shadow-2xl bg-white p-24 min-h-[787px] basis-2/3">
          <fun-overvew-meeting-list [user]="strapiUser" [meeting]="meeting" [project]="project" [hasVoteDelegate]="hasVoteDelegate"
          [strapiUserId]="strapiUserId" #listComponent (sendVoteEvent)="sendVote($event)"></fun-overvew-meeting-list>

          <!-- Documentación -->
          <div *ngIf="meeting.attributes.documents && meeting.attributes.documents.length > 0" class="flex w-full items-center justify-center content-center mt-40 mb-24">
            <fun-new-button
              [buttonType]="'ghost'"
              (eventClick)="downloadDocuments()"
              title="{{ 'MEETING.DOWNLOAD_DOCUMENTATION' | translate }}"
              [size]="'m'"
              icon="download" [iconPosition]="'left'"
            ></fun-new-button>
          </div>

        </div>

      </div>
      <div class="w-full md:basis-1/3 w-full fixed left-0 bottom-0 md:basis-1/3 md:static" *ngIf="(meeting.attributes.status === 'en celebracion' && !hasVoteDelegate) || meeting.attributes.status === 'cerrada'">

        <!-- Video -->
        <div class="rounded-2xl shadow-2xl mb-24 overflow-hidden " [ngClass]="showVideo ? '' : 'hidden md:block'">
          <div *ngIf="meeting.attributes.status === 'oculta' || meeting.attributes.status === 'abierta a voto anticipado'">
            <p class="font-sans text-l text-eggplant-100/[0.4]">Actualmente la junta está abierta a voto anticipado y no está disponible el video en directo.</p>
          </div>
          <div *ngIf="meeting.attributes.status !== 'oculta' && meeting.attributes.status !== 'abierta a voto anticipado'">
            <div *ngIf="youtubeIframe && meeting.attributes.status === 'cerrada'" [innerHTML]="youtubeIframe" class="youtube-container"></div>
            <iframe *ngIf="meeting.attributes.status === 'en celebracion'" class="ninja-video" allow="camera=0;microphone=0;fullscreen;gyroscope;accelerometer;picture-in-picture;display-capture;midi;geolocation"
            src="https://vdo.ninja/?scene=0&room=Juntas_en_directo&cover"></iframe>
            <!-- <youtube-player
              [videoId]="id"
              [height]="225"
              [width]="410"
            ></youtube-player> -->
          </div>
        </div>

        <!-- Chat -->
        <div *ngIf="meeting.attributes.status !== 'cerrada'" class="rounded-2xl shadow-2xl bg-white p-24 mb-24 overflow-hidden" [ngClass]="showChat ? '' : 'hidden md:block'">
          <fun-overvew-meeting-chat *ngIf="meeting.attributes.status === 'en celebracion'" #chatComponent
          [meeting]="meeting" [project]="project" [user]="strapiUser" [strapiUserId]="strapiUserId" (sendCommentEvent)="sendMessage($event)"></fun-overvew-meeting-chat>
        </div>

        <!-- Bottons -->
        <div class="md:rounded-2xl md:shadow-2xl bg-white p-10 flex flex-col gap-y-12 justify-center items-center content-center md:hidden">
          <div class="flex flex-row w-full gap-16" >
            <div class="basis-1/2" [ngClass]="{ 'basis-1/2': meeting.attributes.status !== 'cerrada', 'w-full': meeting.attributes.status === 'cerrada' }">
              <fun-new-button [buttonType]="showVideo ? 'primaryDark': 'secondaryDark'" [size]="'m'" title="Video" (eventClick)="changeDirect('video')" [classExtra]="'!w-full'"></fun-new-button>
            </div>
            <div *ngIf="meeting.attributes.status !== 'cerrada'" class="basis-1/2">
              <fun-new-button [buttonType]="showChat ? 'primaryDark': 'secondaryDark'" [size]="'m'" title="Chat" (eventClick)="changeDirect('chat')" [classExtra]="'!w-full'"></fun-new-button>
            </div>
          </div>
        </div>

      </div>

      <div class="w-full md:basis-1/3" *ngIf="meeting.attributes.status === 'abierta a voto anticipado' && !hasVoteDelegate">

        <!-- Delegacion voto -->
        <div class="rounded-2xl shadow-2xl bg-white p-24">
          <h4 class="text-eggplant-100 font-sans text-h400 md:text-h500 text-center p-12">{{ 'MEETING.DELEGATE_VOTE' | translate }}</h4>
          <div *ngIf="hasVoted" class="h-full w-full md:max-w-[380px] pr-12" >
            <p class="font-sans text-l text-eggplant-100/[0.4] py-12">{{ 'MEETING.CANT_DELEGATE_ALREADY_VOTED' | translate }}</p>
          </div>
          <div class="h-full w-full md:max-w-[380px] pr-12" *ngIf="!hasVoted">
            <p class="font-sans text-l text-eggplant-100/[0.4] py-12" *ngIf="!isRepresentative && role !== 'public'">{{ 'MEETING.USE_SELECTOR' | translate }}</p>
            <p *ngIf="role === 'public'" class="font-sans text-l text-eggplant-100/[0.4] py-12">{{ 'MEETING.INVITED' | translate }}</p>
            <div *ngIf="role !== 'public'" class="md:min-w-[320px]">
              <div class="flex flex-row w-full gap-x-12" *ngIf="usersList.length > 0 && !hasVoteDelegate && !hasVoted && !isRepresentative">
                <div class="basis-2/3">
                  <fun-input
                    [parentForm]="delegateForm"
                    controlName="users"
                    type="select"
                    [items]="usersList"
                    [selectedItem]="usersList[0].id"
                    classExtra="!w-full"
                    placeholder="{{ 'MEETING.SELECT_INVESTOR' | translate }}"
                  ></fun-input>
                </div>
                <div class="basis-1/3">
                  <fun-new-button [buttonType]="'primaryDark'" [size]="'l'" title="{{ 'MEETING.DELEGATE_VOTE' | translate }}" (eventClick)="delegateVoteAdvise()"></fun-new-button>
                </div>
              </div>
              <div *ngIf="hasVoteDelegate">
                <p class="font-sans text-l text-eggplant-100/[0.4] pb-12">{{ 'MEETING.DELEGATE_CANT_ASSIST' | translate }}</p>
              </div>
              <div *ngIf="isRepresentative" class="flex flex-col gap-12 py-24">
                <p class="font-sans text-l text-eggplant-100/[0.4] py-12">{{ 'MEETING.DELEGATED_IN_YOU' | translate }}</p>
                <ul class="flex flex-col self-start gap-12">
                  <li class="flex justify-center items-center content-center text-s text-white uppercase linear-gradient-2 py-2 px-8 rounded-md font-sans" *ngFor="let user of representativeList">
                    {{ user.attributes.users_permissions_user.data.attributes.name + ' ' + user.attributes.users_permissions_user.data.attributes.surname }}
                  </li>
                </ul>
                <fun-new-button [buttonType]="'ghost'" [size]="'m'" title="{{ 'MEETING.CANCEL_DELEGATIONS' | translate }}" (eventClick)="cancelDelegations()" ></fun-new-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="general-container flex flex-col h-full relative appear mt-[32px] md:mt-0"
    *ngIf="meeting.attributes.status === 'redactando acta'">
    <div class="w-full flex flex-col gap-72 items-center justify-center content-center appear my-72">
      <div class="mt-[120px]"><fun-icon [name]="'lock'" [style]="'w-72 h-[86px] stroke-[4px] stroke-eggplant-70'"></fun-icon></div>
      <p class="text-center font-sans text-h600 md:text-h800 text-eggplant-70">{{ 'MEETING.MEETING_FINISHED' | translate }}</p>
    </div>
  </div>

</div>
<div *ngIf="!loaded || !loadedRecords" class="general-container pt-24">

  <!-- Es aqui lo de generando junta -->
  <div class="py-72 w-full flex flex-col gap-72 items-center justify-center content-center appear">
    <!-- <fun-spinner></fun-spinner> -->
    <div class="pt-[120px]"><fun-icon [name]="'globe'" [style]="'w-72 h-[86px] stroke-[4px] stroke-eggplant-70 animate-gradientX'"></fun-icon></div>
    <p class="text-center font-sans text-h600 md:text-h800 text-eggplant-70 animate-gradientX">{{ 'MEETING.LOADING' | translate }}...</p>
  </div>
</div>