import { Injectable } from '@angular/core';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class DetailProjectService {
  constructor(private communicationService: CommunicationService) {}

  getProjectTodayMoney(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + id + '/statistics/today/money', null);
  }

  getProjectTodayProduction(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + id + '/statistics/today/production', null);
  }

  getProjectAnnual(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + id + '/statistics/annual', null);
  }

  getProjectPerformance(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/ ' + id + '/statistics/performance', null);
  }
}
