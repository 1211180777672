<div class="general-container">
  <h2>{{ 'OVERVIEW.DOCUMENTATION.TITLE' | translate }}</h2>
  <p class="profiles">{{ 'OVERVIEW.DOCUMENTATION.TXT' | translate }}</p>
  <div class="documentation">
      <div class="document" *ngFor="let document of documentation">
          <img class="file" (click)="downloadFile(document)" src="../../../assets/images/img/file-green.svg" alt="file">
          <!--<p (click)="downloadFile(document)" *ngIf="document.type === 2 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-1' | translate }}</p>
          <p (click)="downloadFile(document)" *ngIf="document.type === 3 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-2' | translate }}</p>
          <p (click)="downloadFile(document)" *ngIf="document.type === 4 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-3' | translate }}</p>
          <p (click)="downloadFile(document)" *ngIf="document.type === 5 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-4' | translate }}</p>
          <p (click)="downloadFile(document)" *ngIf="document.type === 10 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-10' | translate }}</p>
          <p (click)="downloadFile(document)" *ngIf="document.type === 11 && status !== 14">{{ 'OVERVIEW.DOCUMENTATION.DOC-11' | translate }}</p>-->
          <p (click)="downloadFile(document)" >{{ document.name }}</p>
          <img class="download" (click)="downloadFile(document)" src="../../../assets/images/img/preview.svg" alt="download">
      </div>
  </div>
  <p *ngIf="documentation && documentation.length > 0" class="download-all" (click)="downloadAll()">{{ 'OVERVIEW.DOCUMENTATION.DOWNLOAD' | translate }}</p>
</div>
