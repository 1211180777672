<ion-header>
  <ion-toolbar class="menu-mobile" [color]="fillColor">

    <ion-buttons>
      <img class="back-button" (click)="goBack()" src="../../../assets/icons/arrow-left.svg" />
      <img class="back-button" (click)="close()" src="../../../assets/icons/close.svg" />
    </ion-buttons>

  </ion-toolbar>
</ion-header>
