import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class DailyProductionGraphicService {
  constructor(private communicationService: CommunicationService, private http: HttpClient) {}

  getInfo(id) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/graphicDay/production/' + id, null);
  }

  getN8NMonitoringDailyData(id, startDate, endDate) {
    const url = 'https://n8n.fundeen.com/webhook/dates';

    let params = new HttpParams();
    params = params.append('id', id);
    params = params.append('startDate', startDate);
    params = params.append('endDate', endDate);
    return this.http.get(url, { params: params });
  }

  getApiMonitoringDailyData(id, startDate, endDate) {
    const params = {
      projectId: id,
      startDate: startDate,
      endDate: endDate
    }
    return this.communicationService.post('apiservices/project/v1/projects/monitoring', params);
  }
}
