<div class="general-container" *ngIf="!loading">
  <div class="content md:grid grid-cols-4 md:grid-cols-12 md:gap-x-32 lg:gap-x-32 place-content-center relative">
    <div class="step col-span-6 col-start-4 " *ngIf="step === 0">
      <div class="step-header pb-80">
        <h2 class="text-center font-sans text-h800 bg-gradient-2-text pb-[16px]">{{ 'INVEST.INVESTOR_PROFILE.TXT' | translate }}</h2>
        <p class="text-center font-sans text-xl text-gray-40">{{ 'EXPERIENCED_INVESTOR.SUBTITLE' | translate }}</p>
      </div>
      <div class="step-content experience-step">
        <div class="condition mb-32">
          <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.Q1.TITLE' | translate }}</p>
          <div class="flex">
            <div class="cursor-pointer mr-16 w-full" (click)="checkOption('works')"
            [ngClass]="{'option': !worksInSectorSelected, 'option-selected': worksInSectorSelected }">
              <p class="px-24 py-16 font-sans text-l text-eggplant-100 text-center">{{ 'EXPERIENCED_INVESTOR.YES_WORK' | translate }}</p>
            </div>
            <div class="cursor-pointer ml-16 w-full" (click)="checkOption('works')"
            [ngClass]="{'option': worksInSectorSelected, 'option-selected': !worksInSectorSelected }">
              <p class="px-24 py-16 font-sans text-l text-eggplant-100 text-center">{{ 'EXPERIENCED_INVESTOR.NO_WORK' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="condition  mb-32">
          <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.Q2.TITLE' | translate }}</p>
          <div class="flex">
            <div class="cursor-pointer mr-16 w-full" (click)="checkOption('experience')"
            [ngClass]="{'option': !hasExperienceSelected, 'option-selected': hasExperienceSelected }">
              <p class="px-24 py-16 font-sans text-l text-eggplant-100 text-center">{{ 'EXPERIENCED_INVESTOR.YES_INVESTED' | translate }}</p>
            </div>
            <div class="cursor-pointer ml-16 w-full" (click)="checkOption('experience')"
            [ngClass]="{'option': hasExperienceSelected, 'option-selected': !hasExperienceSelected }">
              <p class="px-24 py-16 font-sans text-l text-eggplant-100 text-center">{{ 'EXPERIENCED_INVESTOR.NO_INVESTED' | translate }}</p>
            </div>
          </div>
        </div>
        <div class="condition  pb-32" *ngIf="hasExperienceSelected">
          <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.Q3.TITLE' | translate }}</p>
          <p class="label font-sans text-s text-eggplant-100/[0.4] inline-flex">{{ 'EXPERIENCED_INVESTOR.Q3.SUBTITLE' | translate }}</p>
          <div class="range-container">
            <ion-range min="0" max="55" [pin]="true" [value]="rangeValueAnnualInvestment" (ionChange)="changeDataRange($event, 'anual-inv')"> <!--[(ngModel)]="value"-->
            </ion-range>
            <div class="range-label">
              <p>5</p>
              <p>20</p>
              <p>30</p>
              <p>+50</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="step col-span-6 col-start-4 " *ngIf="step === 1">
      <div class="step-header pb-80">
        <h2 class="text-center font-sans text-h800 bg-gradient-2-text pb-[16px]">{{ 'INVEST.INVESTOR_PROFILE.TXT' | translate }}</h2>
        <p class="text-center font-sans text-xl text-gray-40">{{ 'EXPERIENCED_INVESTOR.Q4.SUBTITLE' | translate }}</p>
      </div>
      <div class="step-content situation-step">
        <div *ngIf="profile.profileType === 1">
          <div class="condition">
            <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.SALARY' | translate }}</p>
            <div class="range-container">
              <ion-range min="0" max="120" pin="true" [value]="rangeAnnualGrossIncome" (ionChange)="changeDataRange($event, 'annual-gross' )"> </ion-range>
              <div class="range-label">
                <p>10k €</p>
                <p>40k €</p>
                <p>75k €</p>
                <p>+100k €</p>
              </div>
            </div>
          </div>
          <div class="condition">
            <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.PATRIMONY' | translate }}</p>
            <div class="range-container">
              <ion-range min="0" max="120" pin="true" [value]="rangeFinantialPatrimony" (ionChange)="changeDataRange($event, 'finantial-patrimony')"> </ion-range>
              <div class="range-label">
                <p>10k €</p>
                <p>40k €</p>
                <p>75k €</p>
                <p>+100k €</p>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="profile.profileType === 2">
          <div class="condition">
            <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.OWN_RESOURCES' | translate }}</p>
            <div class="range-container">
              <ion-range min="0" max="1000" pin="true" [value]="rangeOwnResources" (ionChange)="changeDataRange($event, 'ownResources')"> </ion-range>
              <div class="range-label">
                <p>100k €</p>
                <p>350k €</p>
                <p>600k €</p>
                <p>+1000k €</p>
              </div>
            </div>
          </div>
          <div class="condition">
            <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">{{ 'EXPERIENCED_INVESTOR.TURNOVER' | translate }}</p>
            <div class="range-container">
              <ion-range min="0" max="1000" pin="true" [value]="rangeTurnover" (ionChange)="changeDataRange($event, 'turnover')"> </ion-range>
              <div class="range-label">
                <p>100k €</p>
                <p>350k €</p>
                <p>600k €</p>
                <p>+1000k €</p>
              </div>
            </div>
          </div>
          <div class="condition">
            <p class="condition-title font-sans text-h400 text-center text-eggplant-100 pb-[18px]">Balance</p>
            <div class="range-container">
              <ion-range min="0" max="1000" pin="true" [value]="rangebalance" (ionChange)="changeDataRange($event, 'balance')"> </ion-range>
              <div class="range-label">
                <p>100k €</p>
                <p>350k €</p>
                <p>600k €</p>
                <p>+1000k €</p>
              </div>
            </div>
          </div>
        </div>

        <div class="condition">
          <div class="condition-check">
            <div [ngClass]="{'check': !declareIsCorrect, 'checked': declareIsCorrect }" (click)="checkOption('dechare')"></div>
            <p class="text-s font-sans text-eggplant-100/[0.4]">{{ 'EXPERIENCED_INVESTOR.CHECK_TXT' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer"  *ngIf="!loading">
  <div class="btn_container first">
    <fun-new-button [buttonType]="'ghost'" [size]="'xl'" title="{{ 'AUTOINVEST.FOOTER.PREV' | translate }}" [classExtra]="' !w-full '"
    (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'"></fun-new-button>
  </div>
  <div class="btn_container secod">
    <fun-new-button [buttonType]="btnType" [size]="'xl'" title="{{ 'AUTOINVEST.FOOTER.NEXT' | translate }}"
    (eventClick)="next()" icon="arrow-right" [iconPosition]="'right'" [classExtra]="' !w-full '"></fun-new-button>
  </div>
</div>
<fun-spinner  *ngIf="loading"></fun-spinner>