import { Injectable, ErrorHandler  } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler  {

  constructor(private logService: LogService) { }

  handleError(error: any) {
    let errorMsg: any;
    if (error instanceof HttpErrorResponse) {
      errorMsg = `Status code: ${error.status}. Error body: ${error.message}`;
    } else {
      errorMsg = `Other type of error: ${error}.`;
      this.logService.throwError(error);
    }
  }

}