<div class="register">
    <div class="body">
        <div class="investor-steps">
            <ul>
                <li *ngFor="let option of options">
                    <h3>{{option.title}}</h3>
                    <p>{{option?.text}}</p>
                </li>
            </ul>
        </div>
    </div>
</div>
