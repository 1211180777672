import { Injectable } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';

@Injectable({
  providedIn: 'root',
})
export class IncomeGraphicService {
  constructor(private communicationService: CommunicationService) {}

  getInfo(id: any, graphic: any) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + graphic + '/money/' + id, null);
  }
  getProjectTodayProduction(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + id + '/statistics/today/money', null);
  }

  getProjectAnnual(id: string) {
    return this.communicationService.get('apiservices/dashboard/v1/dashboard/' + id + '/statistics/annual', null);
  }
  parseMonth(moment) {
    let month;
    switch (moment) {
      case 1:
        month = 'ENERO';
        break;
      case 2:
        month = 'FEBRERO';
        break;
      case 3:
        month = 'MARZO';
        break;
      case 4:
        month = 'ABRIL';
        break;
      case 5:
        month = 'MAYO';
        break;
      case 6:
        month = 'JUNIO';
        break;
      case 7:
        month = 'JULIO';
        break;
      case 8:
        month = 'AGOSTO';
        break;
      case 9:
        month = 'SEPTIEMBRE';
        break;
      case 10:
        month = 'OCTUBRE';
        break;
      case 11:
        month = 'NOVIEMBRE';
        break;
      case 12:
        month = 'DICIEMBRE';
        break;
    }
    return month;
  }
}
