import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview-documentation-section',
  templateUrl: './overview-documentation-section.component.html',
  styleUrls: ['./overview-documentation-section.component.scss'],
})
export class OverviewDocumentationSectionComponent implements OnInit {
  @Input() documentation: any;
  @Input() status: any;
  @Output() private eventdownloadAll = new EventEmitter<void>();
  @Output() private eventdownloadFile = new EventEmitter<void>();

  constructor(private translate: TranslateService) { }

  ngOnInit() {}

  downloadFile(document) {
    /*if (document.type) {
      document.name = this.translate.instant(`overview.doc.doc-${document.type < 10 ? (document.type - 1) : document.type}`);
    }*/
    this.eventdownloadFile.emit(document);
  }
  downloadAll() {
    this.eventdownloadAll.emit();
  }

}
