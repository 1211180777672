import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-register-person-last-step-document',
  templateUrl: './register-person-last-step-document.component.html',
  styleUrls: ['./register-person-last-step-document.component.scss']
})
export class RegisterPersonLastStepDocumentComponent implements OnInit {

  @Output() public getFileEvent = new EventEmitter<any>();
  @Output() public errorFileEvent = new EventEmitter<any>();
  @Input() userName: any;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }
  getFile(value) {
    this.getFileEvent.emit(value);
    this.chdRef.detectChanges();
  }
  errorFile() {
    this.errorFileEvent.emit();
    this.chdRef.detectChanges();
  }

}
