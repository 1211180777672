import { Component, ViewChild, OnInit, AfterViewInit, HostListener, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import { _DeepPartialObject } from 'chart.js/types/utils';

@Component({
  selector: 'app-income-annual-graphic',
  templateUrl: './income-annual-graphic.component.html',
  styleUrls: ['./income-annual-graphic.component.scss'],
})
export class IncomeAnnualGraphicComponent implements OnInit {

  @Input() public data;

  public context: CanvasRenderingContext2D;
  labelList = [];
  pointRadiusList = [];
  staticData = [];
  colorGridLines = [];
  chart: any;
  ctx: any;

  incomesEstimateArray: any;
  incomesArray : any;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.incomesEstimateArray = this.data.map(item => item.data_estimate);
    this.incomesArray = this.data.map(item => item.data);
    for(let i = 0; i < this.incomesArray.length; i++) {
      this.labelList.push('');
      this.staticData.push(12.4);
      if (i === 8) {
        this.colorGridLines.push('#2E7EFF');
        this.pointRadiusList.push(0);
      } else {
        this.colorGridLines.push('white');
        this.pointRadiusList.push(0);
      }
    }
    this.createGrap();
  }

  createGradient(startColor: string, endColor: string): CanvasGradient {
    const ctx = document.createElement('canvas').getContext('2d') as CanvasRenderingContext2D;
    const gradient = ctx.createLinearGradient(0, 0, 0, 130); // Modifica las dimensiones del gradiente
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1, endColor);
    return gradient;
  }

  createGrap() {
    const gradient1 = this.createGradient('rgba(255, 92, 47, 0.3)', 'rgba(255, 255, 255, 0.3)'); // Gradiente para el segundo conjunto de datos
    const gradient2 = this.createGradient('rgba(255, 205, 62, 0.3)', 'rgba(255, 255, 255, 0.3)'); // Gradiente para el primer conjunto de datos
    const animation: _DeepPartialObject<any> = this.getAnimation();
    Chart.register(...registerables);
    const dataConfig = {
      labels: this.labelList,
      datasets: [
        {
          data: this.incomesArray, //this.mockPoints,
          borderColor: '#FF6C3E', // Los naranjas #FF679B #FF5C2F (mejor) #FF6C3E
          backgroundColor: gradient1,
          borderWidth: 2,
          pointRadius: 0,
          fill: true
        },
        {
          data: this.incomesEstimateArray, //this.mockPoints2,
          borderColor: '#FFCD3E', // Los amarillos de #FFCD3E a #FFF1BA ,
          backgroundColor: gradient2,
          borderWidth: 2,
          pointRadius: 0,
          fill: true
        },
      ]
    };
    const optionsConfig = {
      animation,
      responsive: false,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false
        }
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      scales: {
        y: {
          display: false
        },
        x: {
            display: false,
        }
      }
    }
    const config: ChartConfiguration = {
      type: 'line',
      data: dataConfig,
      options: optionsConfig
    }
    const chartItem: ChartItem = document.getElementById('myChartAnnual') as ChartItem;
    new Chart(chartItem, config);
    this.chdRef.detectChanges();
  }

  getAnimation() {
    const totalDuration = 1000;
    const delayBetweenPoints = totalDuration / this.staticData.length;
    const previousY = (ctx) => ctx.index === 0 ? ctx.chart.scales.y.getPixelForValue(100) : ctx.chart.getDatasetMeta(ctx.datasetIndex).data[ctx.index - 1].getProps(['y'], true).y;
    return {
      x: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: NaN, // the point is initially skipped
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.xStarted) {
            return 0;
          }
          ctx.xStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      },
      y: {
        type: 'number',
        easing: 'linear',
        duration: delayBetweenPoints,
        from: previousY,
        delay(ctx) {
          if (ctx.type !== 'data' || ctx.yStarted) {
            return 0;
          }
          ctx.yStarted = true;
          return ctx.index * delayBetweenPoints;
        }
      }
    };
  }

}
