<div class="register">
    <div class="body">
        <div class="date-container">
            <div class="inp">
                <fun-input
                    *ngIf="!isMobile" 
                    placeholder="{{ 'register.nationality.not_specified' | translate }}"
                    [parentForm]="selectForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="status"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.civil_status.title' | translate }}"
                    (selectChange)="statusSelected()"
                    (keyup)="statusSelected()"
                    (click)="statusSelected()"
                    [items]="statusList"
                    [selectedItem]="status"
                    [disabled]="disabled"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="selectForm">
                    <div class="inp select form-input">
                        <label>{{ 'register.civil_status.title' | translate }}</label>
                        <select class="select-box" [ngClass]="{'option' : hasStatusPrev}"
                        name="status" formControlName="status" (change)="statusSelected()" [value]="status">
                            <option hidden [value]="null">{{ 'register.nationality.not_specified' | translate }}</option>
                            <option *ngFor="let data of statusList"
                            [value]="data.id">
                                {{ data.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>