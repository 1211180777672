import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'fun-skeleton-update',
  templateUrl: './skeleton-update.component.html',
  styleUrls: ['./skeleton-update.component.scss']
})
export class SkeletonUpdateComponent {


}
