import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'fun-register-company-contact-person',
  templateUrl: './register-company-contact-person.component.html',
  styleUrls: ['./register-company-contact-person.component.scss']
})
export class RegisterCompanyContactPersonComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;

  public contactPersonForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public notFullSurname = false;
  public isFocusLost = false;

  public businessPower;

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    const pickerElement = (document.querySelectorAll('body > fun-modal > div > div.modal > div.content-modal > fundeen-register > fun-register-company > div > div.body-content > div > fun-register-company-contact-person > div > div > div.person-contact-container.ng-dirty.ng-invalid.ng-touched > div:nth-child(3) > fun-input > div > div > div > dp-date-picker > div:nth-child(1) > div > input')[0] as HTMLElement);
    if (this.contactPersonForm.valid) {
      this.sendInfoEvent.emit(this.contactPersonForm.value);
      this.submitEvent.emit();
    } else {
      if ((event.target as Element).id === 'name') {
        document.getElementById('surname').focus();
      } else if ((event.target as Element).id === 'surname') {
        pickerElement.focus();
        this.focusLost();
      } else if (event.target === pickerElement) {
        document.getElementById('cb1').focus();
      }
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    public dateService: DateService
  ) { }

  ngOnInit() {
    this.contactPersonForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      surname: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      date: new FormControl('', [Validators.required]),
      businessPower: new FormControl(false, [Validators.requiredTrue])
    });
    this.formControlKeys = Object.keys(this.contactPersonForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getInfo() {
    if (this.contactPersonForm.valid && this.contactPersonForm.value.businessPower === true) {
      const model = {
        name: this.contactPersonForm.value.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        surname: this.contactPersonForm.value.surname.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        date: this.contactPersonForm.value.date,
        businessPower: this.contactPersonForm.value.businessPower
      };
      this.sendInfoEvent.emit(model);
    } else {
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  goToLink() {
    const urlES = 'https://help.fundeen.com/quién-es-el-apoderado-y-cuáles-son-sus-funciones';
    const urlEN = 'https://help.fundeen.com/en/articles/who-is-the-attorney-in-fact-and-what-are-their-functions';
    if (this.translate.currentLang === 'es') {
      window.open(urlES);
    } else {
      window.open(urlEN);
    }
  }

  focusLost() {
    this.isFocusLost = true;
    if (this.contactPersonForm.value.surname !== undefined && this.isFocusLost) {
      if (this.contactPersonForm.value.surname.includes(' ')) {
        this.notFullSurname = false;
      } else {
        this.notFullSurname = true;
      }
    }
    this.chdRef.detectChanges();
  }

}
