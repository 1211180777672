import { Injectable } from '@angular/core';
import { HelpService } from 'src/app/services/help.service';

@Injectable({
  providedIn: 'root',
})
export class GraphicsService {
  public currentGraphic: string;

  constructor(public helpService: HelpService) {}

  getCurrentGraphic() {
    switch (this.currentGraphic) {
      case 'daily-production':
        this.helpService.openHelp('first', 0, 'DASHBOARD_GRAPHIC-DAILYPROD');
        break;
      case 'daily-income':
        this.helpService.openHelp('first', 0, 'DASHBOARD_GRAPHIC-DAILYINCOME');
        break;
      case 'yearly-revenues':
        this.helpService.openHelp('first', 0, 'DASHBOARD_GRAPHIC-YEARLYINCOME');
        break;
      case 'production':
        this.helpService.openHelp('first', 0, 'DASHBOARD_GRAPHIC-PRODUCTION');
        break;
    }
  }
}
