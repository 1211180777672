import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import * as momentNs from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { MarketPlaceOfferModel } from 'src/app/shared/models/marketplace.models';
const moment = momentNs;

@Component({
  selector: 'fun-project-detail-row',
  templateUrl: './project-detail-row.component.html',
  styleUrls: ['./project-detail-row.component.scss']
})
export class ProjectDetailRowComponent implements OnInit {

  @Input() marketPlaceOffer: MarketPlaceOfferModel;
  @Input() hasWalletOk: MarketPlaceOfferModel;
  @Output() private emitMarketPlaceOffer = new EventEmitter<MarketPlaceOfferModel>();
  public remainDays: number;

  @ViewChild('bid', { static: false }) bid: ElementRef;
  elements: any;
  isShow = true;
  avatarColor: string;
  acc: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    registerLocaleData(es);
    const today = moment( moment(new Date()).format('YYYY/MM/DD'), 'YYYY/MM/DD');
    const endDate = moment(this.marketPlaceOffer.closing_date, 'YYYY/MM/DD');
    this.remainDays = endDate.diff(today, 'days');
    this.randomAvatarColor();
    this.controlWidth();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.controlWidth();
  }
  randomAvatarColor() {
    const colorList = ['green', 'blue', 'red', 'brown'];
    this.avatarColor = colorList[Math.floor(Math.random() * 4)];
  }

  selected(el) {
    this.elements.forEach(element => {
      if (el.id === element.nativeElement.id) {
        element.nativeElement.setAttribute('class', 'selected');
      } else {
        element.nativeElement.setAttribute('class', 'link');
      }
    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  onClickPujar() {
    this.emitMarketPlaceOffer.emit(this.marketPlaceOffer);
  }

  controlWidth() {
    if (window.screen.availWidth <= 992) {
      this.acc = this.translate.instant('bid-create.shares');
    } else {
      this.acc = '';
    }
  }

}
