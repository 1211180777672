import { Component, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ToastService } from './toast.service';

@Component({
  selector: 'fun-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, OnDestroy {

  private intervalId: any;
  type: string;
  toastText: string;
  indexRef: number;

  baseIcon: string;
  baseText: string;
  baseContent: string;
  classIcon: string;
  classText: string;
  classContent: string;

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.baseIcon = 'w-24 h-24 stroke-2';
    this.baseText = 'font-sans tex-l pl-8';
    this.baseContent = 'flex justify-between items-center content rounded-l bg-white p-12';
    this.chdRef.detectChanges();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.close();
      }
    });
  }

  ngOnDestroy() {
    clearTimeout(this.intervalId);
    this.chdRef.detectChanges();
    this.close();
  }

  setText(text: string): void {
    this.toastText = text;
    this.chdRef.detectChanges();
  }

  setType(type: string): void {
    this.type = type;
    switch (this.type) {
      case 'warning':
        this.classIcon = `${this.baseIcon} stroke-[#FD565B]`;
        this.classText = `${this.baseText} text-[#FD565B]`;
        this.classContent = `${this.baseContent} border border-[#FD565B]`;
        //
        break;
      case 'info':
        this.classIcon = `${this.baseIcon} stroke-[#A9A1A5]`;
        this.classText = `${this.baseText} text-[#A9A1A5]`;
        this.classContent = `${this.baseContent} border border-[#A9A1A5]`;
        break;
      case 'check':
        this.classIcon = `${this.baseIcon} stroke-[#66334E]`;
        this.classText = `${this.baseText} text-[#66334E]`;
        this.classContent = `${this.baseContent} border border-[#66334E]`;
        break;
      case 'success':
        this.classIcon = `${this.baseIcon} stroke-[#39C847]`;
        this.classText = `${this.baseText} text-[#39C847]`;
        this.classContent = `${this.baseContent} border border-[#39C847]`;
        break;
    }
    this.chdRef.detectChanges();
  }

  setTimeout() {
    this.intervalId = setTimeout(() => this.close(), 5000);
    this.chdRef.detectChanges();
  }

  setIndexRef(index: number) {
    this.indexRef = index;
    this.chdRef.detectChanges();
  }

  close() {
    this.toastService.removeToast(this.indexRef);
    this.chdRef.detectChanges();
  }

}
