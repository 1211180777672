<div *ngIf="!loading" class="flex flex-col h-full">
    <div class="items-start general-container">
        <div class="container header">
            <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px] text-center bg-gradient-2-text">E-mail</h2>
            <p class="font-sans text-center text-xl text-gray-40">{{ 'pwd.recover' | translate }}</p>
        </div>
        <div class="input-container">
            <form [formGroup]="passwordForm">
                <div class="row">
                    <div class="col-12 flex flex-col gap-16">
                         <fun-input
                         placeholder="{{ 'LOGIN.PASSWORD' | translate }}"
                         [parentForm]="passwordForm"
                         [formControlKeys]="formControlKeys"
                         [errorMessages]="errorMessages"
                         [isSubmit]="isSubmit"
                         controlName="pwd1"
                         type="password"
                         [errorLogin]="errorPwd1"
                         labelName="{{ 'LOGIN.PASSWORD' | translate }}"
                          ></fun-input>
                          <fun-input
                          class="second-inp"
                          placeholder="{{ 'pwd.new_pwd' | translate }}"
                          [parentForm]="passwordForm"
                          [formControlKeys]="formControlKeys"
                          [errorMessages]="errorMessages"
                          [isSubmit]="isSubmit"
                          controlName="pwd2"
                          type="password"
                          [errorLogin]="errorPwd2"
                          labelName="{{ 'pwd.new_pwd' | translate }}"
                           ></fun-input>
                           <fun-input
                          class="second-inp"
                          placeholder="{{ 'pwd.repeat_pwd' | translate }}"
                          [parentForm]="passwordForm"
                          [formControlKeys]="formControlKeys"
                          [errorMessages]="errorMessages"
                          [isSubmit]="isSubmit"
                          controlName="pwd3"
                          type="password"
                          [errorLogin]="errorPwd3"
                          labelName="{{ 'pwd.repeat_pwd' | translate }}"
                           ></fun-input>
                    </div>
                </div>
            </form>
            <p class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6" (click)="forgotPwd()">{{ 'pwd.forgoten' | translate }}</p>
        </div>
    </div>

    <div *ngIf="!loading" class="footer items-end">
        <div class="next">
            <fun-new-button [buttonType]="btnType" icon="arrow-right" [iconPosition]="'right'" [classExtra]="' !w-full '"
            title="{{ 'btn.send' | translate }}" size="xl"  (eventClick)="submit()"></fun-new-button>
        </div>
    </div>
</div>

<div class="spinner" *ngIf="loading">
    <fun-spinner></fun-spinner>
</div>
