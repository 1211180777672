<div class="general-container" *ngIf="step === 0 && !loading">
  <div class="content">
      <div>
        <fun-credit-card (cardFormValidEvent)="cardFormValidated($event)" (cardFormInvalidEvent)="invalidCardForm()"></fun-credit-card>
      </div>
  </div>
  <div class="button-container">
      <div class="prev">
          <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
          [buttonType]="prevBtnType" title="{{'register.btn.back' | translate}}"></fun-new-button>
      </div>
      <div class="next">
        <fun-new-button size="xl" (eventClick)="investByCard()" [classExtra]="' !w-full '"
        [buttonType]="continueBtnType" title="{{'register.btn.continue' | translate}}"></fun-new-button>
      </div>
  </div>
</div>
<div class="general-container" *ngIf="step === 1">
  <div *ngIf="iframeLoaded === false">
    <fun-spinner></fun-spinner>
  </div>
  <iframe
    [src]="urlSafe"
    frameborder="0"
    (load)="charged($event)"
    #iframe
    allowfullscreen
    webkitallowfullscreen
    mozallowfullscreen
  ></iframe>
</div>

<div *ngIf="step === 2">
  <div *ngIf="!loading">
    <fun-overal-message [text]="text" [image]="image" [btnList]="btnList" (btnEvent)="clickBtn($event)"></fun-overal-message>
  </div>
</div>

<div *ngIf="loading">
    <fun-spinner></fun-spinner>
</div>