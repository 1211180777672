import { AnimationController } from '@ionic/angular';

export const myEnterAnimationGoUp = (baseEl: any) => {
    const backdropAnimation = new AnimationController()
      .create()
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', 0.01, 0.4);

    // Animación de la modal
    const wrapperAnimation = new AnimationController()
      .create()
      .beforeStyles({ 'opacity': 1, 'transform': 'translateY(100%)' })
      .addElement(baseEl.querySelector('.modal-wrapper'))
      .fromTo('transform', 'translateY(100%)', 'translateY(6%)')
      .fromTo('opacity', 0.01, 1);

    // Animación del contenido
    const contentAnimation = new AnimationController()
      .create()
      .beforeStyles({ 'opacity': 1 })
      .addElement(baseEl.querySelector('.modal-wrapper ion-content'))
      .fromTo('opacity', 0.01, 1)
      .fromTo('transform', 'translateY(50%)', 'translateY(6%)')

    // Animación de la pantalla
    const animationPage = new AnimationController()
      .create()
      .addElement(document.querySelector('ion-tabs'))
      .duration(300)
      .easing('ease-out')
      .fromTo('transform', 'scale3d(1, 1, 1)', 'scale3d(0.95, 0.95, 0.95)')

    return new AnimationController()
      .create()
      .addElement(baseEl)
      .easing('cubic-bezier(0.36,0.66,0.04,1)')
      .duration(500)
      .addAnimation([backdropAnimation, wrapperAnimation, contentAnimation, animationPage]);
};

export const myLeaveAnimationGoUp = (baseEl: any) => {
    return myEnterAnimationGoUp(baseEl).direction('reverse');
};

export const myEnterAnimationAppearFromCenter = (baseEl: any) => {
  const backdropAnimation = new AnimationController()
  .create()
  .addElement(baseEl.querySelector('ion-backdrop'))
  .fromTo('opacity', 0.01, 0.4);

  const detailpAnimation = new AnimationController()
  .create()
  .addElement(baseEl.querySelector('fun-new-detail-overview'))
  .fromTo('opacity', 0.01, 1);

  const wrapperAnimation = new AnimationController()
    .create()
    .beforeStyles({ 'opacity': 1 })
    .addElement(baseEl.querySelector('.modal-wrapper'))
    .fromTo('transform', 'scale(0)', 'scale(1)')
    .fromTo('opacity', 0.01, 1);

  return new AnimationController()
    .create()
    .addElement(baseEl)
    .easing('cubic-bezier(0.36,0.66,0.04,1)')
    .duration(500)
    .addAnimation([backdropAnimation, wrapperAnimation, detailpAnimation]);
};

export const myLeaveAnimationAppearFromCenter = (baseEl: any) => {
    return myEnterAnimationAppearFromCenter(baseEl).direction('reverse');
};
