import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxCurrencyModule } from 'ngx-currency';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/auth.guard';
import { LangGuard } from './guards/lang.guard';
// Interceptors
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AppConfigurationService } from './services/app.configuration.service';
import { AuthService } from './services/auth.service';
import { CommunicationService } from './services/communication.service';
import { DataLayerService } from './services/data-layer.service';
//import { PosthogService } from './services/posthog.service';
import { SharedModule } from './shared/shared.module';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { GlobalErrorHandlerService } from './services/global-error-handler.service';
import { environment } from 'src/environments/environment';
//import { RollbarService, rollbarFactory, RollbarErrorHandler } from './shared/other/rollbar';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { HubspotService } from './services/hubspot.service';

registerLocaleData(es);

export function appTranslateLoader(http: HttpClient) {
  const localeEnv = environment.production ? 'production' : 'latest';
  return new MultiTranslateHttpLoader(http, [
    { prefix: 'https://cdn.simplelocalize.io/d533b649994c4552902fc4174580d421/_' + localeEnv + '/', suffix: '' },
    //{ prefix: 'https://cdn.simplelocalize.io/cd10bdef0059460fa9197a2e2a6516ed/_' + localeEnv + '/', suffix: '' }, // fundeen_ui
    //{ prefix: './assets/i18n/', suffix: '.json?v=' + Date.now() },
    //{ prefix: './assets/i18n/library/', suffix: '.json?v=' + Date.now() },
  ]);
}

/**
 * Load configuration from environment folder
 * @param startupService
 */
export function startupServiceFactory(startupService: AppConfigurationService): Function {
  return () => {
    return startupService.loadConfiguration();
  };
}

/* export function localizeLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  return new ManualParserLoader(translate, (location), settings, ['en', 'es'], 'URL.');
} */

@NgModule({
  declarations: [AppComponent],
  exports: [],
  imports: [
    IonicStorageModule.forRoot(),
    NgxCurrencyModule,
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: appTranslateLoader,
        deps: [HttpClient],
      },
    }),
    RecaptchaV3Module
  ],
  providers: [
    AppConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: startupServiceFactory,
      deps: [AppConfigurationService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    CommunicationService,
    AuthGuard,
    DataLayerService,
    GlobalErrorHandlerService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    LangGuard,
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LckZuIjAAAAAHRTCTnttnwaQp0HG-Jl2uORJAcP' },
    HubspotService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
