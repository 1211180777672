import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fun-content-featured',
  templateUrl: './content-featured.component.html',
  styleUrls: ['./content-featured.component.scss'],
})
export class ContentFeaturedComponent implements OnInit {

  @Input() icon;
  @Input() size;
  @Input() title;
  @Input() content;
  @Input() version = 'dark';

  iconStyles = {
    'xl': 'w-[66.67px] h-[66.67px]',
    'l-horizontal': 'w-[32px] h-[32px] md:w-[48px] md:h-[48px]',
    'l-vertical': 'w-[48px] md:h-[48px]',
    'm': 'w-[40px] h-[40px]',
    's': 'w-[26.67px] h-[26.67px]',
    'left': 'pt-24 pr-12 md:p-0',
    'check-style': 'fill-eggplant-100'
  };

  textStyles = {
    'xl': 'text-h500',
    'l-horizontal': 'text-h400 md:text-h600',
    'l-vertical': 'text-h400 md:text-h600', // OJO con esto
    'm': 'text-h400',
    'normal': 'text-eggplant-100',
    'dark': 'text-eggplant-80',
    'light': 'text-white',
    'position-top': 'pt-0 md:pt-20',
    'position-left': ''
  };

  contentStyles = {
    'xl': 'text-l',
    'l': 'text-m md:text-l',
    'm': 'text-m',
    'dark': 'text-eggplant-100/[0.4] md:text-eggplant-100',
    'check-style': '!text-eggplant-100/[0.4]'
  };

  textContentClass = 'flex flex-col gap-16 md:gap-24';

  iconClass: string;
  textClass: string;
  contentClass: string;

  constructor() { }

  ngOnInit() {
    this.iconClass = `stroke-2 stroke-eggplant-100 ${this.iconStyles[this.size]}`;
    this.textClass = `text-left font-sans ${this.textStyles[this.size]} ${this.textStyles[this.version]}`;
    this.contentClass = `text-left font-sans text-eggplant-100 ${this.contentStyles[this.size]} ${this.contentStyles[this.version]}`;
  }

}
