<div [ngClass]="isShow ? '' : 'open'" class="offers-content children:font-sans children:text- children:text-eggplant-80">
    <p class="seller"> <span  [class]="avatarColor" class="profile">{{ marketPlaceOffer.name | nameProfile }}</span> {{ marketPlaceOffer.name | titlecase }}</p>
    <p class="share">{{ marketPlaceOffer.amount | localeNumber }} {{ acc }} </p>
    <p class="em-value"><span class="hidden-lg">{{ 'project-detail.value' | translate }}</span>{{ marketPlaceOffer.adquisition_price | localeNumber }} €</p>
    <p class="bid"><span class="hidden-lg">{{ 'project-detail.bids' | translate }}</span>{{ marketPlaceOffer.current_bids }}</p>
    <p class="mode"><span class="hidden-lg">{{ 'project-detail.formalization' | translate }}</span>
        <span *ngIf="marketPlaceOffer.formalization_type == 'internal'">{{ 'project-detail.internal' | translate }}</span>
        <span *ngIf="marketPlaceOffer.formalization_type == 'external'">{{ 'project-detail.external' | translate }}</span>
    </p>
    <p class="time-r"><span class="hidden-lg">{{ 'project-detail.time-remaining' | translate }}</span>
        <span>{{ remainDays }} </span>
        <span *ngIf="remainDays > 1">{{ 'project-detail.days' | translate }}</span>
        <span *ngIf="remainDays == 1">{{ 'project-detail.day' | translate }}</span>
    </p>
    <p class="action">
        <!--<a class="ico ico-edit"></a>-->
        <a *ngIf="!marketPlaceOffer.has_bids && hasWalletOk" (click)="onClickPujar()" class="text-orange-100 cursor-pointer">{{ 'project-detail.create-bid' | translate }}</a>
    </p>
    <p (click)="toggleDisplay()" class="show-more hidden-lg"> <i class="ico ico-arrow"></i> </p>
</div>
