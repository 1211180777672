import { Component, OnInit, Input } from '@angular/core';
import { BadgeService } from 'src/app/services/badge.service';
import { BadgeModel } from '../../models/badge.model';

@Component({
  selector: 'fun-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {

  @Input() title: string;

  badgeProps: any;
  hasBadge = false;

  constructor(private badgeService: BadgeService) { }

  ngOnInit() {
    this.getBadgeProps();
  }

  async getBadgeProps() {
    if (this.badgeService.hasBadge) {
      this.badgeProps = this.badgeService.badgeContent;
      this.hasBadge = true;
    } else {
      await this.badgeService.hasBadgeEvent.subscribe((badge: BadgeModel) => {
        this.badgeProps = badge;
        this.hasBadge = true;
      });
    }
  }

}
