import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateDate'
})

export class TruncatePipe implements PipeTransform {
  transform(value: any): string {
    return value.split(' ')[0];
  }
}