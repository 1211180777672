import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'fun-register-ifame',
  templateUrl: './register-ifame.component.html',
  styleUrls: ['./register-ifame.component.scss']
})
export class RegisterIfameComponent implements OnInit {

  @Input() isMobileApp: any;
  @Input() url: any;
  @Output() public signEvent = new EventEmitter<any>();
  @Output() public signErrorEvent = new EventEmitter<any>();
  @Output() public startSignEvent = new EventEmitter<any>();
  @Output() public signaturitIssueEvent = new EventEmitter<any>();
  @Output() public initEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  urlSafe: SafeResourceUrl;
  isCharged: boolean;
  hasUrl = false;
  mobApp: string;

  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isCharged = false;
    this.mobApp = (this.isMobileApp !== undefined) ? 'mob_app' : 'content-ifr';
    if (this.url && this.url !== undefined) {
      this.startSign(this.url);
    } else {
      this.initEvent.emit();
    }
    this.cdRef.detectChanges();
  }

  public startSign(url) {
    this.isCharged = true;
    this.urlSafe = url;
    this.hasUrl = true;
    this.cdRef.detectChanges();
  }

  isSigned(value) {
    this.signEvent.emit();
    this.cdRef.detectChanges();
  }

  signError() {
    this.signErrorEvent.emit();
    this.cdRef.detectChanges();
  }

  charged(event) {
    if (event.type === 'load') {
      this.isCharged = true;
    }
    this.cdRef.detectChanges();
  }

}
