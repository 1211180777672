<div class="container px-24 md:pt-0 pb-24">
    <div class="row">
        <div *ngIf="hasOffers" class="table-container">

            <div *ngIf="hasOffers" class="tables offers-container">
                <div class="offers-header children:font-sans children:text-l children:text-eggplant-100/[0.55]">
                    <h2 class="seller">{{ 'project-detail.seller' | translate }}</h2>
                    <h2 class="share">{{ 'project-detail.shares' | translate }}</h2>
                    <h2 class="em-value">{{ 'project-detail.value' | translate }}</h2>
                    <h2 class="bid">{{ 'project-detail.bids' | translate }}</h2>
                    <h2>{{ 'project-detail.formalization' | translate }}</h2>
                    <h2>{{ 'project-detail.time-remaining' | translate }}</h2>
                    <h2></h2>
                </div>
                <ng-container *ngFor="let marketPlaceOffer of project.offers">
                    <fun-project-detail-row [marketPlaceOffer]="marketPlaceOffer" [hasWalletOk]="hasWalletOk" (emitMarketPlaceOffer)="onEmitCreateBid($event)"></fun-project-detail-row>
                </ng-container>
            </div>

            <div *ngIf="hasOffers" class="button-container flex justify-center items-center pb-32">
                <fun-new-button [buttonType]="btnType" title="{{ 'project-detail.public-offer' | translate }}" class="button" (eventClick)="createOffer()" [size]="'xl'"></fun-new-button>
            </div>
        </div>
        <div *ngIf="!hasOffers" class="no-projects table-container col-12">
            <img src="../../../../../assets/images/illustrations/touch-id.svg" alt="No marketplace" />
            <h3 class="font-sans text-eggplant-100 text-2xl md:text-3xl">{{ 'project-detail.No_project_title' | translate }}</h3>
            <p class="font-sans text-l text-eggplant-100/[0.55]">{{ 'project-detail.No_project_text' | translate }}</p>
            <div class="flex justify-center items-center pb-32">
                <fun-new-button [buttonType]="btnType" title="{{ 'project-detail.public-offer' | translate }}" class="button" (eventClick)="createOffer()" [size]="'xl'"></fun-new-button>
            </div>
        </div>
    </div>
</div>