import { Component, OnInit, Input } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-movement-detail',
  templateUrl: './movement-detail.component.html',
  styleUrls: ['./movement-detail.component.scss'],
})
export class MovementDetailComponent implements OnInit {

  @Input() movement: any;

  constructor(private utils: UtilsService) {}

  ngOnInit() {
    const month = Number(this.movement['dateAccountant'].substring(3,5));
    const dateParse =  `${this.movement['dateAccountant'].substring(0,2)} ${this.utils.parseMonthShort(month)}`;
    this.movement['dateParse'] = dateParse;
  }

}
