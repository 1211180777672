import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-renew-session',
  templateUrl: './renew-session.component.html',
  styleUrls: ['./renew-session.component.scss']
})
export class RenewSessionComponent implements OnInit {

  @Output() public aceptEvent = new EventEmitter<any>();
  @Output() public cancelEvent = new EventEmitter<any>();

  public cashForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public moneyOutInfo;
  public skeletonActive = false;
  public sendXHR;
  public cancelBtn;
  public disableInput = false;

  constructor(public translate: TranslateService, private chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.cancelBtn = 'primaryLight';
    this.sendXHR = 'primaryDark';
    this.cashForm = new FormGroup({
      password: new FormControl('', [
        Validators.required
      ]),
      concept: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    this.formControlKeys = Object.keys(this.cashForm.controls);
    this.errorMessages = {
      required: this.translate.instant('input-errors-messages.required')
    };
  }
  emitClick() {
    this.aceptEvent.emit(this.cashForm.value.password);
    this.disableInput = true;
    this.cancelBtn = 'primaryLight-disabled';
    this.sendXHR = 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }
  emitCancel() {
    this.cancelEvent.emit();
    this.chdRef.detectChanges();
  }
  public passwordError() {
    this.disableInput = false;
    this.isSubmit = true;
    this.errorAmount = true;
    this.cancelBtn = 'primaryLight';
    this.sendXHR = 'primaryDark';
    this.chdRef.detectChanges();
  }

}
