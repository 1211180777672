import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-register-header',
  templateUrl: './register-header.component.html',
  styleUrls: ['./register-header.component.scss'],
})
export class RegisterHeaderComponent implements OnInit {

  fillColor = '#FFFF';

  @Output() goPrevStep: EventEmitter<any> = new EventEmitter<any>();

  constructor(public location: Location, private utilsService: UtilsService) { }

  ngOnInit() {}

  goBack() {
    this.goPrevStep.emit();
  }

  close() {
    this.utilsService.navigateTo('/list-of-projects');
  }

  openHelp() {}

}
