import { Injectable } from '@angular/core';
import { ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token, } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import OneSignal from 'onesignal-cordova-plugin';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Storage } from '@ionic/storage';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';
import { ModalService } from '../shared/components/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {

  constructor(public storage: Storage, public modalService: ModalService, public translate: TranslateService) { }

  checkPushNotifications() {
    if (Capacitor.getPlatform() !== 'web' && environment.enablePushNotifications) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        }
      });

      PushNotifications.addListener('registration', (token: Token) => {
        console.log('Push registration success, token: ' + token.value);
      });

      PushNotifications.addListener('registrationError', (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      });

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));
        },
      );

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
        },
      );
    }
  }

  async activateOneSignal() {
    if (Capacitor.getPlatform() !== 'web' && environment.enablePushNotifications) {
      OneSignal.setAppId(environment.oneSignalId); 
      OneSignal.setNotificationOpenedHandler(function(jsonData) {
          //console.log('notificationOpenedCallback OneSignal: ' + JSON.stringify(jsonData));
      });
      const localStr = this.storage;
      localStr.get('DONT_WANT_PUSH').then(res => {
        if (res !== true) {
          OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
            if (accepted) {
              localStr.get('USERNAME').then(val => {
                if (val) {
                  const secret = environment.oneSignalKey;
                  const mailHas = CryptoJS.HmacSHA256(val, secret).toString(CryptoJS.enc.Hex);
                  OneSignal.setExternalUserId(val, mailHas, (results) => {});
                  OneSignal.disablePush(false);
                }
              });
            }
          });
        }
      });
    }
  }

  async setOneSignalMainData(mail) {
    if (Capacitor.getPlatform() !== 'web' && environment.enablePushNotifications) {
      const localStr = this.storage;
      localStr.get('DONT_WANT_PUSH').then(val => {
        if (val !== true) {
          const secret = environment.oneSignalKey;
          const mailHas = CryptoJS.HmacSHA256(mail, secret).toString(CryptoJS.enc.Hex);
          OneSignal.setExternalUserId(mail, mailHas, (results) => {});
          OneSignal.disablePush(false);
        }
      });
    }
  }

  async removeOneSignalMainData() {
    if (Capacitor.getPlatform() !== 'web' && environment.enablePushNotifications) {
      OneSignal.removeExternalUserId((results) => {});
      OneSignal.disablePush(true);
    }
  }

  async checkPushStatus() {
    return new Promise((resolve, reject) => {
      OneSignal.getDeviceState((state) => {
        resolve(state);
      });
    });
  }

  async togglePermissions() {
    if (Capacitor.getPlatform() !== 'web' && environment.enablePushNotifications) {
      return new Promise((resolve, reject) => {
        OneSignal.getDeviceState(val => {
          if (!val['hasNotificationPermission'] || !val['subscribed']) {
            OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
              if (accepted) {
                this.storage.get('USERNAME').then(val => {
                  if (val) {
                    const secret = environment.oneSignalKey;
                    const mailHas = CryptoJS.HmacSHA256(val, secret).toString(CryptoJS.enc.Hex);
                    OneSignal.setExternalUserId(val, mailHas, (results) => {
                      OneSignal.disablePush(false);
                      OneSignal.getDeviceState((state) => {
                        resolve(state);
                      });
                    });
                  }
                });
              } else {
                const msg = this.translate.instant('PUSH_NOTIFICATIONS.ADVISE');
                const modalParams = {
                  text: msg,
                  image: 8,
                  btnList: [
                    {
                      type: 'primaryDark',
                      index: 1,
                      title: 'Continuar',
                    },
                  ],
                };
                this.modalService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
                  modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
                    componentRef.btnEvent.subscribe((res) => {
                      this.modalService.removeModal();
                    });
                  });
                  modal.onClose().subscribe();
                });
                OneSignal.getDeviceState((state) => {
                  resolve(state);
                });
              }
            });
          } else {
            OneSignal.disablePush(true);
            OneSignal.removeExternalUserId((results) => {
              OneSignal.getDeviceState((state) => {
                resolve(state);
              });
            });
          }
        });
      });
    }
  }

}
