<div class="general-container">
    <div class="content">
        <div class="image">
            <img *ngIf="alert === 'bid'" src="../../../../assets/images/illustrations/notifications.svg" alt="no bids image">
            <img *ngIf="alert === 'offer'" src="../../../../assets/images/illustrations/plants.svg" alt="money grow">
        </div>
        <div class="general-content">
            <p *ngIf="alert === 'bid'">{{ 'bid.no-bids' | translate }}</p>
            <p *ngIf="alert === 'offer'">{{ 'offer.no-offer' | translate }}</p>
            <div>
                <fun-new-button *ngIf="alert === 'bid'" buttonType="primaryDark" title="{{ 'bid.view-projects' | translate }}" class="button" (eventClick)="onEventClick()">
                </fun-new-button>
                <fun-new-button *ngIf="alert === 'offer'" buttonType="primaryDark" title="{{ 'offer.create-offer-button' | translate }}" class="button" (eventClick)="makeOffer()">
                </fun-new-button>
            </div>
        </div>
    </div>
</div>
