import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fundeen-empty-alert',
  templateUrl: './empty-alert.component.html',
  styleUrls: ['./empty-alert.component.scss']
})
export class EmptyAlertComponent implements OnInit {

  @Input() public alert;
  @Output() private viewProjects = new EventEmitter<any>();
  @Output() private makeAnOffer = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }
  onEventClick() {
    this.viewProjects.emit();
  }
  makeOffer() {
    this.makeAnOffer.emit();
  }

}
