import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import {
    AppTrackingTransparency,
    AppTrackingStatusResponse,
  } from 'capacitor-plugin-app-tracking-transparency';
import { ModalService } from '../shared/components/modal/modal.service';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private modalService: ModalService, private translate: TranslateService) { }

  async requestATT() {
		if (Capacitor.getPlatform() === 'ios') {
			const status = this.getStatus();
			if((await status).status === 'notDetermined') {
				const txt = this.translate.instant('TRACKING.TITLE') + this.translate.instant('TRACKING.TXT_1') + this.translate.instant('TRACKING.TXT_2');
				const modalParams = {
                    text: txt,
                    //image: 8,
                    btnList: [
                        {
                            type: 'primaryDark',
                            index: 1,
                            title: this.translate.instant('TRACKING.BTN'),
                        }
                    ],
                };
				this.modalService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
                    modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
                        componentRef.btnEvent.subscribe(() => {
                            this.modalService.removeModal();
                            this.requestPermission();
                        });
                    });
                    modal.closeEvent.subscribe(() => {
                      this.requestPermission();
                    });
                    modal.onClose().subscribe();
                });
			}
		}
	}
    public async getStatus(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.getStatus();
        return response;
    }

    public async requestPermission(): Promise<AppTrackingStatusResponse> {
        const response = await AppTrackingTransparency.requestPermission();
        return response;
    }
}
