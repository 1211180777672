import { DOCUMENT } from '@angular/common';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  OnChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
  Output,
  Inject,
  Renderer2
} from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import * as momentNs from 'moment';
import { DateService } from 'src/app/services/date.service';
import { conditionalValidator } from '../movements/money-out/money-out-change-account/money-out-change-account.validator';

const moment = momentNs;
declare const google: any;
const gmapsApiKey = 'AIzaSyBc9hWf6eyhpxuMNMS5dlSjL0a-AWV79Q0';
const gmapsApiKeyRestricted = 'AIzaSyBKSdJIRTRXM42G5gEhis7VbVUNCjq6vbE';

/** @dynamic */
@Component({
  selector: 'fun-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('input', {static: false}) input: ElementRef;
  @ViewChild('day', {static: false}) day: ElementRef;
  @ViewChild('month', {static: false}) month: ElementRef;
  @ViewChild('year', {static: false}) year: ElementRef;

  @Output() selectChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressPlaced: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input() type;
  @Input() isIban;
  @Input() isBIC;
  @Input() labelName;
  @Input() placeholder;
  @Input() disabled;
  @Input() controlName: string;
  @Input() parentForm: FormGroup;
  @Input() formControlKeys = [];
  @Input() errorMessages = null;
  @Input() isSubmit: boolean;
  @Input() errorLogin: boolean;
  @Input() value;
  @Input() id;
  @Input() items;
  @Input() clearable = false;
  @Input() searchable = false;
  @Input() selectedItem;
  @Input() isDesktop: boolean;
  @Input() size = 'l';
  @Input() suffix = '';
  @Input() searchMode = false;
  @Input() classExtra = ' ';
  @Input() checkIsAdult = true;
  @Input() maxWidthDefault = true;

  public dateForm: FormGroup;

  borderType = 'empty';
  labeltype = 'labelDefault';
  idInp: string;
  valueDt: any;
  hasBeenSelected = false;
  autocomplete: any;

  displayDate;
  createdDateForm = false;

  selectedDateInputs = [false, false, false];
  validDateInputs = [true, true, true];
  invalidDate = false;

  inputStyles = {
    'base-select': 'block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill invalid:border-alert invalid:text-alert py-4 pl-12 pr-28',
    'base-date': 'block w-full font-sans bg-white text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill invalid:border-alert invalid:text-alert py-8 pl-12 pr-28',
    'base': 'bg-white block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill py-8 pl-12 pr-28',
    'xl': 'text-xl',
    'l': 'text-l',
    'm': 'text-m'
  };

  labelStyles = {
    'base': 'block w-full font-sans text-eggplant-80',
    'base-error': 'block w-full font-sans text-orange-100',
    'xl': 'text-l',
    'l': 'text-l',
    'm': 'text-m'
  };

  labelClass: string;
  labelErrorClass: string;
  inputClass: string;
  inputDateClass: string;
  inputSelectClass: string;
  showPwd = false;

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.type === 'date') {
      //this.datePicker.api.close();
    }
  }

  constructor(
    private chdRef: ChangeDetectorRef,
    private dateService: DateService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2
    ) { }

  ngOnInit() {
    if (this.disabled) {
      this.inputStyles['base-date'] = 'block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 bg-cream-100/[.10] py-8 pl-12 pr-28';
      this.inputStyles.base = 'block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 bg-cream-100/[.10] py-8 pl-12 pr-28';
    }
    this.labelClass = `${this.labelStyles[this.size]} ${this.labelStyles.base}`;
    this.labelErrorClass = `${this.labelStyles[this.size]} ${this.labelStyles['base-error']}`;
    this.inputClass = `${this.inputStyles[this.size]} ${this.inputStyles.base} ${this.classExtra}`;
    this.inputDateClass = `${this.inputStyles[this.size]} ${this.inputStyles['base-date']}`;
    this.inputSelectClass = `${this.inputStyles[this.size]} ${this.inputStyles['base-select']}`;
    if (this.type === 'date') {
      this.generateDateForm();
      this.parentForm.controls[this.controlName].valueChanges.subscribe((resp) => {
          if (resp !== '') {
            this.dateForm.controls['day'].setValue(resp.split('/')[0].length === 1 ? '0' + resp.split('/')[0] : resp.split('/')[0]);
            this.dateForm.controls['month'].setValue(resp.split('/')[1].length === 1 ? '0' + resp.split('/')[1] : resp.split('/')[1]);
            this.dateForm.controls['year'].setValue(resp.split('/')[2]);
          } else {
            this.dateForm.controls['day'].setValue('');
            this.dateForm.controls['month'].setValue('');
            this.dateForm.controls['year'].setValue('');
          }
      });
    }
    if (this.type === 'invest') {
      this.suffix = 'euro';
    }
    if (this.type === 'password') {
      this.autocomplete = 'on';
      this.suffix = 'password';
    } else {
      this.autocomplete = 'off';
    }
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.disabled) {
      this.labeltype = 'labelDisabled';
      this.borderType = 'disable';
      this.input.nativeElement.disabled = true;
      if (this.type === 'date') {
        this.day.nativeElement.disabled = true;
        this.month.nativeElement.disabled = true;
        this.year.nativeElement.disabled = true;
      }
    }
    if (this.id && this.id !== undefined) {
      this.idInp = this.id;
    } else {
      this.idInp = this.controlName;
    }
    // Autocomplete GMaps
    if(this.idInp === 'addressAutocomplete') {
      this.loadGMapsAutoComplete();
    }
    this.chdRef.detectChanges();
  }

  ngOnChanges() {
    this.chdRef.detectChanges();
    this.setBorderType();
    this.valueChanged.emit();
  }

  @HostListener('document:keydown', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.chdRef.detectChanges();
    this.setBorderType();
  }

  onDateChanged(event?: any) {
    if (event && event !== undefined) {
      this.displayDate = event;
    } else {
      this.displayDate = moment(new Date());
    }
    this.changeValue();
  }

  changeValue() {
    if (this.type === 'select') {
      this.selectChange.emit();
    }
    this.chdRef.detectChanges();
    this.setBorderType();
    this.valueChanged.emit();
  }

  setBorderType() {
    if (this.disabled !== true) {
      setTimeout(() => {
        if ((
          this.parentForm.controls[this.controlName].touched
          && !this.parentForm.valid
          && this.parentForm.controls[this.controlName].errors)
          || this.errorLogin) {
          this.borderType = 'error';
          this.inputStyles['base'] = 'bg-white block w-full font-sans text-eggplant-80 border border-alert rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill py-8 pl-12 pr-28';
        } else if ( this.parentForm.controls[this.controlName].dirty
          && !this.parentForm.controls[this.controlName].errors && this.hasBeenSelected) {
          this.borderType = 'focused';
          this.inputStyles['base'] = 'bg-white block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill py-8 pl-12 pr-28';
        } else {
          this.borderType = 'empty';
          this.inputStyles['base'] = 'bg-white block w-full font-sans text-eggplant-80 border border-eggplant-75 rounded shadow-dimension placeholder:font-sans placeholder:text-l placeholder:text-eggplant-55 active:bg-cream-25 focus:!shadow-dimensionFocus focus:!bg-cream-25 focus:!border-eggplant-75 focus:!ring-0 autofill:!text-eggplant-80 autofill:!shadow-autofill py-8 pl-12 pr-28';
        }
        if (this.parentForm.controls[this.controlName].touched
          && (this.parentForm.controls[this.controlName].value === ''
          || this.parentForm.controls[this.controlName].value === null
          || this.parentForm.controls[this.controlName].value === undefined)) {
            this.borderType = 'empty';
          }
        this.chdRef.detectChanges();
      }, 50);
    } else {
      this.labeltype = 'labelDisabled';
      this.borderType = 'disable';
    }
    this.chdRef.detectChanges();
  }

  selectInput() {
    this.hasBeenSelected = true;
    this.chdRef.detectChanges();
  }
  unselectInput() {
    this.hasBeenSelected = false;
    this.chdRef.detectChanges();
  }

  keypressInput(event: any) {
    return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57
  }

  keyupInput() {
    if (this.type === 'invest') {
      let investValue = this.parentForm.controls[this.controlName].value;
      if((investValue !== '') && (investValue !== null) && (investValue !== undefined)) {
        investValue = (typeof investValue === 'string') ? investValue.replace(/\D/g, '') : investValue;
        const investFormated = Number(investValue).toLocaleString('es-ES', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          useGrouping: true
        });
        this.parentForm.controls[this.controlName].setValue(investFormated);
      }
      this.chdRef.detectChanges();
    }
  }

  public triggerKeyup() {
    this.keyupInput();
  }

  clear() {
    this.parentForm.controls[this.controlName].reset();
    this.borderType = 'empty';
    this.chdRef.detectChanges();
  }

  getObjectKeys(arg) {
    if (arg) {
      return Object.keys(arg);
    } else {
      return null;
    }
  }

  generateDateForm() {
    this.dateForm = new FormGroup({
      day: new FormControl((this.value && this.value !== undefined) ? (this.value.split('/')[0]) : '', [Validators.required, Validators.pattern('^(?!0,?\d)[0-9]+(\.[0-9]{0,0})?$'), Validators.minLength(1)]),
      month: new FormControl((this.value && this.value !== undefined) ? (this.value.split('/')[1]) : '', [Validators.required, Validators.pattern('^(?!0,?\d)[0-9]+(\.[0-9]{0,0})?$'), Validators.minLength(1)]),
      year: new FormControl((this.value && this.value !== undefined) ? (this.value.split('/')[2]) : '', [Validators.required, Validators.pattern('^(?!0,?\d)[0-9]+(\.[0-9]{0,0})?$'), Validators.minLength(4),
        conditionalValidator(
          () => this.checkIsAdult,
          this.yearValidator
        )])
    });
    this.createdDateForm = true;
    this.chdRef.detectChanges();
  }

  selectDateInput(dateN: number) {
    this.selectedDateInputs[dateN] = true;
    this.chdRef.detectChanges();
  }

  unselectDateInput(dateN: number) {
    switch (dateN) {
      case 0:
        this.selectedDateInputs[dateN] = false;
        break;
      case 1:
        this.selectedDateInputs[dateN] = false;
        break;
      case 2:
        this.selectedDateInputs[dateN] = false;
        break;
    }
    this.chdRef.detectChanges();
  }

  checkDateForm(e, input) {
    const day = this.dateForm.value.day;//.length === 1 ? '0' + this.dateForm.value.day : this.dateForm.value.day;
    const month = this.dateForm.value.month;//.length === 1 ? '0' + this.dateForm.value.month : this.dateForm.value.month;
    const year = this.dateForm.value.year;//.length === 1 ? '0' + this.dateForm.value.year : this.dateForm.value.year;
    const dtValue = day + '/' + month + '/' + this.dateForm.value.year;
    const validDate = moment(this.dateForm.value.year + '-' + month + '-' + day).isValid();
    if (this.dateForm.valid && validDate) {
      this.parentForm.controls[this.controlName].setValue(dtValue);
      this.invalidDate = false;
      this.valueChanged.emit();
    } else {
      this.parentForm.controls[this.controlName].setValue(undefined);
      if (day.length === 2 && month.length === 2 && year.length === 4) {
        this.invalidDate = true;
      }
    }
    if (Number(day) || day === '' || day === '0') {
      this.validDateInputs[0] = true;
      if (Number(day) > 31) {
        this.validDateInputs[0] = false;
      }
    } else {
      this.validDateInputs[0] = false;
    }
    if (Number(month) || month === '' || month === '0') {
      this.validDateInputs[1] = true;
      if (Number(month) > 12) {
        this.validDateInputs[1] = false;
      }
    } else {
      this.validDateInputs[1] = false;
    }
    if (Number(year) || year === '' || year === '0') {
      this.validDateInputs[2] = true;
      if (!Number(year) && year.length !== 4) {
        //this.validDateInputs[2] = false;
      }
    } else {
      this.validDateInputs[2] = false;
    }
    if (input === 'day') {
      if ((this.dateForm.value.day.length === 2 && e.key !== 'ArrowLeft')
        || (this.dateForm.value.day.length === 0 && e.key === 'ArrowRight')) {
        this.month.nativeElement.focus();
      }
    }
    if (input === 'month') {
      if ((this.dateForm.value.month.length === 2 && e.key !== 'ArrowLeft')
        || (this.dateForm.value.month.length === 0 && e.key === 'ArrowRight')) {
        this.year.nativeElement.focus();
      }
      if (this.dateForm.value.month.length === 0 && (e.key === 'ArrowLeft' )) {
        this.day.nativeElement.focus();
      }
    }
    if (input === 'year') {
      if (this.dateForm.value.year.length === 0 && (e.key === 'ArrowLeft' )) {
        this.month.nativeElement.focus();
      }
    }

    this.chdRef.detectChanges();
  }

  private loadGMapsAutoComplete() {
    let gmapsIsLoaded = false;
    // API restringida por dominios
    let url = 'https://maps.googleapis.com/maps/api/js?key=' + gmapsApiKeyRestricted + '&libraries=places&v=weekly';
    if(!this.isDesktop) { // API sin restricciones para las APPs
      url = 'https://maps.googleapis.com/maps/api/js?key=' + gmapsApiKey + '&libraries=places&v=weekly';
    }
    const scripts = document.getElementsByTagName("script");
    // Comprobamos si ya se ha cargado alguna vez
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src) {
        if(scripts[i].src === url) {
          gmapsIsLoaded = true;
        }
      }
    }
    if(!gmapsIsLoaded) {
      this.loadScript(url).then(() => this.initGMapsAutocomplete());
    } else {
      this.initGMapsAutocomplete();
    }
  }

  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.head, script);
    })
  }

  initGMapsAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement,
    {
      componentRestrictions: { country:  'ES' },
      fields: ['address_components', 'geometry', 'name'],
      types: ['establishment', 'geocode']
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const  place  =  autocomplete.getPlace();
      this.addressPlaced.emit(place);
      this.chdRef.detectChanges();
		});

  }

  toggleShow() {
    this.showPwd = !this.showPwd;
    this.type = this.showPwd ? 'text' : 'password';
    this.chdRef.detectChanges();
  }

  onlyNumberKey(event) {
    return (event.charCode === 8 || event.charCode === 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  yearValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const currentYear = moment().year();
    if (control.value !== undefined && (Number(control.value) <= currentYear - 18) && (Number(control.value) > currentYear - 115)) {
      return null;
    }
    return { code: true };
  }

  search() {
    this.searchEvent.emit(this.parentForm.controls[this.controlName].value);
    this.chdRef.detectChanges();
  }

}
