import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fun-sidemenu-icon',
  templateUrl: './sidemenu-icon.component.html',
  styleUrls: ['./sidemenu-icon.component.scss'],
})
export class SidemenuIconComponent implements OnInit {

  @Input() icon: string;
  @Input() selected: boolean;

  constructor() { }

  ngOnInit() { }

}
