
<ion-grid>
  <div class="flex flex-col mb-32">
    <p *ngIf="(filter == 'month')" class="font-sans text-h600 text-eggplant-100/[0.25] text-center">{{ 'DASHBOARD.DETAIL_PROJECT.DATE_INFO' | translate }} {{ date }}</p>
    <p *ngIf="(filter != 'month')" class="font-sans text-h600 text-eggplant-100/[0.25] text-center">{{ 'DASHBOARD.DETAIL_PROJECT.DATE_INFO_PLURAL' | translate }} {{ date }}</p>
    <p *ngIf="lastUpdates" class="lastUpdate font-sans text-eggplant-100 text-s text-center" >{{ lastUpdates }}</p>
  </div>
  <div class="flex flex-col md:flex-row items-end gap-32 pb-32 px-16">
    <div class="flex w-full md:w-1/2">
      <p *ngIf="productionHistoric >= 100000" class="font-sans text-xl md:text-h400 text-eggplant-100">{{ 'DASHBOARD.DETAIL_PROJECT.PRODUCTION_FROM_INIT' | translate }} <span class="bg-gradient-1-text">{{ (productionHistoric / 1000).toFixed(0)  | number:'1.0-0':'es' }} MWh</span> {{ 'DASHBOARD.DETAIL_PROJECT.INCOMES_FROM_INIT' | translate }} <span class="bg-gradient-1-text">{{ moneyHistoric | number:'1.0-0':'es' }} €</span>.</p>
      <p *ngIf="productionHistoric < 100000" class="font-sans text-xl md:text-h400 text-eggplant-100">{{ 'DASHBOARD.DETAIL_PROJECT.PRODUCTION_FROM_INIT' | translate }} <span class="bg-gradient-1-text">{{ productionHistoric | number:'1.0-0':'es' }} kWh</span> {{ 'DASHBOARD.DETAIL_PROJECT.INCOMES_FROM_INIT' | translate }} <span class="bg-gradient-1-text">{{ moneyHistoric | number:'1.0-0':'es' }} €</span>.</p>
    </div>
    <div class="flex flex-col gap-20 w-full md:w-1/2">
      <div class="flex flex-col md:flex-row items-center justify-end gap-12">
        <ul class="flex items-center justify-end gap-x-6">
          <li *ngIf="(filter == 'year')"><fun-new-button size="s" icon="" iconPosition="" buttonType="primaryDark" title="anual"></fun-new-button></li>
          <li *ngIf="(filter != 'year')"><fun-new-button (eventClick)="onClickYearly()" size="s" icon="" iconPosition="" buttonType="secondaryDark" title="anual"></fun-new-button></li>
          <li *ngIf="(filter == 'month')"><fun-new-button size="s" icon="" iconPosition="" buttonType="primaryDark" title="mensual"></fun-new-button></li>
          <li *ngIf="(filter != 'month')"><fun-new-button (eventClick)="onClickMonthly()" size="s" icon="" iconPosition="" buttonType="secondaryDark" title="mensual"></fun-new-button></li>
          <li *ngIf="(filter == 'day')"><fun-new-button size="s" icon="" iconPosition="" buttonType="primaryDark" title="diario"></fun-new-button></li>
          <li *ngIf="(filter != 'day')"><fun-new-button size="s" (eventClick)="onChangeSelectDay()" icon="" iconPosition="" buttonType="secondaryDark" title="diario"></fun-new-button></li>
          <li *ngIf="(filter != 'year')" id="btnResetGraphic"><fun-new-button size="s" icon="" iconPosition="" buttonType="ghost" title="borrar"></fun-new-button></li>
        </ul>
        <div class="flex items-center justify-start">
          <fun-new-select *ngIf="(listOfYears.length > 0) && (filter == 'year')" size="s" [options]="listOfYears" [selectedValue]="currentYear" (selectChange)="onChangeSelectYear($event)"></fun-new-select>
          <fun-new-select *ngIf="(listOfYears.length > 0) && (filter == 'month')" size="s" [options]="listOfMonths" [selectedValue]="currentMonth" (selectChange)="onChangeSelectMonth($event)"></fun-new-select>
        </div>
      </div>
    </div>
  </div>
  <div class="hidden flex flex-col w-full pb-32">
    <div class="flex items-baseline justify-center gap-8">
      <p class="text-center relative font-sansMedium text-h500 text-eggplant-100 uppercase">{{ date }}</p>
    </div>
  </div>
  <ion-row class="grid grid-cols-1 md:grid-cols-2 gap-20 mb-32">
    <div class="card rounded-2xl shadow-xl border border-eggplant-100/[0.1] relative">
      <div *ngIf="isLoading" class="absolute w-full h-full bg-white rounded-2xl z-10">
        <fun-spinner></fun-spinner>
      </div>
      <ion-card-content>
        <h3 class="font-sans text-eggplant-100 text-center !text-3xl !p-0 !m-0" >{{ 'DASHBOARD.DETAIL_PROJECT.DAILY_PROD' | translate }}</h3>
        <div class="flex">
          <div class="basis-1/2">
            <div *ngIf="productionToday !== undefined"  class="flex flex-col font-sans text-eggplant-100">
              <p class="font-sans text-m">{{ 'DASHBOARD.DAILY_PRODUCTION.REAL' | translate }}</p>
              <p *ngIf="productionToday >= 100000" class="font-sans !text-2xl" title="{{ (productionToday / 1000).toFixed(0) | localeNumber }} €">{{ (productionToday / 1000).toFixed(0) | localeNumber }} <span class="!text-m">MWh</span></p>
              <p *ngIf="productionToday < 100000" class="font-sans !text-2xl" title="{{ productionToday | localeNumber }} €">{{ productionToday | localeNumber }} <span class="!text-m">kWh</span></p>
            </div>
          </div>
          <div class="basis-1/2 text-right">
            <div *ngIf="productionEstimated !== undefined" class="flex flex-col font-sans text-eggplant-100/[0.4] text-3xl">
              <p class="flex items-center justify-end gap-4 font-sans text-m has-tooltip">
                <span *ngIf="(filter == 'year')" class="font-sans text-s rounded shadow-lg opacity-90 py-2 px-4 bg-eggplant-100 text-cream-100 -mt-64 tooltip">{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED_INFO' | translate }}</span>
                <span *ngIf="(filter != 'year')" class="font-sans text-s rounded shadow-lg opacity-90 py-2 px-4 bg-eggplant-100 text-cream-100 -mt-64 tooltip">{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED_INFO_EXTERNAL' | translate }}</span>
                <fun-icon [name]="'info-circle'" [style]="'w-12 h-12 stroke-[2px] stroke-eggplant-100/[0.5]'"></fun-icon>
                {{ 'DASHBOARD.DAILY_PRODUCTION.ESTIMATED' | translate }}
              </p>
              <p *ngIf="productionEstimated >= 100000" class="font-sans !text-2xl" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ (productionEstimated / 1000).toFixed(0) | localeNumber }} €">{{ (productionEstimated / 1000).toFixed(0) | localeNumber }} <span class="!text-m">MWh</span></p>
              <p *ngIf="productionEstimated < 100000" class="font-sans !text-2xl" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ productionEstimated | localeNumber }} €">{{ productionEstimated | localeNumber }} <span class="!text-m">kWh</span></p>
            </div>
          </div>
        </div>
        <div class="w-full h-[160px] md:h-[280px]">
            <canvas #lineChart id="myChartProduction">{{ chart }}</canvas>
        </div>
      </ion-card-content>
    </div>
    <div class="card rounded-2xl shadow-xl border border-eggplant-100/[0.1] relative">
      <div *ngIf="isLoading" class="absolute w-full h-full bg-white rounded-2xl z-10">
        <fun-spinner></fun-spinner>
      </div>
      <ion-card-content>
        <h3 class="font-sans text-eggplant-100 text-center !text-3xl !p-0 !m-0" >{{ 'DASHBOARD.DETAIL_PROJECT.DAILY_INCOME' | translate }}</h3>
        <div class="flex">
          <div class="basis-1/2">
            <div *ngIf="moneyToday !== undefined" class="flex flex-col font-sans text-eggplant-100" title="{{ moneyToday | localeNumber }} €">
              <p class="font-sans text-m">{{ 'DASHBOARD.INCOME.REAL' | translate }}</p>
              <p class="font-sans !text-2xl">{{ moneyToday | localeNumber }} <span class="!text-m">€</span></p>
            </div>
          </div>
          <div class="basis-1/2 text-right">
            <div *ngIf="moneyEstimated !== undefined" class="flex flex-col font-sans text-eggplant-100/[0.4] text-3xl">
              <p class="flex items-center justify-end gap-4 font-sans text-m has-tooltip">
                <span *ngIf="(filter == 'year')" class="font-sans text-s rounded shadow-lg opacity-90 py-2 px-4 bg-eggplant-100 text-cream-100 -mt-64 tooltip">{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED_INFO' | translate }}</span>
                <span *ngIf="(filter != 'year')" class="font-sans text-s rounded shadow-lg opacity-90 py-2 px-4 bg-eggplant-100 text-cream-100 -mt-64 tooltip">{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATED_INFO_EXTERNAL' | translate }}</span>
                <fun-icon [name]="'info-circle'" [style]="'w-12 h-12 stroke-[2px] stroke-eggplant-100/[0.5]'"></fun-icon>
                {{ 'DASHBOARD.INCOME.ESTIMATED' | translate }}
              </p>
              <p class="font-sans !text-2xl" title="{{ 'DASHBOARD.DETAIL_PROJECT.ESTIMATES' | translate }} {{ moneyEstimated | localeNumber }} €">{{ moneyEstimated | localeNumber }} <span class="!text-m">€</span></p>
            </div>
          </div>
        </div>
        <div class="w-full h-[160px] md:h-[280px]">
            <canvas #lineChart id="myChartIncomes">{{ chartIncomes }}</canvas>
        </div>
      </ion-card-content>
    </div>
  </ion-row>
</ion-grid>