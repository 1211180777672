import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-register-picture',
  templateUrl: './register-picture.component.html',
  styleUrls: ['./register-picture.component.scss']
})
export class RegisterPictureComponent implements OnInit {

  @Output() public takePictureEvent = new EventEmitter<any>();
  @Output() public repeatPictureEvent = new EventEmitter<any>();
  @Output() public prevStepEventEvent = new EventEmitter<any>();
  @Input() isMobileApp: any;
  @Input() isFirst: any;
  @Input() headerTitle: string;
  @Input() headerSubtitle: string;
  @Input() imageSrc: string;

  constructor() { }

  ngOnInit() {
  }
  repeat() {
    this.repeatPictureEvent.emit();
  }
  prev() {
    this.prevStepEventEvent.emit();
  }
  next() {
    this.takePictureEvent.emit();
  }

}
