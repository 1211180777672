<div *ngIf="data && data !== undefined" id="investment-success" class="span-container">
  <span id="item-value">{{ amount }}</span>
  <span id="payment-type">{{ paymentType }}</span>
  <span id="cupon">{{ data.promCode }}</span>
  <span id="coupon-type">{{ ref }}</span>
  <!--<span id="item-name">{{ data.headerInfo.name }}</span>
  <span id="item-id">{{ data.investInfo.id }}</span>
  <span id="item-brand">{{ data.spanInfo.company }}</span>
  <span id="item-category">Energía Solar</span>
  <span id="item-location">{{ data.spanInfo.location }}, ES</span>
  <span id="item-profit">{{ data.headerInfo.profitability | number:'1.0-2':'es' }}</span>
  <span id="item-goal">{{ data.headerInfo.objective | number:'1.0-2':'es' }} €</span>
  <span id="item-index">{{ data.spanInfo.position }}</span>-->
  <span id="item-price">{{ amount }}</span>
  <span id="list-name">En Financiación</span>
</div>

<div class="last-step">
    <img src="../../../../../assets/images/new_branding/colored-briefcase.svg" alt="briefcase">
    <h2 class="font-sans text-h700 text-center m-0 bg-gradient-2-text">{{ 'invest.Success.title' | translate }}</h2>
    <p class="font-sans text-xl text-eggplant-100/[0.55]" *ngIf="prevInvest === undefined">{{ 'invest.Success.invest' | translate }}{{ amount | localeNumber }} € {{ 'invest.Success.in' | translate }} <span>{{ name }}</span>.
        {{ 'invest.Success.text' | translate }}</p>
    <p class="font-sans text-xl text-eggplant-100/[0.55]" *ngIf="prevInvest !== undefined">{{ 'invest.Success.increase' | translate }}{{ amount | localeNumber }} € {{ 'invest.Success.in' | translate }} <span>{{ name }}</span>.
        {{ 'invest.Success.increaseText' | translate }}</p>
    <p class="font-sans text-xl text-eggplant-100/[0.55]" *ngIf="prevInvest !== undefined">{{ 'invest.Success.increaseSecondText' | translate }}</p>
    <p class="font-sans text-xl text-eggplant-100/[0.55]" class="share-code-txt"><span>Comparte tu código</span></p>
    <div class="referral_container">
        <div class="copy-container">
            <div [ngClass]="{ copied: isCopied }" class="copy" (click)="copyCode(refCode)">
              <p>{{ refCode }}</p>
              <img *ngIf="!isCopied" src="../../../../../assets/images/referral/copy.svg " alt="copy icon" />
              <img *ngIf="isCopied" src="../../../../../assets/images/referral/copy-green.svg " alt="copy icon" />
            </div>
          </div>
          <div class="social-container">
            <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
              <fun-icon (click)="shareWatsapp()" [name]="'whatsapp'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
            </div>
            <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
              <fun-icon (click)="shareFacebook()" [name]="'facebook'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
            </div>
            <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
              <fun-icon (click)="shareTwitter()" [name]="'twitter'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
            </div>
            <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
              <fun-icon (click)="shareLinkedin()" [name]="'linkedin'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
            </div>
            <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social cursor-pointer">
              <fun-icon (click)="shareMail()" [name]="'share'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
            </div>
          </div>
          <!---->
          <div class="pt-32 pb-24">
            <div class="md:hidden">
                <fun-new-button size="xl" [buttonType]="'colorFull'" title="ir a mi cartera" (eventClick)="goHome()" [classExtra]="' !w-full '"></fun-new-button>
            </div>
            <div class="hidden md:flex items-center justify-center ">
                <fun-new-button size="xl" [buttonType]="'colorFull'" title="ir a mi cartera" (eventClick)="goHome()"></fun-new-button>
            </div>
        </div>
    </div>
</div>