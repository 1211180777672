import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-capital-increase-more-info',
  templateUrl: './capital-increase-more-info.component.html',
  styleUrls: ['./capital-increase-more-info.component.scss'],
})
export class CapitalIncreaseMoreInfoComponent implements OnInit {

  @Output() public investEvent = new EventEmitter<any>();
  @Output() public goBackEvent = new EventEmitter<any>();
  @Input() available: boolean;

  btnType: string;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.btnType = this.available ? 'primaryDark' : 'primaryDark-disabled';
    this.chdRef.detectChanges();
  }

  goToInvest() {
    this.investEvent.emit();
    this.chdRef.detectChanges();
  }

  goBack() {
    this.goBackEvent.emit();
    this.chdRef.detectChanges();
  }

}
