import { Component, Input, OnInit } from '@angular/core';
import { HelpService } from 'src/app/services/help.service';
import { BenefitsByMonth } from '../../models/benefits.models';

@Component({
  selector: 'app-calendar-pay',
  templateUrl: './calendar-pay.component.html',
  styleUrls: ['./calendar-pay.component.scss'],
})
export class CalendarPayComponent implements OnInit {

  @Input() benefitsByMonth: BenefitsByMonth[];
  @Input() loading: boolean;

  constructor(private helpService: HelpService) { }

  ngOnInit() {}

  openHelp() {
    this.helpService.openHelp('second', 1, 'PAYMENT_CALENDAR');
  }

}
