import { EventEmitter, Injectable, Output } from '@angular/core';
import { BadgeModel } from '../shared/models/badge.model';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {

  @Output() public hasBadgeEvent = new EventEmitter<any>();
  @Output() public openProjectBySlugEvent = new EventEmitter<any>();
  @Output() public removeBadgeEvent = new EventEmitter<any>();
  public hasBadge = false;
  public badgeContent: BadgeModel;

  constructor() { }

  setBadgeProps(badge: BadgeModel) {
    this.hasBadge = true;
    this.badgeContent = badge;
    this.hasBadgeEvent.emit(badge);
  }

  openCurrentProject(proj) {
    this.openProjectBySlugEvent.emit(proj);
  }

  removeBadge() {
    this.hasBadge = false;
    this.removeBadgeEvent.emit();
  }
}
