<div [class]="contentClass" (click)="componentClicked()">
  <div class="flex justify-between flex-col md:flex-row txt-content">
    <p [class]="titleClass">{{ props.title }}</p>
    <p class="font-sans text-s px-8 py-2" [ngClass]="{'text-eggplant-100': props.color === 'primary',
    'text-[#FD565B]': props.color === 'error', 'text-[#039755]': props.color === 'success'}">{{ props.subtitle }}</p>
  </div>
  <div class="inline-block flex-end">
    <fun-icon [name]="'arrow-right'" [style]="iconClass"></fun-icon>
  </div>
</div>
