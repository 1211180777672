<div class="modal-container" #component>
    <div class="bg_shadow"></div>
    <div class="modal">
        <div *ngIf="(title !== '' && title !== null && title !== undefined) || cross" class="modal-header">
            <div class="modal-status">
                <h2>{{ title }}</h2>
            </div>
            <div *ngIf="cross" class="modal-cross" (click)="close()">
                <img class="cerrarModal" alt="x" src="../../../../assets/images/img/ico-close.svg">
            </div>
            <div class="line"></div>
        </div>
        <div>
            <div #componentPlaceholder></div>
        </div>
    </div>
</div>
