
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
    /*transform(value: string): string {
        return value.split(' ').join('').replace(/(.{4})/g, "$1 ");
    }*/
    transform(value: string): string {
        if (value.length <= 30) {
            return value.split(' ').join('').replace(/(.{4})/g, "$1 ");
        } else {
            return value.split(' ').join('').replace(/(.{4})/g, "$1 ").substring(0, 30 - 1);
        }
    }
}
