
<div class="row">
  <div class="col-6 w-padding">
    <fun-skeleton-card></fun-skeleton-card>
  </div>
  <div class="col-6 w-padding">
    <fun-skeleton-card></fun-skeleton-card>
  </div>
  <div class="col-6 w-padding">
    <fun-skeleton-card></fun-skeleton-card>
  </div>
  <div class="col-6 w-padding">
    <fun-skeleton-card></fun-skeleton-card>
  </div>
</div>
