import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataLayerService } from 'src/app/services/data-layer.service';

@Component({
  selector: 'fun-register-person-email',
  templateUrl: './register-person-email.component.html',
  styleUrls: ['./register-person-email.component.scss']
})
export class RegisterPersonEmailComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public acceptConditionsEvent = new EventEmitter<any>();
  @Output() public acceptOffersEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Output() public checkRefCodeEvent = new EventEmitter<any>();
  @Output() public selectTermsAndConditionsEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() public profileType;

  public emailForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public acceptConditions: boolean;
  public acceptOffers: boolean;
  public refCode: string;
  public refName: string;
  public openPromoCodeInp: boolean;

  privacyTxt: string;
  hasPrivacyText = false;

  constructor(
    public chdRef: ChangeDetectorRef,
    private dataLayerService: DataLayerService,
  ) { }

  ngOnInit() {
    // Mandamos evento a GTM
    if(this.profileType === "1") {
      this.dataLayerService.pushCustomEventGTM('pf_sign_up');
    } else if (this.profileType === "2") {
      this.dataLayerService.pushCustomEventGTM('pj_sign_up');
    }
    this.acceptConditions = false;
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      pwd: new FormControl('', [Validators.required]),
      refCode: new FormControl('', [])
    });
    this.openPromoCodeInp = false;
    this.getInfo();
    this.chdRef.detectChanges();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.emailForm.valid && this.acceptConditions === true) {
      this.sendInfoEvent.emit(this.emailForm.value);
      this.submitEvent.emit();
    } else {
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  getInfo() {
    const fakeMail = this.emailForm.value.email.includes('@irebah.com');
    if (this.emailForm.valid && !fakeMail) {
      this.sendInfoEvent.emit(this.emailForm.value);
    } else {
      this.sendErrorEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  public onChangeAcceptPriv() {
    this.acceptConditions = !this.acceptConditions;
    this.acceptConditionsEvent.emit(this.acceptConditions);
    this.chdRef.detectChanges();
  }

  onChangeAcceptOffers() {
    this.acceptOffers = !this.acceptOffers;
    this.acceptOffersEvent.emit(this.acceptOffers);
    this.chdRef.detectChanges();
  }

  viewMore(value?: string) {
    const url = value === 'terms' ? 'https://www.fundeen.com/legal/terminos-y-condiciones' : 'https://www.fundeen.com/legal/privacidad';
    window.open(url);
    this.chdRef.detectChanges();
  }

  toggleRefCode() {
    this.openPromoCodeInp = !this.openPromoCodeInp;
    this.chdRef.detectChanges();
  }

  checkRefCode() {
    this.refCode = this.emailForm.controls.refCode.value;
    const refCode2 = this.refCode;
    setTimeout(() => {
      if (this.refCode === refCode2 && this.refCode !== '' && this.refCode.length > 4) {
        this.checkRefCodeEvent.emit(this.refCode);
      }
    }, 2000);
    this.chdRef.detectChanges();
  }

  public setRefUsername(username) {
    this.refName = username;
    this.chdRef.detectChanges();
  }
}
