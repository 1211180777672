<div class="container" [ngClass]="{'none': totalN === 0}">
    <div class="row w-padding rounded-xl bg-white shadow-card">
        <div class="tables offers-container my-offer">
            <div class="offers-header"  *ngIf="!isEmpty">
                <div class="seller content_h">
                    <h2 [ngClass]="{'down': !projectListOpen, 'up': projectListOpen}" (click)="toggleSelectProject()">{{ 'bid.project' | translate }}</h2>
                    <div *ngIf="projectListOpen" class="abs-container">
                        <div class="selector-container">
                            <p class="title">{{ 'movements.filter_by' | translate }} <span *ngIf="filterByP !== ''" (click)="reset()">{{ 'movements.delete' | translate }}</span></p>
                            <p class="selector" *ngFor="let item of projectList" [ngClass]="{'checked': item === filterByP }" (click)="filterByProjects(item)">{{ item }}</p>
                        </div>
                    </div>
                </div>

                <h2 class="hidden-lg"></h2>
                <h2 class="share" (click)="orderByShare()" [ngClass]="{'down': !orderBestShare, 'up': orderBestShare}">{{ 'bid.shares' | translate }}</h2>
                <h2 class="em-value" (click)="orderByPrice()" [ngClass]="{'down': !orderBestPrice, 'up': orderBestPrice}">{{ 'bid.your-bid' | translate }}</h2>
                <h2 class="bid">{{ 'bid.ends' | translate }}</h2>
                <div class="status_content">
                    <h2 class="status" [ngClass]="{'down': !statusOpen, 'up': statusOpen}" (click)="toggleStatusProject()">{{ 'movements.status' | translate }}</h2>
                    <div *ngIf="statusOpen" class="relative-container">
                        <div class="selector-container">
                            <p class="title">{{ 'movements.filter_by' | translate }} <span *ngIf="filterByS !== ''" (click)="reset()">{{ 'movements.delete' | translate }}</span></p>
                            <p class="selector" *ngFor="let item of statusList" [ngClass]="{'checked': item.id === filterByS }" (click)="filterList(item.id)">{{ item.name }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngFor="let dataItem of dataListFiltered">

                <div [ngClass]="isShow && idSelected == dataItem.id ? 'open' : ''" class="offers-content">
                    <p class="project" (click)="viewBidDetails(dataItem)">
                        <span class="mark"
                            [ngClass]="{'mark-orange': dataItem.project_technology == 1, 'mark-blue': dataItem.project_technology == 2}"></span>
                        {{ dataItem.project_name }}
                    </p>
                    <p (click)="viewBidDetails(dataItem)" class="hidden-lg">
                        <i class="ico ico-info-green" *ngIf="dataItem.status == 'won' && dataItem.offer_status == 'sold'
                        && dataItem.offer_sold_status !== 'ok' && dataItem.offer_sold_status !== 'ko'"></i>
                        <label class="label-tag"
                        [ngClass]="{
                            'label-tag-orange': dataItem.status == 'active',
                            'label-tag-green': dataItem.status == 'won' && dataItem.offer_status === 'sold',
                            'label-tag-red': dataItem.status == 'lost' || (dataItem.status === 'won' && dataItem.offer_status === 'not_sold')}">
                            <span *ngIf="dataItem.status == 'active'">{{ 'bid.active-status' | translate }}</span>
                            <span *ngIf="dataItem.status == 'won' && dataItem.offer_status === 'sold'">{{ 'bid.won-status' | translate }}</span>
                            <span *ngIf="dataItem.status == 'lost'">{{ 'bid.lost-status' | translate }}</span>
                            <span *ngIf="dataItem.status === 'won' && dataItem.offer_status === 'not_sold'">{{ 'bid.expired' | translate }}</span>
                        </label>
                    </p>
                    <p (click)="viewBidDetails(dataItem)" class="share"><span class="hidden-lg">{{ 'bid.shares' | translate }}</span> {{ dataItem.offer_amount | localeNumber }}</p>
                    <p (click)="viewBidDetails(dataItem)" class="em-value"
                    [ngClass]="{'text-green': dataItem.status == 'won'}"
                    ><span class="hidden-lg">{{ 'bid.your-bid' | translate }}</span> {{ dataItem.price | localeNumber }} €</p>
                    <p (click)="viewBidDetails(dataItem)" class="bid"><span class="hidden-lg">
                        {{ 'bid.ends' | translate }}</span>
                        <ng-container *ngIf="dataItem.status == 'active'">
                            {{ dataItem.decision_due_date_msg | timeLeft }}
                        </ng-container>
                        <ng-container *ngIf="dataItem.status != 'active'">
                            -
                        </ng-container>
                    </p>


                    <p (click)="viewBidDetails(dataItem)" class="action">
                        <i class="ico ico-info-green hidden-sm" *ngIf="dataItem.notification"></i>
                        <label class="label-tag"
                            [ngClass]="{
                            'label-tag-orange': dataItem.status == 'active',
                            'label-tag-green': dataItem.status == 'won' && dataItem.offer_status === 'sold',
                            'label-tag-red': dataItem.status == 'lost' || (dataItem.status === 'won' && dataItem.offer_status === 'not_sold')}">
                            <span *ngIf="dataItem.status == 'active'">{{ 'bid.active-status' | translate }}</span>
                            <span *ngIf="dataItem.status == 'won' && dataItem.offer_status === 'sold'">{{ 'bid.won-status' | translate }}</span>
                            <span *ngIf="dataItem.status == 'lost'">{{ 'bid.lost-status' | translate }}</span>
                            <span *ngIf="dataItem.status === 'won' && dataItem.offer_status === 'not_sold'">{{ 'bid.expired' | translate }}</span>
                        </label>
                        <a class="ico ico-edit"></a>
                        <a class="a-link hidden-lg">{{ 'bid.view-details' | translate }}</a>
                        <i class="ico ico-arrow-right-green hidden-sm"></i>
                    </p>

                    <p (click)="toggleDisplay(dataItem.id)" class="show-more hidden-lg"> <i class="ico ico-arrow"></i>
                    </p>
                </div>

            </ng-container>

            <fun-skeleton-list *ngIf="!loaded"></fun-skeleton-list>
        </div>

        <fun-new-button *ngIf="totalN > 0" buttonType="primaryDark" title="{{ 'bid.view-projects' | translate }}"
            (eventClick)="onEventClick()"></fun-new-button>
    </div>
</div>

<div class="container" *ngIf="totalN === 0">
    <div class="row w-padding final-step bid">
        <div class="col-12">
            <h3></h3>
            <p class="font-sans text-l text-eggplant-100">{{ 'bid.create-bid-text' | translate }}</p>
            <div class="container">
                <fun-new-button buttonType="primaryDark" title="{{ 'bid.view-projects' | translate }}" class="button" (eventClick)="onEventClick()">
                </fun-new-button>
            </div>
        </div>
    </div>
</div>
