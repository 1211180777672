import { Component, Input, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.scss'],
})
export class ReferralListComponent implements OnInit {
  @Input() referrals: any;

  loading: boolean;
  platform = Capacitor.getPlatform();

  referralsInversor = [];
  referralsNoInversor = [];

  constructor(
    public translate: TranslateService,
    private utils: UtilsService
  ) { }

  ngOnInit() {
    if(this.referrals.length) {
      this.referrals = this.referrals.map(obj => {
        if (obj.useDate) {
          return {
            ...obj,
            useDate: this.utils.parseDate(obj.useDate)  // Formato deseado
          };
        }
        return obj;
      });

      this.referralsInversor = this.referrals.filter(item => item.type !== 'unused');
      this.referralsNoInversor = this.referrals.filter(item => item.type === 'unused');
    }
    this.loading = false;
  }

}
