import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { StrapiService } from 'src/app/services/strapi.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-overview-meeting-delegation',
  templateUrl: './overview-meeting-delegation.component.html',
  styleUrls: ['./overview-meeting-delegation.component.scss'],
})
export class OverviewMeetingDelegationComponent implements OnInit {

  @Input() data: any;
  @Output() public signedEvent = new EventEmitter<any>();
  @Output() public closeModalEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: false }) iframe: ElementRef;

  loading = true;
  step = 0;
  text: string;
  image = 2;
  btnList = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('AUTOINVEST.FOOTER.NEXT'),
  }];

  urlSafe: SafeResourceUrl;
  strapiSignaturitSignId: any;
  canDelegade: boolean;

  constructor(
    public chdRef: ChangeDetectorRef,
    private strapiService: StrapiService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.strapiService.checkUserHasVote(this.data.delegateEmail, this.data.meeting).subscribe(resp => {
      this.strapiService.checkUserRole(this.data.delegateEmail).subscribe(responseRole => {
        if (responseRole[0].role.type === 'public') {
          this.image = 1;
          this.canDelegade = false;
          this.text = this.translate.instant('MEETING.NO_DELEGATE_1');
          this.loading = false;
        } else if (resp.data.length > 0) {
          this.image = 1;
          this.canDelegade = false;
          this.text = this.translate.instant('MEETING.NO_DELEGATE_1');
          this.loading = false;
        } else {
          this.canDelegade = true;
          this.text =  this.translate.instant('MEETING.ALREADY_DELEGATE', { delegate: this.data.delegate });
          this.loading = false;
        }
        this.chdRef.detectChanges();
      });
      this.chdRef.detectChanges();
    });

    this.chdRef.detectChanges();
  }

  delegateSign() {
    if (this.canDelegade) {
      this.step = 1;
      this.loading = true;
      this.strapiService.createSignaturitTemplate(this.data).subscribe(resp => {
        const signaturitData = {
          data: {
            signaturit_id: resp.id,
            status: 'procesando',
            type: 'meeting',
            object_id: this.data.meeting,
            users_permissions_user: this.data.strapiUserId
          }
        };
        this.strapiService.createSignaturitDelegationIdentificator(signaturitData).subscribe(response => {
          this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(resp.url);
          this.strapiSignaturitSignId = response.data.id;
          this.loading = false;
          this.operateSignaturit();
          this.chdRef.detectChanges();
        }, error => {
          console.log(error)
        });
        this.chdRef.detectChanges();
      }, error => {
        this.step = 0;
        this.loading = false;
        this.chdRef.detectChanges();
      });
    } else {
      this.closeModalEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  charged(event) {
    this.loading = false;
    this.chdRef.detectChanges();
  }

  operateSignaturit() {
    window.addEventListener('message', e => {
      this.chdRef.detectChanges();
      if (e.data.event === 'completed') {
        this.updateSignStatus('completada');
        this.chdRef.detectChanges();
      } else {
        if (e.data.event === 'declined' || e.data.event === 'declined') {
          this.updateSignStatus('declinada');
          this.chdRef.detectChanges();
        }
      }
      if (e.data.event === 'ready') {
        this.chdRef.detectChanges();
      }
      if (e.data.message === 'signaturit issue') {
        this.chdRef.detectChanges();
      }
    });
    this.chdRef.detectChanges();
  }

  updateSignStatus(newStatus) {
    const model = {data: {status: newStatus}};
    this.strapiService.updateSignaturitDelegationIdentificator(model, this.strapiSignaturitSignId).subscribe(() => {
      if (newStatus === 'completada') {
        this.loading = true;
        this.step = 0;
        this.signedEvent.emit();
        this.sendMailAdvise();
      } else if (newStatus === 'declinada') {
        this.loading = true;
        this.step = 0;
        this.image = 1;
        this.canDelegade = false;
        this.text = this.translate.instant('MEETING.DECLINATED_DELEGATION');
        this.loading = false;
      }
      this.chdRef.detectChanges();
    });

    this.chdRef.detectChanges();
  }

  public voteDelegated() {
    this.btnList = [];
    this.text = this.translate.instant('MEETING.DELEGATION_DONE', { delegate: this.data.delegate });
    this.loading = false;
    this.chdRef.detectChanges();
  }

  sendMailAdvise() {
    const origin = environment.origin;
    const model = {
        name: this.data.delegate,
        name_representative: this.data.user,
        date: this.data.day,
        hour: this.data.hour,
        title: this.translate.instant('MEETING.DELEGATION_TITLE'),
        texto: `${this.data.companyName}`,
        link_app: `${origin}/es/acceso?meetingId=${this.data.meeting}`
      }
      const mailData = {
        templateId: 'd-ef68d00fdfdb4141a4497d9937ded180',
        to: [this.data.delegateEmail],
        from: {
          email: "legal@fundeen.com",
          name: "Fundeen - Legal"
        },
        dynamicData: model,
    };
    this.strapiService.sendGenericMail(mailData).subscribe(() => {
      this.chdRef.detectChanges();
    });
  }

}
