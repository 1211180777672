<fun-spinner *ngIf="loading"></fun-spinner>
<div class="w-full meeting-delegation-content overflow-y-auto">
  <fun-overal-message *ngIf="!loading && step === 0" [text]="text" [image]="image" [btnList]="btnList" (btnEvent)="delegateSign()"></fun-overal-message>
  <div *ngIf="step === 1 && !loading"  class="content w-full h-full overflow-y-auto">
    <iframe
        [src]="urlSafe"
        frameborder="0"
        (load)="charged($event)"
        #iframe
        allowfullscreen
        webkitallowfullscreen
        mozallowfullscreen
    ></iframe>
  </div>
</div>