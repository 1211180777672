
<div class="contentList">
  <div class="row offers-content">
    <div class="col-6"><ion-skeleton-text  animated></ion-skeleton-text></div>
    <div class="col-3"></div>
    <div class="col-2"><ion-skeleton-text animated></ion-skeleton-text></div>
    <div class="col-1"><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>
  <div class="row offers-content">
    <div class="col-6"><ion-skeleton-text  animated></ion-skeleton-text></div>
    <div class="col-3"></div>
    <div class="col-2"><ion-skeleton-text animated></ion-skeleton-text></div>
    <div class="col-1"><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>
  <div class="row offers-content">
    <div class="col-6"><ion-skeleton-text  animated></ion-skeleton-text></div>
    <div class="col-3"></div>
    <div class="col-2"><ion-skeleton-text animated></ion-skeleton-text></div>
    <div class="col-1"><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>
  <div class="row offers-content">
    <div class="col-6"><ion-skeleton-text  animated></ion-skeleton-text></div>
    <div class="col-3"></div>
    <div class="col-2"><ion-skeleton-text animated></ion-skeleton-text></div>
    <div class="col-1"><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>
  <div class="row offers-content">
    <div class="col-6"><ion-skeleton-text  animated></ion-skeleton-text></div>
    <div class="col-3"></div>
    <div class="col-2"><ion-skeleton-text animated></ion-skeleton-text></div>
    <div class="col-1"><ion-skeleton-text animated></ion-skeleton-text></div>
  </div>
</div>

