<div *ngIf="finished">
    <fun-overal-message *ngIf="!loading" [check]="check" [text]="text" [image]="image" [btnList]="btnList" (btnEvent)="clickBtn($event)" (checkEvent)="checked($event)"></fun-overal-message>
    <fun-spinner *ngIf="loading"></fun-spinner>
</div>
<div *ngIf="!finished" class="general-container">
        <div class="section" *ngIf="step !== 1 || (step === 1 && !experience)">
            <div class="section-content">
                <div class="content grid grid-cols-4 md:grid-cols-10 md:gap-x-32 lg:gap-x-32 place-content-center relative">
                    <div class="header col-span-6 col-start-3">
                        <h2 class="font-sans text-h800 text-center bg-gradient-2-text pb-[16px]" >{{ 'INVEST.INVESTOR_PROFILE.TXT' | translate }}</h2>
                        <p class="font-sans text-xl text-gray-40 text-center" *ngIf="step === 0">{{ 'TEST_CONV.SUBTITLE' | translate }}</p>

                        <!-- <h2 class="subt" *ngIf="step !== 0">{{ testInfo[step - extraSteps].title }}</h2> -->
                        <p class="font-sans text-xl text-gray-40 text-center" *ngIf="step !== 0">{{ testInfo[step - extraSteps].title }}</p>
                    </div>
                    <div class="body">
                        <div *ngIf="step === 0" class="flex px-8 md:px-120 flex-col md:flex-row">
                            <div class="step relative pr-[68px] pl-[62px] pb-32 md:pb-0">
                                <p class="step-title font-sans text-h400 text-eggplant-80 pb-8">{{ 'TEST_CONV.INFO.TXT_1' | translate }}</p>
                                <p class="step-subtitle font-sans text-m text-eggplant-100/[0.4]">{{ 'TEST_CONV.INFO.TXT_2' | translate }}</p>
                            </div>
                            <div class="step relative pr-[68px] pl-[62px] pb-32 md:pb-0">
                                <p class="step-title font-sans text-h400 text-eggplant-80 pb-8">{{ 'TEST_CONV.INFO.TXT_3' | translate }}</p>
                                <p class="step-subtitle font-sans text-m text-eggplant-100/[0.4]">{{ 'TEST_CONV.INFO.TXT_4' | translate }}</p>
                            </div>
                            <div class="step relative pr-[68px] pl-[62px]">
                                <p class="step-title font-sans text-h400 text-eggplant-80 pb-8">{{ 'TEST_CONV.INFO.TXT_5' | translate }}</p>
                                <p class="step-subtitle font-sans text-m text-eggplant-100/[0.4]">{{ 'TEST_CONV.INFO.TXT_6' | translate }}</p>
                            </div>
                        </div>
                        <div *ngIf="step !== 0" class="md:grid grid-cols-4 md:grid-cols-12 md:gap-x-32 lg:gap-x-32 place-content-center relative pl-32 md:pl-0">
                            <div *ngFor="let question of testInfo[step - extraSteps].answers"  class="col-span-4 col-start-5">
                                <div class="question " (click)="selectQuestion(testInfo[step - extraSteps].id, question)">
                                    <div class="icon" [ngClass]="{'check-empty': !question.selected, 'checked': question.selected }"></div>
                                    <p class="font-sans text-l text-eggplant-80">{{ question.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="step === 0" class="btn-start-content pt-56 pb-32 md:pb-0">
                        <fun-new-button [classExtra]="' !w-full '" [title]="'comenzar test'" [size]="'2xl'" [buttonType]="'primaryDark'" (eventClick)="next()"></fun-new-button>
                    </div>
                </div>
            </div>

            <div class="footer" *ngIf="step !== 0 && step !== 1 || (step === 1 && !experience)">
                <div class="btn_container first">
                      <fun-new-button [classExtra]="' !w-full '" [buttonType]="'ghost'" [size]="'xl'" title="Atrás"
                        (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'"></fun-new-button>
                  </div>
                  <div class="btn_container secod">

                    <fun-new-button [classExtra]="' !w-full '" [buttonType]="btnType" [size]="'xl'" title="{{ 'AUTOINVEST.FOOTER.NEXT' | translate }}"
                    (eventClick)="next()" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
                  </div>
            </div>
        </div>
        <app-inverstor-profile [profile]="profile" *ngIf="step === 1 && experience" (nextEvent)="getExperience($event)" (prevEvent)="prev()" (otherMomentEvent)="otherMoment()"></app-inverstor-profile>
</div>
