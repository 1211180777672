import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localePercentNumber'
})
export class LocalePercentNumberPipe implements PipeTransform {

  constructor(private _translateService: TranslateService) {
  }

  transform(value: any, digits: string = '1.2-2', locale?: string): string | null {
    let lang = this._translateService.currentLang;
    if (lang === undefined || lang === 'ca') {
      lang = 'es';
    }
    const ngPipe = new DecimalPipe(lang);
    return ngPipe.transform(value, digits, locale);

  }
}
