import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { BiometricService } from 'src/app/services/biometric.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ActionSheetService } from '../../action-sheet/action-sheet.service';
import { ToastService } from '../../toast/toast.service';
import { IncomeInfoComponent } from '../../income-info/income-info.component';

@Component({
  selector: 'app-money-in',
  templateUrl: './money-in.component.html',
  styleUrls: ['./money-in.component.scss'],
})
export class MoneyInComponent implements OnInit {

  @Input() public user;
  loading = true;
  profile: any;
  addressee: string;
  deposit = this.translate.instant('MOVEMENTS.MOVEMENT-LIST.DEPOSIT');

  constructor(
    public actionSheetController: ActionSheetController,
    public translate: TranslateService,
    public actionSheetService: ActionSheetService,
    private toastService: ToastService,
    @Inject(DOCUMENT) private document: any,
    public biometricService: BiometricService,
    private profileservice: ProfileService,
    private chdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.profileservice.getProfile(this.user.username).subscribe(profile => {
      this.addressee = this.user.profileType === 1 ? `${profile.name} ${profile.surname}` : profile.socialDenomination;
      this.profile = profile;
      this.loading = false;
      this.chdRef.detectChanges();
    });
  }

  async openInfo() {
    const modalParams = {
      page: 'deposit'
     };

    this.actionSheetService.showModal(IncomeInfoComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('MOVEMENTS.DEPOSIT-FUNDS.INCOME-INFORMATION').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.onClose().subscribe();
      }
    );
  }

  copy(val) {
    this.deleteAllToast();
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toastService.showToast(val + ' copiado', 'success', true);
    this.biometricService.makeMobileVibrate();
  }

  deleteAllToast() {
    if (this.toastService.listRef.length > 0) {
      const elements = this.document.getElementsByTagName('fun-toast');
      for (let i = elements.length - 1; i >= 0; i--) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  }

}
