import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-local-invest-steps',
  templateUrl: './local-invest-steps.component.html',
  styleUrls: ['./local-invest-steps.component.scss'],
})
export class LocalInvestStepsComponent implements OnInit {

  @Input() step: any;
  @Input() stepList: any;

  constructor() { }

  ngOnInit() { }

}
