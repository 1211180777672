<div class="register">
    <div class="body">
        <div class="company-container">
            <div class="inp">
                <fun-input
                    placeholder="{{'register.company.company_street' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxAddress"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.company_address' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.taxAddress"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.company.postal_code' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxPostalCode"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.postal_code' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.taxPostalCode"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input

                    placeholder="{{'register.company.country' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxCountry"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.country' | translate}}"
                    (selectChange)="changeCountry()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="countriesList"
                    [selectedItem]="data.taxCountry"
                ></fun-input>
            </div>
            <div class="inp" *ngIf="isNationalAddress">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.company.region' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxRegion"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.region' | translate}}"
                    (selectChange)="changeRegion()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="regionsList"
                    [selectedItem]="data.taxRegion"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="companyAddressForm">
                    <div class="inp select form-input">
                        <label>{{'register.company.region' | translate}}</label>
                        <select class="select-box" [ngClass]="{'option': companyAddressForm.controls['taxRegion'].value > 0}"
                        name="taxRegion" formControlName="taxRegion" (change)="changeRegion()">
                            <option hidden [value]="0">{{'register.company.region' | translate}}</option>
                            <option *ngFor="let region of regionsList"
                            [value]="region.id">
                                {{ region.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="inp" *ngIf="isNationalAddress">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.company.city' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxCity"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.city' | translate}}"
                    (selectChange)="getInfo()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="citiesList"
                    [selectedItem]="data.taxCity"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="companyAddressForm">
                    <div class="inp select form-input">
                        <label>{{'register.company.city' | translate}}</label>
                        <select class="select-box" [ngClass]="{'option': companyAddressForm.controls['taxCity'].value > 0}"
                        name="taxCity" formControlName="taxCity" (change)="getInfo()">
                            <option hidden [value]="0">{{'register.company.city' | translate}}</option>
                            <option *ngFor="let city of citiesList"
                            [value]="city.id">
                                {{ city.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="inp" *ngIf="!isNationalAddress">
                <fun-input
                    placeholder="{{'register.company.region' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxInternationalRegion"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.region' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.taxInternationalRegion"
                ></fun-input>
            </div>
            <div class="inp" *ngIf="!isNationalAddress">
                <fun-input
                    placeholder="{{'register.company.city' | translate}}"
                    [parentForm]="companyAddressForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="taxInternationalCity"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.company.city' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.taxInternationalCity"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
