import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'fun-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent implements OnInit {

  @Output() public getFile = new EventEmitter<any>();
  @Output() public errorEvent = new EventEmitter<any>();
  @Output() public deleteFile = new EventEmitter<any>();
  @Input() fileText: any;
  @Input() fileType: string;
  @Input() username: string;
  @Input() allowedPdf = true;
  @Input() version = 'card';

  public fileForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public moneyOutInfo;
  public skeletonActive = false;
  public selectIsOpen = false;

  public fileId = false;
  public fileName: any;
  public idDocument: any;
  public isUploadingFile = false;
  public uploadTxt: string;

  errorFile = false;
  errorFileId;
  errorFileText;

  mandatoryFields;

  @ViewChild('documentId', {static: false}) documentId: ElementRef;

  constructor(private chdRef: ChangeDetectorRef, private toastService: ToastService, private translate: TranslateService) { }

  ngOnInit() {
    this.fileForm = new FormGroup({
      file: new FormControl('', [Validators.required]),
    });
    if (this.fileText && this.fileText !== undefined) {
      this.uploadTxt = this.fileText;
    } else {
      this.uploadTxt = this.translate.instant('bank-account.files');
    }
    this.chdRef.detectChanges();
  }

  selectFile(event) {
    if (!event) {
      event = window.event; // old IE
    }
    this.chdRef.detectChanges();
    const tgt = event.target || event.srcElement;
    const files = tgt.files;
    const file = files[0];
    this.fileName = file.name;
    this.idDocument = tgt.id;
    let valid = true;
    this.errorFile = false;
    this.errorFileId = '';
    if (file.type !== 'image/jpeg'
    && file.type !== 'image/jpg'
    && file.type !== 'image/JPG'
    && file.type !== 'image/png'
    && file.type !== 'image/PNG'
    && ((file.type !== 'application/pdf' && this.allowedPdf) || (file.type === 'application/pdf' && !this.allowedPdf))) {
      this.resetStatusFile(this.idDocument);
      valid = false;
      const toastText: any = this.translate.instant('bank-account.invalid-file');
      this.isUploadingFile = false;
      this.errorEvent.emit(this.fileType);
      this.toastService.showToast(toastText, 'warning', true);
    }
    if (file.size > 6000000 && !this.errorFile) {
      const fileBig = this.translate.instant('bank-account.File-big');
      this.resetStatusFile(this.idDocument);
      valid = false;
      this.errorEvent.emit(this.fileType);
      this.toastService.showToast(fileBig, 'warning', true);
    }
    if (valid) {
      this.isUploadingFile = true;
      const reader = this.getFileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const fileToUpload = {
          field: this.getTypeFile(this.fileType),
          file: (reader.result as string).split(',')[1],
          username: this.username,
          format: file.type
        };
        this.getFile.emit(fileToUpload);
        this.isUploadingFile = false;
        this.fileId = true;
        this.chdRef.detectChanges();
      };
    }
    this.documentId.nativeElement.style.display = valid ? 'none' : 'block';
    this.chdRef.detectChanges();
  }

  resetStatusFile(idDocument) {
    this.documentId.nativeElement.value = '';
    this.documentId.nativeElement.style.display = 'block';
    this.errorFile = true;
    this.isUploadingFile = false;
    this.errorFileId = idDocument;
    this.fileId = undefined;
    this.errorEvent.emit(this.fileType);
    //this.getFile.emit();
    this.chdRef.detectChanges();
  }

  getTypeFile(type) {
    switch (type) {
      // Persona física
      case 'identityDocumentFront':
        return 'ID_CARD_FRONT';
      case 'identityDocumentBack':
        return 'ID_CARD_BACK';
      case 'identityDocumentBackNie':
        return 'ID_CARD_BACK';
      case 'bankAccountVerificationDocument':
        return 'PROOF_IBAN';
      case 'taxAddressVerificationDocument':
        return 'PROOF_ADDRESS';
      case 'identityDocumentFrontNie':
        return 'ID_CARD_FRONT';
      case 'identityDocumentFrontPassport':
        return 'ID_CARD_FRONT';
      case 'acreditationDoc':
        return 'ACCOUNT_ACREDITATION';

      case 'census':
        return 'CENSUS_CERTIFICATE';

      // Persona jurídica
      case 'constitutionDocument1':
        return 'CONSTITUTION_DOCUMENT1';
      case 'constitutionDocument2':
        return 'CONSTITUTION_DOCUMENT2';
      case 'realOwnershipDocument':
        return 'REAL_OWNERSHIP';
      case 'model200Document':
        return 'MODEL_200';
      case 'identityDocumentPartner1':
        return 'IDENTITY_DOCUMENT_PARTNER1';
      case 'identityDocumentPartner2':
        return 'IDENTITY_DOCUMENT_PARTNER2';
      case 'identityDocumentPartner3':
        return 'IDENTITY_DOCUMENT_PARTNER3';
      case 'identityDocumentPartner4':
        return 'IDENTITY_DOCUMENT_PARTNER4';
      case 'RLIdentityDocumentFront':
        return 'ID_CARD_FRONT_REPRESENTATIVE';
      case 'RLIdentityDocumentBack':
        return 'ID_CARD_BACK_REPRESENTATIVE';
      case 'RLAddressDocument':
        return 'PROOF_ADDRESS_REPRESENTATIVE';
      case 'acreditationDoc':
        return 'ACCOUNT_ACREDITATION';
    }
    this.chdRef.detectChanges();
  }

  getFileReader(): FileReader {
      const fileReader = new FileReader();
      const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
      return zoneOriginalInstance || fileReader;
  }

}
