import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';

import { Observable, from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StrapiService {

  constructor(private http: HttpClient, private storage: Storage) { }

  private strapi_api_url = environment.strapiUrl + '/api/';
  private strapi_common_token = environment.strapiCommonToken;
  public strapi_api_token: string;

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + this.strapi_api_token });
  }

  private getPublicHeaders(): HttpHeaders {
    return new HttpHeaders({ Authorization: 'Bearer ' + this.strapi_common_token });
  }

  private updateToken(token: string) {
    this.strapi_api_token = token;
    this.storage.set('STRAPITOKEN', token);
  }

  private getStrapiToken(): Observable<string> {
    if (this.strapi_api_token) {
      return of(this.strapi_api_token);
    }

    return from(this.storage.get('STRAPITOKEN')).pipe(
      switchMap((val: string) => {
        this.strapi_api_token = val;
        return of(val);
      })
    );
  }

  private request(method: string, url: string, body?: any, params?: HttpParams): Observable<any> {
    return this.getStrapiToken().pipe(
      switchMap((token: string) => {
        const headers = this.getHeaders();
        const options = { headers, params };
        let request$: Observable<any>;

        switch (method.toLowerCase()) {
          case 'get':
            request$ = this.http.get(url, options);
            break;
          case 'post':
            request$ = this.http.post(url, body, options);
            break;
          case 'put':
            request$ = this.http.put(url, body, options);
            break;
          // Añadir otros casos según necesidad
          default:
            throw new Error('Método HTTP no soportado');
        }

        return request$;
      })
    );
  }

  private publicRequest(method: string, url: string, body?: any, params?: HttpParams): Observable<any> {
    const headers = this.getPublicHeaders();
    const options = { headers, params };
    switch (method.toLowerCase()) {
      case 'get':
        return this.http.get(url, options);
      case 'post':
        return this.http.post(url, body, options);
      default:
        throw new Error('Método HTTP no soportado para login');
    }
  }

  strapiLogin(token) {
    const model = { userToken: token };
    try {
      this.publicRequest('post', this.strapi_api_url + 'auth/loginByEmail', model).subscribe(resp => {
        this.updateToken(resp['jwt']);
      }, error => {
        console.log(error, 'error de strapi')
      });
    } catch {
      console.log(Error, 'strapi error')
    }
  }

  getMeetingData(meetingId) {
    return this.request('get', this.strapi_api_url + `meetings/${meetingId}?populate[0]=comments,project,ics&populate[1]=comments.users_permissions_user,documents.document`);
  }

  delegateVote(userId, model) {
    return this.request('put', this.strapi_api_url + `censuses/${userId}`, model);
  }

  getCensusList(meetingId) {
    return this.publicRequest('get', this.strapi_api_url + `censuses?populate[users_permissions_user][fields][1]=email&populate[users_permissions_user][fields][2]=surname&populate[users_permissions_user][fields][3]=name&populate[users_permissions_user][fields][4]=profile_type&populate[users_permissions_user][fields][5]=company&populate[delegate][fields][1]=delegate&fields[0]&filters[meeting]=${meetingId}`)
  }

  getUserCensus(meetingId) {
    return this.request('get', this.strapi_api_url + `censuses?populate[0]=meeting,representative,delegate,users_permissions_user&populate[1]=representative.users_permissions_user&filters[meeting]=${meetingId}`);
  }

  getCensusDetailForUser() {
    return this.request('get', this.strapi_api_url + `users/me?populate[0]=censuses,role&populate[1]=censuses.meeting,censuses.delegate,censuses.representative&populate[2]=censuses.delegate.users_permissions_user,censuses.representative.users_permissions_user,censuses.meeting.documents,censuses.meeting.project&populate[3]=censuses.meeting.documents.document`);
  }

  getUserRole() {
    return this.request('get', this.strapi_api_url + `users/me?populate[0]=role`);
  }

  getUserVotes(meetingId) {
    return this.request('get', this.strapi_api_url + `records?populate[0]=votes,informative&filters[meeting]=${meetingId}`);
  }

  createVoteUser(vote) {
    return this.request('post', this.strapi_api_url + 'votes', vote);
  }

  updatevoteUser(vote, id) {
    return this.request('put', this.strapi_api_url + `votes/${id}`, vote);
  }

  createSignaturitTemplate(params) {
    return this.request('post', this.strapi_api_url + 'signaturit/createUrlTemplate', params);
  }

  createSignaturitDelegationIdentificator(params) {
    return this.request('post', this.strapi_api_url + 'signatures', params);
  }

  updateSignaturitDelegationIdentificator(params, id) {
    return this.request('put', this.strapi_api_url + `signatures/${id}`, params);
  }

  checkUserHasVote(email, meetingId) {
    return this.request('get', this.strapi_api_url + `votes?filters[users_permissions_user][email]=${email}&filters[record][meeting]=${meetingId}`);
  }

  checkUserRole(email) {
    return this.request('get', this.strapi_api_url + `users?filters[email]=${email}&populate=role`);
  }

  sendGenericMail(model) {
    return this.request('post', this.strapi_api_url + 'sendgrid', model);
  }

  getMaintenanceScreenInfo() {
    return this.publicRequest('get', `${this.strapi_api_url}maintenance-screen`);
  }

}
