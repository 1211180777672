import { Component, OnInit, Input, EventEmitter, Output, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'fun-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnChanges {

  @Input('buttonType') buttonType;
  @Input('title') title;
  @Input('id') id;
  @Input('span') span;
  @Input() filterIcon;
  @Input() count: number;
  @Input() showCount: boolean;
  @Output() private eventClick = new EventEmitter<void>();

  isDisabled = false;
  idB: string;

  constructor(public chdRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.id && this.id !== undefined) {
      this.idB = this.id;
    } else {
      this.idB = this.title;
    }
    this.chdRef.detectChanges();
  }

  ngOnChanges() {
    if (this.buttonType === 'disabled' || this.buttonType === 'secondary-disabled') {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
    if (this.id && this.id !== undefined) {
      this.idB = this.id;
    } else {
      this.idB = this.title;
    }
    this.chdRef.detectChanges();
  }

  emitEventClick() {
    if (!this.isDisabled) {
      this.eventClick.emit();
    }
  }
}
