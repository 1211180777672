import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { ProfileService } from 'src/app/services/profile.service';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'fun-ask-for-communications',
  templateUrl: './ask-for-communications.component.html',
  styleUrls: ['./ask-for-communications.component.scss'],
})
export class AskForCommunicationsComponent implements OnInit {

  @Input() public profile;
  @Output() public removeBadgeEvent = new EventEmitter<any>();

  loading = false;
  image = 11;
  btnList = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('COMMUNICATIONS.ASK_CTA1'),
  },
  {
    type: 'secondaryDark',
    index: 2,
    title: this.translate.instant('COMMUNICATIONS.ASK_CTA2'),
  }];
  text = this.translate.instant('COMMUNICATIONS.ASK_TXT1');

  constructor(
    private profileService: ProfileService,
    private chdRef: ChangeDetectorRef,
    private modalService: ModalService,
    private translate: TranslateService,
    private amplitudeService: AmplitudeService
    ) { }

  ngOnInit() {
    const currentMoment = moment().format('DD/MM/YYYY');
    const model = { lastAcceptCommunicationUpdate: currentMoment };
    this.updateProfile(model);
    this.chdRef.detectChanges();
  }

  async updateProfile(model) {
    await this.profileService.updateProfile(model).subscribe(() => {
      this.removeBadgeEvent.emit();
    }, error => {
      this.removeBadgeEvent.emit();
    });
    this.chdRef.detectChanges();
  }

  subscribe(id) {
    if (id === 1) {
      const model = { acceptCommunications: true };
      this.loading = true;
      this.profileService.updateProfile(model).subscribe((prof) => {
        this.loading = false;
        this.image = 14;
        this.btnList = [];
        this.text = this.translate.instant('COMMUNICATIONS.ASK_TXT2');
        this.profileService.subscribeFullCommunications(prof.username).subscribe(() => {
          this.chdRef.detectChanges();
        });
        this.amplitudeService.trackEvent('notif_advised', { accepted: true });
        this.chdRef.detectChanges();
      }, error => {
        this.modalService.removeModal();
        this.chdRef.detectChanges();
      });
    } else {
      this.amplitudeService.trackEvent('notif_advised', { accepted: false });
      this.modalService.removeModal();
    }
    this.chdRef.detectChanges();
  }

}
