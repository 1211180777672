import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'src/app/services/profile.service';
import { Profile } from 'src/app/shared/models/register.models';
import { Regions } from 'src/app/shared/other/regions';


@Component({
  selector: 'fun-register-person-address',
  templateUrl: './register-person-address.component.html',
  styleUrls: ['./register-person-address.component.scss']
})
export class RegisterPersonAddressComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() profile: Profile;
  @Input() towns: any;
  @Input() isMobile: boolean;

  public addressForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;
  public autocompleteMode = false;

  isNationalResidence: boolean;
  selectIsOpen = false;
  regionList = Regions.map(region => {
    return {
      id: region.code,
      name: region.name
    };
  });
  city: string;
  townList: any;
  taxRegion: any;

  desktopApp: boolean;

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    if (this.addressForm.valid) {
      this.sendInfoEvent.emit(this.addressForm.value);
      this.submitEvent.emit();
    } else {
      if ((event.target as Element).id === 'address') {
        document.getElementById('cp').focus();
      } else if ((event.target as Element).id === 'cp') {
        if (this.profile.taxCountry === 108) {
          document.getElementById('cp').blur();
        } else {
          document.getElementById('internationalRegion').focus();
        }
      } else if ((event.target as Element).id === 'internationalRegion') {
        document.getElementById('city').focus();
      }
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private profileService: ProfileService
    ) { }

  ngOnInit() {
    // Fix cuando no está definida la nacionalidad en los modal del perfil incompleto. Inicializamos los campos del data
    if (this.data !== undefined && this.data.residence !== null && this.data.residence !== undefined) {
      if (this.profile === null || this.profile === undefined) {
        this.profile = {
          taxCountry: this.data.residence,
          nationality: this.data.nation,
          taxAddress: null
        }
      } else {
        this.profile.taxCountry = this.data.residence;
        this.profile.nationality = this.data.nation;
        this.profile.taxAddress = null;
      }
    }
    this.isNationalResidence = (this.profile.taxCountry === null || this.profile.taxCountry === undefined || this.profile.taxCountry === 108) ? true : false;
    if (this.profile && this.profile !== undefined
      && (this.profile.taxCountry === 108 || this.profile.taxCountry === null || this.profile.taxCountry === undefined)) {
      if (this.profile.taxCity) {
        this.city = (this.profile.taxCity < 10) ? `0${this.profile.taxCity}` : this.profile.taxCity;
      }
      if (this.profile.taxRegion) {
        this.taxRegion = (this.profile.taxRegion < 10) ? `0${this.profile.taxRegion}` : this.profile.taxRegion.toString();
      }
      if (this.towns === null || this.towns === undefined) {
        this.towns = {
          list: []
        }
      } else {
        this.townList = this.towns.list !== null && this.towns.list !== undefined ? this.towns.list : [];
      }
      /* this.city = this.profile.taxCity; */
      if(this.isNationalResidence && (this.profile.taxAddress === null)) {
        this.autocompleteMode = true;
      }
    } else {
      this.autocompleteMode = (this.isNationalResidence) ? true : false;
      //this.isNationalResidence = false;
      this.city = this.profile!== null && this.profile !== undefined && this.profile.taxInternationalCity !== null ? this.profile.taxInternationalCity : '';
    }
    this.addressForm = new FormGroup({
      addressAutocomplete: new FormControl('', [Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ\' ,.0123456789ºª/]+$')]),
      address: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ\' ,.0123456789ºª/]+$')]),
      cp: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z0123456789]+$')]),
      city: new FormControl(this.city ? this.city : null, []),
      region: new FormControl(this.taxRegion ? this.taxRegion : null, []),
      internationalRegion: new FormControl('', [Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')])
    });
    this.formControlKeys = Object.keys(this.addressForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    // Fix cuando no está definida la nacionalidad. Por defecto, la tratamos como si fuera Spain
    if ((this.profile.taxCountry === 108) || (this.profile.taxCountry === null)){
      this.addressForm.controls['cp'].setValidators([Validators.required, Validators.pattern('^[0123456789]+$')]);
    } else {
      this.addressForm.controls['cp'].setValidators([Validators.required, Validators.pattern('^[A-Za-z0123456789]+$')]);
      this.addressForm.controls['city'].setValidators([Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚóºòöôõøœōÓºÒÖÔÕØŒŌáàäâãåąæāÁªÀÄÂÃÅĄÆĀéèëêęėēíïìîįīÍÏÌÎĮĪúüùûūÚÜÙÛŪ ]+$')]);
    }

    this.desktopApp = Capacitor.getPlatform() === 'web' ? true : false;
    this.chdRef.detectChanges();
  }

  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getInfo() {
    if (this.addressForm.valid) {
      this.sendInfoEvent.emit(this.addressForm.value);
    } else {
      this.sendErrorEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  changeRegion(selected = '') {
    this.addressForm.controls['city'].setValue(null);
    // Fix cuando no está definida la nacionalidad. Inicializamos a Spain para el selector de regiones
    this.profile.taxCountry = 108;
    this.profileService.getCities(this.profile.taxCountry, this.addressForm.value.region).subscribe(resp => {
      this.townList = resp;
      this.chdRef.detectChanges();
      this.getInfo();
    });
  }

  changeRegionCity(region, city) {
    this.addressForm.controls['city'].setValue(null);
    // Fix cuando no está definida la nacionalidad. Inicializamos a Spain para el selector de regiones
    this.profile.taxCountry = 108;
    this.profileService.getCities(this.profile.taxCountry, region).subscribe(resp => {
      this.townList = resp;
      this.townList.forEach(town => {
        if(town.name.indexOf(city) === 0) {
          this.addressForm.controls['city'].setValue(town.id);
        }
      });
      this.chdRef.detectChanges();
      this.getInfo();
    });
  }

  switchAutocomplete(mode) {
    this.autocompleteMode = mode;
    this.chdRef.detectChanges();
  }

  getAddress(value) {
    this.autocompleteMode = false;
    this.chdRef.detectChanges();
    let numberAddress = '';
    let regionId = '';
    let cityName = '';
    value.address_components.forEach(element => {
      switch (element.types[0]) {
        case 'street_number': numberAddress = element.long_name;
                              this.chdRef.detectChanges();
                              break;

        case 'route':
        case 'town_square':   const autoAddress = (numberAddress !== '')?element.long_name + ', ' + numberAddress:element.long_name;
                              this.addressForm.controls['address'].setValue(autoAddress);
                              this.chdRef.detectChanges();
                              break;

        case 'postal_code':   this.addressForm.controls['cp'].setValue(element.long_name);
                              this.chdRef.detectChanges();
                              break;

        case 'administrative_area_level_2':
                              this.regionList.forEach(region => {
                                if(region.name.indexOf(element.long_name) > -1) {
                                  regionId = region.id;
                                  this.addressForm.controls['region'].setValue(regionId);
                                  this.chdRef.detectChanges();
                                }
                              });
                              break;

        case 'locality':      cityName = element.long_name;
                              break;

      }
    });

    this.changeRegionCity(regionId, cityName);
  }

}
