import { Component, OnInit, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { HttpEventType, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileService } from './file.service';
import { IFile } from '../../models/file.models';

@Component({
  selector: 'fun-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss']
})
export class FileComponent implements OnInit, OnChanges {

  @Input() buttonType: string;
  @Input() buttonTitle: string;
  @Input() buttonAccept: string;
  @Input() urlFile: string;
  @Input() paramsFile: {};
  @Input() showProgress = true;
  @Input() token = '';
  @Output() private eventUploadResponse = new EventEmitter<HttpResponse<any> | HttpErrorResponse>();

  isDisabled = false;
  prevButtonType = '';

  @ViewChild('fileUpload', {static: false}) fileUpload: ElementRef;
  public file: IFile;

  constructor(
    private fileService: FileService,
    private chdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.prevButtonType = this.buttonType;
  }

  ngOnChanges() {
    if (this.buttonType === 'disabled' || this.buttonType === 'secondary-disabled') {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      this.fileService.toBase64(file).then(fileBase64 => {
         this.file = { data: fileBase64.split(',')[1], progress: 0 };
         this.chdRef.detectChanges();
         this.uploadFile(this.urlFile, this.file, this.paramsFile);
      });
    };
    fileUpload.click();
  }

  uploadFile(url: string, file: IFile, params?: {}): void {
    const data = file.data;
    params = {...params, file: data};
    this.disableButton();

    this.fileService.upload(url, params, this.token).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            this.chdRef.detectChanges();
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })).subscribe((response: HttpResponse<any> | HttpErrorResponse) => {
        if (typeof (response) === 'object') {
          this.emitEventUploadResponse(response);
          this.resetButton(file);
          this.enableButton();
        }
      }, (error: HttpErrorResponse) => {
        this.emitEventUploadResponse(error);
        this.resetButton(file);
        this.enableButton();
      });
  }

  enableButton(): void {
    this.buttonType = this.prevButtonType;
    this.isDisabled = false;
    this.chdRef.detectChanges();
  }

  disableButton(): void {
    this.buttonType = 'disabled';
    this.isDisabled = true;
    this.chdRef.detectChanges();
  }

  resetButton(file: IFile): void {
    this.fileUpload.nativeElement.value = '';
    file.progress = 0;
    this.chdRef.detectChanges();
  }

  emitEventUploadResponse(response: HttpResponse<any> | HttpErrorResponse): void {
    this.eventUploadResponse.emit(response);
  }

}
