import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Capacitor } from '@capacitor/core';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.enableTagManager && Capacitor.getPlatform() === 'web') {
  const script1 = document.createElement('script');
  script1.innerHTML = `(function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KKTNJ57');`;
  document.head.appendChild(script1);
} else {
  if (Capacitor.getPlatform() === 'web') {
    const script1 = document.createElement('script');
    script1.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-MZHLMD5');`;
    document.head.appendChild(script1);
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

  declare global {
    interface Window {
      _hsq: any[];  // Declaramos que _hsq es un array de cualquier tipo
    }
    var HubSpotConversations: {
      widget: {
        remove: () => void;
        load: () => void;
        close: () => void;
        refresh: () => void;
        open: () => void;
      };
      clear: (options: { resetWidget: boolean }) => void;  // Agregar clear() con la opción resetWidget
    };
  }  
