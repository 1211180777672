import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'fun-invest-success-taxdown',
  templateUrl: './invest-success-taxdown.component.html',
  styleUrls: ['./invest-success-taxdown.component.scss'],
})
export class InvestSuccessTaxdownComponent implements OnInit {

  @Input() username: string;
  @Output() public closeModalEvent = new EventEmitter<any>();

  loading = false;
  textHubs = `<h2>${this.translate.instant('invest.Success.title')}</h2> ${this.translate.instant('invest.Success.taxdownOK')}`;
  imageHubs = 6;
  btnList = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('invest.Success.taxdown_brn1')
  },
  {
    type: 'secondaryDark',
    index: 2,
    title: this.translate.instant('invest.Success.taxdown_brn2')
  }];

  constructor(private profileService: ProfileService, private cdRef: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {}

  clickBtn(id) {
    if (id === 1) {
      this.subscribeHubspotAsk();
    } else {
      this.closeModalEvent.emit();
    }
  }

  subscribeHubspotAsk() {
    this.loading = true;
    const id = environment.env === 'prod' ? 85199702 : 85196515;
    this.profileService.subscribeHubspot(this.username, [id]).subscribe(() => {
      this.closeModalEvent.emit();
      this.cdRef.detectChanges();
    }, error => {
      this.imageHubs = 1;
      this.textHubs = this.translate.instant('invest.Success.taxdownKO');
      this.btnList = [{
        type: 'primaryDark',
        index: 3,
        title: this.translate.instant('AUTOINVEST.FOOTER.NEXT')
      }];
      this.loading = false;
      this.cdRef.detectChanges();
    });
    this.cdRef.detectChanges();
  }
}
