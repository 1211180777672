import { EventEmitter, Injectable, Output } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { CommunicationService } from './communication.service';
import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { AppConfigurationService } from './app.configuration.service';
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { OfferCreateComponent } from '../shared/components/marketplace/offer-create/offer-create.component';
import { OfferStatusComponent } from '../shared/components/marketplace/offer-status/offer-status.component';
import { ToastService } from '../shared/components/toast/toast.service';
import { AppRoutes, MarketPlaceMyOffersModel, MarketPlaceOfferCreateModel, MarketPlaceProjectModel, MarketPlaceStockPackageModel } from '../shared/models/marketplace.models';
import { IFileButton } from '../shared/models/file.models';
import { ModalService } from '../shared/components/modal/modal.service';
import { AmplitudeService } from './amplitude.service';


@Injectable({
  providedIn: 'root',
})
export class OffersService {
  @Output() public createdOf = new EventEmitter<any>();
  @Output() public cancelOf = new EventEmitter<any>();
  @Output() public acceptBid = new EventEmitter<any>();
  @Output() public documentUploadedEvent = new EventEmitter<any>();
  public urlService = 'apiservices/marketplace/v1/marketplace';
  public urlsRoute = ['list-of-projects', 'dashboard'];
  public isCreated = false;

  constructor(
    private communicationService: CommunicationService,
    private utilsService: UtilsService,
    private modalService: ModalService,
    private authService: AuthService,
    private http: HttpClient,
    private localize: LocalizeRouterService,
    private navCtrl: NavController,
    private toastService: ToastService,
    private appConfigurationService: AppConfigurationService,
    private storage: Storage,
    private translate: TranslateService,
    private amplitudeService: AmplitudeService
  ) {}

  createOffer(id) {
    this.getProjects().subscribe((resProjects: MarketPlaceProjectModel[]) => {
      resProjects.forEach((element) => {
        if (element.id === id) {
          element.num = 2;
        } else {
          element.num = 1;
        }
      });
      this.shortProj(resProjects);
      const modalParams = { dataProjectList: resProjects };
      const title = this.translate.instant('offer.create_offer');
      this.modalService.showModal(OfferCreateComponent, modalParams, '', '', true).subscribe((modal: any) => {
        this.modalService.setPageTitle('Fundeen - Crear oferta');
        modal.setTitle(title);
        modal.getChildComponent().subscribe((componentRef: OfferCreateComponent) => {
          componentRef.eventGetStocksPackages.subscribe((resProject: any) => {
            if (resProject) {
              const projectId = resProject.projectId ? resProject.projectId : null;
              this.getStocksPackages(projectId).subscribe(
                (resPackages: MarketPlaceStockPackageModel[]) => {
                  componentRef.dataList = resPackages;
                },
                () => {
                  componentRef.dataList = [];
                }
              );
            }
          });
          componentRef.eventCreateOffer.subscribe((resDataOffer: MarketPlaceOfferCreateModel) => {
            this.postOffer(resDataOffer).subscribe(
              (resPostOffer: boolean) => {
                componentRef.offerCreated = resPostOffer;
                this.isCreated = true;
                this.createdOf.emit();
              },
              (error: Error) => {
                componentRef.offerCreated = false;
                const msg = this.translate.instant('MESSAGES.OFFER_CREATED');
                const infoMsg = msg;
                this.toastService.showToast(infoMsg, 'warning', true);
              }
            );
          });
          componentRef.eventButtonsRoute.subscribe((resRoute: string) => {
            this.utilsService.navigateTo(`/${resRoute}`);
            // TODO: Eliminar el setTimeout y añadir un loading.
            setTimeout(() => modal.close(), 600);
          });
        });
        modal.onClose().subscribe();
      });
    });
  }
  shortProj(resProjects) {
    resProjects.sort(function (a, b) {
      return Number(b.num) - Number(a.num);
    });
    return resProjects;
  }

  getProjects(): Observable<MarketPlaceProjectModel[]> {
    return new Observable((observer) => {
      this.communicationService.get(`${this.urlService}/openProjects`, null).subscribe(
        (resProjects: MarketPlaceProjectModel[]) => {
          observer.next(resProjects);
          return observer.complete();
        },
        () => {
          observer.next([]);
          return observer.complete();
        }
      );
    });
  }

  getStocksPackages(projectId?: number): Observable<MarketPlaceStockPackageModel[]> {
    return this.communicationService.get(`${this.urlService}/stocksPackages/${projectId}`, null);
  }

  postOffer(offer: MarketPlaceOfferCreateModel): Observable<boolean> {
    return new Observable((observer) => {
      this.communicationService.post(`${this.urlService}/offer`, offer).subscribe(
        (resPost: string) => {
          if (resPost.toLowerCase() !== 'ok') {
            return observer.error(false);
          }
          observer.next(true);
          return observer.complete();
        },
        () => {
          return observer.error(false);
        }
      );
    });
  }
  showOfferDetails(offerSelected: MarketPlaceMyOffersModel) {
    if (!this.authService.isRefreshExpired()) {
      this.showOfferDetailsAction(offerSelected);
    } else if(!this.authService.isMobileExpired()) {
      this.authService.renewSession('showOfferDetails');
      this.authService.refreshLoginevent.subscribe((action) => {
        if (action === 'showOfferDetails') {
          //this.showOfferDetailsAction(offerSelected);
        }
      });
    }
  }
  showOfferDetailsAction(offerSelected: MarketPlaceMyOffersModel) {
    const domain = this.appConfigurationService.get('domain');
    const fileButtonData: IFileButton = {
      buttonType: 'default',
      buttonAccept: '.pdf',
      urlFile: `${domain}apiservices/userfile/v1/marketplace/file `,
      paramsFile: {
        offer: offerSelected.id,
        format: 'application/pdf',
      },
      showProgress: true,
      token: this.authService.authData.accessToken,
    };

    const offerContactSelected = this.getOfferContact(offerSelected.id);
    const actionUpdateOffer = this.getOffer(offerSelected.id);
    const modalParams = {
      offerData: offerSelected,
      offerContactData: offerContactSelected,
      fileButtonData,
      actionUpdateOffer,
    };
    const offerStatus = this.translate.instant('offer-status.title');
    this.modalService.showModal(OfferStatusComponent, modalParams, '').subscribe((modal: any) => {
      this.modalService.setPageTitle('Fundeen - Estado de la oferta');
      modal.setTitle(offerStatus);
      modal.setStatus(offerSelected.status);
      modal.setLostTagName(offerSelected.tagName);
      modal.getChildComponent().subscribe((componentRef: OfferStatusComponent) => {
        componentRef.eventButtonsRoute.subscribe((routeData: AppRoutes) => {
          if (routeData === 'list-projects') {
            this.navCtrl.navigateRoot([this.localize.translateRoute('/portfolio')]);
          } else if (routeData === 'detail-project') {
            this.navCtrl.navigateRoot([this.localize.translateRoute('/list-of-projects')]);
          } else if (routeData === 'contact-us') {
            const subject = 'Problema de documentos del tablón de anuncios';
            const mailText = `mailto:soporte@fundeen.com?subject=${subject}&body=`;
            window.location.href = mailText;
          } else {
            this.navCtrl.navigateRoot([this.localize.translateRoute(`/${routeData}`)]);
          }
          modal.close();
        });
        componentRef.eventAcceptBidClick.subscribe((offer: any) => {
          this.actionAcceptBid(modal, offer.offerData.id, offer.idBid, offer.bids);
        });
        componentRef.eventRejectBidClick.subscribe((offer: any) => {
          let url;
          if (offer.idBid === undefined) {
            url = `apiservices/marketplace/v1/marketplace/offer/${offer.offerData.id}/lost`;
          } else {
            url = `apiservices/marketplace/v1/marketplace/offer/${offer.offerData.id}/lost/${offer.idBid}`;
          }
          this.communicationService.post(url, null).subscribe({
            next: (data) => {
              if (offer.idBid === undefined) {
                const txt =
                this.translate.instant('MESSAGES.REJECT_OFFER_1') + offer.offerData.amount +
                this.translate.instant('MESSAGES.REJECT_OFFER_2') + offer.offerData.project_name +
                this.translate.instant('MESSAGES.REJECT_OFFER_3');
                this.toastService.showToast(txt, 'info', true);
                modal.close();
                this.cancelOf.emit(undefined);
              } else {
                componentRef.bidRejected(offer.idBid);
                this.cancelOf.emit(true);
              }
              this.amplitudeService.trackEvent('marketplace_operation_done', { operation: 'Cancela oferta' });
            },
            error: (error) => {
              console.error(error);
              const errorMsg = this.translate.instant('ERRORS.500');
              this.toastService.showToast(errorMsg, 'warning');
            },
          });
        });
        componentRef.eventRelaunchOfferClick.subscribe((offer: MarketPlaceMyOffersModel) => {
          this.modalService.removeModal();
          if (!this.authService.isRefreshExpired()) {
            this.createOffer(offer.project_id);
          } else if(!this.authService.isMobileExpired()) {
            this.authService.renewSession('RelaunchOffer');
            this.authService.refreshLoginevent.subscribe((action) => {
              if (action === 'RelaunchOffer') {
                //this.createOffer(offer.project_id);
              }
            });
          }
        });
        componentRef.eventBuyerSign.subscribe(() => {
          console.log('eventBuyerSign');
        });
        componentRef.eventUploadDocument.subscribe(() => {
          this.documentUploadedEvent.emit();
        });
        componentRef.eventSellerSign.subscribe(() => {
          console.log('eventSellerSign');
        });
        componentRef.eventNotSellerName.subscribe(() => {
          this.storage.get('NAME').then((val) => {
            componentRef.getCurrentSellerName(val);
          });
        });
      });
      modal.onClose().subscribe();
    });
  }

  actionAcceptBid(modal: any, offerId: number, bidId?: number, bids?: any) {
    this.communicationService
      .post(`apiservices/marketplace/v1/marketplace/offer/${offerId}/win${bidId ? '/'.concat(bidId.toString()) : ''}`, null)
      .subscribe({
        next: (data) => {
          modal.close();
          this.acceptBid.emit(offerId);
          bids.forEach(element => {
            if (element.id === bidId) {
              const txt = this.translate.instant('BID.HAS_BEEN_ACCEPTED', { name: element.name, price: element.price });
              this.toastService.showToast(txt, 'success', true);
              this.amplitudeService.trackEvent('marketplace_operation_done', { operation: 'Acepta una puja' });
            }
          });
        },
        error: (error) => {
          console.error(error);
          const errorMsg = this.translate.instant('ERRORS.500');
          if (bidId !== undefined) {
            this.cancelOf.emit(undefined);
          } else {
            modal.close();
            this.cancelOf.emit(undefined);
          }
          this.toastService.showToast(errorMsg, 'warning');
        },
      });
  }

  getOfferContact(offerId: number) {
    return this.communicationService.get(`apiservices/marketplace/v1/marketplace/offer/${offerId}/contact`, null);
  }

  getOffer(offerId: number) {
    return this.communicationService.get(`apiservices/marketplace/v1/marketplace/offer/${offerId}`, null);
  }
}
