import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { io } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { OvervewMeetingChatComponent } from '../overvew-meeting-chat/overvew-meeting-chat.component';
import { OvervewMeetingListComponent } from '../overvew-meeting-list/overvew-meeting-list.component';
import { FormControl, FormGroup } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ListOfProjectsService } from 'src/app/services/list-of-projects.service';
import { ToastService } from '../../../toast/toast.service';
import { StrapiService } from 'src/app/services/strapi.service';
import { OverviewMeetingDelegationComponent } from '../overview-meeting-delegation/overview-meeting-delegation.component';
import { ModalService } from '../../../modal/modal.service';
import { UtilsService } from 'src/app/services/utils.service';
import { OverviewMeetingDocumentsComponent } from '../overview-meeting-documents/overview-meeting-documents.component';
import { AlertService } from '../../../alert/alert.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-overvew-meeting-direct',
  templateUrl: './overvew-meeting-direct.component.html',
  styleUrls: ['./overvew-meeting-direct.component.scss'],
})
export class OvervewMeetingDirectComponent implements OnInit, OnDestroy {

  @Input() public meeting;
  @Input() public project;
  @Output() public goBackEvent = new EventEmitter<any>();
  @ViewChild('chatComponent', {static: false}) chatComponent: OvervewMeetingChatComponent;
  @ViewChild('listComponent', {static: false}) listComponent: OvervewMeetingListComponent;

  youtubeIframe: any;
  messages = [];
  socket: any;
  loaded = false;
  loadedRecords = false;
  user: any;

  usersList = [];
  strapiUser: any;

  httpSubscription: Subscription;

  public delegateForm: FormGroup;
  componentActive = true;
  strapiUserId: any;
  hasVoteDelegate = false;
  isRepresentative = false;
  representativeList = [];

  delegateParams: any;
  hasVoted = false;
  showVideo = true;
  showChat = false;

  text = this.translate.instant('MEETING.DELEGATE_CANT_ASSIST_2');
  image = 1;
  btnList = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('AUTOINVEST.FOOTER.PREV'),
  }];

  hasPublicrole = false;
  role: string;

  constructor(
    private sanitizer: DomSanitizer,
    public chdRef: ChangeDetectorRef,
    private lisOfProjectsService: ListOfProjectsService,
    private toastService: ToastService,
    private strapiService: StrapiService,
    private modalService: ModalService,
    private utils: UtilsService,
    private alertService: AlertService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.strapiService.getUserRole().subscribe(resp => {
      this.role = resp.role.type;
      this.getMeetingData();
    });
  }

  getMeetingData() {
    try  {
      window.HubSpotConversations.widget.close();
      window.HubSpotConversations.widget.remove();

    } catch {}

    if (this.meeting.attributes['youtube_url']) {
      const youtubeParse = JSON.parse(this.meeting.attributes['youtube_url']);
      this.youtubeIframe = this.sanitizer.bypassSecurityTrustHtml(youtubeParse.rawData.html);
    }
    this.strapiService.getUserCensus(this.meeting.id).subscribe(val => {
      this.strapiUser = {
        id: val.data[0].id,
        name: val.data[0].attributes.users_permissions_user.data.attributes.username,
        participations: val.data[0].attributes.participations,
        social_capital: val.data[0].attributes.social_capital,
        votes: val.data[0].attributes.votes,
        users_permissions_user: val.data[0].attributes.users_permissions_user,
        delegate: val.data[0].attributes.delegate || null,
        representative: val.data[0].attributes.representative || null,
        role: this.role
      };
      this.strapiUserId = this.strapiUser.id;
      this.user = this.strapiUser.name;
      if (this.meeting.attributes.status !== 'oculta') {
          this.socket = io(environment.strapiUrl);
          this.socket.on('connect', () => {
            this.socket.emit('join_project', {
              meetingId: this.meeting.id,
              //authToken: strapi_api_token,
              user:  {username: this.user, name: this.strapiUser.users_permissions_user.data.attributes.name}
            });
            this.socket.on('chat_message', (data) => {
              this.chatComponent.getComment(data);
              this.chdRef.detectChanges();
            });

            this.socket.on('update_records_user', (data) => {
              if (this.meeting.id === data.meetingId) {
                this.listComponent.updateMeetingListStatus(data.recordUpdated);
              }
              this.chdRef.detectChanges();
            });

            this.strapiService.getUserVotes(this.meeting.id).subscribe(response => {
              this.meeting.attributes.records = response;
              //hasVoted
              this.meeting.attributes.records.data.forEach(element => {
                const votes = element.attributes.votes.data;
                if (votes && votes.length > 0) {
                  this.hasVoted = true;
                }
              });
              this.loadedRecords = true;
            });

            this.socket.on('update_meeting_status', (data) => {
              this.meeting.attributes.status = data.status;
              this.chdRef.detectChanges();
            })

            if (this.meeting.attributes.status !== 'oculta') {
              if (this.strapiUser.representative.data && this.strapiUser.representative.data.length > 0) {
                this.isRepresentative = true;
                this.representativeList = this.strapiUser.representative.data;
              }
              if (this.strapiUser.delegate && this.strapiUser.delegate.data !== null) {
                this.hasVoteDelegate = true;
              }

              this.strapiService.getCensusList(this.meeting.id).subscribe(val => {
                this.usersList = val.data.filter(item => {
                  return item.attributes.users_permissions_user.data.attributes.email !== this.user && item.attributes.delegate.data === null;
                }).map(filteredItem => ({
                    id: filteredItem.id,
                    email: filteredItem.attributes.users_permissions_user.data.attributes.email,
                    name: filteredItem.attributes.users_permissions_user.data.attributes.profile_type !== 'juridica' ?
                     `${filteredItem.attributes.users_permissions_user.data.attributes.name} ${filteredItem.attributes.users_permissions_user.data.attributes.surname}`
                     : filteredItem.attributes.users_permissions_user.data.attributes.company,
                    // profile_type: filteredItem.attributes.users_permissions_user.data.attributes.profile_type,
                    // company: filteredItem.attributes.users_permissions_user.data.attributes.company,
                }));
              });
            }
            this.loaded = true;
            this.chdRef.detectChanges();
          });
        } else {
          const msg = this.translate.instant('MEETING.NO_OPENED');
          this.toastService.showToast(msg, 'warning', true);
          this.goBack();
        }
    });

    this.lisOfProjectsService.getMkpProjectList().subscribe(() => {})
    this.httpSubscription = interval(20 * 60 * 1000)
    .pipe(
      takeWhile(() => this.componentActive)
    )
    .subscribe(() => {
      this.lisOfProjectsService.getMkpProjectList().subscribe(() => {})
    });

    this.delegateForm = new FormGroup({
      users: new FormControl('', []),
    });
  }

  sendMessage(message) {
    this.socket.emit('chat_message', message);
    this.chdRef.detectChanges();
  }

  sendVote(vote) {
    this.socket.emit('meeting_vote', vote);
    this.hasVoted = true;
  }

  goBack() {
    this.goBackEvent.emit();
  }

  changeDirect(type) {
    switch(type) {
      case 'video': this.showVideo = !this.showVideo;
                    this.showChat = false;
                    break;
      case 'chat':  this.showVideo = false;
                    this.showChat = !this.showChat;
                    break;
    }
    this.chdRef.detectChanges();
  }

  delegateVoteAdvise() {
    const dateObj = this.utils.parseDateInObj(this.meeting.attributes.date);
    const userstring = this.strapiUser.users_permissions_user.data.attributes.profile_type !== 'juridica' ?
    `${this.strapiUser.users_permissions_user.data.attributes.name} ${this.strapiUser.users_permissions_user.data.attributes.surname}` :
    `${this.strapiUser.users_permissions_user.data.attributes.name} ${this.strapiUser.users_permissions_user.data.attributes.surname} en representación de ${this.strapiUser.users_permissions_user.data.attributes.company}`;
    const dataModel = {
      data: {
        meeting: this.meeting.id,
        companyName: this.meeting.attributes.type === 'ordinaria' ?  ` Junta General Ordinaria de ${this.meeting.attributes.society} ` : ` Junta General Extraordinaria de ${this.meeting.attributes.society} `,
        projectId: this.project.id,
        delegateId: this.delegateForm.value.users,
        delegateEmail: this.usersList.find(user => user.id === this.delegateForm.value.users).email,
        delegate: this.usersList.find(user => user.id === this.delegateForm.value.users).name,
        user: userstring,
        email: this.strapiUser.name,
        userId: this.strapiUser.id,
        day: dateObj.day,
        hour: dateObj.hour,
        strapiUserId: this.strapiUser.users_permissions_user.data.id,
        template: 'delegacion_firma' //plantilla_firma_delegacion
      }
    };
    this.modalService.showModal(OverviewMeetingDelegationComponent, dataModel, '', '', true).subscribe((modal: any) => {
      modal.getChildComponent().subscribe((componentRef: OverviewMeetingDelegationComponent) => {
        componentRef.closeModalEvent.subscribe(() => {
          this.modalService.removeModal();
        })
        componentRef.signedEvent.subscribe(() => {
          this.loaded = false;

          const model = {
            meeting: this.meeting.id,
            delegate: this.delegateForm.value.users,
            representative: null
          }

          this.strapiService.delegateVote(this.strapiUser.id, {data: model}).subscribe(() => {
            this.loaded = true;
            this.hasVoteDelegate = true;
            this.isRepresentative = false;
            componentRef.voteDelegated();
            if (this.representativeList && this.representativeList.length > 0) {
              this.cancelDelegations();
            }
          });
        })
      });
      modal.onClose().subscribe();
    });
  }

  cancelDelegations() {
    this.loaded = false;
    const model = {
      meeting: this.meeting.id,
      delegate: this.strapiUser.delegate,
      representative: null
    }
    this.strapiService.delegateVote(this.strapiUser.id, {data: model}).subscribe(() => {
      this.loaded = true;
      this.isRepresentative = false;
      this.toastService.showToast(this.translate.instant('MEETING.DELEGATION_CANCELED'), 'success', true);
      this.sendCancelDelegationsMail();
    });
  }

  sendCancelDelegationsMail(index: number = 0) {
    if (index < this.representativeList.length) {
      const dateObj = this.utils.parseDateInObj(this.meeting.attributes.date);
      const origin = environment.origin;
      let model = {
        name: this.representativeList[index].attributes.users_permissions_user.data.attributes.name,
        date: dateObj.day,
        hour: dateObj.hour,
        title: this.translate.instant('MEETING.CANCEL_VOTE'),
        texto: `Junta General Ordinaria de ${this.meeting.attributes.society}`,
        link_app: `${origin}/es/acceso?meetingId=${this.meeting.id}`,
        link_calendar: ""
      }
      if (this.meeting.ics && this.meeting.ics.data !== null) {
        model['link_calendar'] = this.meeting.ics.data.attributes.url;
      }
      const mailData = {
        templateId: 'd-bbc83782fb724b50950dd45868b19fa5',
        to: [this.representativeList[index].attributes.users_permissions_user.data.attributes.email],
        from: {
          email: "legal@fundeen.com",
          name: "Fundeen - Legal"
        },
        dynamicData: model,
      };
      setTimeout(() => {
        this.sendMail(mailData, () => {
          this.sendCancelDelegationsMail(index + 1);
        });
      }, 100);
    }
  }

  sendMail(mailData, callback) {
    this.strapiService.sendGenericMail(mailData).subscribe(() => {
      this.chdRef.detectChanges();
      if (callback) {
        callback();
      }
    });
  }

  downloadDocuments() {
    const model = {
      documents: this.meeting.attributes.documents
    }

    this.alertService.showModal(OverviewMeetingDocumentsComponent, model,'', '', true).subscribe((modal: any) => {
      modal.activeCross().subscribe();
      modal.onClose().subscribe();
    });
  }

  ngOnDestroy() {
    this.httpSubscription.unsubscribe();
    if (this.socket) {
      this.socket.off('connect');
      this.socket.off('meeting_vote');
      this.socket.off('chat_message');
      this.socket.off('join_project');
      this.socket.off('update_records_user');
      this.socket.off('meeting_vote');
      this.socket.disconnect();
      this.socket.removeAllListeners();
      this.chdRef.detectChanges();
    }
    this.chdRef.detectChanges();
  }

}
