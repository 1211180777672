import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-invest-ifr',
  templateUrl: './invest-ifr.component.html',
  styleUrls: ['./invest-ifr.component.scss'],
})
export class InvestIfrComponent implements OnInit {
  @Input() url: any;
  @Input() ifr: any;
  @Input() transactionId: any;
  @Input() projectData: any;
  @Output() public signEvent = new EventEmitter<any>();
  @Output() public signErrorEvent = new EventEmitter<any>();
  @Output() public eventCloseModalCard = new EventEmitter<any>();
  @Output() public eventNewInvest = new EventEmitter<any>();
  @Output() public eventStopTime = new EventEmitter<any>();
  @Output() public startSignEvent = new EventEmitter<any>();
  @Output() public signaturitIssueEvent = new EventEmitter<any>();
  @Output() public purchaseEvent = new EventEmitter<any>();
  @Output() public editInvestEvent = new EventEmitter<any>();
  @Output() public goToPreviewDocumentsEvent = new EventEmitter<any>();
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
  urlSafe: SafeResourceUrl;
  isCharged = false;

  projectName;
  status;
  errorPas;
  projectId;
  investment;
  readDocuments = false;
  signDocuments = false;
  paymentType: string;
  constructor(
    private sanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private amplitudeService: AmplitudeService
    ) {
    window.addEventListener('message', this.receiveMessage, false);
  }

  ngOnInit() {
    registerLocaleData(es);
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    switch (this.ifr) {
      case 1:
        this.operateSignaturit();
        break;
      case 2:
        this.operateLemonway();
        break;
    }
    this.amplitudeService.trackEvent('invest_sign_documents', {});
    this.cdRef.detectChanges();
  }
  ngAfterViewInit(): void {}
  receiveMessage(event) {
    this.cdRef.detectChanges();
  }
  operateSignaturit() {
    this.startSignEvent.emit();
    window.addEventListener('message', e => {
      // ready, signed
      this.isCharged = true;
      this.cdRef.detectChanges();
      if (e.data.event === 'completed') {
        this.signEvent.emit(this.ifr);
        this.amplitudeService.trackEvent('invest_sign_ok', {});
      } else {
        if (e.data.event === 'declined' || e.data.event === 'declined') {
          this.signErrorEvent.emit();
          this.amplitudeService.trackEvent('invest_sign_ko', { status: e.data.event });
        }
      }
      if (e.data.event === 'ready') {
        this.readDocuments = true;
        if (this.projectData && this.projectData && undefined && this.projectData.investByCard) {
          this.paymentType = 'Card';
        } else {
          this.paymentType = 'Wallet';
        }
      }
      // if (e.data.event === 'signed') {}
      if (e.data.message === 'signaturit issue') {
        this.signaturitIssueEvent.emit();
      }
    });
    this.cdRef.detectChanges();
  }
  operateLemonway() {
    window.addEventListener('message', e => {
      switch (e.data.message) {
        case 'close timer':
          this.eventStopTime.emit();
          break;
        case 'go home':
          this.eventCloseModalCard.emit();
          break;
        case 'new invest':
          this.eventNewInvest.emit();
          break;
        case 'purchase':
          this.purchaseEvent.emit(e.data.data);
          break;
      }
    });
    this.cdRef.detectChanges();
  }
  charged(event) {
    if (event.type === 'load') {
      setTimeout(() => {
        this.isCharged = true;
      }, 5000);
    }
    if (this.ifr === 2) {
      window.addEventListener('message', e => {
      });
      this.route.queryParams.subscribe(queryParams => {
        this.route.params.subscribe(routeParams => {
          setTimeout(() => {
            this.isCharged = true;
          }, 5000);
        });
      });
    }
    this.cdRef.detectChanges();
  }
  prev() {
    if (this.projectData.signDocuments !== undefined && this.projectData.signDocuments !== null && this.ifr === 1) {
      this.goToPreviewDocumentsEvent.emit();
    } else {
      this.editInvestEvent.emit();
    }
  }
}
