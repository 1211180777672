<div class="card rounded-2xl relative w-full md:w-[460px] min-h-[224px] bg-white mb-32 md:mb-0">
  <div class="w-full h-full gradient-card p-24 rounded-2xl min-h-[224px] ">
    <div class="pb-12">
      <p class="font-sans text-l text-eggplant-100/[0.25] uppercase">{{'REFERRALS.PROMOTIONAL_MONEY' | translate }}</p>
      <p class="font-sans text-eggplant-100 text-h600">{{ promotionalCode | localeNumber }} <span class="text-h300"> €</span></p>
    </div>
    <div class="pb-12" *ngIf="numInversorPaid > 0 || numInversorNoPaid > 0">
      <p class="font-sans text-l text-eggplant-100/[0.25]">{{'REFERRALS.NUM_INVERSOR_PAID' | translate }} / {{'REFERRALS.NUM_INVERSOR_NOPAID' | translate }}</p>
      <p class="font-sans text-eggplant-100 text-h600">{{ numInversorPaid | localeNumber }} / {{ numInversorNoPaid | localeNumber }}</p>
    </div>
    <div class="pb-12" *ngIf="numNoInversor > 0">
      <p class="font-sans text-l text-eggplant-100/[0.25]">{{'REFERRALS.NUM_NOINVERSOR' | translate }}</p>
      <p class="font-sans text-eggplant-100 text-h600">{{ numNoInversor | localeNumber }}</p>
    </div>
    <img class="isotipo-card" src="../../../../assets/images/new_branding/isotipo-card.svg" alt="">
  </div>
</div>