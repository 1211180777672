<div class="register">
    <div class="body">
        <div class="RLDocument-container">
            <div class="inp">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.identity.doc_type' | translate}}"
                    [parentForm]="RLDocForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="identityDocumentTypeRepresentative"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.identity.doc_type' | translate}}"
                    (selectChange)="changeType()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="docList"
                    [selectedItem]="checkDefaultType()"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="RLDocForm">
                    <div class="inp select form-input">
                        <label>{{'register.identity.doc_type' | translate}}</label>
                        <select class="select-box" [disabled]="disabled" [ngClass]="{'option': RLDocForm.controls['identityDocumentTypeRepresentative'].value > 0}"
                        name="identityDocumentTypeRepresentative" formControlName="identityDocumentTypeRepresentative" (change)="changeType()">
                            <option hidden [value]="0">{{'register.identity.doc_type' | translate}}</option>
                            <option *ngFor="let data of docList"
                            [value]="data.id">
                                {{ data.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.identity.doc_number' | translate}}"
                    [parentForm]="RLDocForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="identityDocumentNumberRepresentative"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.identity.doc_number' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (valueChanged)="getInfo()"
                    [value]="data.identityDocumentNumberRepresentative"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
