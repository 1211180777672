<div *ngIf="!hasSecondLevel">
    <div class="txt">
        <p *ngFor="let txt of text">{{ txt }}</p>
    </div>
</div>
<div *ngIf="hasSecondLevel">
    <div class="txt-container" *ngIf="currentLevel === 'second'">
        <div class="txt">
            <p *ngFor="let txt of currentEl">{{ txt }}</p>
        </div>
    </div>
    <!---->
    <div *ngIf="currentLevel === 'first'">
        <div class="list">
            <p *ngFor="let txt of currentEl[0].subtitles" (click)="changeLevel(txt.index)">{{ txt.title }}</p>
        </div>
        <div class="help">
            <p (click)="goToHelpCenter()">{{ 'help.help_center' | translate }}</p>
        </div>
    </div>
    <!---->
</div>
