<div class="general-container register flex flex-col" [ngClass]="{'mob-general': isMobileApp !== undefined}">
    <div class="body-content">
        <div class="step ">
            <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
                <h2 class="font-sans text-h600 text-eggplant-100">{{headerTitle}}</h2>
                <p class="font-sans text-center text-xl text-gray-40">{{headerSubtitle}}</p>
            </div>
            <div class="body">
                <div class="code-container">
                    <img class="default_picture" *ngIf="imageSrc === undefined" src="../../../../../assets/images/register/as.svg" alt="letter">
                    <img class="real_picture" *ngIf="imageSrc !== undefined" [src]="imageSrc" alt="letter">
                    <h4 class="font-sans text-center text-l text-gray-40" *ngIf="isFirst !== 'first'" (click)="repeat()">{{ 'register.document.picture.repeat' | translate }}</h4>
                </div>
            </div>
        </div>

    <div class="mobile-footer">
        <fun-new-button (eventClick)="next()" class="next-btn" buttonType="primaryDark" title="{{ 'register.btn.continue' | translate }}"
        [classExtra]="' !w-full '" [size]="'xl'"></fun-new-button>
    </div>
    <div class="desktop-footer">
        <div class="content-footer">
            <fun-new-button (eventClick)="prev()" class="back-btn" buttonType="ghost" title="{{ 'register.btn.back' | translate }}"
             [classExtra]="' !w-full '" [size]="'xl'"></fun-new-button>

            <fun-new-button (eventClick)="next()" class="next-btn" buttonType="primaryDark" title="{{ 'register.btn.continue' | translate }}"
             [classExtra]="' !w-full '" [size]="'xl'"></fun-new-button>
        </div>
    </div>
</div>
