<div class="flex flex-col gap-20 general-container">
  <fun-spinner *ngIf="loading"></fun-spinner>
  
  <div class="appear lst rounded-2xl bg-white p-24">
    <h3 class="text-eggplant-100 font-sans text-h400 md:text-h500 pb-16 md:pb-32">{{ 'REFERRAL.PAGE.LIST_INVERSOR' | translate }}</h3>
    <div *ngIf="referralsInversor.length && !loading">
        <div *ngFor="let referral of referralsInversor" class="flex w-full py-8 border-b border-b-eggplant-100/[0.1] first:pt-0 last:border-0">
            <div class="w-full flex justify-between full-row">
                <div class="w-[70%] md:w-[65%]">
                    <p class="font-sans text-l text-eggplant-100">{{ referral.user }} ( {{ referral.useDate }} )</p>
                    <p class="font-sans text-m text-eggplant-100/[0.4]" *ngIf="referral.type === 'paid'">{{ 'REFERRAL.PAGE.STATUS_PAID' | translate }}</p>
                    <p class="font-sans text-m text-eggplant-100/[0.4]" *ngIf="referral.type !== 'paid'">{{ 'REFERRAL.PAGE.STATUS_UNPAID' | translate }}</p>
                </div>
                <div class="!pr-[16px] w-[30%] md:w-[35%]">
                    <p [ngClass]="{ 'text-[#39C847]': referral.type === 'paid', 'text-eggplant-100': referral.type !== 'paid'}" class="text-right font-sans text-l md:text-xl">
                        {{ referral.amount | localeNumber }} €
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex gap-12" *ngIf="!referralsInversor.length && !loading" >
        <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
        <p class="font-sans text-eggplant-100/[0.4] text-h300">{{ 'REFERRAL.PAGE.EMPTY_INVERSOR' | translate }}</p>
    </div>
  </div>

  <div class="appear lst rounded-2xl bg-white p-24">
    <h3 class="text-eggplant-100 font-sans text-h400 md:text-h500 pb-16 md:pb-32" >{{ 'REFERRAL.PAGE.LIST_INVERSOR_PENDING' | translate }}</h3>
    <div *ngIf="referralsNoInversor.length && !loading">
        <div *ngFor="let referral of referralsNoInversor" class="flex w-full py-8 border-b border-b-eggplant-100/[0.1] first:pt-0 last:border-0">
            <div class="w-full flex justify-between full-row">
                <div class="w-[100%]">
                    <p class="font-sans text-l text-eggplant-100">{{ referral.emailUser }}</p>
                    <p class="font-sans text-m text-eggplant-100/[0.4]" >{{ 'REFERRAL.PAGE.STATUS_PENDING' | translate }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="flex gap-12" *ngIf="!referralsNoInversor.length && !loading" >
        <fun-icon [name]="'info-circle'" [style]="'w-24 h-24 stroke-gray-40 stroke-2'"></fun-icon>
        <p class="font-sans text-eggplant-100/[0.4] text-h300">{{ 'REFERRAL.PAGE.EMPTY_INVERSOR_PENDING' | translate }}</p>
    </div>  
  </div>

</div>

