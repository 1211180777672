import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fun-wallet-card',
  templateUrl: './wallet-card.component.html',
  styleUrls: ['./wallet-card.component.scss'],
})
export class WalletCardComponent implements OnInit {

  @Input() balance = 0;
  @Input() moneyOutBalance = 0;
  @Input() promotionalCode = 0;

  constructor() { }

  ngOnInit() {}

}
