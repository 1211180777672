import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reserve-investment-advice',
  templateUrl: './reserve-investment-advice.component.html',
  styleUrls: ['./reserve-investment-advice.component.scss'],
})
export class ReserveInvestmentAdviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
