import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavController } from '@ionic/angular';
import { BadgeService } from 'src/app/services/badge.service';
import { ModalService } from '../modal/modal.service';
import { AskForCommunicationsComponent } from '../ask-for-communications/ask-for-communications.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'fun-badge-group',
  templateUrl: './badge-group.component.html',
  styleUrls: ['./badge-group.component.scss'],
})
export class BadgeGroupComponent implements OnInit {

  @Input() props: any;
  @Output() public eventClick = new EventEmitter<any>();

  contentStyle = {
    'base': 'flex w-auto items-center cursor-pointer justify-between rounded-2xl bg-gray-10 md:pl-4 md:pr-8 md:py-4 p-12',
    'primary-medium': 'bg-gray-25',
    'primary-dark': 'bg-gray-10',
    'primary-light': 'bg-gray-10',
    'error-medium': 'bg-[#FDCBCC]',
    'error-dark': 'bg-[#FDEDED]',
    'error-light': 'bg-[#FDEDED]',
    'success-medium': 'bg-[#D0F9DE]',
    'success-dark': 'bg-[#EBFCF2]',
    'success-light': 'bg-[#EBFCF2]',
  };
  contentClass: string;
  titleStyle = {
    'base': 'font-sans text-s px-10 py-2 rounded-xl w-fit',
    'primary-medium': 'bg-white text-eggplant-100',
    'primary-dark': 'bg-eggplant-100 text-white',
    'primary-light': 'bg-white text-eggplant-100',
    'error-medium': 'bg-white text-[#FD565B]',
    'error-dark': 'bg-[#FD565B] text-white',
    'error-light': 'bg-white text-[#FD565B]',
    'success-medium': 'bg-white text-[#039755]',
    'success-dark': 'bg-[#039755] text-white',
    'success-light': 'bg-white text-[#039755]',
  }
  titleClass: string;
  iconStyle = {
    'base': 'w-24 h-24 stroke-[4px]',
    'primary': 'stroke-[#29141F]',
    'error': 'stroke-[#FD565B]',
    'success': 'stroke-[#039755]'
  }
  iconClass: string;
  isListOfProject: boolean;

  constructor(
    private navCtrl: NavController,
    private localize: LocalizeRouterService,
    private router: Router,
    private badgeService: BadgeService,
    private modalService: ModalService,
    private amplitudeService: AmplitudeService,
    private chdRef: ChangeDetectorRef
    ) { }

  ngOnInit() {
    this.isListOfProject = true;
    const stl = `${this.props.color}-${this.props.theme}`;
    this.contentClass = `${this.contentStyle['base']} ${this.contentStyle[stl]}`;
    this.titleClass = `${this.titleStyle['base']} ${this.titleStyle[stl]}`;
    this.iconClass = `${this.iconStyle['base']} ${this.iconStyle[this.props.color]}`;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.url.includes('/es/listado-de-proyectos') || val.url.includes('/en/list-of-projects') || val.url.includes('/ca/llistat-de-projectes')) {
          this.isListOfProject = true;
          this.chdRef.detectChanges();
        } else {
          this.isListOfProject = false;
          this.chdRef.detectChanges();
        }
      }
    });
    this.chdRef.detectChanges();
  }

  componentClicked() {
    if (this.props.communicationsModal) {
      this.modalService.showModal(AskForCommunicationsComponent, {profile: this.props.profile}, '').subscribe((modal) => {
        modal.getChildComponent().subscribe((componentRef: AskForCommunicationsComponent) => {
          componentRef.removeBadgeEvent.subscribe((res) => {
            this.badgeService.removeBadge();
          });
        });
      });
      this.amplitudeService.trackEvent('badge_notif_clicked', {});
    } else {
      this.goTo();
      this.amplitudeService.trackEvent('badge_project_clicked', {});
    }
    this.chdRef.detectChanges();
  }

  goTo() {
    const navigationExtras: NavigationExtras = {
      state: {
        openProj: this.props.project,
        slugProject: this.props.project.slug
      },
    };
    if (this.isListOfProject) {
      this.badgeService.openCurrentProject(this.props.project);
    }
    this.navCtrl.navigateRoot([this.localize.translateRoute(this.props.url)], navigationExtras);
    this.chdRef.detectChanges();
  }

}
