<div>
  <p *ngIf="calendarBenefits && calendarBenefits.length > 0" class="font-sans text-h600 text-eggplant-100/[0.25]">{{'PAYMENT_CALENDAR.NEXT_12' | translate}}</p>
  <div *ngIf="calendarBenefits && calendarBenefits.length > 0" class="mt-[40px] border border-eggplant-100/[0.1] rounded-3xl px-16">
    <div *ngFor="let benefit of calendarBenefits" >
      <div *ngFor="let movement of benefit.benefits"
      class="flex justify-between py-24 border-b border-b-eggplant-100/[0.1]">
        <div>
          <p class="font-sans text-s text-eggplant-100/[0.4]">{{ movement.month }}</p>
          <p class="font-sans text-eggplant-100 text-2xl">{{'PAYMENT_CALENDAR.BENEFITS_COMMON_TYPE' | translate}}</p>
        </div>
        <div>
          <p class="text-right font-sans text-eggplant-70 text-2xl">{{movement.amount | currency: 'EUR': 'symbol': '1.2-2'}}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!calendarBenefits || calendarBenefits.length === 0">
    <fun-overal-message [text]="text" [image]="image" ></fun-overal-message>
  </div>
</div>