<div class="general-container register flex flex-col" [ngClass]="{'mob-general': isMobileApp === 'mob'}">
    <div class="body-content">
        <ng-container *ngFor="let stp of stepList">
            <!--Advantages step-->
            <div  *ngIf="step === stp.index && !loading && stp.name === 'advantages'" class="step investor">
                <div class="span-container">
                    <span id="page-title">Acreditación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2>{{ 'register.accredited_investor.become_accredited' | translate }}</h2>
                    <p class="subtitle">{{ 'register.accredited_investor.advantages' | translate }}</p>
                </div>
                <fun-register-person-last-step-advantages [options]="accreditedOptions"></fun-register-person-last-step-advantages>
            </div>
            <!--Code step-->
            <div  *ngIf="step === stp.index && !loading && stp.name === 'checksStep'" class="step check">
                <div class="span-container">
                    <span id="page-title">Requisitos de acreditación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2>{{ 'register.accredited_investor.become_accredited_2' | translate }}</h2>
                    <div class="check-steps check-steps-first">
                        <p>{{ 'register.accredited_investor.become_description' | translate }}</p>
                    </div>
                </div>
                <fun-register-person-last-step-checks (checkListEvent)="controlChecks($event)"></fun-register-person-last-step-checks>
            </div>
            <!--Code step-->
            <div *ngIf="step === stp.index && !loading && stp.name === 'documentStep'" class="step files">
                <div class="span-container">
                    <span id="page-title">Documentos de Acreditación</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2 *ngIf="checkStatus === 0">{{ 'register.accredited_investor.tax' | translate }}</h2>
                    <p *ngIf="checkStatus === 0" class="subtitle">{{ 'register.accredited_investor.give_us_tax' | translate }}</p>

                    <h2 *ngIf="checkStatus === 1">{{ 'register.accredited_investor.bank_p' | translate }}</h2>
                    <p *ngIf="checkStatus === 1" class="subtitle">{{ 'register.accredited_investor.give_us_bank_p' | translate }}</p>

                    <h2 *ngIf="checkStatus === 2">{{ 'register.accredited_investor.advice' | translate }}</h2>
                    <p *ngIf="checkStatus === 2" class="subtitle">{{ 'register.accredited_investor.give_us_advice' | translate }}</p>
                </div>
                <fun-register-person-last-step-document [userName]="username" (getFileEvent)="getBankDocument($event)" (errorFileEvent)="errorFile()"></fun-register-person-last-step-document>
            </div>
            <!--Code step-->
            <div *ngIf="step === stp.index && !loading && stp.name === 'lwTermsStep'" class="step check">
                <div class="span-container">
                    <span id="page-title">Términos y condiciones</span>
                </div>
                <div class="header" [ngClass]="{'mobApp': isMobileApp === 'mob'}">
                    <h2>{{ 'register.lw_conditions.title' | translate }}</h2>
                    <div class="check-steps">
                        <p>{{ 'register.lw_conditions.text' | translate }}</p>
                        <p>{{ 'register.lw_conditions.DESC_PAYMENT_ENTITY_1' | translate }}</p>
                        <p>{{ 'register.lw_conditions.DESC_PAYMENT_ENTITY_2' | translate }}</p>
                        <p>{{ 'register.lw_conditions.DESC_PAYMENT_ENTITY_3' | translate }}</p>
                        <p>{{ 'register.lw_conditions.DESC_PAYMENT_ENTITY_4' | translate }}</p>
                    </div>
                </div>
                <fun-register-person-last-step-end (checkedEvent)="checkedLastStep($event)"></fun-register-person-last-step-end>
            </div>
            <!--Code step-->
            <div *ngIf="step === stp.index && !loading && stp.name === 'signStep'">
                <fun-register-ifame [isMobileApp]="isMobileApp" [url]="signaturitUrl" #registerIfameComponent (initEvent)="getSignaturitUrl()" (signEvent)="next(); signed();" (signErrorEvent)="next()" (signaturitIssueEvent)="next()"></fun-register-ifame>
            </div>
            <!--Code step-->
            <div *ngIf="step === stp.index && !loading && stp.name === 'finishStep'">
                <fun-register-success *ngIf="isMobileApp === undefined || !isMobileApp" [onlyAccredit]="onlyAccredit"
                [userInfo]="userInfo" [profile]="profile" [acceptedTerms]="acceptLwTerms" (finishEvent)="finish()"></fun-register-success>
            </div>
            <!--Mensaje intermedio-->
            <div *ngIf="step === stp.index && !loading && stp.name === 'signMessageStep'">
                <fun-overal-message [text]="textSign" [image]="imageSign" [btnList]="btnSign"></fun-overal-message>
            </div>
            <!---->
            <fun-skeleton-list *ngIf="loading"></fun-skeleton-list>
        </ng-container>
    </div>
    <div *ngIf="step !== finishIndex && step!== signIndex && !loading" class="mobile-footer">
        <fun-new-button (eventClick)="next()" class="next-btn" [buttonType]="sendXHR" title="{{ 'register.btn.continue' | translate }}"></fun-new-button>
        <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
        (click)="finishRegister()" *ngIf="step !== lwTermsIndex" id="not_now">{{ 'register.btn.another_time' | translate }}</h4>
    </div>
    <div *ngIf="step !== finishIndex && step!== signIndex && !loading" class="desktop-footer">
        <div class="content-footer">
            <h4 class="font-sans pt-6 text-m text-eggplant-100/[0.4] hover:cursor-pointer hover:underline hover:underline-offset-6"
            (click)="finishRegister()" *ngIf="step !== lwTermsIndex" id="not_now">{{ 'register.btn.another_time' | translate }}</h4>
            <fun-new-button (eventClick)="next()" class="next-btn" [buttonType]="sendXHR"
            title="{{ 'register.btn.continue' | translate }}" [id]="personalId"></fun-new-button>
        </div>
    </div>
</div>
