import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fun-new-document',
  templateUrl: './new-document.component.html',
  styleUrls: ['./new-document.component.scss'],
})
export class NewDocumentComponent implements OnInit {

  @Output() public onClickEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  btnClick() {
    this.onClickEvent.emit();
  }

}
