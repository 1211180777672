import { Component, Input, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-overview-investors-section',
  templateUrl: './overview-investors-section.component.html',
  styleUrls: ['./overview-investors-section.component.scss'],
})
export class OverviewInvestorsSectionComponent implements OnInit {
  @Input() investorsInfo: any;
  investorsNumber: number;

  constructor() { }

  ngOnInit() {
    registerLocaleData(es);
    this.investorsNumber =
    this.investorsInfo.investorLevel.first +
    this.investorsInfo.investorLevel.fourth +
    this.investorsInfo.investorLevel.second +
    this.investorsInfo.investorLevel.third;
  }

}
