import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as momentNs from 'moment';
const moment = momentNs;

@Component({
  selector: 'fun-register-company-rldata',
  templateUrl: './register-company-rldata.component.html',
  styleUrls: ['./register-company-rldata.component.scss']
})
export class RegisterCompanyRldataComponent implements OnInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() isMobile: boolean;

  public RLDataForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  public notFullSurname = false;
  public isFocusLost = false;

  contactPerson = false;

  selectIsOpen = false;
  actAsList = [
    {name: this.translate.instant('register.legal-representative.actAsOptions.admin'), id: 1},
    {name: this.translate.instant('register.legal-representative.actAsOptions.attorney'), id: 2}
  ];

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService
  ) { }

  /* @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  } */

  @HostListener('document:keyup.enter', ['$event']) onKeyupEnterHandler(event: KeyboardEvent) {
    const pickerElement = (document.querySelectorAll('body > fun-modal > div > div.modal > div.content-modal > fundeen-register > fun-register-company > div > div.body-content > div > fun-register-company-rldata > div > div > div > div:nth-child(4) > fun-input > div > div > div > dp-date-picker > div:nth-child(1) > div > input')[0] as HTMLElement);
    if (this.RLDataForm.valid) {
      this.sendInfoEvent.emit(this.RLDataForm.value);
      this.submitEvent.emit();
    } else {
      if ((event.target as Element).id === 'nameRepresentative') {
        document.getElementById('surnameRepresentative').focus();
      } else if ((event.target as Element).id === 'surnameRepresentative') {
        pickerElement.focus();
        this.focusLost();
      } else if (event.target === pickerElement) {
        pickerElement.blur();
      }
      this.sendErrorEvent.emit();
      this.chdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.RLDataForm = new FormGroup({
      nameRepresentative: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      surnameRepresentative: new FormControl('', [Validators.required, Validators.pattern('^[A-Za-z-ñÑáéíóúÁÉÍÓÚ ]+$')]),
      birthDateRepresentative: new FormControl('', [Validators.required]),
      actAsRepresentative: new FormControl(0, [Validators.required, Validators.min(0.01)]),
    });
    this.formControlKeys = Object.keys(this.RLDataForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    this.chdRef.detectChanges();
  }

  checkContactPerson() {
    if (this.contactPerson) {
      this.RLDataForm.controls['nameRepresentative'].setValue(this.data.name);
      this.RLDataForm.controls['surnameRepresentative'].setValue(this.data.surname);
      this.RLDataForm.controls['birthDateRepresentative'].setValue(this.data.birthDate);
    } else {
      this.RLDataForm.controls['nameRepresentative'].setValue('');
      this.RLDataForm.controls['surnameRepresentative'].setValue('');
      this.RLDataForm.controls['birthDateRepresentative'].setValue('');
    }
    this.getInfo();
    this.chdRef.detectChanges();
  }

  getInfo() {
    if (this.RLDataForm.value.surnameRepresentative !== undefined && this.isFocusLost) {
      if (this.RLDataForm.value.surnameRepresentative.includes(' ')) {
        this.notFullSurname = false;
      } else {
        this.notFullSurname = true;
      }
    }
    if (this.RLDataForm.valid) {
      const model = {
        nameRepresentative: this.RLDataForm.value.nameRepresentative.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        surnameRepresentative: this.RLDataForm.value.surnameRepresentative.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()),
        birthDateRepresentative: this.RLDataForm.value.birthDateRepresentative,
        actAsRepresentative: this.RLDataForm.value.actAsRepresentative
      };
      this.sendInfoEvent.emit(model);
    } else {
      this.sendErrorEvent.emit();
    }
    this.chdRef.detectChanges();
  }

  focusLost() {
    this.isFocusLost = true;
    this.chdRef.detectChanges();
  }

}
