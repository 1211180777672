import { Component, Input, OnInit } from '@angular/core';
import * as momentNs from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

const moment = momentNs;

@Component({
  selector: 'app-invest-popup-header',
  templateUrl: './invest-popup-header.component.html',
  styleUrls: ['./invest-popup-header.component.scss'],
})
export class InvestPopupHeaderComponent implements OnInit {

  @Input() data: any;
  percentInvested: any;
  daysLeft: any;
  hasPrevInvest: boolean;
  moneyInvested: any;
  moneyExpected: any;
  percentInv: any;
  leftInv: any;

  percentInvestedBeforeIncrease: any;
  progresBarPercent: any;

  constructor() { }

  ngOnInit() {
    if (this.data.invested && this.data.invested !== undefined) {
      this.hasPrevInvest = true;
      this.getPrevInvest(this.data.invested);
    } else {
      this.hasPrevInvest = false;
    }
    registerLocaleData(es);
    if (this.data.status === 14) {
      this.percentInvestedBeforeIncrease = (this.data.contributed * 100) / (this.data.objective - this.data.capital_increase_amount);
      this.progresBarPercent = ((this.data.objective - this.data.capital_increase_amount) / this.data.objective) * 100;
    } else {
      this.progresBarPercent = this.percentInvested;
    }
    this.percentInvested = (this.data.contributed / this.data.objective) * 100;
    const endDateN = new Date(this.convertToIos(moment(this.data.end_date).format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    const todayN = new Date(this.convertToIos(moment().format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    this.daysLeft = (endDateN - todayN) / (1000 * 60 * 60 * 24);
  }
  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] -1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }
  getPrevInvest(prev) {
    const invested = [];
    const moneyExpected = [];
    prev.forEach(element => {
      invested.push(element.invested);
      moneyExpected.push(element.totalRevenue);
    });
    this.moneyInvested = invested.reduce((a, b) => a + b, 0);
    this.moneyExpected = moneyExpected.reduce((a, b) => a + b, 0);
    this.percentInv = (this.moneyInvested / this.data.objective) * 100 + '%';
    this.leftInv = ((((this.data.contributed / this.data.objective) * 100)) - ((this.moneyInvested / this.data.objective) * 100)) + '%';
  }
}
