<div class="register">
    <div class="body">
        <div class="date-container">
            <div class="inp">
                <fun-input
                    *ngIf="countryListCharged"
                    [placeholder]="nationName"
                    [parentForm]="selectForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="nation"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.nationality.nationality' | translate }}"
                    (selectChange)="countrySelected()"
                    (keyup)="countrySelected()"
                    (click)="countrySelected()"
                    [items]="nationList"
                    [selectedItem]="selectForm.value.nation"
                    [disabled]="disabled"
                ></fun-input>
                <fun-skeleton-input *ngIf="!countryListCharged"></fun-skeleton-input>
            </div>
            <div class="inp">
                <fun-input
                *ngIf="countryListCharged"
                    [placeholder]="countryName"
                    [parentForm]="selectForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="residence"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.nationality.residence' | translate }}"
                    (selectChange)="countrySelected()"
                    (keyup)="countrySelected()"
                    (click)="countrySelected()"
                    [items]="nationList"
                    [selectedItem]="selectForm.value.residence"
                    [disabled]="disabled"
                ></fun-input>
                <fun-skeleton-input *ngIf="!countryListCharged"></fun-skeleton-input>
            </div>
        </div>
    </div>
</div>
