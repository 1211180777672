<div class="w-full appear" *ngIf="recordList && recordList.length > 0">
  <div class="flex flex-col gap-20 md:gap-32" *ngIf="!recordCurrent && !hasVoteDelegate && (this.indexOpened < recordList.length) && (meeting.attributes.status === 'en celebracion')">
    <div class="flex flex-col w-full pt-0 pb-12 gap-12 justify-between">
      <div>
        <header class="flex flex-row items-start gap-12 p-12 md:pb-0">
          <div class="flex flex-col justify-center items-center content-center w-full">
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4] text-center">{{ 'MEETING.WAIT_TO_OPEN' | translate }}</p>
          </div>
        </header>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-20 md:gap-32" *ngIf="recordCurrent && !hasVoteDelegate">
    <div class="flex flex-col w-full pt-0 pb-12 gap-12 justify-between">
      <div>
        <header class="flex flex-row items-start gap-12 p-12 md:pb-0">
          <div class="flex flex-col justify-center items-center content-center w-full">
            <h3 class="font-sans text-2xl text-eggplant-100 text-center">{{ recordCurrent.attributes.title }}</h3>
            <p class="font-sans text-m md:text-l text-eggplant-100/[0.4] text-center">{{ recordCurrent.attributes.subtitle }}</p>
            <p *ngIf="recordCurrent.attributes.informative" class="font-sans text-m md:text-l text-verified text-center">{{ 'MEETING.INFORMATIVE_POINT' | translate }}</p>
            <div class="h-[28px]"><p class="font-sans text-m text-orange-100 text-center">{{ countDownMst }}</p></div>
          </div>
        </header>
      </div>
      <div *ngIf="user.role !== 'public' && recordCurrent.attributes.informative !== true" class="flex flex-col gap-y-12 justify-center items-center content-center py-24">
        <div class="flex flex-col md:flex-row w-full md:w-2/3 gap-x-24 gap-y-16" >
          <div class="basis-1/3">
            <fun-new-button *ngIf="!disabledVote"
          [buttonType]="recordCurrent.attributes.userVote && recordCurrent.attributes.userVote === 'yes' ? 'colorFull-disabled': 'colorFull'"
          [size]="'l'" title="{{ 'MEETING.IN_FAVOR' | translate }}" (eventClick)="voteForEachRecord(recordCurrent, 'yes')" [classExtra]="'!w-full'"></fun-new-button>
          <ion-skeleton-text *ngIf="disabledVote" class="w-full h-[45.2px]" animated></ion-skeleton-text>
          </div>
          <div class="basis-1/3">
            <fun-new-button *ngIf="!disabledVote"
          [buttonType]="recordCurrent.attributes.userVote && recordCurrent.attributes.userVote === 'no' ? 'secondaryDark-disabled': 'secondaryDark'"
          [size]="'l'" title="{{ 'MEETING.AGAINST' | translate }}" (eventClick)="voteForEachRecord(recordCurrent, 'no')" [classExtra]="'!w-full'"></fun-new-button>
            <ion-skeleton-text *ngIf="disabledVote" class="w-full h-[45.2px]" animated></ion-skeleton-text>
          </div>
          <div class="basis-1/3">
            <fun-new-button *ngIf="!disabledVote"
            [buttonType]="recordCurrent.attributes.userVote && recordCurrent.attributes.userVote === 'abstention' ? 'ghost-disabled': 'ghost'"
            [size]="'l'" title="{{ 'MEETING.ABSTENTION' | translate }}" (eventClick)="voteForEachRecord(recordCurrent, 'abstention')" [classExtra]="'!w-full'"></fun-new-button>
            <ion-skeleton-text *ngIf="disabledVote" class="w-full h-[45.2px]" animated></ion-skeleton-text>
          </div>
        </div>
      </div>
      <footer *ngIf="((recordCurrent.attributes.userVote && (recordCurrent.attributes.userVote !== 'empty') && (recordCurrent.attributes.userVote !== 'no ha votado'))) || recordCurrent.attributes.informative" class="flex flex-col justify-center items-center content-center gap-32">
        <div *ngIf="meeting.attributes.status === 'abierta a voto anticipado'" class="flex flex-col w-full justify-center items-center content-center">
          <div class="flex flex-col md:flex-row w-full md:w-2/3 gap-x-24 gap-y-16 justify-center items-center content-center" >
            <div class="basis-1/3">
              <fun-new-button [buttonType]="'primaryDark'" [size]="'l'" title="{{ 'btn.next' | translate }}" (eventClick)="nextRecord()" [classExtra]="'!w-full'"></fun-new-button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <h4 class="text-eggplant-100 font-sans text-h400 md:text-h500 text-center py-16 md:py-32">{{ 'MEETING.ORDER_OF_THE_DAY' | translate }}</h4>
  <div class="flex flex-col" *ngIf="recordList">
    <div *ngFor="let record of recordList; let i = index" class="flex flex-col w-full pt-0 pb-12 justify-between relative">
      <div *ngIf="(i < this.indexOpened) && (i < (recordList.length - 1))" class="absolute bg-orange-100 w-[3px] h-full top-[4px] left-[14px] -z-[1]"></div>
      <div *ngIf="(i >= this.indexOpened) && (i < (recordList.length - 1))" class="absolute bg-gray-10 w-[3px] h-full top-[4px] left-[14px] -z-[1]"></div>
      <div>
        <header class="flex flex-row items-start gap-12">
          <div class="flex items-center">
            <span class="flex justify-center items-center content-center w-32 h-32 rounded-full border-4 border-white" [ngClass]="record.attributes.status === 'cerrado' || (i <= this.indexOpened && recordCurrent) ? 'bg-cream-100' : 'bg-gray-10'">
              <fun-icon *ngIf="record.attributes.status === 'cerrado' || (i < this.indexOpened)" [name]="'check-style'" [style]="'w-20 h-20 fill-orange-100'"></fun-icon>
              <span *ngIf="record.attributes.status !== 'cerrado' && (i === this.indexOpened && recordCurrent) && (this.indexOpened < recordList.length)" class="flex justify-center items-center content-center w-8 h-8 rounded-full bg-orange-100"></span>
              <span *ngIf="record.attributes.status !== 'cerrado' && (i > this.indexOpened || !recordCurrent) && (this.indexOpened < recordList.length)" class="flex justify-center items-center content-center w-8 h-8 rounded-full bg-gray-40"></span>
            </span>
          </div>
          <div class="flex flex-col pt-2">
            <h4 class="flex flex-row gap-x-12 font-sans text-l" [ngClass]="(i === this.indexOpened && recordCurrent) ? 'text-orange-100' : 'text-eggplant-100'">
              {{ record.attributes.title }}
              <span *ngIf="(record.attributes.userVote && record.attributes.userVote === 'yes' && record.attributes.informative !== true)"
               class="flex justify-center items-center content-center text-s text-white uppercase linear-gradient-2 py-2 px-8 rounded-md font-sans">{{ 'MEETING.IN_FAVOR' | translate }}</span>
              <span *ngIf="(record.attributes.userVote && record.attributes.userVote === 'no' && record.attributes.informative !== true)"
               class="flex justify-center items-center content-center text-s text-eggplant-100 uppercase border border-eggplant-10 py-2 px-8 rounded-md font-sans">{{ 'MEETING.AGAINST' | translate }}</span>
              <span *ngIf="(record.attributes.userVote && record.attributes.userVote === 'abstention' && record.attributes.informative !== true)"
               class="flex justify-center items-center content-center text-s text-eggplant-100 uppercase bg-gray-10 py-2 px-8 rounded-md font-sans">{{ 'MEETING.ABSTENTION' | translate }}</span>
              <span *ngIf="(record.attributes.userVote && record.attributes.userVote === 'empty' && record.attributes.informative !== true)"
               class="flex justify-center items-center content-center text-s text-eggplant-70 uppercase py-2 px-8 rounded-md font-sans">{{ 'MEETING.NO_VOTED_YET' | translate }}</span>
              <span *ngIf="(record.attributes.informative === true)"
               class="flex justify-center items-center content-center text-s text-eggplant-70 uppercase py-2 px-8 rounded-md font-sans">{{ 'MEETING.INFORMATIVE_POINT_TITLE' | translate }}</span>
            </h4>
            <p *ngIf="(i >= this.indexOpened)" class="font-sans text-m" [ngClass]="(i === this.indexOpened && recordCurrent) ? 'text-orange-100/[0.8]' : 'text-eggplant-100/[0.4]'">{{ record.attributes.subtitle }}</p>
          </div>
        </header>
      </div>
    </div>
  </div>
</div>