<div class="register">
    <div class="body">
        <div class="RLDocument-container">
            <div class="inp">
                <fun-input
                *ngIf="countryListCharged"
                    placeholder="{{'register.nationality.not_specified' | translate}}"
                    [parentForm]="RLDocForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="nationalityRepresentative"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.nationality.nationality' | translate}}"
                    (selectChange)="getInfo()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="nationList"
                    [selectedItem]="RLDocForm.value.nationalityRepresentative"
                ></fun-input>
                <fun-skeleton-input *ngIf="!countryListCharged"></fun-skeleton-input>
            </div>
            <div class="inp">
                <fun-input
                *ngIf="countryListCharged"
                    placeholder="{{'register.nationality.not_specified' | translate}}"
                    [parentForm]="RLDocForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="countryRepresentative"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.nationality.residence' | translate}}"
                    (selectChange)="getInfo()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="nationList"
                    [selectedItem]="RLDocForm.value.countryRepresentative"
                ></fun-input>
                <fun-skeleton-input *ngIf="!countryListCharged"></fun-skeleton-input>
            </div>
        </div>
    </div>
</div>

