<div class="md:mx-16 md:mx-0 pt-32 md:pt-[160px] pb-24" >
  <div *ngIf="platform === 'web'" class="md:grid grid-cols-4 md:grid-cols-10 md:gap-x-32 lg:gap-x-32 place-content-center items-center relative py-24 md:py-[53px] border-y border-y-eggplant-100/[0.4]">
    <div class="col-span-1">
      <img class="w-80 h-80 md:w-96 md:h-96" src="../../../../assets/images/img/isotipoDark.svg" alt="">
    </div>
    <div class="col-span-4 col-start-2">
      <p class="font-sansMedium text-[26px] md:text-3xl text-eggplant-100 leading-[28.6px] md:leading-9">{{ 'FOOTER.TITLE' | translate }}</p>
    </div>
  </div>
  <div class="flex flex-col-reverse md:grid grid-cols-4 md:grid-cols-10 gap-y-24 md:gap-x-32 lg:gap-x-32 place-content-center relative pt-32">
    <div class="col-span-6 md:block pb-12 md:pb-0" >
      <p class="text-s font-sans text-eggplant-100 text-center md:text-left">
        © 2023 Fundeen<span class="hidden md:inline-block lg:inline-block xl:inline-block">. {{ 'FOOTER.COPY' | translate }} • {{ 'FOOTER.MADE_IN_AV' | translate }}</span>
      </p>
      <div class="flex flex-col md:flex-row justify-start">
        <div class="w-full md:w-auto children:text-s children:font-sans children:text-eggplant-100 text-center">
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('terminos-y-condiciones')">{{ 'FOOTER.TERMS' | translate }}</span>
          <span class="inline-block px-8">•</span>
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('privacidad')">{{ 'FOOTER.PRIVACITY' | translate }}</span>
          <span class="hidden md:inline-block lg:inline-block xl:inline-block px-8">•</span>
        </div>
        <div class="w-full md:w-auto children:text-s children:font-sans children:text-eggplant-100  text-center">
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('cookies')">Cookies</span>
          <span class="inline-block px-8">•</span>
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('informacion-inversor')">{{ 'FOOTER.INFO' | translate }}</span>
          <span class="inline-block px-8">•</span>
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('referidos')">{{ 'FOOTER.REFERRAL' | translate }}</span>
        </div>
        <!-- <div class="w-full md:w-auto children:text-s children:font-sans children:text-eggplant-100  text-center">
          <span class="inline-block px-8">•</span>
          <span class="cursor-pointer hover:decoration-eggplant-100 hover:underline" (click)="openLegal('referidos')">{{ 'FOOTER.REFERRAL' | translate }}</span>
        </div> -->
      </div>
    </div>
    <div class="col-span-2 col-start-9">
      <p class="text-center md:text-right font-sans text-l transition-all">
        <span *ngFor="let ln of lngList" (click)="changeLn(ln.ln)" class="pr-8 cursor-pointer"
        [ngClass]="{'text-eggplant-100 decoration-eggplant-100 underline underline-offset-100 hover:text-orange-120
        hover:cursor-pointer hover:decoration-orange-100': !ln.selected,
        'text-orange-120 hover:cursor-pointer': ln.selected }">{{ ln.text }}</span>
      </p>
    </div>
  </div>
</div>
