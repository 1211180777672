<div class="register">
    <div class="body">
        <div class="mail-container">
            <div class="inp pb-12">
                <fun-input
                    placeholder="nombre@tucorreo.es"
                    [parentForm]="emailForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="email"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="E-mail"
                    [value]="data.email"
                    (valueChanged)="getInfo()"
                ></fun-input>
            </div>
            <div class="inp pb-12">
                <fun-input
                    placeholder="***********"
                    [parentForm]="emailForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="pwd"
                    type="password"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.email.password' | translate }}"
                    [value]="data.pwd"
                    (valueChanged)="getInfo()"
                ></fun-input>
            </div>
            <div class="inp pb-12">
                <label class="ref_title font-sans" (click)="toggleRefCode()">{{ 'register.email.ref_code' | translate }}<span *ngIf="!openPromoCodeInp">+</span> <span *ngIf="openPromoCodeInp">-</span></label>
                <fun-input
                *ngIf="openPromoCodeInp"
                labelName="{{ 'register.email.ref_code_msg' | translate }}"
                [ngClass]="{'none': !openPromoCodeInp}"
                placeholder=" FUNDMONB96F"
                [parentForm]="emailForm"
                [formControlKeys]="formControlKeys"
                [errorMessages]="errorMessages"
                [isSubmit]="isSubmit"
                controlName="refCode"
                type="text"
                [errorLogin]="errorAmount"
                (valueChanged)="getInfo()"
                (keyup)="checkRefCode()"
                (pasate)="checkRefCode()"
                ></fun-input>
            </div>
            <p class="refName" *ngIf="refName && refName !== undefined">{{ 'REGISTER.REF_NAME' | translate }} {{ refName }}.</p>
        </div>
        <article class="pb-12">
            <div class="form-input">
                <div class="check-container">
                    <div class="check" [ngClass]="{'checked': acceptConditions }" (click)="onChangeAcceptPriv()"></div>
                </div>
                <p>{{ 'REGISTER.ACCEPT_THE' | translate }}<span class="view_more lowercase" (click)="viewMore('terms')">{{ 'REGISTER.MY_PROFILE.TERMS_AND_CONDITIONS' | translate }}</span>
                {{ 'REGISTER.AND_THE' | translate }} <span class="view_more" (click)="viewMore('privacity')">{{ 'REGISTER.POLICY' | translate }}.</span></p>
            </div>
        </article>
        <article>
            <div class="form-input">
                <div class="check-container">
                    <div class="check" [ngClass]="{'checked': acceptOffers }"  (click)="onChangeAcceptOffers()"></div>
                </div>
                <p>{{ 'register.email.news' | translate }}</p>
            </div>
        </article>
    </div>
</div>