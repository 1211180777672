import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fun-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @Input() data;
  @Output() private clickBtnEvent = new EventEmitter<any>();

  txt: string;
  wantBtn: boolean;
  btnTitle: string;

  constructor() { }

  ngOnInit() {
    this.txt = this.data.txt;
    this.wantBtn = (this.data.wantBtn !== undefined) ? this.data.wantBtn : true;
    this.btnTitle = (this.data.btnTitle !== undefined) ? this.data.btnTitle : 'Continuar';
  }

  clickBtn() {
    this.clickBtnEvent.emit();
  }
}
