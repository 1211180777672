import { Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'fun-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss']
})
export class SkeletonListComponent {


}
