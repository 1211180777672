import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fun-overview-meeting-modal',
  templateUrl: './overview-meeting-modal.component.html',
  styleUrls: ['./overview-meeting-modal.component.scss'],
})
export class OverviewMeetingModalComponent implements OnInit {

  @Input() public meeting;
  @Input() public project;
  @Output() public goBackEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  goBack() {
    this.goBackEvent.emit();
  }
}
