import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fun-register-person-last-step-advantages',
  templateUrl: './register-person-last-step-advantages.component.html',
  styleUrls: ['./register-person-last-step-advantages.component.scss']
})
export class RegisterPersonLastStepAdvantagesComponent implements OnInit {

  @Input() options: any;

  constructor() { }

  ngOnInit() {
  }

}
