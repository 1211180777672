import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-register-file-carrusel',
  templateUrl: './register-file-carrusel.component.html',
  styleUrls: ['./register-file-carrusel.component.scss'],
})
export class RegisterFileCarruselComponent implements OnInit {

  slideOpts: any;

  imageList = [
    {text: this.translate.instant('register.recomendation_1'), url: '../../../../../assets/images/register/dni_example_1.webp'},
    {text: this.translate.instant('register.recomendation_2'), url: '../../../../../assets/images/register/dni_example_2.webp'},
    {text: this.translate.instant('register.recomendation_3'), url: '../../../../../assets/images/register/dni_example_3.webp'},
    {text: this.translate.instant('register.recomendation_4'), url: '../../../../../assets/images/register/dni_example_4.webp'},
    {text: this.translate.instant('register.recomendation_5'), url: '../../../../../assets/images/register/dni_example_5.webp'},
    {text: this.translate.instant('register.recomendation_6'), url: '../../../../../assets/images/register/dni_example_6.webp'},
    {text: this.translate.instant('register.recomendation_7'), url: '../../../../../assets/images/register/dni_example_7.webp'},
  ];

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    this.slideOpts = {
      initialSlide: 0,
      speed: 5000,
      effect: 'slide',
      slidesPerView: 4,
      //slidesPerGroup: 2,
      spaceBetween: 16,
      grabCursor: false,
      freeMode: false,
      loop: true,
      autoplay: {
        delay: 0, // El tiempo en milisegundos entre cada cambio de diapositiva
        disableOnInteraction: false, // Permite que el autoplay continúe incluso cuando el usuario interactúa con el slider
      },
      breakpoints: {
        '774': {
          slidesPerView: 1
        },
        '995': {
          slidesPerView: 4
        },
      }
    }
  }

}
