<div class="general-container mb-48 appear">
  <div class="flex flex-col gap-20 md:gap-32" *ngIf="page === 'list' && !loading">
    <div *ngIf="!data || data.length === 0">
      <fun-overal-message [text]="text" [image]="image" ></fun-overal-message>
    </div>
    <div *ngFor="let meeting of data" class="rounded-2xl border border-eggplant-100/[0.1] bg-white">
      <div class="flex flex-col w-full pt-0 pb-32 px-16 gap-24 justify-between">
        <div>
          <header class="flex flex-col-reverse md:flex-row items-start place-content-between gap-12 p-12">
            <div class="flex flex-col">
              <h2 class="font-sans text-2xl text-eggplant-100 flex">{{ meeting.title }}</h2>
              <p class="font-sans text-m md:text-l text-orange-120">{{ meeting.date | date:'dd/MM/yyyy - hh:mm' }}h</p>
            </div>
            <div class="flex items-center place-self-end md:place-self-start gap-x-8 py-2 px-[8px] my-[8px]">
              <span class="w-16 h-16 rounded-full" [ngClass]="{ 'bg-verified': meeting.status === 'en celebracion', 'bg-active': meeting.status === 'abierta a voto anticipado', 'bg-alert': (meeting.status === 'cerrada') || (meeting.status === 'redactando acta') }"></span>
              <span class="text-m uppercase font-sans text-eggplant-100/[0.4]" >{{ meeting.status }}</span>
            </div>
          </header>
          <div class="py-24 px-12 border-t border-t-eggplant-100/[0.1]" *ngIf="meeting.documents && meeting.documents.length > 0">
            <div class="w-full flex flex-col md:flex-row items-center gap-24 overflow-auto">
              <p class="font-sans text-l text-eggplant-70 uppercase">{{ 'DOCUMENTATION_PAGE.TITLE' | translate }}:</p>
              <div class="flex flex-col md:flex-row gap-x-[32px] gap-y-8" >
                <div *ngFor="let document of meeting.documents">
                  <fun-new-button [buttonType]="'ghost'" [size]="'m'" [title]="document.title"  [icon]="btnIcon" [iconPosition]="'left'" (eventClick)="downloadDocs(document)" ></fun-new-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center content-center">
          <div class="flex flex-row justify-center items-center content-center w-2/3 gap-x-[24px]" >
            <div class="basis-1/3">
              <fun-new-button *ngIf="meeting.status !== 'oculta'"
              [buttonType]="meeting.status === 'en celebracion' ? 'colorFull' : meeting.status === 'redactando acta' ? 'primaryDark-disabled' : 'secondaryDark' " [size]="'l'"
              [title]="meeting.status === 'en celebracion' ? 'unirme a la junta' : meeting.status === 'abierta a voto anticipado' ? 'acceder al voto anticipado' : meeting.status === 'redactando acta' ? 'Redactando Acta' : 'ver junta celebrada' "
              (eventClick)="viewMeeting(meeting)" [classExtra]="'!w-full'"></fun-new-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="page === 'meeting' && !loading">
    <fun-overvew-meeting-direct [meeting]="currentMeeting" [project]="project" (goBackEvent)="page = 'list'"></fun-overvew-meeting-direct>
  </div>
  <div *ngIf="loading"><fun-spinner></fun-spinner></div>
</div>: