<div class="pt-48">
  <div class="flex px-24">
    <div>
      <svg width="34" height="42" viewBox="0 0 34 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 1H5C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5V37C1 38.0609 1.42143 39.0783 2.17157 39.8284C2.92172 40.5786 3.93913 41 5 41H29C30.0609 41 31.0783 40.5786 31.8284 39.8284C32.5786 39.0783 33 38.0609 33 37V13M21 1L33 13M21 1V13H33M25 23H9M25 31H9M13 15H9" stroke="url(#paint0_linear_242_4451)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_242_4451" x1="1" y1="41" x2="40.0244" y2="9.78049" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFD43C"/>
        <stop offset="0.53125" stop-color="#FF679B"/>
        <stop offset="1" stop-color="#FF5C2F"/>
        </linearGradient>
        </defs>
        </svg>
    </div>
    <div class="pl-24">
      <p class="font-sans text-l text-eggplant-100/[0.4] pb-8">{{ 'OVERVIEW.DOCS_TXT' | translate }}</p>
    </div>
  </div>
  <div class="block m-auto w-fit pb-24">
    <fun-new-button title="{{ 'OVERVIEW.DOCS_CTA' | translate }}" [icon]="'download'" [size]="'s'" [buttonType]="'ghost'" [iconPosition]="'right'" (eventClick)="btnClick()"></fun-new-button>
  </div>
</div>