<div class="help-container">
    <div class="title">
        <h2>{{ 'help.title' | translate }}</h2>
    </div>
    <div class="questions">
        <ng-container *ngFor="let question of questions">
            <div class="help-header">
                <p class="help-title" (click)="emitQuestion(question.index)">
                    {{ question.text }}
                    <img class="open-arrow" src="../../../../../assets/images/img/up_blue.svg" alt="" [ngClass]="{'opened': question.opened, 'closed': !question.opened}">
                </p>
            </div>
            <div [id]="'element' + question.index" class="help-content" [ngClass]="{'content-opened': question.opened, 'content-closed': !question.opened}">
                <p *ngFor="let text of question.content">{{ text }}</p>
            </div>
        </ng-container>
    </div>
</div>
