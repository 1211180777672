import { Injectable } from '@angular/core';
import { MarketPlaceMyOffersModel, StatesOffer } from '../shared/models/marketplace.models';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {

  currentState: StatesOffer;

  constructor() { }

  getStateOfferStatus(offer: MarketPlaceMyOffersModel, hasActive: boolean) {
    if (offer.status === 'active' && (offer.current_bids === 0 || !hasActive)) {
      this.currentState = 'activeActive';
    }
    if (offer.status === 'not_sold') {
      this.currentState = 'notSold';
      if (offer.current_bids === 0) {
        this.currentState = 'notSoldNoBids';
      }
    }
    if (offer.status === 'pending_acceptation' ||
    (offer.status === 'active' && offer.current_bids !== 0 && hasActive)) {
      this.currentState = 'activePendingAcceptation';
    }
    if (offer.status === 'sold' && offer.sold_status === 'internal_waiting_sign') {
      if (offer.signed_buyer === false) {
        this.currentState = 'internalSoldWaitingBuyerSign';
      } else {
        this.currentState = 'internalSoldWaitingBuyerSign';
      }
    }
    if (offer.status === 'not_sold' && offer.sold_status === 'internal_waiting_sign') {
      this.currentState = 'internalNotSoldWaitingSignKo';
    }
    if (offer.status === 'sold' && offer.sold_status === 'internal_waiting_payment') {
      this.currentState = 'internalSoldWaitingPayment';
    }
    if (offer.status === 'sold' && offer.sold_status === 'external_waiting_contracts' && offer.contract_seller === false) {
      this.currentState = 'externalSoldWaitingContractsContact';
    }
    if (offer.status === 'not_sold' && offer.sold_status === 'external_waiting_contracts') {
      this.currentState = 'externalSoldWaitingContractsKo';
    }
    if (offer.status === 'sold' && (offer.sold_status === 'external_validating' || offer.contract_seller === true)) {
      this.currentState = 'externalSoldValidating';
    }
    if (offer.formalization_type === 'internal' && offer.status === 'sold' && offer.sold_status === 'ok') {
      this.currentState = 'internalSoldOk';
    }
    if (offer.formalization_type === 'internal' && offer.status === 'sold' && offer.sold_status === 'ko') {
      this.currentState = 'internalSoldKo';
    }
    if (offer.formalization_type === 'external' && offer.status === 'sold' && offer.sold_status === 'ok') {
      this.currentState = 'externalSoldOk';
    }
    if (offer.formalization_type === 'external' && offer.status === 'sold' && offer.sold_status === 'ko') {
      this.currentState = 'externalSoldKo';
    }
    return this.currentState;
  }
}
