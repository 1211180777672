import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'fun-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.scss']
})
export class RegisterSuccessComponent implements OnInit {

  @Output() public finishEvent = new EventEmitter<any>();
  @Input() isMobileApp: any;
  @Input() userInfo: any;
  @Input() profile: any;
  @Input() acceptedTerms: any;
  @Input() onlyAccredit: any;
  @Input() mpSuccess: boolean;

  step = 0;
  btnList: any;
  image: number;
  text: string;
  status: number;
  idG: string;

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private amplitudeService: AmplitudeService
    ) { }

  ngOnInit() {
    this.controlMessage();
    this.chdRef.detectChanges();
  }
  controlMessage() {
    this.image = 6;
    let title: string;
    let text: string;

    let email: any;
    let document: any;

    if (
      this.profile !== undefined
      && this.profile.username !== undefined
      && this.profile.username !== null
      && this.profile.username !== '') {
      email = this.profile.username;
    } else {
      email = this.userInfo.info.email;
    }

    if (
      this.profile !== undefined
      && this.profile.identityDocumentFront !== undefined
      && this.profile.identityDocumentFront !== null
      && this.profile.identityDocumentFront !== '') {
      document = this.profile.identityDocumentFront;
    } else {
      document = this.userInfo.document;
    }
    if (this.onlyAccredit) {
      title = this.translate.instant('register.finish.step_accredit.title');
      text = this.translate.instant('register.finish.step_accredit.txt');
      this.text = title + text;
    } else if (this.mpSuccess) {
      title = this.translate.instant('register.finish.mp_ok.title');
      text = this.translate.instant('register.finish.mp_ok.txt');
      this.text = title + ' <p>' + text + '</p> ';
    } else if (document !== undefined && this.acceptedTerms) {
      this.status = 1;
      if (this.profile !== undefined && this.profile.walletstatus === 0) {
        this.idG = 'full_profile';
        title = this.translate.instant('register.finish.step_0.title');
        text = this.translate.instant('register.finish.step_0.txt');
      } else if (this.profile !== undefined && this.profile.walletstatus === -1) {
        let addStep = false;
        this.profile.files.forEach(element => {
          if ((element.field === 'ID_CARD_FRONT' || element.field === 'ID_CARD_BACK') && element.mpStatus !== null && (element.mpStatus > 3 || element.lwStatus > 2)) {
            addStep = true;
          }
        });
        if (addStep) {
          this.idG = 'incomplete_profile';
          title = '<h2>Perfil incorrecto.</h2>';
          text = this.translate.instant('MESSAGES.BAD_PROFILE_2');
          this.text = title + ' <p>' + text + '</p> ';
          this.image = 4;
        } else {
          this.idG = 'incomplete_profile';
          title = this.translate.instant('register.finish.step_2.title');
          text = this.translate.instant('register.finish.step_2.txt');
          this.text = title + ' <p>' + text + '</p> ';
        }
      } else {
        this.idG = 'full_profile';
        title = this.translate.instant('register.finish.step_1.title');
        text = this.translate.instant('register.finish.step_1.txt');
        this.amplitudeService.trackEvent('register_wallet generated', {});
      }
      this.text = title + ' <p>' + text + '</p> ';
    } else if (email !== undefined) {
      this.status = 0;
      this.idG = 'incomplete_profile';
      title = this.translate.instant('register.finish.step_2.title');
      text = this.translate.instant('register.finish.step_2.txt');
      this.text = title + ' <p>' + text + '</p> ';
    } else {
      this.status = 0;
      this.idG = 'incomplete_profile';
      title = this.translate.instant('register.finish.step_3.title');
      text = this.translate.instant('register.finish.step_3.txt');
      this.text = title + text;
    }
    const finish = this.translate.instant('register.btn.navigate');
    this.btnList = [
      {
        type: 'primaryDark',
        index: 13,
        title: finish
      },
    ];

  }
  finish() {
    this.finishEvent.emit();
    this.chdRef.detectChanges();
  }

}
