import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppConfigurationService } from './app.configuration.service';
import { Capacitor } from '@capacitor/core';
import { ToastService } from '../shared/components/toast/toast.service';
import { AlertService } from '../shared/components/alert/alert.service';
import { OveralMessageComponent } from '../shared/components/overal-message/overal-message.component';
import { StrapiService } from './strapi.service';


@Injectable()
export class CommunicationService {
  constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private toastService: ToastService,
    private translate: TranslateService,
    private alertService: AlertService,
    private strapiService: StrapiService
  ) {}

  maintenanceScreenChecked = false;

  /**
   * DELETE
   * This method is used to communicate with the WebApi specified endpoint with a DELETE verb to delete a resource.
   *
   * @param {string} endpoint endpoint is used to specify the endpoint of the communication
   * @param {any} query
   * @returns an `Observable` of the body as an `Object`.
   */
  delete(endpoint: string, query: any): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (query != null) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    return this.http
      .delete<Observable<any>>(this.appConfigurationService.get('domain') + endpoint, { params })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((e) => {
          this.errorHandler(e);
          return throwError(e);
        })
      );
  }

  /**
   * PATCH
   * This method is used to communicate with the WebApi specified endpoint with a PATCH verb to modify parcially a resource.
   *
   * @param {string} endpoint endpoint is used to specify the endpoint of the communication
   * @param {any} data
   * @returns an `Observable` of the body as an `Object`.
   */
  patch(endpoint: string, data: any): Observable<any> {
    return this.http.patch<Observable<any>>(this.appConfigurationService.get('domain') + endpoint, data).pipe(
      map((res) => {
        return res;
      }),
      catchError((e) => {
        this.errorHandler(e);
        return throwError(e);
      })
    );
  }

  /**
   * PUT
   * This method is used to communicate with the WebApi specified endpoint with a PUT verb to edit or modify a resource.
   *
   * @param {string} endpoint endpoint is used to specify the endpoint of the communication
   * @param {any} data
   * @returns an `Observable` of the body as an `Object`.
   */
  put(endpoint: string, data: any): Observable<any> {
    return this.http.put<Observable<any>>(this.appConfigurationService.get('domain') + endpoint, data).pipe(
      map((res) => {
        return res;
      }),
      catchError((e) => {
        this.errorHandler(e);
        return throwError(e);
      })
    );
  }

  /**
   * GET
   * This method is used to communicate with the WebApi specified endpoint with a GET verb to get and read a resource
   *
   * @param {string} endpoint endpoint is used to specify the endpoint of the communication
   * @param {any} query
   * @param {boolean} auth is an optional value for authentication
   * @returns an `Observable` of the body as an `Object`.
   */
  get(endpoint: string, query: any, responseType: string = null, auth: boolean = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    let responseTypeStr = null;
    if (query != null) {
      for (const key in query) {
        if (query.hasOwnProperty(key)) {
          params = params.append(key.toString(), query[key]);
        }
      }
    }
    responseTypeStr = responseType;

    return this.http
      .get<Observable<any>>(this.appConfigurationService.get('domain') + endpoint, {
        params,
        responseType: responseType ? responseTypeStr : null,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((e) => {
          this.errorHandler(e);
          return throwError(e);
        })
      );
  }

  /**
   * POST
   * This method is used to communicate with the WebApi specified endpoint with a POST verb to create a resource
   *
   * @param {string} endpoint endpoint is used to specify the endpoint of the communication
   * @param {any} data
   * @param {boolean} auth is an optional value for authentication
   * @returns an `Observable` of the body as an `Object`.
   */
  post(endpoint: string, data: any, responseType: string = null, auth: boolean = true): Observable<any> {
    let responseTypeStr = null;
    responseTypeStr = responseType;
    return this.http
      .post<Observable<any>>(this.appConfigurationService.get('domain') + endpoint, data, { responseType: responseTypeStr })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((e) => {
          this.errorHandler(e);
          return throwError(e);
        })
      );
  }
  errorHandler(e) {
    if(e.status === 406) {
      const modalParams = {
        text: this.translate.instant('MODAL_UPDATE.TITLE'),
        btnList: [{
          type: 'primaryDark',
          index: 1,
          title: this.translate.instant('MODAL_UPDATE.UPDATE'),
        }],
        image: 8
      };
      this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
        modal.getChildComponent().subscribe((componentRef: OveralMessageComponent) => {
          componentRef.btnEvent.subscribe(() => {
            if (Capacitor.getPlatform() === 'ios') {
              window.open('https://apps.apple.com/es/app/fundeen/id1510581729', '_system');
            } else if (Capacitor.getPlatform() === 'android') {
              window.open('https://play.google.com/store/apps/details?id=com.fundeen.app', '_system');
            }
          });
        });
        modal.onClose().subscribe();
      });
    } else if (e.status === 503) {
      const modalParams = {
        image: 16,
        text: this.translate.instant('MODAL_UPDATE.MAINTENANCE'),
      };
      this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {});
    } else {
      if (e.error.status === 500) {
        const errorMsg = this.translate.instant('ERRORS.500');
        //this.toastService.showToast(errorMsg, 'warning');
      }
      if (e.error.status === 409 && (!e.error.errors || e.error.errors === undefined || e.error.errors.lenght === 0)) {
        //this.toastService.showToast('Conflicto', 'warning', true);
      }
      if (!this.maintenanceScreenChecked && (
        e.status === 0 ||
        (e.error && e.error.status && (e.error.status === 500 || e.error.status === 501 || e.error.status === 502 || e.error.status === 503))
      )) {
        this.maintenanceScreenChecked = true;
        this.strapiService.getMaintenanceScreenInfo().subscribe(resp => {
          if (resp.data && resp.data.attributes.active) {
            const modalParams = {
              text: resp.data.attributes.message,
              image: 8
            };
            this.alertService.showModal(OveralMessageComponent, modalParams, '', '', true).subscribe((modal: any) => {
              modal.onClose().subscribe();
            });
          }
          setTimeout(() => {
            this.maintenanceScreenChecked = false;
          }, 1800000);
        })
      }
    }
  }
}
