import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'fun-referral-card',
  templateUrl: './referral-card.component.html',
  styleUrls: ['./referral-card.component.scss'],
})
export class ReferralCardComponent implements OnInit {

  @Input() promotionalCode = 0;
  @Input() numInversorPaid = 0;
  @Input() numInversorNoPaid = 0;
  @Input() numNoInversor = 0;

  constructor() { }

  ngOnInit() {}

}
