import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import * as momentNs from 'moment';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { InvestPopupHeaderComponent } from './invest-popup-header/invest-popup-header.component';
import { TranslateService } from '@ngx-translate/core';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { ActionSheetService } from '../action-sheet/action-sheet.service';
import { ToastService } from '../toast/toast.service';
import { HelpService } from 'src/app/services/help.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { HubspotService } from 'src/app/services/hubspot.service';
import { Storage } from '@ionic/storage';

const moment = momentNs;

@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.scss'],
})
export class InvestComponent implements OnInit, AfterViewInit {
  @Output() public investEvent = new EventEmitter<any>();
  @Output() public investByWalletEvent = new EventEmitter<any>();
  @Output() public newInvestEvent = new EventEmitter<any>();
  @Output() public goToHomeEvent = new EventEmitter<any>();
  @Output() public shareTestEvent = new EventEmitter<any>();
  @Output() public keepOpenEvent = new EventEmitter<any>();
  @Output() public notKeepopenEvent = new EventEmitter<any>();
  @Output() public investmentQueueEvent = new EventEmitter<any>();
  @Output() public getPayTypeEvent = new EventEmitter<any>();
  @Output() public getCodePromotionEvent = new EventEmitter<any>();
  @Output() public startSignEvent = new EventEmitter<any>();
  @Output() public confirmInvestWalletEvent = new EventEmitter<any>();
  @Output() public finishSignOneDocumentEvent = new EventEmitter<any>();
  @Output() public signaturitIssueEvent = new EventEmitter<any>();
  @Output() public amountValueEvent = new EventEmitter<any>();
  @Output() public purchaseEvent = new EventEmitter<any>();
  @Output() public cancelInvestEvent = new EventEmitter<any>();
  @Input() data: any;
  @Input() test: any;
  @Input() amount: any;
  @Input() localInvestProject: any;
  @Input() profile: any;

  headerData: any;
  bodyData: any;
  wantToIncrease;
  percentInvested: any;
  percentFuture: any;
  percentReserve: any;
  daysLeft: any;
  percentLeft: any;
  percentBox: number;
  step = 0;
  testInfo: any;
  invest: number;
  countDown: any;
  lwUrl: any;
  investByCard: boolean;
  transaction: any;
  signaturitTransId: any;
  errors = [];
  loaderInvestProcess: boolean;
  shareText: any;
  isTimeStop = false;
  lessThanMinuts: boolean;

  hasPrevInvest: boolean;
  moneyInvested: any;
  moneyExpected: any;
  percentInv: any;
  leftInv: any;
  isDocumentSigned = false;
  paymentType: string;

  countdownStarted = false;
  localInvestId: any;

  promotionalModel: any;
  interval: any;

  percentInvestedBeforeIncrease: any;
  progresBarPercent: any;
  investId: any;
  previewDocuments;
  hubspotQuestion = false;

  deal: any;

  constructor(
    public actionSheetService: ActionSheetService,
    private cdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private helpService: HelpService,
    private overviewService: OverviewProjectService,
    private amplitudeService: AmplitudeService,
    private HubspotService: HubspotService,
    private storage: Storage
  ) { }

  ngOnInit() {
    this.deal = this.data.projectDeal;
    this.previewDocuments = this.data.signDocuments !== undefined && this.data.signDocuments.length > 0 ? true : false;
    this.data.bodyInfo.projectName = this.data.headerInfo.name;
    this.loaderInvestProcess = false;
    this.countDown = '30:00';
    if (((this.test && this.test !== undefined) || (this.profile.dontRequestInvestmentExperienceInfo !== true && this.profile.isExperiencedInvestor === null)) && this.data.headerInfo.status !== 15) {
      this.step = 0;
    } else {
      this.step = 1;
      this.sendTrackEvents('invest_screen', {});
    }
    registerLocaleData(es);
    this.wantToIncrease = 0;
    this.headerData = this.data.headerInfo;
    this.testInfo = this.test;
    this.bodyData = this.data.bodyInfo;
    this.getDaysLeft();
    this.getPercet();

    if (this.headerData.invested && this.headerData.invested !== undefined) {
      this.hasPrevInvest = true;
      this.getPrevInvest(this.headerData.invested);
    } else {
      this.hasPrevInvest = false;
    }
    this.checkCurrentDeal();
    this.cdRef.detectChanges();
  }

  timer(defatulStage?, specificParams?) {
    if (defatulStage !== undefined) {
      setTimeout(() => {
        this.checkCurrentDeal(defatulStage, specificParams);
      }, 100);
    } else {
      setTimeout(() => {
        this.checkCurrentDeal();
      }, 4000);
    }
  }

  checkCurrentDeal(defatulStage?, specificParams?) {
    this.storage.get('USERNAME').then((val) => {
      const userEmail = val;
      const idProject = this.data.investInfo.id;
      if (this.deal === undefined) {
        this.HubspotService.getDeals(userEmail, idProject).subscribe((resHusbpot: any) => {
          if(resHusbpot.success) {
            const deals = resHusbpot.data.results;
            if(deals.length) {
              this.deal = deals[0];
              if (defatulStage !== undefined) {
                this.changeHuspotStage(defatulStage, specificParams);
              } else {
                this.changeHuspotStage();
              }
            } else {
              if (defatulStage !== undefined) {
                this.timer(defatulStage, specificParams);
              } else {
                this.timer();
              }
            }
          }
        });
      } else {
        this.changeHuspotStage();
      }
    });
  }

  changeHuspotStage(defatulStage?, specificParams?) {
    let stage = '';
    let params = {};
    let sum = 0;
    let promotionalMoney;
    let sumParse;
    if (this.promotionalModel && this.promotionalModel['promos']) {
      for (let i = 0; i < this.promotionalModel['promos'].length; i++) {
        sum += this.promotionalModel['promos'][i].netAmount;
      }
      sumParse = Number(sum.toFixed(2)); // Corrige error decimal de js
    } else {
      sumParse = 0;
    }
    promotionalMoney = Math.floor(sumParse);
    const amountInvest = this.promotionalModel && this.promotionalModel.add ? this.amount + promotionalMoney : this.amount;
    if (defatulStage === undefined) {
      switch(this.step) {
        case 0:
          stage = 'test';
          break;
        case 1:
          if (this.headerData.status !== 15) {
            //stage = 'reservation';
            stage = 'checkout';
          }
          break;
        case 2:
          stage = 'sign';
          params = {amount: amountInvest};
          break;
        case 3:
          stage = 'payment';
          params = {amount: amountInvest};
          break;
        case 4:
          stage = 'ok';
          break;
      }
    } else {
      stage = defatulStage;
    }

    if(stage !== '' && stage !== undefined) {
      params['estado_del_proyecto'] = this.headerData.status;
      if(stage === 'ok') {
        this.HubspotService.updateDeal(this.deal.id, stage, { amount: (this.moneyInvested + amountInvest) }).subscribe((resHusbpot: any) => {
          this.deal = resHusbpot.data;
          this.storage.get('HS_PRODUCT_ID').then(val => {
            this.HubspotService.createOrder(val, amountInvest).subscribe((resHusbpot: any) => {
              if(resHusbpot.success) {
                const order = resHusbpot.data;
                if(order) {
                  const deal = this.deal.id;
                  this.HubspotService.associateDealOrder(deal, order.id).subscribe((resHusbpot: any) => {});
                }
              }
            });
          })
        });
      } else if (stage === 'reservation') {
        this.HubspotService.updateDeal(this.deal.id, stage, { amount: (specificParams.amount) }).subscribe((resHusbpot: any) => {
          this.deal = resHusbpot.data;
        });
      } else if (this.deal.properties.dealstage !== this.HubspotService.getStage('ok')) {
        this.HubspotService.updateDeal(this.deal.id, stage, params).subscribe((resHusbpot: any) => {
          this.deal = resHusbpot.data;
        });
      }
    }
  }

  ngAfterViewInit() {
    this.getPercet();
    this.cdRef.detectChanges();
  }

  updateHubsportReservation(model) {
    this.changeHuspotStage('reservation', model);
  }

  getPrevInvest(prev) {
    const invested = [];
    const moneyExpected = [];
    prev.forEach(element => {
      invested.push(element.invested);
      moneyExpected.push(element.totalRevenue);
    });
    this.moneyInvested = invested.reduce((a, b) => a + b, 0);
    this.moneyExpected = moneyExpected.reduce((a, b) => a + b, 0);
    this.percentInv = (this.moneyInvested / this.headerData.objective) * 100 + '%';
    this.leftInv = ((((this.headerData.contributed / this.headerData.objective) * 100))
    - ((this.moneyInvested / this.headerData.objective) * 100)) + '%';
  }

  getMoneyToInvest(event) {
    this.invest = event.invest;
    this.amount = event.invest;
    this.data.moneyInvested = this.invest;
    this.investProcess(event);
    this.cdRef.detectChanges();
  }

  investProcess(event) {
    this.investByCard = event.mode === 1 ? true : false;
    this.data.investByCard = this.investByCard;
    const idP = this.data.investInfo.id;
    const modelP = {
      accept_conditions: true,
      amount: event.invest.toString().replace(',', '.'),
      promotional_code: event.code,
      wallet: this.data.investInfo.wallet,
      username: this.data.investInfo.username,
      invest_by_card: this.investByCard,
      mangopay_user_id: this.data.bodyInfo.mangopayUserId
    };
    if (this.investByCard) {
      modelP['invest_by_card_in_mangopay'] = true;
    }
    if (this.localInvestId !== undefined) {
      modelP['project_participations_id'] = this.localInvestId;
    }
    if (this.promotionalModel.add && this.promotionalModel.promos.length > 0) {
      modelP['promotional_movements'] = this.promotionalModel.promos;
      let sum = 0;
      for (let i = 0; i < this.promotionalModel.promos.length; i++) {
        sum += this.promotionalModel.promos[i].netAmount;
      }
      const sumParse = sum !== 0 ? Number(sum.toFixed(2)) : 0;
      modelP.amount = (Number(modelP.amount) + Math.floor(sumParse)).toString();
      this.invest = modelP.amount;
    }
    const params = {
      id: idP,
      model: modelP
    };
    this.loaderInvestProcess = true;
    if (this.data.bodyInfo.reserved_list.length > 0) {
      for (let i = 0; i < this.data.bodyInfo.reserved_list.length; i++) {
        this.overviewService.getCancelInvest(this.data.bodyInfo.reserved_list[i].id).subscribe(() => {
          if (i + 1 === this.data.bodyInfo.reserved_list.length) {
            this.investEvent.emit(params);
            this.data.bodyInfo.reserved_list = [];
            this.cdRef.detectChanges();
          }
        }, error => {
          if (i + 1 === this.data.bodyInfo.reserved_list.length) {
            this.investEvent.emit(params);
            this.data.bodyInfo.reserved_list = [];
            this.cdRef.detectChanges();
          }
        });
      }
    } else {
      this.investEvent.emit(params);
      this.cdRef.detectChanges();
    }
    this.cdRef.detectChanges();
  }

  public getInvestResponse(resp) {
    this.countdown();
    this.transaction = resp.response.transaction;
    const token = resp.response.transaction.token;
    //this.lwUrl = resp.urlLw + token + '&tpl=Fundeen.zip';
    if (this.transaction.signaturit) {
      this.step = 2;
      this.changeHuspotStage();
      this.keepOpenEvent.emit();
    } else {
      this.finishInvest();
    }
    this.sendTrackEvents('invest_started', { amount: this.amount, method: this.investByCard ? 'card': 'wallet' });
    // this.finishInvest();
    this.cdRef.detectChanges();
  }

  public getErrors(errors) {
    let txtError = '';
    errors.forEach(element => {
      if (element === 113) {
        this.amount = '';
        if ((this.amount + this.headerData.contributed ) > this.headerData.total) {
          this.translate.get('invest.errors.' + element).subscribe(x => {
            txtError = txtError.concat(x);
            txtError = txtError.concat(' ');
          });
        } else {
          this.investmentQueueEvent.emit();
        }
      } else {
        this.translate.get('invest.errors.' + element).subscribe(x => {
          txtError = txtError.concat(x);
          txtError = txtError.concat(' ');
        });
      }
    });
    if (txtError !== '') {
      this.toastService.showToast(txtError, 'warning', true);
    }
    this.localInvestProject = false;
    this.loaderInvestProcess = false;
    this.errors = errors;
    const params = {
      msg: txtError,
      amount: this.amount
    }
    this.sendTrackEvents('invest_error', params);
    this.cdRef.detectChanges();
  }

  isSigned(resp) {
    this.isDocumentSigned = true;
    if (resp) {
      this.finishInvest();
    } else {
      // Fallo en la firma
      // Habra que mostrar un mensaje de back
    }
    this.cdRef.detectChanges();
  }

  finishInvest() {
    if (this.investByCard) {
      this.paymentType = 'Card';
      this.step = 3;
      this.changeHuspotStage();
      this.keepOpenEvent.emit();
      // window.location.href = this.lwUrl;
    } else {
      this.paymentType = 'Wallet';
      this.investByWallet();
    }
    this.cdRef.detectChanges();
  }

  investByWallet() {
    this.investByWalletEvent.emit(this.transaction);
  }

  signaturitIssue() {
    this.signaturitIssueEvent.emit();
  }

  public confirmInvest(event) {
    if (event === false) {
      // Ha habido un problema a la hora de invertir
      this.loaderInvestProcess = false;
      this.localInvestProject = false;
      this.step = 1;
      this.changeHuspotStage();
      const errorMsg = this.translate.instant('FORMALITIES.ERROR_INVEST_PROCESS');
      this.toastService.showToast(errorMsg, 'warning', true);
      const params = {
        msg: this.translate.instant('FORMALITIES.ERROR_INVEST_PROCESS') ,
        amount: this.amount
      }
      this.sendTrackEvents('invest_error', params);
    } else {
      this.isTimeStop = true;
      // if (this.transaction.amount >= 2000 && this.data.bodyInfo.isFirstInvestment) {
      //   this.hubspotQuestion = true;
      // }
      this.step = 4;
      this.changeHuspotStage();
      this.notKeepopenEvent.emit();
      this.confirmInvestWalletEvent.emit(this.transaction);
      const params = {
        msg: 'Inversion realizada',
        amount: this.amount,
        method: 'wallet'
      }
      this.sendTrackEvents('invest_finished', params);
    }
    this.cdRef.detectChanges();
  }

  successInvestedCard() {
    this.isTimeStop = true;
    // if (this.transaction.amount >= 2000 && this.data.bodyInfo.isFirstInvestment) {
    //   this.hubspotQuestion = true;
    // }
    this.step = 4;
    this.notKeepopenEvent.emit();
    const params = {
      msg: 'Inversion realizada',
      amount: this.amount,
      method: 'card'
    }
    this.changeHuspotStage();
    this.sendTrackEvents('invest_finished', params);
    this.cdRef.detectChanges();
  }

  share() {
    this.shareText = 'http://twitter.com/intent/tweet?text=https://www.fundeen.com/es/invertir-energia-solar?id=' +
    this.data.investInfo.id + ' ' + this.translate.instant('invest.social_text');
    window.open(this.shareText);
    this.cdRef.detectChanges();
  }

  goHome() {
    this.goToHomeEvent.emit();
  }

  testSucess(event) {
    this.step = 1;
    try {
      this.changeHuspotStage();
      this.sendTrackEvents('invest_screen', {});
    } catch {
      console.log('error hubspot track')
    }
    this.cdRef.detectChanges();
  }

  getWantToIncrease(money) {
    this.wantToIncrease = money;
    this.getPercet();
    this.cdRef.detectChanges();
  }

  getPercet() {
    if (this.headerData.status === 14) {
      this.percentInvestedBeforeIncrease = (this.headerData.contributed * 100) / (this.headerData.objective - this.headerData.capital_increase_amount);
      this.progresBarPercent = ((this.headerData.objective - this.headerData.capital_increase_amount) / this.headerData.objective) * 100;
    } else {
      this.progresBarPercent = this.percentInvested;
    }
    this.percentInvested = (this.headerData.contributed / this.headerData.objective) * 100;
    this.percentFuture = ((this.headerData.contributed + this.wantToIncrease) / this.headerData.objective) * 100;
    if (this.headerData.contributed_intention !== null && this.headerData.contributed_intention !== undefined && this.headerData.contributed_intention > 0) {
      this.percentReserve = ((this.headerData.contributed_intention + this.headerData.contributed) / this.headerData.objective) * 100;
    } else {
      this.percentReserve  = 0;
    }
    if (this.percentFuture < 100) {
      this.percentLeft = this.percentFuture;
    } else {
      this.percentLeft = 100;
    }
    if (this.percentLeft < 78) {
      this.percentBox = this.percentLeft;
    } else {
      this.percentBox = 78;
    }
  }

  getDaysLeft() {
    let endDateN;
    let todayN;
    endDateN = new Date(this.convertToIos(moment(this.headerData.end_date).format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    todayN = new Date(this.convertToIos(moment().format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    if (endDateN === isNaN || endDateN === undefined || !endDateN) {
      // method for firefox
      endDateN = new Date(this.convertToIos(moment(this.headerData.end_date.replace('/', '-')
      .replace('/', '-')).format('YYYY-MM-DD') + ' 00:00:00')).getTime();
    }
    this.daysLeft = (endDateN - todayN) / (1000 * 60 * 60 * 24);
  }

  closeModal() {
    this.goToHomeEvent.emit();
    this.cdRef.detectChanges();
  }

  showInfo() {
    if (this.step === 1) {
      const modalParams = {
        data: this.headerData
      };
      this.actionSheetService.showModal(InvestPopupHeaderComponent, modalParams, '', '', true).subscribe(
        (modal: any) => {
          //modal.setTitle('Invertir');
          modal.setSide('top');
          modal.onClose().subscribe();
        }
      );
    }
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] -1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  newInvest() {
    this.newInvestEvent.emit();
    this.cdRef.detectChanges();
  }

  testFail() {
    const toastText: any = this.translate.instant('invest.fail_test');
    this.toastService.showToast(toastText, 'warning', true);
  }

  signError() {
    this.localInvestProject = false;
    this.step = 1;
    this.loaderInvestProcess = false;
    this.toastService.showToast(this.translate.instant('FORMALITIES.SIGNATURE_ERROR'), 'warning', true);
    this.changeHuspotStage();
    this.sendTrackEvents('invest_error', { msg: this.translate.instant('FORMALITIES.SIGNATURE_ERROR') });
    this.cdRef.detectChanges();
  }

  stopTime() {
    this.isTimeStop = true;
    this.notKeepopenEvent.emit();
    this.cdRef.detectChanges();
  }

  deleteHeader(value) {
    this.isTimeStop = value;
    this.cdRef.detectChanges();
  }

  countdown() {
    if (!this.countdownStarted) {
      let seconds = 1800;
      this.countdownStarted = true;
      this.interval = setInterval(() => {
        const minutes = Math.round((seconds - 30) / 60);
        const remainingSeconds = seconds % 60;
        let secondsToEnd: any;
        let minutesRemaining: any;
        if (remainingSeconds < 10) {
          secondsToEnd = '0' + remainingSeconds;
        } else {
          secondsToEnd = remainingSeconds;
        }
        if (minutes < 10) {
          minutesRemaining = '0' + minutes;
          if (minutes >= 1) {
            minutesRemaining = '0' + minutes;
          } else {
            minutesRemaining = '';
          }
        } else {
          minutesRemaining = minutes;
        }
        seconds--;
        if (seconds <= 0) {
          minutesRemaining = '00';
          secondsToEnd = '00';
        }
        if (minutesRemaining >= 1) {
          this.lessThanMinuts = false;
          this.countDown = minutesRemaining + ':' + secondsToEnd;
        } else {
          this.lessThanMinuts = true;
          this.countDown = secondsToEnd;
        }
        this.cdRef.detectChanges();
      }, 1000);
    }
  }

  openHelp(level, index) {
    this.helpService.openHelp(level, index, 'INVEST');
  }

  getPayType(event) {
    this.getPayTypeEvent.emit(event);
  }

  getCodePromotion(event) {
    this.data.promCode = event;
    this.getCodePromotionEvent.emit(event);
  }

  startSign(event) {
    this.startSignEvent.emit(event);
  }

  getAmountValue(value) {
    this.amountValueEvent.emit(value);
  }

  purchase(event) {
    this.purchaseEvent.emit(event);
  }

  public setInvestId(id) {
    this.investId = id;
    this.cdRef.detectChanges();
  }

  goToSignDocuments() {
    this.previewDocuments = !this.previewDocuments;
    this.cdRef.detectChanges();
  }

  editInvest() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.localInvestProject = false;
    this.step = 1;
    this.changeHuspotStage();
    this.overviewService.getCancelInvest(this.investId).subscribe((x) => {
      this.cancelInvestEvent.emit();
      this.loaderInvestProcess = false;
      this.countdownStarted = false;
      this.countDown = '30:00';
      this.cdRef.detectChanges();
    }, error => {
      this.loaderInvestProcess = false;
      this.countdownStarted = false;
      this.countDown = '30:00';
      this.cdRef.detectChanges();
    });
    this.sendTrackEvents('invest_cancelled', { msg: 'Inversion cancelada' });
    this.cdRef.detectChanges();
  }

  getLocalInvestId(value) {
    this.localInvestId = value.id;
    this.headerData.profitability = value.prof;
    this.cdRef.detectChanges();
  }

  getPromotionalMoney(value) {
    this.promotionalModel = value;
    this.cdRef.detectChanges();
  }

  showTest() {
    this.step = 0;
    this.changeHuspotStage();
    this.cdRef.detectChanges();
  }

  async sendTrackEvents(title, model) {
    await this.amplitudeService.trackEvent(title, model);
  }

}
