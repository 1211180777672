import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fun-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('code1', {static: false}) code1: ElementRef;
  @ViewChild('code2', {static: false}) code2: ElementRef;
  @ViewChild('code3', {static: false}) code3: ElementRef;
  @ViewChild('code4', {static: false}) code4: ElementRef;

  elements: any;
  isEmpty1: boolean;
  isEmpty2: boolean;
  isEmpty3: boolean;
  isEmpty4: boolean;

  @Output() values = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.code1.nativeElement.focus();
    this.elements =  [this.code1, this.code2, this.code3, this.code4];
    this.controlEmpty();
  }
  nextFocus(code) {
    this.elements.forEach(element => {
      if (code.id === element.nativeElement.id) {
        element.nativeElement.focus();
      }
    });
    this.controlEmpty();
  }
  controlEmpty() {
    if (this.code1.nativeElement.value === '') {
      this.isEmpty1 = true;
    } else {
      this.isEmpty1 = false;
    }
    if (this.code2.nativeElement.value === '') {
      this.isEmpty2 = true;
    } else {
      this.isEmpty2 = false;
    }
    if (this.code3.nativeElement.value === '') {
      this.isEmpty3 = true;
    } else {
      this.isEmpty3 = false;
    }
    if (this.code4.nativeElement.value === '') {
      this.isEmpty4 = true;
    } else {
      this.isEmpty4 = false;
    }
  }
  resetCode(code) {
    this.elements.forEach(element => {
      if (code.id === element.nativeElement.id) {
        // element.nativeElement.value = '';
        //console.log(element)
      }
    });
  }
  getValues(v1, v2, v3, v4) {
    this.values.emit([v1, v2, v3, v4]);
  }

}
