import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { OverviewProjectService } from 'src/app/services/overview-project.service';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastService } from '../../toast/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-overvier-calculator',
  templateUrl: './overvier-calculator.component.html',
  styleUrls: ['./overvier-calculator.component.scss'],
})
export class OvervierCalculatorComponent implements OnInit, AfterViewInit {

  @Input() id: any;
  @Input() profitability: any;
  @Input() investors: any;
  @Input() info: any;
  @Input() type: any;
  @Input() status: number;
  @Input() isFlexibleCompensation: boolean = false;
  @Output() public openInvestEvent = new EventEmitter<any>();
  @Output() public calculateHubspotEvent = new EventEmitter<any>();

  calendarInfo: any;
  partialCalendar: any;
  hasPartialCalendar: boolean;
  benefits: any;
  hasInfo = false;
  loadingCalendarInfo = false;
  originalValue: any;
  inputType = this.isFlexibleCompensation ? 'secondary' : 'primaryDark';
  period: any;

  public cashForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorLogin = false;

  constructor(
    private overviewService: OverviewProjectService,
    private toastService: ToastService,
    private translate: TranslateService
    ) { }

  ngOnInit() {
    registerLocaleData(es);
    this.getCalendarInfo('5000');
    this.cashForm = new FormGroup({
      money: new FormControl('', [])
    });
    this.cashForm.patchValue({money: '5000'});
    if (this.info !== undefined) {
      this.benefits = this.oldCalculator(5000)
    } else {
      const bnf = {
        reem: 0,
        inversion: 0,
        total: 0
      }
      this.benefits = bnf;
    }
  }

  ngAfterViewInit() {
    this.cashForm.patchValue({money: '5.000'});
    setTimeout(() => this.calculate(false), 1000);
  }

  calculate(byInput) {
      this.originalValue = this.cashForm.value.money.replace(/\D/g, '');
      if (Number(this.originalValue) >= 500) {
        this.loadingCalendarInfo = true;
        const valueCopy = this.originalValue;
        this.inputType = this.isFlexibleCompensation ? 'secondary-disabled' : 'primaryDark-disabled';
        setTimeout(() => {
        if (this.originalValue === valueCopy && this.originalValue !== '') {
            this.getCalendarInfo(this.cashForm.value.money.replace(/\D/g, ''));
            if (byInput) {
              this.calculateHubspotEvent.emit({amount: this.cashForm.value.money.replace(/\D/g, '')});
            }
          } else {
            this.loadingCalendarInfo = true;
          }
          this.inputType = this.isFlexibleCompensation ? 'secondary' : 'primaryDark';
        }, 500);
      } else {
        this.toastService.showToast(this.translate.instant('INVEST.MIN_INVEST_ALLOWED'), 'info', true);
      }
  }

  getCalendarInfo(value) {
    const params = {
      projectId: this.id,
      amount: value
    }
    this.overviewService.getCalendarInfo(params).subscribe(resp => {
      const calendarGroupedByYear = this.groupByYears(resp);
      const new_bnf = this.getBenefits(calendarGroupedByYear, value);
      const old_bnf = this.oldCalculator(Number(this.cashForm.value.money.replace(/\D/g, '')));
      const totalByoldCalculator = this.getOldCalculatorOperation(value);
      if (
        (Number(new_bnf['total']) - (Number(new_bnf['total']) * 0.02)) <= totalByoldCalculator.total &&
        (Number(new_bnf['total']) + (Number(new_bnf['total']) * 0.02)) >= totalByoldCalculator.total
      ) {
        if (calendarGroupedByYear.length === this.info.term) {
          this.hasInfo = true;
          this.calendarInfo = this.getPeriod(resp); // calendarGroupedByYear;
          this.benefits = this.getBenefits(calendarGroupedByYear, value);
          if (this.calendarInfo.length > 15) {
            this.partialCalendar = {
              prev: [this.calendarInfo[0], this.calendarInfo[1], this.calendarInfo[2]],
              next: [this.calendarInfo[this.calendarInfo.length - 3], this.calendarInfo[this.calendarInfo.length - 2], this.calendarInfo[this.calendarInfo.length - 1]]
            }

            if (this.hasPartialCalendar !== false) {
              this.hasPartialCalendar = true;
            }
          }
          //
          this.loadingCalendarInfo = false;
        } else {
          this.benefits = this.oldCalculator(Number(this.cashForm.value.money.replace(/\D/g, '')));
          this.loadingCalendarInfo = false;
        }
      } else {
        this.benefits = this.oldCalculator(Number(this.cashForm.value.money.replace(/\D/g, '')));
        this.loadingCalendarInfo = false;
      }
    }, error => {
      console.log(error)
      this.benefits = this.oldCalculator(Number(this.cashForm.value.money.replace(/\D/g, '')));
      this.loadingCalendarInfo = false;
    });
  }

  getBenefits(calend ,amountSelected) {
    const amountToCalculate = Number(amountSelected)
    //this.type
    if (calend !== null) {
      const amount = calend.reduce((accumulator, object) => {
        return accumulator + object.amount;
      }, 0);
      const bnf = {
        reem: amount - amountToCalculate,
        inversion: amountToCalculate,
        total: amount //+ amountToCalculate
      }
      return bnf;
    }
  }

  invest() {
    this.openInvestEvent.emit('invest');
  }

  viewAll() {
    this.hasPartialCalendar = false;
  }

  oldCalculator(valueMoney) {
    let profit;
    if (valueMoney && valueMoney !== 0) {
      if (this.info.revenueCalculatorMultiplier) {
        profit = valueMoney * this.info.revenueCalculatorMultiplier;
      } else {
        profit = 0;
      }
    } else {
      profit = 0;
    }
    const bnf = {
      reem: profit - valueMoney,
      inversion: valueMoney,
      total: profit
    }
    return bnf;
  }

  getOldCalculatorOperation(valueMoney) {
    let profit;
    if (Number(valueMoney) && Number(valueMoney) !== 0) {
      if (this.info.revenueCalculatorMultiplier) {
        profit = Number(valueMoney) * this.info.revenueCalculatorMultiplier;
      } else {
        if (this.info.totalProfit && this.info.totalProfit !== 0) {
          profit = (Number(valueMoney) / this.info.total) * this.info.totalProfit;
        } else {
          profit = 0;
        }
      }
    } else {
      profit = 0;
    }
    const bnf = {
      value: Number(valueMoney),
      reem: profit - Number(valueMoney),
      inversion: Number(valueMoney),
      total: profit
    }
    return bnf;
  }

  groupByYears(resp) {
    let yearList = [];
    let paymentsByYear = [];
    for (let i = 0; i < resp.length; i++) {
      if (yearList.length === 0 || resp[i]['date'].substring(6,10) !== yearList[yearList.length - 1]) {
        yearList.push(resp[i]['date'].substring(6,10));
      }
    }
    for (let i = 0; i < yearList.length; i++) {
      const payment = resp.filter((item) => item['date'].substring(6,10) === yearList[i]);
      const date = payment[0]['date'];
      let amount = 0;
      let profitOrInterest = 0;
      let sharePremiumOrRedemption = 0;
      for (let j = 0; j < payment.length; j++) {
        amount = amount + payment[j]['amount'];
        profitOrInterest = profitOrInterest + payment[j]['profitOrInterest'];
        sharePremiumOrRedemption = sharePremiumOrRedemption + payment[j]['sharePremiumOrRedemption'];
      }
      const obj = {
        'date': date,
        'amount': amount,
        'profitOrInterest': profitOrInterest,
        'sharePremiumOrRedemption': sharePremiumOrRedemption
      }
      paymentsByYear.push(obj);
      amount = 0;
      profitOrInterest = 0;
      sharePremiumOrRedemption = 0;
    }
    return paymentsByYear;
  }

  getPeriod(objetList) {
    // Crear un objet para almacenar los objets agrupados por year
    const periodByYear = {};

    objetList.forEach(objet => {
      const year = objet.date.split('/')[2]; // Obtener el year del objet
      if (!periodByYear[year]) {
        periodByYear[year] = [objet];
      } else {
        periodByYear[year].push(objet);
      }
    });

    // Asignar el período correspondiente a cada grupo de objets por year
    let index = 0;
    for (const year in periodByYear) {
      const periodTime = periodByYear[year].length;
      this.period = this.getPeriodTime(periodTime);

      periodByYear[year].forEach(objet => {
        index += 1;
        objet.period = this.period + ' ' + index;
      });
    }

    return objetList;
  }

  getPeriodTime(periodTime) {
    let period;

    switch (periodTime) {
      case 1:
        period = this.translate.instant('CALENDAR.YEAR');
        break;
      case 2:
        period = this.translate.instant('CALENDAR.SEMESTER');
        break;
      case 3:
        period = this.translate.instant('CALENDAR.FOUR_MONTH');
        break;
      case 4:
        period = this.translate.instant('CALENDAR.TRIMESTER');
        break;
      case 6:
        period = this.translate.instant('CALENBDAR.BIMESTER');
        break;
      case 12:
        period = this.translate.instant('CALENDAR.FOUR_MONTH');
        break;
      default:
        period = this.translate.instant('CALENDAR.YEAR');
        break;
    }
    if(this.id == 130) {
        period = this.translate.instant('CALENBDAR.BIMESTER');
    }
    return period;
  }

}
