import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'fun-register-taxdown-code',
  templateUrl: './register-taxdown-code.component.html',
  styleUrls: ['./register-taxdown-code.component.scss'],
})
export class RegisterTaxdownCodeComponent implements OnInit, AfterViewInit {

  @ViewChild('code1', {static: false}) code1: ElementRef;
  @ViewChild('code2', {static: false}) code2: ElementRef;
  @ViewChild('code3', {static: false}) code3: ElementRef;

  @Output() public sendValueEvent = new EventEmitter<any>();

  parentForm: FormGroup;
  elements: any;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.parentForm = new FormGroup({
      code1: new FormControl('', []),
      code2: new FormControl('', []),
      code3: new FormControl('', []),
    });
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.code1.nativeElement.focus();
    this.elements =  [this.code1, this.code2, this.code3];
    this.chdRef.detectChanges();
  }

  next(code, e) {
    const validKeys = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÑ'.split('');
    for (let i = 0; i < this.elements.length; i++) {
      if (code.id === this.elements[i].nativeElement.id) {
        this.elements[i].nativeElement.focus();
        if (code.value !== '' && validKeys.includes(e.key.toUpperCase())) {
          if (i < 5 && this.elements[i].nativeElement.value !== '') {
            this.elements[i + 1].nativeElement.focus();
          }
        }
      }
    }
    this.chdRef.detectChanges();
  }

  nextFocus(code, e) {
    const validKeys = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÑ'.split('');
    for (let i = 0; i < this.elements.length; i++) {
      if (code.id === this.elements[i].nativeElement.id) {
        this.elements[i].nativeElement.focus();
        if (code.value !== '' && validKeys.includes(e.key.toUpperCase())) {
          if (i < 5) {
            this.elements[i + 1].nativeElement.focus();
          }
        } else {
          if (i > 0 && e.key === 'Backspace') {
            this.elements[i - 1].nativeElement.focus();
          }
        }
        if (e.key === 'ArrowLeft') {
          if (i > 0) {
            this.elements[i - 1].nativeElement.focus();
          }
        }
        if (e.key === 'ArrowRight') {
          if (i < 5) {
            this.elements[i + 1].nativeElement.focus();
          }
        }
      }
    }
    this.chdRef.detectChanges();
  }

  validate() {
    if (this.parentForm.valid) {
      this.sendValueEvent.emit(this.parentForm.value);
    }
    this.chdRef.detectChanges();
  }

  pasteCode() {
    setTimeout(() => {
      const fullCode = this.parentForm.value.code1.toString();
      if (this.checkDigit(fullCode[0])) {
        this.parentForm.patchValue({code1: fullCode[0]});
        this.elements[1].nativeElement.focus();
        if (fullCode[1] && this.checkDigit(fullCode[1])) {
          this.parentForm.patchValue({code2: fullCode[1]});
          this.elements[2].nativeElement.focus();
          if (fullCode[2] && this.checkDigit(fullCode[2])) {
            this.parentForm.patchValue({code3: fullCode[2]});
            this.elements[3].nativeElement.focus();
            if (fullCode[3] && this.checkDigit(fullCode[3])) {
              this.parentForm.patchValue({code4: fullCode[3]});
            }
          }
        }
      }
    }, 10);
  }

  checkDigit(digit) {
    const validKeys = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÑ'.split('');
    return validKeys.includes(digit.toUpperCase());
  }

}
