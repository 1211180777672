import { Pipe, PipeTransform } from '@angular/core';
import * as momentNs from 'moment';
const moment = momentNs;

@Pipe({
    name: 'timeLeft',
    pure: true
})
export class TimeLeftPipe implements PipeTransform {

    leftDays: number;
    leftHours: number;

    transform(value: any, args?: any): any {
        if (value) {
            const currentDate = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
            let endDate;
            if (isNaN(value)) {
                endDate = moment((value), 'YYYY-MM-DD HH:mm:ss');
            } else {
                endDate = moment(moment(value).format('YYYY-MM-DD  HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
            }

            this.leftDays = endDate.diff(currentDate, 'days');
            this.leftHours = endDate.diff(currentDate, 'hours') - (this.leftDays * 24);

            return this.leftDays + 'd ' + this.leftHours + 'h';
        }
        return value;
    }

}
