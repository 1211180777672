import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fun-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {

  @Input('name') name;
  @Input('style') style;

  constructor() { }

  ngOnInit() {}

}
