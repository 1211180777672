import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { interval, Observable, ReplaySubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-invest-reserved',
  templateUrl: './invest-reserved.component.html',
  styleUrls: ['./invest-reserved.component.scss'],
})
export class InvestReservedComponent implements OnInit {
  result$: ReplaySubject<any>;
  @Input() data: any;
  @Output() public cancelEvent = new EventEmitter<any>();
  @Output() public continueEvent = new EventEmitter<any>();

  totalInversoresActual;
  totalMoneyReserved;
  freeToInvest;
  inversiones;
  reservedData = [];

  zeroTime = false;
  sub: Subscription;
  btnType = 'primaryDark-disabled';
  btnTitle: any;
  userN: string;
  reservedFullData: any;

  constructor(private cdRef: ChangeDetectorRef, private translate: TranslateService) {
    this.result$ = new ReplaySubject();
  }

  ngOnInit() {
    registerLocaleData(es);
    this.setData();
    this.sub =  interval(10000).subscribe((val) => {
      this.setData();
    });
    this.cdRef.detectChanges();
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.cdRef.detectChanges();
  }

  setData() {
    this.data.subscribe(response => {
      this.reservedFullData = response;
      this.customData();
      this.cdRef.detectChanges();
    });
    this.cdRef.detectChanges();
  }

  customData() {
    this.totalInversoresActual = this.reservedFullData.reserved_investments.length;
      this.totalMoneyReserved = this.sumMoney(this.reservedFullData.reserved_investments);
      this.freeToInvest = this.reservedFullData.free_to_reserve;
      this.inversiones = this.reservedFullData.reserved_investments;

      if (this.freeToInvest > 500 || this.totalInversoresActual === 0) {
        this.btnType = 'primaryDark';
      } else {
        this.btnType = 'primaryDark-disabled';
      }
      if (this.totalInversoresActual !== 1) {
        this.userN = this.translate.instant('FORMALITIES.PENDING_USERS');
      } else {
        this.userN = this.translate.instant('FORMALITIES.PENDING_USER');
      }
      let fullData = [];
      this.reservedFullData.reserved_investments.forEach(element => {
        const time = this.timeToFinish(element.due_date);
        const data = {
          amount:  element.amount,
          timeR: time
        };
        fullData.push(data);
      });
      if (fullData.length <= 3) {
        this.reservedData = fullData;
      } else {
        this.reservedData = [];
        if (fullData[0]) {
          this.reservedData.push(fullData[0]);
        }
        if (fullData[1]) {
          this.reservedData.push(fullData[1]);
        }
        if (fullData[2]) {
          this.reservedData.push(fullData[2]);
        }
      }
      this.timer();
      this.cdRef.detectChanges();
  }

  timer() {
    setTimeout(() => {
      this.customData();
    }, 1000);
  }

  sumMoney(list) {
    let total = 0;
    list.forEach((item) => {
      total += parseFloat(item.amount);
    });
    return total;
  }
  getResult(): Observable<any> {
    return this.result$.asObservable();
  }
  cancel() {
    this.sub.unsubscribe();
    this.cancelEvent.emit();
    this.cdRef.detectChanges();
  }
  continue() {
    this.result$.next(true);
    this.result$.complete();
    this.sub.unsubscribe();
    this.continueEvent.emit();
    this.cdRef.detectChanges();
  }
  timeToFinish(sentDate) {
    let dateEnd: any = new Date(sentDate);
    const now: any = new Date();
    const diffDays = dateEnd - now;
    if (isNaN(diffDays)) {
      dateEnd = new Date(sentDate.replace('/', '-').replace('/', '-'));
      const newDiffDays = dateEnd - now;
      return this.msToTime(newDiffDays);
    } else {
      return this.msToTime(diffDays);
    }
  }
  getTime(sentDate1) {
    const dateEnd1: any = new Date(sentDate1);
    const now1: any = new Date();
    const diffDays1 = dateEnd1 - now1;
    return diffDays1;
  }

  msToTime(duration) {
    let textMinutos;
    let textSegundos;

    const seconds = Math.floor(duration / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const minutes_D = minutes % 60;
    let seconds_D;
    if (seconds % 60 < 10) {
      seconds_D = '0' + seconds % 60;
    } else {
      seconds_D = seconds % 60;
    }
    if (minutes_D == 1) {
      textSegundos = ' min';
    } else {
      textSegundos = ' mins';
    }
    textMinutos = ':';

    if (minutes_D == 0) {
      if (seconds_D == 0) {
        if (days >= 1) {
          return days + 'd: 00' + textMinutos + '00' + textSegundos;
        }
        return '00' + textMinutos + '00' + textSegundos;
      } else {
        if (days >= 1) {
          return days + 'd: ' + seconds_D + textSegundos;
        }
        return seconds_D + textSegundos;
      }
    } else {
      if (days >= 1) {
        return days + 'd: ' + minutes_D + textMinutos + seconds_D + textSegundos;
      }
      return minutes_D + textMinutos + seconds_D + textSegundos;
    }
  }
}
