export const submenuRoutes = {
    projects: [
        '/es/listado-de-proyectos', '/en/list-of-projects', '/ca/llistat-de-projectes',
        '/es/mis-pujas', '/en/my-bids', '/ca/les-meves-licitacions',
        '/es/mis-ofertas', '/en/my-offers', '/ca/les-meves-ofertes'
    ],
    portfolio: [
        '/es/portfolio', '/en/portfolio', '/ca/portfolio',
        '/es/actualizaciones', '/en/updates', '/ca/actualitzacions',
        '/es/documentacion', '/en/documentation', '/ca/documentació',
        '/es/calendario-de-pagos', '/en/payment-calendar', '/ca/calendari-de-pagaments'
    ],
    profile: [
        '/es/movimientos', '/en/movements', '/ca/moviments',
        '/es/mi-perfil', '/en/my-profile', '/ca/el-meu-perfil',
        '/en/notifications', '/es/notificaciones', '/ca/notificacions',
        '/es/programa-de-referidos', '/en/referral-program', '/ca/programa-de-referits'
    ]
}

export const specificSubmenuRoutes = {
    projects: {
        projectList: ['/es/listado-de-proyectos', '/en/list-of-projects', '/ca/llistat-de-projectes'],
        bids: ['/es/mis-pujas', '/en/my-bids', '/ca/les-meves-licitacions'],
        offers: ['/es/mis-ofertas', '/en/my-offers', '/ca/les-meves-ofertes']
    },
    portfolio: {
        portfolio: ['/es/portfolio', '/en/portfolio', '/ca/portfolio'],
        updates: ['/es/actualizaciones', '/en/updates', '/ca/actualitzacions'],
        documentation: ['/es/documentacion', '/en/documentation', '/ca/documentació'],
        calendar: ['/es/calendario-de-pagos', '/en/payment-calendar', '/ca/calendari-de-pagaments']

    },
    profile: {
        movements: ['/es/movimientos', '/en/movements', '/ca/moviments'],
        profile: ['/es/mi-perfil', '/en/my-profile', '/ca/el-meu-perfil'],
        notifications: ['/en/notifications', '/es/notificaciones', '/ca/notificacions'],
        referral: ['/es/programa-de-referidos', '/en/referral-program', '/ca/programa-de-referits']
    }
}