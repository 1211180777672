import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-overview-meeting',
  templateUrl: './overview-meeting.component.html',
  styleUrls: ['./overview-meeting.component.scss'],
})
export class OverviewMeetingComponent implements OnInit {

  @Input() public data;
  @Input() public project;
  @Input() public genericPage = false;

  @Output() public viewMeetingEvent = new EventEmitter<any>();

  page = 'list';
  currentMeeting: any;
  loading = false;

  text = this.translate.instant('MEETING.NO_MEETING_LIST');
  image = 1;

  btnIcon: string;

  constructor(
    private navCtrl: NavController,
    private utils: UtilsService,
    private localize: LocalizeRouterService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.btnIcon = Capacitor.getPlatform() !== 'web' ? 'eye' : 'download';
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].documents && this.data[i].documents.length > 0) {
        this.data[i].documents.forEach(element => {
          element.loading = false;
        });
      }
    }
    this.page = 'list';
  }

  viewMeeting(meeting) {
    this.loading = true;
    const queryParams = {
      meetingId: meeting.id
    };
    const navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };
    this.navCtrl.navigateRoot([this.localize.translateRoute(`/meeting-detail`)], navigationExtras);
  }

  downloadDocs(doc) {
    doc.loading = true;
    if (Capacitor.getPlatform() !== 'web') {
      this.utils.viewdFile(doc['document']);
    } else {
      this.utils.downloadFile(doc.document.url, doc.document.ext, doc.title);
    }
    doc.loading = false;
  }

}
