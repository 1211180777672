<div class="register">
  <div class="body-content">
    <div class="step ">
      <div class="header" [ngClass]="{'mobApp': isMobileApp !== undefined}">
          <h2>DNI</h2>
          <p>Adjunta el DNI del titular seleccionado.</p>
      </div>
      <div class="body">
          <div class="code-container">
              <fun-file-card
                  (getFile)="getIdentityDocumentPartner($event)"
                  (errorEvent)="errorFile($event)"
                  [fileType]="partner"
                  [username]="profile.username"
              ></fun-file-card>
          </div>
      </div>
    </div>
  </div>
</div>