<div class="register">
    <div class="body">
        <div class="date-container">
            <form *ngIf="isMobile" [formGroup]="selectForm">
                <div class="inp select form-input">
                    <label>{{ 'register.document.doc_type' | translate }}</label>
                    <select class="select-box" [class]="selectIsOpen ? 'open select-box' : 'select-box'" [value]="docListValue"
                    name="docType" formControlName="docType" (change)="onProjectIdSelectChange($event)">
                        <option *ngFor="let data of docList"
                        [value]="data.id">
                            {{ data.name }}
                        </option>
                    </select>
                </div>
            </form>
            <div *ngIf="!isMobile" class="inp">
                <fun-input
                    placeholder="{{ 'register.document.doc_type' | translate }}"
                    [parentForm]="selectForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="docType"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.document.doc_type' | translate }}"
                    (selectChange)="onProjectIdSelectChange(selectForm.controls['docType'].value)"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="docList"
                    [selectedItem]="docListValue"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    [value]="valueInp"
                    [placeholder]="documentPlaceholder"
                    [parentForm]="selectForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="dni"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{ 'register.document.doc_number' | translate }}"
                    (keyup)="getInfo()"
                    (valueChanged)="getInfo()"
                ></fun-input>
            </div>
        </div>
    </div>
</div>
