import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'co2'
})
export class Co2Pipe implements PipeTransform {

  /*
  transform(value: any, ...args: any[]): any {
    if (value && value !== 'undefined' && value > 0) {
      const valueAbs = Math.ceil(value);
      if (valueAbs >= 100000) {
        return Math.trunc(valueAbs / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' t CO₂';
      } else {
        return Math.trunc(valueAbs).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' kg CO₂';
      }
    } else {
      return '0 t CO₂';
    }
  }*/
  transform(value: any, ...args: any[]): any {
    if (value && value !== 'undefined' && value > 0) {
      const valueAbs = Math.ceil(value * 1000);
      const valueAbs2 = value * 1000;
      if (valueAbs >= 1000000) {
        return Math.trunc(valueAbs / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' Kt';
      } else if (valueAbs >= 1000) {
        if ((valueAbs / 1000) < 100) {
          return this.trunc(valueAbs2 / 1000).toString().replace('.', ',') + ' t';
        } else {
          return Math.trunc(valueAbs / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' Kt';
        }
      } else {
        return this.trunc(valueAbs2).toString().replace('.', ',') + ' kg';
      }
    } else {
      return '0 t';
    }
  }

  trunc(x) {
    const s = x.toString();
    const decimalLength = s.indexOf('.') + 1;
    const numStr = s.substr(0, decimalLength + 2);
    return Number(numStr);
  }

}
