<div class="flex flex-col gap-12">
  <div class="relative h-[438px]">
    <div class="max-h-[438px] overflow-y-auto w-full" >
      <div *ngIf="messages.length > 0" class="h-full overflow-y-auto max-h-[438px] pr-16" #messagesContainer>
        <div *ngFor="let message of messages" class="h-full overflow-y-auto w-full flex flex-col gap-y-24 mb-12">
          <div *ngIf="message.username === user.name" class="flex justify-end">
            <div class="flex flex-col max-w-xs mb-8">
              <div class="flex justify-end items-center gap-12"><span class="font-sans text-s text-right text-eggplant-70">{{ message.time | date:'h:mm' }}</span></div>
              <div class="text-m text-eggplant-80 bg-cream-100 py-4 px-10 rounded-tl-lg rounded-br-lg rounded-bl-lg font-sans">{{ message.comment }}</div>
            </div>
          </div>
          <div *ngIf="(message.username !== user.name) && (message.name === 'Fundeen')" class="flex">
            <div class="flex flex-col max-w-xs mb-8">
              <div class="flex justify-between items-center gap-12"><span class="flex flex-col justify-center items-center content-center w-20 h-20 rounded-full linear-gradient-2"><img class="w-16 h-16" src="../../../../assets/images/img/isotipoWhite.svg" alt=""></span><span class="font-sans text-s text-right text-eggplant-70">{{ message.time | date:'h:mm' }}</span></div>
              <div class="text-m text-white linear-gradient-2 py-4 px-10 ml-24 rounded-tr-lg rounded-br-lg rounded-bl-lg font-sans">{{ message.comment }}</div>
            </div>
          </div>
          <div *ngIf="(message.username !== user.name) && (message.name !== 'Fundeen')" class="flex">
            <div class="flex flex-col max-w-xs mb-8">
              <div class="flex justify-between items-center gap-12"><span class="font-sans text-s">{{ message.name }}</span><span class="font-sans text-s text-right text-eggplant-70">{{ message.time | date:'h:mm' }}</span></div>
              <div class="text-m text-eggplant-80 bg-gray-10 py-4 px-10 rounded-tr-lg rounded-br-lg rounded-bl-lg font-sans">{{ message.comment }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex content-center">
    <form class="w-full" [formGroup]="messageForm" (submit)="sendMessage()">
      <fun-input
        placeholder="Mensaje"
        [parentForm]="messageForm"
        controlName="msg"
        type="text"
        classExtra="!rounded-r-none"
      ></fun-input>
    </form>
    <fun-new-button [buttonType]="'primaryDark'" [size]="'l'" title="Enviar" classExtra="!rounded-l-none " (eventClick)="sendMessage()"></fun-new-button>
  </div>
</div>