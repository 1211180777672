<div class="general-container" #filter>
    <div class="desktop_filter">
        <fun-button [id]="'filterButton'"
            [buttonType]="'secondary filterButton'"
            [title]="(count > 0 ? 'filters.filters' : 'filters.filter') | translate"
            [filterIcon]="true"
            [showCount]="filtersOpened"
            [count]="count"
            (eventClick)="filtersOpened = !filtersOpened">
        </fun-button>
        <div *ngIf="filtersOpened" class="filters-panel">
            <div id="filterHeader" class="filters-header">
                <div id="filterPanelBy" class="filter-by">{{ 'filters.filter_by' | translate }}</div>
                <div *ngIf="count > 0" class="delete-filters" (click)="deleteFilters()">{{ 'filters.clean_filters' | translate }}</div>
            </div>
            <div *ngFor="let filter of filterModel; let i = index" class="filter">
                <div id="filterTitle" class="filter-title" (click)="openFilter(i)">
                    <div id="filterName" class="name">{{filter.title}}</div>
                    <img id="openArrow" class="open-arrow" src="../../../../assets/images/img/up.svg" alt=""
                        [ngClass]="{'closed': openedFilterIndex !== i}">
                </div>
                <div id="filterContent" *ngIf="openedFilterIndex === i" class="filter-content">
                    <div *ngFor="let option of filter.values; let optionIndex = index" id="filterOption" class="filter-option" (click)="elementSelected(i, optionIndex, !option.checked)">
                        <div id="filterName" class="name">{{option.name}}</div>
                        <div id="filterCheck">
                            <input type="checkbox" [(ngModel)]="option.checked" [id]="option">
                       </div>
                    </div>
                    <!-- DENTRO DEL INPUT CHECKNOX -->
                    <!-- (change)="elementSelected(i, optionIndex, $event.target.checked)" -->
                </div>
            </div>
        </div>
    </div>
    <div (click)="openMobFilter()" class="mob_filter">
        <p>{{ (mobileActiveFilters > 0 ? 'filters.filters' : 'filters.filter') | translate }} <span *ngIf="mobileActiveFilters > 0">({{ mobileActiveFilters }})</span></p>
    </div>

    <div *ngIf="filterMobOpen" class="mob_filter_content">
        <div class="shadow" (click)="openMobFilter()"></div>
        <div class="mob_filter_actions">
            <fun-filter-mobile (closeEvent)="openMobFilter()" (changeMobileFiltersEvent)="getMobileFilters($event)" [filterList]="filterList"></fun-filter-mobile>
        </div>
    </div>
</div>