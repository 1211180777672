<div class="general-container">

  <div class="header">
    <h2>{{ 'INVESTMENT_RESERVATION.MODAL_TITLE' | translate }}</h2>
    <p innerHTML="{{ 'INVESTMENT_RESERVATION.MODAL_SUBTITLE' | translate }}"></p>
  </div>

  <div class="content">
    <div class="steps-container">
      <h3>{{ 'INVESTMENT_RESERVATION.MODAL_STP_1' | translate }}</h3>
    </div>
    <div class="steps-container">
      <h3>{{ 'INVESTMENT_RESERVATION.MODAL_STP_2' | translate }}</h3>
    </div>
    <div class="steps-container">
      <h3>{{ 'INVESTMENT_RESERVATION.MODAL_STP_3' | translate }}</h3>
    </div>
    <p>{{ 'INVESTMENT_RESERVATION.MODAL_TXT' | translate }}</p>
  </div>

</div>