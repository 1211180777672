export const countryPrefix = [
    {country: 'España', prefix: 34},
    {country: 'Alaska', prefix: 1907},
    {country: 'Argelia', prefix: 213},
    {country: 'Albania', prefix: 355},
    {country: 'Argentina', prefix: 54},
    {country: 'Alemania', prefix: 49},
    {country: 'Armenia', prefix: 374},
    {country: 'Andorra', prefix: 376},
    {country: 'Australia', prefix: 61},
    {country: 'Angola', prefix: 244},
    {country: 'Austria', prefix: 43},
    {country: 'Arabia Saudí', prefix: 966},
    {country: 'Bahreim', prefix: 973},
    {country: 'Bosnia', prefix: 387},
    {country: 'Bangladesh', prefix: 880},
    {country: 'Brasil', prefix: 55},
    {country: 'Bélgica', prefix: 32},
    {country: 'Bulgaria', prefix: 359},
    {country: 'Bolivia', prefix: 591},
    {country: 'Cabo Verde', prefix: 238},
    {country: 'Colombia', prefix: 57},
    {country: 'Camboya', prefix: 855},
    {country: 'Congo, Rep. del', prefix: 242},
    {country: 'Camerún', prefix: 237},
    {country: 'Congo, Rep. Democ.', prefix: 243},
    {country: 'Canadá', prefix: 1},
    {country: 'Corea, Rep. Democ.', prefix: 850},
    {country: 'Centroafricana, Rep.', prefix: 236},
    {country: 'Corea, Rep.', prefix: 82},
    {country: 'Checa, Rep.', prefix: 420},
    {country: 'Costa de Marfil', prefix: 225},
    {country: 'Chile', prefix: 56},
    {country: 'Costa Rica', prefix: 506},
    {country: 'China', prefix: 86},
    {country: 'Croacia', prefix: 385},
    {country: 'Chipre', prefix: 357},
    {country: 'Cuba', prefix: 53},
    {country: 'Dinamarca', prefix: 45},
    {country: 'Dominicana, Rep.', prefix: 1809},
    {country: 'Ecuador', prefix: 593},
    {country: 'Eslovenia', prefix: 386},
    {country: 'Egipto', prefix: 20},
    {country: 'El Salvador', prefix: 503},
    {country: 'Estados Unidos', prefix: 1},
    {country: 'Emiratos Árabes Unidos', prefix: 971},
    {country: 'Estonia', prefix: 372},
    {country: 'Eslovaca, Rep.', prefix: 421},
    {country: 'Etiopía', prefix: 251},
    {country: 'Filipinas', prefix: 63},
    {country: 'Francia', prefix: 33},
    {country: 'Finlandia', prefix: 358},
    {country: 'Gibraltar', prefix: 9567},
    {country: 'Guatemala', prefix: 502},
    {country: 'Grecia', prefix: 30},
    {country: 'Guinea Ecuatorial', prefix: 240},
    {country: 'Groenlandia', prefix: 299},
    {country: 'Haití', prefix: 509},
    {country: 'Hong Kong', prefix: 852},
    {country: 'Hawai', prefix: 1808},
    {country: 'Hungría', prefix: 36},
    {country: 'Honduras', prefix:	504},
    {country: 'India', prefix: 91},
    {country: 'Irlanda', prefix: 353},
    {country: 'Indonesia', prefix: 62},
    {country: 'Islandia', prefix: 354},
    {country: 'Irán', prefix: 98},
    {country: 'Israel', prefix: 972},
    {country: 'Irak', prefix: 964},
    {country: 'Italia', prefix: 39},
    {country: 'Jamaica', prefix: 1876},
    {country: 'Jordania', prefix: 962},
    {country: 'Japón', prefix: 81},
    {country: 'Kenia', prefix: 254},
    {country: 'Kuwait', prefix: 965},
    {country: 'Laos', prefix: 856},
    {country: 'Libia', prefix: 218},
    {country: 'Letonia', prefix: 371},
    {country: 'Liechtenstein', prefix: 41},
    {country: 'Líbano', prefix: 961},
    {country: 'Lituania', prefix: 370},
    {country: 'Liberia', prefix: 231},
    {country: 'Luxemburgo', prefix: 352},
    {country: 'Madagascar', prefix:	261},
    {country: 'México', prefix:	52},
    {country: 'Malasia', prefix: 60},
    {country: 'Moldavia', prefix: 373},
    {country: 'Malta', prefix: 356},
    {country: 'Mónaco', prefix:	377},
    {country: 'Marruecos', prefix: 212},
    {country: 'Mongolia', prefix: 976},
    {country: 'Martinica', prefix: 596},
    {country: 'Mozambique', prefix:	258},
    {country: 'Mauritania', prefix:	222},
    {country: 'Namibia', prefix: 264},
    {country: 'Nigeria', prefix: 234},
    {country: 'Nepal', prefix: 977},
    {country: 'Noruega', prefix: 47},
    {country: 'Nicaragua', prefix: 505},
    {country: 'Nueva Zelanda', prefix: 64},
    {country: 'Países Bajos', prefix: 31},
    {country: 'Perú', prefix: 51},
    {country: 'Pakistán', prefix: 92},
    {country: 'Polonia', prefix: 48},
    {country: 'Panamá', prefix: 507},
    {country: 'Portugal', prefix: 351},
    {country: 'Paraguay', prefix: 595},
    {country: 'Puerto Rico', prefix: 1787},
    {country: 'Qatar', prefix: 974},
    {country: 'Reino Unido', prefix: 44},
    {country: 'Rusia', prefix: 7},
    {country: 'Rumania', prefix: 40},
    {country: 'San Marino', prefix: 378},
    {country: 'Sri-Lanka', prefix: 94},
    {country: 'Senegal', prefix: 221},
    {country: 'Sudáfrica', prefix: 27},
    {country: 'Singapur', prefix: 65},
    {country: 'Sudán', prefix: 249},
    {country: 'Siria', prefix: 963},
    {country: 'Suecia', prefix: 46},
    {country: 'Somalia', prefix: 252},
    {country: 'Suiza', prefix: 41},
    {country: 'Tailandia', prefix: 66},
    {country: 'Túnez', prefix: 216},
    {country: 'Taiwan', prefix: 886},
    {country: 'Turquía', prefix: 90},
    {country: 'Tanzania', prefix: 255},
    {country: 'Ucrania', prefix: 380},
    {country: 'Uruguay', prefix: 598},
    {country: 'Uganda', prefix: 256},
    {country: 'Vaticano', prefix: 39},
    {country: 'Vietnam', prefix: 84},
    {country: 'Venezuela', prefix: 58},
    {country: 'Yemen', prefix: 967},
    {country: 'Yugoslavia', prefix: 381},
    {country: 'Zambia', prefix: 260},
    {country: 'Zimbawe', prefix: 263}
];
