<div class="w-full" *ngIf="investors > 4 || yourInvest > 0">
  <div [class]="'flex ' + wrapClass + ' pl-6 gap-x-24'">
    <div *ngIf="investors > 4">
      <div class="flex">
        <div *ngFor="let avatar of initials" [class]="'avt ' + avtClass + ' ' + avatar.background">
          <p [class]="'text-center font-sans text-white ' + capClass">{{ avatar.letter }}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <p class="font-sans text-m" [ngClass]="{'text-[#0f5e42]' : isFlexibleCompensation, 'bg-gradient-2-text linear-gradient-2' : !isFlexibleCompensation}" *ngIf="yourInvest > 0">
        <span *ngIf="status !== 15">{{ 'OVERVIEW_PROJECT.YOU_INVEST' | translate }} </span>
        <span *ngIf="status === 15">{{ 'OVERVIEW_PROJECT.YOU_RESERVE' | translate }} </span>
         {{ yourInvest | number:'1.0-2':'es' }} €
      </p>
      <p *ngIf="investors > 4" [class]="'font-sans ' + txtClass">
        <span class="font-sansMedium">{{ investors }} personas</span>
        <span *ngIf="status !== 15"> {{ 'INVEST.ALREADY_INVESTED' | translate }}</span>
        <span *ngIf="status === 15"> {{ 'INVEST.ALREADY_RESERVED' | translate }}</span></p>
    </div>
  </div>
</div>