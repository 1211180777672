import { Component, OnInit, ChangeDetectorRef, Input, EventEmitter, Output } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'fun-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {

  @Input() side: any;
  @Input() email: any;
  @Output() public changeLevel = new EventEmitter<any>();
  @Output() public goProjectsEvent = new EventEmitter<any>();

  public passwordForm: FormGroup;
  public emailForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorMail = false;
  public errorPwd1 = false;
  public errorPwd2 = false;

  step = 1;

  constructor(private chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
    this.passwordForm = new FormGroup({
      pwd1: new FormControl('', [Validators.required]),
      pwd2: new FormControl('', [Validators.required]),
    });
  }
  viewprojects() {
    this.goProjectsEvent.emit();
  }
  prev() {
    if (this.step > 1) {
      this.step--;
    } else {
      this.goProjectsEvent.emit();
    }
  }
  submit() {
    this.isSubmit = true;
    if (this.side === 'recover' && this.email !== undefined) {
      this.next();
      this.changeLevel.emit(this.email);
    }
    if (this.side === 'recover' && this.emailForm.status === 'VALID' && this.email === undefined) {
      this.next();
      this.changeLevel.emit(this.emailForm.controls.email.value);
    } else if (this.passwordForm.status === 'VALID') {
      if (this.passwordForm.controls.pwd1.value === this.passwordForm.controls.pwd2.value) {
        const params = {
          pw1: this.passwordForm.controls.pwd1.value,
          pw2: this.passwordForm.controls.pwd2.value,
        };
        this.errorPwd1 = false;
        this.errorPwd2 = false;
        this.next();
        this.changeLevel.emit(params);
      } else {
        this.errorPwd1 = true;
        this.errorPwd2 = true;
      }
    }
    this.chdRef.detectChanges();
  }
  public next() {
    this.step++;
    this.chdRef.detectChanges();
  }

}
