<div class="general-container">
  <div class="text-container">
    <div class="header">
      <h2>{{ 'OVERVIEW.CAPITAL_INCREASE.MORE_INFO_TITLE' | translate }}</h2>
      <p class="subtitle">{{ 'OVERVIEW.CAPITAL_INCREASE.MORE_INFO_SUBTITLE' | translate }}</p>
    </div>
    <div class="content">
      <div class="step">
        <h3>{{ 'OVERVIEW.CAPITAL_INCREASE.STEP_1_TITLE' | translate }}</h3>
        <p>{{ 'OVERVIEW.CAPITAL_INCREASE.STEP_1_SUBTITLE' | translate }}</p>
      </div>
      <div class="step">
        <h3>{{ 'OVERVIEW.CAPITAL_INCREASE.STEP_2_TITLE' | translate }}</h3>
        <p>{{ 'OVERVIEW.CAPITAL_INCREASE.STEP_2_SUBTITLE' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="btn-content">
    <div class="btn-container first-btn">
      <fun-new-button (eventClick)="goBack()" buttonType="secondaryDark" title="{{ 'OVERVIEW.CAPITAL_INCREASE.BTN_1' | translate }}" ></fun-new-button>
    </div>
    <div class="btn-container second-btn">
      <fun-new-button (eventClick)="goToInvest()" [buttonType]="btnType" title="{{ 'OVERVIEW.CAPITAL_INCREASE.BTN_2' | translate }}" ></fun-new-button>
    </div>

  </div>
</div>