<div class="general-container appear">
  <fun-spinner *ngIf="loading"></fun-spinner>
  <div *ngIf="!loading && benefitsByMonth && benefitsByMonth.length > 0" class="content-container">
      <div *ngIf="benefitsByMonth && !loading" class="content-calendar">
          <ng-container *ngFor="let monthWithBenefits of benefitsByMonth;let indice=index">
              <app-calendar-pay-month [indice]="indice + 1" [monthWithBenefits]="monthWithBenefits"></app-calendar-pay-month>
          </ng-container>
      </div>
  </div>
  <div class="notInvestor" *ngIf="!loading && (!benefitsByMonth || benefitsByMonth.length === 0)">
      <img src="../../assets/images/illustrations/notifications.svg" alt="notification">
      <p>{{ 'PAYMENT_CALENDAR.TXT' | translate }}</p>
  </div>
</div>