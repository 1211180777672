import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as momentNs from 'moment-timezone';
const moment = momentNs;

@Component({
  selector: 'app-overview-local-invest-steps',
  templateUrl: './overview-local-invest-steps.component.html',
  styleUrls: ['./overview-local-invest-steps.component.scss'],
})
export class OverviewLocalInvestStepsComponent implements OnInit {

  @Input() info: any;
  today: any;
  step;
  stepName: string;

  timeRemaining: number;
  stepList: [];
  daysLeft: string;
  timeRemainingTxt: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
    const todayParse = new Date(this.convertToIos(moment().tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
    let endDateParse =  new Date(this.convertToIos(moment(this.info.unlock).tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));

    if ((navigator.userAgent.toLowerCase().indexOf('firefox') > -1) && (this.info.unlock !== undefined)) {
      endDateParse = new Date(this.convertToIos(moment(this.info.unlock.replace('/', '-').replace('/', '-'))
      .tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
    }

    this.today = moment(todayParse);
    const endDate = moment(endDateParse);

    this.timeRemaining = endDate.diff(this.today, 'days');
    const dayR = this.timeRemaining === 1 ? this.translate.instant('INVEST.ONE_DAY') : this.translate.instant('INVEST.DAYS', { time: this.timeRemaining});
    this.timeRemainingTxt = this.translate.instant('INVEST.TIME_TO_CAN_INVEST', { time: dayR });
    this.step = this.getCurrentStep();
    this.stepName = this.step;
  }

  convertToIos(date) {
    const arr = date.split(/[- :]/);
    const r = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return r;
  }

  getCurrentStep() {
    const arr = this.sortByDate(this.info.steps);
    let j = 0;
    arr.forEach(element => {
      j++;
      element.index = j;
    });
    this.stepList = arr;

    for (let i = 0; i < arr.length; i++) {
      let starDateParse = new Date(this.convertToIos(moment(arr[i].start_date).tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        starDateParse = new Date(this.convertToIos(moment(arr[i].start_date.replace('/', '-').replace('/', '-'))
        .tz('Europe/Madrid').format('YYYY-MM-DD HH:mm:ss')));
      }
      const starDate = moment(starDateParse);
      const timeRemaining =  starDate.valueOf() - this.today.valueOf(); //starDate.diff(this.today, 'seconds');
      if (timeRemaining <= 0 && arr.length > i + 1) {
        let starDateNext = moment(arr[i + 1].start_date, 'YYYY/MM/DD HH:mm:ss').tz('Europe/Madrid');
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          starDateNext = moment(arr[i + 1].start_date.replace('/', '-').replace('/', '-'), 'YYYY/MM/DD HH:mm:ss').tz('Europe/Madrid');
        }
        const timeRemainingNext = starDateNext.valueOf() - this.today.valueOf(); // starDateNext.diff(this.today, 'seconds');
        if (timeRemainingNext > (- 60 * 1000 * 60)) {
          return i + 1;
        }
      } else if (arr.length === i + 1) {
        if (timeRemaining <= 0) {
          return i + 1;
        }
      }
    }
  }

  sortByDate(arr) {
    arr.sort(function (a, b) {
      if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
        return Number(new Date(a.start_date.replace('/', '-').replace('/', '-'))) -
        Number(new Date(b.start_date.replace('/', '-').replace('/', '-')));
      } else {
        return Number(new Date(a.start_date)) - Number(new Date(b.start_date));
      }
    });
    return arr;
  }
}
