import { Component, NgZone, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { PermissionService } from './services/permission.service';
import { AmplitudeService } from './services/amplitude.service';
import { PushNotificationsService } from './services/push-notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  isLogin: boolean;
  subscription: any;
  counter = 0;
  isLast: boolean;

  constructor(
    private platform: Platform,
    private router: Router,
    private zone: NgZone,
    public location: Location,
    private navCtrl: NavController,
    private storage: Storage,
    private permission: PermissionService,
    private amplitudeService: AmplitudeService,
    private pushNotifications: PushNotificationsService,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/es/acceso' || event.url === '/en/login') {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
        this.amplitudeService.trackEvent('page_view', { page: event.url })
      }
    });
    this.initializeApp();

    this.platform.ready().then(() => {
      try {
        SplashScreen.hide();
        StatusBar.setStyle({ style: Style.Light });
        if (this.platform.is('android')) {
          StatusBar.setBackgroundColor({ color: '#DBB4C6' });
        }
        this.pushNotifications.activateOneSignal();

      } catch (err) { }
    });
    this.amplitudeService.initamplitude();
  }

  ngAfterViewInit() {
    this.permission.requestATT();
    this.removeServiceWorker();
    //this.authService.isLogged();
    this.listenOnHardwareBackButton();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationStart) {
        if (
          (val.url === '/es/registro' || val.url === '/en/register') &&
          this.platform.is('android') &&
          Capacitor.getPlatform() !== 'web'
        ) {
          StatusBar.setBackgroundColor({ color: '#FFFFFF' });
        } else if (Capacitor.getPlatform() !== 'web' && this.platform.is('android')) {
          StatusBar.setBackgroundColor({ color: '#FFFFFF' });
        }
        //this.posthogService.pageView("$pageview");
        this.controlChatClosed();
      }
    });
    this.pushNotifications.checkPushNotifications(); 
  }

  controlChatClosed() {
    if (Capacitor.getPlatform() === 'web' && window.HubSpotConversations === undefined) {
      this.timerChatClosed();
    } else {
      this.storage.get('CHAT_OPENED').then((val) => {
        if (val !== true) {
          setTimeout(() => {
            try {window.HubSpotConversations.widget.remove();} catch {}
          }, 400);
        }
      });
    }
  }

  timerChatClosed() {
    setTimeout(() => {
      this.controlChatClosed();
    }, 100);
  }

  async initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
          // Example url: https://beerswift.app/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split("fundeen.com").pop();
          if (slug) {
              this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
      });
    });
  }

  private listenOnHardwareBackButton() {
    if (this.platform.is('android')) {
      this.platform.backButton.subscribeWithPriority(0, () => {
        if (this.isLast) {
          const appStr = 'app';
          navigator[appStr].exitApp();
        }
        this.navCtrl.back();
      });
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        if (
          event.url === '/es/portfolio' ||
          event.url === '/en/portfolio' ||
          event.url === '/es/acceso' ||
          event.url === '/en/login'
        ) {
          this.isLast = true;
        } else {
          this.isLast = false;
        }
        this.storage.get('ISFIRST').then((val) => {
          if (val === null && this.counter === 1) {
            this.isLast = true;
            this.storage.set('ISFIRST', 'first');
          }
        });
        if (event.navigationTrigger !== 'popstate') {
          this.counter++;
        }
      });
    }
  }
  removeServiceWorker() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (let registration of registrations) {
        registration.unregister();
      }
    });
    /*
    if ('caches' in window) {
      caches.keys().then(function (keyList) {
        return Promise.all(
          keyList.map(function (key) {
            return caches.delete(key);
          })
        );
      });
    }*/
  }

}
