import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-update-list-slider',
  templateUrl: './update-list-slider.component.html',
  styleUrls: ['./update-list-slider.component.scss'],
})
export class UpdateListSliderComponent implements OnInit {

  @ViewChild('sliderImg')  sliderImg: IonSlides;
  @Input() imageList;

  slideOpts = {
    initialSlide: 0,
    speed: 1000,
    effect: 'slide', //slide, fade, cube, coverflow, flip
    spaceBetween: 0,
    slidesPerView: 1,
    grabCursor: true,
    slideToClickedSlide: true,
    freeMode: false
  };
  sliderIndex: number;

  constructor() { }

  ngOnInit() {
    this.sliderIndex = 0;
  }

  next() {
    this.sliderImg.slideNext();
  }

  prev() {
    this.sliderImg.slidePrev();
  }

  getCurrentItem() {
    this.sliderImg.getActiveIndex().then(val => {
      this.sliderIndex = val;
    });
  }

}
