import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, OnDestroy, HostListener, Input } from '@angular/core';
import { RegisterCompanyComponent } from '../../register-company/register-company.component';

@Component({
  selector: 'fun-register-person-code',
  templateUrl: './register-person-code.component.html',
  styleUrls: ['./register-person-code.component.scss']
})
export class RegisterPersonCodeComponent implements OnInit, OnDestroy {

  @Output() public resetBtn = new EventEmitter<any>();
  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public resendCodeEvent = new EventEmitter<any>();
  @Output() public submitEvent = new EventEmitter<any>();

  @Input() isMobile: boolean;


  getCode = false;
  codeResended = false;
  countDown: string;
  isCountDowndEnd = false;

  constructor(public chdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.resetBtn.emit();
    this.timeOutToresend();
  }
  getCodeValue(value) {
    this.getCode = true;
    this.sendInfoEvent.emit(value);
    this.chdRef.detectChanges();
  }
  resendCode() {
    this.codeResended = true;
    setTimeout(() => {
      this.codeResended = false;
      this.isCountDowndEnd = false;
      this.resetBtn.emit();
      this.timeOutToresend();
    }, 5000);
    this.resendCodeEvent.emit();
    this.chdRef.detectChanges();
  }
  timeOutToresend() {
    let seconds = 60;
    let interval = setInterval(() => {
      const minutes = Math.round((seconds - 30) / 60);
      const remainingSeconds = seconds % 60;
      let secondsToEnd;
      let minutesRemaining;
      if (remainingSeconds < 10) {
          secondsToEnd = '0' + remainingSeconds;
      } else {
          secondsToEnd = remainingSeconds;
      }
      if (minutes < 10) {
          minutesRemaining = '0' + minutes;
          if (minutes >= 1) {
          minutesRemaining = '0' + minutes;
          } else {
          minutesRemaining = '';
          }
      } else {
          minutesRemaining = minutes;
      }
      if (seconds <= 0) {
        minutesRemaining = '00';
        secondsToEnd = '00';
        this.isCountDowndEnd = true;
        clearInterval(interval);
      }
      if (minutesRemaining >= 1) {
          this.countDown = minutesRemaining + ':' + secondsToEnd;
      } else {
          this.countDown = secondsToEnd;
      }
      if (!this.chdRef['destroyed']) {
        this.chdRef.detectChanges();
      }
      seconds--;
    }, 1000);
  }

  ngOnDestroy() {
    this.chdRef.detach();
  }

}
