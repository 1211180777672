<div class="general-container">
  <div *ngIf="readDocuments" id="read-documents" class="span-container">
      <span id="payment-type">{{ paymentType }}</span>
      <!--<span id="item-name">{{ projectData.headerInfo.name }}</span>
      <span id="item-id">{{ projectData.investInfo.id }}</span>
      <span id="item-brand">{{ projectData.spanInfo.company }}</span>
      <span id="item-category">Energía Solar</span>
      <span id="item-location">{{ projectData.spanInfo.location }}, ES</span>
      <span id="item-profit">{{ projectData.headerInfo.profitability | number:'1.0-2':'es' }}</span>
      <span id="item-goal">{{ projectData.headerInfo.objective | number:'1.0-2':'es' }} €</span>
      <span id="item-index">{{ projectData.spanInfo.position }}</span>-->
      <span id="item-price">{{ projectData.moneyInvested }}</span>
      <span id="list-name">En Financiación</span>
  </div>

  <div class="content">
      <div class="skl" *ngIf="!isCharged">
          <fun-spinner *ngIf="!isCharged"></fun-spinner>
      </div>

      <iframe
          [src]="urlSafe"
          frameborder="0"
          (load)="charged($event)"
          #iframe
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
      ></iframe>
  </div>

  <div class="button-container" *ngIf="isCharged">
      <div class="prev">
          <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
          [buttonType]="'ghost'" title="{{'register.btn.back' | translate}}"></fun-new-button>
      </div>
  </div>
</div>