<div *ngIf="!loading" class="general-container">

  <div class="header row">
      <div class="col-6 col-sm-12 titleContainer">
          <h2 class="font-sans text-eggplant-100 text-xl">{{ 'MOVEMENTS.DEPOSIT-FUNDS.HOW-DEPOSIT' | translate }}</h2>
          <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.WAY-DEPOSIT' | translate }}</p>
          <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.WE-EXPLAIN' | translate }}</p>
      </div>
      <div class="col-6 col-sm-12 advice">
          <p class="font-sans text-eggplant-100/[0.4] text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.IN-ADDITION' | translate }}</p>
      </div>
  </div>
  <div class="content">
      <h2 class="font-sans text-eggplant-100 text-xl">{{ 'MOVEMENTS.DEPOSIT-FUNDS.BANK-TRANSFER' | translate }}</h2>
      <ul>
          <li>
              <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.ENTER-BANK' | translate }}</p>
              <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.BANK-ACCOUNT' | translate }}</p>
          </li>
          <li>
              <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.CONFIGURE-TRANSFER' | translate }}</p>
              <div class="detailContainer">
                  <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.RECIVER' | translate }}</h4>
                  <p class="font-sans text-eggplant-100 text-l">{{ addressee }}</p>
                  <img class="copy" (click)="copy(addressee)" src="../../../../../assets/images/movements/copy.svg" alt="copy">
              </div>
              <div class="detailContainer">
                  <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.CODE' | translate }}</h4>
                  <p class="font-sans text-eggplant-100 text-l">{{ profile.mangopayBankingAliasBic }}</p>
                  <img class="copy" (click)="copy(profile.mangopayBankingAliasBic)" src="../../../../../assets/images/movements/copy.svg" alt="copy">
              </div>
              <div class="detailContainer">
                  <h4 class="font-sans text-eggplant-100/[0.4] text-m">IBAN</h4>
                  <p class="font-sans text-eggplant-100 text-l">{{ profile.mangopayBankingAliasIban }}</p>
                  <img class="copy" (click)="copy(profile.mangopayBankingAliasIban)" src="../../../../../assets/images/movements/copy.svg" alt="copy">
              </div>
              <div class="detailContainer">
                  <h4 class="font-sans text-eggplant-100/[0.4] text-m">{{ 'MOVEMENTS.DEPOSIT-FUNDS.ORDERED' | translate }}</h4>
                  <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.SENDER-TRANSFER' | translate }}</p>
              </div>
          </li>
          <li>
              <p class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.MAKE-TRANSFER' | translate }}</p>
          </li>
          <li>
              <h3 class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.WAIT' | translate }}</h3>
          </li>
          <li>
              <h3 class="font-sans text-eggplant-100 text-l">{{ 'MOVEMENTS.DEPOSIT-FUNDS.START-INVEST' | translate }}</h3>
          </li>
      </ul>
  </div>
</div>
<div *ngIf="loading">
    <fun-spinner></fun-spinner>
</div>
