<div class="general-container">
  <div class="title">
      <h2 class="font-sans text-h600 text-eggplant-100/[0.25]" *ngIf="section === 'consumer'">{{ 'overview.sections.consumer' | translate }}</h2>
      <h2 class="font-sans text-h600 text-eggplant-100/[0.25]" *ngIf="section === 'constructor'">{{ 'overview.sections.team-builder' | translate }}</h2>
      <h2 class="font-sans text-h600 text-eggplant-100/[0.25]" *ngIf="section === 'management'">{{ 'overview.sections.team-manager' | translate }}</h2>
  </div>
  <div class="body">
      <p class="font-sans text-xl md:text-h400 text-eggplant-100">{{ sectionInfo.description }}</p>
  </div>
  <div class="compay-container border border-eggplant-100/[0.1]">
    <div class="flex justify-between">
        <div>
            <p class="font-sans text-3xl text-egplant-100">{{ sectionInfo.name }}</p>
            <div class="flex verify-container">
                <img src="../../../../../assets/icons/verified-color.svg" alt="icono verificado">
                <p class="font-sansMedium text-h200 bg-gradient-3-text !pl-[10px]">{{ 'OVERVIEW_PROJECT.VERIF_BY_FUNDEEN' | translate }}</p>
            </div>
        </div>
        <div class="max-w-[140px]">
            <img class="max-w-[140px] max-h-[90px]" *ngIf="sectionInfo.image" [src]="sectionInfo.image" alt="logo">
            <div *ngIf="!sectionInfo.image" class="avatar-team">
                <h4 class="gradient-bg linear-gradient-7">{{ sectionInfo.name[0] }}</h4>
            </div>
        </div>
    </div>
  </div>

</div>