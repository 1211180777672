<div class="register">
    <div class="body">
        <div class="RLData-container">
            <div class="inp">
                <div class="form-input checkbox">
                    <input [(ngModel)]="contactPerson" (change)="checkContactPerson(); getInfo();" type="checkbox" id="cb1">
                    <label for="cb1">{{'register.legal-representative.contact_person_data' | translate}}</label>
                </div>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.legal-representative.name' | translate}}"
                    [parentForm]="RLDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="nameRepresentative"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.legal-representative.name' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.nameRepresentative"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="{{'register.legal-representative.last_name' | translate}}"
                    [parentForm]="RLDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="surnameRepresentative"
                    type="text"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.legal-representative.last_name' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    (focusout)="focusLost()"
                    [value]="data.surnameRepresentative"
                ></fun-input>
                <span *ngIf="notFullSurname" class="advice">{{ 'register.know.advice' | translate }}</span>
            </div>
            <div class="inp">
                <fun-input
                    placeholder="dd/mm/aaaa"
                    [parentForm]="RLDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="birthDateRepresentative"
                    type="date"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.legal-representative.date' | translate}}"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [value]="data.birthDateRepresentative"
                ></fun-input>
            </div>
            <div class="inp">
                <fun-input
                    *ngIf="!isMobile"
                    placeholder="{{'register.legal-representative.not_specified' | translate}}"
                    [parentForm]="RLDataForm"
                    [formControlKeys]="formControlKeys"
                    [errorMessages]="errorMessages"
                    [isSubmit]="isSubmit"
                    controlName="actAsRepresentative"
                    type="select"
                    [errorLogin]="errorAmount"
                    labelName="{{'register.legal-representative.actAs' | translate}}"
                    (selectChange)="getInfo()"
                    (keyup)="getInfo()"
                    (click)="getInfo()"
                    [items]="actAsList"
                    [selectedItem]="data.actAsRepresentative"
                ></fun-input>
                <form *ngIf="isMobile" action="" [formGroup]="RLDataForm">
                    <div class="inp select form-input">
                        <label>{{'register.legal-representative.actAs' | translate}}</label>
                        <select class="select-box" [ngClass]="{'option': RLDataForm.controls['actAsRepresentative'].value}"
                        name="typeJob" formControlName="actAsRepresentative" (change)="getInfo()">
                            <option hidden [value]="0">{{'register.legal-representative.not_specified' | translate}}</option>
                            <option *ngFor="let data of actAsList"
                            [value]="data.id">
                                {{ data.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
