import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser, ManualParserLoader } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { LangGuard } from './guards/lang.guard';

export function localizeLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings) {
  let defaultL = 'es';
  const language = navigator.language.split('-')[0];
  const supportedLanguages = ['es', 'en', 'ca'];

  if (language === 'ca' || language === 'en') {
    defaultL = language;
  } else {
    defaultL = 'es';
  }

  return new ManualParserLoader(translate, location, settings, supportedLanguages, 'URL.');
}

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/portfolio/portfolio.module').then((m) => m.PortfolioPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'my-bids',
    loadChildren: () => import('./pages/my-bids/my-bids.module').then((m) => m.MyBidsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'my-offers',
    loadChildren: () => import('./pages/my-offers/my-offers.module').then((m) => m.MyOffersPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
    canActivate: [LangGuard],
  },
  {
    path: 'graphics',
    loadChildren: () => import('./pages/dashboard/graphics/graphics.module').then((m) => m.GraphicsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'movements',
    loadChildren: () => import('./pages/movements/movements.module').then((m) => m.MovementsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'list-of-projects',
    loadChildren: () => import('./pages/list-of-projects/list-of-projects.module').then((m) => m.ListOfProjectsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'invest-card',
    loadChildren: () => import('./pages/invest-card/invest-card.module').then((m) => m.InvestCardPageModule)
  },
  {
    path: 'referral-program',
    loadChildren: () => import('./pages/referral-program/referral-program.module').then((m) => m.ReferralProgramPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'referral-program-terms',
    loadChildren: () =>
      import('./pages/referral-program-terms/referral-program-terms.module').then((m) => m.ReferralProgramTermsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./pages/my-profile/my-profile.module').then( m => m.MyProfilePageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'my-profile-update',
    loadChildren: () => import('./pages/my-profile-update/my-profile-update.module').then( m => m.MyProfileUpdatePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'updates',
    loadChildren: () => import('./pages/updates/updates.module').then( m => m.UpdatesPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'documentation',
    loadChildren: () => import('./pages/documentation/documentation.module').then( m => m.DocumentationPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'payment-calendar',
    loadChildren: () => import('./pages/payment-calendar/payment-calendar.module').then( m => m.PaymentCalendarPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'save-as-you-think',
    loadChildren: () => import('./pages/save-as-you-think/save-as-you-think.module').then( m => m.SaveAsYouThinkPageModule)
  },
  {
    path: 'wait',
    loadChildren: () => import('./pages/wait/wait.module').then( m => m.WaitPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'flexible-remuneration',
    loadChildren: () => import('./pages/flexible-remuneration/flexible-remuneration.module').then( m => m.FlexibleRemunerationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'meeting',
    loadChildren: () => import('./pages/meeting/meeting.module').then( m => m.MeetingPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'meeting-detail',
    loadChildren: () => import('./pages/meeting-detail/meeting-detail.module').then( m => m.MeetingDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./pages/configuration/configuration.module').then( m => m.ConfigurationPageModule),
    canLoad: [AuthGuard],
  },
  {
    path: 'lleidanet-confirmation-sign',
    loadChildren: () => import('./pages/lleidanet-confirmation-sign/lleidanet-confirmation-sign.module').then( m => m.LleidanetConfirmationSignPageModule)
  },

  // SORTEO 500€
  /* {
    path: 'referral-program-status',
    loadChildren: () => import('./pages/referral-program-status/referral-program-status.module').then( m => m.ReferralProgramStatusPageModule),
    canLoad: [AuthGuard],
  }, */
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },
    }),
  ],
  exports: [RouterModule, LocalizeRouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
