import { Component, OnInit, EventEmitter, Output, Input, ChangeDetectorRef, ViewChild, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from 'src/app/services/profile.service';
import { RegisterPersonEmailComponent } from '../register-person/register-person-email/register-person-email.component';

@Component({
  selector: 'fun-register-welcome',
  templateUrl: './register-welcome.component.html',
  styleUrls: ['./register-welcome.component.scss']
})
export class RegisterWelcomeComponent implements OnInit {

  @Output() public emitWay = new EventEmitter<any>();
  @Output() public checkRefCodeEvent = new EventEmitter<any>();
  @Output() public emitLogin = new EventEmitter<any>();
  @Output() public validateEmailEvent = new EventEmitter<any>();
  @Output() public removeModalEvent = new EventEmitter<any>();
  @Input() profile: any;

  @ViewChild('registerPersonEmail', { static: false }) registerPersonEmail: RegisterPersonEmailComponent;

  step = 0;
  title: string;
  subtitle: string;
  loading = false;
  profileType = 1;
  userData = {
    email: '',
    pwd: '',
    acceptConditions: false,
    acceptOffers: false,
    referredPromotionalCode: ''
  };
  sendXHR = 'primaryDark-disabled';
  hasError = true;


  textAcceptMail = this.translate.instant('register.validate_mail_msg');
  btnListAcceptMail = [{
    type: 'primaryDark',
    index: 1,
    title: this.translate.instant('register.messages.continue'),
  }];
  imageAcceptMail = 6;

  constructor(
    public chdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private profileService: ProfileService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.profile === null || this.profile === undefined) {
      this.title = this.translateService.instant('register.welcome.title');
      this.subtitle = this.translateService.instant('register.welcome.txt_1');
      this.step = 0;
    } else {
      this.title = this.translateService.instant('REGISTER.PUBLIC.TITLE');
      this.subtitle = this.translateService.instant('REGISTER.PUBLIC.SUBTITLE');
      this.step = 1;
    }
    this.chdRef.detectChanges();
  }
  selectOption(option) {
    this.profileType = option;
    this.chdRef.detectChanges();
  }

  setProfileType() {
    this.loading = true;
    this.profileService.updateUser(this.profileType).subscribe((res) => {
      this.emitWay.emit(this.profileType);
      this.chdRef.detectChanges();
    });
    this.chdRef.detectChanges();
  }

  getEmailData(value) {
    this.hasError = false;
    this.userData['email'] = value.email;
    this.userData['pwd'] = value.pwd;
    this.userData['referredPromotionalCode'] = value.refCode;
    this.checkFormValid();
    this.chdRef.detectChanges();
  }

  getError() {
    this.hasError = true;
    setTimeout(() => {
      this.sendXHR = 'primaryDark-disabled';
      this.chdRef.detectChanges();
    }, 50);
    this.chdRef.detectChanges();
  }

  getConditions(value) {
    this.userData['acceptConditions'] = value;
    this.checkFormValid()
    this.chdRef.detectChanges();
  }

  getOffers(value) {
    this.userData['acceptOffers'] = value;
    this.checkFormValid()
    this.chdRef.detectChanges();
  }

  checkRefCode(code) {
    this.checkRefCodeEvent.emit(code);
    this.chdRef.detectChanges();
  }

  goLogIn() {
    this.emitLogin.emit();
  }

  public setRefCode(username) {
    this.registerPersonEmail.setRefUsername(username);
    this.chdRef.detectChanges();
  }

  submiForm() {
    if (this.checkFormValid()) {
      this.validateEmailEvent.emit(this.userData)
      this.loading = true;
    }
    this.chdRef.detectChanges();
  }

  checkFormValid() {
    if (!this.hasError && this.userData['acceptConditions']) {
      this.sendXHR = 'primaryDark';
      return true;
    } else {
      this.sendXHR = 'primaryDark-disabled';
      return false;
    }
  }

  public registerDone(val) {
    if (val === 'ok') {
      this.step = 2;
    }
    this.loading = false;
    this.chdRef.detectChanges();
  }

  removeModal() {
    this.removeModalEvent.emit();
    this.chdRef.detectChanges();
  }
}
