<!-- <div class="pt-16"></div> -->
<fun-register-welcome
    #registerWellcome *ngIf="step === 0"
    (emitWay)="emitWay($event)"
    (emitLogin)="emitLogin()"
    (checkRefCodeEvent)="checkRefCode($event)"
    (validateEmailEvent)="validateEmail($event)"
    (removeModalEvent)="removeModal()"
    [profile]="profile">
</fun-register-welcome>

<fun-register-person
#registerPerson
*ngIf="step === 1 && method === 'person' && !registerLoading"
[info]="info"
[list]="registerPersonSteps"
[profile]="profile"
[registerStep]="registerStep"
[towns]="towns"
[specificFileSteps]="specificFileSteps"
[onlyAccredit]="onlyAccredit"
[isMobileApp]="isMobile"
[addSpecificNie]="addSpecificNie"
[updateSpecificDocument]="updateSpecificDocument"
(prevStepEvent)="reset()"
(finishRegisterEvent)="finishRegister($event)"
(phoneNumberEvent)="getPhoneNumber($event)"
(lastStepEvent)="secondStep($event)"
(emitDocumentEvent)="emitDocPerson($event)"
(changeWayEvent)="changeWay()"
(confirmCodeEvent)="confitmCode($event, 'phone')"
(confirmCodeEmailEvent)="confitmCode($event, 'email')"
(confirmPhoneEvent)="confirmPhone()"
(resendCodeEvent)="resendCode($event)"
(validateEmailEvent)="validateEmail($event)"
(fullInfoEvent)="getFullInfo($event)"
(getAddressInfoEvent)="emitAddressValue($event)"
(changeEmailEvent)="changeEmail($event)"
(downloadAppEvent)="downloadApp()"
(generateProfileEvent)="generateProfilePerson($event)"
(updateInfoEvent)="updateInfo($event)"
(setDocumentsEvent)="getDocument($event)"
(emitDocumentsEvent)="emitDocumentation($event)"
(finishEvent)="finishProccess()"
(accreditationChecksEvent)="accreditationChecks($event)"
(bankDocumentEvent)="getBankDocument($event)"
(acceptLwTermsEvent)="acceptLw($event)"
(signaturitAccreditEvent)="getSignaturitUrl()"
(continueInvestEvent)="continueInvest()"
(otherMomentEventEvent)="otherMomentAccredit()"
(updateDocNumberEvent)="updateDocNumber($event)"
(signaturitSignedEvent)="signaturitSigned()"
(removeModalEvent)="removeModal()"
></fun-register-person>


<fun-register-company
#registerCompany
*ngIf="step === 1 && method === 'company' && !registerLoading"
[info]="companyInfo"
[list]="registerCompanySteps"
[profile]="profile"
[registerStep]="registerStep"
[towns]="towns"
[onlyAccredit]="onlyAccredit"
[specificPartner]="specificPartner"
(changeWayEvent)="changeWay()"
(prevStepEvent)="reset()"
(finishRegisterEvent)="finishRegister($event)"
(phoneNumberEvent)="getPhoneNumber($event)"
(confirmPhoneEvent)="confirmPhone()"
(confirmCodeEvent)="confitmCode($event, 'phone')"
(confirmCodeEmailEvent)="confitmCode($event, 'email')"
(resendCodeEvent)="resendCode($event)"
(validateEmailEvent)="validateEmail($event)"
(changeEmailEvent)="changeEmail($event)"
(updateCompanyAddressEvent)="updateCompanyAddressInfo($event)"
(updateConstitutionDocumentEvent)="updateConstitutionDocument($event)"
(updateRealOwnershipDocumentEvent)="updateRealOwnershipDocument($event)"
(updateModel200DocumentEvent)="updateModel200Document($event)"
(updateIdentityPartnersDocumentsEvent)="updateIdentityPartnersDocuments($event)"
(updateRLDataEvent)="updateRLData($event)"
(updateRLNationalityEvent)="updateRLNationality($event)"
(updateRLDocTypeEvent)="updateRLDocType($event)"
(updateRLIdentityDocumentEvent)="updateRLIdentityDoc($event)"
(updateProofAddressDocumentEvent)="updateProofAddressDoc($event)"
(updateAcreditationCompanyChecksEvent)="updateAcreditationCompanyChecks($event)"
(updateAcreditationCompanyDocEvent)="updateAcreditationCompanyDoc($event)"
(acceptLWTermsEvent)="acceptLWTerms($event)"
(finishEvent)="finishProccess()"
(signaturitAccreditEvent)="getSignaturitUrl()"
(continueInvestEvent)="continueInvest()"
(removeModalEvent)="removeModal()"
(profilegeneratedEvent)="companyProfileGenerated()"
></fun-register-company>

<div *ngIf="registerLoading">
    <fun-skeleton-list></fun-skeleton-list>
</div>
