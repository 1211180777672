import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'fun-help-mobile',
  templateUrl: './help-mobile.component.html',
  styleUrls: ['./help-mobile.component.scss']
})
export class HelpMobileComponent implements OnInit {

  @Input() public hasSecondLevel;
  @Input() public text;
  @Input() public setLavel;
  @Input() public setIndex;
  @Input() public fullData;
  @Output() public changeS = new EventEmitter<any>();
  currentIndex: any;
  currentData: any;
  currentLevel: any;
  firstEl: any;
  secondEl: any;
  currentEl: any;

  constructor(private chdRef: ChangeDetectorRef, private translate: TranslateService) { }

  ngOnInit() {
    this.currentIndex = this.setIndex;
    this.currentLevel = this.setLavel;
    this.controlLevel();
    this.chdRef.detectChanges();
  }
  public setFirstLevel() {
    this.currentIndex = 0;
    this.currentLevel = 'first';
    this.currentEl = this.fullData.filter(item => item.level === 'first');
    this.chdRef.detectChanges();
  }
  controlLevel() {
    if (this.hasSecondLevel) {
      this.firstEl = this.fullData.filter(item => item.level === 'first');
      this.secondEl = this.fullData.filter(item => item.level === 'second');
      if (this.setLavel === 'second') {
        const el = this.secondEl.filter(item => item.index === this.setIndex);
        this.currentEl = el[0].text;
      } else {
        this.currentEl = this.firstEl;
      }
    }
    this.chdRef.detectChanges();
  }
  changeLevel(index) {
    this.currentIndex = index;
    this.currentLevel = 'second';
    const el = this.secondEl.filter(item => item.index === this.currentIndex);
    this.currentEl = el[0].text;
    this.changeS.emit(index);
    this.chdRef.detectChanges();
  }
  goToHelpCenter() {
    const url = this.translate.instant('help.url');
    window.open(url);
  }

}
