<div [id]="idTitle" class="general-container">
    <img [ngClass]="{'notF': image === 5}" *ngIf="image && image !== undefined && imgUrl !== undefined" [src]="imgUrl" alt="image">
    <div class="text [&>p]:font-sans [&>p]:text-xl [&>h2]:font-sans [&>h2]:text-h600 [&>h2]:md:text-h800" [ngClass]="{'no_padding': removePadding === true}" [innerHTML]="text"></div>
    <div *ngIf="check !== undefined" class="check-container">
      <p (click)="selectElement()" [ngClass]="{'check': !selected, 'checked': selected }">{{ check }}</p>
    </div>
    <div *ngIf="refCode !== undefined">
        <div class="referral_container">
            <div class="copy-container">
                <div [ngClass]="{ copied: isCopied }" class="copy" (click)="copyCode(refCode)">
                  <p>{{ refCode }}</p>
                  <img *ngIf="!isCopied" src="../../../assets/images/referral/copy.svg " alt="copy icon" />
                  <img *ngIf="isCopied" src="../../../assets/images/referral/copy-green.svg " alt="copy icon" />
                </div>
              </div>
              <div class="social-container">
                <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
                  <fun-icon (click)="shareWatsapp()" [name]="'whatsapp'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
                </div>
                <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
                  <fun-icon (click)="shareFacebook()" [name]="'facebook'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
                </div>
                <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
                  <fun-icon (click)="shareTwitter()" [name]="'twitter'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
                </div>
                <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social pr-12 cursor-pointer">
                  <fun-icon (click)="shareLinkedin()" [name]="'linkedin'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
                </div>
                <div class="bg-eggplant-100/[.4] rounded-[50%] p-8 md:p-12 social cursor-pointer">
                  <fun-icon (click)="shareMail()" [name]="'share'" [style]="'stroke-2 stroke-white w-[24px] h-[24px] md:w-[32px] md:h-[32px]'"></fun-icon>
                </div>
              </div>
        </div>
    </div>
    <div *ngIf="btnList && btnList!== undefined && btnList.length > 0" class="btn-container gap-16">
        <fun-new-button *ngFor="let btn of btnList" [buttonType]="btn.type"  [size]="'xl'" [id]="btn.id" [title]="btn.title" (eventClick)="emitClick(btn.index)" [classExtra]="' !w-full '"></fun-new-button>
    </div>
    <div class="btn-text" [innerHTML]="btnText" *ngIf="btnText !== undefined" (click)="btnTxtClicked()"></div>
</div>
