import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NationalityService } from 'src/app/services/nationality.service';
import { NationModel } from '../../register-person/register-person-natinality/nation.model';

@Component({
  selector: 'fun-register-company-rlnationality',
  templateUrl: './register-company-rlnationality.component.html',
  styleUrls: ['./register-company-rlnationality.component.scss']
})
export class RegisterCompanyRlnationalityComponent implements OnInit, AfterViewInit {

  @Output() public sendInfoEvent = new EventEmitter<any>();
  @Output() public sendSelectedEvent = new EventEmitter<any>();
  @Output() public sendErrorEvent = new EventEmitter<any>();
  @Input() public data;
  @Input() isMobile: boolean;
  @Input() myProfile = false;

  public RLDocForm: FormGroup;
  public formControlKeys = [];
  public errorMessages = {};
  public isSubmit = false;
  public errorAmount = false;
  public errorConcept = false;

  selectIsOpen = false;

  nationList = [];

  residence: string;
  nationality: string;

  hasResidence = true;
  hasNationality = true;
  countryListCharged = false;

  constructor(
    public chdRef: ChangeDetectorRef,
    public translate: TranslateService,
    private nationalityService: NationalityService
  ) { }

/*   @HostListener('document:click', ['$event']) onClickHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
  @HostListener('document:keyup', ['$event']) onKeyupHandler(event: KeyboardEvent) {
    this.getInfo();
    this.chdRef.detectChanges();
  }
 */
  ngOnInit() {
    this.getCountryList();
    this.RLDocForm = new FormGroup({
      nationalityRepresentative: new FormControl(this.data.nationalityRepresentative ? this.data.nationalityRepresentative : (this.myProfile ? null : 108), [Validators.required, Validators.min(0.01)]),
      countryRepresentative: new FormControl(this.data.countryRepresentative ? this.data.countryRepresentative : (this.myProfile ? null : 108), [Validators.required, Validators.min(0.01)])
    });
    this.formControlKeys = Object.keys(this.RLDocForm.controls);
    this.errorMessages = {
      required:  this.translate.instant('input-errors-messages.required'),
      pattern: this.translate.instant('input-errors-messages.format'),
    };
    this.getInfo();
    if (this.data.nationalityRepresentative && this.data.nationalityRepresentative !== undefined) {
      this.nationality = this.data.nationalityRepresentative;
    } else {
      this.nationality = '0';
      this.hasNationality = false;
    }
    if (this.data.countryRepresentative && this.data.countryRepresentative !== undefined) {
      this.residence = this.data.countryRepresentative;
    } else {
      this.residence = '0';
      this.hasResidence = false;
    }
    this.chdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.countrySelected();
    this.chdRef.detectChanges();
  }

  getCountryList() {
    this.nationalityService.getNations().subscribe(
      (response) => {
        let nations: NationModel[] = response;
        for (let nation of nations) {
          if (nation.code === 108) {
            this.nationList.unshift({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          } else {
            this.nationList.push({
              id: nation.code,
              name: nation.name,
              flagUrl: `https://flagcdn.com/${nation.isoAlpha2.toLowerCase()}.svg`
            });
          }
        }
        this.countryListCharged = true;
        this.chdRef.detectChanges();
      }
    );
    /* this.translate.get('COUNTRY').subscribe(resp => {
      const num = Object.keys(resp).length;
      for (let i = 0; i < num; i++) {
        const code = Object.keys(resp)[i];
        this.translate.get('COUNTRY.' + code).subscribe(response => {
          const country = response;
          const countryList = {
            id: Number(code),
            name: country
          };
          if (code === '108') {
            this.nationList.unshift(countryList);
          } else {
            this.nationList.push(countryList);
          }
        });
      }
    });
    const spain = this.nationList.find((element) => element.id === 108);
    this.nationList.splice(0, 1);
    this.nationList.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      if (a.name > b.name) { return 1; }
      return 0;
    });
    this.nationList.unshift(spain); */
  }

  getInfo() {
    if (this.RLDocForm.valid) {
      this.sendInfoEvent.emit(this.RLDocForm.value);
    } else {
      this.sendErrorEvent.emit();
    }
    this.countrySelected();
    this.chdRef.detectChanges();
  }

  countrySelected() {
    let nat;
    let res;
    if (!this.RLDocForm.value.nationalityRepresentative || this.RLDocForm.value.nationalityRepresentative === undefined || this.RLDocForm.value.nationalityRepresentative === '') {
      nat = String(this.nationality);
    } else {
      nat = this.RLDocForm.value.nationalityRepresentative;
    }

    if (!this.RLDocForm.value.countryRepresentative || this.RLDocForm.value.countryRepresentative === undefined || this.RLDocForm.value.countryRepresentative === '') {
      res = String(this.residence);
      this.hasResidence = true;
    } else {
      res = this.RLDocForm.value.countryRepresentative;
    }

    this.hasNationality = (nat !== '0') ? true : false;
    this.hasResidence = (res !== '0') ? true : false;
    const value = {
      nationalityRepresentative: nat,
      countryRepresentative: res
    };
    this.sendSelectedEvent.emit(value);
    this.chdRef.detectChanges();
  }

}
