import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Storage } from '@ionic/storage';
import * as amplitude from '@amplitude/analytics-browser';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(private storage: Storage, private utils: UtilsService) { }

  async initamplitude() {
    //Tengo que mirar el tema tracking
    if (!this.utils.checkEmptyField(environment.amplitudeToken)) {  
      amplitude.init(environment.amplitudeToken);
    }
  }

  async trackLogin(username) {
    amplitude.setUserId(username);
    amplitude.track('login', {});
  }

  async trackEvent(name, props) {
    //Tengo que mirar el tema tracking
    if (!this.utils.checkEmptyField(environment.amplitudeToken)) {
      this.setCustomProps(props).then(val => {
        const eventProperties = val;
        amplitude.track(name, eventProperties);
      });
    }
  }

  async setCustomProps(props) {
    // props['fun_platform'] = Capacitor.getPlatform() === 'web' ? `app-${Capacitor.getPlatform()}` : Capacitor.getPlatform();
    /* if (Capacitor.getPlatform() !== 'web') {
      await this.getDeviceInfo().then(data => {
        props['fun_version'] = data.version;
      });
    }
    if (this.utils.checkEmptyField(props['username'])) {
      await this.storage.get('USERNAME').then(val => {
        if (!this.utils.checkEmptyField(val)) {
          props['username'] = val;
        }
      });
    } */
    return props;
  }

  async getDeviceInfo() {
    return await App.getInfo();
  }
}
