import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor,  } from '@capacitor/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, flatMap, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppConfigurationService } from '../services/app.configuration.service';
import { AuthService } from '../services/auth.service';
import { App } from '@capacitor/app';
import { LogService } from '../services/log.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  queuedRequest = [];
  appVersion;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);



  constructor(private authService: AuthService, private appConfigurationService: AppConfigurationService, private logService: LogService) {}

  kickOut() {
    this.authService.logout();
  }

  isApiRequest(url: string) {
    return this.appConfigurationService.didFinishLoad() && url.startsWith(this.appConfigurationService.get('domain'));
  }

  isConfigRequest(url: string) {
    return url.startsWith(environment.configFilePath);
  }

  isLoginRequest(url: string) {
    return this.appConfigurationService.didFinishLoad() && url.startsWith(this.appConfigurationService.get('domain')) && url.endsWith('apisecurity/auth/v1/login');
  }

  isRefreshRequest(url: string) {
    return this.appConfigurationService.didFinishLoad() && url.indexOf('apisecurity/auth/v1/refresh') !== -1;
  }
  async getDeviceInfo() {
    return await App.getInfo();
  }

  getAppBuildVersion() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.getDeviceInfo().then(data => {
                this.appVersion = Capacitor.getPlatform() + ' ' + data.build + ' ' + data.version;
              })

    if(this.appVersion && this.appVersion !=='' && !req.url.startsWith('https://fundeen-backend-strapi-c818688d6fc7.herokuapp.com')){
      req = req.clone({ headers: req.headers.set('X-Fundeen-Version', this.appVersion ) })
    }

    if (this.isRefreshRequest(req.url)) {
      this.refreshTokenSubject.next(null);
      const copiedReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.authService.authData.refreshToken } });

      return next.handle(copiedReq).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            if (this.authService.getMobileTokenexpired()) {
              this.kickOut();
            } else {
              this.refreshTokenSubject.next(null);
              const copiedReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.authService.authData.mobileToken } });

              return next.handle(copiedReq).pipe(
                catchError((err: HttpErrorResponse) => {
                  this.logService.throwError(err);
                  return throwError(err);
                })
              );
            }
          }
          this.logService.throwError(err);
          return throwError(err);
        })
      );
    } else if (this.isConfigRequest(req.url) || this.isLoginRequest(req.url)) {
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          this.logService.throwError(err);
          return throwError(err);
        })
      );
    } else if (this.isApiRequest(req.url)) {
      const headers: HttpHeaders = req.headers;
      let copiedReq = req.clone();
      if (this.authService.hasToRefresh()) {
        if (this.authService.requestRefreshTokenInProcess) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            switchMap((result) => {
              copiedReq = copiedReq.clone({ headers: copiedReq.headers.set('Authorization', 'Bearer ' + result.access_token) });
              return next.handle(copiedReq).pipe(
                catchError((err: HttpErrorResponse) => {
                  this.logService.throwError(err);
                  return throwError(err);
                })
              );
            }));
        } else {
          if (!this.authService.isRefreshExpired()) {
            return this.authService.refreshTokens().pipe(

              flatMap((data) => {
                this.refreshTokenSubject.next(data);

                copiedReq = copiedReq.clone({ headers: req.headers.set('Authorization', 'Bearer ' + data.access_token) });

                return next.handle(copiedReq).pipe(
                  catchError((err: HttpErrorResponse) => {
                    if (err.status === 401) {
                      this.kickOut();
                    }
                    this.logService.throwError(err);
                    return throwError(err);
                  })
                );
              }),
              catchError((e) => {
                if (this.authService.getMobileTokenexpired()) {
                  this.kickOut();
                }
                this.logService.throwError(e);
                return throwError(e);
              })
            );
          } else if(!this.authService.isMobileExpired()) {
            this.refreshTokenSubject.next(null);
            const copiedReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.authService.authData.mobileToken } });

            return next.handle(copiedReq).pipe(
              catchError((err: HttpErrorResponse) => {
                if (err.status === 401 || err.status === 403) {
                  //this.kickOut();
                }
                this.logService.throwError(err);
                return throwError(err);
              })
            );
          }
        }
      }

      if (this.authService.authData) {
        copiedReq = copiedReq.clone({
          headers: copiedReq.headers.set('Authorization', 'Bearer ' + this.authService.authData.accessToken),
        });
      }

      if (!this.authService.requestRefreshTokenInProcess) {
        return next.handle(copiedReq).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
              this.kickOut();
            }
            this.logService.throwError(err);
            return throwError(err);
          })
        );
      }

      if (this.authService.isRefreshExpired() && this.authService.isMobile) {
        this.refreshTokenSubject.next(null);
        const copiedReq = req.clone({ setHeaders: { Authorization: 'Bearer ' + this.authService.authData.mobileToken } });

        return next.handle(copiedReq).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 || err.status === 403) {
              //this.kickOut();
            }
            this.logService.throwError(err);
            return throwError(err);
          })
        );
      }
    } else {
      return next.handle(req);
    }
  }
}
