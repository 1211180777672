<nav>
    <div class="static">
      <p (click)="selected('/portfolio')" [ngClass]="{ active: page === 'dashboard' }">
        <a>Portfolio</a>
      </p>
      <p (click)="selected('/updates')" [ngClass]="{ active: page === 'updates' }">
        <a>{{ 'submenu.updates' | translate }}</a>
      </p>
      <p (click)="selected('/documentation')" [ngClass]="{ active: page === 'documentation' }">
        <a>{{ 'overview.doc.documentation' | translate }}</a>
      </p>
      <p (click)="selected('/payment-calendar')" [ngClass]="{ active: page === 'payment-calendar' }">
        <a>{{ 'submenu.payment-calendar' | translate }}</a>
      </p>
    </div>

    <div class="slider">
      <ion-slides [options]="slideOpts" #slider>
          <ion-slide>
            <p (click)="selected('/portfolio')" [ngClass]="{ active: page === 'dashboard' }">
              <a>{{ 'submenu.portfolio' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/updates')" [ngClass]="{ active: page === 'updates' }">
              <a>{{ 'submenu.updates' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/documentation')" [ngClass]="{ active: page === 'documentation' }">
              <a>{{ 'overview.doc.documentation' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p (click)="selected('/payment-calendar')" [ngClass]="{ active: page === 'payment-calendar'}">
              <a>{{ 'submenu.payment-calendar' | translate }}</a>
            </p>
          </ion-slide>
          <ion-slide>
            <p>
              <a></a>
            </p>
          </ion-slide>
      </ion-slides>
    </div>
  </nav>
