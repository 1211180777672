<div class="general-container">
  <!--<app-graphics-nav [page]="'production'" initial="3"></app-graphics-nav>-->
  <nav>
    <ion-grid>
      <ion-row>
        <ion-col size="2" class="clicked" (click)="getMoment('day')" [ngClass]="{ clicked: moment === 'day' || moment === '' }">
          <p>{{ 'DASHBOARD.PRODUCTION.TODAY' | translate }}</p>
        </ion-col>
        <ion-col size="4" (click)="getMoment('week')" [ngClass]="{ clicked: moment === 'week' }">
          <p>{{ 'DASHBOARD.PRODUCTION.WEEK' | translate }}</p>
        </ion-col>
        <ion-col size="3" (click)="getMoment('month')" [ngClass]="{ clicked: moment === 'month' }">
          <p>{{ 'DASHBOARD.PRODUCTION.MONTH' | translate }}</p>
        </ion-col>
        <ion-col size="3" (click)="getMoment('year')" [ngClass]="{ clicked: moment === 'year' }">
          <p>{{ 'DASHBOARD.PRODUCTION.YEAR' | translate }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </nav>
  <div #faddingContainer>
    <div class="full-graphic">
      <div class="graphic production">
        <h3>{{ 'DASHBOARD.PRODUCTION.PRODUCTION' | translate }}</h3>
        <h2>{{ production | number: '1.0-2':'es' }} kWh</h2>
        <p>{{ productionestimated | number: '1.0-2':'es' }} kWh</p>
        <div class="bar-container">
          <div class="bar" [ngStyle]="{ width: productionBar }"></div>
          <div class="estimated" [ngStyle]="{ 'margin-left': productionEstimatedWidth }">
            <span>{{ productionUntilNow | number: '1.0-2':'es' }} kWh</span>
          </div>
        </div>
      </div>

      <div class="graphic billing">
        <h3>{{ 'DASHBOARD.PRODUCTION.INVOICING' | translate }}</h3>
        <h2>{{ money | number: '1.0-2':'es' }} €</h2>
        <p>{{ moneyEstimated | number: '1.0-2':'es' }} €</p>
        <div class="bar-container">
          <div class="bar" [ngStyle]="{ width: billingBar }"></div>
          <div class="estimated" [ngStyle]="{ 'margin-left': billingEstimatedWidth }">
            <span>{{ moneyUntilNow | number: '1.0-2':'es' }} €</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
