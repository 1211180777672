<div class="genearl-container flex flex-col h-full justify-between">
  <div *ngIf="!loading">
    <!---->
    <div *ngIf="step === 0">
      <h2 class="font-sans text-eggplant-100 text-h500 md:text-h700 bg-gradient-2-text py-32">{{ 'REGISTER.CLAVE_PIN_TITLE' | translate }}</h2>
      <p class="font-sans text-eggplant-100/[0.55] text-l">Te ofrecemos una forma sencilla... </p>
      <div>
        <div class="question cursor-pointer mr-16 mb-16 w-full flex gap-32 justify-between" (click)="selectOption(1)"
        [ngClass]="{'option-selected': option === 1, 'option': option !== 1}">
          <div>
            <p class="px-24 py-4 font-sans text-xl text-eggplant-80 mt-12">Clave Pin</p>
            <h4 class="px-24 py-4 font-sans text-l text-gray-40">Texto que te dice las ventajas de Clave Pin.</h4>
          </div>
          <div class="flex items-center justify-center pr-24 gap-12 flex-col md:flex-row">
            <img class="md:w-[80px] max-w-[80px]" src="./assets/logos/logo-clave.svg">
            <img class="md:w-[80px] max-w-[80px]" src="./assets/logos/logo-taxdown.svg">
          </div>
        </div>
        <div class="question cursor-pointer mr-16 w-full flex gap-32 justify-between" (click)="selectOption(2)"
        [ngClass]="{'option-selected': option === 2, 'option': option !== 2}">
          <div>
            <p class="px-24 py-4 font-sans text-xl text-eggplant-80 mt-12">Registro manual</p>
            <h4 class="px-24 py-4 font-sans text-l text-gray-40">Texto que te explica que lo haces manual</h4>
          </div>
          <div class="flex items-center justify-center pr-24 gap-6">
            <img class="w-[80px]" src="./assets/images/invest/fundeen-payment.svg">
           </div>
        </div>
      </div>
      <div *ngIf="option === 1" >
        <div class="checkbox_content w-full flex gap-12 content-center pt-24 md:pt-48">
          <div class="checkbox w-16 h-16" [ngClass]="{ checked: acceptedTerms }" (click)="checkAction('terms')"></div>
          <p innerHTML="{{ 'REGISTER.TAXDOWN_TERMS' | translate }}"></p>
        </div>
      </div>
    </div>
    <!---->
    <div *ngIf="step === 1">
      <h2 class="font-sans text-eggplant-100 text-h500 md:text-h700 bg-gradient-2-text py-32">Registro con Clave Pin</h2>
      <p class="font-sans text-eggplant-100/[0.55] text-l text-center md:text-left">Te ofrecemos una forma sencilla de obtener tus datos a través de Clave Pin</p>
      <div class="pb-24">
        <fun-input
          (selectChange)="controlForm()"
          placeholder="{{ 'LOCAL_INNVEST.STEP_3.DOC_TYPE' | translate }}"
          [parentForm]="taxdownForm"
          [formControlKeys]="formControlKeys"
          [errorMessages]="errorMessages"
          [isSubmit]="isSubmit"
          controlName="identity_type"
          type="select"
          [errorLogin]="errorAmount"
          labelName="{{ 'LOCAL_INNVEST.STEP_3.DOC_TYPE' | translate }}"
          [items]="documentList"
          [selectedItem]="'DNI'"
        ></fun-input>
      </div>
      <div class="pb-24">
        <fun-input
          (keyup)="controlForm()"
          placeholder="{{ 'REGISTER.DOC_NUMBER_CLAVE_PIN' | translate }}"
          [parentForm]="taxdownForm"
          [formControlKeys]="formControlKeys"
          [errorMessages]="errorMessages"
          [isSubmit]="isSubmit"
          controlName="docNumber"
          type="text"
          [errorLogin]="errorAmount"
          labelName="{{ 'REGISTER.DOC_NUMBER_CLAVE_PIN' | translate }}"
        ></fun-input>
      </div>
      <div class="pb-24" *ngIf="docType === 'NIE'">
        <fun-input
          (keyup)="controlForm()"
          placeholder="{{ 'REGISTER.NIE_SUP_NUMB' | translate }}"
          [parentForm]="taxdownForm"
          [formControlKeys]="formControlKeys"
          [errorMessages]="errorMessages"
          [isSubmit]="isSubmit"
          controlName="supportNumber"
          type="text"
          [errorLogin]="errorAmount"
          labelName="{{ 'REGISTER.NIE_SUP_NUMB' | translate }}"
        ></fun-input>
      </div>
      <div class="pb-24">
        <p class="font-sans text-success text-l text-center md:text-left">{{ 'REGISTER.VALID_DATA_ID' | translate }}</p>
        <fun-input
          (keyup)="controlForm()"
          placeholder="{{ 'REGISTER.VALID_DATA_ID_2' | translate }}"
          [parentForm]="taxdownForm"
          [formControlKeys]="formControlKeys"
          [errorMessages]="errorMessages"
          [isSubmit]="isSubmit"
          controlName="validationDate"
          type="date"
          [errorLogin]="errorAmount"
          labelName="{{ 'REGISTER.VALID_DATA_ID_2' | translate }}"
          [checkIsAdult]="false"
        ></fun-input>
      </div>
      <div class="pb-24">
        <fun-input
          (selectChange)="controlForm()"
          placeholder="{{'REGISTER.METHOD' | translate}}"
          [parentForm]="taxdownForm"
          [formControlKeys]="formControlKeys"
          [errorMessages]="errorMessages"
          [isSubmit]="isSubmit"
          controlName="challengeMethod"
          type="select"
          [errorLogin]="errorAmount"
          labelName="{{'REGISTER.METHOD' | translate}}"
          [items]="challengeList"
          [selectedItem]="'SMS'"
        ></fun-input>
      </div>
    </div>
    <!---->
    <div *ngIf="step === 2">
      <h2 class="font-sans text-eggplant-100 text-h500 md:text-h700 bg-gradient-2-text py-32">Registro con Clave Pin</h2>
      <p class="font-sans text-eggplant-100/[0.55] text-l">
        Introduce el código que se te ha proporcionado para poder continuar con el proceso.
        Ten en cuenta que las letras deben ir en mayúscula y que el codigo puede tardar unos minutos en llegar.
        Si quieres cambiar la forma de obtener el código, haz click <span (click)="prevStep()" class="cursor-pointer text-orange-100">aquí</span>.
      </p>
      <div class="py-[80px]">
        <fun-register-taxdown-code (sendValueEvent)="getPinCode($event)"></fun-register-taxdown-code>
      </div>
    </div>
    <!---->
    <div *ngIf="step === 3">
      <h2 class="font-sans text-eggplant-100 text-h500 md:text-h700 bg-gradient-2-text py-32">Registro con Clave Pin</h2>
      <p class="font-sans text-eggplant-100/[0.55] text-l">Proceso realizado correctamente, rellenaremos los datos por ti de forma automática, pero puedes modificarlos si lo deseas.</p>
      <p class="font-sans text-eggplant-100/[0.55] text-l">Estos son los datos proporcionados:</p>
      <div class="px-12 md:px-72">
        <p class="text-l text-eggplant-55">{{ 'REGISTER.MY_PROFILE.NAME' | translate }}: <span class="text-eggplant-100">{{ userData.name }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'REGISTER.MY_PROFILE.SURNAME' | translate }}: <span class="text-eggplant-100">{{ userData.surname }} {{ userData.secondSurname }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'REGISTER.MY_PROFILE.B_DATE' | translate }}: <span class="text-eggplant-100">{{ userData.birthDate | date:'dd/MM/yyyy' }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'REGISTER.MY_PROFILE.ADDRESS_3' | translate }}:
          <span class="text-eggplant-100">{{ userData.streetType }} {{ userData.streetName }} {{ userData.streetNumber }}, {{ userData.postalCode }} {{ userData.town }}, {{ userData.province }}</span>
        </p>
        <p class="text-l text-eggplant-55">{{ 'REGISTER.IDENTITY_DOCUMENT' | translate }}: <span class="text-eggplant-100">{{ userData.nif }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'REGISTER.MY_PROFILE.PHONE' | translate }}: <span class="text-eggplant-100">{{ userData.phone }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'register.identity.nationality' | translate }}: <span class="text-eggplant-100">{{ userData.country }}</span></p>
        <p class="text-l text-eggplant-55">{{ 'EXPERIENCED_INVESTOR.GROSS_SALARY' | translate }}: <span class="text-eggplant-100">{{ userData.grossSalary | localeNumber }}€</span></p>
        <p *ngIf="modelUserData.civilStatus" class="text-l text-eggplant-55">{{ 'register.civil_status.title' | translate }}:
          <span class="text-eggplant-100">{{ modelUserData.civilStatus == 1 ? 'Soltero/a' : modelUserData.civilStatus == 2 ? 'Casado/a' : '-' }}</span>
        </p>
      </div>
      <p class="font-sans text-eggplant-100 text-2xl text-center pt-32">{{ 'REGISTER.INFO_CORRECT' | translate }}</p>
      <div class="w-full flex gap-12 py-16 items-center justify-center">
        <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="next()" class="next-btn"
        [buttonType]="'secondaryDark'" title="{{ 'REGISTER.MODIFY_INFO_BTN' | translate }}" ></fun-new-button>
        <fun-new-button [classExtra]="' !w-full '" size="xl" (eventClick)="gerateProfileAndUpdateData()" class="next-btn"
        [buttonType]="'primaryDark'" title="{{ 'REGISTER.INFO_OK_BTN' | translate }}" ></fun-new-button>
      </div>
    </div>
    <!---->
    <fun-overal-message *ngIf="step === 4" [text]="overalText" [image]="overalImage" [btnList]="btnList" ></fun-overal-message>
    <!---->
    <div *ngIf="step === 5">
      <div *ngIf="!hasNationality">
        <div class="header">
          <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.nationality.nationality' | translate }}</h2>
          <p class="font-sans text-center text-xl text-gray-40">{{ 'register.nationality.txt' | translate }}</p>
        </div>
        <fun-register-person-natinality
        [data]="profile"
        [myProfile]="true"
        (sendInfoEvent)="getNationalityInfo($event)"
        ></fun-register-person-natinality>
      </div>

      <div *ngIf="hasNationality">
        <div class="header">
          <h2 class="font-sans text-h600 md:text-h800 text-eggplant-100 pb-[18px]">{{ 'register.identity.address' | translate }}</h2>
          <p class="font-sans text-center text-xl text-gray-40">{{ 'register.identity.address_txt' | translate }}</p>
        </div>
        <fun-register-person-address
        [data]="profile"
        [profile]="profile"
        [towns]="towns"
        (sendInfoEvent)="getAddressInfo($event)"
        ></fun-register-person-address>
      </div>
    </div>
    <!---->
    <div *ngIf="step === 6">
      <fun-register-file
        #registerPersonFile
        [stepList]="specificFileSteps"
        [info]="profile"
        [isRegister]="true"
        [canGoBack]="false"
        (documentEvent)="getDocuments($event)"
        (lastStepEvent)="lastStepPerson()"
        (finishRegisterEvent)="finishRegister()"
        (updateDocumentEvent)="emitDocs($event)"
        (updateNumberEvent)="updatePassportNumber($event)"
        #registerDocument
      ></fun-register-file>
    </div>
    <!---->
    <fun-overal-message *ngIf="step === 7" [text]="textFinish" [image]="imageFinish" [btnList]="btnListFinish" (btnEvent)="finish()"></fun-overal-message>
    <!---->
  </div>
  <div *ngIf="loading"><fun-spinner></fun-spinner></div>
  <div *ngIf="!loading && step !== 3 && step !== 7 && step !== 6" class="py-32 md:py-48 flex justify-center content-center">
    <fun-new-button [classExtra]="' !w-full md:max-w-[320px] '" size="xl" (eventClick)="next()" class="next-btn"
    [buttonType]="sendXHR" title="{{ 'register.btn.continue' | translate }}"  icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
  </div>
</div>