import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  Input,
  TemplateRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener} from '@angular/core';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { AppRoutes, MarketPlaceContactModel, MarketPlaceMyOffersModel, StateOffer, StatesOffer } from 'src/app/shared/models/marketplace.models';
import { IFileButton } from 'src/app/shared/models/file.models';
import { ToastService } from '../../toast/toast.service';
import { HelpService } from 'src/app/services/help.service';
import { AlertService } from '../../alert/alert.service';
import { DateService } from 'src/app/services/date.service';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { WarningComponent } from '../../warning/warning.component';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'fun-offer-status',
  templateUrl: './offer-status.component.html',
  styleUrls: ['./offer-status.component.scss']
})
export class OfferStatusComponent implements OnInit, AfterViewInit {

  private _offerData: MarketPlaceMyOffersModel = null;
  contactData: MarketPlaceContactModel;
  storageSellerName = false;
  sName: string;

  get offerData(): MarketPlaceMyOffersModel {
    return this._offerData;
  }

  @Input('offerData')
  set offerData(value: MarketPlaceMyOffersModel) {
    if (value.closing_date !== undefined) {
      value.closing_date = this.dateService.getDateMs(value.closing_date);
    }
    if (value.date !== undefined) {
      value.date = this.dateService.getDateMs(value.date);
    }
    if (value.decision_due_date !== undefined) {
      value.decision_due_date = this.dateService.getDateMs(value.decision_due_date);
    }
    if (value.contract_seller_date !== undefined) {
      value.contract_seller_date = this.dateService.getDateMs(value.contract_seller_date);
    }
    if (value.external_waiting_documentation_due_date !== undefined) {
      value.external_waiting_documentation_due_date = this.dateService.getDateMs(value.external_waiting_documentation_due_date);
    }
    if (value.ko_date !== undefined) {
      value.ko_date = this.dateService.getDateMs(value.ko_date);
    }
    if (value.internal_waiting_sign_due_date !== undefined) {
      value.internal_waiting_sign_due_date = this.dateService.getDateMs(value.internal_waiting_sign_due_date);
    }
    if (value.internal_waiting_payment_due_date !== undefined) {
      value.internal_waiting_payment_due_date = this.dateService.getDateMs(value.internal_waiting_payment_due_date);
    }
    if (value.ok_date !== undefined) {
      value.ok_date = this.dateService.getDateMs(value.ok_date);
    }
    if (value.payment_date !== undefined) {
      value.payment_date = this.dateService.getDateMs(value.payment_date);
    }
    if (value.contract_buyer_date !== undefined) {
      value.contract_buyer_date = this.dateService.getDateMs(value.contract_buyer_date);
    }
    this._offerData = value;
    if (value) {
      this.loadStatus(value);
    }
  }

  @Input() offerContactData: Observable<MarketPlaceContactModel>;
  @Input() fileButtonData: IFileButton;
  @Input() actionUpdateOffer: Observable<MarketPlaceMyOffersModel>;

  @Output() public eventButtonsRoute = new EventEmitter<string>();
  @Output() public eventAcceptBidClick = new EventEmitter<any>();
  @Output() public eventRelaunchOfferClick = new EventEmitter<MarketPlaceMyOffersModel>();
  @Output() public eventRejectBidClick = new EventEmitter<any>();
  @Output() public eventBuyerSign = new EventEmitter<any>();
  @Output() public eventSellerSign = new EventEmitter<any>();
  @Output() public eventNotSellerName = new EventEmitter<any>();
  @Output() public eventUploadDocument = new EventEmitter<any>();


  @ViewChild('activeActive', { static: false }) activeActive: TemplateRef<any>;
  @ViewChild('activePendingAcceptation', { static: false }) activePendingAcceptation: TemplateRef<any>;
  @ViewChild('notSoldNoBids', { static: false }) notSoldNoBids: TemplateRef<any>;
  @ViewChild('notSold', { static: false }) notSold: TemplateRef<any>;
  @ViewChild('internalSoldWaitingBuyerSign', { static: false }) internalSoldWaitingBuyerSign: TemplateRef<any>;
  @ViewChild('internalSoldWaitingSellerSign', { static: false }) internalSoldWaitingSellerSign: TemplateRef<any>;
  @ViewChild('internalNotSoldWaitingSignKo', { static: false }) internalNotSoldWaitingSignKo: TemplateRef<any>;
  @ViewChild('internalSoldWaitingPayment', { static: false }) internalSoldWaitingPayment: TemplateRef<any>;
  @ViewChild('internalSoldOk', { static: false }) internalSoldOk: TemplateRef<any>;
  @ViewChild('internalSoldKo', { static: false }) internalSoldKo: TemplateRef<any>;
  @ViewChild('externalSoldWaitingContractsContact', { static: false }) externalSoldWaitingContractsContact: TemplateRef<any>;
  @ViewChild('externalSoldWaitingContracts', { static: false }) externalSoldWaitingContracts: TemplateRef<any>;
  @ViewChild('externalSoldWaitingContractsKo', { static: false }) externalSoldWaitingContractsKo: TemplateRef<any>;
  @ViewChild('externalSoldValidating', { static: false }) externalSoldValidating: TemplateRef<any>;
  @ViewChild('externalSoldOk', { static: false }) externalSoldOk: TemplateRef<any>;
  @ViewChild('externalSoldKo', { static: false }) externalSoldKo: TemplateRef<any>;


  public statesTmpl: StateOffer[] = [
    { state: 'activeActive' },
    { state: 'activePendingAcceptation' },
    { state: 'notSoldNoBids' },
    { state: 'notSold' },
    { state: 'internalSoldWaitingBuyerSign' },
    { state: 'internalSoldWaitingSellerSign' },
    { state: 'internalNotSoldWaitingSignKo' },
    { state: 'internalSoldWaitingPayment' },
    { state: 'internalSoldOk' },
    { state: 'internalSoldKo' },
    { state: 'externalSoldWaitingContractsContact' },
    { state: 'externalSoldWaitingContracts' },
    { state: 'externalSoldWaitingContractsKo' },
    { state: 'externalSoldValidating' },
    { state: 'externalSoldOk' },
    { state: 'externalSoldKo' },
  ];
  public currentState: StatesOffer;

  elements: any;
  isShow = true;
  selectIsOpen = false;
  stateValue = 1;

  btnAccept: string;
  btnCancel: string;
  btnSecondary: string;

  bidList = [];
  bidSelectedId: any;
  btnAcceptBid: string;
  btnAcceptBidMob: string;
  btnrejectBid: string;
  dotsOpened = false;
  aceptedBid = false;
  substatus: string;
  lostResolutionName: string;
  loadigPostAccept = false;

  constructor(
    private chdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private translate: TranslateService,
    private helpService: HelpService,
    private utils: UtilsService,
    private alertService: AlertService,
    public dateService: DateService,
    private marketplceService: MarketplaceService
  ) {
    registerLocaleData(es);
   }

  ngOnInit() {
    this.btnAccept = 'primaryDark';
    this.btnCancel = 'secondaryDark';
    this.btnSecondary = 'secondaryDark';
    this.btnAcceptBid = 'primaryDark-disabled';
    this.btnAcceptBidMob = 'primaryDark';
    this.btnrejectBid = 'secondaryDark';
    this.offerData.adquisition_price = this.utils.roundNumber(this.offerData.adquisition_price);
    registerLocaleData(es);
    this.chdRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.offerContactData.subscribe((offerContactData: MarketPlaceContactModel) => {
      this.contactData = offerContactData;
      this.chdRef.detectChanges();
    }, error => {
      this.eventNotSellerName.emit();
    });
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (
      event.target.id !== 'dotsD'
    ) {
      this.dotsOpened = false;
    } else {
      if (!this.aceptedBid) {
        this.dotsOpened = true;
      }
    }
    this.chdRef.detectChanges();
  }
  public getCurrentSellerName(name) {
    this.storageSellerName = true;
    this.sName = name;
    this.chdRef.detectChanges();
  }

  selected(el) {
    this.elements.forEach(element => {
      if (el.id === element.nativeElement.id) {
        element.nativeElement.setAttribute('class', 'selected');
      } else {
        element.nativeElement.setAttribute('class', 'link');
      }
    });
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
    this.chdRef.detectChanges();
  }

  selectOpen() {
    if (window.innerWidth < 992) {
      this.selectIsOpen = !this.selectIsOpen;
      this.chdRef.detectChanges();
    }
  }

  nextStep() {
    this.stateValue = this.stateValue + 1;
    this.chdRef.detectChanges();
  }

  prevStep() {
    this.stateValue = this.stateValue - 1;
    this.chdRef.detectChanges();
  }

  onClickChangeState(state: StatesOffer): void {
    this.currentState = state;
    this.chdRef.detectChanges();
  }

  onEventUploadResponse(event: HttpResponse<any> | HttpErrorResponse): void {
    if (event instanceof HttpErrorResponse) {
      const toastText: any = this.translate.instant('toast-messages.upload-fail');
      this.toastService.showToast(toastText, 'warning');
    }
    if (event instanceof HttpResponse) {
      if (event.status === 200) {

        this.actionUpdateOffer.subscribe({
          next: offerDataResp => {
            this._offerData = offerDataResp;
            this._offerData.contract_seller_date = this.dateService.getDateMs(this._offerData.contract_seller_date);
            this._offerData.external_waiting_documentation_due_date =
            this.dateService.getDateMs(this._offerData.external_waiting_documentation_due_date);
            this._offerData.date = this.dateService.getDateMs(this._offerData.date);
            this._offerData.decision_due_date = this.dateService.getDateMs(this._offerData.decision_due_date);
            this._offerData.closing_date = this.dateService.getDateMs(this._offerData.closing_date);
            this.currentState = 'externalSoldValidating';
            this.eventUploadDocument.emit();
            this.chdRef.detectChanges();
          },
          error: error => console.error(error)
        });
      }
    }
  }

  onEventAcceptBidClick(offer: MarketPlaceMyOffersModel, id) {
    let offerData;
    offer.bids.forEach(element => {
      if (element.id === id) {
        offerData = element;
      }
    });
    const modalParams = {
      place: 'accept-bid',
      data: offerData
     };
    this.alertService.showModal(WarningComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('warning.accept-title').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.getChildComponent().subscribe((componentRef: WarningComponent) => {
          componentRef.continueClick.subscribe((res) => {
            this.aceptedBid = true;
            this.btnAcceptBid = 'primaryDark-disabled';
            this.btnAccept = 'primaryDark-disabled';
            this.btnCancel = 'secondaryDark-disabled';
            this.btnAcceptBidMob = 'primaryDark';
            this.btnrejectBid = 'secondaryDark-disabled';
            const obj = {
              offerData: offer,
              idBid: id
            };
            this.eventAcceptBidClick.emit(obj);
            this.alertService.removeModal();
            this.loadigPostAccept = true;
            this.chdRef.detectChanges();
          });
        });
        modal.onClose().subscribe();
      }
    );
    this.chdRef.detectChanges();
  }

  onEventRejectBidClick(offer: MarketPlaceMyOffersModel, id?: any) {
    this.btnAccept = 'primaryDark-disabled';
    this.btnCancel = 'secondaryDark-disabled';
    const modalParams = {
      place: 'offer-status',
      txtOffer: id,
     };
    this.alertService.showModal(WarningComponent, modalParams, '', '', true).subscribe(
      (modal: any) => {
        this.translate.get('warning.mkp-title').subscribe((title) => {
          modal.setTitle(title);
        });
        modal.getChildComponent().subscribe((componentRef: WarningComponent) => {
          componentRef.continueClick.subscribe((res) => {
            const obj = {
              offerData: offer,
              idBid: id
            };
            this.btnAcceptBid = 'primaryDark-disabled';
            this.btnAcceptBidMob = 'primaryDark-disabled';
            this.btnrejectBid = 'secondaryDark-disabled';
            this.eventRejectBidClick.emit(obj);
            this.alertService.removeModal();
            this.chdRef.detectChanges();
          });
          componentRef.backClick.subscribe((res) => {
            this.btnAccept = 'primaryDark';
            this.btnCancel = 'secondaryDark';
            this.chdRef.detectChanges();
          });
        });
        modal.onClose().subscribe();
      }
    );
    this.chdRef.detectChanges();
  }

  onEventRelaunchOfferClick(offer: MarketPlaceMyOffersModel) {
    this.btnAccept = 'primaryDark-disabled';
    this.eventRelaunchOfferClick.emit(offer);
    this.chdRef.detectChanges();
  }

  onEventClickRoute(route: AppRoutes): void {
    this.btnAccept = 'primaryDark-disabled';
    this.btnSecondary = 'secondaryDark-disabled';
    this.eventButtonsRoute.emit(route);
    this.chdRef.detectChanges();
  }

  onEventClickBuyerSign(): void {
    this.eventBuyerSign.emit();
  }

  onEventClickSellerSign(): void {
    this.eventSellerSign.emit();
  }

  loadStatus(offer: MarketPlaceMyOffersModel) {
    let hasActive = false;
    if (offer.bids !== undefined) {
      this.customBids(offer.bids);
      offer.bids.forEach(element => {
        if (element.status === 'active') {
          hasActive = true;
        }
      });
    }
    if (offer.status === 'pending_acceptation' ||
    (offer.status === 'active' && offer.current_bids !== 0 && hasActive)) {
      this.substatus = offer.status;
    }
    this.currentState = this.marketplceService.getStateOfferStatus(offer, hasActive);
    //
    if (this.currentState === 'notSold') {
      this.lostResolutionName = this.translate.instant('offer-status.cancelled-offer');
    } else if (offer.bids !== undefined && offer.bids.length > 0) {
      this.lostResolutionName = this.translate.instant('offer-status.expired_offer');
    } else {
      this.lostResolutionName = this.translate.instant('offer-status.not_sold_offer');
    }
  }
  openHelp(level, index) {
    this.helpService.openHelp(level, index, 'BID_STATUS');
  }
  customBids(offer) {
    offer.forEach(element => {
      if (element.status === 'active') {
        element.date = this.dateService.getDateMs(element.date);
        const obj = element;
        obj.openMob = false;
        this.bidList.push(obj);
      }
    });
    this.sortListById('asc', this.bidList);
    this.sortListByPrice('desc', this.bidList);
    this.chdRef.detectChanges();
  }
  seletcBid(id) {
    if (!this.aceptedBid) {
      this.bidSelectedId = id;
      this.btnAcceptBid = 'primaryDark';
    }
    this.chdRef.detectChanges();
  }
  openMobInfo(id) {
    this.bidList.forEach(element => {
      if (id === element.id) {
        element.openMob = !element.openMob;
      }
    });
    this.chdRef.detectChanges();
  }
  sortListById(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.id - b.id;
      }
      if (type === 'desc') {
        return b.id - a.id;
      }
    });
    return arr;
  }
  sortListByPrice(type: string, arr) {
    arr.sort(function (a, b) {
      if (type === 'asc') {
        return a.price - b.price;
      }
      if (type === 'desc') {
        return b.price - a.price;
      }
    });
    return arr;
  }
  public bidRejected(id) {
    for (let i = 0; i < this.bidList.length; i++) {
      if (this.bidList[i].id === id) {
        const txt =
        this.translate.instant('offer-status.rejected_offer_text_1') + this.bidList[i].name +
        this.translate.instant('offer-status.rejected_offer_text_2') + this.bidList[i].price +
        this.translate.instant('offer-status.rejected_offer_text_3');
        this.bidList.splice(i, 1);
        this.btnAcceptBid = 'primaryDark-disabled';
        this.btnAcceptBidMob = 'primaryDark-default';
        this.btnrejectBid = 'secondaryDark';
        this.toastService.showToast(txt, 'info', true);
        this.chdRef.detectChanges();
      }
    }
    if (this.bidList.length === 0) {
      this.offerData.best_bid = 0;
      this.currentState = 'activeActive';
      this.btnCancel = 'secondaryDark';
    }
    this.chdRef.detectChanges();
  }

  changeToResendDocumentStatus() {
    this.currentState = 'externalSoldWaitingContracts';
    this.chdRef.detectChanges();
  }

  contactByMail() {
    const subject = 'Subida de documentos en tablón de anuncios';
    const mailText = `mailto:soporte@fundeen.com,bdemiguel@demira.es?subject=${subject}&body=¡Hola! %0DEscribo este mensaje para comunicar que he subido mi contrato de compra al tablón de anuncios.`;
    window.location.href = mailText;
  }
}
