import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root',
})
export class MovementsService {
  constructor(private communicationService: CommunicationService) {}

  getBalance(): Observable<any> {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallet/balance', null);
  }
  getMoves(): Observable<any> {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallets/history', null);
  }
  getAccount(): Observable<any> {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallets/account', null);
  }
  getWalletStatus(): Observable<any> {
    return this.communicationService.get('apiservices/profile/v1/profiles/wallets/status', null);
  }
  getWithdrawFunds(model): Observable<any> {
    return this.communicationService.post('apiservices/profile/v1/profiles/wallets/withdrawFunds', model);
  }
  uploadFile(file: any): Observable<any> {
    return this.communicationService.post('apiservices/userfile/v1/userfiles', file);
  }
  createAccount(model: any): Observable<any> {
    return this.communicationService.post('apiservices/profile/v1/profiles/account', model);
  }
}
