<div *ngIf="loading">
  <fun-spinner></fun-spinner>
</div>
<div *ngIf="!loading" class="general-container">
  <div *ngIf="step === 0" class="invest-content">
    <h2 class="font-sans text-h600 md:text-h800 text-center"
    [ngClass]="{'text-[#0f5e42]' : data.isFlexibleCompensation, 'bg-gradient-2-text linear-gradient-2' : !data.isFlexibleCompensation}">{{ 'FORMALITIES.RESERVE_PROCESS.TITLE' | translate }}</h2>
      <p *ngIf="minReserveAmount === 0" class="font-sans text-center text-gray-40 text-l pb-24">{{ 'FORMALITIES.RESERVE_PROCESS.SUBTITLE' | translate }}</p>
      <p *ngIf="minReserveAmount > 0" class="font-sans text-center text-gray-40 text-l pb-24">{{ 'FORMALITIES.RESERVE_PROCESS.SUBTITLE_2' | translate:{amount: minReserveAmount} }}</p>
      <form [formGroup]="cashForm">
          <div class="amount">
              <fun-input
              placeholder="1000 €"
              [parentForm]="cashForm"
              [formControlKeys]="formControlKeys"
              [errorMessages]="errorMessages"
              [isSubmit]="isSubmit"
              controlName="cash"
              type="invest"
              [errorLogin]="errorCash"
              labelName="{{ 'FORMALITIES.RESERVE_PROCESS.CAP_TO_RESERVE' | translate }}"
              [value]="amount"
              (keyup)="sendValue()"
              #inputAmount></fun-input>
              <div class="co2">
                  <span class="font-sans text-s text-gray-40">{{ 'invest.Process.avoid' | translate }}</span>
                  <h3 class="font-sans text-eggplant-80 text-l" *ngIf="!moreTanAvailable">{{ prevent | co2 }}</h3>
                  <h3 class="font-sans text-eggplant-80 text-l" *ngIf="moreTanAvailable">-</h3>
              </div>
          </div>
      </form>
  </div>
  <div *ngIf="step === 1" class="check-content">
    <h2 class="font-sans text-h600 md:text-h800 text-center"
    [ngClass]="{'text-[#0f5e42]' : data.isFlexibleCompensation, 'bg-gradient-2-text linear-gradient-2' : !data.isFlexibleCompensation}">{{ 'FORMALITIES.RESERVE_PROCESS.RESERVE' | translate }}</h2>
    <div class="legal_text scrollbar-none">
     <p class="font-sans text-eggplant-80 text-l" *ngIf="data.allowedPostalCodes !== undefined">{{ 'FORMALITIES.RESERVE_PROCESS.LEGAL_TXT_1' | translate }}</p>
     <p class="font-sans text-eggplant-80 text-l" *ngIf="data.allowedPostalCodes !== undefined">{{ 'FORMALITIES.RESERVE_PROCESS.LEGAL_TXT_2' | translate }}</p>

     <p class="font-sans text-eggplant-80 text-l" *ngIf="data.allowedPostalCodes === undefined">{{ 'FORMALITIES.RESERVE_PROCESS.LEGAL_TXT_3' | translate }}</p>
    </div>
    <div class="check_container">
      <div class="check" [ngClass]="{'checked': checkedRespon }" (click)="checkRespon()"></div>
      <p class="text-m font-sans text-eggplant-100/[0.4] relative">{{ 'FORMALITIES.RESERVE_PROCESS.CHECK_TEXT' | translate: { cash: cashForm.value.cash, projectName: data.projectName } }}</p>
    </div>
  </div>
  <div *ngIf="step === 2">
    <fun-overal-message [text]="textOv" [image]="imageOv" [btnList]="btnListOv" (btnEvent)="clickBtn($event)"></fun-overal-message>
  </div>

  <div *ngIf="step !== 2" class="button-container pb-32 md:pb-0" >
    <div class="prev">
        <fun-new-button size="xl" (eventClick)="prev()" icon="arrow-left" [iconPosition]="'left'" [classExtra]="' !w-full '"
        [buttonType]="'ghost'" title="{{ 'local_invest.go_back' | translate }}"></fun-new-button>
    </div>
    <div class="next">
        <fun-new-button size="xl" (eventClick)="next()" class="next-btn"  [classExtra]="' !w-full '"
        [buttonType]="btntype" title="{{ 'FORMALITIES.RESERVE_PROCESS.BTN' | translate }}" icon="arrow-right" [iconPosition]="'right'"></fun-new-button>
    </div>
  </div>
</div>